export default function MessageIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2649 7.88535L3.30575 0.847854C2.46075 0.437021 1.46492 0.578687 0.765753 1.20702C0.0649194 1.83785 -0.178414 2.81785 0.144919 3.70285C0.159086 3.73869 3.82075 10.6162 3.82075 10.6162C3.82075 10.6162 0.226586 17.492 0.214086 17.527C-0.108414 18.4129 0.137419 19.3912 0.838253 20.0212C1.27242 20.4104 1.82075 20.6112 2.37242 20.6112C2.71325 20.6112 3.05492 20.5345 3.37325 20.3787L18.2666 13.347C19.3374 12.8445 20.0024 11.7979 20.0016 10.6154C20.0016 9.43202 19.3341 8.38535 18.2649 7.88535ZM1.69492 3.08535C1.59325 2.73952 1.80992 2.51035 1.88075 2.44535C1.95492 2.37952 2.22575 2.17535 2.57909 2.34869C2.58325 2.35035 17.5574 9.39452 17.5574 9.39452C17.7566 9.48785 17.9224 9.61952 18.0499 9.77952H5.26409L1.69492 3.08535ZM17.5566 11.8387L2.64992 18.877C2.29575 19.0512 2.02575 18.8479 1.95159 18.7804C1.87992 18.717 1.66325 18.4862 1.76575 18.1395L5.26742 11.4462H18.0549C17.9274 11.6087 17.7583 11.7437 17.5566 11.8387Z"
        fill="#252733"
      />
    </svg>
  );
}
