function YoutubeSocialMediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#E02F2F"
        d="M23.753 6.884s-.235-1.764-.954-2.542c-.912-1.018-1.935-1.024-2.404-1.082C17.038 3 12.002 3 12.002 3h-.012s-5.036 0-8.393.26c-.469.058-1.49.064-2.404 1.083C.475 5.12.24 6.883.24 6.883S0 8.955 0 11.026v1.94c0 2.073.24 4.143.24 4.143s.234 1.764.953 2.54c.914 1.018 2.111.987 2.645 1.094 1.92.196 8.158.257 8.158.257s5.042-.009 8.4-.266c.468-.06 1.49-.066 2.403-1.085.719-.775.954-2.54.954-2.54s.24-2.07.24-4.142v-1.94c0-2.072-.24-4.143-.24-4.143z"
      />
      <path fill="#fff" d="M9 7.5v9l7.5-4.5L9 7.5z" />
    </svg>
  );
}

export default YoutubeSocialMediaIcon;
