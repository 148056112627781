import { Backdrop, Box, Fade, Modal, styled } from "@mui/material";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

import {
  enterDuration,
  exitDuration,
} from "../../../constants/animations/fade";
import styleModal from "./modal-dialog.module.scss";

interface BlurContextProps {
  backdropBlur?: number;
}

const BlurContext = createContext<BlurContextProps>({});
const useBlurContext = () => useContext(BlurContext);

const StyledBackdrop = styled(Backdrop)(() => {
  const { backdropBlur } = useBlurContext();
  return {
    backdropFilter: `blur(${backdropBlur || 5}px)`,
  };
});

type ModalDialogProp = {
  isOpen: boolean;
  hideBackdrop?: boolean;
  onClose?: (node: HTMLElement) => void;
  classes?: string;
  dataCy?: string;
  backdropBlur?: number;
};

export const ModalDialog: FC<PropsWithChildren<ModalDialogProp>> = ({
  isOpen,
  hideBackdrop = false,
  children,
  onClose,
  classes,
  dataCy = null,
  backdropBlur,
}) => {
  const blurContextValue = useMemo(() => ({ backdropBlur }), [backdropBlur]);

  return (
    <BlurContext.Provider value={blurContextValue}>
      <Modal
        open={isOpen}
        closeAfterTransition
        hideBackdrop={hideBackdrop}
        slots={{ backdrop: StyledBackdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade
          in={isOpen}
          timeout={{ enter: enterDuration, exit: exitDuration }}
          unmountOnExit
          onExited={onClose}
        >
          <Box
            data-cy={dataCy || "modal"}
            className={`${styleModal.modal} ${classes}`}
          >
            {children}
          </Box>
        </Fade>
      </Modal>
    </BlurContext.Provider>
  );
};
