import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import FavouriteIcon from "../../../../media/icons/favourite";
import FavouritedIcon from "../../../../media/icons/favourited";
import {
  useBecomeInterestedMutation,
  useStopBeInterestedMutation,
} from "../../../../services/home/home.service";
import { SocialMedial } from "../../../profile/creator-catalog/creator-catalog-cc-view/side-bar-cc-view/social-media/social_media";
import { CreatorCardSidebarView } from "../../../shared/creator-card-sidebar-view/creator-card-sidebar-view";
import { ProfileDescription } from "../../../shared/profile-description/profile-description";
import { ProfilePersonalInfo } from "../../../shared/profile-personal-info/profile-personal-info";
import { ProfileTags } from "../../../shared/profile-tags/profile-tags";
import { useCreatorCatalogCSViewContext } from "../context/creator-catalog-cs-view-context";
import styles from "./sidebar-cs-view.module.scss";
import { SubscriptionPlansCsView } from "./subscription-plans-cs-view/subscription-plans-cs-view";
import { TipButton } from "./tip-button/tip-button";

interface SideBarCsViewParams {}

export const SideBarCsView: React.FC<SideBarCsViewParams> = () => {
  const { creatorCard, setCreatorCard, personalInfoParameters, isPreview } =
    useCreatorCatalogCSViewContext();

  const [becomeInterested] = useBecomeInterestedMutation();
  const [stopBeInterested] = useStopBeInterestedMutation();

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const handleInterested = useCallback(async () => {
    if (isPreview) return;

    setCreatorCard((prevState) => {
      if (!prevState) return prevState;
      return { ...prevState, is_interested: !prevState.is_interested };
    });

    if (creatorCard?.is_interested) {
      await stopBeInterested({
        accessToken: userAccessToken || "",
        id: creatorCard?.id || "",
      }).unwrap();
    } else {
      await becomeInterested({
        accessToken: userAccessToken || "",
        id: creatorCard?.id || "",
      }).unwrap();
    }
  }, [
    creatorCard,
    stopBeInterested,
    userAccessToken,
    becomeInterested,
    isPreview,
  ]);

  return (
    <div className={styles.sideBarGrid}>
      <CreatorCardSidebarView
        creatorCard={creatorCard}
        setCreatorCard={setCreatorCard}
        isPreview={isPreview}
      />
      <Box className={styles.ccBtnInteraction}>
        {creatorCard && (
          <TipButton
            isPreview={isPreview}
            creatorId={creatorCard.id}
            creatorName={creatorCard.username}
          />
        )}
        <Button
          color="primary"
          variant="contained"
          type="button"
          fullWidth
          onClick={handleInterested}
        >
          {creatorCard?.is_interested ? (
            <FavouritedIcon width="24" height="24" />
          ) : (
            <FavouriteIcon color="#FFFFFF" width="24" height="24" />
          )}
          <Typography variant="body1" color="#fff">
            Interested
          </Typography>
        </Button>
      </Box>

      {personalInfoParameters?.description && (
        <ProfileDescription personalInfoParameters={personalInfoParameters} />
      )}

      {personalInfoParameters?.links && (
        <SocialMedial links={personalInfoParameters.links} />
      )}

      {personalInfoParameters?.tags && (
        <ProfileTags personalInfoParameters={personalInfoParameters} />
      )}

      {personalInfoParameters && (
        <ProfilePersonalInfo personalInfoParameters={personalInfoParameters} />
      )}

      <SubscriptionPlansCsView isPreview={isPreview} />
    </div>
  );
};
