import { Typography } from "@mui/material";

import styles from "../subscriptions.module.scss";
import { CancelledSubscriptionsList } from "./cancelled-subscriptions-list";

export const CancelledSubscriptions = () => (
  <>
    <header className={styles.header}>
      <Typography variant="h4" fontWeight={400} className={styles.title}>
        Cancelled subscriptions
      </Typography>
    </header>
    <CancelledSubscriptionsList />
  </>
);
