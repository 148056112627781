import { Box, Fade, Popper, Typography } from "@mui/material";
import { useRef } from "react";

import { REGEX } from "../../../../constants/regex/regex";
import { PasswordValidationError } from "../../../../media/icons/password-validation-error";
import { PasswordValidationSuccess } from "../../../../media/icons/password-validation-success";

interface PasswordValidationPopperProps {
  anchorEl: HTMLDivElement | null;
  open: boolean;
  password: string;
}

export default function PasswordValidationPopover(
  props: PasswordValidationPopperProps
) {
  const { anchorEl, open, password } = props;
  const popperRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {anchorEl ? (
        <Popper
          anchorEl={anchorEl}
          placement="bottom-end"
          disablePortal
          open={open}
          transition
          ref={popperRef}
          sx={{
            zIndex: 1,
          }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: false,
                rootBoundary: "document",
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: "document",
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  backgroundColor: "rgba(49, 52, 45, 0.8)",
                  backdropFilter: "blur(2px)",
                  borderRadius: "10px",
                  marginRight: "0px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "13px 30px 13px 12px",
                  justifyContent: "space-between",
                  gap: "4px",
                  marginTop: "20px",
                  ...(popperRef.current?.dataset.popperPlacement ===
                    "top-end" && {
                    marginBottom: "23px",
                  }),

                  "&::before": {
                    content: '""',
                    position: "absolute",
                    borderWidth: 18,
                    borderStyle: "dashed",
                    borderColor: "transparent",
                    top: 0,
                    right: 0,
                    transform: "translate(-50%, -100%) scale(0.85, 1)",
                    borderBottomColor: "rgba(49, 52, 45, 0.8)",

                    ...(popperRef.current?.dataset.popperPlacement ===
                      "top-end" && {
                      bottom: 0,
                      right: 0,
                      transform: "translate(-50%, 100%) scale(0.85, 1)",
                      borderTopColor: "rgba(49, 52, 45, 0.8)",
                      borderBottomColor: "transparent",
                    }),
                  },
                }}
              >
                <Box display="flex" alignItems="center" gap="5px">
                  {REGEX.PASSWORD.LENGTH.test(password) ? (
                    <PasswordValidationSuccess />
                  ) : (
                    <PasswordValidationError />
                  )}
                  <Typography
                    variant="body2"
                    color="white"
                    fontWeight="normal"
                    fontSize="12px"
                  >
                    at least 8 characters
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap="5px">
                  {REGEX.PASSWORD.UPPERCASE.test(password) ? (
                    <PasswordValidationSuccess />
                  ) : (
                    <PasswordValidationError />
                  )}
                  <Typography
                    variant="body2"
                    color="white"
                    fontWeight="normal"
                    fontSize="12px"
                  >
                    at least 1 uppercase letter
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap="5px">
                  {REGEX.PASSWORD.LOWERCASE.test(password) ? (
                    <PasswordValidationSuccess />
                  ) : (
                    <PasswordValidationError />
                  )}
                  <Typography
                    variant="body2"
                    color="white"
                    fontWeight="normal"
                    fontSize="12px"
                  >
                    at least 1 lowercase letter
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap="5px">
                  {REGEX.PASSWORD.NUMBER.test(password) ? (
                    <PasswordValidationSuccess />
                  ) : (
                    <PasswordValidationError />
                  )}
                  <Typography
                    variant="body2"
                    color="white"
                    fontWeight="normal"
                    fontSize="12px"
                  >
                    at least 1 number
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap="5px">
                  {REGEX.PASSWORD.CHARS.test(password) ? (
                    <PasswordValidationSuccess />
                  ) : (
                    <PasswordValidationError />
                  )}
                  <Typography
                    variant="body2"
                    color="white"
                    fontWeight="normal"
                    fontSize="12px"
                  >
                    at least 1 special character
                  </Typography>
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      ) : null}
    </>
  );
}
