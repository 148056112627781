import type { FC } from "react";

type HeadsetIconProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
};
export const HeadsetIcon: FC<HeadsetIconProp> = ({
  width = 32,
  height = 32,
  disabled = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.9998 8.28234V7.33301C13.9998 5.74171 13.3677 4.21559 12.2424 3.09037C11.1172 1.96515 9.5911 1.33301 7.9998 1.33301C6.40851 1.33301 4.88238 1.96515 3.75716 3.09037C2.63195 4.21559 1.9998 5.74171 1.9998 7.33301V8.28234C1.30058 8.59023 0.728338 9.12898 0.378885 9.80838C0.0294327 10.4878 -0.0760217 11.2666 0.080176 12.0145C0.236374 12.7624 0.644743 13.4339 1.23692 13.9166C1.82909 14.3994 2.56913 14.6641 3.33314 14.6663C3.68676 14.6663 4.0259 14.5259 4.27595 14.2758C4.526 14.0258 4.66647 13.6866 4.66647 13.333V9.33301C4.66647 8.97939 4.526 8.64025 4.27595 8.3902C4.0259 8.14015 3.68676 7.99967 3.33314 7.99967V7.33301C3.33314 6.09533 3.8248 4.90835 4.69997 4.03318C5.57514 3.15801 6.76213 2.66634 7.9998 2.66634C9.23748 2.66634 10.4245 3.15801 11.2996 4.03318C12.1748 4.90835 12.6665 6.09533 12.6665 7.33301V7.99967C12.3128 7.99967 11.9737 8.14015 11.7237 8.3902C11.4736 8.64025 11.3331 8.97939 11.3331 9.33301V13.333H9.33314C9.15633 13.333 8.98676 13.4032 8.86173 13.5283C8.73671 13.6533 8.66647 13.8229 8.66647 13.9997C8.66647 14.1765 8.73671 14.3461 8.86173 14.4711C8.98676 14.5961 9.15633 14.6663 9.33314 14.6663H12.6665C13.4305 14.6641 14.1705 14.3994 14.7627 13.9166C15.3549 13.4339 15.7632 12.7624 15.9194 12.0145C16.0756 11.2666 15.9702 10.4878 15.6207 9.80838C15.2713 9.12898 14.699 8.59023 13.9998 8.28234ZM3.33314 13.333C2.8027 13.333 2.294 13.1223 1.91892 12.7472C1.54385 12.3721 1.33314 11.8634 1.33314 11.333C1.33314 10.8026 1.54385 10.2939 1.91892 9.91879C2.294 9.54372 2.8027 9.33301 3.33314 9.33301V13.333ZM12.6665 13.333V9.33301C13.1969 9.33301 13.7056 9.54372 14.0807 9.91879C14.4558 10.2939 14.6665 10.8026 14.6665 11.333C14.6665 11.8634 14.4558 12.3721 14.0807 12.7472C13.7056 13.1223 13.1969 13.333 12.6665 13.333Z"
      fill={`${disabled ? "#909294" : "#242424"}`}
    />
  </svg>
);
