import { Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { PlusIcon } from "../../../media/icons/plus";
import { HeaderWithSearch } from "../header-with-search/header-with-search";
import styles from "../manage-subscribers.module.scss";
import { SubscriberGroupsList } from "./subscriber-groups-list";

export const SubscriberGroups = () => {
  const [searchText, setSearchText] = useState<string>("");

  const [isDialogAddOpened, setIsDialogAddOpened] = useState(false);

  const handleOpenDialog = useCallback(
    () => () => {
      setIsDialogAddOpened(true);
    },
    []
  );

  return (
    <>
      <HeaderWithSearch setSearchText={setSearchText}>
        <Button
          variant="outlined"
          color="primary"
          className={styles.createGroupBtn}
          onClick={handleOpenDialog()}
          sx={{
            maxWidth: "236px",
            borderWidth: "2px",
            gap: "0.4rem",
            "&:hover": { borderWidth: "2px" },
          }}
        >
          <PlusIcon width={14} height={14} />
          <Typography variant="body2" fontWeight={600}>
            Create group
          </Typography>
        </Button>
      </HeaderWithSearch>
      <SubscriberGroupsList
        searchText={searchText}
        isDialogAddOpened={isDialogAddOpened}
        setIsDialogAddOpened={setIsDialogAddOpened}
      />
    </>
  );
};
