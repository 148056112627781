import {
  Box,
  Button,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../../../hooks/redux";
import { useSubscribeMutation } from "../../../../../services/subscriptions/subscriptions.service";
import { CurrenciesEnum } from "../../../../automatic-transfers/enums/currencies.enum";
import { ModalWrapper } from "../../../../creator-card/modal-wrapper/modal-wrapper";
import { PaymentMethodPicker } from "../../../../shared/payment-method-picker/payment-method-picker";
import { useCreatorCatalogCSViewContext } from "../../context/creator-catalog-cs-view-context";
import { ISubscriptionPlans, SUBSCRIPTION_PLANS } from "./subscription-plans";
import styles from "./subscription-plans-cs-view.module.scss";

interface ISubscriptionPlansProps {
  isPreview?: boolean;
}

export const SubscriptionPlansCsView: FC<ISubscriptionPlansProps> = ({
  isPreview,
}) => {
  const [activeSubscriptionPlan, setActiveSubscriptionPlan] = useState(
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN)
  );
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const isSubscriptionPlanValid = ["0", "1", "2"].includes(
    activeSubscriptionPlan || ""
  );

  const { user } = useAppSelector((data) => data.userReducer);
  const [isPurchaseSubscriptionOpen, setIsPurchaseSubscriptionOpen] =
    useState<boolean>(false);
  const [isChangeSubscriptionOpen, setIsChangeSubscriptionOpen] =
    useState<boolean>(false);
  const [openedSubscriptionPlan, setOpenedSubscriptionPlan] =
    useState<ISubscriptionPlans | null>(null);
  const [changedPlan, setChangedPlan] = useState<ISubscriptionPlans | null>(
    null
  );
  const [
    subscribe,
    { isLoading: isSubscribeLoading, isSuccess: isSubscribeSuccess },
  ] = useSubscribeMutation();
  const { creatorCard } = useCreatorCatalogCSViewContext();

  const formikSubscribe = useFormik({
    initialValues: {
      selectedMethodId: "",
    },
    validationSchema: Yup.object({
      selectedMethodId: Yup.string().required("Payment method is required"),
    }),
    onSubmit: async (values) => {
      await subscribe({
        accessToken: userAccessToken || "",
        currency: CurrenciesEnum.USD,
        payment_method_id: values.selectedMethodId,
        creator_id: creatorCard?.id || "",
      }).unwrap();
    },
  });

  const formikChangeSubscribe = useFormik({
    initialValues: {
      selectedMethodId: "",
    },
    validationSchema: Yup.object({
      selectedMethodId: Yup.string().required("Payment method is required"),
    }),
    onSubmit: async () => {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN,
        changedPlan?.plan_id || ""
      );
      setActiveSubscriptionPlan(changedPlan?.plan_id || "");
      // Close modal
      formikChangeSubscribe.resetForm();
      setOpenedSubscriptionPlan(null);
      setIsChangeSubscriptionOpen(false);
      setChangedPlan(null);
    },
  });

  useEffect(() => {
    const defaultMethod = user?.user_payment_methods.find(
      (item) => item.default
    );

    formikSubscribe.setFieldValue(
      "selectedMethodId",
      defaultMethod ? defaultMethod.id : ""
    );
  }, [user, isPurchaseSubscriptionOpen]);

  useEffect(() => {
    const defaultMethod = user?.user_payment_methods.find(
      (item) => item.default
    );

    formikChangeSubscribe.setFieldValue(
      "selectedMethodId",
      defaultMethod ? defaultMethod.id : ""
    );
  }, [user, isChangeSubscriptionOpen]);

  const onPurchaseSubscriptionClose = () => {
    formikSubscribe.resetForm();
    setOpenedSubscriptionPlan(null);
    setIsPurchaseSubscriptionOpen(false);
  };

  const onPurchaseSubscriptionOpen = (plan: ISubscriptionPlans) => {
    setOpenedSubscriptionPlan(plan);
    setIsPurchaseSubscriptionOpen(true);
  };

  const onChangeSubscriptionClose = () => {
    formikChangeSubscribe.resetForm();
    setOpenedSubscriptionPlan(null);
    setIsChangeSubscriptionOpen(false);
    setChangedPlan(null);
  };

  const onChangeSubscriptionOpen = () => {
    setOpenedSubscriptionPlan(
      SUBSCRIPTION_PLANS.find(
        (plan) => plan.plan_id === activeSubscriptionPlan
      ) || null
    );
    setIsChangeSubscriptionOpen(true);
  };

  useEffect(() => {
    if (isSubscribeSuccess) {
      // TODO: This is temporary solution because saving into database subscription plan is out of scope
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN,
        openedSubscriptionPlan?.plan_id || ""
      );
      setActiveSubscriptionPlan(openedSubscriptionPlan?.plan_id || "");
      onPurchaseSubscriptionClose();
    }
  }, [isSubscribeSuccess]);

  useEffect(() => {
    setActiveSubscriptionPlan(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN)
    );
  });

  return (
    <>
      <Box className={styles.plansWrapper}>
        {SUBSCRIPTION_PLANS.filter((item) => {
          if (creatorCard?.is_subscribed && isSubscriptionPlanValid) {
            return item.plan_id === activeSubscriptionPlan;
          }
          return item;
        }).map((plan) => (
          <Button
            key={plan.plan_id}
            variant="contained"
            type="button"
            fullWidth
            className={styles.plan}
            onClick={() => {
              if (isPreview) return;

              if (!creatorCard?.is_subscribed) {
                onPurchaseSubscriptionOpen(plan);
              }
            }}
            sx={{
              background: plan.backgroundColor,

              "&:hover": {
                background: plan.backgroundHoverColor,
              },
            }}
          >
            <Typography variant="body1" color="#fff">
              {plan.plan_name}
            </Typography>
            <Typography variant="body1">
              ${plan.plan_amount}.00/Month
            </Typography>
          </Button>
        ))}
        {creatorCard?.is_subscribed && isSubscriptionPlanValid && (
          <Box>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={onChangeSubscriptionOpen}>
              Change subscription plan
            </Link>
          </Box>
        )}
      </Box>

      {/* Purchase subscription */}
      <ModalWrapper
        shown={isPurchaseSubscriptionOpen}
        close={onPurchaseSubscriptionClose}
        disableBackdropClose
      >
        <form onSubmit={formikSubscribe.handleSubmit}>
          <Box className={styles.subscriptionModalWrapper}>
            <Box className={styles.subscriptionModal}>
              <Box className={styles.subscriptionHeaderModal}>
                <Typography fontSize="24px" color="#252733">
                  Purchase subscription
                </Typography>
              </Box>

              <Box className={styles.subscriptionMessage}>
                <Typography variant="body1">
                  Subscription plan &quot;{openedSubscriptionPlan?.plan_name}
                  &quot;
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  ${openedSubscriptionPlan?.plan_amount}
                  .00/Month
                </Typography>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Payment method
                  </Typography>

                  <PaymentMethodPicker
                    value={formikSubscribe.values.selectedMethodId}
                    onChange={(selectedMethodId) => {
                      formikSubscribe.setFieldValue(
                        "selectedMethodId",
                        selectedMethodId
                      );
                    }}
                  />
                </>
              </Box>

              <Box className={styles.footer}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formikSubscribe.isValid || isSubscribeLoading}
                >
                  <Typography variant="button">Buy</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={onPurchaseSubscriptionClose}
                  disabled={isSubscribeLoading}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>

      {/* Change subscription */}
      <ModalWrapper
        shown={isChangeSubscriptionOpen}
        close={onChangeSubscriptionClose}
        disableBackdropClose
      >
        <form onSubmit={formikChangeSubscribe.handleSubmit}>
          <Box className={styles.subscriptionModalWrapper}>
            <Box className={styles.subscriptionModal}>
              <Box className={styles.subscriptionHeaderModal}>
                <Typography fontSize="24px" color="#252733">
                  Change subscription plan
                </Typography>
              </Box>

              <Box className={styles.subscriptionMessage}>
                <Typography variant="body1">
                  Subscription plan &quot;{openedSubscriptionPlan?.plan_name}
                  &quot;
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  ${openedSubscriptionPlan?.plan_amount}
                  .00/Month
                </Typography>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Choose plan
                  </Typography>

                  <ToggleButtonGroup
                    orientation="horizontal"
                    value={changedPlan}
                    exclusive
                    onChange={(e, nextView) => {
                      if (nextView === null) return;

                      setChangedPlan(nextView);
                    }}
                    className={styles.btnViewGroup}
                    sx={{
                      width: "100%",
                    }}
                  >
                    {SUBSCRIPTION_PLANS.filter((item) => {
                      if (isSubscriptionPlanValid) {
                        return item.plan_id !== activeSubscriptionPlan;
                      }
                      return null;
                    }).map((plan) => (
                      <ToggleButton
                        key={plan.plan_id}
                        value={plan}
                        aria-label="plan"
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          "&.MuiToggleButtonGroup-firstButton": {
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                            border: `2px solid ${plan.backgroundColor}`,
                            color: plan.backgroundColor,
                            background: "#fff",
                          },
                          "&.MuiToggleButtonGroup-lastButton": {
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                            border: `2px solid ${plan.backgroundColor}`,
                            color: plan.backgroundColor,
                            background: "#fff",
                          },
                          "&.Mui-selected": {
                            color: "#fff",
                            background: plan.backgroundColor,
                          },
                          "&.Mui-selected:hover": {
                            color: "#fff",
                            background: plan.backgroundHoverColor,
                          },
                        }}
                      >
                        <Typography variant="button">
                          {plan.plan_name}
                        </Typography>
                        <Typography variant="body1">
                          ${openedSubscriptionPlan?.plan_amount}
                          .00/Month
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Payment method
                  </Typography>

                  <PaymentMethodPicker
                    value={formikChangeSubscribe.values.selectedMethodId}
                    onChange={(selectedMethodId) => {
                      formikChangeSubscribe.setFieldValue(
                        "selectedMethodId",
                        selectedMethodId
                      );
                    }}
                  />
                </>
              </Box>

              <Box className={styles.footer}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formikChangeSubscribe.isValid || !changedPlan}
                >
                  <Typography variant="button">Buy</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={onChangeSubscriptionClose}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>
    </>
  );
};
