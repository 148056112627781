import { Box, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { maxNumberOfGreetingMedia } from "../../../../constants/constants";
import type { IUploadFile } from "../../../../interfaces/file-uploading/uploaded-files.interface";
import { FileLoader } from "../../../shared/file-loader/file-loader";
import { GreetingsMediaList } from "../greetings-media-list/greetings-media-list";

interface IGreetingsMediaProp {
  loadedGreetingMedia: IUploadFile[];
  setLoadedGreetingMedia: Dispatch<SetStateAction<IUploadFile[]>>;
  uploadFileOnRetry: (file: IUploadFile) => void;
  setDeletedRemoteMedia: Dispatch<SetStateAction<IUploadFile[]>>;
}

export const GreetingsMedia: FC<IGreetingsMediaProp> = ({
  setLoadedGreetingMedia,
  loadedGreetingMedia,
  uploadFileOnRetry,
  setDeletedRemoteMedia,
}) => {
  const getFileType = (file: File): "image" | "video" | "unknown" => {
    if (file.type.includes("video")) {
      return "video";
    }

    if (file.type.includes("image")) {
      return "image";
    }

    return "unknown";
  };

  const onGreetingMediaUpload = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const alreadyExists = loadedGreetingMedia.find(
          (f) => f.file?.name === file.name && f.file.size === file.size
        );

        if (!alreadyExists) {
          const newFile: IUploadFile = {
            file,
            id: uuidv4(),
            status: "loaded",
            progressOfUploading: 0,
            fileName: file.name,
            fileUrl: URL.createObjectURL(file),
            fileType: getFileType(file),
          };

          setLoadedGreetingMedia((prevState) => [...prevState, newFile]);
        }
      });
    },
    [loadedGreetingMedia]
  );

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Typography fontWeight="bold" variant="body1">
        Upload photos/videos
      </Typography>
      <Typography variant="body2" sx={{ padding: "5px 0" }}>
        Add up to 10 photos/videos to make your profile stunning
      </Typography>
      <Box
        sx={{
          marginTop: "8px",
          height: "200px",
        }}
      >
        <FileLoader
          onFilesUpload={onGreetingMediaUpload}
          isError={loadedGreetingMedia.length > maxNumberOfGreetingMedia}
        />
        <Typography
          variant="caption"
          align="right"
          color={
            loadedGreetingMedia.length > maxNumberOfGreetingMedia
              ? "#FF5252"
              : "#989898"
          }
          marginTop="3px"
        >
          {loadedGreetingMedia.length}/{maxNumberOfGreetingMedia}
        </Typography>
      </Box>
      <Box>
        <GreetingsMediaList
          files={loadedGreetingMedia}
          setLoadedFiles={setLoadedGreetingMedia}
          fileWidthPx={325}
          draggable
          uploadFileOnRetry={uploadFileOnRetry}
          setDeletedRemoteMedia={setDeletedRemoteMedia}
        />
      </Box>
    </Box>
  );
};
