import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch } from "../../../hooks/redux";
import AmexLargeIcon from "../../../media/icons/amex-large";
import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../media/icons/btc";
import { DeleteIcon } from "../../../media/icons/delete-icon";
import DiscoverLargeIcon from "../../../media/icons/discover-large";
import { EditIcon } from "../../../media/icons/edit";
import { MakeDefaultIcon } from "../../../media/icons/make-default";
import { MasterCardIconLarge } from "../../../media/icons/master-card-large";
import { VisaIconLarge } from "../../../media/icons/visa-icon";
import {
  useMakeDefaultMutation,
  useRemovePaymentMethodMutation,
} from "../../../services/payment-methods/payment-methods.service";
import { paymentMethodReducer } from "../../../store/reducers/payment-methods/payment-methods";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import type { PaymentMethodsResponseInterface } from "../interfaces/payment-method.interface";
import styles from "./payment-method-card.module.scss";

interface PaymentMethodCardProps {
  method: PaymentMethodsResponseInterface;
  handleEditMethod: (method: PaymentMethodsResponseInterface) => void;
  index: number;
}

export default function PaymentMethodCard(props: PaymentMethodCardProps) {
  const { method, handleEditMethod, index } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteMethodId, setDeleteMethodId] = useState("");

  const [makeDefaultMethod, { isLoading: isDefaultLoading }] =
    useMakeDefaultMutation();

  const [removeMethod, { isLoading: isDeleteLoading }] =
    useRemovePaymentMethodMutation();
  const dispatch = useAppDispatch();

  const { makeDefault, deleteMethod } = paymentMethodReducer.actions;

  const renderLogo = (type: string) => {
    switch (type) {
      case "visa":
        return <VisaIconLarge height={24} width={32} />;
      case "mastercard":
        return <MasterCardIconLarge height={24} width={32} />;
      case "discover":
        return <DiscoverLargeIcon />;
      case "amex":
        return <AmexLargeIcon />;
      default:
        return null;
    }
  };

  const renderType = () => {
    const { card, bank_account: bankAccount, crypto } = method;

    if (card) {
      const { card_number: cardNumber, card_type: cardType, name } = card;
      return (
        <Box display="flex" gap="8px" alignItems="center">
          {renderLogo(cardType)}
          {name ? (
            <Typography>{name}</Typography>
          ) : (
            <Box>
              <Typography>{cardNumber}</Typography>
            </Box>
          )}
        </Box>
      );
    }
    if (bankAccount) {
      const {
        bank_account_number: bankAccountNumber,
        routing_number: routingNumber,
        name,
      } = bankAccount;
      return (
        <Box display="flex" gap="8px" alignItems="center">
          <BankAcc width={32} height={32} />
          <Box>
            {name ? (
              <Typography>{name}</Typography>
            ) : (
              <>
                <Typography>Acc. Number: {bankAccountNumber}</Typography>
                <Typography>Routing Number: {routingNumber}</Typography>
              </>
            )}
          </Box>
        </Box>
      );
    }
    if (crypto) {
      const { wallet_id: walletId, name } = crypto;
      return (
        <Box display="flex" gap="8px" alignItems="center">
          <BtcLogo width={32} height={27} />
          {name ? (
            <Typography>{name}</Typography>
          ) : (
            <Box>
              <Typography>{walletId}</Typography>
            </Box>
          )}
        </Box>
      );
    }

    return "";
  };

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const handleMakeDefault = async (id: string) => {
    if (isDefaultLoading || isDeleteLoading) {
      return;
    }
    const data = await makeDefaultMethod({ id, accessToken }).unwrap();

    if (data.success) {
      dispatch(makeDefault({ id }));
    }
  };

  const handleRemove = async (id: string) => {
    if (isDeleteLoading || isDefaultLoading) {
      return;
    }
    const data = await removeMethod({ id, accessToken }).unwrap();

    if (data.success) {
      dispatch(deleteMethod({ id }));
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {renderType()}
        <Box display="flex" gap="4px" alignItems="center">
          <Box display="flex" gap="20px" alignItems="center">
            <Button
              startIcon={
                isDefaultLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <MakeDefaultIcon
                    fill={method.default ? "white" : "rgba(58, 117, 233, 1)"}
                    width={20}
                    height={20}
                  />
                )
              }
              variant={method.default ? "contained" : "outlined"}
              data-cy={`makeDefault-${index}`}
              sx={{
                height: "32px",
                backgroundColor: method.default ? "" : "white",
                width: "170px",
                border: method.default ? "" : "1.5px solid",
                padding: method.default ? "8px" : "6px 23px",
                gap: method.default ? "0" : undefined,
                "&:hover": {
                  backgroundColor: !method.default ? "#fff" : undefined,
                },
                justifyContent: !method.default ? "flex-start" : undefined,
              }}
              onClick={() => {
                if (method.default) {
                  return;
                }
                handleMakeDefault(method.id);
              }}
            >
              <Typography variant="body2">
                {method.default ? "Set as default" : "Make default"}
              </Typography>
            </Button>
            <Button
              startIcon={<EditIcon width={20} height={20} />}
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "114px",
                border: "1.5px solid",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              data-cy={`edit-${index}`}
              onClick={() => {
                if (isDefaultLoading || isDeleteLoading) {
                  return;
                }
                handleEditMethod(method);
              }}
            >
              Edit
            </Button>
          </Box>

          <IconButton
            data-cy={`delete-${index}`}
            color="error"
            onClick={() => {
              setOpenDialog(true);
              setDeleteMethodId(method.id);
            }}
            sx={{ transform: "translate(8px, -1px)" }}
          >
            <DeleteIcon color="red" />
          </IconButton>
        </Box>
      </Box>

      <ModalDialog
        isOpen={openDialog && deleteMethodId !== ""}
        classes={styles.modal}
      >
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Delete payment method
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Are you sure you want to delete this payment method?
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="error"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={() => handleRemove(deleteMethodId)}
            data-cy="confirm-deleting"
          >
            <Typography variant="button">
              {isDeleteLoading ? <CircularProgress size="1.5rem" /> : "Delete"}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => {
              setOpenDialog(false);
              setDeleteMethodId("");
            }}
            disabled={isDeleteLoading}
          >
            <Typography variant="button">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>
    </>
  );
}
