import { CurrenciesEnum } from "../../../components/automatic-transfers/enums/currencies.enum";
import { ActiveTab } from "../../../components/profile/creator-catalog/interfaces/ActiveTab";

export type Audience =
  | "me"
  | "everyone"
  | "subscribers"
  | "specific_subscriber"
  | "group"
  | null;

export enum AudienceEnum {
  me = "No One (Just Me)",
  everyone = "Everyone",
  subscribers = "My Subscribers",
  specific_subscriber = "A Specific Subscriber",
  group = "A Subscriber Group",
}

export enum AudienceValuesEnum {
  me = "me",
  everyone = "everyone",
  subscribers = "subscribers",
  specific_subscriber = "specific_subscriber",
  group = "group",
}

export type Visibility = "visibleNow" | "planned" | null;

export enum VisibilityEnum {
  visible = "visibleNow",
  planned = "visibleLater",
}

export type ContentDetails = "noDescription" | null;

export type ContentType = "image" | "video" | "collection" | null;

export type Price = "lowHigh" | "highLow" | null;

export type Show = "free" | "paid" | null;

export enum PriceEnum {
  free = "free",
  paid = "paid",
}

export type SortBy =
  | "all"
  | "name"
  | "created_at"
  | "price"
  | "viewes"
  | "sold"
  | "earnings"
  | "folders/asc"
  | "folders/desc"
  | null;

export type Direction = "asc" | "desc" | null;

export interface IFilterCatalog {
  audience: Audience[];
  show: Show[];
  contentType: ContentType[];
  visibility: Visibility[];
  contentDetails: ContentDetails[];
}

export interface IFilterCatalogCs {
  show: Show[];
  contentType: ContentType[];
  price: Price[];
}

export interface IGetContentCatalogParams {
  accessToken: string;
  page: number;
  rowsPerPage: number;
  searchText: string | undefined;

  // Filters
  filters: IFilterCatalog;

  // Sorting
  sortBy: SortBy;
  direction: Direction;

  currentTab: ActiveTab;

  folder_id?: string;
}

export interface IGetContentCatalogCsParams {
  accessToken: string;
  creator_id: string;
  page: number;
  rowsPerPage: number;
  searchText: string | undefined;

  // Filters
  filters: IFilterCatalogCs;
}

export interface IBaseParams {
  accessToken: string;
}

export interface IGetUserCreatorByIdParams {
  accessToken: string;
  id: string;
}

export interface IDeleteContentByIdsParams {
  accessToken: string;
  content_ids: string[];
}

export interface IEditSingleContentFormValues {
  title: string | null;
  descriptionText: string | null;
  description: string | null;
  tags: string[];
  audience: {
    audience: AudienceValuesEnum;
    ids: string[] | null;
  };
  price: {
    price: PriceEnum;
    amount: number | null;
    currency: CurrenciesEnum | null;
  };
  visibility: {
    visibility: VisibilityEnum;
    date: string | null;
  };
  folder_id: string | null;
}

export interface IEditSingleCollectionFormValues {
  title: string | null;
  descriptionText: string | null;
  description: string | null;
  tags: string[];
  audience: {
    audience: AudienceValuesEnum;
    ids: string[] | null;
  };
  price: {
    price: PriceEnum;
    amount: number | null;
    currency: CurrenciesEnum | null;
  };
  visibility: {
    visibility: VisibilityEnum;
    date: string | null;
  };
  folder_id: string | null;
}

export interface IUpdateContentByIdsParams {
  contentIds: string[];
  accessToken: string;
  formData: Partial<IEditSingleContentFormValues>;
  folder_id: string | null;
}

export interface IUpdateCollectionParams {
  collectionId: string;
  accessToken: string;
  media_id_changed: string[];
  formData: Partial<IEditSingleCollectionFormValues>;
}

export interface ICreateCollectionParams {
  accessToken: string;
  media_ids: string[];
  formData: Partial<IEditSingleCollectionFormValues>;
}

export interface IAddContentToCollectionsParams {
  collection_ids: string[];
  media_ids: string[];
  accessToken: string;
}

export interface IGetAllFoldersParams {
  accessToken: string;
  searchText?: string;
  folderId?: string;
}

export interface IDeleteFolderParams {
  accessToken: string;
  folderId: string;
}

export interface IUpdateFolderParams {
  accessToken: string;
  folderName?: string;
  content_id?: string;
  folderId: string;
  destination_folder_id?: string;
}

export interface ICreateFolderParams {
  accessToken: string;
  folderName: string;
  parent_folder_id: string | null;
}

export interface IRemoveFromFolderParams {
  accessToken: string;
  folderId: string;
  content_id: string;
}

export interface ISetLovedParams {
  accessToken: string;
  content_id: string;
}

export interface IRemoveLovedParams {
  accessToken: string;
  content_id: string;
}

export interface IAddViewParams {
  accessToken: string;
  content_id: string;
}

export interface IBuyContentParams {
  accessToken: string;
  content_id: string;
  currency: CurrenciesEnum;
  payment_method_id: string;
}

export interface IGetContentByIdParams {
  accessToken: string;
  content_id: string;
}
