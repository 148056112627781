import type { Theme } from "@mui/material";
import {
  createTheme,
  filledInputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paperClasses,
  tableCellClasses,
} from "@mui/material";
import type { Components } from "@mui/material/styles/components";

import type ComponentConfig from "./interfaces/config.interface";

const muiTheme = createTheme();

export default function createComponents(config: ComponentConfig): Components {
  const { palette } = config;

  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderBottom: "1px solid #FFFFFF",
          borderTop: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,

          "&.MuiAccordion-root": {
            borderRadius: 0,
            position: "static",
          },

          ".MuiPaper-rounded": {
            borderRadius: 0,
          },

          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 0 5px 0",
          "&.Mui-expanded": {
            minHeight: "48px",
            marginTop: 0,
            margin: 0,
            "& > .MuiAccordionSummary-content": {
              margin: "12px 0",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "0 5px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: ({ ownerState: { max = 1 } }) => ({
          ...[...Array(max)].reduce(
            (result, curr, index) => ({
              ...result,
              [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
                zIndex: max - index,
              },
              [`& > .MuiAvatar-root`]: {
                marginLeft: "-16px",
                fontWeight: "normal",
              },
            }),
            {}
          ),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px",
          color: "rgba(135, 140, 142, 1)",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            padding: "8px 24px",
            minWidth: "auto",
            textTransform: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            fontSize: "16px",
            color: "#FFF",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "24px",
            boxShadow: "none",
            backgroundImage:
              "radial-gradient(circle at 100% 100%, transparent 10px, #6694ee 10px, #6694ee 12px, transparent 12px), linear-gradient(to right, #6694ee, rgba(102, 148, 238, 0.3)), radial-gradient(circle at 0% 100%, transparent 10px, rgba(102, 148, 238, 0.3) 10px, rgba(102, 148, 238, 0.3) 12px, transparent 12px), linear-gradient(to bottom, rgba(102, 148, 238, 0.3), rgba(102, 148, 238, 0)), radial-gradient(circle at 0% 0%, transparent 10px, rgba(102, 148, 238, 0) 10px, rgba(102, 148, 238, 0) 12px, transparent 12px), linear-gradient(to left, rgba(102, 148, 238, 0), rgba(102, 148, 238, 0.3)), radial-gradient(circle at 100% 0%, transparent 10px, rgba(102, 148, 238, 0.3) 10px, rgba(102, 148, 238, 0.3) 12px, transparent 12px), linear-gradient(to top, rgba(102, 148, 238, 0.3), #6694ee)",
            backgroundSize:
              "12px 12px, calc(100% - 24px) 2px, 12px 12px, 2px calc(100% - 24px)",
            backgroundPosition:
              "top left,top center,top right,center right, bottom right,bottom center,bottom left,center left",
            backgroundRepeat: "no-repeat",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            padding: "7px 22px",
            borderWidth: "1.5px",
            background: "transparent",
            borderRadius: "12px",
            "&:hover": {
              background: "transparent",
              backgroundImage: "none",
              boxShadow: "none",
              borderWidth: "1.5px",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: "rgba(58, 117, 233, 1)",
            "&:hover": {
              borderColor: "rgba(27, 94, 227, 1)",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            background: "transparent",
            boxShadow: "none",
            "&:hover": {
              background: "transparent",
              backgroundImage: "none",
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          "&:hover": {
            backgroundImage:
              "radial-gradient(circle at 100% 100%, transparent 10px, rgb(78, 130, 232) 10px, rgb(78, 130, 232) 12px, transparent 12px), linear-gradient(to right, rgb(78, 130, 232), rgba(78, 130, 232, 0.3)), " +
              "radial-gradient(circle at 0% 100%, transparent 10px, rgba(78, 130, 232, 0.3) 10px, rgba(78, 130, 232, 0.3) 12px, transparent 12px), linear-gradient(to bottom, rgba(78, 130, 232, 0.3), rgba(78, 130, 232, 0)), " +
              "radial-gradient(circle at 0% 0%, transparent 10px, rgba(78, 130, 232, 0) 10px, rgba(78, 130, 232, 0) 12px, transparent 12px), linear-gradient(to left, rgba(78, 130, 232, 0), rgba(78, 130, 232, 0.3)), " +
              "radial-gradient(circle at 100% 0%, transparent 10px, rgba(78, 130, 232, 0.3) 10px, rgba(78, 130, 232, 0.3) 12px, transparent 12px), linear-gradient(to top, rgba(78, 130, 232, 0.3), rgb(78, 130, 232))",
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            background: "rgba(170, 170, 170, 1)",
            color: "#fff",
            backgroundPosition:
              "top left,top center,top right,center right, bottom right,bottom center,bottom left,center left",
            backgroundSize:
              "12px 12px, calc(100% - 24px) 2px, 12px 12px, 2px calc(100% - 24px)",
            backgroundRepeat: "no-repeat",
          },
          "&.MuiButton-containedSuccess": {
            backgroundImage:
              ownerState.disabled === false
                ? "radial-gradient(circle at 100% 100%, transparent 10px, rgb(93, 221, 122) 10px, rgb(93, 221, 122) 12px, transparent 12px), linear-gradient(to right, rgb(93, 221, 122), rgba(93, 221, 122, 0.3)), " +
                  "radial-gradient(circle at 0% 100%, transparent 10px, rgba(93, 221, 122, 0.3) 10px, rgba(93, 221, 122, 0.3) 12px, transparent 12px), linear-gradient(to bottom, rgba(93, 221, 122, 0.3), rgba(93, 221, 122, 0)), " +
                  "radial-gradient(circle at 0% 0%, transparent 10px, rgba(93, 221, 122, 0) 10px, rgba(93, 221, 122, 0) 12px, transparent 12px), linear-gradient(to left, rgba(93, 221, 122, 0), rgba(93, 221, 122, 0.3)), " +
                  "radial-gradient(circle at 100% 0%, transparent 10px, rgba(93, 221, 122, 0.3) 10px, rgba(93, 221, 122, 0.3) 12px, transparent 12px), linear-gradient(to top, rgba(93, 221, 122, 0.3), rgb(93, 221, 122))"
                : "transparent",
          },
          "&.MuiButton-containedError": {
            backgroundImage:
              ownerState.disabled === false
                ? "radial-gradient(circle at 100% 100%, transparent 10px, rgb(255, 120, 120) 10px, rgb(255, 120, 120) 12px, transparent 12px), linear-gradient(to right, rgb(255, 120, 120), rgba(255, 120, 120, 0.3)), " +
                  "radial-gradient(circle at 0% 100%, transparent 10px, rgba(255, 120, 120, 0.3) 10px, rgba(255, 120, 120, 0.3) 12px, transparent 12px), linear-gradient(to bottom, rgba(255, 120, 120, 0.3), rgba(93, 221, 122, 0)), " +
                  "radial-gradient(circle at 0% 0%, transparent 10px, rgba(93, 221, 122, 0) 10px, rgba(93, 221, 122, 0) 12px, transparent 12px), linear-gradient(to left, rgba(93, 221, 122, 0), rgba(255, 120, 120, 0.3)), " +
                  "radial-gradient(circle at 100% 0%, transparent 10px, rgba(255, 120, 120, 0.3) 10px, rgba(255, 120, 120, 0.3) 12px, transparent 12px), linear-gradient(to top, rgba(255, 120, 120, 0.3), rgb(255, 120, 120))"
                : "transparent",
          },
          "&.MuiButton-containedInfo": {
            backgroundImage:
              ownerState.disabled === false
                ? "radial-gradient(circle at 100% 100%, transparent 10px, rgb(161, 165, 167) 10px, rgb(161, 165, 167) 12px, transparent 12px), linear-gradient(to right, rgb(161, 165, 167), rgba(161, 165, 167, 0.3)), " +
                  "radial-gradient(circle at 0% 100%, transparent 10px, rgba(161, 165, 167, 0.3) 10px, rgba(161, 165, 167, 0.3) 12px, transparent 12px), linear-gradient(to bottom, rgba(161, 165, 167, 0.3), rgba(93, 221, 122, 0)), " +
                  "radial-gradient(circle at 0% 0%, transparent 10px, rgba(93, 221, 122, 0) 10px, rgba(93, 221, 122, 0) 12px, transparent 12px), linear-gradient(to left, rgba(93, 221, 122, 0), rgba(161, 165, 167, 0.3)), " +
                  "radial-gradient(circle at 100% 0%, transparent 10px, rgba(161, 165, 167, 0.3) 10px, rgba(161, 165, 167, 0.3) 12px, transparent 12px), linear-gradient(to top, rgba(161, 165, 167, 0.3), rgb(161, 165, 167))"
                : "transparent",
          },
        }),
        sizeSmall: {
          padding: "6px 16px",
        },
        sizeMedium: {
          padding: "8px 20px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
          },
          borderRadius: "24px",
          border: "1px solid rgba(255, 255, 255, 0.80)",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 156.08%)",
          padding: "24px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "32px 24px",
          "&:last-child": {
            paddingBottom: "32px",
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
        subheaderTypographyProps: {
          variant: "body2",
        },
      },
      styleOverrides: {
        root: {
          padding: "32px 24px 16px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
        "#nprogress": {
          pointerEvents: "none",
        },
        "#nprogress .bar": {
          backgroundColor: palette.primary.main,
          height: 3,
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2000,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px",
          "&::placeholder": {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: "#000000",
          transform: "translate(14px, 9px) scale(1)",
          letterSpacing: "0.025rem",

          "&.MuiFormLabel-colorSuccess": {
            color: (theme as Theme).palette.success.main,
          },
          "&.MuiFormLabel-colorError": {
            color: (theme as Theme).palette.error.main,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: "100%",
          padding: "8px 12px",
          borderRadius: "8px",
          background: "#fff",
          "&.Mui-disabled, &.Mui-disabled:hover": {
            color: "rgba(170, 170, 170, 1)",
            "-webkit-text-fill-color": "unset",
            background: "transparent",
            backgroundColor: "transparent",
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor:
              (ownerState.color === "success" &&
                (theme as Theme).palette.success.main) ||
              (ownerState.color === "error" &&
                (theme as Theme).palette.error.main) ||
              (ownerState.disabled && "rgba(170, 170, 170, 1)"),
          },
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.59)",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: ownerState.disabled
                ? (ownerState.color === "success" &&
                    (theme as Theme).palette.success.main) ||
                  (ownerState.color === "error" &&
                    (theme as Theme).palette.error.main)
                : "rgb(182,198,204)",
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              boxSizing: "border-box",
              borderWidth: "1px",
              borderColor:
                (ownerState.color === "success" &&
                  (theme as Theme).palette.success.main) ||
                (ownerState.color === "error" &&
                  (theme as Theme).palette.error.main) ||
                (ownerState.color === "primary" &&
                  (theme as Theme).palette.primary.main),
            },
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "rgba(199, 216, 222, 1)",
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.error.main,
            },
          },
        }),
        input: {
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
          letterSpacing: "0.013rem",
          padding: "0",
          boxSizing: "border-box",
          "&::placeholder": {
            opacity: "0.5",
          },
        },
        notchedOutline: {
          color: "black",
          borderColor: palette.neutral[300],
          transition: muiTheme.transitions.create(["border-color"]),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          [`&.${inputLabelClasses.filled}`]: {
            transform: "translate(12px, 18px) scale(1)",
          },
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.standard}`]: {
              transform: "translate(0, -1.5px) scale(0.85)",
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: "translate(12px, 6px) scale(0.85)",
            },
            [`&.${inputLabelClasses.outlined}`]: {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          "& + &": {
            marginLeft: 24,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderTop: `1px solid #fff`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: `none`,
          borderBottom: "none",
          borderBottomColor: palette.divider,
          padding: "15px 16px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
            backgroundColor: palette.neutral[50],
            color: palette.neutral[700],
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: "uppercase",
          },
          [`& .${tableCellClasses.paddingCheckbox}`]: {
            paddingTop: 4,
            paddingBottom: 4,
          },
          "& .MuiTableRow-head": {
            borderTop: "none",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 42,
          height: 26,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: (theme as Theme).palette.primary,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: (theme as Theme).palette.primary,
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: (theme as Theme).palette.grey[100],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "rgba(135, 140, 142, 1)",
            opacity: 1,
            transition: (theme as Theme).transitions.create(
              ["background-color"],
              {
                duration: 500,
              }
            ),
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          backgroundImage:
            "radial-gradient(#ffffff, #ffffff 36%, #bebebe 40%,transparent 42%)",
          color: "transparent",

          "&.Mui-checked": {
            borderWidth: "22px",
            backgroundImage:
              "radial-gradient(#3A75E9, #3A75E9 28%, #ffffff 32%, #ffffff 36%, #3A75E9 40%,transparent 42%)",
            color: "transparent",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "&:nth-of-type(2) > .MuiBackdrop-root ": {
            backdropFilter: "blur(7px)",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .Mui-focused": {
            borderRadius: "8px 8px 0 0 !important",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-paper": {
            borderRadius: "0 0 8px 8px",
          },
        },
      },
    },
  };
}
