import type { FC } from "react";

type ReturnBackIconProp = {
  width?: number;
  height?: number;
  color?: string;
};

export const ReturnBackIcon: FC<ReturnBackIconProp> = ({
  width = 18,
  height = 18,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4457_47599)">
      <path
        d="M15.4982 8.99883C15.3345 7.55258 14.639 6.21884 13.5467 5.25687C12.4544 4.2949 11.0435 3.77344 9.58816 3.79383H1.99816L3.62316 1.49883C3.66207 1.44463 3.68973 1.38318 3.70452 1.31811C3.71932 1.25305 3.72093 1.18568 3.70929 1.11998C3.69764 1.05428 3.67295 0.991575 3.63669 0.935561C3.60043 0.879547 3.55333 0.831358 3.49816 0.793833C3.38997 0.717921 3.25613 0.687931 3.12589 0.710417C2.99566 0.732904 2.87962 0.806039 2.80316 0.913833L0.573156 4.03883C0.521733 4.11206 0.494141 4.19936 0.494141 4.28883C0.494141 4.37831 0.521733 4.46561 0.573156 4.53883L2.80816 7.66883C2.88916 7.76217 3.00185 7.82219 3.1245 7.83734C3.24715 7.85248 3.37106 7.82167 3.47233 7.75084C3.57361 7.68001 3.64506 7.57419 3.67291 7.45379C3.70076 7.33339 3.68304 7.20694 3.62316 7.09883L1.99816 4.79383H9.58816C10.8134 4.77749 11.9997 5.22412 12.91 6.04447C13.8203 6.86482 14.3874 7.99846 14.4982 9.21883C14.542 9.86839 14.4519 10.52 14.2336 11.1334C14.0153 11.7467 13.6733 12.3087 13.229 12.7845C12.7846 13.2603 12.2472 13.6397 11.6502 13.8994C11.0532 14.159 10.4092 14.2933 9.75816 14.2938H0.998156C0.865548 14.2938 0.738371 14.3465 0.644603 14.4403C0.550834 14.534 0.498156 14.6612 0.498156 14.7938C0.498156 14.9264 0.550834 15.0536 0.644603 15.1474C0.738371 15.2412 0.865548 15.2938 0.998156 15.2938H9.74816C10.5512 15.2967 11.3459 15.1314 12.0812 14.8088C12.8165 14.4861 13.4762 14.0131 14.0177 13.4202C14.5593 12.8273 14.9708 12.1276 15.2258 11.3661C15.4807 10.6047 15.5735 9.7983 15.4982 8.99883Z"
        fill={color}
      />
      <path
        d="M2.79912 7.886L0.564115 4.756C0.514672 4.682 0.488281 4.595 0.488281 4.506C0.488281 4.417 0.514672 4.33 0.564115 4.256L2.79912 1.116C2.83739 1.06231 2.8859 1.01673 2.94186 0.981865C2.99783 0.947003 3.06013 0.923552 3.12519 0.912864C3.19025 0.902175 3.25678 0.90446 3.32096 0.919586C3.38513 0.934712 3.44568 0.962381 3.49912 1.001C3.5528 1.03927 3.59839 1.08779 3.63325 1.14375C3.66811 1.19971 3.69156 1.26201 3.70225 1.32707C3.71294 1.39213 3.71066 1.45867 3.69553 1.52284C3.6804 1.58701 3.65273 1.64756 3.61412 1.701L1.61412 4.501L3.61412 7.301C3.65273 7.35444 3.6804 7.41499 3.69553 7.47916C3.71066 7.54333 3.71294 7.60987 3.70225 7.67493C3.69156 7.73999 3.66811 7.80229 3.63325 7.85825C3.59839 7.91421 3.5528 7.96273 3.49912 8.001C3.44568 8.03962 3.38513 8.06729 3.32096 8.08241C3.25678 8.09754 3.19025 8.09983 3.12519 8.08914C3.06013 8.07845 2.99783 8.055 2.94186 8.02013C2.8859 7.98527 2.83739 7.93969 2.79912 7.886Z"
        fill={color}
      />
      <path
        d="M9.58 4.00082H1C0.867392 4.00082 0.740215 4.05349 0.646447 4.14726C0.552678 4.24103 0.5 4.36821 0.5 4.50082C0.5 4.63342 0.552678 4.7606 0.646447 4.85437C0.740215 4.94814 0.867392 5.00082 1 5.00082H9.58C10.807 4.98193 11.9958 5.42742 12.9082 6.24802C13.8206 7.06862 14.3892 8.2037 14.5 9.42582C14.5439 10.0762 14.4535 10.7287 14.2346 11.3427C14.0157 11.9567 13.6729 12.5192 13.2274 12.9951C12.7819 13.4711 12.2434 13.8503 11.6452 14.1093C11.047 14.3684 10.4019 14.5016 9.75 14.5008H1C0.867392 14.5008 0.740215 14.5535 0.646447 14.6473C0.552678 14.741 0.5 14.8682 0.5 15.0008C0.5 15.1334 0.552678 15.2606 0.646447 15.3544C0.740215 15.4481 0.867392 15.5008 1 15.5008H9.75C10.5539 15.5038 11.3496 15.3383 12.0856 15.015C12.8217 14.6917 13.4819 14.2178 14.0236 13.6238C14.5653 13.0298 14.9766 12.3289 15.2309 11.5662C15.4852 10.8035 15.5768 9.99607 15.5 9.19582C15.335 7.74918 14.6373 6.4158 13.5429 5.45544C12.4485 4.49508 11.0358 3.97653 9.58 4.00082Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4457_47599">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
