import { Box } from "@mui/material";
import cx from "classnames";

import { useCreatorCatalogCSViewContext } from "../context/creator-catalog-cs-view-context";
import { ContentCsCardItem } from "./content-cs-card-item/content-cs-card-item";
import styles from "./content-cs-collection.module.scss";
import { PreviewContentCsCardItem } from "./preview-content-cs-card-item/preview-content-cs-card-item";

export const ContentCsCollection = () => {
  const { creatorContentItems, view, isPreview } =
    useCreatorCatalogCSViewContext();

  return (
    <>
      <Box
        sx={{
          marginTop: "22px",
        }}
      >
        <Box
          className={cx({
            [styles.contentCollectionWrapper]: true,
            [styles.contentCollectionListView]: view === "list",
          })}
          sx={{
            marginBottom: "33px",
          }}
        >
          {isPreview ? (
            <>
              {[...Array(9)].map((_, index) => (
                <PreviewContentCsCardItem key={index} />
              ))}
            </>
          ) : (
            <>
              {creatorContentItems.map((content) => (
                <ContentCsCardItem content={content} key={content.id} />
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
