import { Box, Card, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/redux";
import { BitcoinTextIcon } from "../../../media/icons/bitcoin-text";
import { CurrenciesEnum } from "../../automatic-transfers/enums/currencies.enum";
import styles from "./calculation.module.scss";

interface CalculationProps {
  data: any;
}

export default function Calculation(props: CalculationProps) {
  const { data } = props;

  const { user } = useAppSelector((item) => item.userReducer);

  const rest = data.currency_transfers.reduce((prev: any, curr: any) => {
    if (curr.payment_type === "fixed") {
      return Number(prev) + Number(curr.amount);
    }
    if (curr.payment_type === "percentageOfTotal") {
      const amount =
        (Number(user?.wallets[data.currency]) *
          Number(curr.custom_amount.replace(/%/g, ""))) /
        100;

      return Number(prev) + amount;
    }
    return Number(prev) + Number(curr.amount);
  }, 0);

  const renderCurrency = (logo: CurrenciesEnum, error: boolean = false) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return (
          <span
            style={{ marginRight: "-2px", userSelect: "none", lineHeight: "0" }}
          >
            <BitcoinTextIcon
              width={19}
              height={19}
              color={error ? "#FF5252" : undefined}
            />
          </span>
        );
      case CurrenciesEnum.USD:
        return <span style={{ userSelect: "none", lineHeight: "0" }}>$</span>;
      case CurrenciesEnum.EURO:
        return <span style={{ userSelect: "none", lineHeight: "0" }}>€</span>;
      default:
        return <span style={{ userSelect: "none", lineHeight: "0" }}>-</span>;
    }
  };

  let remainingBalance = Number(user?.wallets[data.currency]);

  return (
    <div className={styles.cardWrapper}>
      <Card className={styles.card}>
        <Typography fontSize={20} color="#252733">
          Transfer calculation:
        </Typography>
        <Box className={styles.transactionsWrapper}>
          <Box className={styles.transaction}>
            <Typography color="#4C4B4C">Balance:</Typography>
            <Typography
              fontWeight={600}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              color="#252733"
            >
              {renderCurrency(data.currency)}
              {Number(user?.wallets[data.currency]).toFixed(2)}
            </Typography>
          </Box>

          {data.currency_transfers.length > 0 && (
            <Box>
              <Box>
                {data.currency_transfers.map(
                  (transaction: any, index: number) => {
                    let transferredAmount = 0;
                    let intendedAmount = 0;

                    if (transaction.payment_type === "fixed") {
                      intendedAmount = Number(transaction.amount);
                      transferredAmount = Math.min(
                        intendedAmount,
                        remainingBalance
                      );
                    } else if (
                      transaction.payment_type === "percentageOfTotal"
                    ) {
                      intendedAmount =
                        (Number(user?.wallets[data.currency]) *
                          Number(transaction.custom_amount.replace(/%/g, ""))) /
                        100;
                      transferredAmount = Math.min(
                        intendedAmount,
                        remainingBalance
                      );
                    }

                    remainingBalance -= transferredAmount;

                    if (
                      Number(user?.wallets[data.currency]) - rest < 0 &&
                      transferredAmount < intendedAmount
                    ) {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography color="#4C4B4C">
                            Transfer {index + 1}:
                          </Typography>
                          <Box>
                            <Typography
                              fontWeight={600}
                              sx={{
                                textDecoration: "line-through",
                                display: "flex",
                                alignItems: "center",
                              }}
                              color="error"
                            >
                              {"- "}
                              {renderCurrency(data.currency, true)}{" "}
                              {intendedAmount.toFixed(2)}
                            </Typography>
                            <Typography
                              fontWeight={600}
                              color="error"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {"- "}
                              {renderCurrency(data.currency, true)}{" "}
                              {transferredAmount.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography color="#4C4B4C">
                          Transfer {index + 1}:
                        </Typography>
                        <Typography
                          fontWeight={600}
                          color="error"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {"- "}
                          {renderCurrency(data.currency, true)}
                          {transferredAmount.toFixed(2)}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>
          )}
          <Box className={styles.line} />
          <Box className={styles.transaction}>
            <Typography color="#4C4B4C">Rest:</Typography>
            <Typography
              fontWeight={600}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              color="#252733"
            >
              {renderCurrency(data.currency)}
              {Number(user?.wallets[data.currency]) - rest < 0
                ? (0.0).toFixed(2)
                : (Number(user?.wallets[data.currency]) - rest).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
