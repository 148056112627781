import { Button, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";

import { RoleEnum } from "../../../../enums/role.enum";
import signUpShared from "../../sign-up-shared.module.scss";
import type { AgreementType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";
import styles from "./agreement.module.scss";

// eslint-disable-next-line no-shadow
enum AgreementBtnName {
  accept,
  decline,
}

type AgreementProp = {
  setAgreement: Dispatch<SetStateAction<AgreementType>>;
  isAgreementValid: boolean;
  role: RoleEnum;
  agreement: AgreementType;
};

export const Agreement: FC<AgreementProp> = ({
  setAgreement,
  agreement,
  isAgreementValid,
  role,
}) => {
  const onAgreementHandler = (btnName: AgreementBtnName) => {
    switch (btnName) {
      case AgreementBtnName.decline:
        setAgreement({ status: "valid", value: false });
        break;
      case AgreementBtnName.accept:
        setAgreement({ status: "valid", value: true });
        break;
      default:
        setAgreement({ status: "none", value: false });
    }
  };

  return (
    <SignUpCard
      status={isAgreementValid && agreement.value ? "valid" : "default"}
    >
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Access agreement
        </Typography>
        <Typography
          variant="caption"
          fontWeight={400}
          lineHeight="1.27rem"
          sx={{ fontSize: "14px" }}
          className={styles.agreement}
        >
          {role === RoleEnum.subscriber && (
            <>
              Between SideFans and the User Terms and Definitions SideFans: An
              online platform providing description of service. User: An
              individual or legal entity utilizing the services of SideFans.
              Terms of Use By registering on the SideFans website, the User
              agrees to comply with and be bound by the terms of this Agreement.
              The User acknowledges that SideFans is not responsible for
              liability extension, limitations. Confidentiality SideFans
              undertakes to maintain the confidentiality of the User&apos;s
              information. The User also agrees not to disclose confidential
              information from SideFans. Payment and Returns Payment terms are
              outlined in description of payment terms. The return policy is
              detailed in description of return policy. Intellectual Property
              All content on the SideFans website is protected by copyright and
              intellectual property laws. The User is not permitted to use,
              copy, or distribute any content from SideFans without prior
              permission. Changes to the Agreement SideFans reserves the right
              to make changes to this Agreement, notifying users in advance.
              Continued use of the SideFans website after changes indicates
              acceptance of the new terms of use. Final Provisions This
              Agreement constitutes a legal agreement between SideFans and the
              User, replacing all previous agreements. In case of conflicts or
              misunderstandings, the parties commit to resolving them through
              negotiations. For additional information and inquiries, please
              contact SideFans.
            </>
          )}
          {role === RoleEnum.creator && (
            <>
              Between SideFans and the User Terms and Definitions SideFans: An
              online platform providing description of service. User: An
              individual or legal entity utilizing the services of SideFans.
              Terms of Use By registering on the SideFans website, the User
              agrees to comply with and be bound by the terms of this Agreement.
              The User acknowledges that SideFans is not responsible for
              liability extension, limitations. Confidentiality SideFans
              undertakes to maintain the confidentiality of the User&apos;s
              information. The User also agrees not to disclose confidential
              information from SideFans. Payment and Returns Payment terms are
              outlined in description of payment terms. The return policy is
              detailed in description of return policy. Intellectual Property
              All content on the SideFans website is protected by copyright and
              intellectual property laws. The User is not permitted to use,
              copy, or distribute any content from SideFans without prior
              permission. Changes to the Agreement SideFans reserves the right
              to make changes to this Agreement, notifying users in advance.
              Continued use of the SideFans website after changes indicates
              acceptance of the new terms of use. Final Provisions This
              Agreement constitutes a legal agreement between SideFans and the
              User, replacing all previous agreements. In case of conflicts or
              misunderstandings, the parties commit to resolving them through
              negotiations. For additional information and inquiries, please
              contact SideFans.
            </>
          )}
        </Typography>
        <form
          className={`${signUpShared.cardForm} ${signUpShared.cardFormJustBtns}`}
          noValidate
        >
          <Button
            color={isAgreementValid && agreement.value ? "success" : "primary"}
            className={signUpShared.actionBtnStretch}
            variant="contained"
            type="button"
            onClick={() => onAgreementHandler(AgreementBtnName.accept)}
          >
            <Typography fontSize="16px">
              <span>Accept</span>
            </Typography>
          </Button>
          <Button
            color="error"
            className={signUpShared.actionBtnStretch}
            variant={
              isAgreementValid && !agreement.value ? "contained" : "outlined"
            }
            type="button"
            onClick={() => onAgreementHandler(AgreementBtnName.decline)}
          >
            <Typography fontSize="16px">
              <span>Decline</span>
            </Typography>
          </Button>
        </form>
      </div>
    </SignUpCard>
  );
};
