import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as Yup from "yup";

import { maxLengthOfFolderName } from "../../../../../../../constants/constants";
import { CrossIcon } from "../../../../../../../media/icons/cross";
import { ModalWrapper } from "../../../../../../creator-card/modal-wrapper/modal-wrapper";
import styles from "./folder-create-form.module.scss";

interface IFolderCreateFormProps {
  isOpen: boolean;
  isLoading: boolean;
  onSaveCreateClick: (folderName: string) => void;
  onCancelCreateClick: () => void;
}

export const FolderCreateForm: FC<IFolderCreateFormProps> = ({
  isOpen,
  isLoading,
  onSaveCreateClick,
  onCancelCreateClick,
}) => {
  const formik = useFormik<{ name: string }>({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable().max(maxLengthOfFolderName),
    }),
    onSubmit: async (values) => {
      onSaveCreateClick(values.name);
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <ModalWrapper
      shown={isOpen}
      close={onCancelCreateClick}
      disableBackdropClose
    >
      <Box className={styles.createWrapper}>
        <Box className={styles.create}>
          <Box className={styles.header}>
            <Typography fontSize="24px">New folder</Typography>
            <IconButton
              onClick={() => {
                onCancelCreateClick();
              }}
            >
              <CrossIcon />
            </IconButton>
          </Box>

          <form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className={styles.form}
          >
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                name="name"
                placeholder="Folder name"
                error={!!formik.errors.name}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <Typography
                variant="caption"
                align="right"
                color={formik.errors.name ? "#FF5252" : "#989898"}
                marginTop="3px"
              >
                {formik.values.name ? formik.values.name.length : 0}/
                {maxLengthOfFolderName}
              </Typography>
            </Box>
            <Box className={styles.btnInteraction}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                sx={{
                  width: "237px",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  <Typography variant="button">Save</Typography>
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
