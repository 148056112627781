import { Button, CircularProgress, Typography } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useRemoveTransferAccountMutation } from "../../../services/transfer-accounts/transfer-accounts.service";
import { transferAccountsReducer } from "../../../store/reducers/transfer-accounts/transfer-accounts";
import type { CollectedData } from "../../select-payment-method/interfaces/collected-data.interface";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import styles from "../transfer-accounts.module.scss";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => Promise<void> | void;
  isAutomaticTransferExists: boolean;
  collectedData: CollectedData;
};

type RenderModalArgs = {
  title: string;
  secondaryText: string;
  acceptButtonTitle: string;
  acceptButtonOnClick?: () => void;
  acceptButtonColor: "primary" | "error";
};

export const DeleteTransferAccountModal = ({
  isOpen,
  setIsOpen,
  collectedData,
  isAutomaticTransferExists,
}: Props) => {
  const { transfer_account: transferAccount } = collectedData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deleteTransferAccount } = transferAccountsReducer.actions;
  const [removeTransferAccount, { isLoading, isError }] =
    useRemoveTransferAccountMutation();

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const handleRemoveAutomaticTransfer = useCallback(async () => {
    if (transferAccount?.id) {
      await removeTransferAccount({ accessToken, id: transferAccount.id });

      if (!isError) {
        dispatch(deleteTransferAccount({ id: transferAccount.id }));
      }
      setIsOpen(false);
    }
  }, [transferAccount]);

  const renderModal = ({
    title,
    secondaryText,
    acceptButtonTitle,
    acceptButtonOnClick = () => {},
    acceptButtonColor,
  }: RenderModalArgs) => (
    <ModalDialog classes={styles.modal} isOpen={isOpen}>
      <ModalDialogHeader position="center">
        <Typography align="center" variant="h4" fontWeight={300}>
          {title}
        </Typography>
      </ModalDialogHeader>
      <ModalDialogContent>
        <div>
          <Typography align="center" variant="body1" fontWeight={400}>
            {secondaryText}
          </Typography>
        </div>
      </ModalDialogContent>
      <ModalDialogFooter className={styles.modalFooter}>
        <Button
          type="button"
          variant="contained"
          color={acceptButtonColor}
          onClick={acceptButtonOnClick}
          sx={{
            width: "218px",
          }}
        >
          <Typography fontSize="1rem">
            {isLoading ? <CircularProgress size="1.5rem" /> : acceptButtonTitle}
          </Typography>
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={() => setIsOpen(false)}
          sx={{
            backgroundColor: "transparent",
            width: "218px",
          }}
        >
          <Typography fontSize="1rem">Cancel</Typography>
        </Button>
      </ModalDialogFooter>
    </ModalDialog>
  );

  return isAutomaticTransferExists
    ? renderModal({
        title: "Important Notice",
        secondaryText:
          "You’ve scheduled automatic transfers to this account. " +
          "If you want to delete it, you must first remove it from your automatic transfers schedule.",
        acceptButtonTitle: "OK",
        acceptButtonColor: "primary",
        acceptButtonOnClick: () => navigate("/cc/automatic-transfers"),
      })
    : renderModal({
        title: "Delete Transfer Account",
        secondaryText: "Are you sure you want to delete transfer account?",
        acceptButtonTitle: "Delete",
        acceptButtonColor: "error",
        acceptButtonOnClick: handleRemoveAutomaticTransfer,
      });
};
