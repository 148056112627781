import { Box } from "@mui/material";
import classNames from "classnames";
import type React from "react";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../hooks/redux";
import ArrowLeftIcon from "../../../media/icons/creator-card/arrow-left";
import ArrowRightIcon from "../../../media/icons/creator-card/arrow-right";
import type { IUserAvatar } from "../../../services/sign-in/interfaces/initialize.interface";
import { GreetingMedia } from "./greeting-media.tsx/greeting-media";
import { GreetingVideoContextProvider } from "./greeting-media.tsx/gretting-content/greeting-video-context";
import styles from "./greetings-carousel.module.scss";

interface GreetingsCarouselProps {
  greetings: IUserAvatar[];
  toggleGreetings?: boolean;
  handleOpenCreatorCard?: () => void;
}

export const GreetingsCarousel: React.FC<GreetingsCarouselProps> = ({
  greetings,
  toggleGreetings = true,
  handleOpenCreatorCard,
}) => {
  const MAX_DOTS_COUNT: number = 5;

  const { user } = useAppSelector((item) => item.userReducer);
  const [currentGreeting, setCurrentGreeting] = useState<IUserAvatar | null>(
    greetings[0]
  );

  useEffect(() => {
    setCurrentGreeting(greetings[0]);
  }, [greetings]);

  const [currentGreetingIndex, setCurrentGreetingIndex] = useState<number>(0);

  const dotsCount = Math.min(MAX_DOTS_COUNT, greetings.length);

  const setPreviousGreeting = () => {
    const previousGreetingIndex =
      greetings.findIndex((item) => item === currentGreeting) - 1;
    if (previousGreetingIndex >= 0) {
      setCurrentGreeting(greetings[previousGreetingIndex]);
      setCurrentGreetingIndex(previousGreetingIndex);
    }
  };

  const setNextGreeting = () => {
    const nextGreetingIndex =
      greetings.findIndex((item) => item === currentGreeting) + 1;

    if (nextGreetingIndex !== 0 && nextGreetingIndex < greetings.length) {
      setCurrentGreeting(greetings[nextGreetingIndex]);
      setCurrentGreetingIndex(nextGreetingIndex);
    }
  };

  const isActiveDot = (index: number) => {
    let isActive = false;
    if (greetings.length > dotsCount && currentGreetingIndex >= dotsCount - 1) {
      isActive =
        currentGreetingIndex === greetings.length
          ? index === dotsCount - 1
          : index === dotsCount - 2;
    } else {
      isActive = currentGreetingIndex === index;
    }
    return isActive;
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {currentGreeting && (
        <Box onClick={handleOpenCreatorCard}>
          <GreetingVideoContextProvider
            showControls
            muted={!!user?.media_sound_muted}
            isPlaying={!!user?.media_play_automatically}
          >
            <GreetingMedia
              type={currentGreeting.type}
              mediaUrl={currentGreeting.backend_media_url || ""}
            />
          </GreetingVideoContextProvider>
        </Box>
      )}
      {toggleGreetings && (
        <Box className={styles.grettingNavigationButtons}>
          <button
            type="button"
            disabled={currentGreetingIndex === 0}
            className={styles.navigationButtonStyle}
            onClick={setPreviousGreeting}
          >
            <ArrowLeftIcon />
          </button>
          <div className={styles.carouselDots}>
            {Array.from(Array(dotsCount).keys()).map((i) =>
              currentGreetingIndex !== greetings.length - 1 ||
              i < dotsCount - 1 ? (
                <button
                  type="button"
                  key={i}
                  className={classNames(
                    styles.dot,
                    isActiveDot(i) && styles.active,
                    !isActiveDot(i) &&
                      i === dotsCount - 1 &&
                      currentGreetingIndex !== greetings.length - 1 &&
                      styles.smallDot
                  )}
                />
              ) : null
            )}
          </div>
          <button
            type="button"
            disabled={currentGreetingIndex === greetings.length - 1}
            className={styles.navigationButtonStyle}
            onClick={setNextGreeting}
          >
            <ArrowRightIcon />
          </button>
        </Box>
      )}
    </Box>
  );
};
