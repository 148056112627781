import { alpha } from "@mui/material/styles";

import type { Colors } from "./interfaces/color.interface";

const withAlphas = (color: Colors) => ({
  ...color,
  alpha4: alpha(color.main, 0.04),
  alpha8: alpha(color.main, 0.08),
  alpha12: alpha(color.main, 0.12),
  alpha30: alpha(color.main, 0.3),
  alpha50: alpha(color.main, 0.5),
});

export const neutral = {
  50: "#F8F9FA",
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D2D6DB",
  400: "#9DA4AE",
  500: "#6C737F",
  600: "#4D5761",
  700: "#2F3746",
  800: "#1C2536",
  900: "#111927",
};

export const indigo = withAlphas({
  lightest: "#5C8BE8",
  light: "#3A75E9",
  main: "#3A75E9",
  dark: "#1B5EE3",
  darkest: "#1B5EE3",
  contrastText: "#FFFFFF",
});

export const success = withAlphas({
  lightest: "#30D354",
  light: "#30D354",
  main: "#30D354",
  dark: "#30D354",
  darkest: "#30D354",
  contrastText: "#FFFFFF",
});

export const info = withAlphas({
  lightest: "#b4bfc0",
  light: "#9da5a6",
  main: "#878C8E",
  dark: "#767c7c",
  darkest: "#616565",
  contrastText: "#FFFFFF",
});

export const warning = withAlphas({
  lightest: "#FFFAEB",
  light: "#FEF0C7",
  main: "#F79009",
  dark: "#B54708",
  darkest: "#7A2E0E",
  contrastText: "#FFFFFF",
});

export const error = withAlphas({
  lightest: "#FF5252",
  light: "#FF5252",
  main: "#FF5252",
  dark: "#FF5252",
  darkest: "#FF5252",
  contrastText: "#FFFFFF",
});
