import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { PaymentHistoryInterface } from "../../../components/payment-info/payment-history/interfaces/payment-history.interface";

interface IPurchaseHistory {
  purchaseHistory: PaymentHistoryInterface[];
}

const initialState: IPurchaseHistory = {
  purchaseHistory: [],
};

export const purchaseHistorySlice = createSlice({
  name: "purchaseHistory",
  initialState,
  reducers: {
    setPurchaseHistory(
      state,
      action: PayloadAction<PaymentHistoryInterface[]>
    ) {
      state.purchaseHistory = action.payload;
    },
  },
});

export default purchaseHistorySlice.reducer;
