import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import {
  useCreateCollectionByMutation,
  useGetContentCatalogMeMutation,
  useGetUserCreatorByIdMutation,
  useUpdateCollectionByIdMutation,
  useUpdateContentByIdMutation,
} from "../../../../../services/catalog/catalog.service";
import {
  Content,
  ContentMedia,
} from "../../../../../services/catalog/interfaces/catalog.interfaces";
import {
  ICreateCollectionParams,
  IGetContentCatalogParams,
  IUpdateCollectionParams,
  IUpdateContentByIdsParams,
} from "../../../../../services/catalog/interfaces/request-models.interfaces";
import { useGetLastSearchNamesMutation } from "../../../../../services/home/home.service";
import {
  type ICreatorCardData,
  LastSearchType,
} from "../../../../../services/home/interfaces/home.interface";
import { IPersonalInfo } from "../../../../../services/settings/interfaces/settings.interface";
import { useGetSettingsMutation } from "../../../../../services/settings/settings.service";
import { lastSearchSlice } from "../../../../../store/reducers/last-search/last-search";
import { ActiveView } from "../../../../home/interfaces/temporary.interface";
import type { ActiveTab } from "../../interfaces/ActiveTab";

// Define the shape of the context
interface ICreatorCatalogCCViewType {
  // Sidebar
  personalInfoParamteres: IPersonalInfo | null;
  creatorCard: ICreatorCardData | null;
  setCreatorCard: Dispatch<SetStateAction<ICreatorCardData | null>>;

  // Search section
  view: ActiveView;
  setView: Dispatch<SetStateAction<ActiveView>>;

  // Catalog
  tab: ActiveTab | undefined;
  setTab: Dispatch<SetStateAction<ActiveTab | null>>;
  contentCatalogParams: IGetContentCatalogParams;
  updateCatalogParam: <K extends keyof IGetContentCatalogParams>(
    key: K,
    value: IGetContentCatalogParams[K]
  ) => void;
  creatorContentItems: Content[];

  selectedCreatorContentItems: Content[];
  setSelectedCreatorContentItems: Dispatch<SetStateAction<Content[]>>;

  contentEdit: Content[];
  setContentEdit: Dispatch<SetStateAction<Content[]>>;

  collectionMediaDisplay: ContentMedia[];
  setCollectionMediaDisplay: Dispatch<SetStateAction<ContentMedia[]>>;

  collectionMediaChanged: ContentMedia[];
  setCollectionMediaChanged: Dispatch<SetStateAction<ContentMedia[]>>;

  collectionEdit: Content | null;
  setCollectionEdit: Dispatch<SetStateAction<Content | null>>;

  isCollectionCreating: boolean;
  setIsCollectionCreating: Dispatch<SetStateAction<boolean>>;

  postsToDelete: string[];
  setPostsToDelete: Dispatch<SetStateAction<string[]>>;
  fetchContent: () => void;

  updateContent: (newValues: IUpdateContentByIdsParams) => void;
  isUpdateLoading: boolean;
  isUpdateSuccess: boolean;

  updateCollection: (newValues: IUpdateCollectionParams) => void;
  createCollection: (newValues: ICreateCollectionParams) => void;

  selectedItemsForCollection: Content[];
  setSelectedItemsForCollection: Dispatch<SetStateAction<Content[]>>;

  isFolderNavigation: boolean;
  setIsFolderNavigation: Dispatch<SetStateAction<boolean>>;
}

const CreatorCatalogCCViewContext = createContext<ICreatorCatalogCCViewType>(
  {} as ICreatorCatalogCCViewType
);

export const CreatorCatalogCCViewProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { setLastSearchItems } = lastSearchSlice.actions;

  const [tab, setTab] = useState<ActiveTab | null>("all_posts");
  const [creatorContentItems, setCreatorContentItems] = useState<Content[]>([]);
  const [selectedCreatorContentItems, setSelectedCreatorContentItems] =
    useState<Content[]>([]);
  const [personalInfoParamteres, setPersonalInfoParameters] =
    useState<IPersonalInfo | null>(null);
  const [creatorCard, setCreatorCard] = useState<ICreatorCardData | null>(null);

  const [contentEdit, setContentEdit] = useState<Content[]>([]);
  const [collectionEdit, setCollectionEdit] = useState<Content | null>(null);
  const [isCollectionCreating, setIsCollectionCreating] =
    useState<boolean>(false);

  const [collectionMediaDisplay, setCollectionMediaDisplay] = useState<
    ContentMedia[]
  >([]);

  const [selectedItemsForCollection, setSelectedItemsForCollection] = useState<
    Content[]
  >([]);
  const [isFolderNavigation, setIsFolderNavigation] = useState<boolean>(false);

  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

  const [postsToDelete, setPostsToDelete] = useState<string[]>([]);
  const [view, setView] = useState<ActiveView>("tile");

  const [collectionMediaChanged, setCollectionMediaChanged] = useState<
    ContentMedia[]
  >([]);

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { user } = useAppSelector((data) => data.userReducer);

  const [contentCatalogParams, setContentCatalogParams] =
    useState<IGetContentCatalogParams>({
      accessToken: userAccessToken != null ? userAccessToken : "",
      page: 1,
      rowsPerPage: 20,
      searchText: "",

      // Filters
      filters: {
        audience: [],
        show: [],
        contentType: [],
        visibility: [],
        contentDetails: [],
      },

      // Sorting
      sortBy: "all",
      direction: null,

      // Active tab
      currentTab: "all_posts",
    });

  const [getLastSearchNames, { data: getLastSearchNamesData }] =
    useGetLastSearchNamesMutation();

  const fetchLastSearchedList = useCallback(async () => {
    await getLastSearchNames({
      accessToken: userAccessToken || "",
      type: LastSearchType.MY_CATALOG_SEARCH,
    }).unwrap();
  }, [userAccessToken, getLastSearchNames]);

  useEffect(() => {
    if (getLastSearchNamesData?.data) {
      dispatch(setLastSearchItems(getLastSearchNamesData.data));
    }
  }, [getLastSearchNamesData]);

  // Generic function to update state properties
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCatalogParam = <K extends keyof IGetContentCatalogParams>(
    key: K,
    value: IGetContentCatalogParams[K]
  ) => {
    setContentCatalogParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const [getContentCatalogMe] = useGetContentCatalogMeMutation();
  const [getSettings] = useGetSettingsMutation();
  const [getUserCreatorById] = useGetUserCreatorByIdMutation();
  const [
    updateSingleContent,
    { isLoading: contentUpdateLoading, isSuccess: contentUpdateSuccess },
  ] = useUpdateContentByIdMutation();

  const [
    updateSingleCollection,
    { isLoading: collectionUpdateLoading, isSuccess: collectionUpdateSuccess },
  ] = useUpdateCollectionByIdMutation();

  const [
    createSingleCollection,
    { isLoading: collectionCreateLoading, isSuccess: collectionCreateSuccess },
  ] = useCreateCollectionByMutation();

  const fetchContentCatalogMe = useCallback(async () => {
    await getContentCatalogMe(contentCatalogParams)
      .unwrap()
      .then((data) => {
        setCreatorContentItems(data.data);
        fetchLastSearchedList();
      })
      .catch(() => {
        setCreatorContentItems((prevState) => [...prevState]);
      });
  }, [contentCatalogParams, getContentCatalogMe]);

  useEffect(() => {
    setIsUpdateLoading(contentUpdateLoading);
  }, [contentUpdateLoading]);

  useEffect(() => {
    setIsUpdateLoading(collectionUpdateLoading);
  }, [collectionUpdateLoading]);

  useEffect(() => {
    setIsUpdateLoading(collectionCreateLoading);
  }, [collectionCreateLoading]);

  useEffect(() => {
    if (contentUpdateSuccess) {
      setIsUpdateSuccess(contentUpdateSuccess);
      setSelectedCreatorContentItems([]);
      setPostsToDelete([]);
      setCollectionEdit(null);
    }
  }, [contentUpdateSuccess]);

  useEffect(() => {
    if (collectionCreateSuccess) {
      setIsUpdateSuccess(collectionCreateSuccess);
      setSelectedCreatorContentItems([]);
      setCollectionMediaChanged([]);
      setIsCollectionCreating(false);
      setPostsToDelete([]);
    }
  }, [collectionCreateSuccess]);

  useEffect(() => {
    if (collectionUpdateSuccess) {
      setIsUpdateSuccess(collectionUpdateSuccess);
      setSelectedCreatorContentItems([]);
      setCollectionMediaChanged([]);
      setPostsToDelete([]);
      setCollectionEdit(null);
    }
  }, [collectionUpdateSuccess]);

  const fetchPersonalInfo = useCallback(async () => {
    await getSettings({
      accessToken: userAccessToken != null ? userAccessToken : "",
      fullSocialLinks: true,
      userId: user?.id || "",
    })
      .unwrap()
      .then((data: { data: IPersonalInfo | null }) => {
        if (data.data !== null) {
          setPersonalInfoParameters(data.data);
        }
      })
      .catch(() => {
        setPersonalInfoParameters((prevState) => prevState);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userAccessToken, setPersonalInfoParameters, getSettings]);

  useEffect(() => {
    updateCatalogParam("currentTab", tab || null);
  }, [tab]);

  const fetchUserCreatorById = useCallback(async () => {
    await getUserCreatorById({
      accessToken: userAccessToken != null ? userAccessToken : "",
      id: user !== null ? user.id : "",
    })
      .unwrap()
      .then((data) => {
        setCreatorCard(data.data.creator);
      })
      .catch(() => {
        setCreatorCard((prevState) => prevState);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessToken]);

  useEffect(() => {
    if (
      contentCatalogParams.sortBy !== "folders/desc" &&
      contentCatalogParams.sortBy !== "folders/asc"
    ) {
      fetchContentCatalogMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCatalogParams]);

  useEffect(() => {
    fetchPersonalInfo();
    fetchUserCreatorById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchContent = useCallback(() => {
    fetchContentCatalogMe();
  }, [fetchContentCatalogMe]);

  const updateContent = useCallback(
    async (updatedValues: IUpdateContentByIdsParams) => {
      await updateSingleContent(updatedValues);
    },
    []
  );

  const updateCollection = useCallback(
    async (updatedValues: IUpdateCollectionParams) => {
      await updateSingleCollection(updatedValues);
    },
    []
  );

  const createCollection = useCallback(
    async (newCollectionValues: ICreateCollectionParams) => {
      await createSingleCollection(newCollectionValues);
    },
    []
  );

  useEffect(() => {
    if (isUpdateSuccess) {
      fetchContentCatalogMe();
      setIsUpdateSuccess(false);
    }
  }, [isUpdateSuccess]);

  const contextValues = useMemo(
    () => ({
      tab,
      setTab,
      contentCatalogParams,
      updateCatalogParam,
      createCollection,
      creatorContentItems,
      personalInfoParamteres,
      creatorCard,
      setCreatorCard,
      selectedCreatorContentItems,
      setSelectedCreatorContentItems,
      contentEdit,
      setContentEdit,
      postsToDelete,
      setPostsToDelete,
      fetchContent,
      updateContent,
      isUpdateLoading,
      isUpdateSuccess,
      setCollectionEdit,
      collectionEdit,
      updateCollection,
      setCollectionMediaDisplay,
      collectionMediaDisplay,
      setCollectionMediaChanged,
      collectionMediaChanged,
      setSelectedItemsForCollection,
      selectedItemsForCollection,
      setIsCollectionCreating,
      isCollectionCreating,
      setIsFolderNavigation,
      isFolderNavigation,
      setView,
      view,
    }),
    [
      tab,
      setTab,
      contentCatalogParams,
      updateCatalogParam,
      createCollection,
      creatorContentItems,
      personalInfoParamteres,
      creatorCard,
      setCreatorCard,
      selectedCreatorContentItems,
      setSelectedCreatorContentItems,
      contentEdit,
      setContentEdit,
      postsToDelete,
      setPostsToDelete,
      fetchContent,
      updateContent,
      isUpdateLoading,
      isUpdateSuccess,
      setCollectionEdit,
      collectionEdit,
      updateCollection,
      setCollectionMediaDisplay,
      collectionMediaDisplay,
      setCollectionMediaChanged,
      collectionMediaChanged,
      setSelectedItemsForCollection,
      selectedItemsForCollection,
      setIsCollectionCreating,
      isCollectionCreating,
      setIsFolderNavigation,
      isFolderNavigation,
      setView,
      view,
    ]
  );

  return (
    <CreatorCatalogCCViewContext.Provider value={contextValues}>
      {children}
    </CreatorCatalogCCViewContext.Provider>
  );
};

// Custom hook to use the CreatorCatalogCCViewContext
export const useCreatorCatalogCCViewContext = () =>
  useContext(CreatorCatalogCCViewContext);
