import type { FC, PropsWithChildren } from "react";

interface IListIconProp {
  width?: number;
  height?: number;
  color?: string;
}
export const ListIcon: FC<PropsWithChildren<IListIconProp>> = ({
  width = 32,
  height = 32,
  color = "#AAAAAA",
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.48807 0C0.666326 0 0 0.666304 0 1.48805V2.97465C0 3.79639 0.666326 4.4627 1.48807 4.4627H2.97465C3.79639 4.4627 4.46272 3.79639 4.46272 2.97465V1.48805C4.46272 0.666304 3.79639 0 2.97465 0H1.48807ZM1.48807 7.43735C0.666326 7.43735 0 8.1022 0 8.92394V10.412C0 11.2337 0.666326 11.8986 1.48807 11.8986H2.97465C3.79639 11.8986 4.46272 11.2337 4.46272 10.412V8.92394C4.46272 8.1022 3.79639 7.43735 2.97465 7.43735H1.48807ZM1.48807 14.8732C0.666326 14.8732 0 15.5395 0 16.3613V17.8479C0 18.6696 0.666326 19.3359 1.48807 19.3359H2.97465C3.79639 19.3359 4.46272 18.6696 4.46272 17.8479V16.3613C4.46272 15.5395 3.79639 14.8732 2.97465 14.8732H1.48807ZM7.43737 0C6.61562 0 5.9493 0.666304 5.9493 1.48805V2.97465C5.9493 3.79639 6.61562 4.4627 7.43737 4.4627H22.3084C23.1309 4.4627 23.7972 3.79639 23.7972 2.97465V1.48805C23.7972 0.666304 23.1309 0 22.3084 0H7.43737ZM7.43737 7.43735C6.61562 7.43735 5.9493 8.1022 5.9493 8.92394V10.412C5.9493 11.2337 6.61562 11.8986 7.43737 11.8986H22.3084C23.1309 11.8986 23.7972 11.2337 23.7972 10.412V8.92394C23.7972 8.1022 23.1309 7.43735 22.3084 7.43735H7.43737ZM7.43737 14.8732C6.61562 14.8732 5.9493 15.5395 5.9493 16.3613V17.8479C5.9493 18.6696 6.61562 19.3359 7.43737 19.3359H22.3084C23.1309 19.3359 23.7972 18.6696 23.7972 17.8479V16.3613C23.7972 15.5395 23.1309 14.8732 22.3084 14.8732H7.43737Z"
        fill={color}
      />
    </svg>
  </>
);
