import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import {
  useGetBlockerUsersMutation,
  useUnblockUserMutation,
} from "../../../../services/block-users/block-users";
import type { IGetBlockedUsers } from "../../../../services/block-users/interfaces/block-users.interface";
import styles from "./blocked-users.module.scss";

export const BlockedUsers = () => {
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const [blockedUsers, setBlockedUsers] = useState<IGetBlockedUsers[]>([]);
  const [unblockUser, { isLoading: isUnblockLoading }] =
    useUnblockUserMutation();
  const [
    getBlockedUsers,
    { data: blockedUsersResponse, isLoading: isBlockedUsersListLoading },
  ] = useGetBlockerUsersMutation();

  const onUnblock = async (userId: string) => {
    const response = await unblockUser({ accessToken, id: userId }).unwrap();
    if (response.success) {
      setBlockedUsers((prevState) =>
        prevState.filter((user) => user.blocked_user.id !== userId)
      );
    }
  };

  useEffect(() => {
    getBlockedUsers({ accessToken }).unwrap();
  }, []);

  useEffect(() => {
    if (blockedUsersResponse) {
      setBlockedUsers(blockedUsersResponse.data);
    }
  }, [blockedUsersResponse]);

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.title}>
        <Typography variant="body1" fontWeight="bold">
          Blocked users
        </Typography>
        <Typography variant="body1">
          These users are blocked from tagging you. They’re never blocked from
          subscribing or purchasing premium content.
        </Typography>
      </Box>
      <Box className={styles.list}>
        {isBlockedUsersListLoading ? (
          <LinearProgress sx={{ width: "100%" }} />
        ) : (
          <>
            {blockedUsers.map((user) => (
              <Box className={styles.userWrapper} key={user.blocked_user.id}>
                <Box className={styles.userInfo}>
                  <Avatar
                    alt={user.blocked_user.username}
                    src={user.blocked_user.avatar?.backend_media_url || ""}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography variant="body1">
                    {user.blocked_user.username}
                  </Typography>
                </Box>

                <Button
                  variant="outlined"
                  onClick={() => onUnblock(user.blocked_user.id)}
                  sx={{
                    width: "108px",
                  }}
                >
                  <Typography variant="button">
                    {isUnblockLoading ? (
                      <CircularProgress color="inherit" size="1rem" />
                    ) : (
                      <span>Unblock</span>
                    )}
                  </Typography>
                </Button>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
