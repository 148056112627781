import { Button, Typography } from "@mui/material";
import { type Dispatch, type FC, type SetStateAction, useEffect } from "react";

import type { CollectedData } from "../../../select-payment-method/interfaces/collected-data.interface";
import signUpShared from "../../sign-up-shared.module.scss";
import type { PurchaseContentType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

// eslint-disable-next-line no-shadow
enum PurchaseBtnName {
  yes,
  later,
}

type PurchaseContentProp = {
  setPurchaseContent: Dispatch<SetStateAction<PurchaseContentType>>;
  purchaseContent: PurchaseContentType;
  isPurchaseContentValid: boolean;
  setOpenPaymentMethod: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  collectedData: CollectedData;
  setCollectedData: (
    value: CollectedData | ((prevVar: CollectedData) => CollectedData)
  ) => void;
  setSelectedStep: (value: string | ((prevVar: string) => string)) => void;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
};

export const PurchaseContent: FC<PurchaseContentProp> = ({
  setPurchaseContent,
  isPurchaseContentValid,
  purchaseContent,
  setOpenPaymentMethod,
  collectedData,
  setCollectedData,
  setSelectedStep,
  setSelectedMethods,
}) => {
  const deleteKeysFromObject = () => {
    const keysToDelete = ["credit_card", "bank_account", "crypto"];
    const cleanedData = { ...collectedData };
    keysToDelete.forEach((key: any) => {
      if (Object.prototype.hasOwnProperty.call(cleanedData, key)) {
        delete (cleanedData as any)[key];
      }
    });

    setCollectedData(cleanedData);
    setSelectedStep("");
    setSelectedMethods([]);
  };

  const onPurchaseHandler = (btnName: PurchaseBtnName) => {
    switch (btnName) {
      case PurchaseBtnName.yes:
        setPurchaseContent({ status: "valid", value: true, isLater: false });
        setOpenPaymentMethod(true);
        break;
      case PurchaseBtnName.later:
        setPurchaseContent({ status: "valid", value: false, isLater: true });
        deleteKeysFromObject();
        break;
      default:
        setPurchaseContent({
          status: "none",
          value: false,
          isLater: false,
        });
    }
  };

  useEffect(() => {
    deleteKeysFromObject();
  }, []);

  return (
    <SignUpCard status={isPurchaseContentValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Do you want to purchase content?
        </Typography>
        <form
          className={`${signUpShared.cardForm} ${signUpShared.cardFormJustBtns}`}
          noValidate
          style={{ paddingRight: "50px" }}
        >
          <Button
            color={
              purchaseContent.value && isPurchaseContentValid
                ? "success"
                : "primary"
            }
            className={signUpShared.actionBtnStretch}
            variant="contained"
            type="button"
            onClick={() => onPurchaseHandler(PurchaseBtnName.yes)}
          >
            <Typography variant="button">Yes!</Typography>
          </Button>
          <Button
            color={
              !purchaseContent.value && isPurchaseContentValid
                ? "success"
                : "primary"
            }
            className={signUpShared.actionBtnStretch}
            variant="outlined"
            type="button"
            onClick={() => onPurchaseHandler(PurchaseBtnName.later)}
          >
            <Typography variant="button">Maybe later</Typography>
          </Button>
        </form>
      </div>
    </SignUpCard>
  );
};
