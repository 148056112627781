import { Box, Tooltip, tooltipClasses } from "@mui/material";

import { InfoIcon } from "../../../../media/icons/info";

export const FrequencyTooltip = () => (
  <Tooltip
    title="If you select the 31st for automatic transfers and some months have only 30 days, the transfer will occur on the last day of those months instead."
    placement="top-end"
    arrow
    slotProps={{
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*="top-end"] .${tooltipClasses.tooltip}`]:
            {
              right: -15,
              bottom: 15,
              width: "240px",
              padding: "10px",
              marginBottom: "7px",
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "rgba(76, 75, 76, 1)",
              fontSize: "14px",
              boxShadow: "0px 5px 8px 0px rgba(95, 92, 84, 0.2)",
            },
          [`&.${tooltipClasses.popper}[data-popper-placement*="top-end"] .${tooltipClasses.arrow}`]:
            {
              bottom: -8,
              marginLeft: "-8px",
              width: "10px",
              height: "20px",
              borderLeft: "13px solid transparent",
              borderRight: "13px solid transparent",
              borderTop: "20px solid rgba(255, 255, 255, 1)",
              "&::before": {
                display: "none",
              },
            },
        },
      },
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ cursor: "pointer" }}
    >
      <InfoIcon width={18} height={18} />
    </Box>
  </Tooltip>
);
