export const RefreshIcon = ({
  width = 20,
  height = 20,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0798 8.2979C16.2441 9.84336 15.8636 11.3977 15.0039 12.6924C14.1442 13.9872 12.8593 14.9411 11.3712 15.3893C9.88309 15.8376 8.28514 15.7521 6.85334 15.1476C5.42155 14.5431 4.24581 13.4576 3.52918 12.0785C2.81256 10.6994 2.60005 9.11333 2.92835 7.59422C3.25666 6.07512 4.10516 4.71836 5.32732 3.75827C6.54949 2.79818 8.06857 2.29504 9.62221 2.33575C11.1758 2.37645 12.6665 2.95845 13.8367 3.98123L12.657 3.98765C12.4359 3.98829 12.2242 4.0767 12.0684 4.23343C11.9126 4.39017 11.8254 4.60238 11.826 4.8234C11.8267 5.04441 11.9151 5.25612 12.0718 5.41194C12.2286 5.56777 12.4408 5.65495 12.6618 5.65431H12.6664L15.671 5.63806C15.8919 5.63685 16.1033 5.54795 16.2587 5.39089C16.4141 5.23384 16.5008 5.0215 16.4997 4.80056L16.483 1.79606C16.4865 1.68548 16.4671 1.57537 16.4261 1.47263C16.385 1.36988 16.3232 1.2767 16.2446 1.19891C16.1659 1.12111 16.072 1.06038 15.9688 1.0205C15.8656 0.980627 15.7553 0.962462 15.6448 0.967146C15.4238 0.968446 15.2125 1.05744 15.0571 1.21455C14.9018 1.37166 14.8152 1.58404 14.8164 1.80498L14.8207 2.63523C13.342 1.38981 11.4759 0.69788 9.54271 0.678167C7.60951 0.658455 5.72971 1.31219 4.22591 2.52719C2.7221 3.7422 1.68805 5.44271 1.30119 7.33691C0.914341 9.23112 1.19881 11.2009 2.10579 12.9083C3.01276 14.6156 4.48569 15.9541 6.27181 16.694C8.05794 17.4338 10.0459 17.529 11.8945 16.9631C13.7432 16.3973 15.3372 15.2056 16.4031 13.5927C17.4691 11.9798 17.9404 10.0462 17.7363 8.12373C17.7277 8.01271 17.6969 7.90455 17.6458 7.80562C17.5947 7.7067 17.5242 7.61903 17.4387 7.54778C17.3531 7.47653 17.2541 7.42315 17.1476 7.3908C17.041 7.35845 16.9291 7.34778 16.8183 7.35942C16.7076 7.37106 16.6003 7.40477 16.5028 7.45857C16.4053 7.51237 16.3196 7.58516 16.2507 7.67265C16.1818 7.76013 16.1312 7.86054 16.1018 7.96793C16.0723 8.07532 16.0651 8.18751 16.0798 8.2979Z"
      fill={color}
    />
  </svg>
);
