import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { MESSAGE_TIMEOUT } from "../../../../constants/constants";
import type { ICheckEmailFail } from "../../../../services/sign-up/interfaces/check-email-response.interface";
import { useCheckEmailMutation } from "../../../../services/sign-up/sign-up.service";
import signUpShared from "../../sign-up-shared.module.scss";
import { getColorOfState } from "../../sign-up.utils";
import type {
  ConfirmEmailType,
  CreateEmailType,
} from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

type CreateEmailProp = {
  setEmail: Dispatch<SetStateAction<CreateEmailType>>;
  isEmailValid: boolean;
  email: CreateEmailType;
  invitedUserEmail: string | null;
  setConfirmEmail: Dispatch<SetStateAction<ConfirmEmailType>>;
};

export const CreateEmail: FC<CreateEmailProp> = ({
  setEmail,
  isEmailValid,
  email,
  invitedUserEmail,
  setConfirmEmail,
}) => {
  const [isMessageVisible, setIsMessageVisible] = useState(true);
  const [checkEmail, { error, isSuccess, isLoading }] = useCheckEmailMutation();
  const formik = useFormik({
    initialValues: {
      email: email.value,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(
        "Sorry, that email address appears to be invalid. Maybe there’s a typo? Please fix it or enter a different address"
      ),
    }),
    onSubmit: async (values: { email: string }) => {
      await checkEmail(values.email).unwrap();
    },
  });

  useEffect(() => {
    if (invitedUserEmail) {
      setEmail({ status: "valid", value: invitedUserEmail });
      setConfirmEmail({ status: "valid", value: invitedUserEmail });
      formik.setValues({ email: invitedUserEmail });
    }
  }, []);

  useEffect(() => {
    if (error) {
      setEmail({ status: "invalid", value: "" });

      const err = error as ICheckEmailFail;
      let errorMessage = "";

      switch (err.status) {
        case 409:
          errorMessage = "Account already exists.";
          break;
        case 400:
          errorMessage =
            "Sorry, that email address appears to be invalid. Maybe there’s a typo? Please fix it or enter a different address";
          break;
        default:
          errorMessage = "Something went wrong. Please try again!";
      }

      formik.setErrors({
        email: errorMessage,
      });
    }
  }, [error]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isSuccess) {
      setIsMessageVisible(true);
      setEmail({ status: "valid", value: formik.values.email });
      timeoutId = setTimeout(() => {
        setIsMessageVisible(false);
      }, MESSAGE_TIMEOUT);
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isSuccess]);

  const onChangeClick = useCallback(() => {
    formik.resetForm();
    setEmail({ status: "none", value: "" });
  }, []);

  return (
    <SignUpCard status={isEmailValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Enter your email address:
        </Typography>
        <Typography variant="body2">
          We’ll only use it to verify that it’s really you, and to keep your
          account secure. We won’t spam you, and we won’t ever sell this
          information.
        </Typography>
        <form
          className={signUpShared.cardForm}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <TextField
            placeholder="Enter email"
            color={getColorOfState(
              isLoading,
              formik.errors.email,
              isEmailValid
            )}
            error={!!(formik.touched.email && formik.errors.email)}
            fullWidth
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            disabled={isEmailValid}
            variant="outlined"
            InputProps={{ sx: { borderRadius: "4px" } }}
          />
          {isEmailValid
            ? !invitedUserEmail && (
                <Button
                  color="success"
                  className={signUpShared.actionBtn}
                  variant="contained"
                  type="button"
                  onClick={() => onChangeClick()}
                >
                  {isLoading && (
                    <CircularProgress color="inherit" size="1rem" />
                  )}
                  <Typography variant="button">Change</Typography>
                </Button>
              )
            : !invitedUserEmail && (
                <Button
                  color="primary"
                  className={signUpShared.actionBtn}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && (
                    <CircularProgress color="inherit" size="1rem" />
                  )}
                  <Typography variant="button">Submit</Typography>
                </Button>
              )}
        </form>
      </div>
      {isSuccess && isEmailValid && isMessageVisible && (
        <Typography variant="caption" color="success.main" marginTop="4px">
          Great! We sent you an email with a code
        </Typography>
      )}
      {formik.errors.email && (
        <Typography color="error" variant="caption" marginTop="4px">
          {formik.errors.email}
        </Typography>
      )}
    </SignUpCard>
  );
};
