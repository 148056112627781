import type { ITableHead } from "../../interfaces/table-head.interface";

export const subscriptionsTableHead: ITableHead[] = [
  {
    display_text: "Creator",
    value: "creator.username",
    sortable: true,
    default_sort: false,
    headWidth: 20,
  },
  {
    display_text: "Subscriptions name",
    value: "subscription_name",
    sortable: false,
    default_sort: false,
    headWidth: 20,
  },
  {
    display_text: "Price",
    value: "plan",
    sortable: true,
    default_sort: false,
    headWidth: 20,
  },
  {
    display_text: "Renews On",
    value: "expires_at",
    sortable: true,
    default_sort: true,
    headWidth: 20,
  },
  {
    display_text: "",
    value: "cancel_subscription",
    sortable: false,
    default_sort: false,
    headWidth: 20,
  },
];
