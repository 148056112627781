import { Avatar, Badge, Box, Typography } from "@mui/material";
import type React from "react";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../hooks/redux";
import ButtonNextIcon from "../../media/icons/creator-card/bottom-next-icon";
import SeeAllOfMeNextIcon from "../../media/icons/creator-card/see-all-of-me-next-icon";
import TopPreviousIcon from "../../media/icons/creator-card/top-previous-icon";
import { CrossIcon } from "../../media/icons/cross";
import FavouriteIcon from "../../media/icons/favourite";
import FavouritedIcon from "../../media/icons/favourited";
import { MediaImageIcon } from "../../media/icons/media-image";
import { ProfileIcon } from "../../media/icons/profile";
import { CrownIcon } from "../../media/icons/solid-crown";
import {
  useBecomeInterestedMutation,
  useStopBeInterestedMutation,
} from "../../services/home/home.service";
import { ICreatorCardData } from "../../services/home/interfaces/home.interface";
import type { IUserAvatar } from "../../services/sign-in/interfaces/initialize.interface";
import styles from "./creator-card.module.scss";
import { GreetingVideoContextProvider } from "./greetings-carousel/greeting-media.tsx/gretting-content/greeting-video-context";
import { GreetingsCarousel } from "./greetings-carousel/greetings-carousel";

interface CreatorCardProps {
  handleCloseCreatorCard: () => void;
  currentCard: ICreatorCardData | null;
  setCurrentCard?: Dispatch<SetStateAction<ICreatorCardData | null>>;
  isRenderRank?: boolean;

  // You have to set isCreatorsSwitchEnabled as true to have ability use handleSetPreviousCard and handleSetNextCard
  isCreatorsSwitchEnabled?: boolean;
  handleSetPreviousCard?: () => void;
  handleSetNextCard?: () => void;

  allowBeInterested?: boolean;
  isPreview?: boolean;
}

export const CreatorCard: React.FC<CreatorCardProps> = ({
  handleCloseCreatorCard,
  currentCard,
  setCurrentCard,
  isRenderRank = false,
  handleSetPreviousCard,
  handleSetNextCard,
  isCreatorsSwitchEnabled,
  allowBeInterested,
  isPreview = false,
}) => {
  const { user } = useAppSelector((item) => item.userReducer);
  const navigate = useNavigate();
  const [becomeInterested] = useBecomeInterestedMutation();
  const [stopBeInterested] = useStopBeInterestedMutation();

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const handleInterested = useCallback(async () => {
    if (!setCurrentCard) {
      return;
    }
    setCurrentCard((prevState) => {
      if (!prevState) return prevState;
      return { ...prevState, is_interested: !prevState.is_interested };
    });

    if (currentCard?.is_interested) {
      await stopBeInterested({
        accessToken: userAccessToken || "",
        id: currentCard?.id || "",
      }).unwrap();
    } else {
      await becomeInterested({
        accessToken: userAccessToken || "",
        id: currentCard?.id || "",
      }).unwrap();
    }
  }, [becomeInterested, currentCard, userAccessToken, setCurrentCard]);

  const renderCrown = useCallback(() => {
    let color = "";

    switch (currentCard?.rank) {
      case 1:
        color = "#DBA621";
        break;
      case 2:
        color = "#C0C0C0";
        break;
      case 3:
        color = "#D67539";
        break;
      default:
        return (
          <Box position="relative">
            <CrownIcon color="#DBA621" isSolid={false} />
            <Typography
              fontSize="14px"
              className={styles.order}
              color="#4C4B4C"
              fontWeight="bold"
            >
              {currentCard?.rank}
            </Typography>
          </Box>
        );
    }

    return (
      <Box position="relative">
        <CrownIcon color={color} isSolid />
        <Typography fontSize="14px" className={styles.order} color="#fff">
          {currentCard.rank}
        </Typography>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard]);

  function goToNextCard(): void {
    if (handleSetNextCard) {
      handleSetNextCard();
    }
  }

  function goToPreviousCard(): void {
    if (handleSetPreviousCard) {
      handleSetPreviousCard();
    }
  }

  const seeAllOfMeHandler = () => {
    handleCloseCreatorCard();

    if (currentCard?.id === user?.id) {
      navigate(`/cc/profile`);
    } else {
      navigate(`/user-profile/${currentCard?.id}`);
    }
  };

  return (
    <div className={styles.cardWrapper}>
      <Box className={`${styles.card} ${isPreview && styles.cardPreview}`}>
        <Box className={styles.header}>
          {currentCard?.avatar ? (
            <Avatar
              alt={currentCard.username}
              src={currentCard.avatar.backend_media_url}
              sx={{ width: 56, height: 56, border: "1px solid #D9D9D9" }}
            />
          ) : (
            <Avatar
              className={styles.noAvatar}
              sx={{ background: "#fff", width: "60px", height: "60px" }}
            >
              <ProfileIcon width={34} height={34} color="#3A75E9" />
            </Avatar>
          )}
          <Box className={styles.info}>
            <Box className={styles.infoHeader}>
              <Typography fontSize="18px" marginTop="-11px" fontWeight="bold">
                {currentCard?.username}
              </Typography>
              {!!currentCard?.new_content_count && !isPreview && (
                <Badge
                  badgeContent={`+${currentCard?.new_content_count}`}
                  color="error"
                  max={9999}
                  sx={{
                    marginBottom: "10px",
                    marginRight: "35px",
                  }}
                />
              )}
              {isRenderRank || (!isPreview && renderCrown())}
            </Box>
            <Box className={styles.infoFooter}>
              <Typography fontSize="16px" color="#575757">
                Posts:{" "}
                {isPreview ? (
                  <span>28</span>
                ) : (
                  <span>
                    {currentCard?.number_of_posts
                      ? currentCard.new_content_count
                      : 0}
                  </span>
                )}
              </Typography>
              <Typography fontSize="16px" color="#575757">
                Subscribers:{" "}
                {isPreview ? (
                  <span>4.5k</span>
                ) : (
                  <span>{currentCard?._count.subscribers}</span>
                )}
              </Typography>
              <Typography fontSize="16px" color="#575757">
                Rank:{" "}
                {isPreview ? (
                  <span>19 of 100</span>
                ) : (
                  <span>
                    {currentCard?.rank} of {currentCard?.creators_amount}
                  </span>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.file}>
          {currentCard?.greetings &&
          (currentCard?.greetings?.length > 0 || currentCard.avatar) ? (
            <GreetingVideoContextProvider
              showControls={false}
              muted={!!user?.media_sound_muted}
              isPlaying={!!user?.media_play_automatically}
            >
              <GreetingsCarousel
                greetings={
                  currentCard.greetings.length > 0
                    ? currentCard.greetings
                    : [currentCard.avatar as IUserAvatar]
                }
                toggleGreetings={currentCard.greetings.length > 1}
              />
            </GreetingVideoContextProvider>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "575px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)",
              }}
            >
              <MediaImageIcon color="#fff" width={128} height={128} />
            </Box>
          )}
        </Box>
        {!isPreview && (
          <Box className={styles.modalFooter}>
            {allowBeInterested && (
              <button
                className={styles.interestedButton}
                type="button"
                onClick={handleInterested}
              >
                {currentCard?.is_interested ? (
                  <FavouritedIcon width="24" height="24" />
                ) : (
                  <FavouriteIcon color="#FFFFFF" width="24" height="24" />
                )}
                <Typography className={styles.button}>Interested</Typography>
              </button>
            )}
            <button
              className={styles.seeAllButton}
              type="button"
              onClick={seeAllOfMeHandler}
            >
              <Typography className={styles.button}>See all of me</Typography>
              <SeeAllOfMeNextIcon />
            </button>
          </Box>
        )}
      </Box>
      {isCreatorsSwitchEnabled && !isPreview && (
        <Box className={styles.cardNavigationButtons}>
          <button
            type="button"
            className={styles.navigationButtonStyle}
            onClick={goToPreviousCard}
          >
            <TopPreviousIcon />
          </button>
          <button
            type="button"
            className={styles.navigationButtonStyle}
            onClick={goToNextCard}
          >
            <ButtonNextIcon />
          </button>
          <Box
            sx={{
              position: "absolute",
              fontSize: "100px",
              top: "0",
              right: "-100%",
              cursor: "pointer",
            }}
            onClick={handleCloseCreatorCard}
          >
            <CrossIcon width={22} height={22} />
          </Box>
        </Box>
      )}
    </div>
  );
};
