import { FC, ReactNode, createContext, useContext, useMemo } from "react";

interface IGreetingVideoContextType {
  showControls: boolean;
  muted: boolean;
  isPlaying: boolean;
}

// Create the context with a default value
const GreetingVideoContext = createContext<IGreetingVideoContextType>({
  showControls: true,
  muted: false,
  isPlaying: false,
});

// Create a provider component
export const GreetingVideoContextProvider: FC<{
  children: ReactNode;
  showControls: boolean;
  muted: boolean;
  isPlaying: boolean;
}> = ({ children, showControls, muted, isPlaying }) => {
  // Memorize the context value
  const contextValue = useMemo(
    () => ({
      showControls,
      muted,
      isPlaying,
    }),
    [showControls, muted, isPlaying]
  );

  return (
    <GreetingVideoContext.Provider value={contextValue}>
      {children}
    </GreetingVideoContext.Provider>
  );
};

// Custom hook to use the CreatorCardContext
export const useGreetingVideoContext = () => useContext(GreetingVideoContext);
