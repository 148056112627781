import type { FC, PropsWithChildren } from "react";

interface ICrownIconProp {
  width?: number;
  height?: number;
  color?: string;
  isSolid?: boolean;
}
export const CrownIcon: FC<PropsWithChildren<ICrownIconProp>> = ({
  width = 30,
  height = 26,
  color = "#DBA621",
  isSolid = true,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1571 3.44901C27.7105 3.25212 27.2187 3.20037 26.7442 3.30034C26.2697 3.40031 25.834 3.64748 25.4927 4.01037L22.332 7.35529L16.7269 1.42344C16.2685 0.93846 15.6469 0.666016 14.9987 0.666016C14.3505 0.666016 13.7289 0.93846 13.2705 1.42344L7.66536 7.35529L4.5047 4.01037C4.16284 3.6487 3.72733 3.4024 3.25322 3.30263C2.77911 3.20286 2.2877 3.25409 1.8411 3.44984C1.3945 3.6456 1.01277 3.97708 0.744161 4.4024C0.475555 4.82772 0.332134 5.32777 0.332031 5.83934L0.332031 19.532C0.333972 21.2466 0.978443 22.8904 2.12408 24.1028C3.26971 25.3153 4.82297 25.9973 6.44314 25.9993H23.5543C25.1744 25.9973 26.7277 25.3153 27.8733 24.1028C29.019 22.8904 29.6634 21.2466 29.6654 19.532V5.83934C29.6655 5.32773 29.5223 4.82757 29.2538 4.4021C28.9853 3.97663 28.6037 3.64495 28.1571 3.44901ZM27.2209 19.532C27.2209 20.5612 26.8346 21.5481 26.147 22.2759C25.4593 23.0036 24.5267 23.4124 23.5543 23.4124H6.44314C5.47068 23.4124 4.53805 23.0036 3.85042 22.2759C3.16278 21.5481 2.77648 20.5612 2.77648 19.532V5.83934L6.80125 10.0987C7.03045 10.3412 7.34128 10.4774 7.66536 10.4774C7.98945 10.4774 8.30028 10.3412 8.52948 10.0987L14.9987 3.2524L21.4679 10.0987C21.6971 10.3412 22.0079 10.4774 22.332 10.4774C22.6561 10.4774 22.9669 10.3412 23.1961 10.0987L27.2209 5.83934V19.532Z"
        fill={color}
      />
      <path
        d="M27.2209 19.532C27.2209 20.5612 26.8346 21.5481 26.147 22.2759C25.4593 23.0036 24.5267 23.4124 23.5543 23.4124H6.44314C5.47068 23.4124 4.53805 23.0036 3.85042 22.2759C3.16278 21.5481 2.77648 20.5612 2.77648 19.532V5.83934L6.80125 10.0987C7.03045 10.3412 7.34128 10.4774 7.66536 10.4774C7.98945 10.4774 8.30028 10.3412 8.52948 10.0987L14.9987 3.2524L21.4679 10.0987C21.6971 10.3412 22.0079 10.4774 22.332 10.4774C22.6561 10.4774 22.9669 10.3412 23.1961 10.0987L27.2209 5.83934V19.532Z"
        fill={isSolid ? color : "transparent"}
      />
    </svg>
  </>
);
