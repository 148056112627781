import { Typography } from "@mui/material";

import styles from "../subscriptions.module.scss";
import { ActiveSubscriptionsList } from "./active-subscriptions-list";

export const ActiveSubscriptions = () => (
  <>
    <header className={styles.header}>
      <Typography variant="h4" fontWeight={400} className={styles.title}>
        Active subscriptions
      </Typography>
    </header>
    <ActiveSubscriptionsList />
  </>
);
