// Define the shape of the context
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  useAddViewMutation,
  useGetContentCatalogMutation,
  useGetUserCreatorByIdMutation,
} from "../../../../services/catalog/catalog.service";
import { ContentCs } from "../../../../services/catalog/interfaces/catalog.interfaces";
import { IGetContentCatalogCsParams } from "../../../../services/catalog/interfaces/request-models.interfaces";
import { useGetLastSearchNamesMutation } from "../../../../services/home/home.service";
import {
  ICreatorCardData,
  LastSearchType,
} from "../../../../services/home/interfaces/home.interface";
import { IPersonalInfo } from "../../../../services/settings/interfaces/settings.interface";
import { useGetSettingsMutation } from "../../../../services/settings/settings.service";
import { lastSearchSlice } from "../../../../store/reducers/last-search/last-search";
import { ActiveView } from "../../../home/interfaces/temporary.interface";

interface ICreatorCatalogCsViewContext {
  // Sidebar
  creatorCard: ICreatorCardData | null;
  setCreatorCard: Dispatch<SetStateAction<ICreatorCardData | null>>;
  personalInfoParameters: IPersonalInfo | null;

  // Search section
  view: ActiveView;
  setView: Dispatch<SetStateAction<ActiveView>>;

  // Catalog
  updateCsCatalogParam: <K extends keyof IGetContentCatalogCsParams>(
    key: K,
    value: IGetContentCatalogCsParams[K]
  ) => void;
  fetchContent: () => void;
  creatorContentItems: ContentCs[];
  setCreatorContentItems: Dispatch<SetStateAction<ContentCs[]>>;
  currentContentCard: ContentCs | null;
  setCurrentContentCard: Dispatch<SetStateAction<ContentCs | null>>;

  handleSetNextCard: () => void;
  handleSetPreviousCard: () => void;

  isPreview: boolean;
}

const CreatorCatalogCSViewContext = createContext<ICreatorCatalogCsViewContext>(
  {} as ICreatorCatalogCsViewContext
);

export const CreatorCatalogCSViewProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((data) => data.userReducer);
  const { setLastSearchItems } = lastSearchSlice.actions;

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { profileId } = useParams();

  // Start states
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [creatorCard, setCreatorCard] = useState<ICreatorCardData | null>(null);
  const [personalInfoParameters, setPersonalInfoParameters] =
    useState<IPersonalInfo | null>(null);
  const [view, setView] = useState<ActiveView>("tile");
  const [creatorContentItems, setCreatorContentItems] = useState<ContentCs[]>(
    []
  );
  const [currentContentCard, setCurrentContentCard] =
    useState<ContentCs | null>(null);
  const [contentCatalogParams, setContentCatalogParams] =
    useState<IGetContentCatalogCsParams>({
      accessToken: userAccessToken != null ? userAccessToken : "",
      creator_id: profileId || "",
      page: 1,
      rowsPerPage: 20,
      searchText: "",

      filters: {
        show: [],
        contentType: [],
        price: [],
      },
    });
  // End states

  // Start RTK calls
  const [getUserCreatorById] = useGetUserCreatorByIdMutation();
  const [getSettings] = useGetSettingsMutation();
  const [getLastSearchNames, { data: getLastSearchNamesData }] =
    useGetLastSearchNamesMutation();
  const [getContentCatalog] = useGetContentCatalogMutation();
  const [addViewToContent] = useAddViewMutation();
  // End RTK calls

  // Start function to call api
  const fetchUserCreatorById = useCallback(async () => {
    await getUserCreatorById({
      accessToken: userAccessToken != null ? userAccessToken : "",
      id: profileId || "",
    })
      .unwrap()
      .then((data) => {
        setCreatorCard(data.data.creator);
      })
      .catch(() => {
        setCreatorCard((prevState) => prevState);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessToken, getUserCreatorById, setCreatorCard]);

  const fetchPersonalInfo = useCallback(async () => {
    await getSettings({
      accessToken: userAccessToken != null ? userAccessToken : "",
      fullSocialLinks: true,
      userId: profileId || "",
    })
      .unwrap()
      .then((data: { data: IPersonalInfo | null }) => {
        if (data.data !== null) {
          setPersonalInfoParameters(data.data);
        }
      })
      .catch(() => {
        setPersonalInfoParameters((prevState) => prevState);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSettings, userAccessToken, profileId, setPersonalInfoParameters]);

  const fetchLastSearchedList = useCallback(async () => {
    if (isPreview) return;
    await getLastSearchNames({
      accessToken: userAccessToken || "",
      type: LastSearchType.CONTENT_SEARCH,
    }).unwrap();
  }, [userAccessToken, getLastSearchNames]);

  const fetchContentCatalog = useCallback(async () => {
    if (isPreview) return;
    await getContentCatalog(contentCatalogParams)
      .unwrap()
      .then((data) => {
        setCreatorContentItems(data.data);
        fetchLastSearchedList();
      })
      .catch(() => {
        setCreatorContentItems((prevState) => [...prevState]);
      });
  }, [
    contentCatalogParams,
    getContentCatalog,
    fetchLastSearchedList,
    setCreatorContentItems,
  ]);
  // End function to call api

  // Generic function to update state properties
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCsCatalogParam = <K extends keyof IGetContentCatalogCsParams>(
    key: K,
    value: IGetContentCatalogCsParams[K]
  ) => {
    setContentCatalogParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const fetchContent = useCallback(() => {
    fetchContentCatalog();
  }, [fetchContentCatalog]);

  const handleSetNextCard = useCallback(() => {
    const currentCardIndex = creatorContentItems.findIndex(
      (item) => item.id === currentContentCard?.id
    );

    if (currentCardIndex === creatorContentItems.length - 1) {
      return;
    }

    if (currentCardIndex < creatorContentItems.length) {
      // Update current card
      setCurrentContentCard(creatorContentItems[currentCardIndex + 1]);

      addViewToContent({
        accessToken: userAccessToken || "",
        content_id: creatorContentItems[currentCardIndex + 1].id,
      })
        .unwrap()
        .then(() => {
          // Update current card number of views
          setCurrentContentCard({
            ...creatorContentItems[currentCardIndex + 1],
            _count: {
              ...creatorContentItems[currentCardIndex + 1]._count,
              viewsPerContents:
                creatorContentItems[currentCardIndex + 1]._count
                  .viewsPerContents + 1,
            },
          });

          // Update card in list number of views
          setCreatorContentItems((prevState) => {
            if (!prevState) return prevState;

            const itemInListIndex = prevState.findIndex(
              (item) => item.id === creatorContentItems[currentCardIndex + 1].id
            );

            if (itemInListIndex !== -1) {
              const updatedItems = [
                ...prevState.slice(0, itemInListIndex),
                {
                  ...prevState[itemInListIndex],
                  _count: {
                    ...prevState[itemInListIndex]._count,
                    viewsPerContents:
                      prevState[itemInListIndex]._count.viewsPerContents + 1,
                  },
                } as ContentCs,
                ...prevState.slice(itemInListIndex + 1),
              ];

              return updatedItems;
            }

            return prevState;
          });
        })
        .catch(() => {});
    }
  }, [
    currentContentCard,
    creatorContentItems,
    addViewToContent,
    userAccessToken,
  ]);

  const handleSetPreviousCard = useCallback(() => {
    const currentCardIndex = creatorContentItems.findIndex(
      (item) => item.id === currentContentCard?.id
    );

    if (currentCardIndex === 0) {
      return;
    }

    setCurrentContentCard(creatorContentItems[currentCardIndex - 1]);

    addViewToContent({
      accessToken: userAccessToken || "",
      content_id: creatorContentItems[currentCardIndex - 1].id,
    })
      .unwrap()
      .then(() => {
        // Update current card
        setCurrentContentCard({
          ...creatorContentItems[currentCardIndex - 1],
          _count: {
            ...creatorContentItems[currentCardIndex - 1]._count,
            viewsPerContents:
              creatorContentItems[currentCardIndex - 1]._count
                .viewsPerContents + 1,
          },
        });

        // Update card in list
        setCreatorContentItems((prevState) => {
          if (!prevState) return prevState;

          const itemInListIndex = prevState.findIndex(
            (item) => item.id === creatorContentItems[currentCardIndex - 1].id
          );

          if (itemInListIndex !== -1) {
            const updatedItems = [
              ...prevState.slice(0, itemInListIndex),
              {
                ...prevState[itemInListIndex],
                _count: {
                  ...prevState[itemInListIndex]._count,
                  viewsPerContents:
                    prevState[itemInListIndex]._count.viewsPerContents + 1,
                },
              } as ContentCs,
              ...prevState.slice(itemInListIndex + 1),
            ];

            return updatedItems;
          }

          return prevState;
        });
      })
      .catch(() => {});
  }, [
    currentContentCard,
    creatorContentItems,
    addViewToContent,
    userAccessToken,
  ]);

  useEffect(() => {
    fetchPersonalInfo();
    fetchUserCreatorById();
    fetchContent();
  }, []);

  useEffect(() => {
    if (getLastSearchNamesData?.data) {
      dispatch(setLastSearchItems(getLastSearchNamesData.data));
    }
  }, [getLastSearchNamesData]);

  useEffect(() => {
    fetchContentCatalog();
  }, [contentCatalogParams]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const splitPath = location.pathname.split("/");
    const id = splitPath[splitPath.length - 1];
    const isPreviewProfile = params.get("isPreview") === "true";

    if (id === user?.id && !isPreviewProfile) {
      navigate(`/cc/profile`);
    }
    if (id === user?.id && isPreviewProfile) {
      setIsPreview(isPreviewProfile);
    }
  }, [location.search]);

  const contextValues = useMemo(
    () => ({
      creatorCard,
      setCreatorCard,
      personalInfoParameters,
      updateCsCatalogParam,
      view,
      setView,
      fetchContent,
      creatorContentItems,
      currentContentCard,
      setCurrentContentCard,
      handleSetNextCard,
      handleSetPreviousCard,
      setCreatorContentItems,
      isPreview,
    }),
    [
      creatorCard,
      setCreatorCard,
      personalInfoParameters,
      updateCsCatalogParam,
      view,
      setView,
      fetchContent,
      creatorContentItems,
      currentContentCard,
      setCurrentContentCard,
      handleSetNextCard,
      handleSetPreviousCard,
      setCreatorContentItems,
      isPreview,
    ]
  );

  return (
    <CreatorCatalogCSViewContext.Provider value={contextValues}>
      {children}
    </CreatorCatalogCSViewContext.Provider>
  );
};

// Custom hook to use the CreatorCatalogCSViewContext
export const useCreatorCatalogCSViewContext = () =>
  useContext(CreatorCatalogCSViewContext);
