import {
  Avatar,
  Button,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Link as LinkButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import {
  type ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import { SortingDirection } from "../../../../../../enums/sorting-direction.enum";
import { ArrowDown } from "../../../../../../media/icons/arrowDown";
import { SearchInCircleIcon } from "../../../../../../media/icons/search-in-circle";
import { useFetchSubscribersMutation } from "../../../../../../services/manage-subscribers/manage-subscribers.service";
import type { ISearchText } from "../../../../../manage-subscribers/interfaces/search-text.interface";
import type { ISubscribersList } from "../../../../../manage-subscribers/my-subscribers/interfaces/my-subscribers-list.interface";
import styles from "../../../../../manage-subscribers/subscriber-groups/subscriber-groups.module.scss";
import { DefaultAvatar } from "../../../../../shared/default-avatar/default-avatar";
import { ModalDialog } from "../../../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";

interface IAddSubscribersProp {
  isDialogOpened: boolean;
  setIsOpenAddSubscribersDialog: Dispatch<SetStateAction<boolean>>;
  selectedUsersIds: string[];
  onSaveClick: (switches: string[]) => void;
  setSelectedUsers: Dispatch<SetStateAction<string[]>>;
  onCancelClick: () => void;
}

export const AddSubscribers: FC<IAddSubscribersProp> = ({
  isDialogOpened,
  setIsOpenAddSubscribersDialog,
  selectedUsersIds,
  onSaveClick,
  setSelectedUsers,
  onCancelClick,
}) => {
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  // All subscribers
  const [allSubscribers, setAllSubscribers] = useState<ISubscribersList[]>([]);
  // Values which will be sent to the backend
  const [switches, setSwitches] = useState<string[]>([]);

  const typingTimeout = useRef<any>(null);

  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const formikSubscribers = useFormik({
    initialValues: {
      searchText,
    },
    onSubmit: async (values: ISearchText) => {
      setSearchText(values.searchText);
    },
  });

  const handleSearchTextChange = (e: Object) => {
    clearTimeout(typingTimeout.current);

    formikSubscribers.handleChange(e);

    typingTimeout.current = setTimeout(() => {
      formikSubscribers.submitForm();
    }, 500);
  };

  useEffect(() => {
    setSelectedUsers(selectedUsersIds);
  }, [selectedUsersIds]);

  const [
    fetchSubscribers,
    {
      error: fetchSubscribersError,
      isLoading: fetchSubscribersLoading,
      data: subscribersData,
    },
  ] = useFetchSubscribersMutation();

  const containerElemRef = useRef<HTMLDivElement | null>(null);

  const getSubscribers = useCallback(async () => {
    try {
      await fetchSubscribers({
        accessToken: accessToken || "",
        rowsPerPage: 6,
        page: page + 1,
        direction: SortingDirection.asc,
        sortBy: "created_at",
        searchText,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [accessToken, fetchSubscribers, page, searchText]);

  useEffect(() => {
    setAllSubscribers([]);
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    if (subscribersData) {
      if (page === 0) {
        setAllSubscribers(subscribersData.data);
        return;
      }

      setAllSubscribers((prevState) => [...prevState, ...subscribersData.data]);
    }
  }, [subscribersData]);

  useEffect(() => {
    containerElemRef.current?.scrollTo({
      top: containerElemRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [allSubscribers]);

  useEffect(() => {
    if (fetchSubscribersError) {
      navigate("/");
    }

    if (!isDialogOpened) {
      setSearchText("");
      return;
    }

    getSubscribers();
  }, [getSubscribers, isDialogOpened]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setSelectedUsers((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value)
    );

    const isSubscriberChanged = switches.findIndex((id) => id === value);

    if (isSubscriberChanged !== -1) {
      setSwitches((prevState) => prevState.filter((id) => id !== value));
      return;
    }

    setSwitches((prevState) => [...prevState, value]);
  };

  const handleSaveClick = () => {
    setIsOpenAddSubscribersDialog(false);
    onSaveClick(switches);
    setSwitches([]);
  };

  return (
    <ModalDialog
      isOpen={isDialogOpened}
      classes={styles.modal}
      dataCy="edit-group-modal"
    >
      <ModalDialogHeader>
        <Typography variant="h5" color="#252733" fontWeight={400}>
          Select subscribers
        </Typography>
      </ModalDialogHeader>
      <ModalDialogContent>
        <form className={styles.groupForm} noValidate>
          <TextField
            placeholder="Search SideFans"
            type="text"
            fullWidth
            name="searchText"
            onBlur={formikSubscribers.handleBlur}
            onChange={handleSearchTextChange}
            value={formikSubscribers.values.searchText}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchInCircleIcon height={24} width={24} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <div className={styles.subscriberList} ref={containerElemRef}>
              {allSubscribers.length > 0 &&
                allSubscribers
                  .filter((row) => row.subscriber)
                  .map((row: ISubscribersList) => (
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      key={row.id}
                      className={styles.subscriberLabel}
                      control={
                        <Switch
                          sx={{ mt: 1, mb: 1 }}
                          onChange={handleSwitchChange}
                          checked={selectedUsersIds.includes(row.subscriber_id)}
                        />
                      }
                      label={
                        <div className={styles.subscriberInfo}>
                          {row.subscriber?.avatar ? (
                            <Avatar
                              alt={row.subscriber.username}
                              src={row.subscriber.avatar.backend_media_url}
                              sx={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <Avatar
                              alt={row.subscriber?.username}
                              sx={{ width: 32, height: 32 }}
                            >
                              <DefaultAvatar width={32} height={32} />
                            </Avatar>
                          )}
                          <Typography variant="body1">
                            {row.subscriber?.username}
                          </Typography>
                        </div>
                      }
                      labelPlacement="start"
                      value={row.subscriber?.id}
                    />
                  ))}
            </div>
            {subscribersData?.total !== allSubscribers.length && (
              <div className={styles.showMoreWrapper}>
                <Button
                  sx={{ padding: 0 }}
                  disableRipple
                  component={LinkButton}
                  variant="text"
                  onClick={() => {
                    setPage((prev) => prev + 1);
                  }}
                >
                  <Typography className={styles.showMore} variant="body1">
                    <span style={{ display: "block" }}>Show more</span>{" "}
                    <ArrowDown color="blue" />
                  </Typography>
                </Button>
              </div>
            )}
          </FormControl>
        </form>
      </ModalDialogContent>
      <ModalDialogFooter>
        <div className={styles.modalFooter}>
          <Button
            color="primary"
            className={styles.btn}
            variant="contained"
            type="button"
            onClick={handleSaveClick}
          >
            <Typography variant="body1" fontWeight="bold">
              Save
            </Typography>
          </Button>
          <Button
            color="primary"
            className={styles.btn}
            variant="outlined"
            type="button"
            onClick={() => {
              setPage(0);
              setIsOpenAddSubscribersDialog(false);
              onCancelClick();
            }}
            sx={{ borderRadius: "12px" }}
          >
            <Typography variant="body1" fontWeight="bold">
              Cancel
            </Typography>
          </Button>
        </div>
        {fetchSubscribersLoading && (
          <LinearProgress style={{ width: "100%", marginTop: "5px" }} />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};
