import { useLocation, useNavigate } from "react-router-dom";

export default function useQueryParam() {
  const location = useLocation();
  const navigate = useNavigate();

  const setParam = (queryName: string, value: string) => {
    const params = new URLSearchParams(location.search);
    params.set(queryName, value);

    if (queryName === "" || value === "") {
      navigate(location.pathname, { replace: true });
    } else {
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  };

  return {
    setParam,
  };
}
