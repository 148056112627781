import type { DefaultSelectOptions } from "../../../shared/select/select";
import type { IPersonalInfo } from "../interfaces/personal-info.interface";

export interface IPersonalInfoForm {
  [key: string]: any;
  age: number | null;
  birthday: string | null;
  birthplace: string | null;
  birthstone: string | null;
  zodiac_sign: string | null;
  currently_reside_in: string | null;
  relationship_status: string | null;
  profession: string | null;
  languages: string | null;
}

// eslint-disable-next-line no-shadow
export enum PersonalInformationKeys {
  age = "age",
  birthday = "birthday",
  birthplace = "birthplace",
  birthstone = "birthstone",
  zodiacSign = "zodiac_sign",
  currentlyResideIn = "currently_reside_in",
  relationshipStatus = "relationship_status",
  profession = "profession",
  languages = "languages",
}

export enum PersonalInformationNames {
  age = "Age",
  birthday = "Birthday",
  birthplace = "Birthplace",
  birthstone = "Birthstone",
  zodiacSign = "Zodiac Sign",
  currentlyResideIn = "Currently Reside In",
  relationshipStatus = "Relationship Status",
  profession = "Profession",
  languages = "Language(s)",
}

export const personalInfoDropdown: DefaultSelectOptions[] = [
  {
    value: PersonalInformationKeys.age,
    display_text: PersonalInformationNames.age,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.birthday,
    display_text: PersonalInformationNames.birthday,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.birthplace,
    display_text: PersonalInformationNames.birthplace,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.birthstone,
    display_text: PersonalInformationNames.birthstone,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.zodiacSign,
    display_text: PersonalInformationNames.zodiacSign,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.currentlyResideIn,
    display_text: PersonalInformationNames.currentlyResideIn,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.relationshipStatus,
    display_text: PersonalInformationNames.relationshipStatus,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.profession,
    display_text: PersonalInformationNames.profession,
    disabled: false,
  },
  {
    value: PersonalInformationKeys.languages,
    display_text: PersonalInformationNames.languages,
    disabled: false,
  },
];

export const personalInformation: IPersonalInfo[] = [
  {
    name: PersonalInformationKeys.age,
    title: PersonalInformationNames.age,
    placeholder: "Enter age",
    value: null,
  },
  {
    name: PersonalInformationKeys.birthday,
    title: PersonalInformationNames.birthday,
    placeholder: "Enter birthday",
    value: null,
  },
  {
    name: PersonalInformationKeys.birthplace,
    title: PersonalInformationNames.birthplace,
    placeholder: "Enter birthplace",
    value: null,
  },
  {
    name: PersonalInformationKeys.birthstone,
    title: PersonalInformationNames.birthstone,
    placeholder: "Enter birthstone",
    value: null,
  },
  {
    name: PersonalInformationKeys.zodiacSign,
    title: PersonalInformationNames.zodiacSign,
    placeholder: "Enter zodiac sign",
    value: null,
  },
  {
    name: PersonalInformationKeys.currentlyResideIn,
    title: PersonalInformationNames.currentlyResideIn,
    placeholder: "Enter currently reside in",
    value: null,
  },
  {
    name: PersonalInformationKeys.relationshipStatus,
    title: PersonalInformationNames.relationshipStatus,
    placeholder: "Enter relationship status",
    value: null,
  },
  {
    name: PersonalInformationKeys.profession,
    title: PersonalInformationNames.profession,
    placeholder: "Enter profession",
    value: null,
  },
  {
    name: PersonalInformationKeys.languages,
    title: PersonalInformationNames.languages,
    placeholder: "Enter language(s)",
    value: null,
  },
];
