import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import {
  IBecomeCreatorParams,
  IBecomeCreatorResponse,
  IDeleteGreetingResponse,
  IDeleteMultipleGreetingParams,
  IDeleteSingleGreetingParams,
  IGetGreetingFilesParams,
  IGetPersonalSettings,
  IGetSettings,
  IGreetingsResponse,
  IPersonalInfoResponse,
  IPrivacyResponse,
  IUpdatePersonalInfo,
  IUpdatePrivacySettings,
} from "./interfaces/settings.interface";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getSettings: builder.mutation<IPersonalInfoResponse, IGetPersonalSettings>({
      query: (data: IGetPersonalSettings) => ({
        url: `${ENDPOINTS.CATALOG.GET_PERSONAL_INFO}/${data.userId}${data.fullSocialLinks ? `?fullSocialLinks=${data.fullSocialLinks}` : ""}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    updateSettings: builder.mutation<
      IPersonalInfoResponse,
      IUpdatePersonalInfo
    >({
      query: (data: IUpdatePersonalInfo) => ({
        url: ENDPOINTS.SETTINGS.UPDATE_PERSONAL_INFO,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: data.body,
      }),
    }),

    getPrivacySettings: builder.mutation<IPrivacyResponse, IGetSettings>({
      query: (data: IGetSettings) => ({
        url: ENDPOINTS.SETTINGS.PRIVACY.GET_PRIVACY,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    updatePrivacySettings: builder.mutation<
      IPrivacyResponse,
      IUpdatePrivacySettings
    >({
      query: (data: IUpdatePrivacySettings) => {
        const { accessToken, ...bodyData } = data;

        return {
          url: ENDPOINTS.SETTINGS.PRIVACY.UPDATE_PRIVACY,
          method: "POST",
          headers: {
            Authorization: accessToken,
          },
          body: bodyData,
        };
      },
    }),

    getGreetingsFiles: builder.mutation<
      IGreetingsResponse,
      IGetGreetingFilesParams
    >({
      query: (data: IGetGreetingFilesParams) => ({
        url: ENDPOINTS.SETTINGS.GREETINGS.GET_GREETINGS_MEDIA,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    deleteSingleGreetingsFile: builder.mutation<
      IDeleteGreetingResponse,
      IDeleteSingleGreetingParams
    >({
      query: (data: IDeleteSingleGreetingParams) => ({
        url: ENDPOINTS.SETTINGS.GREETINGS.DELETE_SINGLE_GREETINGS_MEDIA.replace(
          "{greetingId}",
          data.greetingId
        ),
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    deleteMultipleGreetingsFiles: builder.mutation<
      IDeleteGreetingResponse,
      IDeleteMultipleGreetingParams
    >({
      query: (data: IDeleteMultipleGreetingParams) => ({
        url: ENDPOINTS.SETTINGS.GREETINGS.DELETE_MULTIPLE_GREETINGS_MEDIA,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          ids: data.greetingIds,
        },
      }),
    }),

    becomeCreator: builder.mutation<
      IBecomeCreatorResponse,
      IBecomeCreatorParams
    >({
      query: (data: IBecomeCreatorParams) => ({
        url: ENDPOINTS.SETTINGS.BECOME_CREATOR,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          creator_agreement: data.creator_agreement,
          inquiry_id: data.inquiry_id,
        },
      }),
    }),
  }),
});

export const {
  useGetSettingsMutation,
  useUpdateSettingsMutation,
  useGetPrivacySettingsMutation,
  useUpdatePrivacySettingsMutation,
  useGetGreetingsFilesMutation,
  useDeleteSingleGreetingsFileMutation,
  useDeleteMultipleGreetingsFilesMutation,
  useBecomeCreatorMutation,
} = settingsApi;
