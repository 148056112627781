import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { REGEX } from "../../../../constants/regex/regex";
import { VisibilityOff } from "../../../../media/icons/visibility-off";
import type { ICheckPasswordResponseFail } from "../../../../services/sign-up/interfaces/check-password-response.interface";
import { useCheckPasswordMutation } from "../../../../services/sign-up/sign-up.service";
import PasswordValidationPopover from "../../../shared/popovers/password-validation-popover/password-validation-popover";
import signUpShared from "../../sign-up-shared.module.scss";
import { getColorOfState } from "../../sign-up.utils";
import { type PasswordType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

type CreatePasswordProp = {
  setPassword: Dispatch<SetStateAction<PasswordType>>;
  isPasswordValid: boolean;
  password: PasswordType;
};

export const CreatePassword: FC<CreatePasswordProp> = ({
  setPassword,
  isPasswordValid,
  password,
}) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, { error, isSuccess, isLoading }] =
    useCheckPasswordMutation();
  const formik = useFormik({
    initialValues: {
      password: password.value,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(
          "Sorry, that password doesn’t follow the rules. Please try again."
        )
        .matches(
          REGEX.PASSWORD.GENERAL,
          "Sorry, that password doesn’t follow the rules. Please try again."
        ),
    }),
    onSubmit: async (values: { password: string }) => {
      await checkPassword(values.password).unwrap();
    },
  });

  useEffect(() => {
    if (error) {
      setPassword({ status: "invalid", value: "" });

      const err = error as ICheckPasswordResponseFail;
      let errorMessage = "";

      switch (err.status) {
        case 400:
          errorMessage =
            "Sorry, that password doesn't follow the rules. Please try again.";
          break;
        default:
          errorMessage = "Something went wrong. Please try again!";
      }

      formik.setErrors({
        password: errorMessage,
      });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setPassword({ status: "valid", value: formik.values.password });
    }
  }, [isSuccess]);

  const onChangeClick = useCallback(() => {
    formik.resetForm();
    setPassword({ status: "none", value: "" });
  }, []);

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!formik.errors.password) {
      setAnchorRef(null);
      return;
    }

    setAnchorRef(inputRef.current);
  }, [formik.errors]);

  const isOpenPoppsr =
    Boolean(formik.errors.password) && Boolean(anchorRef) && isFieldFocused;

  return (
    <SignUpCard status={isPasswordValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Create a password:
        </Typography>
        <form
          className={signUpShared.cardForm}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <TextField
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            color={getColorOfState(
              isLoading,
              formik.errors.password,
              isPasswordValid
            )}
            error={!!(formik.touched.password && formik.errors.password)}
            fullWidth
            name="password"
            onBlur={(e) => {
              formik.handleBlur(e);
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
            disabled={isPasswordValid}
            ref={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onShowPassword} style={{ padding: 0 }}>
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              sx: { borderRadius: "4px" },
            }}
          />
          {isPasswordValid ? (
            <Button
              color="success"
              className={signUpShared.actionBtn}
              variant="contained"
              type="button"
              onClick={onChangeClick}
            >
              {isLoading && <CircularProgress color="inherit" size="1rem" />}
              <Typography variant="button">Change</Typography>
            </Button>
          ) : (
            <Button
              color="primary"
              className={signUpShared.actionBtn}
              variant="contained"
              type="submit"
              disabled={isLoading || !formik.isValid || !formik.dirty}
            >
              {isLoading && <CircularProgress color="inherit" size="1rem" />}
              <Typography variant="button">Submit</Typography>
            </Button>
          )}
        </form>
      </div>
      {formik.errors.password && (
        <Typography color="error" variant="caption" marginTop="4px">
          {formik.errors.password}
        </Typography>
      )}
      <PasswordValidationPopover
        anchorEl={anchorRef}
        open={isOpenPoppsr}
        password={formik.values.password}
      />
    </SignUpCard>
  );
};
