export const ENDPOINTS = {
  SIGN_UP: {
    CHECK_USERNAME: "users/check-username",
    CHECK_EMAIL: "users/check-email",
    CONFIRM_EMAIL: "users/verify-email",
    CHECK_PASSWORD: "users/check-password",
    SIGN_UP: "users",
  },
  SIGN_IN: "users/sign-in",
  ME: "users/me",
  UPDATE_ME: "users",
  DELETE_ME: "users",
  UPDATE_PASSWORD: "users/update-password",
  REQUEST_RESET_PASSWORD: "users/request-reset-password",
  CHECK_VERIFICATION: "users/verification",
  INVITES: {
    INVITE_USER: "invites",
    INVITES_LIST: "invites",
    CANCEL_INVITATION: "invites",
  },
  PAYMENT_METHODS: "payment-methods",
  MANAGE_SUBSCRIBERS: {
    FETCH_INTERESTED_USERS: "users/interested",
    FETCH_SUBSCRIBERS: "users/subscribers",
    FETCH_GROUPS: "groups",
    CREATE_GROUP: "groups",
    FETCH_GROUP_SUBSCRIBERS: "groups/members",
    UPDATE_GROUP: "groups",
  },
  TRANSACTIONS: "transactions",
  TRANSFERS: {
    AUTOMATIC_TRANSFERS: "transfers/automatic-transfers",
    CURRENCY_TRANSFERS: "transfers/currency-transfers",
    MANUAL_TRANSFERS: "manual-transfers/manual-transfers",
    CURRENCY_MANUAL_TRANSFERS: "manual-transfers/currency-manual-transfers",
  },
  TRANSFER_ACCOUNTS: {
    ROOT: "transfer-accounts",
    FETCH_TRANSFER_HISTORY: "transfer-accounts/transfer-history",
  },
  SUBSCRIPTIONS: {
    GET_ALL: "users/subscriptions",
    CANCEL: "users/cancel-subscription",
    RENEW: "users/renew-subscription",
    SUBSCRIBE: "users/subscribe",
  },
  EARNINGS: {
    HISTORY: "transactions/earnings",
  },
  WALLETS: "users/wallets",
  CURRENCIES: "users/currencies",
  UPLOAD_CONTENT: "media/content-media",
  UPLOAD_GREETINGS: "media/greeting",
  UPLOAD_GREETING_CONTENT: "media/greeting-content",
  UPLOAD_AVATAR: "media/avatar",
  SETTINGS: {
    GET_PERSONAL_INFO: "settings/personal-info",
    UPDATE_PERSONAL_INFO: "settings/personal-info",
    PRIVACY: {
      GET_PRIVACY: "settings/privacy",
      UPDATE_PRIVACY: "settings/privacy",
    },
    GREETINGS: {
      GET_GREETINGS_MEDIA: "settings/greetings",
      DELETE_SINGLE_GREETINGS_MEDIA: "media/greeting/{greetingId}",
      DELETE_MULTIPLE_GREETINGS_MEDIA: "media/greeting-content",
    },
    BECOME_CREATOR: "settings/become-creator",
  },
  BLOCK_USERS: {
    UNBLOCK: "users/unblock",
    GET_BLOCKED_USERS: "users/blocked-users",
  },
  HOME: {
    GET_SUBSCRIBED: "users/creators/subscribed",
    GET_NEWEST: "users/creators/newest",
    GET_INTERESTED: "users/creators/interested",
    GET_RANKED: "users/creators/top-ranked",
    GET_BY_SEARCH: "users/creators/all",
    SAVED_SEARCHES: "saved-searches",
    CONTENT_COUNT: "users/creators/content-count",
    INTERESTED_PATCH_CARD_ORDER: "users/interested/",
    SUBSCRIPTION_PATCH_CARD_ORDER: "users/subscriptions/",
    INTERESTED: "users/interested",
  },
  CATALOG: {
    GET_MY_CONTENT: "content/catalog/me",
    GET_CC_CONTENT: "content/catalog/",
    GET_PERSONAL_INFO: "settings/personal-info",
    GET_USER_CREATOR_CARD_BY_ID: "users/creators/card",
    DELETE_CONTENT_BY_IDS: "content",
    UPDATE_SINGLE_CONTENT: "content/",
    ADD_CONTENT_TO_COLLECTIONS: "content/add-content-to-collection",
    UPDATE_COLLECTION: "content/update-collection",
    CREATE_COLLECTION: "content/create-collection",
    GET_CONTENT_BY_ID: "content/get-post-by-id/",

    GET_FOLDER_BY_ID: "content/folders/get-by-id",
    CREATE_FOLDER: "content/folders/",
    DELETE_FOLDER: "content/folder/",
    UPDATE_FOLDER: "content/folders/",
    REMOVE_FROM_FOLDER: "content/folders/",

    SET_LOVED: "content/love",
    REMOVE_LOVED: "content/love",

    ADD_VIEW: "content/view-content",

    BUY_CONTENT: "content/buy-content/",
  },
  SEND_TIP: "tips",
};
