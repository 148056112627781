import type { Dispatch, SetStateAction } from "react";
import { useCallback } from "react";

type StateUpdater<T> = Dispatch<SetStateAction<T[]>>;
type ItemWithId = { name: string };
type InfoForm = { [p: string]: any };
type Info = { name: string; value: any };

export const useGetDataItemsToDisplay = <T extends InfoForm, U extends Info>(
  infoData: U[],
  deps: any[]
) =>
  useCallback((infoForm: T) => {
    if (!infoForm) {
      return infoData.slice(0, 3);
    }

    const nonNullInfoForm: InfoForm = Object.entries(infoForm)
      .filter(([, value]) => value !== null)
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    if (Object.keys(nonNullInfoForm).length === 0) {
      return infoData.slice(0, 3);
    }

    const updatedInfoData = infoData
      .map((info) => ({
        ...info,
        value: nonNullInfoForm[info.name] || info.value,
      }))
      .filter((info) => info.value !== null);

    return updatedInfoData;
  }, deps);

export const useAddDetail = <T>(setState: StateUpdater<T>, deps: any[]) =>
  useCallback((newDetail: T) => {
    setState((prevState) => [...prevState, newDetail]);
  }, deps);

export const useDeleteDetail = <J extends ItemWithId>(
  setState: StateUpdater<J>,
  deps: any[]
) =>
  useCallback((nameToDelete: string) => {
    setState((prevState) =>
      prevState.filter((item) => item.name !== nameToDelete)
    );
  }, deps);
