import {
  Button,
  Card,
  CircularProgress,
  Fade,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { enterDuration, exitDuration } from "../../constants/animations/fade";
import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { RoleEnum } from "../../enums/role.enum";
import { BackIcon } from "../../media/icons/back";
import type { ISignUpBody } from "../../services/sign-up/interfaces/sign-up.interface";
import { useSignUpMutation } from "../../services/sign-up/sign-up.service";
import type { CollectedData } from "../select-payment-method/interfaces/collected-data.interface";
import { UnauthorizedWrapper } from "../shared/unauthorized-wrapper/unauthorized-wrapper";
import { AgeVerification } from "./components/age-verification/age-verification";
// import { AgeVerification } from "./components/age-verification/age-verification";
import { Agreement } from "./components/agreement/agreement";
import { ConfirmEmail } from "./components/confirm-email/confirm-email";
import { CreateEmail } from "./components/create-email/create-email";
import { CreatePassword } from "./components/create-password/create-password";
import { CreateUsername } from "./components/create-username/create-username";
import { PlayAutomatically } from "./components/play-automatically/play-automatically";
import { PurchaseContent } from "./components/purchase-content/purchase-content";
import { Role } from "./components/role/role";
import { SignUpCard } from "./components/sign-up-card/sign-up-card";
import { SoundMuted } from "./components/sound-muted/sound-muted";
import signUpShared from "./sign-up-shared.module.scss";
import styles from "./sign-up.module.scss";
import type {
  AgeVerificationType,
  AgreementType,
  ConfirmEmailType,
  CreateEmailType,
  CreateUsernameType,
  PasswordType,
  PlayAutomaticallyType,
  PurchaseContentType,
  RoleType,
  SoundMutedType,
} from "./types/sign-up.types";

type SignUpProps = {
  setAgeVerificationStep: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  inquiryId: string;
  ageVerification: AgeVerificationType;
  setAgeVerification: (
    value:
      | AgeVerificationType
      | ((prevVar: AgeVerificationType) => AgeVerificationType)
  ) => void;
  setUsername: (
    value:
      | CreateUsernameType
      | ((prevVar: CreateUsernameType) => CreateUsernameType)
  ) => void;
  username: CreateUsernameType;
  setEmail: (
    value: CreateEmailType | ((prevVar: CreateEmailType) => CreateEmailType)
  ) => void;
  email: CreateEmailType;
  setConfirmEmail: (
    value: ConfirmEmailType | ((prevVar: ConfirmEmailType) => ConfirmEmailType)
  ) => void;
  confirmEmail: ConfirmEmailType;
  setPassword: (
    value: PasswordType | ((prevVar: PasswordType) => PasswordType)
  ) => void;
  password: PasswordType;
  setRole: (value: RoleType | ((prevVar: RoleType) => RoleType)) => void;
  role: RoleType;
  setAgreement: (
    value: AgreementType | ((prevVar: AgreementType) => AgreementType)
  ) => void;
  agreement: AgreementType;
  setPurchaseContent: (
    value:
      | PurchaseContentType
      | ((prevVar: PurchaseContentType) => PurchaseContentType)
  ) => void;
  purchaseContent: PurchaseContentType;
  setSoundMuted: (
    value: SoundMutedType | ((prevVar: SoundMutedType) => SoundMutedType)
  ) => void;
  soundMuted: SoundMutedType;
  setPlayAutomatically: (
    value:
      | PlayAutomaticallyType
      | ((prevVar: PlayAutomaticallyType) => PlayAutomaticallyType)
  ) => void;
  playAutomatically: PlayAutomaticallyType;
  setOpenPaymentMethod: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  collectedData: CollectedData;
  setCollectedData: (
    value: CollectedData | ((prevVar: CollectedData) => CollectedData)
  ) => void;
  setSelectedStep: (value: string | ((prevVar: string) => string)) => void;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
};

export const SignUp: FC<SignUpProps> = ({
  setAgeVerificationStep,
  inquiryId,
  ageVerification,
  setAgeVerification,
  setUsername,
  username,
  email,
  setEmail,
  confirmEmail,
  setConfirmEmail,
  password,
  setPassword,
  role,
  setRole,
  agreement,
  setAgreement,
  purchaseContent,
  setPurchaseContent,
  setSoundMuted,
  soundMuted,
  playAutomatically,
  setPlayAutomatically,
  setOpenPaymentMethod,
  collectedData,
  setCollectedData,
  setSelectedStep,
  setSelectedMethods,
}) => {
  const [signUp, { isLoading, isSuccess, data: signUpResponse, error }] =
    useSignUpMutation();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  // eslint-disable-next-line prefer-destructuring
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const verificationToken = searchParams.get("invitation");
  const invitedUserEmail = searchParams.get("email");

  const navigate = useNavigate();

  const containerElemRef = useRef<HTMLDivElement | null>(null);
  // // TODO: AgeVerification Remove later
  // useEffect(() => {
  //   setAgeVerificationStep(false);
  //   setAgeVerification({
  //     status: "none",
  //     value: false,
  //   });
  // }, []);
  // // END of TODO
  useEffect(() => {
    containerElemRef.current?.scrollTo({
      top: containerElemRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [
    username,
    email,
    confirmEmail,
    password,
    role,
    agreement,
    ageVerification,
    purchaseContent,
    soundMuted,
    playAutomatically,
  ]);

  useEffect(() => {
    if (isSuccess) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
        `Bearer ${signUpResponse?.accessToken || ""}`
      );
      setIsDialogOpened(true);
    }
  }, [isSuccess, signUpResponse]);

  const onSignUp = async () => {
    const body: ISignUpBody = {
      username: username.value,
      role: role.value,
      email: email.value,
      password: password.value,
      creator_agreement: agreement.value && role.value === RoleEnum.creator,
      subscriber_agreement:
        agreement.value && role.value === RoleEnum.subscriber,
      media_sound_muted: soundMuted.value,
      media_play_automatically: playAutomatically.value,
      create_content_later: role.isLater,
      purchase_content_later: purchaseContent.isLater,
      purchase_content: purchaseContent.value,
      // TODO:
      inquiry_id: "inquiryIdPromptPlaceHolder123" || inquiryId,
      payment_method: collectedData,
    };

    if (verificationToken) {
      body.invitation_token = verificationToken;
    }

    await signUp(body).unwrap;
  };

  return (
    <UnauthorizedWrapper>
      <Card
        className={styles.signUpCardWrapper}
        sx={{
          padding: "0",
          borderRadius: "32px",
          display: isDialogOpened ? "none" : "flex",
        }}
      >
        <header className={styles.header}>
          <div className={styles.backToSignIn}>
            <BackIcon width={15} height={15} />
            <Link to="/sign-in" style={{ color: "#0F70CA" }}>
              Back to Sign In
            </Link>
          </div>
        </header>
        <div
          className={styles.signUpContent}
          ref={(el) => {
            containerElemRef.current = el;
          }}
        >
          <Typography
            variant="h5"
            fontWeight={400}
            letterSpacing="0.017rem"
            lineHeight="32px"
            textAlign="left"
          >
            Welcome to SideFans! Let&apos;s get you inside.
          </Typography>
          <CreateUsername
            setUsername={setUsername}
            isUsernameValid={username.status === "valid"}
            username={username}
          />

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={username.status === "valid"}
            onExited={() => setEmail({ status: "none", value: "" })}
            unmountOnExit
          >
            {/* Use div wrapper to avoid ScrollTop error */}
            <div style={{ width: "100%" }}>
              <CreateEmail
                setEmail={setEmail}
                isEmailValid={email.status === "valid"}
                email={email}
                invitedUserEmail={verificationToken ? invitedUserEmail : null}
                setConfirmEmail={setConfirmEmail}
              />
            </div>
          </Fade>

          {!invitedUserEmail && (
            <Fade
              timeout={{ enter: enterDuration, exit: exitDuration }}
              in={email.status === "valid"}
              onExited={() => setConfirmEmail({ status: "none", value: "" })}
              unmountOnExit
            >
              <div style={{ width: "100%" }}>
                <ConfirmEmail
                  setConfirmEmail={setConfirmEmail}
                  isConfirmEmailValid={confirmEmail.status === "valid"}
                  email={email.value}
                  confirmEmail={confirmEmail}
                />
              </div>
            </Fade>
          )}

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={confirmEmail.status === "valid"}
            onExited={() => setPassword({ status: "none", value: "" })}
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <CreatePassword
                setPassword={setPassword}
                isPasswordValid={password.status === "valid"}
                password={password}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={password.status === "valid"}
            onExited={() =>
              setRole({
                status: "none",
                value: RoleEnum.undefined,
                isLater: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <Role
                setRole={setRole}
                setAgreement={setAgreement}
                isRoleValid={role.status === "valid"}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={role.status === "valid"}
            onExited={() =>
              setAgreement({
                status: "none",
                value: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <Agreement
                setAgreement={setAgreement}
                agreement={agreement}
                isAgreementValid={agreement.status === "valid"}
                role={role.value}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={
              agreement.status === "valid" &&
              role.value === RoleEnum.creator &&
              agreement.value
            }
            onExited={() =>
              setAgeVerification({
                status: "none",
                value: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <AgeVerification
                setAgeVerificationStep={setAgeVerificationStep}
                isAgeVerified={ageVerification.status === "valid"}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            // in={ageVerification.status === "valid"}
            in={
              (agreement.status === "valid" &&
                agreement.value &&
                role.value === RoleEnum.subscriber) ||
              (role.value === RoleEnum.creator &&
                ageVerification.status === "valid" &&
                ageVerification.value)
            }
            onExited={() =>
              setPurchaseContent({
                status: "none",
                value: false,
                isLater: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <PurchaseContent
                setPurchaseContent={setPurchaseContent}
                purchaseContent={purchaseContent}
                isPurchaseContentValid={purchaseContent.status === "valid"}
                setOpenPaymentMethod={setOpenPaymentMethod}
                setCollectedData={setCollectedData}
                collectedData={collectedData}
                setSelectedStep={setSelectedStep}
                setSelectedMethods={setSelectedMethods}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={purchaseContent.status === "valid"}
            onExited={() =>
              setSoundMuted({
                status: "none",
                value: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <SoundMuted
                setSoundMuted={setSoundMuted}
                isSoundMutedValid={soundMuted.status === "valid"}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={soundMuted.status === "valid"}
            onExited={() =>
              setPlayAutomatically({
                status: "none",
                value: false,
              })
            }
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <PlayAutomatically
                setPlayAutomatically={setPlayAutomatically}
                isPlayAutomaticallyValid={playAutomatically.status === "valid"}
              />
            </div>
          </Fade>

          <Fade
            timeout={{ enter: enterDuration, exit: exitDuration }}
            in={playAutomatically.status === "valid"}
            unmountOnExit
          >
            <div style={{ width: "100%" }}>
              <SignUpCard status="default">
                <div className={signUpShared.cardContent}>
                  <form
                    className={signUpShared.cardForm}
                    noValidate
                    style={{ padding: "5px 26px 0 26px" }}
                  >
                    <Button
                      color="primary"
                      className={signUpShared.actionBtnStretch}
                      variant="contained"
                      type="button"
                      onClick={() => onSignUp()}
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <CircularProgress color="inherit" size="1rem" />
                      )}
                      <Typography variant="button">Sign up</Typography>
                    </Button>
                  </form>
                </div>
                {error && (
                  <Typography color="error" variant="caption" marginTop="4px">
                    Something went wrong. Please try again!
                  </Typography>
                )}
              </SignUpCard>
            </div>
          </Fade>
        </div>
      </Card>
      <Card
        className={styles.congratulationsCardWrapper}
        sx={{
          display: isDialogOpened ? "flex" : "none",
        }}
      >
        <Typography
          align="center"
          variant="h6"
          fontWeight={400}
          lineHeight="1.9rem"
          px="10px"
        >
          Congratulations! You&apos;ve successfully signed up and created your
          account.
        </Typography>
        <Button
          color="primary"
          className={signUpShared.actionBtnStretch}
          variant="contained"
          type="button"
          onClick={() => {
            navigate("/home");
            setIsDialogOpened(false);
          }}
        >
          <Typography fontSize="16px">
            <span>Let&apos;s go!</span>
          </Typography>
        </Button>
      </Card>
    </UnauthorizedWrapper>
  );
};
