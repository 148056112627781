import { Card } from "@mui/material";
import type { FC, PropsWithChildren } from "react";

import { LogoSmallIcon } from "../../../../media/icons/logo-small";
import styles from "./sign-up-card.module.scss";

interface ISignUpCardProps {
  status?: "default" | "valid";
}

export const SignUpCard: FC<PropsWithChildren<ISignUpCardProps>> = ({
  children,
  status = "default",
}) => (
  <div className={styles.cardWrapper}>
    <div>
      <LogoSmallIcon status={status} width={48} height={48} />
      <span className={styles.logoText}>SF</span>
    </div>
    <Card
      className={styles.cardContentWrapper}
      sx={{ padding: "6px 12px 11px 10px", borderRadius: "0 12px 12px 12px" }}
    >
      {children}
    </Card>
  </div>
);
