import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { IFetchWalletsParams } from "./interfaces/fetch-wallets-params.interface";
import type { IFetchWalletsResponse } from "./interfaces/fetch-wallets-response.interface";

export const walletApi = createApi({
  reducerPath: "walletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    fetchWallets: build.mutation<IFetchWalletsResponse, IFetchWalletsParams>({
      query: (data: IFetchWalletsParams) => ({
        url: ENDPOINTS.WALLETS,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const { useFetchWalletsMutation } = walletApi;
