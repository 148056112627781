import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useGetPaymentMethodsMutation } from "../../../services/payment-methods/payment-methods.service";
import { paymentMethodReducer } from "../../../store/reducers/payment-methods/payment-methods";
import { PaymentMethodTypes } from "../../select-payment-method/enums";
import AddPaymentMethod from "../../select-payment-method/select-payment-method";
import type { PaymentMethodsResponseInterface } from "../interfaces/payment-method.interface";
import PaymentMethodCard from "../payment-method-card/payment-method-card";
import styles from "./payment-methods.module.scss";

export default function PaymentMethods() {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedStep, setSelectedStep] = useState("");
  const [collectedData, setCollectedData] = useState({});
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);

  const [getPaymentMethods, { isLoading }] = useGetPaymentMethodsMutation();

  const { paymentMethods } = useAppSelector(
    (item) => item.paymentMethodReducer
  );

  const { setPaymentMethods } = paymentMethodReducer.actions;

  const dispatch = useAppDispatch();

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const handleFetchPaymentMethods = useCallback(async () => {
    const { data }: any = await getPaymentMethods({
      accessToken,
    });

    if (data) {
      dispatch(setPaymentMethods(data.data));
    }
  }, []);

  useEffect(() => {
    handleFetchPaymentMethods();
  }, []);

  const handleEditMethod = (method: PaymentMethodsResponseInterface) => {
    if (method.bank_account) {
      setEdit(true);
      setCollectedData({
        bank_account: method.bank_account,
        method_id: method.id,
      });
      setSelectedMethods([PaymentMethodTypes.BANK_ACCOUNT]);
      setSelectedStep(PaymentMethodTypes.BANK_ACCOUNT);
      setOpen(true);
    }
    if (method.card) {
      setEdit(true);
      setOpen(true);
      setCollectedData({ credit_card: method.card, method_id: method.id });
      setSelectedMethods([PaymentMethodTypes.CREDIT_CARD]);
      setSelectedStep(PaymentMethodTypes.CREDIT_CARD);
    }
    if (method.crypto) {
      setCollectedData({ crypto: method.crypto, method_id: method.id });
      setSelectedMethods([PaymentMethodTypes.CRYPTO]);
      setSelectedStep(PaymentMethodTypes.CRYPTO);
      setEdit(true);
      setOpen(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={paymentMethods.length > 0 ? "39px" : "18px"}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="9px"
        >
          <Typography variant="h4" fontWeight={400}>
            Payment info
          </Typography>
          <Typography variant="body1">
            Explore seamless transactions in the &apos;Payment Methods&apos;
            section.
          </Typography>
        </Box>
        {isLoading ? <LinearProgress sx={{ width: "100%" }} /> : null}
        {paymentMethods.length > 0 && (
          <Box display="flex" flexDirection="column" gap="15px" width="750px">
            {!isLoading &&
              paymentMethods.map((method, index) => (
                <Fragment key={method.id}>
                  {index !== 0 && <Box className={styles.line} />}
                  <PaymentMethodCard
                    method={method}
                    handleEditMethod={handleEditMethod}
                    key={method.id}
                    index={index}
                  />
                </Fragment>
              ))}
          </Box>
        )}
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          data-cy="addPaymentMethod"
          sx={{
            marginTop: "4px",
            padding: "8px 20px",
          }}
        >
          Add payment method
        </Button>
      </Box>
      <AddPaymentMethod
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
        }}
        collectedData={collectedData}
        selectedMethods={selectedMethods}
        selectedStep={selectedStep}
        setCollectedData={setCollectedData}
        setSelectedMethods={setSelectedMethods}
        setSelectedStep={setSelectedStep}
        edit={edit}
      />
    </>
  );
}
