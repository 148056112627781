export const BtcLogo = ({
  width = 15,
  height = 20,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.85 9.45833C12.7059 8.63333 13.2353 7.5 13.2353 6.25C13.2353 3.86667 11.2941 1.9 8.82353 1.68333V0.833333C8.82353 0.375 8.42647 0 7.94118 0C7.45588 0 7.05882 0.375 7.05882 0.833333V1.66667H5.29412V0.833333C5.29412 0.375 4.89706 0 4.41176 0C3.92647 0 3.52941 0.375 3.52941 0.833333V1.66667C1.57941 1.66667 0 3.15833 0 5V15C0 16.8417 1.57941 18.3333 3.52941 18.3333V19.1667C3.52941 19.625 3.92647 20 4.41176 20C4.89706 20 5.29412 19.625 5.29412 19.1667V18.3333H7.05882V19.1667C7.05882 19.625 7.45588 20 7.94118 20C8.42647 20 8.82353 19.625 8.82353 19.1667V18.3333H10.1471C12.8206 18.3333 15 16.275 15 13.75C15 11.7833 13.6853 10.1083 11.85 9.45833ZM1.76471 5C1.76471 4.08333 2.55882 3.33333 3.52941 3.33333H8.38235C10.0853 3.33333 11.4706 4.64167 11.4706 6.25C11.4706 7.85833 10.0853 9.16667 8.38235 9.16667H1.76471V5ZM10.1471 16.6667H3.52941C2.55882 16.6667 1.76471 15.9167 1.76471 15V10.8333H10.1471C11.85 10.8333 13.2353 12.1417 13.2353 13.75C13.2353 15.3583 11.85 16.6667 10.1471 16.6667Z"
      fill="#DD7D00"
    />
  </svg>
);
