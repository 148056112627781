import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";

import signUpShared from "../../sign-up-shared.module.scss";
import type { PlayAutomaticallyType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

// eslint-disable-next-line no-shadow
enum PlayAutomaticallyBtnName {
  yes,
  no,
}

type PlayAutomaticallyProp = {
  setPlayAutomatically: Dispatch<SetStateAction<PlayAutomaticallyType>>;
  isPlayAutomaticallyValid: boolean;
};

export const PlayAutomatically: FC<PlayAutomaticallyProp> = ({
  setPlayAutomatically,
  isPlayAutomaticallyValid,
}) => {
  const onPlayAutomaticallyHandler = (btnName: PlayAutomaticallyBtnName) => {
    switch (btnName) {
      case PlayAutomaticallyBtnName.yes:
        setPlayAutomatically({ status: "valid", value: true });
        break;
      case PlayAutomaticallyBtnName.no:
        setPlayAutomatically({ status: "valid", value: false });
        break;
      default:
        setPlayAutomatically({
          status: "none",
          value: false,
        });
    }
  };

  return (
    <SignUpCard status={isPlayAutomaticallyValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Do you want videos to play automatically?
        </Typography>
        <Typography variant="body2">
          Remember, these settings are flexible and can be adjusted site-wide at
          any time using toggles located at the top of the screen.
        </Typography>
        <form
          className={`${signUpShared.cardForm} ${signUpShared.cardFormJustBtns}`}
          noValidate
        >
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="autoplay-radio-buttons-group"
            row
            className={`${signUpShared.cardFormJustRadios}`}
            onChange={({ target }) =>
              onPlayAutomaticallyHandler(
                Number(target.value) as PlayAutomaticallyBtnName
              )
            }
          >
            <FormControlLabel
              value={PlayAutomaticallyBtnName.yes}
              control={<Radio />}
              label="Yes"
            />

            <FormControlLabel
              value={PlayAutomaticallyBtnName.no}
              control={<Radio />}
              label="No"
            />
            <div />
          </RadioGroup>
        </form>
      </div>
    </SignUpCard>
  );
};
