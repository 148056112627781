import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import useRoundCurrency from "../../../../hooks/round-currency";
import useWalletArray from "../../../../hooks/wallet-array";
import { BitcoinTextIcon } from "../../../../media/icons/bitcoin-text";
import { BtcLogo } from "../../../../media/icons/btc";
import { DollarLogo } from "../../../../media/icons/dollar";
import { EuroLogo } from "../../../../media/icons/euro";
import { useSetUserCurrencyMutation } from "../../../../services/user-currencies/user-currencies";
import { useFetchWalletsMutation } from "../../../../services/wallet/wallet.service";
import { automaticTransfersSlice } from "../../../../store/reducers/automatic-transfers/automatic-transfers";
import { manualTransfersSlice } from "../../../../store/reducers/manual-transfers/manual-transfers";
import { userSlice } from "../../../../store/reducers/user/user-slice";
import { CurrenciesEnum } from "../../../automatic-transfers/enums/currencies.enum";
import { ModalDialog } from "../../modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../modal-dialog/modal-dialog-header/modal-dialog-header";
import { SwitchWithTooltip } from "./switch-with-tooltip";
import styles from "./wallets.module.scss";

type WalletsProp = {
  title: string;
  description?: string | ReactJSXElement;
  footer?: string | ReactJSXElement;
  disableSwitch?: boolean;
};

export const Wallets: FC<WalletsProp> = ({
  title,
  description,
  footer,
  disableSwitch,
}) => {
  const navigate = useNavigate();

  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [fetchWallet, { data: walletData, isLoading, error }] =
    useFetchWalletsMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const { user } = useAppSelector((item) => item.userReducer);

  const [setUserCurrency, { isLoading: isCurrencyLoading }] =
    useSetUserCurrencyMutation();

  const { setCurrencyAccept } = userSlice.actions;
  const { setAutomaticTransfersData } = automaticTransfersSlice.actions;
  const {
    automatic_transfers: automaticTransfers,
    available_currencies: availableCurrencies,
  } = useAppSelector((item) => item.automaticTransfersReducer);

  const { setManualTransfersData } = manualTransfersSlice.actions;
  const {
    manual_transfers: manualTransfers,
    available_currencies: availableManualCurrencies,
  } = useAppSelector((item) => item.manualTransfersReducer);

  const dispatch = useAppDispatch();
  const { roundAmount } = useRoundCurrency();

  const renderLogo = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.USD:
        return (
          <>
            <DollarLogo /> My USD wallet
          </>
        );
      case CurrenciesEnum.EURO:
        return (
          <>
            <EuroLogo /> My EUR wallet
          </>
        );
      case CurrenciesEnum.BTC:
        return (
          <>
            <BtcLogo /> My BTC wallet
          </>
        );
      default:
        return <BtcLogo />;
    }
  };
  const renderCurrency = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.USD:
        return <span style={{ userSelect: "none" }}>$</span>;
      case CurrenciesEnum.EURO:
        return <span style={{ userSelect: "none" }}>€</span>;
      case CurrenciesEnum.BTC:
        return (
          <Box
            sx={{
              transform: "translate(4px, 4px)",
            }}
          >
            <BitcoinTextIcon width={24} height={24} />
          </Box>
        );
      default:
        return <span style={{ userSelect: "none" }}>-</span>;
    }
  };

  const { walletArray } = useWalletArray(walletData?.data, true);

  useEffect(() => {
    fetchWallet({ accessToken: accessToken || "" });
  }, [accessToken]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error]);

  const renderSwitch = (type: CurrenciesEnum) => {
    switch (type) {
      case CurrenciesEnum.USD:
        return user?.accept_usd;
      case CurrenciesEnum.EURO:
        return user?.accept_euro;
      case CurrenciesEnum.BTC:
        return user?.accept_btc;
      default:
        return user?.accept_usd;
    }
  };

  const canDisableSwitch = (currency: CurrenciesEnum) => {
    switch (currency) {
      case CurrenciesEnum.USD:
        return !(user && !user.accept_btc && !user.accept_euro);
      case CurrenciesEnum.EURO:
        return !(user && !user.accept_usd && !user.accept_btc);
      case CurrenciesEnum.BTC:
        return !(user && !user.accept_usd && !user.accept_euro);
      default:
        return false;
    }
  };

  const handleChangeAcceptedCurrencies = async (currency: CurrenciesEnum) => {
    try {
      if (!canDisableSwitch(currency)) return;

      const data = await setUserCurrency({
        accessToken: accessToken || "",
        currency,
      }).unwrap();

      if (!user) {
        return;
      }

      if (data.success) {
        if (data.statusCode === "subscriptionsExsist") {
          setDialogContent(
            `You have ${data.count} active subscriptions that use this currency. These subscriptions will continue to be billed via this currency until the user cancels their subscription. Future subscribers will not be allowed to pay with this currency.`
          );
          setOpenDialog(true);
        }

        const isCurrencyExist = availableCurrencies.filter(
          (curr) => curr.value === currency
        )[0];
        if (isCurrencyExist) {
          dispatch(
            setAutomaticTransfersData({
              automatic_transfers: automaticTransfers,
              available_currencies: availableCurrencies.filter(
                (curr) => curr.value !== currency
              ),
            })
          );
        }

        const isManualCurrencyExist = availableManualCurrencies.filter(
          (curr) => curr.value === currency
        )[0];
        if (isManualCurrencyExist) {
          dispatch(
            setManualTransfersData({
              manual_transfers: manualTransfers,
              available_currencies: availableManualCurrencies.filter(
                (curr) => curr.value !== currency
              ),
            })
          );
        }

        switch (currency) {
          case CurrenciesEnum.USD:
            if (user) {
              dispatch(setCurrencyAccept({ type: "accept_usd" }));
            }
            if (!isCurrencyExist) {
              dispatch(
                setAutomaticTransfersData({
                  automatic_transfers: automaticTransfers,
                  available_currencies: [
                    ...availableCurrencies,
                    {
                      display_text: "USD",
                      value: CurrenciesEnum.USD,
                    },
                  ],
                })
              );
            }
            if (!isManualCurrencyExist) {
              dispatch(
                setManualTransfersData({
                  manual_transfers: manualTransfers,
                  available_currencies: [
                    ...availableManualCurrencies,
                    {
                      display_text: "USD",
                      value: CurrenciesEnum.USD,
                    },
                  ],
                })
              );
            }
            break;
          case CurrenciesEnum.EURO:
            if (user) {
              dispatch(setCurrencyAccept({ type: "accept_euro" }));
              if (!isCurrencyExist) {
                dispatch(
                  setAutomaticTransfersData({
                    automatic_transfers: automaticTransfers,
                    available_currencies: [
                      ...availableCurrencies,
                      {
                        display_text: "EURO",
                        value: CurrenciesEnum.EURO,
                      },
                    ],
                  })
                );
              }
              if (!isManualCurrencyExist) {
                dispatch(
                  setManualTransfersData({
                    manual_transfers: manualTransfers,
                    available_currencies: [
                      ...availableManualCurrencies,
                      {
                        display_text: "EURO",
                        value: CurrenciesEnum.EURO,
                      },
                    ],
                  })
                );
              }
            }
            break;
          case CurrenciesEnum.BTC:
            if (user) {
              dispatch(setCurrencyAccept({ type: "accept_btc" }));
              if (!isCurrencyExist) {
                dispatch(
                  setAutomaticTransfersData({
                    automatic_transfers: automaticTransfers,
                    available_currencies: [
                      ...availableCurrencies,
                      {
                        display_text: "BTC",
                        value: CurrenciesEnum.BTC,
                      },
                    ],
                  })
                );
              }
              if (!isManualCurrencyExist) {
                dispatch(
                  setManualTransfersData({
                    manual_transfers: manualTransfers,
                    available_currencies: [
                      ...availableManualCurrencies,
                      {
                        display_text: "BTC",
                        value: CurrenciesEnum.BTC,
                      },
                    ],
                  })
                );
              }
            }
            break;
          default:
            dispatch(setCurrencyAccept({ type: "accept_usd" }));
            break;
        }
      }
    } catch (err: any) {
      if (err.status === 409) {
        setDialogContent(
          `You’ve scheduled automatic transfers to this account. If you want to delete it, you must first remove it from your automatic transfers schedule.`
        );
        setOpenDialog(true);
      }
    }
  };

  return (
    <Box data-type="Transfer">
      <Typography variant="h4" fontWeight={400}>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ marginTop: "10px" }}>
          {description}
        </Typography>
      )}

      <Box className={styles.walletList}>
        <Box className={styles.walletHeaderWrapper}>
          <Typography
            fontWeight="normal"
            sx={{
              color: "#575757",
              fontWeight: 400,
            }}
          >
            Currencies:
          </Typography>

          <Typography
            fontWeight="normal"
            sx={{
              color: "#575757",
              fontWeight: 400,
            }}
          >
            Wallet On/Off:
          </Typography>

          <Typography
            fontWeight="normal"
            sx={{
              color: "#575757",
              fontWeight: 400,
            }}
          >
            Balance:
          </Typography>
        </Box>

        {walletArray.map((wallet, idx) => (
          <Box key={wallet.type}>
            {idx === 0 && (
              <Box className={styles.line} sx={{ marginTop: "10px" }} />
            )}
            <Box className={styles.walletNameWrapper}>
              <Typography className={styles.walletName} data-type="wallet name">
                {renderLogo(wallet.type)}
              </Typography>
              {!disableSwitch && (
                <SwitchWithTooltip
                  isTooltipNeeded={!canDisableSwitch(wallet.type)}
                  checked={renderSwitch(wallet.type)}
                  disabled={isCurrencyLoading}
                  onChange={() => handleChangeAcceptedCurrencies(wallet.type)}
                />
              )}
              <Typography
                className={styles.walletCurrency}
                fontSize={24}
                fontWeight={600}
                data-type="wallet currency"
              >
                {renderCurrency(wallet.type)}
                {wallet.type === CurrenciesEnum.BTC
                  ? roundAmount(wallet._sum.amount || 0, 5)
                  : roundAmount(wallet._sum.amount || 0, 2)}
              </Typography>
            </Box>
            {idx !== walletArray.length - 1 && <Box className={styles.line} />}
          </Box>
        ))}
        <Box sx={{ width: "100%", marginTop: "25px" }}>{footer}</Box>
      </Box>
      {isLoading && <LinearProgress />}
      <ModalDialog isOpen={openDialog}>
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h6" fontWeight={400}>
            Informational Message
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <Typography>{dialogContent}</Typography>
        </ModalDialogContent>
        <ModalDialogFooter>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={() => setOpenDialog(false)}
            fullWidth
          >
            <Typography fontSize="16px">
              <span>OK</span>
            </Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>
    </Box>
  );
};
