import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { IUserTransferAccounts } from "../../../services/sign-in/interfaces/initialize.interface";

interface TransferAccountsState {
  transferAccounts: IUserTransferAccounts[];
}

const initialState: TransferAccountsState = {
  transferAccounts: [],
};

export const transferAccountsReducer = createSlice({
  name: "transferAccountsReducer",
  initialState,
  reducers: {
    setTransferAccounts(state, action: PayloadAction<IUserTransferAccounts[]>) {
      state.transferAccounts = action.payload;
    },
    deleteTransferAccount(state, action: PayloadAction<{ id: string }>) {
      state.transferAccounts = state.transferAccounts.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateTransferAccount(
      state,
      action: PayloadAction<{
        id: string;
        method: IUserTransferAccounts;
      }>
    ) {
      const foundedMethod = state.transferAccounts.findIndex(
        (item) => item.id === action.payload.id
      );

      state.transferAccounts[foundedMethod] = action.payload.method;
    },
  },
});

export default transferAccountsReducer.reducer;
