import type { TypographyOptions } from "@mui/material/styles/createTypography";

export const createTypography = (): TypographyOptions => ({
  fontFamily: "'Poppins', sans-serif",
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "19px",
    letterSpacing: "0.01rem",
  },
  button: {
    fontWeight: 500,
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "auto",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "1rem",
    display: "block",
    letterSpacing: "0.006rem",
    marginLeft: "2px",
    marginTop: "8px",
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.57,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 600,
    letterSpacing: "0.5px",
    lineHeight: 2.5,
    textTransform: "uppercase",
  },
  h1: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "3.5rem",
    lineHeight: 1.2,
  },
  h2: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "3rem",
    lineHeight: 1.2,
  },
  h3: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "2.25rem",
    lineHeight: 1.2,
  },
  h4: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: 1.2,
  },
  h6: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: 1.2,
    letterSpacing: "0.017rem",
  },
});
