export const quillModules = {
  toolbar: [
    [
      "italic",
      "bold",
      "underline",
      "strike",
      "link",
      { color: [] },
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
  ],
};
