import type { FC } from "react";

type AddUserProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const AddUserIcon: FC<AddUserProp> = ({
  width = 32,
  height = 32,
  disabled = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2871_62618)">
      <path
        d="M15.3337 7.33301H14.0003V5.99967C14.0003 5.82286 13.9301 5.65329 13.8051 5.52827C13.68 5.40325 13.5105 5.33301 13.3337 5.33301C13.1568 5.33301 12.9873 5.40325 12.8623 5.52827C12.7372 5.65329 12.667 5.82286 12.667 5.99967V7.33301H11.3337C11.1568 7.33301 10.9873 7.40325 10.8623 7.52827C10.7372 7.65329 10.667 7.82286 10.667 7.99967C10.667 8.17649 10.7372 8.34605 10.8623 8.47108C10.9873 8.5961 11.1568 8.66634 11.3337 8.66634H12.667V9.99967C12.667 10.1765 12.7372 10.3461 12.8623 10.4711C12.9873 10.5961 13.1568 10.6663 13.3337 10.6663C13.5105 10.6663 13.68 10.5961 13.8051 10.4711C13.9301 10.3461 14.0003 10.1765 14.0003 9.99967V8.66634H15.3337C15.5105 8.66634 15.68 8.5961 15.8051 8.47108C15.9301 8.34605 16.0003 8.17649 16.0003 7.99967C16.0003 7.82286 15.9301 7.65329 15.8051 7.52827C15.68 7.40325 15.5105 7.33301 15.3337 7.33301Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
      <path
        d="M6 8C6.79113 8 7.56449 7.76541 8.22228 7.32588C8.88008 6.88635 9.39277 6.26164 9.69552 5.53074C9.99827 4.79983 10.0775 3.99556 9.92314 3.21964C9.7688 2.44372 9.38784 1.73098 8.82843 1.17157C8.26902 0.612165 7.55629 0.231202 6.78036 0.0768607C6.00444 -0.0774802 5.20017 0.00173314 4.46927 0.304484C3.73836 0.607234 3.11365 1.11992 2.67412 1.77772C2.2346 2.43552 2 3.20888 2 4C2.00106 5.06054 2.42283 6.07734 3.17274 6.82726C3.92266 7.57718 4.93946 7.99894 6 8ZM6 1.33334C6.52742 1.33334 7.04299 1.48973 7.48152 1.78275C7.92005 2.07577 8.26185 2.49224 8.46368 2.97951C8.66551 3.46678 8.71832 4.00296 8.61543 4.52024C8.51253 5.03753 8.25856 5.51268 7.88562 5.88562C7.51268 6.25856 7.03752 6.51254 6.52024 6.61543C6.00296 6.71832 5.46678 6.66551 4.97951 6.46368C4.49224 6.26185 4.07576 5.92005 3.78275 5.48152C3.48973 5.04299 3.33333 4.52742 3.33333 4C3.33333 3.29276 3.61428 2.61448 4.11438 2.11438C4.61448 1.61429 5.29276 1.33334 6 1.33334Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
      <path
        d="M6 9.33301C4.40924 9.33477 2.88414 9.96748 1.75931 11.0923C0.634472 12.2172 0.00176457 13.7423 0 15.333C0 15.5098 0.0702379 15.6794 0.195262 15.8044C0.320286 15.9294 0.489856 15.9997 0.666667 15.9997C0.843478 15.9997 1.01305 15.9294 1.13807 15.8044C1.2631 15.6794 1.33333 15.5098 1.33333 15.333C1.33333 14.0953 1.825 12.9083 2.70017 12.0332C3.57534 11.158 4.76232 10.6663 6 10.6663C7.23768 10.6663 8.42466 11.158 9.29983 12.0332C10.175 12.9083 10.6667 14.0953 10.6667 15.333C10.6667 15.5098 10.7369 15.6794 10.8619 15.8044C10.987 15.9294 11.1565 15.9997 11.3333 15.9997C11.5101 15.9997 11.6797 15.9294 11.8047 15.8044C11.9298 15.6794 12 15.5098 12 15.333C11.9982 13.7423 11.3655 12.2172 10.2407 11.0923C9.11586 9.96748 7.59076 9.33477 6 9.33301Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_2871_62618">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
