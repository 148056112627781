import { Box, Typography } from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import useSubmitState from "../../../../hooks/submit-state";
import type {
  IBackyardBanishmentUpdate,
  IPrivacy,
} from "../../../../services/settings/interfaces/settings.interface";
import {
  useGetPrivacySettingsMutation,
  useUpdatePrivacySettingsMutation,
} from "../../../../services/settings/settings.service";
import { MaterialUISwitch } from "../../../shared/material-ui-switch/material-ui-switch";
import { SecurityPrivacyTooltip } from "../security-privacy-tooltip/security-privacy-tooltip";
import { Banishment } from "./banishment/banishment";
import { BirthdaySpecial } from "./birthday-special/birthday-special";
import styles from "./privacy.module.scss";

export const Privacy = () => {
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const [privacyData, setPrivacyData] = useState<IPrivacy | null>(null);
  const [banishmentEnabled, setBanishmentEnabled] = useState<boolean>(false);

  const [getPrivacySettings, { data: privacyDataResponse }] =
    useGetPrivacySettingsMutation();

  const [
    updateBirthdaySpecialSetting,
    { data: updatedBirthdaySpecialSettingResponse },
  ] = useUpdatePrivacySettingsMutation();

  const [
    updatePrivacySettings,
    {
      data: updatedPrivacyDataResponse,
      error: updatePrivacyError,
      isSuccess: updatePrivacySuccess,
      reset: resetState,
    },
  ] = useUpdatePrivacySettingsMutation();

  const submitState = useSubmitState({
    requestError: updatePrivacyError as FetchBaseQueryError,
    defaultText: "Apply",
    successText: "Applied",
    isRequestSuccess: updatePrivacySuccess,
  });

  const [
    updateBirthdaySpecialDate,
    {
      data: updatedBirthdaySpecialDateResponse,
      error: updateBirthdaySpecialDateError,
      isSuccess: updateBirthdaySpecialDateSuccess,
      reset: resetBirthdaySpecialDateState,
      isLoading: isLoadingUpdateBirthdayDateSpecial,
    },
  ] = useUpdatePrivacySettingsMutation();

  const submitBirthdaySpecialState = useSubmitState({
    requestError: updateBirthdaySpecialDateError as FetchBaseQueryError,
    defaultText: "Apply",
    successText: "Applied",
    isRequestSuccess: updateBirthdaySpecialDateSuccess,
  });

  useEffect(() => {
    getPrivacySettings({ accessToken });
  }, []);

  useEffect(() => {
    if (privacyDataResponse?.data) {
      setPrivacyData(privacyDataResponse.data);
    }
  }, [privacyDataResponse]);

  useEffect(() => {
    if (updatedBirthdaySpecialDateResponse?.data) {
      setPrivacyData(updatedBirthdaySpecialDateResponse.data);
    }
  }, [updatedBirthdaySpecialDateResponse]);

  useEffect(() => {
    if (updatedBirthdaySpecialSettingResponse?.data) {
      setPrivacyData(updatedBirthdaySpecialSettingResponse.data);
    }
  }, [updatedBirthdaySpecialSettingResponse]);

  useEffect(() => {
    if (updatedPrivacyDataResponse?.data) {
      setPrivacyData(updatedPrivacyDataResponse.data);
    }
  }, [updatedPrivacyDataResponse]);

  useEffect(() => {
    const hasBanishment = !!privacyData?.backyard_banishment;
    setBanishmentEnabled(hasBanishment);
  }, [privacyData]);

  const onFindabilityChange = async (state: boolean) => {
    await updatePrivacySettings({
      accessToken,
      findability: state,
    });
  };

  const onDontCreateCookiesChange = async (state: boolean) => {
    await updatePrivacySettings({
      accessToken,
      dont_create_cookies: state,
    });
  };

  const onUpdateBackyardBanishment = useCallback(
    async (backyardBanishment: IBackyardBanishmentUpdate | null) => {
      await updatePrivacySettings({
        accessToken,
        backyard_banishment: backyardBanishment,
      });
    },
    [privacyData]
  );

  const onUpdateBirthdaySpecialSetting = useCallback(
    async (state: boolean) => {
      await updateBirthdaySpecialSetting({
        accessToken,
        is_birthday_special: state,
      });
    },
    [privacyData]
  );

  const onUpdateBirthdaySpecialDate = useCallback(
    async (date: string | null) => {
      await updateBirthdaySpecialDate({
        accessToken,
        birthday_special: date,
      });
    },
    [privacyData]
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.title}>
        <Typography variant="body1" fontWeight="bold">
          Privacy
        </Typography>
        <Typography variant="body1">Customize your privacy</Typography>
      </Box>
      <Box className={styles.privacyInputWrapper}>
        <Box className={styles.privacyInput}>
          <Box className={styles.privacyInputLabel}>
            <Typography fontWeight="bold" variant="body1">
              Findability
            </Typography>
            <SecurityPrivacyTooltip
              text="This prevents other users from finding you in search or in
“Suggested content” areas of the site. You can only be found via direct
URL"
            />
          </Box>
          <MaterialUISwitch
            checked={privacyData?.findability === true}
            onChange={() => {
              onFindabilityChange(!privacyData?.findability);
            }}
          />
        </Box>

        <Box className={styles.privacyInput}>
          <Box className={styles.privacyInputLabel}>
            <Typography fontWeight="bold" variant="body1">
              Don’t Create Cookies
            </Typography>
            <SecurityPrivacyTooltip
              text="This limits the ability for sites outside of SF to track you
as a Side Fans user"
            />
          </Box>
          <MaterialUISwitch
            checked={privacyData?.dont_create_cookies === true}
            onChange={() => {
              onDontCreateCookiesChange(!privacyData?.dont_create_cookies);
            }}
          />
        </Box>

        <Box className={styles.privacyBirthday}>
          <Box className={styles.privacyInput}>
            <Box className={styles.privacyInputLabel}>
              <Typography fontWeight="bold" variant="body1">
                Birthday Special Opt-Out
              </Typography>
              <SecurityPrivacyTooltip text="If you enable this option, you will not be able to revert back to your actual birthdate for 1 year" />
            </Box>
            <MaterialUISwitch
              checked={privacyData?.is_birthday_special}
              onChange={() => {
                onUpdateBirthdaySpecialSetting(
                  !privacyData?.is_birthday_special
                );
              }}
            />
          </Box>
          <BirthdaySpecial
            birthdayEnabled={!!privacyData?.is_birthday_special}
            canChange={!!privacyData?.can_change_birthday}
            value={privacyData?.birthday_special || ""}
            onUpdateBirthdaySpecialDate={onUpdateBirthdaySpecialDate}
            submitState={submitBirthdaySpecialState}
            resetState={resetBirthdaySpecialDateState}
            isLoading={isLoadingUpdateBirthdayDateSpecial}
          />
        </Box>

        <Box className={styles.privacyBanishment}>
          <Box className={styles.privacyInput}>
            <Box className={styles.privacyInputLabel}>
              <Typography fontWeight="bold" variant="body1">
                Backyard Banishment
              </Typography>
              <SecurityPrivacyTooltip
                text="You can prevent users within their local area from finding you or viewing
 your content (US-based CCs only)"
              />
            </Box>
            <MaterialUISwitch
              checked={banishmentEnabled}
              onChange={async () => {
                if (banishmentEnabled) {
                  await onUpdateBackyardBanishment(null);
                  return;
                }

                setBanishmentEnabled(!banishmentEnabled);
              }}
            />
          </Box>
          <Banishment
            banishmentEnabled={banishmentEnabled}
            onUpdateBackyardBanishment={onUpdateBackyardBanishment}
            banishmentData={privacyData?.backyard_banishment}
            submitState={submitState}
            resetState={resetState}
          />
        </Box>
      </Box>
    </Box>
  );
};
