import type { FC, PropsWithChildren } from "react";

interface ISearchInCircle {
  width?: number;
  height?: number;
}

export const SearchInCircleIcon: FC<PropsWithChildren<ISearchInCircle>> = ({
  width = 32,
  height = 32,
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g filter="url(#filter0_b_2389_35938)">
        <rect width="24" height="24" rx="12" fill="transparent" />
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="#6995EC"
        />
      </g>
      <path
        d="M16.8831 16.2944L14.3981 13.8094C15.0753 12.9812 15.4083 11.9243 15.3281 10.8575C15.2479 9.79064 14.7608 8.79542 13.9674 8.07767C13.1741 7.35992 12.1352 6.97456 11.0657 7.0013C9.99616 7.02805 8.97784 7.46484 8.22134 8.22134C7.46484 8.97784 7.02805 9.99616 7.0013 11.0657C6.97456 12.1352 7.35992 13.1741 8.07767 13.9674C8.79542 14.7608 9.79064 15.2479 10.8575 15.3281C11.9243 15.4083 12.9812 15.0753 13.8094 14.3981L16.2944 16.8831C16.373 16.959 16.4781 17.0009 16.5873 17C16.6965 16.999 16.8009 16.9553 16.8781 16.8781C16.9553 16.8009 16.999 16.6965 17 16.5873C17.0009 16.4781 16.959 16.373 16.8831 16.2944ZM11.1766 14.5072C10.5178 14.5072 9.8739 14.3118 9.32619 13.9459C8.77848 13.5799 8.35159 13.0597 8.0995 12.4511C7.84742 11.8425 7.78146 11.1729 7.90997 10.5268C8.03848 9.88073 8.35569 9.28728 8.82148 8.82148C9.28728 8.35569 9.88073 8.03848 10.5268 7.90997C11.1729 7.78146 11.8425 7.84742 12.4511 8.0995C13.0597 8.35159 13.5799 8.77848 13.9459 9.32619C14.3118 9.8739 14.5072 10.5178 14.5072 11.1766C14.5062 12.0596 14.155 12.9062 13.5306 13.5306C12.9062 14.155 12.0596 14.5062 11.1766 14.5072Z"
        fill="#6995EC"
      />
    </svg>
  </>
);
