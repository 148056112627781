import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ReturnBackIcon } from "../../../media/icons/return-back";
import { CatalogSearchBarCs } from "./catalog-search-bar-cs/catalog-search-bar-cs";
import { ContentCsCollection } from "./content-cs-collection/content-cs-collection";
import { useCreatorCatalogCSViewContext } from "./context/creator-catalog-cs-view-context";
import styles from "./creator-catalog-cs-view.module.scss";
import { SideBarCsView } from "./sidebar-cs-view/sidebar-cs-view";

export const CreatorCatalogCsView = () => {
  const { isPreview } = useCreatorCatalogCSViewContext();

  return (
    <Box className={styles.wrapper}>
      {isPreview && (
        <Box className={styles.previewWrapper}>
          <Box
            sx={{
              width: "fit-content",
            }}
          >
            <Link
              to="/cc/settings"
              style={{
                color: "#0F70CA",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
                gap: "5px",
              }}
            >
              <ReturnBackIcon color="#0F70CA" width={18} height={15} />
              <Typography>Back to profile settings</Typography>
            </Link>
          </Box>
          <Typography fontSize="32px" color="#3C3C3C">
            Profile preview
          </Typography>
        </Box>
      )}
      <div className={styles.container}>
        <SideBarCsView />
        <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
          <CatalogSearchBarCs />
        </div>
        <Box
          sx={{
            marginTop: "22px",
          }}
        >
          <ContentCsCollection />
        </Box>
      </div>
    </Box>
  );
};
