import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { ENDPOINTS } from "../../constants/api/api";
import { maxLengthOfDisplayName } from "../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import useFileUploader from "../../hooks/file-upload";
import { useQuery } from "../../hooks/query-params";
import { useAppSelector } from "../../hooks/redux";
import useQueryParam from "../../hooks/set-query-param";
import useSubmitState from "../../hooks/submit-state";
import { MagicStickIcon } from "../../media/icons/magic-stick";
import { IUpdatePersonalInfo } from "../../services/settings/interfaces/settings.interface";
import { useUpdateSettingsMutation } from "../../services/settings/settings.service";
import { ModalWrapper } from "../creator-card/modal-wrapper/modal-wrapper";
import { DeleteAccount } from "../settings-cc/security-privacy/delete-account/delete-account";
import { Security } from "../settings-cc/security-privacy/security/security";
import { AvatarLoader } from "../shared/avatar-loader/avatar-loader";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { BecomeCreator } from "./become-creator/become-creator";
import styles from "./settings-cs.module.scss";

interface IFormValues {
  avatar: string | File | null;
  info_on_update: {
    username: string;
  };
}

export const SettingsCs = () => {
  const { setParam } = useQueryParam();
  const queryParams = useQuery();
  const isAvatarNotSet = queryParams.get("avatar") === "false";
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { user } = useAppSelector((item) => item.userReducer);

  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const [showBecomeCreator, setShowBecomeCreator] = useState<boolean>(false);

  const {
    uploadFile: uploadAvatar,
    errorCode: errorCodeAvatar,
    errorMessage: errorMessageAvatar,
    reset: resetAvatar,
    isSuccess: isUploadAvatarSuccess,
  } = useFileUploader(
    `${process.env.REACT_APP_BASE_URL}${ENDPOINTS.UPLOAD_AVATAR}`
  );

  const [
    updateSettings,
    {
      isLoading: isLoadingUpdate,
      error: updateSettingsError,
      isSuccess: isUpdateSettingsSuccess,
      reset,
    },
  ] = useUpdateSettingsMutation();

  const formik = useFormik<IFormValues>({
    initialValues: {
      avatar: null,
      info_on_update: {
        username: user?.username || "",
      },
    },
    validationSchema: Yup.object({
      info_on_update: Yup.object({
        username: Yup.string()
          .required("Username is required")
          .max(
            maxLengthOfDisplayName,
            `Username must be at most ${maxLengthOfDisplayName} characters`
          ),
      }),
    }),
    onSubmit: async (values) => {
      const updateObj: IUpdatePersonalInfo = {
        accessToken: accessToken || "",
        body: values.info_on_update,
      };
      updateSettings(updateObj);

      try {
        if (values.avatar instanceof File) {
          setIsAvatarUploading(true);
          await uploadAvatar(values.avatar);
          setIsAvatarUploading(false);
        }
      } catch (e) {
        setIsAvatarUploading(false);
      }
    },
  });

  const onAvatarUpload = useCallback(
    (file: File) => {
      resetAvatar();
      formik.setFieldValue("avatar", file);
    },
    [formik]
  );

  const { text, color, errorCode } = useSubmitState({
    requestError: updateSettingsError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: isUpdateSettingsSuccess,
  });

  useEffect(() => {
    formik.setFieldValue("avatar", user?.avatar?.backend_media_url);
  }, [user]);

  useEffect(() => {
    if (isUploadAvatarSuccess) {
      setParam("", "");
    }
  }, [isUploadAvatarSuccess]);

  const handleCloseBecomeCreator = () => {
    setShowBecomeCreator(false);
  };

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.wrapper}>
          <Box className={styles.header}>
            <Typography color="#3C3C3C" fontSize={32}>
              Profile settings
            </Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                setShowBecomeCreator(true);
              }}
            >
              <MagicStickIcon height={20} width={20} />
              Become a creator
            </Button>
          </Box>

          <Box
            sx={{
              width: "622px",
            }}
          >
            <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <Box className={styles.avatarSection}>
                <AvatarLoader
                  onAvatarUpload={onAvatarUpload}
                  error={isAvatarNotSet}
                  avatar={
                    typeof formik.values.avatar === "string"
                      ? formik.values.avatar
                      : null
                  }
                />
                <Box className={styles.avatarDescription}>
                  <Typography variant="body1" fontWeight="bold">
                    Avatar
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    This will be displayed on your profile
                    {isAvatarNotSet && !formik.values.avatar && (
                      <Typography
                        variant="caption"
                        color="error"
                        fontWeight={500}
                      >
                        Please add avatar to your profile
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box className={styles.representationSection}>
                <Box>
                  <TextField
                    name="info_on_update.username"
                    placeholder="Enter display name"
                    fullWidth
                    error={!!formik.errors.info_on_update?.username}
                    value={formik.values.info_on_update.username}
                    onChange={formik.handleChange}
                  />
                  <Typography
                    variant="caption"
                    align="right"
                    color={
                      formik.errors.info_on_update?.username
                        ? "#FF5252"
                        : "#989898"
                    }
                    marginTop="3px"
                  >
                    {formik.values.info_on_update.username
                      ? formik.values.info_on_update.username.length
                      : 0}
                    /{maxLengthOfDisplayName}
                  </Typography>
                </Box>
              </Box>
              <Box
                margin="20px 0"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  sx={{ width: "237px" }}
                  variant="contained"
                  type={
                    color === "primary" || !errorCodeAvatar
                      ? "submit"
                      : "button"
                  }
                  color={errorCodeAvatar ? "error" : color}
                  disabled={
                    !formik.isValid || isLoadingUpdate || isAvatarUploading
                  }
                  onClick={(e) => {
                    if (color === "error" || errorCodeAvatar) {
                      e.preventDefault();
                      reset();
                      resetAvatar();
                    }

                    if (color === "success") {
                      e.preventDefault();
                    }
                  }}
                >
                  {isLoadingUpdate || isAvatarUploading ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    <Typography variant="button">
                      {errorCodeAvatar ? "Retry" : text}
                    </Typography>
                  )}
                </Button>
                {errorCode && (
                  <Typography
                    variant="body1"
                    color="error"
                    mt={1}
                    textAlign="center"
                  >
                    Oops... Something went wrong. Error {errorCode}
                  </Typography>
                )}
                {errorCodeAvatar === 400 &&
                  errorMessageAvatar === "File type is not supported" && (
                    <Typography
                      variant="body1"
                      color="error"
                      mt={1}
                      textAlign="center"
                    >
                      File type is not supported
                    </Typography>
                  )}
              </Box>
            </form>

            <Security isLearnMoreShown />
            <Box
              marginTop="20px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <DeleteAccount>
                <Box
                  sx={{
                    padding: "0 70px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    color="#252733"
                  >
                    By deleting your account, you will lose access to all
                    purchased content and your subscription history.
                  </Typography>
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    color="#252733"
                  >
                    Please note:
                  </Typography>
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    color="#252733"
                  >
                    <strong>Loss of Access:</strong> All purchased content will
                    be permanently inaccessible.
                  </Typography>
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    color="#252733"
                  >
                    <strong>Subscription Cancellation:</strong> Your
                    subscription to premium content will be terminated
                    immediately.
                  </Typography>
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    color="#252733"
                  >
                    <strong>No Refunds:</strong> Deleting your account does not
                    entitle you to refunds for any remaining subscription
                    periods.
                  </Typography>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link>Read the CS Agreement</Link>
                  <Typography
                    align="left"
                    variant="body1"
                    fontWeight={400}
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    Are you sure you want to delete account?
                  </Typography>
                </Box>
              </DeleteAccount>
            </Box>
          </Box>
        </Box>
      </MainWrapper>

      <ModalWrapper
        shown={showBecomeCreator}
        close={handleCloseBecomeCreator}
        disableBackdropClose
      >
        <BecomeCreator onClose={handleCloseBecomeCreator} />
      </ModalWrapper>
    </Box>
  );
};
