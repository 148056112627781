import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";

import signUpShared from "../../sign-up-shared.module.scss";
import type { SoundMutedType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

// eslint-disable-next-line no-shadow
enum SoundMutedBtnName {
  yes,
  no,
}

type SoundMutedProp = {
  setSoundMuted: Dispatch<SetStateAction<SoundMutedType>>;
  isSoundMutedValid: boolean;
};

export const SoundMuted: FC<SoundMutedProp> = ({
  setSoundMuted,
  isSoundMutedValid,
}) => {
  const onSoundMutedHandler = (btnName: SoundMutedBtnName) => {
    switch (btnName) {
      case SoundMutedBtnName.yes:
        setSoundMuted({ status: "valid", value: true });
        break;
      case SoundMutedBtnName.no:
        setSoundMuted({ status: "valid", value: false });
        break;
      default:
        setSoundMuted({
          status: "none",
          value: false,
        });
    }
  };

  return (
    <SignUpCard status={isSoundMutedValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Do you want all video sound muted?
        </Typography>
        <Typography variant="body2">
          Remember, these settings are flexible and can be adjusted site-wide at
          any time using the Sound button located at the top of the screen.
        </Typography>
        <form
          className={`${signUpShared.cardForm} ${signUpShared.cardFormJustBtns}`}
          noValidate
        >
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="sound-radio-buttons-group"
            row
            className={`${signUpShared.cardFormJustRadios}`}
            onChange={({ target }) =>
              onSoundMutedHandler(Number(target.value) as SoundMutedBtnName)
            }
          >
            <FormControlLabel
              value={SoundMutedBtnName.yes}
              control={<Radio />}
              label="Yes"
            />

            <FormControlLabel
              value={SoundMutedBtnName.no}
              control={<Radio />}
              label="No"
            />
            <div />
          </RadioGroup>
        </form>
      </div>
    </SignUpCard>
  );
};
