import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { IPersonalInfo } from "../../../services/settings/interfaces/settings.interface";
import styles from "./profile-tags.module.scss";

interface IProfileTagsProps {
  personalInfoParameters: IPersonalInfo;
}

export const ProfileTags: FC<IProfileTagsProps> = ({
  personalInfoParameters,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {personalInfoParameters.tags.length > 0 && (
        <Box className={styles.tagsGroup}>
          <Typography variant="body1" fontWeight="bold">
            Tags:
          </Typography>
          {personalInfoParameters.tags.map((tag) => (
            <button
              key={tag}
              type="button"
              className={styles.tagButton}
              onClick={() => {
                navigate(`/home?searchTag=${tag}`);
              }}
            >
              <Typography variant="button">{tag}</Typography>
            </button>
          ))}
        </Box>
      )}
    </>
  );
};
