import type { FC, PropsWithChildren } from "react";

import styles from "./modal-dialog-header.module.scss";

type ModalDialogHeaderProp = {
  position?: "left" | "center" | "right";
};

export const ModalDialogHeader: FC<
  PropsWithChildren<ModalDialogHeaderProp>
> = ({ children, position = "left" }) => (
  <header
    className={`${styles.modalDialogHeader}
    ${position === "left" && styles.left}
    ${position === "center" && styles.center}
    ${position === "right" && styles.right}`}
  >
    {children}
  </header>
);
