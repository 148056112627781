import { Box } from "@mui/material";

import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import PaymentHistoryTable from "./payment-history/payment-history";
import styles from "./payment-info.module.scss";
import PaymentMethods from "./payment-methods/payment-methods";

export default function PaymentInfo() {
  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.mainCardWrapper}>
          <PaymentMethods />
          <PaymentHistoryTable />
        </Box>
      </MainWrapper>
    </Box>
  );
}
