import { Box, Button, Card, Link, Typography } from "@mui/material";
import type { FC, PropsWithChildren } from "react";
import { useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { UnauthorizedWrapper } from "../shared/unauthorized-wrapper/unauthorized-wrapper";
import protectedRoute from "./protected-route.module.scss";

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  useEffect(() => {
    const isAgeConfirmed = window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.AGE_VERIFIED
    );

    if (isAgeConfirmed === String(true)) {
      setHasAccess(true);
      return;
    }

    setHasAccess(false);
  }, []);

  const onLeaveHandler = () => {
    window.location.replace("about:blank");
  };

  const onAcceptHandler = () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.AGE_VERIFIED, String(true));
    setHasAccess(true);
  };

  return (
    <>
      {!hasAccess ? (
        <UnauthorizedWrapper>
          <Card className={protectedRoute.cardWrapper}>
            <Typography align="center" variant="h4" fontWeight={400}>
              TERMS OF USE
            </Typography>

            <div className={protectedRoute.termsContent}>
              <Typography
                align="left"
                variant="body1"
                fontWeight={400}
                lineHeight="22px"
              >
                SideFans contains age-restricted content.
              </Typography>
              <Typography
                align="left"
                variant="body1"
                fontWeight={400}
                lineHeight="22px"
              >
                To use SideFans, you must be at least 18 years old and the age
                of majority and legal consent under the laws of the applicable
                jurisdiction from which you are accessing this website.
              </Typography>
              <Typography
                align="left"
                variant="body1"
                fontWeight={400}
                lineHeight="22px"
              >
                By clicking the “Enter SideFans” button, and by entering
                SideFans, you hereby (1) agree to these{" "}
                <Link href="/terms-of-use">Terms of Use</Link>; and (2) under
                penalty of perjury, certify that you are above the age of 18 or
                the age of majority in your location, whichever is greater.
              </Typography>
            </div>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="20px"
            >
              <Button
                color="primary"
                className={protectedRoute.actionBtn}
                variant="contained"
                type="button"
                onClick={() => {
                  onAcceptHandler();
                }}
              >
                <Typography fontSize="16px">
                  <span>Enter SideFans</span>
                </Typography>
              </Button>
              <Button
                color="info"
                className={protectedRoute.actionBtn}
                variant="contained"
                type="button"
                onClick={() => {
                  onLeaveHandler();
                }}
              >
                <Typography fontSize="16px">
                  <span>Leave SideFans</span>
                </Typography>
              </Button>
            </Box>
          </Card>
        </UnauthorizedWrapper>
      ) : (
        children
      )}
    </>
  );
};
