export const BankAcc = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.5 31.49"
  >
    <path
      d="M29.06,6.2,19.07,1a6.78,6.78,0,0,0-6.14,0L2.93,6.2A6,6,0,0,0,.8,8.12,3.09,3.09,0,0,0,.25,9.67a3,3,0,0,0,.36,1.61h0a3.37,3.37,0,0,0,3,1.8h.64V24.24H2.67a1.09,1.09,0,0,0-1.09,1.09,1.09,1.09,0,0,0,1.09,1.08H29.33a1.06,1.06,0,0,0,.77-.32,1.07,1.07,0,0,0,.32-.76,1.12,1.12,0,0,0-.32-.77,1.1,1.1,0,0,0-.77-.32H27.75V13.08h.64a3.46,3.46,0,0,0,1.75-.48,3.38,3.38,0,0,0,1.25-1.32,3,3,0,0,0,.36-1.6,3.12,3.12,0,0,0-.55-1.56A6,6,0,0,0,29.06,6.2Zm-18.14,18H6.42V13.08h4.5Zm8,0H13.08V13.08h5.84Zm6.66,0h-4.5V13.08h4.5Zm3.88-13.95a1.21,1.21,0,0,1-.44.46,1.29,1.29,0,0,1-.64.16H3.59a1.25,1.25,0,0,1-.6-.16,1.22,1.22,0,0,1-.46-.47.84.84,0,0,1-.11-.47.76.76,0,0,1,.17-.46A3.77,3.77,0,0,1,3.93,8.12l10-5.21a4.55,4.55,0,0,1,4.13,0l10,5.21a4,4,0,0,1,1.36,1.23.81.81,0,0,1,.15.46A.8.8,0,0,1,29.46,10.29Z"
      transform="translate(-0.25 -0.26)"
    />
    <path
      d="M1.33,31.74H30.67a1.08,1.08,0,0,0,1.08-1.08,1.12,1.12,0,0,0-.32-.77,1.08,1.08,0,0,0-.76-.31H1.33a1.08,1.08,0,0,0-.76.31,1.14,1.14,0,0,0-.32.77,1.08,1.08,0,0,0,1.08,1.08Z"
      transform="translate(-0.25 -0.26)"
    />
  </svg>
);
