import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { automaticTransfersApi } from "../services/automatic-transfers/automatic-transfers";
import { blockUserApi } from "../services/block-users/block-users";
import { catalogApi } from "../services/catalog/catalog.service";
import { earningsApi } from "../services/earnings/earnings.service";
import { checkVerificationApi } from "../services/get-verification/check-verification.service";
import { homeApi } from "../services/home/home.service";
import { invitationsApi } from "../services/invitations/invitations.service";
import { inviteUserApi } from "../services/invite-user/invite-user.service";
import { manageSubscribersApi } from "../services/manage-subscribers/manage-subscribers.service";
import { manualTransfersApi } from "../services/manual-transfers/manual-transfers";
import { paymentMethodsApi } from "../services/payment-methods/payment-methods.service";
import { requestResetPasswordApi } from "../services/request-reset-password/request-reset-password.service";
import { resetPasswordApi } from "../services/reset-password/reset-password.service";
import { settingsApi } from "../services/settings/settings.service";
import { signInApi } from "../services/sign-in/sign-in.service";
import { signUpApi } from "../services/sign-up/sign-up.service";
import { subscriptionsApi } from "../services/subscriptions/subscriptions.service";
import { tipsApi } from "../services/tips/tips.service";
import { transferAccountsApi } from "../services/transfer-accounts/transfer-accounts.service";
import { userCurrenciesApi } from "../services/user-currencies/user-currencies";
import { walletApi } from "../services/wallet/wallet.service";
import automaticTransfersReducer from "./reducers/automatic-transfers/automatic-transfers";
import invitationsReducer from "./reducers/invitations/invitations";
import lastSearchReducer from "./reducers/last-search/last-search";
import loaderReducer from "./reducers/loading/loading";
import interestedUsersReducer from "./reducers/manage-subscribers/interested-users";
import manageSubscribersModalReducer from "./reducers/manage-subscribers/manage-subscribers-modal";
import mySubscribersTableReducer from "./reducers/manage-subscribers/my-subscribers-table";
import subscriberGroupsReducer from "./reducers/manage-subscribers/subscriber-groups";
import manualTransfersReducer from "./reducers/manual-transfers/manual-transfers";
import paymentMethodReducer from "./reducers/payment-methods/payment-methods";
import purchaseHistoryReducer from "./reducers/purchase-history/purchase-history";
import activeSubscriptionsReducer from "./reducers/subscriptions/active-subscriptions";
import cancelledSubscriptionsReducer from "./reducers/subscriptions/cancelled-subscriptions";
import totalEarningsReducer from "./reducers/transactions-history/total-earnings";
import transactionHistoryReducer from "./reducers/transactions-history/transaction-history";
import transferAccountsReducer from "./reducers/transfer-accounts/transfer-accounts";
import transferHistoryReducer from "./reducers/transfer-accounts/transfer-history";
import userReducer from "./reducers/user/user-slice";

const rootReducer = combineReducers({
  automaticTransfersReducer,
  manualTransfersReducer,
  userReducer,
  invitationsReducer,
  loaderReducer,
  paymentMethodReducer,
  manageSubscribersModalReducer,
  mySubscribersTableReducer,
  subscriberGroupsReducer,
  activeSubscriptionsReducer,
  cancelledSubscriptionsReducer,
  purchaseHistoryReducer,
  transactionHistoryReducer,
  totalEarningsReducer,
  interestedUsersReducer,
  transferAccountsReducer,
  transferHistoryReducer,
  lastSearchReducer,
  [signInApi.reducerPath]: signInApi.reducer,
  [requestResetPasswordApi.reducerPath]: requestResetPasswordApi.reducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [checkVerificationApi.reducerPath]: checkVerificationApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer,
  [inviteUserApi.reducerPath]: inviteUserApi.reducer,
  [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
  [manageSubscribersApi.reducerPath]: manageSubscribersApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [earningsApi.reducerPath]: earningsApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [automaticTransfersApi.reducerPath]: automaticTransfersApi.reducer,
  [manualTransfersApi.reducerPath]: manualTransfersApi.reducer,
  [transferAccountsApi.reducerPath]: transferAccountsApi.reducer,
  [userCurrenciesApi.reducerPath]: userCurrenciesApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [blockUserApi.reducerPath]: blockUserApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [tipsApi.reducerPath]: tipsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      signUpApi.middleware,
      signInApi.middleware,
      requestResetPasswordApi.middleware,
      resetPasswordApi.middleware,
      checkVerificationApi.middleware,
      invitationsApi.middleware,
      inviteUserApi.middleware,
      paymentMethodsApi.middleware,
      manageSubscribersApi.middleware,
      subscriptionsApi.middleware,
      earningsApi.middleware,
      walletApi.middleware,
      automaticTransfersApi.middleware,
      manualTransfersApi.middleware,
      transferAccountsApi.middleware,
      userCurrenciesApi.middleware,
      settingsApi.middleware,
      blockUserApi.middleware,
      homeApi.middleware,
      catalogApi.middleware,
      tipsApi.middleware
    ),
  devTools: process.env.NODE_ENV !== "production",
});

export const setupStore = () => store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

interface CypressWithStore extends Cypress.Cypress {
  store?: typeof store;
}

declare global {
  interface Window {
    Cypress?: CypressWithStore;
  }
}

if (typeof window !== "undefined" && window.Cypress) {
  window.Cypress.store = store;
}
