import {
  Badge,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { ListIcon } from "../../../media/icons/list";
import { TileIcon } from "../../../media/icons/tile";
import { useGetContentCountMutation } from "../../../services/home/home.service";
import {
  IContentCount,
  LastSearchType,
} from "../../../services/home/interfaces/home.interface";
import { SearchBar } from "../../shared/search-bar/search-bar";
import { ActiveTab, ActiveView } from "../interfaces/temporary.interface";
import styles from "./home-search-bar.module.scss";

interface IHomeSearchBarProp {
  tab: ActiveTab;
  view: ActiveView;
  handleTabChange: (nextView: ActiveTab) => void;
  handleViewChange: (nextView: ActiveView) => void;
  handleSearchTextChange: (text: string) => void;
}

export const HomeSearchBar: FC<IHomeSearchBarProp> = ({
  handleTabChange,
  handleViewChange,
  view,
  tab,
  handleSearchTextChange,
}) => {
  const [getContentCount] = useGetContentCountMutation();
  const contentCountInitialState: IContentCount = {
    subscribed_content_count: 0,
    interested_content_count: 0,
  };
  const accessTokenValue = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [contentCount, setContentCount] = useState<IContentCount>(
    contentCountInitialState
  );

  const fetchContentCount = useCallback(async () => {
    setContentCount(contentCountInitialState);

    await getContentCount({
      accessToken: accessTokenValue || "",
    })
      .unwrap()
      .then((data) => {
        setContentCount(data.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenValue]);

  useEffect(() => {
    fetchContentCount();
  }, [accessTokenValue, fetchContentCount]);

  const handleSearchText = (searchText: string) => {
    handleSearchTextChange(searchText);

    if (searchText === "") {
      handleTabChange("top_ranked");
      return;
    }

    handleTabChange(null);
  };

  const renderFooter = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <ToggleButtonGroup
        orientation="horizontal"
        value={tab}
        exclusive
        onChange={(e, nextView) => {
          if (nextView === null) return;

          handleTabChange(nextView);
        }}
        className={styles.btnTabGroup}
      >
        <ToggleButton
          value="top_ranked"
          aria-label="top_ranked"
          color="primary"
          sx={{
            "&.MuiToggleButtonGroup-firstButton": {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
              color: "#492942",
              border: "none",
            },
            "&.Mui-selected": {
              color: "#fff",
              background: "#3A75E9",
            },
            "&.Mui-selected:hover": {
              color: "#fff",
              background: "#4879e7",
            },
            borderRadius: "12px",
            padding: "6px 17px",
          }}
        >
          <Typography variant="button">Top Ranked</Typography>
        </ToggleButton>
        <Badge
          badgeContent={
            contentCount.subscribed_content_count != null &&
            contentCount.subscribed_content_count > 0
              ? `+${contentCount.subscribed_content_count}`
              : null
          }
          color="error"
          max={9999}
        >
          <ToggleButton
            value="subscribed"
            aria-label="subscribed"
            color="primary"
            sx={{
              padding: "6px 17px",
              "&.MuiToggleButtonGroup-middleButton": {
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                color: "#492942",
                border: "none",
              },
              "&.Mui-selected": {
                color: "#fff",
                background: "#3A75E9",
              },
              "&.Mui-selected:hover": {
                color: "#fff",
                background: "#4879e7",
              },
            }}
          >
            <Typography variant="button">Subscribed</Typography>
          </ToggleButton>
        </Badge>
        <Badge
          badgeContent={
            contentCount.interested_content_count != null &&
            contentCount.interested_content_count > 0
              ? `+${contentCount.interested_content_count}`
              : null
          }
          color="error"
          max={9999}
        >
          <ToggleButton
            value="interested"
            aria-label="interested"
            color="primary"
            sx={{
              padding: "6px 17px",
              "&.MuiToggleButtonGroup-middleButton": {
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                color: "#492942",
                border: "none",
              },
              "&.Mui-selected": {
                color: "#fff",
                background: "#3A75E9",
              },
              "&.Mui-selected:hover": {
                color: "#fff",
                background: "#4879e7",
              },
            }}
          >
            <Typography variant="button">Interested</Typography>
          </ToggleButton>
        </Badge>
        <ToggleButton
          value="newest"
          aria-label="newest"
          color="primary"
          sx={{
            padding: "6px 17px",
            "&.MuiToggleButtonGroup-lastButton": {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
              color: "#492942",
              border: "none",
            },
            "&.Mui-selected": {
              color: "#fff",
              background: "#3A75E9",
            },
            "&.Mui-selected:hover": {
              color: "#fff",
              background: "#4879e7",
            },
            borderRadius: "12px",
          }}
        >
          <Typography variant="button">Newest</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        orientation="horizontal"
        value={view}
        exclusive
        onChange={(e, nextView) => {
          if (nextView === null) return;

          handleViewChange(nextView);
        }}
        className={styles.btnViewGroup}
      >
        <ToggleButton
          value="tile"
          aria-label="tile"
          color="primary"
          sx={{
            "&.MuiToggleButtonGroup-firstButton": {
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              border: "none",
            },
            "&.Mui-selected": {
              color: "transparent",
              background: "transparent",
            },
            "&.Mui-selected:hover": {
              color: "transparent",
              background: "transparent",
            },
            borderRadius: "0",
            padding: "0",
          }}
        >
          {view === "tile" ? (
            <TileIcon color="#6995EC" width={24} height={24} />
          ) : (
            <TileIcon color="#AAAAAA" width={24} height={24} />
          )}
        </ToggleButton>

        <ToggleButton
          value="list"
          aria-label="list"
          color="primary"
          sx={{
            "&.MuiToggleButtonGroup-lastButton": {
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              border: "none",
            },
            "&.Mui-selected": {
              color: "transparent",
              background: "transparent",
            },
            "&.Mui-selected:hover": {
              color: "transparent",
              background: "transparent",
            },
            borderRadius: "0",
            padding: "0",
          }}
        >
          {view === "list" ? (
            <ListIcon color="#6995EC" width={24} height={24} />
          ) : (
            <ListIcon color="#AAAAAA" width={24} height={24} />
          )}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <>
      <SearchBar
        placeholder="Search SideFans"
        renderFooter={renderFooter()}
        handleSearchTextChange={handleSearchText}
        lastSearchType={LastSearchType.CREATORS_SEARCH}
      />
    </>
  );
};
