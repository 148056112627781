import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { IFetchSubscriptionsParams } from "./interfaces/fetch-subscriptions-params.interface";
import type { IFetchSubscriptionsResponse } from "./interfaces/fetch-subscriptions-response.interface";
import type {
  ICancelSubscriptionParams,
  IRenewSubscriptionParams,
} from "./interfaces/manage-subscription-params.interface";
import type { IManageSubscriptionResponse } from "./interfaces/manage-subscription-response.interface";
import {
  ISubscribeParams,
  ISubscribeResponse,
} from "./interfaces/subscribe.interface";

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    fetchSubscriptions: build.mutation<
      IFetchSubscriptionsResponse,
      IFetchSubscriptionsParams
    >({
      query: (data: IFetchSubscriptionsParams) => ({
        url: ENDPOINTS.SUBSCRIPTIONS.GET_ALL,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          status: data.status,
        },
      }),
    }),
    cancelSubscription: build.mutation<
      IManageSubscriptionResponse,
      ICancelSubscriptionParams
    >({
      query: (data: ICancelSubscriptionParams) => ({
        url: ENDPOINTS.SUBSCRIPTIONS.CANCEL,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          subscription_ids: data.subscription_ids,
        },
      }),
    }),
    renewSubscription: build.mutation<
      IManageSubscriptionResponse,
      IRenewSubscriptionParams
    >({
      query: (data: IRenewSubscriptionParams) => ({
        url: ENDPOINTS.SUBSCRIPTIONS.RENEW,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          subscription_ids: data.subscription_ids,
          payment_method_id: data.payment_method_id,
          currency: data.currency,
        },
      }),
    }),

    subscribe: build.mutation<ISubscribeResponse, ISubscribeParams>({
      query: (data: ISubscribeParams) => ({
        url: ENDPOINTS.SUBSCRIPTIONS.SUBSCRIBE,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          id: data.creator_id,
          payment_method_id: data.payment_method_id,
          currency: data.currency,
        },
      }),
    }),
  }),
});

export const {
  useFetchSubscriptionsMutation,
  useCancelSubscriptionMutation,
  useRenewSubscriptionMutation,
  useSubscribeMutation,
} = subscriptionsApi;
