export const PhotoIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.6693 25.3333C30.6693 26.0406 30.3883 26.7189 29.8882 27.219C29.3881 27.719 28.7098 28 28.0026 28H4.0026C3.29536 28 2.61708 27.719 2.11699 27.219C1.61689 26.7189 1.33594 26.0406 1.33594 25.3333V10.6667C1.33594 9.95942 1.61689 9.28115 2.11699 8.78105C2.61708 8.28095 3.29536 8 4.0026 8H9.33594L12.0026 4H20.0026L22.6693 8H28.0026C28.7098 8 29.3881 8.28095 29.8882 8.78105C30.3883 9.28115 30.6693 9.95942 30.6693 10.6667V25.3333Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9974 22.6667C18.9429 22.6667 21.3307 20.2789 21.3307 17.3333C21.3307 14.3878 18.9429 12 15.9974 12C13.0519 12 10.6641 14.3878 10.6641 17.3333C10.6641 20.2789 13.0519 22.6667 15.9974 22.6667Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
