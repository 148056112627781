import type { FC, PropsWithChildren } from "react";

import styles from "./modal-dialog-footer.module.scss";

type ModalDialogFooterProp = {
  className?: string;
};

export const ModalDialogFooter: FC<
  PropsWithChildren<ModalDialogFooterProp>
> = ({ children, className }) => (
  <section className={`${styles.modalDialogFooter} ${className}`}>
    {children}
  </section>
);
