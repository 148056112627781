export const FileIcon = ({
  width = 12,
  height = 12,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.668 31.3332H7.33464C5.86197 31.3332 4.66797 30.1392 4.66797 28.6665V3.33317C4.66797 1.8605 5.86197 0.666504 7.33464 0.666504H19.9833C19.9846 0.666504 19.9866 0.666504 19.988 0.666504H20.0013C20.2146 0.666504 20.3966 0.773837 20.5186 0.929837L27.0713 7.4825C27.228 7.6045 27.3346 7.7865 27.3346 7.99984V8.01384C27.3346 8.01517 27.3346 8.01584 27.3346 8.01717V28.6665C27.3346 30.1392 26.1406 31.3332 24.668 31.3332ZM20.668 2.9205V7.33317H25.0806L20.668 2.9205ZM26.0013 8.6665H20.0013C19.6326 8.6665 19.3346 8.36784 19.3346 7.99984V1.99984H7.33464C6.59864 1.99984 6.0013 2.59717 6.0013 3.33317V28.6665C6.0013 29.4025 6.59864 29.9998 7.33464 29.9998H24.668C25.404 29.9998 26.0013 29.4025 26.0013 28.6665V8.6665ZM22.0013 25.9998H10.0013C9.63264 25.9998 9.33464 25.7018 9.33464 25.3332C9.33464 24.9652 9.63264 24.6665 10.0013 24.6665H22.0013C22.37 24.6665 22.668 24.9652 22.668 25.3332C22.668 25.7018 22.37 25.9998 22.0013 25.9998ZM22.0013 20.6665H10.0013C9.63264 20.6665 9.33464 20.3685 9.33464 19.9998C9.33464 19.6318 9.63264 19.3332 10.0013 19.3332H22.0013C22.37 19.3332 22.668 19.6318 22.668 19.9998C22.668 20.3685 22.37 20.6665 22.0013 20.6665ZM22.0013 15.3332H10.0013C9.63264 15.3332 9.33464 15.0352 9.33464 14.6665C9.33464 14.2985 9.63264 13.9998 10.0013 13.9998H22.0013C22.37 13.9998 22.668 14.2985 22.668 14.6665C22.668 15.0352 22.37 15.3332 22.0013 15.3332Z"
      fill="#AAAAAA"
    />
  </svg>
);
