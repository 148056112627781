import { BtcLogo } from "../../media/icons/btc";
import { DollarLogo } from "../../media/icons/dollar";
import { EuroLogo } from "../../media/icons/euro";
import { MastercardLogo } from "../../media/icons/mastercard";
import { VisaLogo } from "../../media/icons/visa";
import { CurrenciesEnum } from "./enums/currencies.enum";

export const currencyData = [
  {
    value: CurrenciesEnum.BTC,
    display_text: "BTC",
    start_component: BtcLogo({ width: 20, height: 20 }),
    disabled: false,
  },
  {
    value: CurrenciesEnum.USD,
    display_text: "USD",
    start_component: DollarLogo({ width: 20, height: 20 }),
    disabled: false,
  },
  {
    value: CurrenciesEnum.EURO,
    display_text: "EUR",
    start_component: EuroLogo({ width: 20, height: 20 }),
    disabled: false,
  },
];

export const accountValues = [
  {
    display_text: "VISA 0067",
    value: "visa0067",
    start_component: VisaLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "MASTERCARD 7862",
    value: "masterCard7862",
    start_component: MastercardLogo({ width: 24, height: 24 }),
  },
];

export const paymentValues = [
  {
    display_text: "Fixed Amount",
    value: "fixed",
  },
  {
    display_text: "Percentage of total",
    value: "percentageOfTotal",
  },
];

export const euroValues = [
  {
    display_text: "50",
    value: "50",
    start_component: EuroLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "100",
    value: "100",
    start_component: EuroLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "500",
    value: "500",
    start_component: EuroLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "1000",
    value: "1000",
    start_component: EuroLogo({ width: 24, height: 24 }),
  },
];

export const usdValues = [
  {
    display_text: "50",
    value: "50",
    start_component: DollarLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "100",
    value: "100",
    start_component: DollarLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "500",
    value: "500",
    start_component: DollarLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "1000",
    value: "1000",
    start_component: DollarLogo({ width: 24, height: 24 }),
  },
];

export const btcValues = [
  {
    display_text: "0.01",
    value: "0.01",
    start_component: BtcLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "0.05",
    value: "0.05",
    start_component: BtcLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "0.1",
    value: "0.1",
    start_component: BtcLogo({ width: 24, height: 24 }),
  },
  {
    display_text: "0.5",
    value: "0.5",
    start_component: BtcLogo({ width: 24, height: 24 }),
  },
];

export const notAvailables = [
  {
    display_text: "Transfer as much as I have",
    value: "transferAll",
  },
  {
    display_text: "Transfer Nothing",
    value: "dontTransfer",
  },
];
