import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { CurrenciesEnum } from "../../../components/automatic-transfers/enums/currencies.enum";
import type {
  AutomaticTransfer,
  CurrencyTransfer,
} from "../../../services/sign-in/interfaces/initialize.interface";

export interface AvailableCurrencies {
  value: CurrenciesEnum;
  display_text: string;
}

interface AutomaticTransfers {
  available_currencies: AvailableCurrencies[];
  automatic_transfers: AutomaticTransfer[];
}

const initialState: AutomaticTransfers = {
  available_currencies: [],
  automatic_transfers: [],
};

export const automaticTransfersSlice = createSlice({
  name: "transferAccounts",
  initialState,
  reducers: {
    setAutomaticTransfersData(
      state,
      action: PayloadAction<AutomaticTransfers>
    ) {
      const {
        automatic_transfers: automaticTransfers,
        available_currencies: availableCurrencies,
      } = action.payload;

      state.automatic_transfers = automaticTransfers;
      state.available_currencies = availableCurrencies;
    },
    addCurrencyTransfer(
      state,
      action: PayloadAction<{
        automatic_transfer_id: string;
        currency_transfer: CurrencyTransfer;
      }>
    ) {
      const {
        automatic_transfer_id: automaticTransferId,
        currency_transfer: currencyTransfer,
      } = action.payload;

      const automaticTransferIndex = state.automatic_transfers.findIndex(
        (item) => item.id === automaticTransferId
      );
      if (automaticTransferIndex !== -1) {
        state.automatic_transfers[
          automaticTransferIndex
        ].currency_transfers.push(currencyTransfer);
      }
    },
    removeCurrencyTransfer(
      state,
      action: PayloadAction<{
        automatic_transfer_id: string;
        currency_transfer_id: string;
      }>
    ) {
      const {
        automatic_transfer_id: automaticTransferId,
        currency_transfer_id: currencyTransferId,
      } = action.payload;

      const automaticTransferIndex = state.automatic_transfers.findIndex(
        (item) => item.id === automaticTransferId
      );
      if (automaticTransferIndex !== -1) {
        const currencyTransferIndex = state.automatic_transfers[
          automaticTransferIndex
        ].currency_transfers.findIndex(
          (currencyTransfer) => currencyTransfer.id === currencyTransferId
        );
        if (currencyTransferIndex !== -1) {
          state.automatic_transfers[
            automaticTransferIndex
          ].currency_transfers.splice(currencyTransferIndex, 1);
        }
      }
    },
    changeValue(
      state,
      action: PayloadAction<{
        automatic_transfer_id: string;
        currency_transfer_id: string;
        key: keyof CurrencyTransfer;
        value: string;
      }>
    ) {
      const {
        automatic_transfer_id: automaticTransferId,
        currency_transfer_id: currencyTransferId,
        key,
        value,
      } = action.payload;
      const automaticTransferIndex = state.automatic_transfers.findIndex(
        (transfer) => transfer.id === automaticTransferId
      );

      if (automaticTransferIndex !== -1) {
        const currencyTransferIndex = state.automatic_transfers[
          automaticTransferIndex
        ].currency_transfers.findIndex(
          (currencyTransfer) => currencyTransfer.id === currencyTransferId
        );
        if (currencyTransferIndex !== -1) {
          state.automatic_transfers[automaticTransferIndex].currency_transfers[
            currencyTransferIndex
          ][key] = value;

          if (
            key === "amount" ||
            (key === "payment_type" && value === "fixed")
          ) {
            state.automatic_transfers[
              automaticTransferIndex
            ].currency_transfers[currencyTransferIndex].custom_amount = "";
          }

          if (
            key === "custom_amount" ||
            (key === "payment_type" && value === "percentageOfTotal")
          ) {
            state.automatic_transfers[
              automaticTransferIndex
            ].currency_transfers[currencyTransferIndex].amount = "";
          }
        }
      }
    },
    changeAutomaticTransferValue(
      state,
      action: PayloadAction<{
        automatic_transfer_id: string;
        key:
          | "currency"
          | "frequency"
          | "first_day_number"
          | "second_day_number";
        value: CurrenciesEnum;
      }>
    ) {
      const {
        automatic_transfer_id: automaticTransferId,
        key,
        value,
      } = action.payload;

      const automaticTransferIndex = state.automatic_transfers.findIndex(
        (transfer) => transfer.id === automaticTransferId
      );
      if (automaticTransferIndex !== -1) {
        state.automatic_transfers[automaticTransferIndex][key] = value;

        state.automatic_transfers[automaticTransferIndex].currency_transfers =
          state.automatic_transfers[
            automaticTransferIndex
          ].currency_transfers.map((item) => ({
            ...item,
            transfer_account: "",
            amount: "",
            custom_amount: "",
          }));
      }
    },
  },
});

export default automaticTransfersSlice.reducer;
