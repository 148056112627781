import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { ISubscriptions } from "../../../components/subscriptions/interfaces/subscriptions.interface";

interface IActiveSubscriptions {
  activeSubscriptions: ISubscriptions[];
}

const initialState: IActiveSubscriptions = {
  activeSubscriptions: [],
};

export const activeSubscriptionsSlice = createSlice({
  name: "activeSubscriptions",
  initialState,
  reducers: {
    setActiveSubscriptions(state, action: PayloadAction<ISubscriptions[]>) {
      state.activeSubscriptions = action.payload;
    },
  },
});

export default activeSubscriptionsSlice.reducer;
