export const enterDuration = 300;
export const exitDuration = 0;
export const defaultStyle = {
  transition: `opacity ${enterDuration}ms ease-in-out`,
  opacity: 0,
};

export const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};
