import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import type React from "react";

import styles from "./select-state.module.scss";
import type { StateType } from "./states";
import { states } from "./states";

type SelectProps = {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: any }>) => void;
  disabled?: boolean;
};

export default function StateSelect(props: SelectProps) {
  const { value, onChange, disabled = false } = props;
  function findStateByCode(code: string): StateType | undefined {
    return states.find((state) => state.code === code);
  }
  const state = value ? findStateByCode(value) : { code: "", label: "" };

  return (
    <Autocomplete
      id="state"
      fullWidth
      value={state}
      onChange={(e: any, eValue: any) => {
        const event = {
          ...e,
          target: { ...e.target, name: "state", value: eValue.code },
        };

        return onChange(event);
      }}
      options={states}
      autoHighlight
      disableClearable
      disabled={disabled}
      ListboxProps={{
        className: styles.menu,
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(option) => option.label}
      renderOption={(properties, option) => (
        <Box component="li" value={option.code} {...properties}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select state"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
