import type { FC } from "react";

type BackIconProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
  mirrored?: boolean;
};

export const BackIcon: FC<BackIconProp> = ({
  width = 32,
  height = 32,
  disabled = false,
  mirrored = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `${mirrored ? "scale(-1, 1)" : "inherit"}` }}
  >
    <g clipPath="url(#clip0_696_32631)">
      <path
        d="M15.5001 8.99981C15.3365 7.55356 14.6409 6.21982 13.5486 5.25785C12.4564 4.29588 11.0454 3.77441 9.59011 3.79481H2.00011L3.62511 1.49981C3.66402 1.4456 3.69168 1.38416 3.70648 1.31909C3.72127 1.25403 3.72289 1.18666 3.71124 1.12096C3.69959 1.05526 3.67491 0.992551 3.63865 0.936538C3.60239 0.880524 3.55528 0.832335 3.50011 0.794809C3.39192 0.718898 3.25808 0.688907 3.12785 0.711394C2.99761 0.733881 2.88158 0.807015 2.80511 0.914809L0.575109 4.03981C0.523686 4.11303 0.496094 4.20033 0.496094 4.28981C0.496094 4.37929 0.523686 4.46659 0.575109 4.53981L2.81011 7.66981C2.89111 7.76314 3.00381 7.82317 3.12646 7.83831C3.24911 7.85346 3.37302 7.82264 3.47429 7.75181C3.57556 7.68099 3.64701 7.57517 3.67486 7.45476C3.70271 7.33436 3.68499 7.20791 3.62511 7.09981L2.00011 4.79481H9.59011C10.8154 4.77846 12.0017 5.2251 12.912 6.04545C13.8222 6.8658 14.3894 7.99944 14.5001 9.21981C14.5439 9.86937 14.4539 10.521 14.2356 11.1344C14.0172 11.7477 13.6753 12.3097 13.2309 12.7855C12.7865 13.2612 12.2492 13.6407 11.6522 13.9003C11.0551 14.16 10.4111 14.2942 9.76011 14.2948H1.00011C0.867501 14.2948 0.740324 14.3475 0.646556 14.4413C0.552788 14.535 0.500109 14.6622 0.500109 14.7948C0.500109 14.9274 0.552788 15.0546 0.646556 15.1484C0.740324 15.2421 0.867501 15.2948 1.00011 15.2948H9.75011C10.5531 15.2977 11.3478 15.1324 12.0831 14.8097C12.8185 14.487 13.4781 14.014 14.0197 13.4211C14.5613 12.8282 14.9727 12.1286 15.2277 11.3671C15.4827 10.6057 15.5755 9.79927 15.5001 8.99981Z"
        fill={`${disabled ? "#909294" : "#0F70CA"}`}
      />
      <path
        d="M2.79912 7.88502L0.564115 4.75502C0.514672 4.68102 0.488281 4.59402 0.488281 4.50502C0.488281 4.41602 0.514672 4.32902 0.564115 4.25502L2.79912 1.11502C2.83739 1.06134 2.8859 1.01575 2.94186 0.980889C2.99783 0.946026 3.06013 0.922576 3.12519 0.911887C3.19025 0.901199 3.25678 0.903483 3.32096 0.91861C3.38513 0.933736 3.44568 0.961405 3.49912 1.00002C3.5528 1.0383 3.59839 1.08681 3.63325 1.14277C3.66811 1.19873 3.69156 1.26104 3.70225 1.3261C3.71294 1.39116 3.71066 1.45769 3.69553 1.52186C3.6804 1.58604 3.65273 1.64659 3.61412 1.70002L1.61412 4.50002L3.61412 7.30002C3.65273 7.35346 3.6804 7.41401 3.69553 7.47818C3.71066 7.54236 3.71294 7.60889 3.70225 7.67395C3.69156 7.73901 3.66811 7.80131 3.63325 7.85728C3.59839 7.91324 3.5528 7.96175 3.49912 8.00002C3.44568 8.03864 3.38513 8.06631 3.32096 8.08144C3.25678 8.09656 3.19025 8.09885 3.12519 8.08816C3.06013 8.07747 2.99783 8.05402 2.94186 8.01916C2.8859 7.9843 2.83739 7.93871 2.79912 7.88502Z"
        fill={`${disabled ? "#909294" : "#0F70CA"}`}
      />
      <path
        d="M9.58 3.99984H1C0.867392 3.99984 0.740215 4.05252 0.646447 4.14629C0.552678 4.24005 0.5 4.36723 0.5 4.49984C0.5 4.63245 0.552678 4.75962 0.646447 4.85339C0.740215 4.94716 0.867392 4.99984 1 4.99984H9.58C10.807 4.98095 11.9958 5.42644 12.9082 6.24704C13.8206 7.06764 14.3892 8.20273 14.5 9.42484C14.5439 10.0752 14.4535 10.7277 14.2346 11.3417C14.0157 11.9558 13.6729 12.5182 13.2274 12.9941C12.7819 13.4701 12.2434 13.8493 11.6452 14.1084C11.047 14.3674 10.4019 14.5006 9.75 14.4998H1C0.867392 14.4998 0.740215 14.5525 0.646447 14.6463C0.552678 14.7401 0.5 14.8672 0.5 14.9998C0.5 15.1324 0.552678 15.2596 0.646447 15.3534C0.740215 15.4472 0.867392 15.4998 1 15.4998H9.75C10.5539 15.5028 11.3496 15.3373 12.0856 15.014C12.8217 14.6908 13.4819 14.2168 14.0236 13.6228C14.5653 13.0288 14.9766 12.3279 15.2309 11.5652C15.4852 10.8026 15.5768 9.99509 15.5 9.19484C15.335 7.74821 14.6373 6.41482 13.5429 5.45446C12.4485 4.4941 11.0358 3.97555 9.58 3.99984Z"
        fill={`${disabled ? "#909294" : "#0F70CA"}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_696_32631">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
