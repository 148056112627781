import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { type FormikHelpers, useFormik } from "formik";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import type { ICheckUsernameResponseError } from "../../../../services/sign-up/interfaces/check-username-response.interface";
import { useCheckUsernameMutation } from "../../../../services/sign-up/sign-up.service";
import signUpShared from "../../sign-up-shared.module.scss";
import { getColorOfState } from "../../sign-up.utils";
import type { CreateUsernameType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

type CreateUsernameProp = {
  setUsername: Dispatch<SetStateAction<CreateUsernameType>>;
  isUsernameValid: boolean;
  username: CreateUsernameType;
};

export const CreateUsername: FC<CreateUsernameProp> = ({
  setUsername,
  isUsernameValid = false,
  username,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [checkUsername, { isLoading, error, isSuccess, isError }] =
    useCheckUsernameMutation();

  const formik = useFormik({
    initialValues: {
      username: username.value,
    },
    validationSchema: Yup.object({
      username: Yup.string(),
    }),
    onSubmit: async (
      values: { username: string },
      helpers: FormikHelpers<{ username: string }>
    ) => {
      (document.activeElement as HTMLElement).blur();
      await checkUsername(values.username).unwrap();
      helpers.setStatus({ success: true });
    },
  });

  useEffect(() => {
    if (error) {
      setUsername({ status: "invalid", value: "" });
      setSuggestions((error as ICheckUsernameResponseError).data.suggestions);
      formik.setErrors({
        username: "Sorry, that username is taken. ",
      });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setUsername({ status: "valid", value: formik.values.username });
      setSuggestions([]);
    }
  }, [isSuccess]);

  const onChangeClick = useCallback(() => {
    formik.resetForm();
    setUsername({ status: "none", value: "" });
  }, []);

  return (
    <SignUpCard status={isUsernameValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Create your display name:
        </Typography>
        <form
          className={signUpShared.cardForm}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <TextField
            placeholder="Enter display name"
            color={getColorOfState(
              isLoading,
              formik.errors.username,
              isUsernameValid
            )}
            error={!!(formik.touched.username && formik.errors.username)}
            fullWidth
            name="username"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.username}
            disabled={isUsernameValid}
            variant="outlined"
            InputProps={{ sx: { borderRadius: "4px" } }}
          />
          {isUsernameValid ? (
            <Button
              color="success"
              className={signUpShared.actionBtn}
              variant="contained"
              type="button"
              onClick={onChangeClick}
            >
              {isLoading && <CircularProgress color="inherit" size="1rem" />}
              <Typography variant="button">Change</Typography>
            </Button>
          ) : (
            <Button
              color="primary"
              className={signUpShared.actionBtn}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && <CircularProgress color="inherit" size="1rem" />}
              <Typography variant="button">Submit</Typography>
            </Button>
          )}
        </form>
      </div>
      {formik.errors.username && suggestions && (
        <Typography
          color="error"
          variant="caption"
          marginTop="4px"
          borderRadius="4px"
        >
          {formik.errors.username}
          {isError && (
            <Typography
              variant="caption"
              sx={{ display: "inline", color: "#000000" }}
            >
              Try one of these:&nbsp;
              {suggestions.map((name, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={index}
                  onClick={() => {
                    formik.setValues({ username: name });
                  }}
                >
                  {name},{" "}
                </Link>
              ))}
              etc.
            </Typography>
          )}
        </Typography>
      )}
    </SignUpCard>
  );
};
