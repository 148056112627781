export const VisaIconLarge = ({
  width = 48,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="32" rx="4" fill="#2A2A6C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3502 17.67C30.3563 16.1452 29.0988 15.4867 28.1229 14.9756C27.5051 14.6521 27.0002 14.3876 27.0002 14C27.0002 13.67 27.3202 13.32 28.0002 13.23C28.8095 13.1499 29.6253 13.2916 30.3602 13.64L30.7802 11.64C30.0638 11.3713 29.3053 11.2325 28.5402 11.23C26.1802 11.23 24.5402 12.49 24.5402 14.29C24.5402 15.62 25.7302 16.36 26.6302 16.8C27.5302 17.24 27.8802 17.55 27.8702 17.95C27.8702 18.57 27.1302 18.85 26.4402 18.86C25.589 18.8709 24.7492 18.6644 24.0002 18.26L23.5702 20.26C24.4176 20.5908 25.3204 20.7571 26.2302 20.75C28.7402 20.75 30.3902 19.51 30.4002 17.59L30.3502 17.67ZM23.8602 11.39L21.8602 20.64H19.4602L21.4602 11.39H23.8602ZM33.9202 17.39L35.1802 13.92L35.9102 17.39H33.9202ZM38.8102 20.67H36.5902L36.3002 19.29H33.2902L32.8002 20.67H30.2802L33.8702 12.1C34.0357 11.6922 34.4301 11.424 34.8702 11.42H36.8702L38.8102 20.67ZM16.6102 20.61L20.4902 11.36H17.8902L15.4002 17.65L14.4002 12.3C14.3116 11.7563 13.841 11.3577 13.2902 11.36H9.22016L9.16016 11.63C9.97649 11.7897 10.7694 12.0518 11.5202 12.41C11.8399 12.5603 12.0566 12.8682 12.0902 13.22L14.0002 20.61H16.6102Z"
      fill="white"
    />
  </svg>
);
