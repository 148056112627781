import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

import { a11yProps } from "../../helpers/tabs/allyProps";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { CustomTabPanel } from "../shared/tabs/custom-tab-panel";
import { InterestedUsers } from "./interested-users/interested-users";
import { MySubscribers } from "./my-subscribers/my-subscribers";
import { SubscriberGroups } from "./subscriber-groups/subscriber-groups";

export const ManageSubscribers = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box
          sx={{
            padding: "47px 128px",
            color: "#3C3C3C",
          }}
        >
          <Typography
            variant="h4"
            fontWeight={400}
            sx={{
              marginBottom: "1rem",
              color: "#3C3C3C",
            }}
          >
            Manage subscribers
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Interested users" {...a11yProps(0)} />
              <Tab label="My Subscribers" {...a11yProps(1)} />
              <Tab label="Subscriber groups" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <InterestedUsers />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <MySubscribers />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <SubscriberGroups />
            </CustomTabPanel>
          </Box>
        </Box>
      </MainWrapper>
    </Box>
  );
};
