import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { InvitationStatuses } from "../../../components/invitations/enums/invitation-statuses.enum";
import type { InvitationListInterface } from "../../../components/invitations/interfaces/invitation-list.interface";

interface Invitations {
  invitations: InvitationListInterface[];
}

const initialState: Invitations = {
  invitations: [],
};

export const invitationsSlice = createSlice({
  name: "accessToken",
  initialState,
  reducers: {
    setInvitations(state, action: PayloadAction<InvitationListInterface[]>) {
      state.invitations = action.payload;
    },
    cancelInvitation(state, action: PayloadAction<{ id: string }>) {
      const index = state.invitations.findIndex(
        (item) => item.id === action.payload.id
      );

      state.invitations[index].status = InvitationStatuses.CANCELED;
    },
    updateInvitation(state, action: PayloadAction<{ id: string }>) {
      const index = state.invitations.findIndex(
        (item) => item.id === action.payload.id
      );

      state.invitations[index].status = InvitationStatuses.PENDING;
    },
  },
});

export default invitationsSlice.reducer;
