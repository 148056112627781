import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { MESSAGE_TIMEOUT } from "../../../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../../hooks/redux";
import useSubmitState from "../../../../hooks/submit-state";
import { useUpdateMeMutation } from "../../../../services/sign-in/sign-in.service";
import type { ICheckEmailFail } from "../../../../services/sign-up/interfaces/check-email-response.interface";
import type { IConfirmEmailFail } from "../../../../services/sign-up/interfaces/confirm-email.response.interface";
import {
  useCheckEmailMutation,
  useConfirmEmailMutation,
} from "../../../../services/sign-up/sign-up.service";
import { ModalWrapper } from "../../../creator-card/modal-wrapper/modal-wrapper";
import { getColorOfState } from "../../../sign-up/sign-up.utils";
import { ChangePassword } from "./change-password/change-password";
import styles from "./security.module.scss";

interface IFormValues {
  email: string;
}

interface ISecurityProps {
  isLearnMoreShown?: boolean;
}

export const Security: FC<ISecurityProps> = ({ isLearnMoreShown = false }) => {
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const navigate = useNavigate();

  const [isEmailConfirmationOpen, setIsEmailConfirmationOpen] =
    useState<boolean>(false);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [isMessageVisible, setIsMessageVisible] = useState({
    newCode: false,
    submitedCode: false,
  });

  const { user } = useAppSelector((data) => data.userReducer);
  const [
    updateMe,
    {
      isSuccess: isUpdateUserSuccess,
      isLoading: isUpdateUserLoading,
      error: isUpdateUserError,
    },
  ] = useUpdateMeMutation();

  const [
    checkEmail,
    {
      isSuccess: isCheckEmailSuccess,
      error: isCheckEmailError,
      isLoading: isCheckEmailLoading,
    },
  ] = useCheckEmailMutation();
  const formikSendVerification = useFormik<IFormValues>({
    initialValues: {
      email: user?.email || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address."),
    }),
    onSubmit: async () => {},
  });

  const [
    confirmEmail,
    {
      isLoading: isConfirmEmailLoading,
      isSuccess: isConfirmEmailSuccess,
      error: isConfirmEmailError,
      reset,
    },
  ] = useConfirmEmailMutation();

  const { text, color, errorCode } = useSubmitState({
    requestError: isUpdateUserError as FetchBaseQueryError,
    defaultText: "Change",
    successText: "Changed",
    isRequestSuccess: isUpdateUserSuccess,
  });

  const formikConfirmEmail = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string(),
    }),
    onSubmit: async (values: { code: string }) => {
      await confirmEmail({
        email: formikSendVerification.values.email,
        code: Number(values.code) || 0,
      }).unwrap();
    },
  });

  useEffect(() => {
    setIsEmailConfirmationOpen(false);
  }, [isUpdateUserSuccess]);

  const onCloseModal = () => {
    setIsEmailConfirmationOpen(false);
    formikConfirmEmail.resetForm();
  };

  useEffect(() => {
    if (isConfirmEmailSuccess) {
      updateMe({
        accessToken: accessToken || "",
        email: formikSendVerification.values.email,
      }).unwrap();
    }
  }, [isConfirmEmailSuccess]);

  useEffect(() => {
    if (isCheckEmailSuccess) {
      setIsEmailConfirmationOpen(true);
    }
  }, [isCheckEmailSuccess]);

  useEffect(() => {
    if (isCheckEmailError) {
      const err = isCheckEmailError as ICheckEmailFail;
      let errorMessage = "";

      switch (err.status) {
        case 409:
          errorMessage = "Account already exists.";
          break;
        case 400:
          errorMessage =
            "Sorry, that email address appears to be invalid. Maybe there’s a typo? Please fix it or enter a different address";
          break;
        default:
          errorMessage = "Something went wrong. Please try again!";
      }

      formikSendVerification.setErrors({
        email: errorMessage,
      });
    }
  }, [isCheckEmailError]);

  useEffect(() => {
    if (isConfirmEmailError) {
      const err = isConfirmEmailError as IConfirmEmailFail;
      let errorMessage = "";

      switch (err.status) {
        case 409:
          errorMessage =
            "Sorry, the code you entered doesn't match. Please try again. If you entered the wrong email address, go back and fix it now, and we'll send another code.";
          break;
        default:
          errorMessage = "Something went wrong. Please try again!";
      }

      formikConfirmEmail.setErrors({
        code: errorMessage,
      });
    }
  }, [isConfirmEmailError]);

  const sendVerificationCode = useCallback(async () => {
    await checkEmail(formikSendVerification.values.email)
      .unwrap()
      .catch(() => {});
  }, [checkEmail, formikSendVerification]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      navigate("/sign-in");
    }
  }, [isUpdateUserSuccess]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isCheckEmailSuccess) {
      setIsMessageVisible((prev) => ({ ...prev, newCode: true }));
      timeoutId = setTimeout(() => {
        setIsResend(false);
        setIsMessageVisible((prev) => ({ ...prev, newCode: false }));
      }, MESSAGE_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
  }, [isCheckEmailSuccess]);

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.title}>
        <Typography variant="body1" fontWeight="bold">
          Security
        </Typography>
        <Typography variant="body1">Change email and password</Typography>
      </Box>
      <form
        noValidate
        onSubmit={formikSendVerification.handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <TextField
              placeholder="Enter email"
              type="text"
              error={
                !!(
                  formikSendVerification.touched.email &&
                  formikSendVerification.errors.email
                )
              }
              fullWidth
              name="email"
              onBlur={formikSendVerification.handleBlur}
              onChange={formikSendVerification.handleChange}
              value={formikSendVerification.values.email}
            />
            <Button
              type={color === "primary" ? "submit" : "button"}
              color={color}
              variant="contained"
              disabled={
                user?.email === formikSendVerification.values.email ||
                isCheckEmailLoading
              }
              onClick={(e) => {
                if (color === "error") {
                  e.preventDefault();
                  reset();
                }

                if (color === "success") {
                  e.preventDefault();
                  reset();
                }

                if (color === "primary") {
                  sendVerificationCode();
                }
              }}
            >
              <Typography variant="button">{text}</Typography>
            </Button>
            {errorCode && (
              <Typography
                variant="body1"
                color="error"
                mt={1}
                textAlign="center"
              >
                Oops... Something went wrong. Error {errorCode}
              </Typography>
            )}
          </Box>
          {formikSendVerification.errors.email && (
            <Typography color="error" variant="caption" marginTop="4px">
              {formikSendVerification.errors.email}
            </Typography>
          )}
        </Box>
      </form>
      <ChangePassword isLearnMoreShown={isLearnMoreShown} />

      <ModalWrapper
        shown={isEmailConfirmationOpen}
        close={onCloseModal}
        disableBackdropClose
      >
        <form onSubmit={formikConfirmEmail.handleSubmit}>
          <Box className={styles.confirmEmailWrapper}>
            <Box className={styles.confirmEmail}>
              <Box className={styles.header}>
                <Typography fontSize="24px" color="#252733">
                  Enter the verification code we sent you on the new email:
                </Typography>
              </Box>

              <Box className={styles.message}>
                <Typography variant="body1">
                  If you haven&apos;t received the code, click{"  "}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    component="button"
                    disabled={isCheckEmailLoading}
                    sx={{
                      color: isCheckEmailLoading ? "#bebebe" : "",
                      cursor: isCheckEmailLoading ? "auto" : "pointer",
                    }}
                    onClick={async () => {
                      setIsResend(true);
                      await checkEmail(formikSendVerification.values.email);
                    }}
                  >
                    Resend Code
                    {isCheckEmailLoading && (
                      <CircularProgress
                        style={{ marginLeft: "5px" }}
                        color="primary"
                        size=".7rem"
                      />
                    )}
                  </Link>
                </Typography>
              </Box>

              <Box>
                <TextField
                  placeholder="Enter the code"
                  color={getColorOfState(
                    isConfirmEmailLoading,
                    formikConfirmEmail.errors.code,
                    isConfirmEmailSuccess
                  )}
                  error={
                    !!(
                      formikConfirmEmail.touched.code &&
                      formikConfirmEmail.errors.code
                    )
                  }
                  fullWidth
                  name="code"
                  onBlur={formikConfirmEmail.handleBlur}
                  onChange={formikConfirmEmail.handleChange}
                  value={formikConfirmEmail.values.code}
                  InputProps={{ sx: { borderRadius: "4px" } }}
                />
                {formikConfirmEmail.errors.code && (
                  <Typography color="error" variant="caption" marginTop="4px">
                    {formikConfirmEmail.errors.code}
                  </Typography>
                )}
                {isCheckEmailSuccess &&
                  isResend &&
                  !isCheckEmailLoading &&
                  isMessageVisible.newCode && (
                    <Typography
                      color="success.main"
                      variant="caption"
                      marginTop="4px"
                    >
                      New code has been sent
                    </Typography>
                  )}
              </Box>

              <Box className={styles.footer}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isConfirmEmailLoading || isUpdateUserLoading}
                >
                  <Typography variant="button">
                    {isConfirmEmailLoading || isUpdateUserLoading ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      "Confirm and change"
                    )}
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={onCloseModal}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>
    </Box>
  );
};
