import { Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { AddUserIcon } from "../../../../../media/icons/add-user";
import { EarningsIcon } from "../../../../../media/icons/earnings";
import { ExitIcon } from "../../../../../media/icons/exit";
import { FollowIcon } from "../../../../../media/icons/follow";
import { GearIcon } from "../../../../../media/icons/gear";
import { HeadsetIcon } from "../../../../../media/icons/headset";
import { HelpIcon } from "../../../../../media/icons/help";
import { PeopleIcon } from "../../../../../media/icons/people";
import { ProfileIcon } from "../../../../../media/icons/profile";
import { UploadContentIcon } from "../../../../../media/icons/upload-content";
import { WalletIcon } from "../../../../../media/icons/wallet";
import styles from "../avatar-menu/avatar-menu.module.scss";

export const CreatorMenu = () => {
  const navigate = useNavigate();
  const btnStyles = {
    padding: "8px 16px 6px 12px",
    borderRadius: 0,
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    "&:hover": {
      backgroundImage: "none",
      background: "rgba(135, 140, 142, .3)",
    },
  };

  const textStyle = {
    color: "black",
  };

  const onSignOut = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    navigate("/sign-in");
  };

  return (
    <div className={styles.menuContent}>
      <Button
        sx={btnStyles}
        startIcon={<ProfileIcon width={16} height={16} color="black" />}
        onClick={() => navigate("/cc/profile")}
      >
        <span style={textStyle}>Creator profile</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<UploadContentIcon width={16} height={16} />}
        onClick={() => navigate("/cc/upload-content")}
      >
        <span style={textStyle}>Upload content</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<EarningsIcon width={16} height={16} />}
        onClick={() => navigate("/cc/earnings")}
      >
        <span style={textStyle}>Earnings</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<PeopleIcon width={16} height={16} />}
        onClick={() => navigate("/cc/manage-subscribers")}
      >
        <span style={textStyle}>Subscribers</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<AddUserIcon width={16} height={16} />}
        onClick={() => navigate("/cc/invitations")}
      >
        <span style={textStyle}>Invite others</span>
      </Button>
      <Divider sx={{ bgcolor: "#eee", width: "100%", height: "1px" }} />
      <Button
        sx={btnStyles}
        startIcon={<WalletIcon width={16} height={16} />}
        onClick={() => navigate("/payment-info")}
      >
        <span style={textStyle}>Payment info</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<WalletIcon width={16} height={16} />}
        onClick={() => navigate("/cc/transfers")}
      >
        <span style={textStyle}>Transfers</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<WalletIcon width={16} height={16} />}
        onClick={() => navigate("/cc/automatic-transfers")}
      >
        <span style={textStyle}>Automatic Transfers</span>
      </Button>

      {/* TODO rewrite using the Link component! */}
      <Button
        sx={btnStyles}
        startIcon={<WalletIcon width={16} height={16} />}
        onClick={() => navigate("/cc/transfer-accounts")}
      >
        <span style={textStyle}>Transfer Accounts</span>
      </Button>

      <Button
        sx={btnStyles}
        startIcon={<FollowIcon width={16} height={16} />}
        onClick={() => navigate("/cs/subscriptions")}
      >
        <span style={textStyle}>Subscriptions</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<GearIcon width={16} height={16} />}
        onClick={() => navigate("/cc/settings")}
      >
        <span style={textStyle}>Settings</span>
      </Button>
      <Button sx={btnStyles} startIcon={<HelpIcon width={16} height={16} />}>
        <span style={textStyle}>Need help?</span>
      </Button>
      <Button sx={btnStyles} startIcon={<HeadsetIcon width={16} height={16} />}>
        <span style={textStyle}>Contact us</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<ExitIcon width={16} height={16} />}
        onClick={onSignOut}
      >
        <span style={textStyle}>Sign out</span>
      </Button>
    </div>
  );
};
