export const ArrowDown = ({
  width = 24,
  height = 24,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: "black" | "blue";
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57613 9.57564C7.81044 9.34132 8.19034 9.34132 8.42465 9.57564L12.5004 13.6514L16.5761 9.57564C16.8104 9.34132 17.1903 9.34132 17.4247 9.57564C17.659 9.80995 17.659 10.1899 17.4247 10.4242L13.0661 14.7827C12.7537 15.0952 12.2471 15.0952 11.9347 14.7827L7.57613 10.4242C7.34181 10.1899 7.34181 9.80995 7.57613 9.57564Z"
      fill={color === "blue" ? "#3A75E9" : "#566678"}
    />
  </svg>
);
