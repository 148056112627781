/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Modal } from "@mui/material";
import { FC, PropsWithChildren } from "react";



import styles from "./modal-wrapper.module.scss";


interface ModalWrapperProps {
  shown: boolean;
  close: () => void;
  disableBackdropClose?: boolean;
}

export const ModalWrapper: FC<PropsWithChildren<ModalWrapperProps>> = ({
  children,
  shown,
  close,
  disableBackdropClose = false,
}) => {
  const onModalClose = (event: Object, reason: string) => {
    if (reason !== "backdropClick" || !disableBackdropClose) {
      close();
    }
  };

  return (
    <>
      {shown ? (
        <div
          className={styles.modalBackdrop}
          role="button"
          tabIndex={0}
          onClick={!disableBackdropClose ? close : undefined}
          onKeyDown={(event) => {
            if (
              (event.key === "Enter" || event.key === "Space") &&
              !disableBackdropClose
            ) {
              onModalClose(event, "close");
              event.preventDefault();
            }
          }}
        >
          <Modal
            open={shown}
            onClose={onModalClose}
            className={styles.modalWrapper}
          >
            <div
              className={styles.modalKeyHandlersWrapper}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  );
};
