import { Box, Typography } from "@mui/material";

import ContentLikeIcon from "../../../../../media/icons/catalog/content-card/content-like-icon";
import PurchasesIcon from "../../../../../media/icons/catalog/content-card/purchases-icon";
import ViewesIcon from "../../../../../media/icons/catalog/content-card/viewes-icon";
import { MediaImageIcon } from "../../../../../media/icons/media-image";
import styles from "../content-cs-card-item/content-cs-card-item.module.scss";

export const PreviewContentCsCardItem = () => (
  <>
    <div className={styles.contentCardWrapper}>
      <Box className={styles.header}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 10px 0 10px",
          }}
        >
          <Typography className={styles.title}>Content title</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "219px",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(196, 196, 196, 0.2) 156.08%)",
        }}
      >
        <MediaImageIcon color="#fff" width={85} height={85} />
      </Box>
      <Box className={styles.footer}>
        <Box className={styles.footerFirstRow}>
          <Typography fontWeight="bold" variant="body1" color="#252733">
            $10.00
          </Typography>
        </Box>
        <Box className={styles.footerThirdRow}>
          <div className={styles.categoryStatistic}>
            <ContentLikeIcon />
            <Typography color="#AAAAAA" variant="body1">
              100
            </Typography>
          </div>
          <div className={styles.categoryStatistic}>
            <ViewesIcon />
            <Typography color="#AAAAAA" variant="body1">
              100
            </Typography>
          </div>
          <div className={styles.categoryStatistic}>
            <PurchasesIcon />
            <Typography color="#AAAAAA" variant="body1">
              100
            </Typography>
          </div>
        </Box>
      </Box>
    </div>
  </>
);
