import { useCallback, useMemo, useRef, useState } from "react";

import { SortingDirection } from "../enums/sorting-direction.enum";
import type { ITableHead } from "../interfaces/table-head.interface";

export default function useInfinityScrollTable(
  isLoading: boolean,
  loadedData: any[],
  totalNumberOfRecords: number,
  tableHead: ITableHead[]
) {
  const { sortableFields, defaultSortableField } = useMemo(() => {
    const sortable = tableHead.filter((item) => item.sortable);
    const defaultSortable = sortable.filter((field) => field.default_sort)[0];

    return {
      sortableFields: sortable,
      defaultSortableField: defaultSortable,
    };
  }, [tableHead]);

  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState<SortingDirection>(
    SortingDirection.asc
  );
  const [sortBy, setSortBy] = useState(
    defaultSortableField ? defaultSortableField.value : sortableFields[0].value
  );

  const observer = useRef<IntersectionObserver>();
  const lastMemberElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          loadedData.length < totalNumberOfRecords
        ) {
          setPage((prev) => prev + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, loadedData, totalNumberOfRecords, setPage]
  );

  return {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  };
}
