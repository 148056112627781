export default function VectorDownIcon({
  fill = "#878C8E",
}: {
  fill?: string;
}) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49989 0.5H0.499894C0.401018 0.500021 0.304369 0.529357 0.222164 0.584299C0.139959 0.639241 0.0758896 0.717323 0.0380546 0.808673C0.000219561 0.900023 -0.00968207 1.00054 0.00960171 1.09752C0.0288855 1.19449 0.0764889 1.28357 0.146394 1.3535L4.64639 5.8535C4.6928 5.89994 4.74791 5.93679 4.80857 5.96192C4.86922 5.98706 4.93424 6 4.99989 6C5.06555 6 5.13057 5.98706 5.19122 5.96192C5.25188 5.93679 5.30698 5.89994 5.35339 5.8535L9.85339 1.3535C9.9233 1.28357 9.9709 1.19449 9.99018 1.09752C10.0095 1.00054 9.99957 0.900023 9.96173 0.808673C9.9239 0.717323 9.85983 0.639241 9.77762 0.584299C9.69542 0.529357 9.59877 0.500021 9.49989 0.5Z"
        fill={fill}
      />
    </svg>
  );
}
