import type { ITableHead } from "../../../interfaces/table-head.interface";

export const paymentHistoryTableHead: ITableHead[] = [
  {
    display_text: "Date",
    value: "created_at",
    sortable: true,
    default_sort: true,
    headWidth: 11,
  },
  {
    display_text: "Payment Method",
    value: "payment_method",
    sortable: false,
    default_sort: false,
    headWidth: 21,
  },
  {
    display_text: "Type",
    value: "type",
    sortable: false,
    default_sort: false,
    headWidth: 18,
  },
  {
    display_text: "Creator",
    value: "creator",
    sortable: false,
    default_sort: false,
    headWidth: 22,
  },
  {
    display_text: "Content",
    value: "content",
    sortable: false,
    default_sort: false,
    headWidth: 17,
  },
  {
    display_text: "Amount",
    value: "amount",
    sortable: true,
    default_sort: false,
    headWidth: 11,
  },
];
