export const MutedIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5167 0.170015C8.98275 0.646071 6.7289 2.07822 5.22167 4.17001H4.16667C3.06214 4.17178 2.00336 4.61133 1.22234 5.39235C0.441316 6.17337 0.00176302 7.23215 0 8.33668L0 11.67C0.00176302 12.7745 0.441316 13.8333 1.22234 14.6143C2.00336 15.3954 3.06214 15.8349 4.16667 15.8367H5.22167C6.7292 17.9282 8.98291 19.3602 11.5167 19.8367C11.5664 19.8464 11.6169 19.8511 11.6675 19.8508C11.8885 19.8508 12.1005 19.7631 12.2568 19.6068C12.413 19.4505 12.5008 19.2385 12.5008 19.0175V0.991681C12.5011 0.869293 12.4745 0.748339 12.4228 0.637416C12.371 0.526494 12.2955 0.428325 12.2016 0.349888C12.1076 0.27145 11.9976 0.21467 11.8792 0.183582C11.7608 0.152494 11.637 0.147862 11.5167 0.170015ZM10.8333 17.9492C8.99532 17.3633 7.4149 16.1627 6.3575 14.5492C6.28195 14.433 6.1786 14.3374 6.0568 14.2712C5.93501 14.205 5.79863 14.1702 5.66 14.17H4.16667C3.50363 14.17 2.86774 13.9066 2.3989 13.4378C1.93006 12.9689 1.66667 12.3331 1.66667 11.67V8.33668C1.66667 7.67364 1.93006 7.03776 2.3989 6.56891C2.86774 6.10007 3.50363 5.83668 4.16667 5.83668H5.66667C5.80514 5.83666 5.94143 5.80214 6.06321 5.73623C6.18499 5.67032 6.28842 5.5751 6.36417 5.45918C7.41941 3.84605 8.9974 2.64497 10.8333 2.05751V17.9492Z"
      fill="#3A75E9"
    />
    <path
      d="M15 7L20 12M20 7L15 12"
      stroke="#3A75E9"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);
