export const MastercardLogo = ({
  width = 24,
  height = 16,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="16" rx="2" fill="#F2F0EC" />
    <g opacity="0.01">
      <rect x="2.32001" y="1.59998" width="19.35" height="12.8" fill="white" />
    </g>
    <rect x="9.45001" y="3.82996" width="5.11" height="8.34" fill="#F26122" />
    <path
      d="M10 8.00004C9.99457 6.37747 10.7313 4.84146 12 3.83004C9.82643 2.12491 6.70764 2.37536 4.834 4.40549C2.96036 6.43562 2.96036 9.56445 4.834 11.5946C6.70764 13.6247 9.82643 13.8752 12 12.17C10.7289 11.1605 9.99159 9.62327 10 8.00004Z"
      fill="#EA1D25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.41 11.08V11.29H20.3V11.23L20.36 11.08H20.41ZM20.2786 11.1764L20.3 11.23V11.13L20.2786 11.1764ZM20.24 11.08L20.2786 11.1764L20.24 11.26L20.18 11.13V11.08H20.24ZM20.07 11.12V11.29H20V11.12H20.07Z"
      fill="#F69E1E"
    />
    <path
      d="M20.58 7.99992C20.5748 10.0278 19.415 11.8756 17.5911 12.7621C15.7672 13.6485 13.5977 13.4187 12 12.1699C13.2772 11.163 14.0226 9.62631 14.0226 7.99992C14.0226 6.37352 13.2772 4.83682 12 3.82992C13.5957 2.5727 15.7696 2.3382 17.5968 3.22621C19.4239 4.11421 20.5826 5.96845 20.58 7.99992Z"
      fill="#F69E1E"
    />
  </svg>
);
