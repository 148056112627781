import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  IBaseParams,
  IBasicParamsResponse,
  IGetContentCountResponse,
  IGetCreatorsParams,
  IGetInterestedCreatorsResponse,
  IGetLastSearchParamsResponse,
  IGetNewestCreatorsResponse,
  IGetSearchedCreatorsResponse,
  IGetSubscribedCreatorsResponse,
  IGetTopRankedCreatorsResponse,
  IIdentifierParams,
  ILastSearchParam,
  IPatchOrderParams,
  IPatchOrderResponse,
} from "./interfaces/home.interface";

export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getSubscribedCreators: builder.mutation<
      IGetSubscribedCreatorsResponse,
      IGetCreatorsParams
    >({
      query: (data: IGetCreatorsParams) => ({
        url: `${ENDPOINTS.HOME.GET_SUBSCRIBED}?rowsPerPage=${data.rowsPerPage}&page=${data.page}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getNewestCreators: builder.mutation<
      IGetNewestCreatorsResponse,
      IGetCreatorsParams
    >({
      query: (data: IGetCreatorsParams) => ({
        url: `${ENDPOINTS.HOME.GET_NEWEST}?rowsPerPage=${data.rowsPerPage}&page=${data.page}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getInterestedCreators: builder.mutation<
      IGetInterestedCreatorsResponse,
      IGetCreatorsParams
    >({
      query: (data: IGetCreatorsParams) => ({
        url: `${ENDPOINTS.HOME.GET_INTERESTED}?rowsPerPage=${data.rowsPerPage}&page=${data.page}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getRankedCreators: builder.mutation<
      IGetTopRankedCreatorsResponse,
      IGetCreatorsParams
    >({
      query: (data: IGetCreatorsParams) => ({
        url: `${ENDPOINTS.HOME.GET_RANKED}?rowsPerPage=${data.rowsPerPage}&page=${data.page}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getCreatorsBySearch: builder.mutation<
      IGetSearchedCreatorsResponse,
      IGetCreatorsParams
    >({
      query: (data: IGetCreatorsParams) => ({
        url: `${ENDPOINTS.HOME.GET_BY_SEARCH}?rowsPerPage=${data.rowsPerPage}&page=${data.page}&searchText=${data.searchText}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    getLastSearchNames: builder.mutation<
      IGetLastSearchParamsResponse,
      ILastSearchParam
    >({
      query: (data: ILastSearchParam) => ({
        url: `${ENDPOINTS.HOME.SAVED_SEARCHES}?type=${data.type}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    deleteLastSearchName: builder.mutation<
      IBasicParamsResponse,
      IIdentifierParams
    >({
      query: (data: IIdentifierParams) => ({
        url: `${ENDPOINTS.HOME.SAVED_SEARCHES}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    getContentCount: builder.mutation<IGetContentCountResponse, IBaseParams>({
      query: (data: IBaseParams) => ({
        url: `${ENDPOINTS.HOME.CONTENT_COUNT}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    patchInterestedCardOrder: builder.mutation<
      IPatchOrderResponse,
      IPatchOrderParams
    >({
      query: (data: IPatchOrderParams) => ({
        url: `${ENDPOINTS.HOME.INTERESTED_PATCH_CARD_ORDER}${data.id}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          order: data.order,
        },
      }),
    }),
    patchSubscriptionsOrder: builder.mutation<
      IPatchOrderResponse,
      IPatchOrderParams
    >({
      query: (data: IPatchOrderParams) => ({
        url: `${ENDPOINTS.HOME.SUBSCRIPTION_PATCH_CARD_ORDER}${data.id}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          order: data.order,
        },
      }),
    }),
    becomeInterested: builder.mutation<IBasicParamsResponse, IIdentifierParams>(
      {
        query: (data: IIdentifierParams) => ({
          url: ENDPOINTS.HOME.INTERESTED,
          method: "POST",
          headers: {
            Authorization: data.accessToken,
          },
          body: {
            author_id: data.id,
          },
        }),
      }
    ),
    stopBeInterested: builder.mutation<IBasicParamsResponse, IIdentifierParams>(
      {
        query: (data: IIdentifierParams) => ({
          url: ENDPOINTS.HOME.INTERESTED,
          method: "DELETE",
          headers: {
            Authorization: data.accessToken,
          },
          body: {
            author_id: data.id,
          },
        }),
      }
    ),
  }),
});

export const {
  useGetSubscribedCreatorsMutation,
  useGetNewestCreatorsMutation,
  useGetInterestedCreatorsMutation,
  useGetRankedCreatorsMutation,
  useGetCreatorsBySearchMutation,
  useGetLastSearchNamesMutation,
  useDeleteLastSearchNameMutation,
  useGetContentCountMutation,
  usePatchInterestedCardOrderMutation,
  usePatchSubscriptionsOrderMutation,
  useBecomeInterestedMutation,
  useStopBeInterestedMutation,
} = homeApi;
