import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";

import { ENDPOINTS } from "../../../constants/api/api";
import {
  maxLengthOfDisplayName,
  maxLengthOfIntroduction,
} from "../../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useFileUploader from "../../../hooks/file-upload";
import {
  useAddDetail,
  useDeleteDetail,
  useGetDataItemsToDisplay,
} from "../../../hooks/personal-info";
import { useQuery } from "../../../hooks/query-params";
import { useAppSelector } from "../../../hooks/redux";
import useQueryParam from "../../../hooks/set-query-param";
import useSubmitState from "../../../hooks/submit-state";
import type { IUpdatePersonalInfo } from "../../../services/settings/interfaces/settings.interface";
import {
  useGetSettingsMutation,
  useUpdateSettingsMutation,
} from "../../../services/settings/settings.service";
import { AvatarLoader } from "../../shared/avatar-loader/avatar-loader";
import type { IBodyMeasurementsForm } from "./body-measurements/body-measurement-info";
import { bodyMeasurement } from "./body-measurements/body-measurement-info";
import { BodyMeasurements } from "./body-measurements/body-measurements";
import type {
  IBodyMeasurementInfo,
  IPersonalInfo,
  ISocialInfo,
  ITastesInfo,
} from "./interfaces/personal-info.interface";
import { PersonalDetails } from "./personal-details/personal-details";
import type { IPersonalInfoForm } from "./personal-details/personal-information";
import { personalInformation } from "./personal-details/personal-information";
import styles from "./personal-info.module.scss";
import { quillModules } from "./quill-modules";
import "./quill-styles.css";
import type { ILinkForm } from "./social-media/social-info";
import { socialInformation } from "./social-media/social-info";
import { SocialMedia } from "./social-media/social-media";
import { Tags } from "./tags-accordion/tags-accordion";
import { Tastes } from "./tastes/tastes";
import type { ITastesForm } from "./tastes/tastes-info";
import { tastes } from "./tastes/tastes-info";

interface IFormValues {
  avatar: string | File | null;
  descriptionText: string | null;
  info_on_update: {
    username: string;
    description: string;
    tags: string[];
    personal_info: IPersonalInfoForm | null;
    links: ILinkForm;
    body_measurements: IBodyMeasurementsForm | null;
    tastes: ITastesForm | null;
  };
}

export const PersonalInfo = () => {
  const { setParam } = useQueryParam();
  const queryParams = useQuery();
  const isAvatarNotSet = queryParams.get("avatar") === "false";
  const quillRef = useRef<ReactQuill | null>(null);

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const [personalInfoDisplay, setPersonalInfoDisplay] = useState<
    IPersonalInfo[]
  >([]);
  const [bodyMeasurementInfoDisplay, setBodyMeasurementInfoDisplay] = useState<
    IBodyMeasurementInfo[]
  >([]);
  const [tastesInfoDisplay, setTastesInfoDisplay] = useState<ITastesInfo[]>([]);
  const [linksDisplay, setLinksDisplay] = useState<ISocialInfo[]>([]);

  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);

  const { user } = useAppSelector((data) => data.userReducer);

  const [
    getSettings,
    { data: personalInfoData, isLoading: personalInfoLoading },
  ] = useGetSettingsMutation();

  const [
    updateSettings,
    {
      isLoading: isLoadingUpdate,
      error: updateSettingsError,
      isSuccess: isUpdateSettingsSuccess,
      reset,
    },
  ] = useUpdateSettingsMutation();

  const {
    uploadFile: uploadAvatar,
    errorCode: errorCodeAvatar,
    errorMessage: errorMessageAvatar,
    reset: resetAvatar,
    isSuccess: isUploadAvatarSuccess,
  } = useFileUploader(
    `${process.env.REACT_APP_BASE_URL}${ENDPOINTS.UPLOAD_AVATAR}`
  );

  const { text, color, errorCode } = useSubmitState({
    requestError: updateSettingsError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: isUpdateSettingsSuccess,
  });

  const formik = useFormik<IFormValues>({
    initialValues: {
      avatar: null,
      descriptionText: "",
      info_on_update: {
        username: user?.username || "",
        description: "",
        tags: [],
        personal_info: {
          age: null,
          birthday: null,
          birthplace: null,
          birthstone: null,
          zodiac_sign: null,
          currently_reside_in: null,
          relationship_status: null,
          profession: null,
          languages: null,
        },
        body_measurements: {
          height: null,
          weight: null,
          body_type: null,
          skin_tone: null,
          eye_color: null,
          hair_style: null,
          hair_color: null,
          cup_size: null,
          cock_size: null,
          waist_size: null,
          tattoos: null,
          piercings: null,
        },
        links: {
          twitter: null,
          instagram: null,
          tiktok: null,
          youtube: null,
        },
        tastes: {
          interestedIn: null,
          turnOns: null,
          turnOffs: null,
          fetishes: null,
          hobbies: null,
          foods: null,
          musicGenres: null,
          movies: null,
          sports: null,
          videoGames: null,
          jewelry: null,
          lingerie: null,
          sexToys: null,
          drinks: null,
        },
      },
    },
    validationSchema: Yup.object({
      info_on_update: Yup.object({
        username: Yup.string()
          .required("Username is required")
          .max(
            maxLengthOfDisplayName,
            `Username must be at most ${maxLengthOfDisplayName} characters`
          ),
      }),
      descriptionText: Yup.string().max(maxLengthOfIntroduction),
    }),
    onSubmit: async (values) => {
      const updateObj: IUpdatePersonalInfo = {
        accessToken,
        body: values.info_on_update,
      };
      updateSettings(updateObj);

      try {
        if (values.avatar instanceof File) {
          setIsAvatarUploading(true);
          await uploadAvatar(values.avatar);
          setIsAvatarUploading(false);
        }
      } catch (e) {
        setIsAvatarUploading(false);
      }
    },
  });

  useEffect(() => {
    getSettings({ accessToken, userId: user?.id || "" });
  }, []);

  const onAvatarUpload = useCallback(
    (file: File) => {
      resetAvatar();
      formik.setFieldValue("avatar", file);
    },
    [formik]
  );

  const onFormikValueUpdate = useCallback(
    (formikKey: string, formikValue: string | string[] | null | number) => {
      formik.setFieldValue(formikKey, formikValue);
    },
    [formik]
  );

  const getPersonalDataItemsToDisplay = useGetDataItemsToDisplay<
    IPersonalInfoForm,
    IPersonalInfo
  >(personalInformation, [personalInfoData]);
  const getBodyMeasurementDataItemsToDisplay = useGetDataItemsToDisplay<
    IBodyMeasurementsForm,
    IBodyMeasurementInfo
  >(bodyMeasurement, [personalInfoData]);
  const getTastesDataItemsToDisplay = useGetDataItemsToDisplay<
    ITastesForm,
    ITastesInfo
  >(tastes, [personalInfoData]);

  useEffect(() => {
    if (!personalInfoData) return;

    const {
      username,
      description,
      tags,
      personal_info: personalInfo,
      body_measurements: bodyMeasurements,
      tastes: tastesForm,
      links,
    } = personalInfoData.data;

    formik.setValues({
      ...formik.values,
      info_on_update: {
        username,
        description,
        tags,
        personal_info: personalInfo,
        body_measurements: bodyMeasurements,
        tastes: tastesForm,
        links,
      },
    });

    setTimeout(formik.validateForm, 10);

    if (personalInfo) {
      setPersonalInfoDisplay(getPersonalDataItemsToDisplay(personalInfo));
    }

    if (bodyMeasurements) {
      setBodyMeasurementInfoDisplay(
        getBodyMeasurementDataItemsToDisplay(bodyMeasurements)
      );
    }

    if (tastesForm) {
      setTastesInfoDisplay(getTastesDataItemsToDisplay(tastesForm));
    }

    setLinksDisplay(() =>
      socialInformation.map((info) => ({
        ...info,
        value: links[info.name],
      }))
    );
  }, [personalInfoData]);

  const setEditorContent = () => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      formik.setFieldValue("descriptionText", editor.getText().trim());
    }
  };

  useEffect(() => {
    if (formik.values.info_on_update.description) {
      setEditorContent();
    }
  }, [formik.values.info_on_update.description]);

  const onPersonalDetailAdd = useAddDetail<IPersonalInfo>(
    setPersonalInfoDisplay,
    [personalInfoDisplay]
  );
  const onBodyMeasurementAdd = useAddDetail<IBodyMeasurementInfo>(
    setBodyMeasurementInfoDisplay,
    [bodyMeasurementInfoDisplay]
  );
  const onTastesAdd = useAddDetail<ITastesInfo>(setTastesInfoDisplay, [
    tastesInfoDisplay,
  ]);

  const onPersonalDetailDelete = useDeleteDetail<IPersonalInfo>(
    setPersonalInfoDisplay,
    [personalInfoDisplay]
  );
  const onBodyMeasurementDelete = useDeleteDetail<IBodyMeasurementInfo>(
    setBodyMeasurementInfoDisplay,
    [bodyMeasurementInfoDisplay]
  );
  const onTastesDelete = useDeleteDetail<ITastesInfo>(setTastesInfoDisplay, [
    tastesInfoDisplay,
  ]);

  useEffect(() => {
    if (formik.values.info_on_update.personal_info) {
      setPersonalInfoDisplay((currentPersonalInfoDisplay) =>
        currentPersonalInfoDisplay.map((info) => ({
          ...info,
          value:
            formik.values.info_on_update.personal_info?.[info.name] ||
            info.value,
        }))
      );
    }
  }, [formik.values.info_on_update.personal_info]);

  useEffect(() => {
    reset();
  }, [formik.values]);

  useEffect(() => {
    formik.setFieldValue("avatar", user?.avatar?.backend_media_url);
  }, [user]);

  useEffect(() => {
    if (isUploadAvatarSuccess) {
      setParam("", "");
    }
  }, [isUploadAvatarSuccess]);

  return (
    <Box className={styles.wrapper}>
      <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
        <Box className={styles.info}>
          {personalInfoLoading ? (
            <LinearProgress data-cy="loader" />
          ) : (
            <>
              <Box className={styles.avatarSection}>
                <AvatarLoader
                  onAvatarUpload={onAvatarUpload}
                  error={isAvatarNotSet}
                  avatar={
                    typeof formik.values.avatar === "string"
                      ? formik.values.avatar
                      : null
                  }
                />
                <Box className={styles.avatarDescription}>
                  <Typography variant="body1" fontWeight="bold">
                    Avatar
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    This will be displayed on your profile
                    {isAvatarNotSet && !formik.values.avatar && (
                      <Typography
                        variant="caption"
                        color="error"
                        fontWeight={500}
                      >
                        Please add avatar to your profile
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box className={styles.representationSection}>
                <Box>
                  <TextField
                    name="info_on_update.username"
                    placeholder="Enter display name"
                    fullWidth
                    error={!!formik.errors.info_on_update?.username}
                    value={formik.values.info_on_update.username}
                    onChange={formik.handleChange}
                  />
                  <Typography
                    variant="caption"
                    align="right"
                    color={
                      formik.errors.info_on_update?.username
                        ? "#FF5252"
                        : "#989898"
                    }
                    marginTop="3px"
                  >
                    {formik.values.info_on_update.username
                      ? formik.values.info_on_update.username.length
                      : 0}
                    /{maxLengthOfDisplayName}
                  </Typography>
                </Box>

                <Box>
                  <ReactQuill
                    ref={quillRef}
                    className={`quill ${formik.errors.descriptionText ? "quillError" : ""}`}
                    theme="snow"
                    value={formik.values.info_on_update.description || ""}
                    onChange={(content, delta, source, editor) => {
                      formik.setFieldValue(
                        "info_on_update.description",
                        editor.getHTML()
                      );
                      formik.setFieldValue(
                        "descriptionText",
                        editor.getText().trim()
                      );
                    }}
                    modules={quillModules}
                    placeholder="Write a short introduction here..."
                  />
                  <Typography
                    variant="caption"
                    align="right"
                    color={
                      formik.errors.descriptionText ? "#FF5252" : "#989898"
                    }
                    marginTop="3px"
                  >
                    {formik.values.descriptionText
                      ? formik.values.descriptionText.length
                      : 0}
                    /{maxLengthOfIntroduction}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Tags
                  onFormikValueUpdate={onFormikValueUpdate}
                  tags={formik.values.info_on_update.tags || []}
                />
                <PersonalDetails
                  onFormikValueUpdate={onFormikValueUpdate}
                  personalInfoDisplay={personalInfoDisplay}
                  onPersonalDetailAdd={onPersonalDetailAdd}
                  onPersonalDetailDelete={onPersonalDetailDelete}
                />
                <SocialMedia
                  onFormikValueUpdate={onFormikValueUpdate}
                  linksDisplay={linksDisplay}
                />
                <BodyMeasurements
                  onFormikValueUpdate={onFormikValueUpdate}
                  bodyMeasurementInfoDisplay={bodyMeasurementInfoDisplay}
                  onBodyMeasurementAdd={onBodyMeasurementAdd}
                  onBodyMeasurementDelete={onBodyMeasurementDelete}
                />
                <Tastes
                  onFormikValueUpdate={onFormikValueUpdate}
                  tastesInfoDisplay={tastesInfoDisplay}
                  onTastesAdd={onTastesAdd}
                  onTastesDelete={onTastesDelete}
                />
              </Box>

              <Box
                marginTop="20px"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  sx={{ width: "237px" }}
                  variant="contained"
                  type={
                    color === "primary" || !errorCodeAvatar
                      ? "submit"
                      : "button"
                  }
                  color={errorCodeAvatar ? "error" : color}
                  disabled={
                    !formik.isValid ||
                    !formik.values.avatar ||
                    isLoadingUpdate ||
                    isAvatarUploading
                  }
                  onClick={(e) => {
                    if (color === "error" || errorCodeAvatar) {
                      e.preventDefault();
                      reset();
                      resetAvatar();
                    }

                    if (color === "success") {
                      e.preventDefault();
                    }
                  }}
                >
                  {isLoadingUpdate || isAvatarUploading ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : (
                    <Typography variant="button">
                      {errorCodeAvatar ? "Retry" : text}
                    </Typography>
                  )}
                </Button>
                {errorCode && (
                  <Typography
                    variant="body1"
                    color="error"
                    mt={1}
                    textAlign="center"
                  >
                    Oops... Something went wrong. Error {errorCode}
                  </Typography>
                )}
                {errorCodeAvatar === 400 &&
                  errorMessageAvatar === "File type is not supported" && (
                    <Typography
                      variant="body1"
                      color="error"
                      mt={1}
                      textAlign="center"
                    >
                      File type is not supported
                    </Typography>
                  )}
              </Box>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
};
