export default function VectorUpIcon({ fill = "#878C8E" }: { fill?: string }) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499894 5.50002H9.49989C9.59877 5.5 9.69542 5.47066 9.77762 5.41572C9.85983 5.36078 9.9239 5.28269 9.96173 5.19134C9.99957 5.09999 10.0095 4.99948 9.99018 4.9025C9.9709 4.80553 9.9233 4.71644 9.85339 4.64652L5.35339 0.146518C5.30699 0.100069 5.25188 0.0632208 5.19122 0.0380804C5.13057 0.01294 5.06555 0 4.99989 0C4.93423 0 4.86922 0.01294 4.80856 0.0380804C4.74791 0.0632208 4.6928 0.100069 4.64639 0.146518L0.146394 4.64652C0.0764889 4.71644 0.0288855 4.80553 0.00960171 4.9025C-0.00968207 4.99948 0.000219561 5.09999 0.0380546 5.19134C0.0758896 5.28269 0.139959 5.36078 0.222164 5.41572C0.304369 5.47066 0.401018 5.5 0.499894 5.50002Z"
        fill={fill}
      />
    </svg>
  );
}
