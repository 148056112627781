export const VisaLogo = ({
  width = 24,
  height = 16,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} rx="2" fill="#2A2A6C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1696 8.80984C15.1696 8.07867 14.5662 7.76056 14.0848 7.50678C13.7629 7.33712 13.4956 7.19621 13.4996 6.97984C13.4996 6.80984 13.6596 6.62984 13.9996 6.58984C14.4037 6.55169 14.8107 6.62067 15.1796 6.78984L15.3896 5.78984C15.0341 5.65879 14.6585 5.5911 14.2796 5.58984C13.0896 5.58984 12.2796 6.21984 12.2796 7.11984C12.2796 7.77333 12.8678 8.1131 13.3162 8.37212L13.3296 8.37984C13.7796 8.63984 13.9496 8.74984 13.9496 8.94984C13.9496 9.14984 13.5796 9.39984 13.2396 9.39984C12.8099 9.41891 12.3825 9.32586 11.9996 9.12984L11.7796 10.1298C12.2061 10.2847 12.6559 10.3659 13.1096 10.3698C14.3696 10.3698 15.1896 9.74984 15.1896 8.78984L15.1696 8.80984ZM11.9296 5.68984L10.9296 10.3198H9.73961L10.7396 5.68984H11.9296ZM16.9296 8.68984L17.5596 6.94984L17.9996 8.67984L16.9296 8.68984ZM19.3696 10.3298H18.2696L18.0996 9.63984H16.5696L16.3196 10.3298H15.0596L16.8596 6.03984C16.9448 5.83351 17.1464 5.69917 17.3696 5.69984H18.3696L19.3696 10.3298ZM8.27961 10.3198L10.2196 5.68984H8.99961L7.65961 8.83984L7.14961 6.16984C7.11008 5.89592 6.87637 5.69195 6.59961 5.68984H4.59961V5.82984C4.98645 5.91721 5.36217 6.04804 5.71961 6.21984C5.87514 6.30164 5.98002 6.45521 5.99961 6.62984L6.99961 10.3198H8.27961Z"
      fill="white"
    />
  </svg>
);
