import { Box, Button, CircularProgress } from "@mui/material";
import type { AxiosError } from "axios";
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { ENDPOINTS } from "../../../constants/api/api";
import {
  maxNumberOfGreetingMedia,
  maxNumberOfGreetingPhoto,
  maxNumberOfGreetingVideo,
} from "../../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useFileUploader from "../../../hooks/file-upload";
import type {
  IUnsupportedResponse,
  IUpdateFile,
  IUploadFile,
} from "../../../interfaces/file-uploading/uploaded-files.interface";
import type { ISingleGreetingMedia } from "../../../services/settings/interfaces/settings.interface";
import {
  useDeleteMultipleGreetingsFilesMutation,
  useDeleteSingleGreetingsFileMutation,
  useGetGreetingsFilesMutation,
} from "../../../services/settings/settings.service";
import { GreetingsMedia } from "./greetings-media/greetings-media";
import { GreetingsPhoto } from "./greetings-photo/greetings-photo";
import { GreetingsVideo } from "./greetings-video/greetings-video";
import styles from "./greetings.module.scss";

export const Greetings = () => {
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [deletedRemoteGreetingVideo, setDeletedRemoteGreetingVideo] = useState<
    IUploadFile[]
  >([]);
  const [deletedRemoteGreetingPhoto, setDeletedRemoteGreetingPhoto] = useState<
    IUploadFile[]
  >([]);
  const [deletedRemoteGreetingMedia, setDeletedRemoteGreetingMedia] = useState<
    IUploadFile[]
  >([]);

  const [loadedGreetingVideo, setLoadedGreetingVideo] = useState<IUploadFile[]>(
    []
  );
  const [loadedGreetingPhoto, setLoadedGreetingPhoto] = useState<IUploadFile[]>(
    []
  );
  const [loadedGreetingMedia, setLoadedGreetingMedia] = useState<IUploadFile[]>(
    []
  );

  const [getGreetingsMedia, { data: greetingsMediaResponse }] =
    useGetGreetingsFilesMutation();
  const [
    deleteSingleGreetingsFile,
    { isLoading: isDeleteSingleGreetingsFileLoading },
  ] = useDeleteSingleGreetingsFileMutation();
  const [
    deleteMultipleGreetingsFiles,
    { isLoading: isDeleteMultipleGreetingsFilesLoading },
  ] = useDeleteMultipleGreetingsFilesMutation();

  const isLoading =
    isDeleteSingleGreetingsFileLoading ||
    isDeleteMultipleGreetingsFilesLoading ||
    loadedGreetingVideo.some((file) => file.status === "uploading") ||
    loadedGreetingPhoto.some((file) => file.status === "uploading") ||
    loadedGreetingMedia.some((file) => file.status === "uploading");

  const { uploadFile: uploadSingleGreeting } = useFileUploader(
    `${process.env.REACT_APP_BASE_URL}${ENDPOINTS.UPLOAD_GREETINGS}`
  );
  const { uploadFile: uploadGreetingContent } = useFileUploader(
    `${process.env.REACT_APP_BASE_URL}${ENDPOINTS.UPLOAD_GREETING_CONTENT}`
  );

  useEffect(() => {
    getGreetingsMedia({ accessToken: accessToken || "" });
  }, []);

  const handleSetSingleFiles = (mediaData: ISingleGreetingMedia | null) => {
    if (mediaData) {
      const mediaFile: IUploadFile = {
        id: mediaData.id,
        fileName: mediaData.media_original_name,
        status: "remote",
        fileUrl: mediaData.backend_media_url,
        fileType: mediaData.type,
      };
      return [mediaFile];
    }
    return [];
  };

  useEffect(() => {
    if (greetingsMediaResponse) {
      const videoData = greetingsMediaResponse.data.greetingVideo;
      const imageData = greetingsMediaResponse.data.greetingImage;
      const mediaData = greetingsMediaResponse.data.greetingsContent;

      setLoadedGreetingVideo(handleSetSingleFiles(videoData));
      setLoadedGreetingPhoto(handleSetSingleFiles(imageData));

      if (mediaData.length > 0) {
        const mediaDataCopy = [...mediaData];
        const transformedFiles: IUploadFile[] = mediaDataCopy.map(
          (mediaFile) => ({
            id: mediaFile.id,
            fileName: mediaFile.media_original_name,
            status: "remote",
            fileUrl: mediaFile.backend_media_url,
            fileType: mediaFile.type,
          })
        );
        setLoadedGreetingMedia(transformedFiles);
        return;
      }
      setLoadedGreetingMedia([]);
    }
  }, [greetingsMediaResponse]);

  const updateFileInfo = useCallback(
    (payload: IUpdateFile, setter: Dispatch<SetStateAction<IUploadFile[]>>) => {
      setter((prevState) => {
        const updatedFiles = prevState.map((file) => {
          if (file.id === payload.fileId) {
            return { ...file, ...payload.info };
          }
          return file;
        });

        return updatedFiles;
      });
    },
    []
  );

  const handleDeleteRemoteMedia = useCallback(async () => {
    if (deletedRemoteGreetingVideo.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const deletedVideo of deletedRemoteGreetingVideo) {
        // eslint-disable-next-line no-await-in-loop
        await deleteSingleGreetingsFile({
          accessToken: accessToken || "",
          greetingId: deletedVideo.id,
        });
      }
    }

    if (deletedRemoteGreetingPhoto.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const deletedPhoto of deletedRemoteGreetingPhoto) {
        // eslint-disable-next-line no-await-in-loop
        await deleteSingleGreetingsFile({
          accessToken: accessToken || "",
          greetingId: deletedPhoto.id,
        });
      }
    }

    const deletedContentRemoteIds = deletedRemoteGreetingMedia.map(
      (file) => file.id
    );

    if (deletedContentRemoteIds.length > 0) {
      await deleteMultipleGreetingsFiles({
        accessToken: accessToken || "",
        greetingIds: deletedContentRemoteIds,
      });
    }
  }, [
    deletedRemoteGreetingVideo,
    deletedRemoteGreetingPhoto,
    deletedRemoteGreetingMedia,
  ]);

  const handleUpload = useCallback(
    async (
      file: IUploadFile,
      setLoadedGreeting: Dispatch<SetStateAction<IUploadFile[]>>
    ) => {
      updateFileInfo(
        {
          fileId: file.id,
          info: {
            status: "uploading",
            progressOfUploading: 0,
            reason: undefined,
          },
        },
        setLoadedGreeting
      );

      let type;
      if (setLoadedGreeting === setLoadedGreetingVideo) {
        type = "video";
      } else if (setLoadedGreeting === setLoadedGreetingPhoto) {
        type = "image";
      } else {
        type = null;
      }

      try {
        if (type) {
          if (!file.file) {
            return;
          }

          await uploadSingleGreeting(
            file.file,
            [{ key: "type", value: type }],
            (progress) => {
              updateFileInfo(
                {
                  fileId: file.id,
                  info: {
                    progressOfUploading: progress,
                    status: progress === 100 ? "uploaded" : "uploading",
                  },
                },
                setLoadedGreeting
              );
            }
          );
        } else {
          const filesForUpload = loadedGreetingMedia.filter(
            (loadedFile) => loadedFile.status !== "error"
          );
          const order =
            filesForUpload.findIndex(
              (loadedFile) => loadedFile.id === file.id
            ) + 1;

          if (!file.file) {
            await uploadGreetingContent(
              null,
              [
                { key: "media_id", value: file.id },
                { key: "order", value: order },
              ],
              (progress) => {
                updateFileInfo(
                  {
                    fileId: file.id,
                    info: {
                      progressOfUploading: progress,
                      status: progress === 100 ? "uploaded" : "uploading",
                    },
                  },
                  setLoadedGreeting
                );
              }
            );
          } else {
            await uploadGreetingContent(
              file.file,
              [{ key: "order", value: order }],
              (progress) => {
                updateFileInfo(
                  {
                    fileId: file.id,
                    info: {
                      progressOfUploading: progress,
                      status: progress === 100 ? "uploaded" : "uploading",
                    },
                  },
                  setLoadedGreeting
                );
              }
            );
          }
        }
      } catch (error) {
        const e = error as AxiosError;

        if (e.code === "ERR_CANCELED") return;

        if (e.code === "ERR_NETWORK") {
          updateFileInfo(
            {
              fileId: file.id,
              info: {
                status: "error",
                progressOfUploading: 0,
                reason: "unstable_connection",
              },
            },
            setLoadedGreeting
          );
          return;
        }

        if (
          (e.response?.data as IUnsupportedResponse).message ===
            "Described file format does not matches with actual!" ||
          (e.response?.data as IUnsupportedResponse).message ===
            "File type is not supported"
        ) {
          updateFileInfo(
            {
              fileId: file.id,
              info: {
                status: "error",
                progressOfUploading: 0,
                reason: "type_unsupported",
              },
            },
            setLoadedGreeting
          );
          return;
        }

        updateFileInfo(
          {
            fileId: file.id,
            info: {
              status: "error",
              progressOfUploading: 0,
              reason: `Oops... Something went wrong ${e.response?.status}`,
            },
          },
          setLoadedGreeting
        );
      }
    },
    [loadedGreetingMedia, loadedGreetingVideo, loadedGreetingPhoto]
  );

  const handleSaveClick = async () => {
    await handleDeleteRemoteMedia();

    const validGreetingsVideos = loadedGreetingVideo.filter(
      (file) => file.status === "loaded"
    );

    const validGreetingsPhotos = loadedGreetingPhoto.filter(
      (file) => file.status === "loaded"
    );

    const validGreetingsMedia = loadedGreetingMedia.filter(
      (file) => file.status === "loaded" || file.status === "remote"
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const file of validGreetingsVideos) {
      handleUpload(file, setLoadedGreetingVideo);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const file of validGreetingsPhotos) {
      handleUpload(file, setLoadedGreetingPhoto);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const file of validGreetingsMedia) {
      handleUpload(file, setLoadedGreetingMedia);
    }
  };

  const areAllListsValid =
    loadedGreetingVideo.length <= maxNumberOfGreetingVideo &&
    loadedGreetingPhoto.length <= maxNumberOfGreetingPhoto &&
    loadedGreetingMedia.length <= maxNumberOfGreetingMedia;

  return (
    <Box className={styles.wrapper}>
      <GreetingsVideo
        loadedGreetingVideo={loadedGreetingVideo}
        setLoadedGreetingVideo={setLoadedGreetingVideo}
        uploadFileOnRetry={(file) => {
          handleUpload(file, setLoadedGreetingVideo);
        }}
        setDeletedRemoteMedia={setDeletedRemoteGreetingVideo}
      />
      <GreetingsPhoto
        loadedGreetingPhoto={loadedGreetingPhoto}
        setLoadedGreetingPhoto={setLoadedGreetingPhoto}
        uploadFileOnRetry={(file) => {
          handleUpload(file, setLoadedGreetingPhoto);
        }}
        setDeletedRemoteMedia={setDeletedRemoteGreetingPhoto}
      />
      <GreetingsMedia
        loadedGreetingMedia={loadedGreetingMedia}
        setLoadedGreetingMedia={setLoadedGreetingMedia}
        uploadFileOnRetry={(file) => {
          handleUpload(file, setLoadedGreetingMedia);
        }}
        setDeletedRemoteMedia={setDeletedRemoteGreetingMedia}
      />
      <Box
        sx={{
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            width: "237px",
          }}
          variant="contained"
          disabled={!areAllListsValid || isLoading}
          onClick={handleSaveClick}
        >
          {isLoading ? <CircularProgress size="1.5rem" /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};
