import { Box, LinearProgress, Link, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link as LinkRouter, useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useDateGroup from "../../../hooks/date-group";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import useRoundCurrency from "../../../hooks/round-currency";
import useQueryParam from "../../../hooks/set-query-param";
import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BitcoinTextIcon } from "../../../media/icons/bitcoin-text";
import { BtcLogo } from "../../../media/icons/btc";
import { MastercardLogo } from "../../../media/icons/mastercard";
import { VisaLogo } from "../../../media/icons/visa";
import { useFetchPaymentHistoryMutation } from "../../../services/payment-methods/payment-methods.service";
import { purchaseHistorySlice } from "../../../store/reducers/purchase-history/purchase-history";
import { CurrenciesEnum } from "../../automatic-transfers/enums/currencies.enum";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import DateFilters from "../../shared/date-filters/date-filters";
import UserPopover from "../../shared/popovers/user-popover/user-popover";
import type { PaymentHistoryInterface } from "./interfaces/payment-history.interface";
import { paymentHistoryTableHead } from "./payment-history-table-head";
import styles from "./payment-history.module.scss";

export default function PaymentHistoryTable() {
  const rowsPerPage = 5;

  const { setParam } = useQueryParam();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { roundAmount } = useRoundCurrency();

  const { setPurchaseHistory } = purchaseHistorySlice.actions;
  const { purchaseHistory } = useAppSelector(
    (reducers) => reducers.purchaseHistoryReducer
  );
  const [fetchPaymentHistory, { isLoading, error, data: historyData }] =
    useFetchPaymentHistoryMutation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    purchaseHistory,
    historyData?.count || 0,
    paymentHistoryTableHead
  );

  const { date, setDate, handleChangeDate, setCalendarDate, calendarDate } =
    useDateGroup("week");

  const handleGetHistory = useCallback(async () => {
    try {
      await fetchPaymentHistory({
        accessToken: accessToken || "",
        direction,
        page: page + 1,
        sortBy,
        rowsPerPage,
        startDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.startDate).subtract(1, "day").toISOString()
            : date.startDate,
        endDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.endDate).add(1, "day").toISOString()
            : date.endDate,
      }).unwrap();
    } catch (e) {
      navigate("/");
    }
  }, [
    accessToken,
    fetchPaymentHistory,
    rowsPerPage,
    direction,
    sortBy,
    page,
    date,
  ]);

  const renderMethod = (history: PaymentHistoryInterface) => {
    const { payment_method: paymentMethod } = history;

    if (!paymentMethod) return "";

    const { card, crypto, bank_account: bankAccount } = paymentMethod;

    if (card) {
      return (
        <Typography textTransform="capitalize" className={styles.textStyle}>
          {card.card_number.startsWith("4") ? <VisaLogo /> : <MastercardLogo />}{" "}
          {card.name || card.card_number}
        </Typography>
      );
    }
    if (crypto) {
      return (
        <Tooltip title={crypto.wallet_id}>
          <Typography className={styles.textStyle}>
            <BtcLogo /> {crypto.name || crypto.wallet_id}
          </Typography>
        </Tooltip>
      );
    }
    if (bankAccount) {
      return (
        <Typography className={styles.textStyle}>
          <BankAcc width={22} height={22} />
          {bankAccount.name || bankAccount.bank_account_number}
        </Typography>
      );
    }

    return "";
  };

  const renderUser = (history: PaymentHistoryInterface) => {
    const { tip, subscription, oneTimePurchase } = history;
    if (tip) {
      const { receiver } = tip;

      return receiver.display_name ? receiver.display_name : receiver.username;
    }

    if (subscription) {
      const { creator } = subscription;

      return creator.display_name ? creator.display_name : creator.username;
    }

    if (oneTimePurchase) {
      const { content } = oneTimePurchase;
      return content.author.display_name
        ? content.author.display_name
        : content.author.username;
    }

    return "";
  };

  const renderCurrency = (currency: CurrenciesEnum) => {
    switch (currency) {
      case CurrenciesEnum.BTC:
        return (
          <Box
            sx={{
              transform: "translate(-2px, 3px)",
            }}
          >
            <BitcoinTextIcon width={22} height={22} />
          </Box>
        );
      case CurrenciesEnum.USD:
        return "$";
      case CurrenciesEnum.EURO:
        return "€";
      default:
        return "-";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (page === 0) {
      dispatch(setPurchaseHistory(historyData?.data || []));
      return;
    }

    dispatch(
      setPurchaseHistory([...purchaseHistory, ...(historyData?.data || [])])
    );
  }, [historyData]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    handleGetHistory();
  }, [handleGetHistory, page, date]);

  useEffect(() => {
    dispatch(setPurchaseHistory([]));
    setPage(0);
  }, [sortBy, direction, date]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" fontWeight={400} width="100%">
          Purchase history
        </Typography>
        <DateFilters
          calendarDate={calendarDate}
          date={date}
          handleChangeDate={handleChangeDate}
          setCalendarDate={setCalendarDate}
          setDate={setDate}
          disableFuture
        />
      </Box>

      <Table
        sx={{
          minWidth: 650,
          marginTop: "7px",
          maxHeight: "100px",
          overflow: "hidden",
        }}
        aria-label="simple table"
      >
        <CustomTableHead
          tableHeads={paymentHistoryTableHead}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
        />

        <TableBody>
          {purchaseHistory.map((row: PaymentHistoryInterface, index) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderTop: index === 0 ? "none" : "1px solid #fff",
              }}
              ref={
                purchaseHistory.length === index + 1
                  ? lastMemberElementRef
                  : null
              }
            >
              <TableCell
                component="th"
                scope="row"
                className={styles.tableCell}
                sx={{ fontSize: "18px", width: "11%" }}
              >
                {dayjs(row.created_at).format("MM/DD/YYYY")}
              </TableCell>
              <TableCell
                className={styles.tableCell}
                align="left"
                sx={{ width: "21%" }}
              >
                {renderMethod(row)}
              </TableCell>
              <TableCell
                className={styles.tableCell}
                align="left"
                sx={{ width: "18%" }}
              >
                <Typography textTransform="capitalize" fontSize="18px">
                  {row.type === "oneTime" ? "Premium Content" : row.type}
                </Typography>
              </TableCell>
              <TableCell
                className={styles.tableCell}
                align="left"
                sx={{ maxWidth: "50px", overflow: "hidden", width: "22%" }}
              >
                <Typography
                  sx={{
                    color: "rgba(15, 112, 202, 1)",
                    cursor: "pointer",
                  }}
                  fontSize="18px"
                >
                  {row.subscription && (
                    <LinkRouter
                      to={`/user-profile/${row.subscription.creator.id}`}
                    >
                      {renderUser(row)}
                    </LinkRouter>
                  )}

                  {row.oneTimePurchase && (
                    <LinkRouter
                      to={`/user-profile/${row.oneTimePurchase.content.author.id}`}
                    >
                      {renderUser(row)}
                    </LinkRouter>
                  )}

                  {row.tip && (
                    <>
                      <LinkRouter to={`/user-profile/${row.tip.receiver_id}`}>
                        {renderUser(row)}
                      </LinkRouter>
                    </>
                  )}
                </Typography>
              </TableCell>
              <TableCell
                className={styles.tableCell}
                align="left"
                sx={{ width: "17%" }}
              >
                <Typography fontSize="18px">
                  {row.tip && (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        onClick={() => {
                          setParam("contentId", row.tip?.content?.id || "");
                        }}
                      >
                        {
                          // eslint-disable-next-line no-nested-ternary
                          row.tip.content
                            ? row.tip.content.name
                              ? row.tip.content.name
                              : "Post"
                            : null
                        }
                      </Link>
                    </>
                  )}

                  {row.oneTimePurchase && (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        onClick={() => {
                          setParam(
                            "contentId",
                            row.oneTimePurchase?.content.id || ""
                          );
                        }}
                      >
                        {row.type === "oneTime"
                          ? row.oneTimePurchase.content.name
                          : null}
                      </Link>
                    </>
                  )}
                </Typography>
              </TableCell>
              <TableCell
                className={styles.tableCell}
                align="right"
                sx={{ width: "11%" }}
              >
                <Typography
                  fontWeight={600}
                  display="flex"
                  alignItems="center"
                  fontSize="18px"
                >
                  {renderCurrency(row.currency)}{" "}
                  {roundAmount(
                    row.amount,
                    row.currency === CurrenciesEnum.BTC ? 5 : 2
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress />}
      <UserPopover anchorEl={anchorEl} handleClose={handleClose} open={open} />
    </Box>
  );
}
