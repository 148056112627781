import type { AxiosError } from "axios";
import axios from "axios";
import { useCallback, useRef, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../constants/local-storage-keys/local-storage-keys";

interface IAdditionalInfo {
  key: string;
  value: any;
}

interface IResponseError {
  error: string;
  message: string;
  statusCode: number;
}

const useFileUploader = (apiUrl: string) => {
  const controllerRef = useRef<AbortController | null>(null);
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const reset = useCallback(() => {
    setErrorCode(null);
    setErrorMessage(null);
    setIsSuccess(null);
  }, []);

  const uploadFile = useCallback(
    async (
      file: File | null,
      additionalInfo?: IAdditionalInfo[],
      onUploadProgress?: (progress: number) => void
    ) => {
      reset();
      const formData = new FormData();
      if (file) {
        formData.append("media", file);
      }

      if (additionalInfo) {
        additionalInfo.forEach((item) => {
          formData.append(item.key, item.value);
        });
      }

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            Authorization: accessToken,
            "Content-Type": "multipart/form-data",
          },
          signal: controllerRef.current?.signal,
          onUploadProgress: (progressEvent) => {
            if (onUploadProgress) {
              const progress = Math.round(
                (progressEvent.loaded / (progressEvent.total || 1)) * 100
              );
              onUploadProgress(progress);
            }
          },
        });

        if (response.status === 200) {
          setIsSuccess(true);
        }
      } catch (e) {
        const error = e as AxiosError;

        if (error.code === "ERR_CANCELED") {
          setErrorCode(444);
          setErrorMessage("Uploading has been cancelled");
          setIsSuccess(false);
          return;
        }

        const errorResponse = error.response?.data as IResponseError;

        setErrorCode(errorResponse.statusCode);
        setErrorMessage(errorResponse.message);
        setIsSuccess(false);

        throw e;
      }
    },
    []
  );

  const cancelUpload = useCallback(() => {
    reset();
    controllerRef.current?.abort();
  }, []);

  const renewCancelToken = useCallback(() => {
    reset();
    controllerRef.current = new AbortController();
  }, []);

  return {
    uploadFile,
    cancelUpload,
    renewCancelToken,
    errorCode,
    errorMessage,
    reset,
    isSuccess,
  };
};

export default useFileUploader;
