function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#3A75E9" rx="16" />
      <path
        fill="#fff"
        d="M18.816 10.087l-5.38 5.465a.637.637 0 000 .896l5.379 5.466a.64.64 0 01-.203 1.037.618.618 0 01-.683-.138l-5.378-5.464a1.925 1.925 0 010-2.697l5.378-5.465a.618.618 0 01.887 0 .642.642 0 010 .9z"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
