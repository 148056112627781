import { Box, Checkbox, Typography } from "@mui/material";
import type React from "react";

import styles from "./payment-method-card.module.scss";

interface PaymentMethodCardProps {
  icon: React.JSX.Element;
  text: string;
  selected: boolean;
  index: number;
}

export default function PaymentMethodCard(props: PaymentMethodCardProps) {
  const { icon, selected, text, index } = props;

  return (
    <Box
      className={styles.cardWrapper}
      sx={{
        border: selected
          ? "2px solid rgba(58, 117, 233, 1)"
          : "2px solid rgba(175, 177, 182, 1)",
      }}
    >
      <Checkbox
        data-cy={`selectPaymentMethod-${index}`}
        sx={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          "& svg": {
            color: selected
              ? "rgba(58, 117, 233, 1)"
              : "rgba(135, 140, 142, 1)",
          },
        }}
        inputProps={{
          style: {
            margin: "2px",
            opacity: 1,
            width: "20px",
            height: "20px",
          },
        }}
        checked={selected}
      />
      <Box className={styles.typeWrapper}>
        {icon}
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
}
