export const DeleteCrossIcon = ({
  width = 24,
  height = 24,
  color = "#4C5A69",
  strokeWidth = 1.5,
}: {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L11 11"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M1 11L11 1"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);
