import { IconButton, Link, Typography } from "@mui/material";
import { type FC, useCallback, useEffect } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { DeleteCrossIcon } from "../../../../media/icons/delete-cross-icon";
import {
  useDeleteLastSearchNameMutation,
  useGetLastSearchNamesMutation,
} from "../../../../services/home/home.service";
import { LastSearchType } from "../../../../services/home/interfaces/home.interface";
import { lastSearchSlice } from "../../../../store/reducers/last-search/last-search";
import styles from "./last-searched-list.module.scss";

interface ILastSearchedProp {
  lastTextHandleChange: any;
  lastSearchType: LastSearchType;
}

export const LastSearchedList: FC<ILastSearchedProp> = ({
  lastTextHandleChange,
  lastSearchType,
}) => {
  const accessTokenValue = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const dispatch = useAppDispatch();

  const { setLastSearchItems } = lastSearchSlice.actions;
  const { lastSearchItems } = useAppSelector(
    (reducers) => reducers.lastSearchReducer
  );

  const [getLastSearchNames, { data: getLastSearchNamesData }] =
    useGetLastSearchNamesMutation();

  const [deleteLastSearchName] = useDeleteLastSearchNameMutation();

  const fetchLastSearchedList = useCallback(async () => {
    await getLastSearchNames({
      accessToken: accessTokenValue || "",
      type: lastSearchType,
    }).unwrap();
  }, [accessTokenValue, getLastSearchNames]);

  useEffect(() => {
    if (getLastSearchNamesData?.data) {
      dispatch(setLastSearchItems(getLastSearchNamesData.data));
    }
  }, [getLastSearchNamesData]);

  useEffect(() => {
    fetchLastSearchedList();
  }, [accessTokenValue, fetchLastSearchedList]);

  const deleteLastSearchedItem = useCallback(
    async (idToDelete: string) => {
      await deleteLastSearchName({
        accessToken: accessTokenValue || "",
        id: idToDelete,
      });
      const updatedList = lastSearchItems.filter(
        (item) => item.id !== idToDelete
      );
      dispatch(setLastSearchItems(updatedList));
    },
    [lastSearchItems]
  );

  const deleteAllSearchedItems = useCallback(async () => {
    dispatch(setLastSearchItems([]));
    // eslint-disable-next-line no-restricted-syntax
    for (const item of lastSearchItems) {
      // eslint-disable-next-line no-await-in-loop
      deleteLastSearchName({
        accessToken: accessTokenValue || "",
        id: item.id,
      });
    }
  }, [deleteLastSearchedItem]);

  return (
    <>
      {lastSearchItems.length > 0 ? (
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingTop: "20px",
            }}
          >
            <div>
              <Typography
                className={styles.lastSearchLabel}
                style={{ fontSize: "14px", color: "gray", marginRight: "8px" }}
              >
                Last search:
              </Typography>
            </div>
            <div
              className={styles.listContainer}
              style={{ display: "flex", flexGrow: 1 }}
            >
              {lastSearchItems.map((item, index) => (
                <div
                  key={index}
                  className={styles.listItem}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    name="searchText"
                    value={item.text}
                    className={styles.lastSearchTextButton}
                    type="button"
                    onClick={lastTextHandleChange}
                  />
                  {!!item.new_creators_count && item.new_creators_count > 0 && (
                    <span className={styles.newCreatorsNumber}>
                      +{item.new_creators_count}
                    </span>
                  )}
                  <IconButton
                    type="submit"
                    style={{ padding: "5px" }}
                    onClick={() => {
                      deleteLastSearchedItem(item.id);
                    }}
                  >
                    <DeleteCrossIcon
                      width={8}
                      height={8}
                      color="gray"
                      strokeWidth={3}
                    />
                  </IconButton>
                </div>
              ))}
            </div>
            <div style={{ flexShrink: 0 }}>
              <Typography variant="body2">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  onClick={async () => {
                    deleteAllSearchedItems();
                  }}
                >
                  Clear all
                </Link>
              </Typography>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};
