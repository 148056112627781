import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";

import { RoleEnum } from "../../../../enums/role.enum";
import signUpShared from "../../sign-up-shared.module.scss";
import type { AgreementType, RoleType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

// eslint-disable-next-line no-shadow
enum RoleBtnName {
  yes,
  later,
  no,
}

type RoleProp = {
  setRole: Dispatch<SetStateAction<RoleType>>;
  setAgreement: Dispatch<SetStateAction<AgreementType>>;
  isRoleValid: boolean;
};

export const Role: FC<RoleProp> = ({ isRoleValid, setRole, setAgreement }) => {
  const onRoleChoseHandler = (btnName: RoleBtnName) => {
    setAgreement({ status: "none", value: false });

    switch (btnName) {
      case RoleBtnName.yes:
        setRole({ status: "valid", value: RoleEnum.creator, isLater: false });
        break;
      case RoleBtnName.later:
        setRole({ status: "valid", value: RoleEnum.subscriber, isLater: true });
        break;
      case RoleBtnName.no:
        setRole({
          status: "valid",
          value: RoleEnum.subscriber,
          isLater: false,
        });
        break;
      default:
        setRole({
          status: "none",
          value: RoleEnum.undefined,
          isLater: false,
        });
    }
  };

  return (
    <SignUpCard status={isRoleValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Do you want to create content?
        </Typography>
        <form className={`${signUpShared.cardForm}`} noValidate>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="creator-radio-buttons-group"
            row
            className={`${signUpShared.cardFormJustRadios}`}
            onChange={({ target }) =>
              onRoleChoseHandler(Number(target.value) as RoleBtnName)
            }
          >
            <FormControlLabel
              value={RoleBtnName.yes}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              value={RoleBtnName.later}
              control={<Radio />}
              label="Maybe later"
            />
            <FormControlLabel
              value={RoleBtnName.no}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </form>
      </div>
    </SignUpCard>
  );
};
