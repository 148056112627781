import { Button, CircularProgress, Typography } from "@mui/material";
import { FC, useCallback, useEffect } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import { useDeleteContentByIdsMutation } from "../../../../../../services/catalog/catalog.service";
import { ModalDialog } from "../../../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import { useCreatorCatalogCCViewContext } from "../../context/creator-catalog-cc-view-context";
import styles from "../confirmation-modal.module.scss";

interface IDeleteContentConfirmationProp {
  isOpen: boolean;
  onCancelClick: (reason?: "deleted" | "deleteCreating") => void;
}

export const DeleteContentConfirmation: FC<IDeleteContentConfirmationProp> = ({
  isOpen,
  onCancelClick,
}) => {
  const { isCollectionCreating } = useCreatorCatalogCCViewContext();

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { postsToDelete, fetchContent } = useCreatorCatalogCCViewContext();
  const [deleteContent, { isLoading, isSuccess }] =
    useDeleteContentByIdsMutation();

  const deleteContentHandler = useCallback(async () => {
    if (isCollectionCreating) {
      onCancelClick("deleteCreating");
      return;
    }

    await deleteContent({
      accessToken: userAccessToken || "",
      content_ids: postsToDelete,
    }).unwrap();
  }, [
    deleteContent,
    userAccessToken,
    postsToDelete,
    isCollectionCreating,
    onCancelClick,
  ]);

  useEffect(() => {
    if (isSuccess) {
      onCancelClick("deleted");
      fetchContent();
    }
  }, [isSuccess]);

  return (
    <ModalDialog isOpen={isOpen} classes={styles.modal} backdropBlur={10}>
      <ModalDialogHeader position="center">
        <Typography align="center" variant="h4" fontWeight={400}>
          Delete content
        </Typography>
      </ModalDialogHeader>
      <ModalDialogContent>
        <Typography align="center" variant="body1" fontWeight={400}>
          Are you sure you want to delete content?
        </Typography>
      </ModalDialogContent>
      <ModalDialogFooter className={styles.footer}>
        <Button
          color="error"
          variant="contained"
          type="button"
          sx={{
            width: "218px",
          }}
          disabled={isLoading}
          onClick={deleteContentHandler}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="1.6rem" />
          ) : (
            <Typography variant="button">Yes</Typography>
          )}
        </Button>
        <Button
          variant="outlined"
          type="button"
          sx={{
            backgroundColor: "transparent",
            width: "218px",
          }}
          disabled={isLoading}
          onClick={() => onCancelClick()}
        >
          <Typography variant="button">No</Typography>
        </Button>
      </ModalDialogFooter>
    </ModalDialog>
  );
};
