import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";

import { ModalDialog } from "../../../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import styles from "../confirmation-modal.module.scss";

interface CloseEditingConfirmationProp {
  isOpen: boolean;
  onSaveClick: () => void;
  onDiscardClick: () => void;
  onBackClick: () => void;
}

export const CloseEditingConfirmation: FC<CloseEditingConfirmationProp> = ({
  isOpen,
  onDiscardClick,
  onBackClick,
  onSaveClick,
}) => (
  <ModalDialog isOpen={isOpen} classes={styles.modal} backdropBlur={10}>
    <ModalDialogHeader position="center">
      <Typography align="center" variant="h4" fontWeight={400}>
        Save your changes?
      </Typography>
    </ModalDialogHeader>
    <ModalDialogContent>
      <Box
        sx={{
          padding: "0 70px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography align="center" variant="body1" fontWeight={400}>
          Are you sure you want to discard all changes?
        </Typography>
      </Box>
    </ModalDialogContent>
    <ModalDialogFooter className={styles.footer}>
      <Button
        color="primary"
        variant="contained"
        type="button"
        sx={{
          width: "218px",
        }}
        onClick={onSaveClick}
      >
        <Typography variant="button" fontWeight="bold">
          Save
        </Typography>
      </Button>
      <Button
        color="error"
        variant="contained"
        type="button"
        sx={{
          width: "218px",
        }}
        onClick={onDiscardClick}
      >
        <Typography variant="button" fontWeight="bold">
          Discard
        </Typography>
      </Button>
      <Button
        variant="outlined"
        type="button"
        sx={{
          backgroundColor: "transparent",
          width: "218px",
        }}
        onClick={onBackClick}
      >
        <Typography variant="button" fontWeight="bold">
          Go Back
        </Typography>
      </Button>
    </ModalDialogFooter>
  </ModalDialog>
);
