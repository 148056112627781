import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { ITransactionHistoryParams } from "./interfaces/transaction-history-params.interface";
import type { ITransactionHistoryResponse } from "./interfaces/transaction-history-response.interface";

export const earningsApi = createApi({
  reducerPath: "earningsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    fetchTransactionHistory: build.mutation<
      ITransactionHistoryResponse,
      ITransactionHistoryParams
    >({
      query: (data: ITransactionHistoryParams) => ({
        url: ENDPOINTS.EARNINGS.HISTORY,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          type: data.type,
        },
        body: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
  }),
});

export const { useFetchTransactionHistoryMutation } = earningsApi;
