import type { TypographyProps } from "@mui/material";
import { Tooltip, Typography } from "@mui/material";
import type { BaseSyntheticEvent } from "react";
import { useState } from "react";

type TextAreaWithAbilityToCopyProps = {
  textToCopy: string;
  tooltipTitle: string;
} & TypographyProps;

export const TextAreaWithAbilityToCopy = ({
  textToCopy,
  tooltipTitle,
  ...props
}: TextAreaWithAbilityToCopyProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = (text: string) => {
    setIsCopied(true);
    navigator.clipboard.writeText(text);
  };

  const handleClose = () => {
    setIsCopied(false);
  };

  return (
    <Tooltip
      leaveDelay={200}
      open={isCopied}
      onClose={handleClose}
      arrow
      title={tooltipTitle}
    >
      <Typography
        {...props}
        onClick={(e: BaseSyntheticEvent) =>
          copyTextToClipboard(e.target.innerText)
        }
      >
        {textToCopy}
      </Typography>
    </Tooltip>
  );
};
