import { Box, Link, Typography } from "@mui/material";

import { BlockedUsers } from "./blocked-users/blocked-users";
import { DeleteAccount } from "./delete-account/delete-account";
import { Privacy } from "./privacy/privacy";
import styles from "./security-privacy.module.scss";
import { Security } from "./security/security";

export const SecurityPrivacy = () => (
  <Box className={styles.wrapper}>
    <Box className={styles.info}>
      <Security />
      <Privacy />
      <Box>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>Learn more about Privacy Policy</Link>
      </Box>
      <BlockedUsers />
      <DeleteAccount>
        <Box
          sx={{
            padding: "0 70px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography align="center" variant="body1" fontWeight={400}>
            Everything you’ve posted will be permanently removed from the site,
            and cannot be recovered. Per the CC Agreement, content that is
            licensed for use by SideFans will become sole property of SideFans
          </Typography>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link>Read the CC Agreement</Link>
          <Typography
            align="center"
            variant="body1"
            fontWeight={400}
            sx={{
              marginTop: "10px",
            }}
          >
            Are you sure you want to delete account?
          </Typography>
        </Box>
      </DeleteAccount>
    </Box>
  </Box>
);
