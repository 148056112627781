import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import styles from "./video.module.scss";

interface IVideoProp {
  muted: boolean;
  isPlaying: boolean;
  showControls: boolean;
  videoUrl: string;
  showDuration?: boolean;
}

export const Video: FC<IVideoProp> = ({
  muted,
  showControls,
  videoUrl,
  isPlaying,
  showDuration = false,
}) => {
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [mediaUrl, setMediaUrl] = useState<string>("");
  const [duration, setDuration] = useState<string>("");

  const playerRef = useRef<ReactPlayer>(null);

  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const hours = h > 0 ? `${h}:` : "";
    const minutes = m < 10 ? `0${m}:` : `${m}:`;
    const secs = s < 10 ? `0${s}` : `${s}`;

    return `${hours}${minutes}${secs}`;
  };

  const downloadVideo = useCallback(async () => {
    await axios
      .get(videoUrl, {
        headers: {
          Authorization: accessToken,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 200) {
          const file = response.data;
          const blob = new Blob([file]);
          const blobUrl = URL.createObjectURL(blob);

          const videoElement = document.createElement("video");

          videoElement.src = blobUrl;

          videoElement.onloadedmetadata = () => {
            const { duration: videoDuration } = videoElement;
            setDuration(formatTime(videoDuration));
          };

          setMediaUrl(blobUrl);
        } else {
          setMediaUrl("");
        }
      })
      .catch((e: any) => {
        console.log("Eror: ", e);
        setMediaUrl("");
      });
  }, [videoUrl, accessToken]);

  useEffect(() => {
    downloadVideo();
  }, [videoUrl]);

  return (
    <div className={styles.cardMedia}>
      {duration && showDuration && (
        <Box className={styles.duration}>
          <Typography fontSize="14px" color="#ffffff" fontWeight="bold">
            {duration}
          </Typography>
        </Box>
      )}
      {mediaUrl ? (
        <Box className={styles.cardMediaVideo}>
          <ReactPlayer
            ref={playerRef}
            url={mediaUrl}
            playing={isPlaying}
            volume={muted ? 0 : 1}
            width="100%"
            height="100%"
            controls={showControls}
            loop
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload noremoteplayback",
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        </Box>
      ) : (
        <div className={`${styles.cardMediaVideo} ${styles.loader}`}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
