export const UploadContentIcon = ({
  width = 14,
  height = 14,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2921_38011)">
      <path
        d="M7.64416 0.975129L4.41166 4.20763C4.25986 4.3648 4.17587 4.5753 4.17777 4.7938C4.17966 5.01229 4.26731 5.2213 4.42181 5.37581C4.57632 5.53032 4.78533 5.61796 5.00383 5.61986C5.22232 5.62176 5.43283 5.53776 5.58999 5.38596L8.82249 2.15346C8.92043 2.05743 9.02955 1.97349 9.14749 1.90346C9.14749 1.91596 9.14083 1.92596 9.14083 1.93846L9.16749 15.0085C9.16749 15.2295 9.25529 15.4414 9.41157 15.5977C9.56785 15.754 9.77981 15.8418 10.0008 15.8418C10.2218 15.8418 10.4338 15.754 10.5901 15.5977C10.7464 15.4414 10.8342 15.2295 10.8342 15.0085L10.8075 1.94346C10.8075 1.92013 10.7958 1.90096 10.7942 1.87846C10.9351 1.95238 11.0648 2.04611 11.1792 2.1568L14.4117 5.3893C14.4885 5.46889 14.5805 5.53237 14.6822 5.57605C14.7838 5.61972 14.8932 5.64271 15.0038 5.64367C15.1145 5.64463 15.2242 5.62355 15.3266 5.58165C15.429 5.53975 15.5221 5.47787 15.6003 5.39962C15.6786 5.32138 15.7404 5.22834 15.7823 5.12592C15.8242 5.02351 15.8453 4.91378 15.8444 4.80313C15.8434 4.69248 15.8204 4.58313 15.7767 4.48146C15.7331 4.37979 15.6696 4.28784 15.59 4.21096L12.3575 0.978462C11.7324 0.353558 10.8847 0.00250882 10.0008 0.00250886C9.11695 0.0025089 8.26925 0.353558 7.64416 0.978462L7.64416 0.975129Z"
        fill="#252733"
      />
      <path
        d="M18.3314 14.1647L18.3314 15.8314C18.3314 16.4944 18.068 17.1303 17.5991 17.5991C17.1303 18.068 16.4944 18.3314 15.8314 18.3314L4.16471 18.3314C3.50167 18.3314 2.86579 18.068 2.39695 17.5991C1.92811 17.1303 1.66471 16.4944 1.66471 15.8314L1.66471 14.1647C1.66471 13.9437 1.57692 13.7317 1.42064 13.5755C1.26436 13.4192 1.05239 13.3314 0.83138 13.3314C0.610366 13.3314 0.398405 13.4192 0.242124 13.5755C0.085844 13.7317 -0.00195339 13.9437 -0.00195338 14.1647L-0.00195331 15.8314C-0.000630044 16.936 0.438782 17.9951 1.2199 18.7762C2.00101 19.5573 3.06005 19.9967 4.16471 19.998L15.8314 19.998C16.936 19.9967 17.9951 19.5573 18.7762 18.7762C19.5573 17.9951 19.9967 16.936 19.998 15.8314L19.998 14.1647C19.998 13.9437 19.9102 13.7317 19.754 13.5755C19.5977 13.4192 19.3857 13.3314 19.1647 13.3314C18.9437 13.3314 18.7317 13.4192 18.5755 13.5755C18.4192 13.7317 18.3314 13.9437 18.3314 14.1647Z"
        fill="#252733"
      />
    </g>
    <defs>
      <clipPath id="clip0_2921_38011">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
