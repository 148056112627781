import type {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { Avatar, Badge, Box, IconButton, Typography } from "@mui/material";
import { type FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../hooks/redux";
import { DragIcon } from "../../../media/icons/drag";
import { ProfileIcon } from "../../../media/icons/profile";
import { CrownIcon } from "../../../media/icons/solid-crown";
import { IHomeCreatorCard } from "../../../services/home/interfaces/home.interface";
import { ModalWrapper } from "../../creator-card/modal-wrapper/modal-wrapper";
import { Video } from "../../shared/video/video";
import { HomeCreatorCard } from "../home-creator-card/home-creator-card";
import { useHomeListContext } from "../home-list/home-list-context/home-list-context";
import styles from "./home-list-item.module.scss";

interface IHomeListItemProp {
  itemData: IHomeCreatorCard;
  draggable: boolean;
  isDragging: boolean;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
}

export const HomeListItem: FC<IHomeListItemProp> = ({
  itemData,
  draggable,
  isDragging,
  listeners,
  attributes,
}) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((data) => data.userReducer);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { tab, view, setCurrentCard } = useHomeListContext();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handlePause = () => {
        // Rewind the video to the start
        videoElement.currentTime = 0;
      };

      videoElement.addEventListener("pause", handlePause);

      return () => {
        videoElement.removeEventListener("pause", handlePause);
      };
    }

    // Return undefined when videoElement is not available
    return undefined;
  }, []);

  const toggleAutoPlay = (mediaPlayAutomatically: boolean | undefined) => {
    if (mediaPlayAutomatically) {
      const playPromise = videoRef.current?.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            console.log(_);
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            console.error("video is paused", error);
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    } else {
      videoRef.current?.pause();
    }
  };

  useEffect(() => {
    toggleAutoPlay(user?.media_play_automatically);
  }, [user?.media_play_automatically, view]);

  const [showCreatorCard, setShowCreatorCard] = useState<boolean>(false);
  const handleOpenCreatorCard = () => {
    if (itemData.creator.is_subscribed) {
      navigate(`/user-profile/${itemData.creator.id}`);
    } else {
      setCurrentCard(itemData.creator);
      setShowCreatorCard(true);
    }
  };
  const handleCloseCreatorCard = () => {
    setShowCreatorCard(false);
  };

  const renderCrown = (orderOfSubscriber: number) => {
    let color = "";

    switch (orderOfSubscriber) {
      case 1:
        color = "#DBA621";
        break;
      case 2:
        color = "#C0C0C0";
        break;
      case 3:
        color = "#D67539";
        break;
      default:
        return (
          <Box position="relative" className={styles.crownContainer}>
            <CrownIcon color="#DBA621" isSolid={false} />
            <Typography
              fontSize="14px"
              fontWeight="bold"
              className={styles.order}
              color={view === "tile" ? "#4C4B4C" : "#fff"}
            >
              {orderOfSubscriber}
            </Typography>
          </Box>
        );
    }

    return (
      <Box position="relative" className={styles.crownContainer}>
        <CrownIcon color={color} isSolid />
        <Typography
          fontSize="14px"
          fontWeight="bold"
          className={styles.order}
          color={view === "tile" && orderOfSubscriber > 3 ? "#4C4B4C" : "#fff"}
        >
          {orderOfSubscriber}
        </Typography>
      </Box>
    );
  };

  const renderVideoElement = (videoUrl: string) => (
    <Box className={styles.cardMedia}>
      <Video
        muted
        isPlaying={!!user?.media_play_automatically}
        videoUrl={videoUrl}
        showControls={false}
      />
    </Box>
  );

  return (
    <>
      {view === "tile" && (
        <Box className={styles.card} onClick={handleOpenCreatorCard}>
          <Box className={styles.header}>
            {itemData.creator.avatar ? (
              <Avatar
                alt={itemData.creator.username}
                src={itemData.creator.avatar.backend_media_url}
                sx={{ width: 56, height: 56, border: "1px solid #D9D9D9" }}
              />
            ) : (
              <Avatar className={styles.noAvatar} sx={{ background: "#fff" }}>
                <ProfileIcon width={24} height={24} />
              </Avatar>
            )}
            <Box className={styles.info}>
              <Box className={styles.infoHeader}>
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize="18px"
                    marginTop="-11px"
                    fontWeight="bold"
                  >
                    {itemData.creator.username}
                  </Typography>
                  {itemData.creator.new_content_count &&
                  itemData.creator.new_content_count > 0 ? (
                    <Badge
                      badgeContent={`+${itemData.creator.new_content_count}`}
                      color="error"
                      max={9999}
                      sx={{ marginLeft: "28px", marginBottom: "10px" }}
                    />
                  ) : null}
                </Box>
                {tab === "top_ranked" &&
                  renderCrown(itemData.creator.rank || 0)}
                {draggable && (
                  <IconButton
                    {...listeners}
                    {...attributes}
                    color="primary"
                    size="small"
                    disableRipple
                    data-cy="drag"
                    sx={{
                      cursor: isDragging ? "grabbing" : "grab",
                    }}
                  >
                    <DragIcon width={20} height={13} />
                  </IconButton>
                )}
              </Box>

              <Box className={styles.infoFooter}>
                <Typography fontSize="16px">
                  Subscribers: {itemData.creator._count.subscribers}
                </Typography>
                <Typography fontSize="16px">
                  Interested: {itemData.creator._count.interested_users}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.file}>
            {itemData.creator.greetings !== null &&
              itemData.creator.greetings?.length > 0 && (
                <>
                  {itemData.creator.greetings[0]?.type?.includes("video") &&
                    renderVideoElement(
                      itemData.creator.greetings[0].backend_media_url
                    )}
                  {itemData.creator.greetings[0]?.type?.includes("image") && (
                    <img
                      src={itemData.creator.greetings[0].backend_media_url}
                      alt="content"
                      className={styles.cardMedia}
                    />
                  )}
                </>
              )}
            {itemData.creator.greetings?.length === 0 &&
              itemData.creator.avatar?.backend_media_url && (
                <img
                  src={itemData.creator.avatar.backend_media_url}
                  alt="content"
                  className={styles.cardMedia}
                />
              )}
          </Box>
        </Box>
      )}

      {view === "list" && (
        <Box className={styles.cardList} onClick={handleOpenCreatorCard}>
          <Box className={styles.fileList}>
            {itemData.creator.greetings != null &&
              itemData.creator.greetings.length > 0 && (
                <>
                  {itemData.creator.greetings[0]?.type?.includes("video") &&
                    renderVideoElement(
                      itemData.creator.greetings[0].backend_media_url
                    )}
                  {itemData.creator.greetings[0]?.type?.includes("image") && (
                    <img
                      src={itemData.creator.greetings[0].backend_media_url}
                      alt="content"
                      className={styles.cardMedia}
                    />
                  )}
                </>
              )}
            {itemData.creator.greetings?.length === 0 &&
              itemData.creator.avatar?.backend_media_url && (
                <img
                  src={itemData.creator.avatar?.backend_media_url}
                  alt="content"
                  className={styles.cardMedia}
                />
              )}
            <Box className={styles.headerList}>
              {itemData.creator.avatar ? (
                <Avatar
                  alt={itemData.creator.username}
                  src={itemData.creator.avatar.backend_media_url}
                  sx={{ width: 56, height: 56, border: "1px solid #D9D9D9" }}
                />
              ) : (
                <Avatar className={styles.noAvatar} sx={{ background: "#fff" }}>
                  <ProfileIcon width={24} height={24} />
                </Avatar>
              )}

              <Box className={styles.infoList}>
                <Box className={styles.infoHeaderList}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      fontSize="18px"
                      marginTop="-11px"
                      fontWeight="bold"
                      color="white"
                    >
                      {itemData.creator.username}
                    </Typography>
                    {itemData.creator.new_content_count &&
                    itemData.creator.new_content_count > 0 ? (
                      <Badge
                        badgeContent={`+${itemData.creator.new_content_count}`}
                        color="error"
                        max={9999}
                        sx={{
                          marginBottom: "9px",
                          marginLeft: "30px",
                        }}
                      />
                    ) : null}
                  </Box>
                  {tab === "top_ranked" &&
                    renderCrown(itemData.creator.rank || 0)}
                  {draggable && (
                    <IconButton
                      {...listeners}
                      {...attributes}
                      color="primary"
                      size="small"
                      disableRipple
                      data-cy="drag"
                      sx={{
                        cursor: isDragging ? "grabbing" : "grab",
                      }}
                    >
                      <DragIcon width={20} height={13} />
                    </IconButton>
                  )}
                </Box>
                <Box className={styles.infoFooterList}>
                  <Typography fontSize="16px">
                    Subscribers: {itemData.creator._count.subscribers}
                  </Typography>
                  <Typography fontSize="16px">
                    Interested: {itemData.creator._count.interested_users}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <ModalWrapper shown={showCreatorCard} close={handleCloseCreatorCard}>
        <HomeCreatorCard handleCloseCreatorCard={handleCloseCreatorCard} />
      </ModalWrapper>
    </>
  );
};
