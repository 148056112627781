import { Box } from "@mui/material";
import { FastAverageColor } from "fast-average-color";
import { FC, useEffect, useState } from "react";

import styles from "./greeting-media.module.scss";
import { GreetingVideo } from "./gretting-content/greeting-video";

interface GreetingMediaProps {
  mediaUrl: string;
  type: "image" | "video" | null;
  isMediaActive?: boolean;
  isDominantColorEnabled?: boolean;
}

export const GreetingMedia: FC<GreetingMediaProps> = ({
  mediaUrl,
  type,
  isMediaActive = true,
  isDominantColorEnabled = false,
}) => {
  const [backgroundColors, setBackgroundColors] = useState<(string | null)[]>(
    []
  );

  const getDominantColor = async (
    fac: FastAverageColor,
    imageUrl: string
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;

      img.onload = () => {
        try {
          const color = fac.getColor(img).hex;
          resolve(color);
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });

  useEffect(() => {
    if (!mediaUrl) return;

    const fetchColors = async () => {
      if (!mediaUrl) return;

      const fac = new FastAverageColor();
      const dominantColor = await getDominantColor(fac, mediaUrl);
      setBackgroundColors([dominantColor]);
    };

    fetchColors();
  }, [mediaUrl]);

  return (
    <Box
      className={styles.greetingMediaLayout}
      sx={{
        width: "100%",
        height: "100%",
        filter: isMediaActive ? "none" : "blur(25px)",
        /* eslint-disable-next-line no-nested-ternary */
        backgroundColor: !isDominantColorEnabled
          ? "rgba(255, 255, 255, 0)"
          : backgroundColors[0] !== null && isDominantColorEnabled
            ? backgroundColors[0]
            : "opacity: 0.9",
      }}
    >
      {type?.includes("video") ? (
        <GreetingVideo url={mediaUrl} />
      ) : (
        <img src={mediaUrl} alt="content" className={styles.cardMedia} />
      )}
    </Box>
  );
};
