import { Button, Typography } from "@mui/material";
import type { FC } from "react";

import signUpShared from "../../sign-up-shared.module.scss";
// import type { AgeVerificationType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

type AgeVerificationProp = {
  setAgeVerificationStep: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  isAgeVerified: boolean;
};

export const AgeVerification: FC<AgeVerificationProp> = ({
  isAgeVerified,
  setAgeVerificationStep,
}) => {
  const onAgeVerifyHandler = () => {
    if (isAgeVerified) {
      return;
    }
    setAgeVerificationStep(true);
  };

  return (
    <SignUpCard status={isAgeVerified ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Verify your age and identification
        </Typography>
        <form className={signUpShared.cardForm} noValidate>
          <Button
            color={isAgeVerified ? "success" : "primary"}
            className={signUpShared.actionBtnStretch}
            variant="contained"
            type="button"
            onClick={onAgeVerifyHandler}
          >
            <Typography variant="button">
              {isAgeVerified ? "Verified" : "Verify"}
            </Typography>
          </Button>
        </form>
      </div>
    </SignUpCard>
  );
};
