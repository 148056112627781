export const REGEX = {
  PASSWORD: {
    GENERAL:
      /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).(?=.*[.!@#$&*]).{7,}$/,
    LENGTH: /^.{8,}$/,
    UPPERCASE: /[A-Z]+/,
    LOWERCASE: /[a-z]+/,
    NUMBER: /[0-9]+/,
    CHARS: /[.!@#$&*]+/,
  },
  CURRENCY_TRANSFER: /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)%$/,
  DIGITS_ONLY: /^\d*[.{1}\d*]\d*$/,
};
