import { useState } from "react";

import { HeaderWithSearch } from "../header-with-search/header-with-search";
import { InterestedUsersList } from "./interested-users-list";

export const InterestedUsers = () => {
  const [searchText, setSearchText] = useState<string>("");

  return (
    <>
      <HeaderWithSearch setSearchText={setSearchText} />
      <InterestedUsersList searchText={searchText} />
    </>
  );
};
