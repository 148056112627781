import {
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import type { FC } from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import type { ISubmitState } from "../../../../../../hooks/submit-state";
import { DeleteCrossIcon } from "../../../../../../media/icons/delete-cross-icon";
import type {
  IBackyardBanishmentDetails,
  IBackyardBanishmentUpdate,
} from "../../../../../../services/settings/interfaces/settings.interface";
import { BanishmentKeys } from "../../banishment";

interface BanishByCodeProp {
  banishmentData: IBackyardBanishmentDetails | null | undefined;
  onUpdateBackyardBanishment: (
    backyardBanishment: IBackyardBanishmentUpdate
  ) => Promise<void>;
  submitState: ISubmitState;
  resetState: () => void;
}

export const BanishByCode: FC<BanishByCodeProp> = ({
  banishmentData,
  onUpdateBackyardBanishment,
  submitState,
  resetState,
}) => {
  const [zipCodes, setZipCodes] = useState<string[]>([]);
  const [limitError, setLimitError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (submitState.color === "error" || submitState.color === "success") {
      setIsLoading(false);
    }
  }, [submitState]);

  useEffect(() => {
    if (banishmentData) {
      setZipCodes(banishmentData.zip_codes || []);
    }
  }, [banishmentData]);

  const formik = useFormik({
    initialValues: {
      zipCode: "",
    },
    validationSchema: Yup.object({
      zipCode: Yup.string()
        .required()
        .test("is-zip-code", "", (value) => /^[0-9]{5}$/.test(value))
        .test("is-zip-code-not-empty", "", (value) => value.trim() !== ""),
    }),
    onSubmit: async (values: { zipCode: string }) => {
      if (zipCodes.includes(values.zipCode)) return;
      resetState();

      if (zipCodes.length === 3) {
        setLimitError(true);
        return;
      }

      const arrayOfCodes = [...zipCodes, values.zipCode];
      setZipCodes(arrayOfCodes);
      formik.resetForm();
    },
  });

  const handleDelete = (codeToDelete: string) => {
    resetState();
    const arrayOfCodes = zipCodes.filter((code) => code !== codeToDelete);
    setZipCodes(arrayOfCodes);

    setLimitError(false);
  };

  const handleApplyCodes = () => {
    onUpdateBackyardBanishment({
      type: BanishmentKeys.zipCodes,
      data: {
        zip_codes: zipCodes,
        radius: null,
        state: null,
        user_zip_code: null,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <TextField
        name="zipCode"
        error={limitError}
        onChange={(e) => {
          formik.handleChange(e);

          if (e.target.value === "") {
            setLimitError(false);
          }
        }}
        placeholder="Enter ZIP code and press Enter"
        fullWidth
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            formik.handleSubmit();
          }
        }}
        value={formik.values.zipCode}
      />
      {limitError && (
        <Typography
          variant="caption"
          align="left"
          color="#FF5252"
          marginTop="3px"
        >
          Only 3 ZIP codes are acceptable
        </Typography>
      )}

      <Box
        sx={{
          marginTop: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {zipCodes.map((code) => (
            <Chip
              key={code}
              label={<Typography variant="button">{code}</Typography>}
              variant="outlined"
              onDelete={() => handleDelete(code)}
              deleteIcon={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DeleteCrossIcon width={10} height={10} />
                </Box>
              }
              sx={{
                padding: "20px 10px",
                gap: "10px",
                background: "#fff",
                borderRadius: "10px",
                border: "none",
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{ width: "108px" }}
            variant="contained"
            disabled={zipCodes.length === 0}
            color={submitState.color}
            onClick={(e) => {
              if (submitState.color === "error") {
                e.preventDefault();
                resetState();
                return;
              }

              if (submitState.color === "success") {
                e.preventDefault();
                return;
              }

              handleApplyCodes();
              setIsLoading(true);
            }}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="1.5rem" />
            ) : (
              <Typography variant="button">{submitState.text}</Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
