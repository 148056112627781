import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../media/icons/btc";
import { DeleteIcon } from "../../../media/icons/delete-icon";
import { EditIcon } from "../../../media/icons/edit";
import type { IUserTransferAccounts } from "../../../services/sign-in/interfaces/initialize.interface";
import { TextAreaWithAbilityToCopy } from "../../shared/text-area-with-ability-to-copy/text-area-with-ability-to-copy";

type Props = {
  transferAccounts: IUserTransferAccounts[];
  handleEdit: (row: IUserTransferAccounts) => () => void;
  handleDelete: (row: IUserTransferAccounts) => () => void;
};

export const TransferAccountsList = ({
  transferAccounts,
  handleEdit,
  handleDelete,
}: Props) => (
  <Table
    sx={{
      width: "50%",
      marginBottom: "1rem",
    }}
  >
    <TableBody>
      {transferAccounts.map((row) => {
        const accountTitle = row.crypto
          ? row?.crypto.name || row?.crypto?.wallet_id
          : row?.bank_account?.name || row?.bank_account?.bank_account_number;

        return (
          <TableRow
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              "&:first-of-type": { borderTop: "none" },
            }}
            key={row.id}
          >
            <TableCell sx={{ paddingLeft: 0 }}>
              <Box
                sx={{
                  gap: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {row.crypto ? (
                  <BtcLogo width={32} height={32} />
                ) : (
                  <BankAcc width={32} height={32} />
                )}
                <TextAreaWithAbilityToCopy
                  sx={{
                    maxWidth: "10rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  textToCopy={accountTitle}
                  tooltipTitle="Copied"
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                display: "flex",
                paddingRight: 0,
              }}
            >
              <Button
                startIcon={<EditIcon width={20} height={20} />}
                onClick={handleEdit(row)}
                variant="outlined"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  width: "114px",
                  border: "1.5px solid",
                  letterSpacing: "0.5px",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <Typography fontWeight={500}>Edit</Typography>
              </Button>

              <IconButton
                color="error"
                onClick={handleDelete(row)}
                sx={{
                  marginLeft: "0.8rem",
                }}
              >
                <DeleteIcon color="red" />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
