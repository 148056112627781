import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useState } from "react";

import { useAppSelector } from "../../../hooks/redux";
import SettingsIcon from "../../../media/icons/catalog/content-card/settings-icon";
import { MediaImageIcon } from "../../../media/icons/media-image";
import { ProfileIcon } from "../../../media/icons/profile";
import { ICreatorCardData } from "../../../services/home/interfaces/home.interface";
import type { IUserAvatar } from "../../../services/sign-in/interfaces/initialize.interface";
import { GreetingVideoContextProvider } from "../../creator-card/greetings-carousel/greeting-media.tsx/gretting-content/greeting-video-context";
import { GreetingsCarousel } from "../../creator-card/greetings-carousel/greetings-carousel";
import { ModalWrapper } from "../../creator-card/modal-wrapper/modal-wrapper";
import { CatalogCreatorCard } from "../../profile/creator-catalog/creator-catalog-cc-view/side-bar-cc-view/catalog-creator-card/catalog-creator-card";
import styles from "./creator-card-sidebar-view.module.scss";

interface CreatorCardCCViewParams {
  creatorCard: ICreatorCardData | null;
  setCreatorCard: Dispatch<SetStateAction<ICreatorCardData | null>>;
  isPreview?: boolean;
}

export const CreatorCardSidebarView: FC<CreatorCardCCViewParams> = ({
  creatorCard,
  setCreatorCard,
  isPreview = false,
}) => {
  const { user } = useAppSelector((item) => item.userReducer);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCreatorCard, setShowCreatorCard] = useState<boolean>(false);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenCreatorCard = () => {
    if (isPreview) return;
    setShowCreatorCard(true);
  };
  const handleCloseCreatorCard = () => {
    setShowCreatorCard(false);
  };

  const handleCopyLink = () => {
    const { protocol, host } = window.location;
    const domain = `${protocol}//${host}`;

    navigator.clipboard.writeText(`${domain}/user-profile/${creatorCard?.id}`);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box className={styles.card}>
        <Box className={styles.header}>
          {creatorCard?.avatar ? (
            <Avatar
              alt={creatorCard.username}
              src={creatorCard.avatar.backend_media_url}
              sx={{ width: 64, height: 64, border: "1px solid #D9D9D9" }}
            />
          ) : (
            <Avatar
              className={styles.noAvatar}
              sx={{ background: "#fff", width: 64, height: 64 }}
            >
              <ProfileIcon width={34} height={34} color="#3A75E9" />
            </Avatar>
          )}
          <Box className={styles.info}>
            <Box className={styles.infoHeader}>
              <Typography
                fontSize="18px"
                marginTop="-11px"
                fontWeight="bold"
                color="#252733"
              >
                {creatorCard?.username}
              </Typography>
              <IconButton aria-describedby={id} onClick={handleOpenPopover}>
                <SettingsIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box className={styles.infoFooter}>
          <Box className={styles.infoFooterItem}>
            <Typography variant="body1" color="#4C4B4C">
              Posts
            </Typography>
            <Typography
              variant="body1"
              fontSize="18px"
              color="#252733"
              fontWeight="bold"
            >
              {isPreview ? (
                124
              ) : (
                <>
                  {creatorCard?.number_of_posts
                    ? creatorCard.number_of_posts
                    : 0}
                </>
              )}
            </Typography>
          </Box>

          <Box className={styles.infoFooterItem}>
            <Typography variant="body1" color="#4C4B4C">
              Subscribers
            </Typography>
            <Typography
              variant="body1"
              fontSize="18px"
              color="#252733"
              fontWeight="bold"
            >
              {isPreview ? "12.1K" : <>{creatorCard?._count.subscribers}</>}
            </Typography>
          </Box>

          <Box className={styles.infoFooterItem}>
            <Typography variant="body1" color="#4C4B4C">
              Rank
            </Typography>
            <Typography
              variant="body1"
              fontSize="18px"
              color="#252733"
              fontWeight="bold"
            >
              {isPreview ? (
                "32 of 24K"
              ) : (
                <>
                  {creatorCard?.rank === null ? 0 : creatorCard?.rank} of{" "}
                  {creatorCard?.creators_amount}
                </>
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.file}>
          {creatorCard?.greetings &&
          (creatorCard?.greetings?.length > 0 || creatorCard.avatar) ? (
            <GreetingVideoContextProvider
              showControls={false}
              muted
              isPlaying={!!user?.media_play_automatically}
            >
              <GreetingsCarousel
                handleOpenCreatorCard={handleOpenCreatorCard}
                greetings={
                  creatorCard.greetings.length > 0
                    ? creatorCard.greetings
                    : [creatorCard.avatar as IUserAvatar]
                }
                toggleGreetings={creatorCard.greetings.length > 1}
              />
            </GreetingVideoContextProvider>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "473px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(196, 196, 196, 0.2) 100%)",
              }}
            >
              <MediaImageIcon color="#fff" width={128} height={128} />
            </Box>
          )}
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Button type="button" variant="text" onClick={handleCopyLink}>
          <Typography variant="button" color="#252733">
            Copy profile link
          </Typography>
        </Button>
      </Popover>

      <ModalWrapper shown={showCreatorCard} close={handleCloseCreatorCard}>
        <CatalogCreatorCard
          handleCloseCreatorCard={handleCloseCreatorCard}
          creatorCard={creatorCard}
          setCreatorCard={setCreatorCard}
        />
      </ModalWrapper>
    </>
  );
};
