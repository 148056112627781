import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export interface ISubmitState {
  color: "error" | "success" | "primary";
  text: string;
  errorCode?: number | string;
}

interface IUseSubmitStateProps {
  requestError: FetchBaseQueryError;
  defaultText: string;
  successText: string;
  isRequestSuccess: boolean;
  statusGreaterThan?: number;
}

export default function useSubmitState(
  props: IUseSubmitStateProps
): ISubmitState {
  const {
    isRequestSuccess,
    defaultText,
    statusGreaterThan = 400,
    successText,
    requestError,
  } = props;

  const submitState = (): ISubmitState => {
    if (requestError && requestError.status >= statusGreaterThan) {
      return {
        text: `Retry`,
        color: "error",
        errorCode: requestError.status,
      };
    }

    if (
      isRequestSuccess ||
      (requestError && requestError.status < statusGreaterThan)
    ) {
      return {
        text: successText,
        color: "success",
      };
    }

    return {
      text: defaultText,
      color: "primary",
    };
  };

  return {
    color: submitState().color,
    text: submitState().text,
    errorCode: submitState().errorCode,
  };
}
