import type { FC } from "react";

type ExitIconProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
  mirrored?: boolean;
};

export const ExitIcon: FC<ExitIconProp> = ({
  width = 32,
  height = 32,
  disabled = false,
  mirrored = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `${mirrored ? "scale(-1, 1)" : "inherit"}` }}
  >
    <g clipPath="url(#clip0_2871_62646)">
      <path
        d="M15.2195 6.11494L12.6335 3.52894C12.5078 3.4075 12.3394 3.3403 12.1646 3.34182C11.9898 3.34334 11.8226 3.41345 11.699 3.53706C11.5754 3.66066 11.5052 3.82787 11.5037 4.00267C11.5022 4.17747 11.5694 4.34587 11.6908 4.4716L14.2768 7.05761C14.3537 7.13596 14.4208 7.22325 14.4768 7.3176C14.4668 7.3176 14.4588 7.31227 14.4488 7.31227L3.99284 7.3336C3.81603 7.3336 3.64646 7.40384 3.52143 7.52887C3.39641 7.65389 3.32617 7.82346 3.32617 8.00027C3.32617 8.17708 3.39641 8.34665 3.52143 8.47168C3.64646 8.5967 3.81603 8.66694 3.99284 8.66694L14.4448 8.64561C14.4635 8.64561 14.4788 8.63627 14.4968 8.63494C14.4377 8.74771 14.3627 8.85143 14.2742 8.94294L11.6882 11.5289C11.6245 11.5904 11.5737 11.664 11.5388 11.7453C11.5038 11.8267 11.4854 11.9142 11.4847 12.0027C11.4839 12.0912 11.5008 12.179 11.5343 12.2609C11.5678 12.3428 11.6173 12.4173 11.6799 12.4799C11.7425 12.5425 11.8169 12.592 11.8989 12.6255C11.9808 12.659 12.0686 12.6759 12.1571 12.6751C12.2456 12.6743 12.3331 12.6559 12.4144 12.621C12.4958 12.5861 12.5693 12.5353 12.6308 12.4716L15.2168 9.8856C15.7168 9.38553 15.9976 8.70738 15.9976 8.00027C15.9976 7.29317 15.7168 6.61501 15.2168 6.11494H15.2195Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
      <path
        d="M4.66667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H4.66667C4.84348 1.33333 5.01305 1.2631 5.13807 1.13807C5.2631 1.01305 5.33333 0.843478 5.33333 0.666667C5.33333 0.489856 5.2631 0.320286 5.13807 0.195262C5.01305 0.0702379 4.84348 0 4.66667 0L3.33333 0C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H4.66667C4.84348 16 5.01305 15.9298 5.13807 15.8047C5.2631 15.6797 5.33333 15.5101 5.33333 15.3333C5.33333 15.1565 5.2631 14.987 5.13807 14.8619C5.01305 14.7369 4.84348 14.6667 4.66667 14.6667Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_2871_62646">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
