import {
  Avatar,
  Box,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { getReadableDateTable } from "../../../helpers/helpers";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useFetchInterestedUsersMutation } from "../../../services/manage-subscribers/manage-subscribers.service";
import { interestedUsersSlice } from "../../../store/reducers/manage-subscribers/interested-users";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import { DefaultAvatar } from "../../shared/default-avatar/default-avatar";
import styles from "../manage-subscribers.module.scss";
import { interestedUsersTableHeads } from "./interested-users-tablle-head";

type InterestedUsersListProps = {
  searchText: string;
};

export const InterestedUsersList: FC<InterestedUsersListProps> = ({
  searchText,
}) => {
  const rowsPerPage = 5;

  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { setInterestedUsers } = interestedUsersSlice.actions;
  const { interestedUsers } = useAppSelector(
    (reducers) => reducers.interestedUsersReducer
  );
  const [
    fetchInterestedUsers,
    { error, isLoading, data: interestedUsersData },
  ] = useFetchInterestedUsersMutation();

  const navigate = useNavigate();

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    interestedUsers,
    interestedUsersData?.total || 0,
    interestedUsersTableHeads
  );

  const getSubscribers = useCallback(async () => {
    await fetchInterestedUsers({
      accessToken: accessToken || "",
      rowsPerPage,
      page: page + 1,
      direction,
      sortBy,
      searchText,
    }).unwrap();
  }, [
    accessToken,
    fetchInterestedUsers,
    rowsPerPage,
    direction,
    sortBy,
    page,
    searchText,
  ]);

  useEffect(() => {
    getSubscribers();
  }, [getSubscribers, page]);

  useEffect(() => {
    if (error) {
      setInterestedUsers([]);
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    if (page === 0) {
      dispatch(setInterestedUsers(interestedUsersData?.data || []));
      return;
    }

    dispatch(
      setInterestedUsers([
        ...interestedUsers,
        ...(interestedUsersData?.data || []),
      ])
    );
  }, [interestedUsersData]);

  useEffect(() => {
    dispatch(setInterestedUsers([]));
    setPage(0);
  }, [sortBy, searchText, direction]);

  return (
    <>
      <Table
        sx={{
          overflow: "hidden",
          tableLayout: "fixed",
        }}
        aria-label="simple table"
      >
        <CustomTableHead
          tableHeads={interestedUsersTableHeads}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
        />

        <TableBody>
          {interestedUsers.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              ref={
                interestedUsers.length === index + 1
                  ? lastMemberElementRef
                  : null
              }
            >
              <TableCell
                className={styles.tableCell}
                sx={{
                  width: "25%",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  gap="0.5rem"
                >
                  {row.interested_user.avatar ? (
                    <Avatar
                      alt={row.interested_user.username}
                      src={row.interested_user.avatar.backend_media_url}
                      sx={{ width: 32, height: 32 }}
                    />
                  ) : (
                    <Avatar
                      alt={row.interested_user.username}
                      sx={{ width: 32, height: 32 }}
                    >
                      <DefaultAvatar width={32} height={32} />
                    </Avatar>
                  )}
                  <Tooltip
                    arrow
                    title={row.interested_user?.username}
                    placement="top"
                  >
                    <Typography
                      variant="body1"
                      className={styles.textWithOverFlowHidden}
                    >
                      {row.interested_user?.username}
                    </Typography>
                  </Tooltip>
                </Box>
              </TableCell>

              <TableCell
                className={styles.tableCell}
                sx={{
                  width: "25%",
                }}
              >
                <Typography variant="body1">
                  {getReadableDateTable(row.created_at)}
                </Typography>
              </TableCell>

              <TableCell
                className={styles.tableCell}
                sx={{
                  width: "15%",
                }}
              >
                <Link to="/cc/earnings">
                  <Typography variant="body1">
                    {row.interested_user?.oneTimePurchase.length}
                  </Typography>
                </Link>
              </TableCell>

              <TableCell
                className={styles.tableCell}
                sx={{
                  width: "35%",
                }}
              >
                <Link to="/cc/earnings">
                  <Typography variant="body1">
                    {row.interested_user?.sent_tips?.length}
                  </Typography>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress data-cy="loader" />}
    </>
  );
};
