import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { PaymentMethodsResponseInterface } from "../../../components/payment-info/interfaces/payment-method.interface";

interface PaymentMethodsState {
  paymentMethods: PaymentMethodsResponseInterface[];
}

const initialState: PaymentMethodsState = {
  paymentMethods: [],
};

export const paymentMethodReducer = createSlice({
  name: "paymentMethodReducer",
  initialState,
  reducers: {
    setPaymentMethods(
      state,
      action: PayloadAction<PaymentMethodsResponseInterface[]>
    ) {
      state.paymentMethods = action.payload;
    },
    makeDefault(state, action: PayloadAction<{ id: string }>) {
      const foundedMethod = state.paymentMethods.findIndex(
        (item) => item.id === action.payload.id
      );

      const defaultMethod = state.paymentMethods.findIndex(
        (item) => item.default === true
      );

      if (defaultMethod >= 0) {
        state.paymentMethods[defaultMethod].default = false;
      }

      if (foundedMethod >= 0) {
        state.paymentMethods[foundedMethod].default = true;
      }
    },
    deleteMethod(state, action: PayloadAction<{ id: string }>) {
      state.paymentMethods = state.paymentMethods.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateMethod(
      state,
      action: PayloadAction<{
        id: string;
        method: PaymentMethodsResponseInterface;
      }>
    ) {
      const foundedMethod = state.paymentMethods.findIndex(
        (item) => item.id === action.payload.id
      );

      state.paymentMethods[foundedMethod] = action.payload.method;
    },
  },
});

export default paymentMethodReducer.reducer;
