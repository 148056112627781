import type { DefaultSelectOptions } from "../../../shared/select/select";

// eslint-disable-next-line no-shadow
export enum BanishmentKeys {
  none = "none",
  zipCodes = "byCodes",
  state = "byState",
  withinMiles = "byRadius",
}

export const banishmentDropdown: DefaultSelectOptions[] = [
  {
    value: BanishmentKeys.zipCodes,
    display_text: "Banish up to 3 ZIP codes",
    disabled: false,
  },
  {
    value: BanishmentKeys.state,
    display_text: "Banish one entire State",
    disabled: false,
  },
  {
    value: BanishmentKeys.withinMiles,
    display_text: "Banish users within 10-100 miles of your ZIP code",
    disabled: false,
  },
];
