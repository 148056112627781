import { Box } from "@mui/material";

import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import InvitesList from "./invitations-list/invitations-list";
import styles from "./invitations.module.scss";
import InviteFriend from "./invite-friend/invite-friend";

export default function Invitations() {
  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.mainCardWrapper}>
          <InviteFriend />
          <InvitesList />
        </Box>
      </MainWrapper>
    </Box>
  );
}
