import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { IWallet } from "../../../services/wallet/interfaces/fetch-wallets-response.interface";

interface ITotalEarningsState {
  totalEarnings: IWallet;
}

const initialState: ITotalEarningsState = {
  totalEarnings: {
    usd: {
      _sum: {
        amount: null,
      },
    },
    euro: {
      _sum: {
        amount: null,
      },
    },
    btc: {
      _sum: {
        amount: null,
      },
    },
  },
};

export const totalEarningsSlice = createSlice({
  name: "totalEarnings",
  initialState,
  reducers: {
    setTotalEarnings(state, action: PayloadAction<IWallet>) {
      state.totalEarnings = action.payload;
    },
    setInitialTotalEarningsState(state) {
      state.totalEarnings = initialState.totalEarnings;
    },
  },
});

export default totalEarningsSlice.reducer;
