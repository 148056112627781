import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { REGEX } from "../../../../constants/regex/regex";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { BankAcc } from "../../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../../media/icons/btc";
import { DeleteIcon } from "../../../../media/icons/delete-icon";
import { DollarLogo } from "../../../../media/icons/dollar";
import { EuroLogo } from "../../../../media/icons/euro";
import type {
  AutomaticTransfer,
  CurrencyTransfer,
  IUserTransferAccounts,
} from "../../../../services/sign-in/interfaces/initialize.interface";
import { automaticTransfersSlice } from "../../../../store/reducers/automatic-transfers/automatic-transfers";
import DefaultSelect from "../../../shared/select/select";
import { notAvailables, paymentValues } from "../../data";
import { CurrenciesEnum } from "../../enums/currencies.enum";
import type { AutomaticTransferFormInterface } from "../../interfaces/automatic-transfer.interface";

type CurrencyTransferProps = {
  handleValidTransfer: (isValid: boolean, transferId: string) => void;
  currency: CurrenciesEnum;
  automaticTransfer: AutomaticTransfer;
  currencyTransfer: CurrencyTransfer;
  isDeleteLoading: boolean;
  reset: () => void;
  index: number;
  setOpenDialog: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setCurrencyTransferId: (
    value: string | ((prevVar: string) => string)
  ) => void;
  setSelectedAutomaticTransfer: (
    value: string | ((prevVar: string) => string)
  ) => void;
};

export default function AutomaticCurrencyTransfer(
  props: CurrencyTransferProps
) {
  const {
    handleValidTransfer,
    currency,
    automaticTransfer,
    currencyTransfer,
    isDeleteLoading,
    reset,
    index,
    setCurrencyTransferId,
    setOpenDialog,
    setSelectedAutomaticTransfer,
  } = props;

  const { changeValue } = automaticTransfersSlice.actions;

  const dispatch = useAppDispatch();

  const { transferAccounts } = useAppSelector(
    (item) => item.transferAccountsReducer
  );

  const formik = useFormik({
    initialValues: {
      to: currencyTransfer.transfer_account,
      payment_type: currencyTransfer.payment_type,
      amount: currencyTransfer.amount,
      custom_amount: currencyTransfer.custom_amount,
      if_amount_not_available: currencyTransfer.if_amount_is_not_available,
    },
    validationSchema: Yup.object({
      to: Yup.string().required(),
      payment_type: Yup.string().required(),
      amount: Yup.number().required(" "),
      custom_amount: Yup.string()
        .required(" ")
        .matches(REGEX.CURRENCY_TRANSFER, " "),
      if_amount_not_available: Yup.string().required(),
    }),
    onSubmit: async (
      values: AutomaticTransferFormInterface,
      helpers: FormikHelpers<AutomaticTransferFormInterface>
    ) => {
      try {
        /* Waiting for further implementation */
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const {
      to,
      amount,
      payment_type: paymentType,
      custom_amount: customAmount,
    } = formik.values;

    const invalidTo = !to;
    const invalidFixedAmount =
      paymentType === "fixed" && (!amount || Number.isNaN(Number(amount)));
    const invalidPercentageOfTotal =
      paymentType === "percentageOfTotal" &&
      (!customAmount || !REGEX.CURRENCY_TRANSFER.test(customAmount));

    if (invalidTo || invalidFixedAmount || invalidPercentageOfTotal) {
      handleValidTransfer(false, currencyTransfer.id);
      return;
    }

    handleValidTransfer(true, currencyTransfer.id);
  }, [formik.values]);

  const clearAmount = () => {
    formik.setFieldValue("amount", "");
    formik.setFieldValue("custom_amount", "");
  };

  useEffect(() => {
    const item = automaticTransfer.currency_transfers.find(
      (trans) => trans.id === currencyTransfer.id
    );

    formik.setFieldValue("amount", item?.amount);
    formik.setFieldValue("custom_amount", item?.custom_amount);
    formik.setFieldValue("to", item?.transfer_account);
  }, [automaticTransfer.currency]);

  const renderAmount = () => (
    <>
      <TextField
        placeholder="Enter amount"
        type="text"
        disabled={isDeleteLoading}
        error={!!(formik.touched.amount && formik.errors.amount)}
        name="amount"
        onBlur={formik.handleBlur}
        onChange={(e) => {
          formik.handleChange(e);

          dispatch(
            changeValue({
              automatic_transfer_id: automaticTransfer.id,
              currency_transfer_id: currencyTransfer.id,
              key: "amount",
              value: e.target.value,
            })
          );
          reset();
        }}
        value={formik.values.amount}
        sx={{ width: "300px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {currency === CurrenciesEnum.BTC && (
                <BtcLogo width={24} height={24} />
              )}
              {currency === CurrenciesEnum.EURO && (
                <EuroLogo width={24} height={24} />
              )}
              {currency === CurrenciesEnum.USD && (
                <DollarLogo width={24} height={24} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const calculateDisplayText = (item: IUserTransferAccounts) => {
    if (item.bank_account) {
      return item.bank_account.name
        ? item.bank_account.name
        : item.bank_account.bank_account_number;
    }

    if (item.crypto) {
      return item.crypto.name ? item.crypto.name : item.crypto.wallet_id;
    }

    return "";
  };

  const formatPercentageAmount = (value: string) => {
    if (value === "" || Number.isNaN(Number(value))) {
      return "0";
    }
    const numValue = Number(value);
    if (numValue < 1) {
      value = "0";
    } else if (numValue > 100) {
      value = "100";
    }
    return value;
  };

  const isTransferAccountAdded =
    transferAccounts.length > 0 &&
    ((automaticTransfer.currency === CurrenciesEnum.BTC &&
      transferAccounts.some(
        (transferAccount) => transferAccount.crypto !== null
      )) ||
      (automaticTransfer.currency !== CurrenciesEnum.BTC &&
        transferAccounts.some(
          (transferAccount) => transferAccount.bank_account !== null
        )));

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight={700}>Transfer № {index + 1}</Typography>
        <IconButton
          onClick={() => {
            setOpenDialog(true);
            setCurrencyTransferId(currencyTransfer.id);
            setSelectedAutomaticTransfer(automaticTransfer.id);
          }}
          sx={{ transform: "translate(8px, -1px)" }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>To: </Typography>
        <Box width={300}>
          {isTransferAccountAdded ? (
            <DefaultSelect
              placeholder="Select account"
              disabled={isDeleteLoading}
              name="to"
              options={transferAccounts
                .filter((curr) => {
                  if (automaticTransfer.currency === CurrenciesEnum.BTC) {
                    return (
                      Object.prototype.hasOwnProperty.call(curr, "crypto") &&
                      curr.crypto !== null
                    );
                  }

                  return curr.bank_account !== null;
                })
                .map((item) => ({
                  display_text: calculateDisplayText(item),
                  value: item.id,
                  disabled:
                    automaticTransfer.currency_transfers.filter(
                      (curr) => item.id === curr.transfer_account
                    ).length > 0,
                  start_component: item.bank_account
                    ? BankAcc({ width: 24, height: 24 })
                    : BtcLogo({ width: 24, height: 24 }),
                }))}
              onChange={(value) => {
                formik.setFieldValue("to", value.value);

                dispatch(
                  changeValue({
                    automatic_transfer_id: automaticTransfer.id,
                    currency_transfer_id: currencyTransfer.id,
                    key: "transfer_account",
                    value: value.value,
                  })
                );
                reset();
              }}
              value={formik.values.to}
            />
          ) : (
            <Typography>Please add transfer account</Typography>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Payment Type: </Typography>
        <Box width={300}>
          <DefaultSelect
            name="payment_type"
            disabled={isDeleteLoading}
            options={paymentValues}
            onChange={(value) => {
              formik.setFieldValue("payment_type", value.value);
              clearAmount();

              dispatch(
                changeValue({
                  automatic_transfer_id: automaticTransfer.id,
                  currency_transfer_id: currencyTransfer.id,
                  key: "payment_type",
                  value: value.value,
                })
              );
              reset();
            }}
            value={formik.values.payment_type}
          />
        </Box>
      </Box>
      {formik.values.payment_type === "fixed" ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Amount: </Typography>
          <Box width={300}>{renderAmount()}</Box>
        </Box>
      ) : (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Amount: </Typography>
          <Box width={300}>
            <TextField
              placeholder="Enter amount"
              type="text"
              disabled={isDeleteLoading}
              error={
                !!(formik.touched.custom_amount && formik.errors.custom_amount)
              }
              name="custom_amount"
              onFocus={(e) => {
                if (e.target.value.includes("%")) {
                  formik.setFieldValue(
                    "custom_amount",
                    `${e.target.value.slice(0, -1)}`
                  );
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);

                if (!e.target.value.includes("%") && e.target.value) {
                  formik.setFieldValue(
                    "custom_amount",
                    `${formatPercentageAmount(e.target.value)}%`
                  );
                }
              }}
              onChange={(e) => {
                formik.handleChange(e);

                dispatch(
                  changeValue({
                    automatic_transfer_id: automaticTransfer.id,
                    currency_transfer_id: currencyTransfer.id,
                    key: "custom_amount",
                    value: !e.target.value.includes("%")
                      ? `${formatPercentageAmount(e.target.value)}%`
                      : formatPercentageAmount(e.target.value),
                  })
                );
                reset();
              }}
              value={formik.values.custom_amount}
              sx={{ width: "300px" }}
            />
          </Box>
        </Box>
      )}
      {formik.values.payment_type === "fixed" && (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>If amount is not available: </Typography>
          <Box width={300}>
            <DefaultSelect
              disabled={isDeleteLoading}
              name="if_amount_not_available"
              options={notAvailables}
              onChange={(value) => {
                formik.setFieldValue("if_amount_not_available", value.value);

                dispatch(
                  changeValue({
                    automatic_transfer_id: automaticTransfer.id,
                    currency_transfer_id: currencyTransfer.id,
                    key: "if_amount_is_not_available",
                    value: value.value,
                  })
                );

                reset();
              }}
              value={formik.values.if_amount_not_available}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
