import { Box } from "@mui/material";
import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { RoleEnum } from "../../../enums/role.enum";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useMeMutation } from "../../../services/sign-in/sign-in.service";
import type { AvailableCurrencies } from "../../../store/reducers/automatic-transfers/automatic-transfers";
import { automaticTransfersSlice } from "../../../store/reducers/automatic-transfers/automatic-transfers";
import { loaderSlice } from "../../../store/reducers/loading/loading";
import { manualTransfersSlice } from "../../../store/reducers/manual-transfers/manual-transfers";
import { transferAccountsReducer } from "../../../store/reducers/transfer-accounts/transfer-accounts";
import { userSlice } from "../../../store/reducers/user/user-slice";
import { CurrenciesEnum } from "../../automatic-transfers/enums/currencies.enum";
import "./authorized-wrapper.scss";

export const AuthorizedWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [checked, setChecked] = useState(false);

  const dispatch = useAppDispatch();

  const accessToken = globalThis.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { initialize } = userSlice.actions;
  const { setAutomaticTransfersData } = automaticTransfersSlice.actions;
  const { setManualTransfersData } = manualTransfersSlice.actions;
  const { setTransferAccounts } = transferAccountsReducer.actions;

  const { setLoading } = loaderSlice.actions;

  const { isAuthenticated } = useAppSelector((data) => data.userReducer);
  const { pathname } = useLocation();

  const [me] = useMeMutation();
  const { user } = useAppSelector((data) => data.userReducer);

  const navigate = useNavigate();

  const check = useCallback(async () => {
    dispatch(setLoading({ loading: true }));
    const response = await me({
      accessToken: `${accessToken}`,
    });

    if (Object.prototype.hasOwnProperty.call(response, "error")) {
      const searchParams = new URLSearchParams({
        returnTo: pathname,
      }).toString();
      const href = `/sign-in?${searchParams}`;
      dispatch(setLoading({ loading: false }));
      navigate(href);
    } else {
      const { data } = response as any;
      dispatch(initialize(data.user));

      const transferAccounts = data.user.user_transfer_accounts;
      const automaticTransfers = data.user.automatic_transfers;
      const manualTransfers = data.user.manual_transfers;

      const availableCurrencies: AvailableCurrencies[] = [];

      if (data.user.accept_btc) {
        availableCurrencies.push({
          display_text: "BTC",
          value: CurrenciesEnum.BTC,
        });
      }

      if (data.user.accept_euro) {
        availableCurrencies.push({
          display_text: "EURO",
          value: CurrenciesEnum.EURO,
        });
      }

      if (data.user.accept_usd) {
        availableCurrencies.push({
          display_text: "USD",
          value: CurrenciesEnum.USD,
        });
      }

      dispatch(
        setAutomaticTransfersData({
          automatic_transfers: automaticTransfers,
          available_currencies: availableCurrencies.filter((item) => {
            if (!data.user.accept_btc) {
              return item.value !== CurrenciesEnum.BTC;
            }
            if (!data.user.accept_euro) {
              return item.value !== CurrenciesEnum.EURO;
            }
            if (!data.user.accept_usd) {
              return item.value !== CurrenciesEnum.USD;
            }

            return item;
          }),
        })
      );
      dispatch(
        setManualTransfersData({
          manual_transfers: manualTransfers,
          available_currencies: availableCurrencies.filter((item) => {
            if (!data.user.accept_btc) {
              return item.value !== CurrenciesEnum.BTC;
            }
            if (!data.user.accept_euro) {
              return item.value !== CurrenciesEnum.EURO;
            }
            if (!data.user.accept_usd) {
              return item.value !== CurrenciesEnum.USD;
            }

            return item;
          }),
        })
      );
      dispatch(setTransferAccounts(transferAccounts));
      dispatch(setLoading({ loading: false }));
      setChecked(true);
    }
  }, [isAuthenticated, navigate]);

  const location = useLocation();
  const path = location.pathname;
  const query = location.search;

  if (
    !user?.avatar &&
    user?.role === RoleEnum.creator &&
    path !== "/cc/settings"
  ) {
    if (!path.includes("user-profile") && !query.includes("isPreview=true")) {
      const href = `/cc/settings?avatar=false#personal-info`;
      navigate(href);
    }
  }

  if (
    !user?.avatar &&
    user?.role === RoleEnum.subscriber &&
    path !== "/cs/settings"
  ) {
    const href = `/cs/settings?avatar=false`;
    navigate(href);
  }

  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  return <Box>{children}</Box>;
};
