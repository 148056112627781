import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";

import { DeleteCrossIcon } from "../../../../media/icons/delete-cross-icon";
import { PlusIcon } from "../../../../media/icons/plus";
import type { DefaultSelectOptions } from "../../../shared/select/select";
import DefaultSelect from "../../../shared/select/select";
import styles from "../info.module.scss";
import type { ITastesInfo } from "../interfaces/personal-info.interface";
import { tastes, tastesInfoDropdown } from "./tastes-info";

interface ITastesProps {
  onFormikValueUpdate: (formikKey: string, formikValue: string | null) => void;
  tastesInfoDisplay: ITastesInfo[];
  onTastesAdd: (newDetail: ITastesInfo) => void;
  onTastesDelete: (nameToDelete: string) => void;
}

export const Tastes: FC<ITastesProps> = ({
  onFormikValueUpdate,
  tastesInfoDisplay,
  onTastesDelete,
  onTastesAdd,
}) => {
  const formikKey = "info_on_update.tastes";

  const [tastesInfoDropDown, setTastesInfoDropdown] =
    useState<DefaultSelectOptions[]>(tastesInfoDropdown);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const onInfoSelect = useCallback(
    (value: string) => {
      setShowDropDown(false);

      const newInfo = tastes.find((item) => item.name === value);

      if (newInfo) {
        onTastesAdd(newInfo);
      }
    },
    [tastesInfoDisplay, tastesInfoDropDown]
  );

  useEffect(() => {
    const updatedDropdown = tastesInfoDropdown.map((dropdownItem) => {
      const isPresentInDisplay = tastesInfoDisplay.some(
        (displayItem) => displayItem.name === dropdownItem.value
      );

      return { ...dropdownItem, disabled: isPresentInDisplay };
    });

    setTastesInfoDropdown(updatedDropdown);
  }, [tastesInfoDisplay]);

  const onTastesInfoDelete = (name: string) => {
    onTastesDelete(name);
    onFormikValueUpdate(`${formikKey}.${name}`, null);
  };

  const isAllInfoDisplayed = tastesInfoDropDown.every((item) => item.disabled);

  return (
    <Accordion
      sx={{
        marginTop: "12px",
        borderBottom: "none",

        "&.Mui-expanded": {
          marginTop: "12px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5-content"
        id="panel5-header"
      >
        <Typography variant="button" fontWeight="bold">
          My tastes
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        <Box className={styles.details}>
          {tastesInfoDisplay.map((info, index) => (
            <Box
              key={info.name}
              className={styles.detail}
              sx={{
                marginBottom:
                  isAllInfoDisplayed && index === tastesInfoDisplay.length - 1
                    ? "20px"
                    : "inherit",
              }}
            >
              <Typography variant="body1" width="65%">
                {info.title}:
              </Typography>
              <Box className={styles.inputArea}>
                <TextField
                  name={info.name}
                  placeholder={info.placeholder}
                  fullWidth
                  defaultValue={info.value}
                  onChange={(e) => {
                    onFormikValueUpdate(
                      `${formikKey}.${info.name}`,
                      e.target.value
                    );
                  }}
                />

                <IconButton onClick={() => onTastesInfoDelete(info.name)}>
                  <DeleteCrossIcon width={14} height={14} />
                </IconButton>
              </Box>
            </Box>
          ))}

          {showDropDown && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                }}
              />
              <Box className={styles.inputArea}>
                <DefaultSelect
                  options={tastesInfoDropDown}
                  onChange={(value) => {
                    onInfoSelect(value.value.toString());
                  }}
                  value=""
                  name="info"
                  placeholder="Select..."
                />
                <IconButton
                  onClick={() => {
                    if (showDropDown) {
                      setShowDropDown(false);
                    }
                  }}
                >
                  <DeleteCrossIcon width={14} height={14} />
                </IconButton>
              </Box>
            </Box>
          )}

          {!isAllInfoDisplayed && (
            <Button
              variant="text"
              sx={{
                padding: "10px 0",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "142px",
                marginBottom: "12px",
                marginTop: "-12px",
              }}
              onClick={() => {
                if (!showDropDown) {
                  setShowDropDown(true);
                }
              }}
            >
              <PlusIcon width={12} height={12} />
              <Typography variant="button">Add more info</Typography>
            </Button>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
