import { FC } from "react";

import { useAppSelector } from "../../../hooks/redux";
import { CreatorCard } from "../../creator-card/creator-card";
import { useHomeListContext } from "../home-list/home-list-context/home-list-context";

interface IHomeCreatorCardProp {
  handleCloseCreatorCard: () => void;
}

export const HomeCreatorCard: FC<IHomeCreatorCardProp> = ({
  handleCloseCreatorCard,
}) => {
  const {
    currentCard,
    setCurrentCard,
    tab,
    handleSetPreviousCard,
    handleSetNextCard,
  } = useHomeListContext();
  const { user } = useAppSelector((data) => data.userReducer);

  const isAllowBeInterested = currentCard?.id !== user?.id;

  return (
    <CreatorCard
      handleCloseCreatorCard={handleCloseCreatorCard}
      currentCard={currentCard}
      setCurrentCard={setCurrentCard}
      isRenderRank={tab === "top_ranked"}
      handleSetPreviousCard={handleSetPreviousCard}
      handleSetNextCard={handleSetNextCard}
      isCreatorsSwitchEnabled
      allowBeInterested={isAllowBeInterested}
    />
  );
};
