import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../../../hooks/redux";
import { CrossIcon } from "../../../../../media/icons/cross";
import { DollarLogo } from "../../../../../media/icons/dollar";
import { useSendTipMutation } from "../../../../../services/tips/tips.service";
import { CurrenciesEnum } from "../../../../automatic-transfers/enums/currencies.enum";
import { ModalWrapper } from "../../../../creator-card/modal-wrapper/modal-wrapper";
import { PaymentMethodPicker } from "../../../../shared/payment-method-picker/payment-method-picker";
import { CurrencySelect } from "../../../../shared/select/currency-select/currency-select";
import styles from "./tip-button.module.scss";

interface ITipButtonProps {
  creatorId: string;
  creatorName: string;
  contentId?: string;
  isPreview?: boolean;
}

export const TipButton: FC<ITipButtonProps> = ({
  creatorId,
  creatorName,
  contentId,
  isPreview = false,
}) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAppSelector((data) => data.userReducer);
  const [sendTip, { isLoading, isSuccess }] = useSendTipMutation();

  const formik = useFormik({
    initialValues: {
      selectedMethodId: "",
      currency: CurrenciesEnum.USD,
      amount: undefined as number | undefined,
    },
    validationSchema: Yup.object({
      selectedMethodId: Yup.string().required("Payment method is required"),
      currency: Yup.string().oneOf(Object.values(CurrenciesEnum)).required(),
      amount: Yup.number()
        .when("currency", {
          is: CurrenciesEnum.BTC,
          then: (schema) =>
            schema
              .typeError("Amount must be a number")
              .test(
                "is-decimal",
                "Invalid amount format for BTC",
                (value) =>
                  value === undefined ||
                  /^\d+(\.\d{1,5})?$/.test(value.toString())
              ),
          otherwise: (schema) =>
            schema
              .typeError("Amount must be a number")
              .test(
                "is-decimal",
                "Invalid amount format for USD/EUR",
                (value) =>
                  value === undefined ||
                  /^\d+(\.\d{1,2})?$/.test(value.toString())
              ),
        })
        .required("Amount is required"),
    }),
    onSubmit: async (values) => {
      await sendTip({
        accessToken: userAccessToken || "",
        currency: values.currency,
        amount: Number(values.amount) || 0,
        payment_method_id: values.selectedMethodId,
        receiver_id: creatorId,
        content_id: contentId,
      }).unwrap();
    },
  });

  const onCloseModal = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    const defaultMethod = user?.user_payment_methods.find(
      (item) => item.default
    );

    formik.setFieldValue(
      "selectedMethodId",
      defaultMethod ? defaultMethod.id : ""
    );
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      onCloseModal();
    }
  }, [isSuccess]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          type="button"
          fullWidth
          sx={{
            background: "#E66C26",
            gap: "3px",

            "&:hover": {
              background: "#d06223",
            },
          }}
          onClick={() => {
            if (!isPreview) {
              setIsOpen(true);
            }
          }}
        >
          <DollarLogo width={24} height={24} color="white" />
          <Typography variant="body1" color="#fff">
            Tip
          </Typography>
        </Button>
      </Box>

      <ModalWrapper shown={isOpen} close={onCloseModal} disableBackdropClose>
        <form onSubmit={formik.handleSubmit}>
          <Box className={styles.sendTipWrapper}>
            <Box className={styles.sendTip}>
              <Box className={styles.header}>
                <Typography fontSize="24px" color="#252733">
                  Send tip
                </Typography>
                <IconButton onClick={onCloseModal} disabled={isLoading}>
                  <CrossIcon width={14} height={14} color="#4C4B4C" />
                </IconButton>
              </Box>

              <Box className={styles.message}>
                <Typography variant="body1">
                  Show your love to this creator, tip{" "}
                  <strong>{creatorName}</strong> some credits!
                </Typography>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Payment method
                  </Typography>

                  <PaymentMethodPicker
                    value={formik.values.selectedMethodId}
                    onChange={(selectedMethodId) => {
                      formik.setFieldValue(
                        "selectedMethodId",
                        selectedMethodId
                      );
                    }}
                  />
                </>
              </Box>

              <Box className={styles.currencyAmount}>
                <Box className={styles.currency}>
                  <Typography>Currency</Typography>
                  <CurrencySelect
                    value={formik.values.currency}
                    onValueUpdate={(selectedCurrency) => {
                      formik.setFieldValue("currency", selectedCurrency);
                    }}
                  />
                </Box>

                <Box className={styles.amount}>
                  <Typography>Amount</Typography>
                  <TextField
                    placeholder="Enter amount"
                    type="text"
                    error={!!(formik.touched.amount && formik.errors.amount)}
                    name="amount"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("amount", e.target.value);
                    }}
                    value={formik.values.amount}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box className={styles.footer}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formik.isValid || isLoading}
                >
                  <Typography variant="button">Send</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  disabled={isLoading}
                  onClick={onCloseModal}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>
    </>
  );
};
