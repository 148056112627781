import { Box } from "@mui/material";

import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { TransferAccountsSection } from "./transfer-accounts-table/transfer-accounts-section";
import { TransferHistorySection } from "./transfer-history-table/transfer-history-section";

export const TransferAccounts = () => (
  <>
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box
          sx={{
            padding: "47px 128px",
            color: "#3C3C3C",
          }}
        >
          <TransferAccountsSection />
          <TransferHistorySection />
        </Box>
      </MainWrapper>
    </Box>
  </>
);
