import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { REGEX } from "../../../../../constants/regex/regex";
import useSubmitState from "../../../../../hooks/submit-state";
import { VisibilityOff } from "../../../../../media/icons/visibility-off";
import { useUpdateMeMutation } from "../../../../../services/sign-in/sign-in.service";
import PasswordValidationPopover from "../../../../shared/popovers/password-validation-popover/password-validation-popover";
import styles from "./change-password.module.scss";

interface IFormValues {
  password: string;
  confirm_password: string;
}

interface ICcChangePasswordProps {
  isLearnMoreShown?: boolean;
}

export const ChangePassword: FC<ICcChangePasswordProps> = ({
  isLearnMoreShown = false,
}) => {
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const [showPassword, setShowPassword] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);

  const [
    updateMe,
    {
      isSuccess: isUpdateUserSuccess,
      error: updateUserError,
      isLoading: isUpdateUserLoading,
      reset,
    },
  ] = useUpdateMeMutation();

  const { text, color, errorCode } = useSubmitState({
    requestError: updateUserError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: isUpdateUserSuccess,
  });

  const formik = useFormik<IFormValues>({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().max(255).matches(REGEX.PASSWORD.GENERAL, {
        message: "Password doesn't meet all rules",
      }),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password mismatch"
      ),
    }),
    onSubmit: async (values) => {
      await updateMe({
        accessToken: accessToken || "",
        password: values.password,
      }).unwrap();
    },
  });

  useEffect(() => {
    if (!formik.errors.password) {
      setAnchorRef(null);
      return;
    }

    setAnchorRef(inputRef.current);
  }, [formik.errors]);

  const isOpen =
    (Boolean(formik.errors.password) && Boolean(anchorRef)) || isFieldFocused;

  return (
    <>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <section>
          <TextField
            placeholder="Enter password"
            type={showPassword ? "text" : "password"}
            error={!!(formik.touched.password && formik.errors.password)}
            fullWidth
            name="password"
            onBlur={(e) => {
              formik.handleBlur(e);
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
            ref={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && (
            <Typography color="error" variant="caption">
              {formik.errors.password}
            </Typography>
          )}
        </section>
        <section>
          <TextField
            placeholder="Confirm password"
            type={showConfirmPassword ? "text" : "password"}
            error={
              !!(
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              )
            }
            fullWidth
            name="confirm_password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirm_password &&
            formik.touched.confirm_password && (
              <Typography color="error" variant="caption">
                {formik.errors.confirm_password}
              </Typography>
            )}
        </section>
        <section>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            {isLearnMoreShown && (
              <Box
                sx={{
                  alignSelf: "flex-start",
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link>Learn more about Privacy Policy</Link>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                marginTop: "22px",
                width: "237px",
              }}
              disabled={
                !formik.isValid ||
                isUpdateUserLoading ||
                !formik.values.password
              }
              type={color === "primary" ? "submit" : "button"}
              color={color}
              onClick={(e) => {
                if (color === "error") {
                  e.preventDefault();
                  reset();
                }

                if (color === "success") {
                  e.preventDefault();
                  reset();
                }
              }}
            >
              {isUpdateUserLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                <Typography variant="button">{text}</Typography>
              )}
            </Button>
            {errorCode && (
              <Typography
                variant="body1"
                color="error"
                mt={1}
                textAlign="center"
              >
                Oops... Something went wrong. Error {errorCode}
              </Typography>
            )}
          </Box>
        </section>
      </form>
      <PasswordValidationPopover
        anchorEl={anchorRef}
        open={isOpen}
        password={formik.values.password}
      />
    </>
  );
};
