export const MediaImageIcon = ({
  width = 32,
  height = 32,
  color = "#fff",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8246 1.16602H3.17797C2.06997 1.16602 1.16797 2.06735 1.16797 3.17602V12.8233C1.16797 13.9313 2.0693 14.8333 3.17797 14.8333H12.8253C13.9333 14.8333 14.8353 13.932 14.8353 12.8233V3.17602C14.8346 2.06735 13.9333 1.16602 12.8246 1.16602ZM14.0306 12.8227C14.0306 13.4873 13.49 14.0287 12.8246 14.0287H3.17797C2.5133 14.0287 1.97197 13.488 1.97197 12.8227V11.3687L5.21597 7.76402L9.59797 11.7473L12.0213 9.72801L14.0313 11.4027L14.0306 12.8227ZM14.0306 10.3567L12.0206 8.68202L9.61997 10.682L5.1593 6.62602L1.97197 10.1673V3.17602C1.97197 2.51135 2.51264 1.97002 3.17797 1.97002H12.8253C13.49 1.97002 14.0313 2.51068 14.0313 3.17602L14.0306 10.3567Z"
      fill={color}
    />
    <path
      d="M10.8147 7.19578C11.9227 7.19578 12.8247 6.29445 12.8247 5.18578C12.8247 4.07711 11.9234 3.17578 10.8147 3.17578C9.70669 3.17578 8.80469 4.07711 8.80469 5.18578C8.80469 6.29445 9.70602 7.19578 10.8147 7.19578ZM10.8147 3.98045C11.4794 3.98045 12.0207 4.52111 12.0207 5.18645C12.0207 5.85178 11.48 6.39245 10.8147 6.39245C10.1494 6.39245 9.60869 5.85178 9.60869 5.18645C9.60869 4.52111 10.1494 3.98045 10.8147 3.98045Z"
      fill={color}
    />
  </svg>
);
