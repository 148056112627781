// eslint-disable-next-line no-shadow
export enum TransactionHistoryType {
  all = "all",
  subscription = "subscription",
  tip = "tip",
  premium_content = "oneTime",
  birthday = "birthday",
  merch = "merch",
  transfer_from_sf = "transfer_from_sf",
  purchases = "purchases",
}
