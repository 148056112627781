import type React from "react";

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const FavouriteIcon: React.FC<Props> = ({ color, width, height }) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: color || "#252733" }} // Apply color if provided, otherwise use default color
  >
    <path d="M14.5835 0.876953C13.6451 0.891549 12.7272 1.1535 11.9224 1.63635C11.1177 2.11919 10.4546 2.80584 10.0001 3.62695C9.54566 2.80584 8.88257 2.11919 8.07783 1.63635C7.27308 1.1535 6.35517 0.891549 5.41679 0.876953C3.92091 0.941945 2.51155 1.59633 1.49661 2.69715C0.481678 3.79796 -0.0563308 5.25572 0.000128002 6.75195C0.000128002 12.3978 9.13013 18.9186 9.51846 19.1953L10.0001 19.5361L10.4818 19.1953C10.8701 18.9203 20.0001 12.3978 20.0001 6.75195C20.0566 5.25572 19.5186 3.79796 18.5036 2.69715C17.4887 1.59633 16.0793 0.941945 14.5835 0.876953ZM10.0001 17.4845C7.28929 15.4595 1.66679 10.4845 1.66679 6.75195C1.60983 5.69755 1.9721 4.66339 2.6746 3.87504C3.37709 3.08668 4.36282 2.60808 5.41679 2.54362C6.47077 2.60808 7.45649 3.08668 8.15899 3.87504C8.86149 4.66339 9.22376 5.69755 9.16679 6.75195H10.8335C10.7765 5.69755 11.1388 4.66339 11.8413 3.87504C12.5438 3.08668 13.5295 2.60808 14.5835 2.54362C15.6374 2.60808 16.6232 3.08668 17.3257 3.87504C18.0282 4.66339 18.3904 5.69755 18.3335 6.75195C18.3335 10.4861 12.711 15.4595 10.0001 17.4845Z" />
  </svg>
);

export default FavouriteIcon;
