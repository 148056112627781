import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useDateGroup from "../../../hooks/date-group";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppSelector } from "../../../hooks/redux";
import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../media/icons/btc";
import { DollarLogo } from "../../../media/icons/dollar";
import { EuroLogo } from "../../../media/icons/euro";
import { useGetTransferHistoryMutation } from "../../../services/transfer-accounts/transfer-accounts.service";
import { transferHistoryReducer } from "../../../store/reducers/transfer-accounts/transfer-history";
import { CurrenciesEnum } from "../../automatic-transfers/enums/currencies.enum";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import DateFilters from "../../shared/date-filters/date-filters";
import { TransferHistoryTableHead } from "../transfer-history-table-head";
import styles from "./transfer-history-section.module.scss";

export const TransferHistorySection = () => {
  const rowsPerPage = 5;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const { transferHistory } = useAppSelector(
    (reducers) => reducers.transferHistoryReducer
  );
  const { setTransferHistory } = transferHistoryReducer.actions;

  const [getTransferHistory, { error, isLoading, data: transferHistoryData }] =
    useGetTransferHistoryMutation();

  const { date, setDate, calendarDate, setCalendarDate, handleChangeDate } =
    useDateGroup("week");

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    transferHistory,
    transferHistoryData?.total || 0,
    TransferHistoryTableHead
  );

  const handleGetTransferHistory = useCallback(async () => {
    try {
      await getTransferHistory({
        accessToken,
        rowsPerPage,
        page: page + 1,
        sortBy,
        direction,
        startDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.startDate).subtract(1, "day").toISOString()
            : date.startDate,
        endDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.endDate).add(1, "day").toISOString()
            : date.endDate,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [
    accessToken,
    getTransferHistory,
    rowsPerPage,
    direction,
    sortBy,
    page,
    date,
  ]);

  useEffect(() => {
    if (page === 0) {
      dispatch(setTransferHistory(transferHistoryData?.data || []));
      return;
    }

    dispatch(
      setTransferHistory([
        ...transferHistory,
        ...(transferHistoryData?.data || []),
      ])
    );
  }, [transferHistoryData]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    handleGetTransferHistory();
  }, [handleGetTransferHistory, page, date]);

  useEffect(() => {
    dispatch(setTransferHistory([]));
    setPage(0);
  }, [sortBy, direction, date]);

  const renderLogo = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return <BtcLogo />;
      case CurrenciesEnum.USD:
        return <DollarLogo />;
      case CurrenciesEnum.EURO:
        return <EuroLogo />;
      default:
        return <BtcLogo />;
    }
  };

  const renderCurrency = (currency: CurrenciesEnum) => {
    switch (currency) {
      case CurrenciesEnum.BTC:
        return CurrenciesEnum.BTC;
      case CurrenciesEnum.EURO:
        return CurrenciesEnum.EURO.slice(0, -1);
      case CurrenciesEnum.USD:
        return CurrenciesEnum.USD;
      default:
        return CurrenciesEnum.BTC;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontWeight={400}>
          Transfer history
        </Typography>
        <Box
          sx={{
            alignSelf: "right",
          }}
        >
          <DateFilters
            date={date}
            calendarDate={calendarDate}
            handleChangeDate={handleChangeDate}
            setCalendarDate={setCalendarDate}
            setDate={setDate}
            disableFuture
          />
        </Box>
      </Box>

      <Table sx={{ tableLayout: "fixed" }}>
        <CustomTableHead
          tableHeads={TransferHistoryTableHead}
          setSortBy={setSortBy}
          setDirection={setDirection}
          direction={direction}
          sortBy={sortBy}
        />
        <TableBody data-cy="historyTable">
          {transferHistory.length ? (
            transferHistory.map((history, index) => (
              <TableRow
                key={history.id}
                ref={
                  transferHistory.length === index + 1
                    ? lastMemberElementRef
                    : null
                }
                sx={{
                  color: "#252733",
                }}
              >
                <TableCell className={styles.tableCell}>
                  <Typography className={styles.tableTextCell}>
                    {dayjs(history.date).format("DD/MM/YYYY")}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Typography className={styles.tableTextCell}>
                    {history.crypto_title ? (
                      <BtcLogo width={24} height={24} />
                    ) : (
                      <BankAcc />
                    )}
                    <Tooltip
                      arrow
                      placement="top"
                      title={history.crypto_title || history.bank_account_title}
                    >
                      <Box className={styles.tableTextCellWithoutOverflow}>
                        {history.crypto_title || history.bank_account_title}
                      </Box>
                    </Tooltip>
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Typography className={styles.tableTextCell}>
                    {renderLogo(history.currency)}
                    {renderCurrency(history.currency)?.toUpperCase()}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Typography className={styles.tableTextCell}>
                    {parseFloat(history.amount).toFixed(
                      history.currency === CurrenciesEnum.BTC ? 5 : 2
                    )}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell}>
                  <Typography className={styles.tableTextCell}>
                    {history.status}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow />
          )}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress />}
    </>
  );
};
