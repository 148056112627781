import type { FC } from "react";

type CloseIconProp = {
  width?: number;
  height?: number;
  color?: string;
};

export const CloseIcon: FC<CloseIconProp> = ({
  width = 18,
  height = 18,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2207_67789)">
      <path
        d="M11.9996 6.00084C11.859 5.86024 11.6682 5.78125 11.4693 5.78125C11.2705 5.78125 11.0797 5.86024 10.9391 6.00084L8.9996 7.94034L7.0601 6.00084C6.91864 5.86422 6.72919 5.78863 6.53255 5.79033C6.3359 5.79204 6.14779 5.87092 6.00873 6.00998C5.86968 6.14903 5.7908 6.33714 5.78909 6.53379C5.78738 6.73044 5.86298 6.91989 5.9996 7.06134L7.9391 9.00084L5.9996 10.9403C5.86298 11.0818 5.78738 11.2712 5.78909 11.4679C5.7908 11.6645 5.86968 11.8526 6.00873 11.9917C6.14779 12.1308 6.3359 12.2096 6.53255 12.2113C6.72919 12.2131 6.91864 12.1375 7.0601 12.0008L8.9996 10.0613L10.9391 12.0008C11.0805 12.1375 11.27 12.2131 11.4666 12.2113C11.6633 12.2096 11.8514 12.1308 11.9905 11.9917C12.1295 11.8526 12.2084 11.6645 12.2101 11.4679C12.2118 11.2712 12.1362 11.0818 11.9996 10.9403L10.0601 9.00084L11.9996 7.06134C12.1402 6.92069 12.2192 6.72996 12.2192 6.53109C12.2192 6.33222 12.1402 6.14149 11.9996 6.00084Z"
        fill={color}
      />
      <path
        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2207_67789">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
