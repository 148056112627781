import { InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import type { PropsWithChildren } from "react";
import { useRef } from "react";

import { SearchInCircleIcon } from "../../../media/icons/search-in-circle";
import type { ISearchText } from "../interfaces/search-text.interface";
import styles from "./header-with-search.module.scss";

type HeaderWithSearchProps = {
  setSearchText: (text: string) => void;
  fullWidth?: boolean;
} & PropsWithChildren;

export const HeaderWithSearch = ({
  setSearchText,
  fullWidth,
  children,
}: HeaderWithSearchProps) => {
  const typingTimeout = useRef<any>(null);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    onSubmit: async (values: ISearchText) => {
      setSearchText(values.searchText);
    },
  });

  const handleChange = (e: Object) => {
    clearTimeout(typingTimeout.current);

    formik.handleChange(e);

    typingTimeout.current = setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  return (
    <header className={styles.header}>
      <form
        className={fullWidth ? styles.fullWidthForm : styles.standardWidthForm}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          sx={{
            "& fieldset": { borderColor: "transparent" },
            padding: "8px 0",
          }}
          placeholder="Search SideFans"
          type="text"
          fullWidth
          name="searchText"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          value={formik.values.searchText}
          variant="outlined"
          InputProps={{
            sx: {
              borderRadius: "12px",
              borderColor: "transparent",
              padding: "12px 14px",
            },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#6995EC",
                }}
              >
                <SearchInCircleIcon height={25} width={25} />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {children}
    </header>
  );
};
