import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../../../hooks/redux";
import { BitcoinTextIcon } from "../../../../../media/icons/bitcoin-text";
import ContentLikeIcon from "../../../../../media/icons/catalog/content-card/content-like-icon";
import PurchasesIcon from "../../../../../media/icons/catalog/content-card/purchases-icon";
import SettingsIcon from "../../../../../media/icons/catalog/content-card/settings-icon";
import ViewesIcon from "../../../../../media/icons/catalog/content-card/viewes-icon";
import ButtonNextIcon from "../../../../../media/icons/creator-card/bottom-next-icon";
import TopPreviousIcon from "../../../../../media/icons/creator-card/top-previous-icon";
import FavouriteIcon from "../../../../../media/icons/favourite";
import FavouritedIcon from "../../../../../media/icons/favourited";
import { LockIcon } from "../../../../../media/icons/lock";
import { MediaImageIcon } from "../../../../../media/icons/media-image";
import { MediaVideoIcon } from "../../../../../media/icons/media-video";
import {
  useBuyContentMutation,
  useRemoveLovedMutation,
  useSetLovedMutation,
} from "../../../../../services/catalog/catalog.service";
import { ContentCs } from "../../../../../services/catalog/interfaces/catalog.interfaces";
import { CurrenciesEnum } from "../../../../automatic-transfers/enums/currencies.enum";
import { ModalWrapper } from "../../../../creator-card/modal-wrapper/modal-wrapper";
import { PaymentMethodPicker } from "../../../../shared/payment-method-picker/payment-method-picker";
import { useCreatorCatalogCSViewContext } from "../../context/creator-catalog-cs-view-context";
import { TipButton } from "../../sidebar-cs-view/tip-button/tip-button";
import { ContentCarousel } from "../content-carousel/content-carousel";
import styles from "./content-cs-card-fullscreen.module.scss";

const CustomQuill = styled(ReactQuill)`
  background-color: transparent;
  border-color: transparent;

  .ql-toolbar {
    display: none;
  }

  div > .ql-editor {
    min-height: auto !important;
    padding: 0;
    color: #3c3c3c;
    font-family: Poppins, serif;
  }
`;

interface IContentCsCardFullScreenProps {
  currentCard: ContentCs;
  handleSetPreviousCard: () => void;
  handleSetNextCard: () => void;
  handleCloseCreatorCard: () => void;
  isOpenedFromMainWrapper?: boolean;
}

export const ContentCsCardFullscreen: FC<IContentCsCardFullScreenProps> = ({
  currentCard,
  handleSetNextCard,
  handleSetPreviousCard,
  handleCloseCreatorCard,
  isOpenedFromMainWrapper,
}) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const navigate = useNavigate();
  const { user } = useAppSelector((data) => data.userReducer);
  const [isPurchaseContentOpen, setIsPurchaseContentOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // Start popover handling
  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCopyPostLink = (contentId: string) => {
    setAnchorEl(null);
    const { protocol, host } = window.location;
    const domain = `${protocol}//${host}`;

    navigator.clipboard.writeText(`${domain}/home?contentId=${contentId}`);
  };

  const {
    setCurrentContentCard,
    setCreatorContentItems,
    fetchContent,
    creatorCard,
  } = useCreatorCatalogCSViewContext();
  const [setLovedContent] = useSetLovedMutation();
  const [removedLovedContent] = useRemoveLovedMutation();
  const [
    buyContent,
    { isLoading: isBuyContentLoading, isSuccess: isBuyContentSuccess },
  ] = useBuyContentMutation();

  const formikBuyContent = useFormik({
    initialValues: {
      selectedMethodId: "",
    },
    validationSchema: Yup.object({
      selectedMethodId: Yup.string().required("Payment method is required"),
    }),
    onSubmit: async (values) => {
      await buyContent({
        accessToken: userAccessToken || "",
        currency: currentCard.currency,
        payment_method_id: values.selectedMethodId,
        content_id: currentCard.id,
      }).unwrap();
    },
  });

  const onClickLovedHandler = useCallback(async () => {
    const newStateIsLoved = !currentCard.isLoved;

    setCurrentContentCard((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        isLoved: newStateIsLoved,
        _count: {
          ...prevState._count,
          user_likes: newStateIsLoved
            ? prevState._count.user_likes + 1
            : prevState._count.user_likes - 1,
        },
      };
    });

    setCreatorContentItems((prevState) => {
      if (!prevState) return prevState;

      const itemInListIndex = prevState.findIndex(
        (item) => item.id === currentCard.id
      );

      if (itemInListIndex !== -1) {
        const updatedItems = [
          ...prevState.slice(0, itemInListIndex),
          {
            ...prevState[itemInListIndex],
            isLoved: newStateIsLoved,
            _count: {
              ...prevState[itemInListIndex]._count,
              user_likes: newStateIsLoved
                ? prevState[itemInListIndex]._count.user_likes + 1
                : prevState[itemInListIndex]._count.user_likes - 1,
            },
          } as ContentCs,
          ...prevState.slice(itemInListIndex + 1),
        ];

        return updatedItems;
      }

      return prevState;
    });

    if (currentCard.isLoved) {
      await removedLovedContent({
        accessToken: userAccessToken || "",
        content_id: currentCard?.id || "",
      }).unwrap();
    } else {
      await setLovedContent({
        accessToken: userAccessToken || "",
        content_id: currentCard?.id || "",
      }).unwrap();
    }
  }, [
    setCurrentContentCard,
    currentCard,
    userAccessToken,
    removedLovedContent,
    setLovedContent,
  ]);

  useEffect(() => {
    const defaultMethod = user?.user_payment_methods.find(
      (item) => item.default
    );

    formikBuyContent.setFieldValue(
      "selectedMethodId",
      defaultMethod ? defaultMethod.id : ""
    );
  }, [user, isPurchaseContentOpen]);

  const renderPrice = (
    price: number,
    currency: CurrenciesEnum,
    signColor: string
  ) => {
    switch (currency) {
      case CurrenciesEnum.USD: {
        return `$${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.EURO: {
        return `€${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.BTC: {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1px",
              }}
            >
              <BitcoinTextIcon color={signColor} width={18} height={18} />
              <span style={{ display: "block" }}>
                {price.toFixed(5).toString()}
              </span>
            </Box>
          </>
        );
      }
      default: {
        return "";
      }
    }
  };

  const onPurchaseContentClose = () => {
    formikBuyContent.resetForm();
    setIsPurchaseContentOpen(false);
  };

  useEffect(() => {
    if (isBuyContentSuccess) {
      onPurchaseContentClose();
      if (setCurrentContentCard) {
        setCurrentContentCard(null);
      } else {
        window.location.reload();
      }
      handleCloseCreatorCard();
      fetchContent();
    }
  }, [isBuyContentSuccess]);

  return (
    <div className={styles.cardWrapper}>
      <Box className={styles.card}>
        <Box className={styles.header}>
          <Typography fontSize="20px">{currentCard.name}</Typography>

          {!isOpenedFromMainWrapper && (
            <Box className={styles.headerInteraction}>
              <Button
                color="primary"
                sx={{
                  background: "#E861A0",

                  "&:hover": {
                    background: "#F01D7F",
                  },
                }}
                variant="contained"
                type="button"
                fullWidth
                onClick={onClickLovedHandler}
              >
                {currentCard.isLoved ? (
                  <>
                    <FavouritedIcon width="24" height="24" />
                    <Typography variant="body1" color="#fff">
                      Loved
                    </Typography>
                  </>
                ) : (
                  <>
                    <FavouriteIcon color="#FFFFFF" width="24" height="24" />
                    <Typography variant="body1" color="#fff">
                      Love
                    </Typography>
                  </>
                )}
              </Button>

              <IconButton aria-describedby={id} onClick={handleOpenPopover}>
                <SettingsIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={styles.file}>
          {currentCard?.content_media && (
            <>
              {currentCard.closed && (
                <Box className={styles.closedContent}>
                  <Box>
                    <LockIcon width={48} height={60} />
                  </Box>

                  {currentCard.is_collection && (
                    <Box className={styles.numberOfMedia}>
                      <Box className={styles.numberOfMediaItem}>
                        <MediaImageIcon width={16} height={16} />
                        <Typography variant="body1" color="#fff">
                          {currentCard._count.photos}
                        </Typography>
                      </Box>
                      <Box className={styles.numberOfMediaItem}>
                        <MediaVideoIcon width={16} height={16} />
                        <Typography variant="body1" color="#fff">
                          {currentCard._count.videos}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "auto",
                      marginTop: "10px",
                      boxShadow: "2px 4px 24px 0px #FFFFFF7A",
                    }}
                    onClick={() => {
                      setIsPurchaseContentOpen(true);
                    }}
                  >
                    <Typography variant="button">
                      Unlock for{" "}
                      {renderPrice(
                        currentCard.price,
                        currentCard.currency,
                        "#ffffff"
                      )}
                    </Typography>
                  </Button>
                </Box>
              )}
              <ContentCarousel
                isMediaActive={!currentCard.closed}
                media={currentCard.content_media}
                toggleGreetings={currentCard.content_media.length > 1}
              />
            </>
          )}
        </Box>
        <Box className={styles.modalFooter}>
          <Typography color="#AAAAAA" variant="body1">
            {dayjs(currentCard.scheduled_at).format("MMM D, YYYY")}
          </Typography>

          <Box className={styles.tagsWrapper}>
            {currentCard.content_tags.map((tag) => (
              <Typography
                color="#0F70CA"
                variant="body1"
                sx={{
                  cursor: "pointer",
                  userSelect: "none",

                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  navigate(`/home?searchTag=${tag}`);
                }}
              >
                #{tag}
                {"  "}
              </Typography>
            ))}
          </Box>

          {currentCard.description && (
            <Box
              sx={{
                width: "100%",
                maxHeight: "250px",
              }}
            >
              <CustomQuill
                theme="snow"
                value={currentCard?.description}
                modules={{ toolbar: true }}
                className={styles.textAreaDescription}
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                readOnly
              />
            </Box>
          )}

          <Box className={styles.footer}>
            <Box className={styles.statistics}>
              <Box className={styles.categoryStatistic}>
                <ContentLikeIcon />
                <Typography color="#AAAAAA" variant="body1">
                  {currentCard._count.user_likes}
                </Typography>
              </Box>
              <Box className={styles.categoryStatistic}>
                <ViewesIcon />
                <Typography color="#AAAAAA" variant="body1">
                  {currentCard._count.viewsPerContents}
                </Typography>
              </Box>
              <Box className={styles.categoryStatistic}>
                <PurchasesIcon />
                <Typography color="#AAAAAA" variant="body1">
                  {currentCard._count.contentPurchaseAmount}
                </Typography>
              </Box>
            </Box>

            {!isOpenedFromMainWrapper && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {currentCard.closed && (
                  <>
                    <Typography fontWeight="bold" fontSize={24}>
                      {renderPrice(
                        currentCard.price,
                        currentCard.currency,
                        "black"
                      )}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsPurchaseContentOpen(true);
                      }}
                      sx={{
                        width: "172px",
                      }}
                    >
                      <Typography variant="button">Buy</Typography>
                    </Button>
                  </>
                )}
                {creatorCard && (
                  <Box
                    sx={{
                      width: "172px",
                    }}
                  >
                    <TipButton
                      creatorName={creatorCard.username}
                      creatorId={creatorCard.id}
                      contentId={currentCard.id}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {!isOpenedFromMainWrapper && (
        <Box className={styles.cardNavigationButtons}>
          <IconButton
            type="button"
            className={styles.navigationButtonStyle}
            onClick={handleSetPreviousCard}
          >
            <TopPreviousIcon />
          </IconButton>
          <IconButton
            type="button"
            className={styles.navigationButtonStyle}
            onClick={handleSetNextCard}
          >
            <ButtonNextIcon />
          </IconButton>
        </Box>
      )}

      {/* Purchase content */}
      <ModalWrapper
        shown={isPurchaseContentOpen}
        close={onPurchaseContentClose}
        disableBackdropClose
      >
        <form onSubmit={formikBuyContent.handleSubmit}>
          <Box className={styles.buyContentModalWrapper}>
            <Box className={styles.buyContentModal}>
              <Box className={styles.buyContentHeaderModal}>
                <Typography fontSize="24px" color="#252733">
                  Purchase content
                </Typography>
              </Box>

              <Box className={styles.buyContentMessage}>
                <Typography variant="body1">
                  Content <b>{currentCard?.name}</b>
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {renderPrice(
                    currentCard?.price || 0,
                    currentCard?.currency || CurrenciesEnum.USD,
                    "black"
                  )}
                </Typography>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Payment method
                  </Typography>

                  <PaymentMethodPicker
                    value={formikBuyContent.values.selectedMethodId}
                    onChange={(selectedMethodId) => {
                      formikBuyContent.setFieldValue(
                        "selectedMethodId",
                        selectedMethodId
                      );
                    }}
                  />
                </>
              </Box>

              <Box className={styles.buyContentFooter}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formikBuyContent.isValid || isBuyContentLoading}
                >
                  <Typography variant="button">Buy</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  disabled={isBuyContentLoading}
                  onClick={onPurchaseContentClose}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "237px",
          }}
        >
          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={() => handleCopyPostLink(currentCard.id)}
          >
            <Typography variant="button" color="#252733">
              Copy post link
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            <Typography variant="button" color="#FF5252">
              Report
            </Typography>
          </Button>
        </Box>
      </Popover>
    </div>
  );
};
