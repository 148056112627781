import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { Wallets } from "../shared/transfers/wallets/wallets";
import { TransactionHistory } from "./transaction-history/transaction-history";

export const Earnings = () => {
  const navigate = useNavigate();

  const walletsFooter = (
    <Button
      color="primary"
      sx={{ width: "219px" }}
      variant="contained"
      type="button"
      onClick={() => navigate("/cc/transfers")}
    >
      <Typography variant="button">Transfer</Typography>
    </Button>
  );

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box
          sx={{
            padding: "47px 126px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "11px",
          }}
        >
          <Box sx={{ width: "49%", marginBottom: "2rem" }}>
            <Wallets title="Earnings" footer={walletsFooter} />
          </Box>
          <TransactionHistory />
        </Box>
      </MainWrapper>
    </Box>
  );
};
