import type { FC } from "react";

import { ProfileIcon } from "../../../media/icons/profile";
import styles from "./default-avatar.module.scss";

type DefaultAvatarProps = {
  width: number;
  height: number;
};

export const DefaultAvatar: FC<DefaultAvatarProps> = ({ width, height }) => (
  <div className={styles.avatar} style={{ width, height }}>
    <ProfileIcon color="#3A75E9" width={width} height={height} />
  </div>
);
