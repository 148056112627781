import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, IconButton, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import type { Dispatch, FC, SetStateAction } from "react";

import { SortingDirection } from "../../../enums/sorting-direction.enum";
import type { ITableHead } from "../../../interfaces/table-head.interface";
import VectorDownIcon from "../../../media/icons/vector-down";
import VectorUpIcon from "../../../media/icons/vector-up";
import styles from "../../manage-subscribers/manage-subscribers.module.scss";

type CustomTableHeadProps = {
  tableHeads: ITableHead[];
  setSortBy: Dispatch<SetStateAction<string>>;
  setDirection: Dispatch<SetStateAction<SortingDirection>>;
  direction: SortingDirection;
  sortBy: string;
  checkBoxCell?: ReactJSXElement;
};

export const CustomTableHead: FC<CustomTableHeadProps> = ({
  setSortBy,
  tableHeads,
  setDirection,
  direction,
  sortBy,
  checkBoxCell,
}) => (
  <TableHead>
    <TableRow
      sx={{
        borderBottom: "1px solid #fff",
      }}
    >
      {checkBoxCell}
      {tableHeads.map((head, idx) => (
        <TableCell
          key={head.value}
          className={styles.tableHead}
          onClick={() => {
            if (!head.sortable) {
              return;
            }

            setSortBy(head.value);
            setDirection(
              direction === SortingDirection.asc
                ? SortingDirection.desc
                : SortingDirection.asc
            );
          }}
          sx={{
            paddingLeft: 0,
            paddingRight: idx === tableHeads.length - 1 ? 0 : "16px",
            paddingBottom: "5px",
            width: `${head.headWidth ? head.headWidth : 100}%`,
          }}
        >
          <Typography
            fontWeight="normal"
            sx={{
              color: "#575757",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 0.5 }}>{head.display_text}</Box>
            {head.sortable ? (
              <Box sx={{ flexGrow: 0.5 }}>
                <IconButton
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <VectorUpIcon
                    fill={
                      direction === SortingDirection.asc &&
                      sortBy === head.value
                        ? "#0F70CA"
                        : "#C2C2C2"
                    }
                  />
                  <VectorDownIcon
                    fill={
                      direction === SortingDirection.desc &&
                      sortBy === head.value
                        ? "#0F70CA"
                        : "#C2C2C2"
                    }
                  />
                </IconButton>
              </Box>
            ) : null}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
