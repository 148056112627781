import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import { ISendTipParams, ISendTipResponse } from "./interfaces/tips.interface";

export const tipsApi = createApi({
  reducerPath: "tipsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    sendTip: builder.mutation<ISendTipResponse, ISendTipParams>({
      query: (data: ISendTipParams) => ({
        url: ENDPOINTS.SEND_TIP,
        method: "POST",
        body: {
          receiver_id: data.receiver_id,
          amount: data.amount,
          currency: data.currency,
          payment_method_id: data.payment_method_id,
          content_id: data.content_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const { useSendTipMutation } = tipsApi;
