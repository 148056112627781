export default function DiscoverLargeIcon() {
  return (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="discover-color-large">
        <rect id="card_bg" width="48" height="32" rx="4" fill="white" />
        <path
          id="card_bg-2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0H44C46.2091 0 48 1.79086 48 4V28C48 30.2091 46.2091 32 44 32H4C1.79086 32 0 30.2091 0 28V4C0 1.79086 1.79086 0 4 0ZM46 4C46 2.89543 45.1046 2 44 2H4C2.89543 2 2 2.89543 2 4V28C2 29.1046 2.89543 30 4 30H44C45.1046 30 46 29.1046 46 28V4Z"
          fill="#231F20"
        />
        <g id="discover">
          <path
            id="Path"
            d="M46 17.72V28C46 29.1046 45.1046 30 44 30H11.92C34 26.22 46 17.72 46 17.72Z"
            fill="#EE7623"
          />
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10.64H5.83C6.72346 10.5635 7.60751 10.869 8.26313 11.4808C8.91875 12.0925 9.28458 12.9534 9.27 13.85C9.26809 14.7994 8.84726 15.6996 8.12 16.31C7.47109 16.8251 6.65765 17.088 5.83 17.05H4V10.64ZM5.58 16C6.20073 16.0537 6.81698 15.8555 7.29 15.45C7.74494 15.0346 8.00287 14.446 8 13.83C8.00098 13.2201 7.74289 12.6385 7.29 12.23C6.81074 11.8382 6.19624 11.6513 5.58 11.71H5.25V16H5.58Z"
            fill="#231F20"
          />
          <rect
            id="Rectangle"
            x="9.85001"
            y="10.63"
            width="1.25"
            height="6.42"
            fill="#231F20"
          />
          <path
            id="Path_2"
            d="M14.17 13.09C13.42 12.82 13.17 12.63 13.17 12.29C13.17 11.95 13.56 11.57 14.1 11.57C14.4939 11.5866 14.8602 11.777 15.1 12.09L15.75 11.24C15.2369 10.771 14.5651 10.5138 13.87 10.52C13.3578 10.4872 12.8545 10.6651 12.4768 11.0126C12.0991 11.3601 11.8799 11.8469 11.87 12.36C11.87 13.25 12.27 13.7 13.44 14.12C13.7408 14.2164 14.032 14.3402 14.31 14.49C14.5457 14.6261 14.6907 14.8779 14.69 15.15C14.6882 15.4068 14.5791 15.6511 14.3892 15.824C14.1993 15.9968 13.9458 16.0824 13.69 16.06C13.1068 16.0664 12.5743 15.7296 12.33 15.2L11.52 15.98C11.9927 16.7804 12.8719 17.2508 13.8 17.2C14.3772 17.2395 14.9444 17.0344 15.3629 16.6349C15.7814 16.2355 16.0126 15.6784 16 15.1C16 14.08 15.59 13.61 14.17 13.09Z"
            fill="#231F20"
          />
          <path
            id="Path_3"
            d="M16.41 13.84C16.4017 14.738 16.7575 15.6011 17.3962 16.2323C18.035 16.8635 18.9022 17.209 19.8 17.19C20.3453 17.1933 20.8835 17.0664 21.37 16.82V15.35C20.9923 15.7917 20.4411 16.0472 19.86 16.05C19.2722 16.0648 18.7049 15.8336 18.2948 15.4121C17.8847 14.9907 17.6692 14.4172 17.7 13.83C17.6811 13.2583 17.8931 12.7029 18.2881 12.2891C18.6831 11.8753 19.228 11.6378 19.8 11.63C20.4027 11.6324 20.9749 11.8949 21.37 12.35V10.88C20.8987 10.6188 20.3689 10.4812 19.83 10.48C18.9301 10.4694 18.0631 10.818 17.4212 11.4487C16.7792 12.0794 16.4153 12.9401 16.41 13.84Z"
            fill="#231F20"
          />
          <path
            id="Path_4"
            d="M31.3 14.94L29.58 10.63H28.22L30.94 17.21H31.61L34.39 10.63H33.03L31.3 14.94Z"
            fill="#231F20"
          />
          <path
            id="Path_5"
            d="M34.95 17.05H38.51V15.96H36.2V14.23H38.42V13.14H36.2V11.72H38.51V10.63H34.95V17.05Z"
            fill="#231F20"
          />
          <path
            id="Shape_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.46 12.52C43.5276 13.4182 42.8908 14.2164 42 14.35L44 17.05H42.48L40.74 14.47H40.59V17.05H39.34V10.64H41.19C42.63 10.64 43.46 11.33 43.46 12.52ZM40.63 13.58H41C41.75 13.58 42.19 13.32 42.19 12.64C42.19 11.96 41.77 11.64 41 11.64H40.63V13.58Z"
            fill="#231F20"
          />
          <path
            id="Path_6"
            d="M28.59 13.84C28.59 15.7233 27.0633 17.25 25.18 17.25C23.2967 17.25 21.77 15.7233 21.77 13.84C21.77 11.9567 23.2967 10.43 25.18 10.43C27.061 10.4355 28.5845 11.959 28.59 13.84Z"
            fill="#EE7623"
          />
          <path
            id="Shape_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.11 10.75C44.1175 10.8231 44.0709 10.8908 44 10.91L44.17 11.16H44.02L43.84 10.92V11.16H43.71V10.58H43.9C44.04 10.58 44.11 10.64 44.11 10.75ZM43.88 10.69V10.83H43.9C43.9842 10.83 43.9833 10.8159 43.9809 10.7756C43.9805 10.768 43.98 10.7595 43.98 10.75C43.98 10.69 43.94 10.69 43.88 10.69Z"
            fill="#231F20"
          />
          <path
            id="Shape_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.5964 10.5206C43.6864 10.4268 43.81 10.3726 43.94 10.37C44.0768 10.3616 44.2109 10.4109 44.3097 10.5059C44.4086 10.6009 44.4631 10.733 44.46 10.87C44.46 11.1461 44.2361 11.37 43.96 11.37C43.6839 11.37 43.46 11.1461 43.46 10.87C43.4573 10.74 43.5064 10.6143 43.5964 10.5206ZM43.94 11.27C44.0353 11.2712 44.1265 11.2311 44.19 11.16C44.2736 11.087 44.3211 10.981 44.32 10.87C44.3254 10.7649 44.2881 10.662 44.2166 10.5848C44.145 10.5076 44.0453 10.4626 43.94 10.46C43.8339 10.4626 43.7332 10.5073 43.6601 10.5842C43.5869 10.6611 43.5473 10.7639 43.55 10.87C43.5473 10.9752 43.5872 11.0769 43.6606 11.1523C43.7341 11.2276 43.8348 11.27 43.94 11.27Z"
            fill="#231F20"
          />
        </g>
      </g>
    </svg>
  );
}
