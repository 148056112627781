export default function RectangleIcon() {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.65686 14.584C1.09324 14.584 -0.691432 10.2754 1.82843 7.75556L7.75497 1.82902C9.31707 0.266919 11.8497 0.266921 13.4118 1.82902L19.3384 7.75556C21.8582 10.2754 20.0735 14.584 16.5099 14.584H11.2327H4.65686Z"
        fill="white"
      />
    </svg>
  );
}
