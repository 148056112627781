import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IGroup } from "../../../services/manage-subscribers/interfaces/fetch-groups-response.interface";

interface ISubscriberGroups {
  subscriberGroups: IGroup[];
}

const initialState: ISubscriberGroups = {
  subscriberGroups: [],
};

export const subscriberGroupsSlice = createSlice({
  name: "subscriberGroups",
  initialState,
  reducers: {
    setSubscriberGroups(state, action: PayloadAction<IGroup[]>) {
      state.subscriberGroups = action.payload;
    },
  },
});

export default subscriberGroupsSlice.reducer;
