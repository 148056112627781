import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import FilterIcon from "../../../media/filter";
import FilterActiveIcon from "../../../media/filter-active";
import { ListIcon } from "../../../media/icons/list";
import { TileIcon } from "../../../media/icons/tile";
import {
  AudienceEnum,
  IFilterCatalog,
} from "../../../services/catalog/interfaces/request-models.interfaces";
import { LastSearchType } from "../../../services/home/interfaces/home.interface";
import { ActiveView } from "../../home/interfaces/temporary.interface";
import { SearchBar } from "../../shared/search-bar/search-bar";
import { useCreatorCatalogCCViewContext } from "../creator-catalog/creator-catalog-cc-view/context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../creator-catalog/creator-catalog-cc-view/context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { ActiveTab } from "../creator-catalog/interfaces/ActiveTab";
import styles from "./catalog-search-bar-cc.module.scss";

interface InputObject {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface ICatalogSearchBarCCProp {}

export const CatalogSearchBarCC: FC<ICatalogSearchBarCCProp> = () => {
  const { updateCatalogParam, setTab, tab, setView, view } =
    useCreatorCatalogCCViewContext();

  const { getFoldersById } = useFolderCreatorCatalogCCViewContext();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const transformFilters = (input: InputObject): IFilterCatalog => {
    const output: any = {};
    Object.entries(input).forEach(([category, values]) => {
      output[category] = [];

      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          output[category].push(key);
        }
      });
    });

    return output;
  };

  const formik = useFormik({
    initialValues: {
      audience: {
        me: false,
        everyone: false,
        subscribers: false,
        specific_subscriber: false,
        group: false,
      },
      show: {
        free: false,
        paid: false,
      },
      contentType: {
        image: false,
        video: false,
        collection: false,
      },
      visibility: {
        visibleNow: false,
        planned: false,
      },
      contentDetails: {
        noDescription: false,
      },
    },
    onSubmit: (values) => {
      updateCatalogParam("filters", transformFilters(values));
    },
  });

  const navigate = useNavigate();

  const handleSearchText = (searchText: string) => {
    getFoldersById(undefined, searchText);
    updateCatalogParam("searchText", searchText);
    if (searchText === "") {
      updateCatalogParam("currentTab", "all_posts");
      setTab("all_posts");
      return;
    }

    updateCatalogParam("currentTab", null);
    setTab(null);
  };

  const handleTabChange = useCallback(
    (nextView: ActiveTab) => {
      setTab(nextView);
    },
    [setTab]
  );

  const handleViewChange = useCallback(
    (nextView: ActiveView) => {
      setView(nextView);
    },
    [setView]
  );

  const handleFilterChange = useCallback(() => {
    setIsFilterOpen((prevState) => !prevState);
  }, [setIsFilterOpen]);

  const formControlLabelStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const renderFooter = () => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            orientation="horizontal"
            value={tab}
            exclusive
            onChange={(e, nextView) => {
              if (nextView === null) return;

              handleTabChange(nextView);
            }}
            className={styles.btnTabGroup}
          >
            <ToggleButton
              value="all_posts"
              aria-label="all_posts"
              color="primary"
              sx={{
                "&.MuiToggleButtonGroup-firstButton": {
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  color: "#492942",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "#fff",
                  background: "#3A75E9",
                },
                "&.Mui-selected:hover": {
                  color: "#fff",
                  background: "#4879e7",
                },
                borderRadius: "12px",
                padding: "6px 33px",
              }}
            >
              <Typography variant="button">All posts</Typography>
            </ToggleButton>
            <ToggleButton
              value="published"
              aria-label="published"
              color="primary"
              sx={{
                padding: "6px 17px",
                "&.MuiToggleButtonGroup-middleButton": {
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  color: "#492942",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "#fff",
                  background: "#3A75E9",
                },
                "&.Mui-selected:hover": {
                  color: "#fff",
                  background: "#4879e7",
                },
              }}
            >
              <Typography variant="button">Published</Typography>
            </ToggleButton>
            <ToggleButton
              value="unpublished"
              aria-label="unpublished"
              color="primary"
              sx={{
                padding: "6px 17px",
                "&.MuiToggleButtonGroup-lastButton": {
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                  color: "#492942",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "#fff",
                  background: "#3A75E9",
                },
                "&.Mui-selected:hover": {
                  color: "#fff",
                  background: "#4879e7",
                },
                borderRadius: "12px",
              }}
            >
              <Typography variant="button">Unpublished</Typography>
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ToggleButtonGroup
              orientation="horizontal"
              value={view}
              exclusive
              onChange={(e, nextView) => {
                if (nextView === null) return;

                handleViewChange(nextView);
              }}
              className={styles.btnViewGroup}
            >
              <ToggleButton
                value="tile"
                aria-label="tile"
                color="primary"
                sx={{
                  "&.MuiToggleButtonGroup-firstButton": {
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    border: "none",
                  },
                  "&.Mui-selected": {
                    color: "transparent",
                    background: "transparent",
                  },
                  "&.Mui-selected:hover": {
                    color: "transparent",
                    background: "transparent",
                  },
                  borderRadius: "0",
                  padding: "0",
                }}
              >
                {view === "tile" ? (
                  <TileIcon color="#6995EC" width={24} height={24} />
                ) : (
                  <TileIcon color="#AAAAAA" width={24} height={24} />
                )}
              </ToggleButton>

              <ToggleButton
                value="list"
                aria-label="list"
                color="primary"
                sx={{
                  "&.MuiToggleButtonGroup-lastButton": {
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    border: "none",
                  },
                  "&.Mui-selected": {
                    color: "transparent",
                    background: "transparent",
                  },
                  "&.Mui-selected:hover": {
                    color: "transparent",
                    background: "transparent",
                  },
                  borderRadius: "0",
                  padding: "0",
                }}
              >
                {view === "list" ? (
                  <ListIcon color="#6995EC" width={24} height={24} />
                ) : (
                  <ListIcon color="#AAAAAA" width={24} height={24} />
                )}
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              className={styles.btnViewGroup}
              onChange={(e, nextView) => {
                if (nextView === null) return;

                handleFilterChange();
              }}
            >
              <ToggleButton
                value="filter"
                aria-label="filter"
                color="primary"
                sx={{
                  "&.MuiToggleButtonGroup-middleButton": {
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    border: "none",
                  },
                  "&.Mui-selected": {
                    color: "transparent",
                    background: "transparent",
                  },
                  "&.Mui-selected:hover": {
                    color: "transparent",
                    background: "transparent",
                  },
                  borderRadius: "0",
                  padding: "0",
                  border: "0px solid black",
                }}
              >
                {isFilterOpen ? (
                  <FilterActiveIcon width={27} height={27} />
                ) : (
                  <FilterIcon width={27} height={27} />
                )}
              </ToggleButton>
            </ToggleButtonGroup>

            <Button
              type="button"
              variant="contained"
              sx={{ padding: "8px 56px" }}
              onClick={() => {
                navigate("/cc/upload-content");
              }}
            >
              <Typography variant="button">Upload content</Typography>
            </Button>
          </Box>
        </Box>

        {isFilterOpen && (
          <Box
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: "100%" }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{
                  margin: "0",

                  ".MuiGrid-item": {
                    paddingLeft: "2px",
                  },
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Audience:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "5px",
                      marginLeft: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="audience.me"
                          checked={formik.values.audience.me}
                          onChange={formik.handleChange}
                        />
                      }
                      label={AudienceEnum.me}
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="audience.everyone"
                          checked={formik.values.audience.everyone}
                          onChange={formik.handleChange}
                        />
                      }
                      label={AudienceEnum.everyone}
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="audience.subscribers"
                          checked={formik.values.audience.subscribers}
                          onChange={formik.handleChange}
                        />
                      }
                      label={AudienceEnum.subscribers}
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="audience.specific_subscriber"
                          checked={formik.values.audience.specific_subscriber}
                          onChange={formik.handleChange}
                        />
                      }
                      label={AudienceEnum.specific_subscriber}
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="audience.group"
                          checked={formik.values.audience.group}
                          onChange={formik.handleChange}
                        />
                      }
                      label={AudienceEnum.group}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Show:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="show.free"
                          checked={formik.values.show.free}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Free posts"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="show.paid"
                          checked={formik.values.show.paid}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Paid posts"
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Content type:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.image"
                          checked={formik.values.contentType.image}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Photos"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.video"
                          checked={formik.values.contentType.video}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Videos"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.collection"
                          checked={formik.values.contentType.collection}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Collections"
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Visibility:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="visibility.visibleNow"
                          checked={formik.values.visibility.visibleNow}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Visible now"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="visibility.planned"
                          checked={formik.values.visibility.planned}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Planned visibility"
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Content details:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentDetails.noDescription"
                          checked={formik.values.contentDetails.noDescription}
                          onChange={formik.handleChange}
                        />
                      }
                      label="No description"
                    />
                  </Box>
                </Grid>

                <Box
                  sx={{
                    width: "100%",
                    marginRight: "17px",
                    marginTop: "19px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await formik.submitForm();
                    }}
                    sx={{
                      padding: "8px 40px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="reset"
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      formik.handleReset(e);
                      formik.submitForm();
                    }}
                  >
                    Reset all
                  </Button>
                </Box>
              </Grid>
            </form>
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <>
      <SearchBar
        placeholder="Search..."
        handleSearchTextChange={handleSearchText}
        renderFooter={renderFooter()}
        lastSearchType={LastSearchType.MY_CATALOG_SEARCH}
      />
    </>
  );
};
