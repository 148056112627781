import type { ISocialInfo } from "../interfaces/personal-info.interface";

export interface ILinkForm {
  [key: string]: any;
  twitter: string | null;
  instagram: string | null;
  tiktok: string | null;
  youtube: string | null;
}

// eslint-disable-next-line no-shadow
export enum SocialInfoKeys {
  twitter = "twitter",
  instagram = "instagram",
  tiktok = "tiktok",
  youtube = "youtube",
}

export const socialInformation: ISocialInfo[] = [
  {
    name: SocialInfoKeys.twitter,
    title: "Twitter",
    placeholder: "@username",
    value: null,
  },
  {
    name: SocialInfoKeys.instagram,
    title: "Instagram",
    placeholder: "@username",
    value: null,
  },
  {
    name: SocialInfoKeys.tiktok,
    title: "TikTok",
    placeholder: "@username",
    value: null,
  },
  {
    name: SocialInfoKeys.youtube,
    title: "YouTube",
    placeholder: "@username",
    value: null,
  },
];
