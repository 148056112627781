import { Box, Button, Typography } from "@mui/material";
import cx from "classnames";
import React, { useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { CheckmarkIcon } from "../../../../../media/icons/checkmark";
import { EditIcon } from "../../../../../media/icons/edit";
import { Content } from "../../../../../services/catalog/interfaces/catalog.interfaces";
import {
  AudienceValuesEnum,
  Direction,
  SortBy,
} from "../../../../../services/catalog/interfaces/request-models.interfaces";
import { CurrenciesEnum } from "../../../../automatic-transfers/enums/currencies.enum";
import DefaultSelect from "../../../../shared/select/select";
import { ContentFolders } from "../content-folders/content-folders";
import { useCreatorCatalogCCViewContext } from "../context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { DeleteContentConfirmation } from "../modals/delete-content-confirmation/delete-content-confirmation";
import { EditCollection } from "../modals/edit-collection/edit-collection";
import { EditContent } from "../modals/edit-content/edit-content";
import { SelectCollection } from "../modals/select-collection/select-collection";
import {
  CatalogDefaultSelectOptions,
  catalogSortOptions,
} from "./catalog-sort";
import { ContentCard } from "./content-card/content-card";
import styles from "./content-cc-collection.module.scss";

interface ContentCollectionParams {}

export const ContentCcCollection: React.FC<ContentCollectionParams> = () => {
  const {
    creatorContentItems,
    updateCatalogParam,
    contentCatalogParams,
    selectedCreatorContentItems,
    setSelectedCreatorContentItems,
    setPostsToDelete,
    updateContent,
    setContentEdit,
    contentEdit,
    collectionEdit,
    setCollectionEdit,
    setIsCollectionCreating,
    isFolderNavigation,
    view,
  } = useCreatorCatalogCCViewContext();
  const { currentOpenedFolder } = useFolderCreatorCatalogCCViewContext();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [isDeleteContentDialogOpened, setIsDeleteContentDialogOpened] =
    useState<boolean>(false);
  const [isSelectCollectionDialogOpened, setIsSelectCollectionDialogOpened] =
    useState<boolean>(false);

  const onBulkUnpublishClick = () => {
    updateContent({
      accessToken: accessToken || "",
      contentIds: selectedCreatorContentItems.map((item) => item.id),
      formData: {
        audience: {
          audience: AudienceValuesEnum.me,
          ids: [],
        },
      },
      folder_id: currentOpenedFolder?.id || null,
    });
    setSelectedCreatorContentItems([]);
  };

  const onBulkEditClick = () => {
    setContentEdit(selectedCreatorContentItems);
  };

  const onCollectionCreateClick = () => {
    setIsSelectCollectionDialogOpened(false);
    const contentMedia = selectedCreatorContentItems.flatMap(
      (item) => item.content_media
    );

    const newCollection: Content = {
      id: "11111111-1111-1111-1111-111111111111",
      is_collection: true,
      created_at: "",
      name: "",
      content_subscriber_audience: [],
      content_group_audience: [],
      scheduled_at: "",
      content_media: contentMedia,
      audience: AudienceValuesEnum.me,
      content_tags: [],
      visible: true,
      description: "",
      currency: CurrenciesEnum.USD,
      folder: null,
      price: 0,
      visible_days: null,
      _count: {
        photos: 0,
        viewsPerContents: 0,
        user_likes: 0,
        contentPurchaseAmount: 0,
        earnings: [],
        videos: 0,
      },
    };

    setCollectionEdit(newCollection);
    setIsCollectionCreating(true);
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "22px",
        }}
      >
        <Box
          sx={{
            marginBottom: "31px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap="17px"
            >
              {!isFolderNavigation && (
                <>
                  <Typography variant="body1">Sort by:</Typography>
                  <Box width="290px">
                    <DefaultSelect<CatalogDefaultSelectOptions>
                      options={catalogSortOptions}
                      onChange={(value) => {
                        updateCatalogParam("sortBy", value.value as SortBy);
                        updateCatalogParam(
                          "direction",
                          (value.direction as Direction)
                            ? (value.direction as Direction)
                            : null
                        );
                      }}
                      value={contentCatalogParams.sortBy || ""}
                      name="sort"
                      placeholder="Select..."
                    />
                  </Box>
                </>
              )}

              {selectedCreatorContentItems.length > 0 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setPostsToDelete([]);
                    setSelectedCreatorContentItems([]);
                  }}
                >
                  <CheckmarkIcon />
                  <Typography fontWeight="bold">Unselect All</Typography>
                </Button>
              )}
            </Box>

            {selectedCreatorContentItems.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onBulkEditClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EditIcon color="#ffffff" />
                  <Typography variant="button" fontWeight="bold">
                    Edit
                  </Typography>
                </Button>

                <Button variant="outlined" onClick={onBulkUnpublishClick}>
                  <Typography variant="button" fontWeight="bold">
                    Unpublish
                  </Typography>
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsSelectCollectionDialogOpened(true);
                  }}
                >
                  <Typography variant="button" fontWeight="bold">
                    Add to Collection
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {contentCatalogParams.sortBy !== "folders/desc" && (
          <Box>
            <ContentFolders />
          </Box>
        )}

        <Box
          className={cx({
            [styles.contentCollectionWrapperSelectPostForCollection]:
              isFolderNavigation,
            [styles.contentCollectionWrapper]: !isFolderNavigation,
            [styles.contentCollectionListView]:
              view === "list" && !isFolderNavigation,
          })}
          sx={{
            marginBottom: "33px",
          }}
        >
          {creatorContentItems.map((content) => (
            <ContentCard
              key={content.id}
              content={content}
              onAddToCollection={(contentToAdd) => {
                setIsSelectCollectionDialogOpened(true);
                setSelectedCreatorContentItems([contentToAdd]);
              }}
            />
          ))}
        </Box>

        {contentCatalogParams.sortBy === "folders/desc" && (
          <Box>
            <ContentFolders />
          </Box>
        )}
      </Box>

      <SelectCollection
        isOpen={isSelectCollectionDialogOpened}
        onCancelClick={() => {
          setIsSelectCollectionDialogOpened(false);
        }}
        onCollectionCreateClick={onCollectionCreateClick}
      />
      <EditContent isOpen={contentEdit.length > 0} />
      <EditCollection isOpen={!!collectionEdit} />
      <DeleteContentConfirmation
        isOpen={isDeleteContentDialogOpened}
        onCancelClick={() => {
          setIsDeleteContentDialogOpened(false);
        }}
      />
    </>
  );
};
