import type {
  AutomaticTransfer,
  IUserTransferAccounts,
} from "../../../services/sign-in/interfaces/initialize.interface";

type CheckForAutomaticTransferArgs = {
  transferAccount: IUserTransferAccounts;
  automaticTransfers: AutomaticTransfer[];
};

export const checkForAutomaticTransfers = ({
  transferAccount,
  automaticTransfers,
}: CheckForAutomaticTransferArgs) =>
  !!automaticTransfers.flatMap((transfer) =>
    transfer.currency_transfers.filter(
      (currTransfer) => currTransfer.transfer_account === transferAccount.id
    )
  ).length;
