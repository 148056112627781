function ButtonNextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#fff" fillOpacity="0.8" rx="16" />
      <path
        fill="#3C3C3C"
        d="M10.087 13.184l5.465 5.38a.637.637 0 00.896 0l5.466-5.379a.64.64 0 011.037.203.618.618 0 01-.138.683l-5.464 5.378a1.925 1.925 0 01-2.697 0L9.187 14.07a.618.618 0 010-.887.642.642 0 01.9 0z"
      />
    </svg>
  );
}

export default ButtonNextIcon;
