export const LockIcon = ({
  width = 32,
  height = 32,
  color = "#fff",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 60H6C4.4087 60 2.88258 59.3679 1.75736 58.2426C0.632141 57.1174 0 55.5913 0 54V32C0 30.4087 0.632141 28.8826 1.75736 27.7574C2.88258 26.6321 4.4087 26 6 26H42C43.5913 26 45.1174 26.6321 46.2426 27.7574C47.3679 28.8826 48 30.4087 48 32V54C48 55.5913 47.3679 57.1174 46.2426 58.2426C45.1174 59.3679 43.5913 60 42 60ZM6 30C5.46957 30 4.96086 30.2107 4.58579 30.5858C4.21071 30.9609 4 31.4696 4 32V54C4 54.5304 4.21071 55.0391 4.58579 55.4142C4.96086 55.7893 5.46957 56 6 56H42C42.5304 56 43.0391 55.7893 43.4142 55.4142C43.7893 55.0391 44 54.5304 44 54V32C44 31.4696 43.7893 30.9609 43.4142 30.5858C43.0391 30.2107 42.5304 30 42 30H6Z"
      fill={color}
    />
    <path
      d="M40 30H8C7.46957 30 6.96086 29.7893 6.58579 29.4142C6.21071 29.0391 6 28.5304 6 28V16C6 11.7565 7.68571 7.68687 10.6863 4.68629C13.6869 1.68571 17.7565 0 22 0H26C30.2435 0 34.3131 1.68571 37.3137 4.68629C40.3143 7.68687 42 11.7565 42 16V28C42 28.5304 41.7893 29.0391 41.4142 29.4142C41.0391 29.7893 40.5304 30 40 30ZM10 26H38V16C38 12.8174 36.7357 9.76516 34.4853 7.51472C32.2348 5.26428 29.1826 4 26 4H22C18.8174 4 15.7652 5.26428 13.5147 7.51472C11.2643 9.76516 10 12.8174 10 16V26Z"
      fill={color}
    />
    <path
      d="M24 44C23.2089 44 22.4355 43.7654 21.7777 43.3259C21.1199 42.8864 20.6072 42.2616 20.3045 41.5307C20.0017 40.7998 19.9225 39.9956 20.0769 39.2196C20.2312 38.4437 20.6122 37.731 21.1716 37.1716C21.731 36.6122 22.4437 36.2312 23.2196 36.0769C23.9956 35.9225 24.7998 36.0017 25.5307 36.3045C26.2616 36.6072 26.8864 37.1199 27.3259 37.7777C27.7654 38.4355 28 39.2089 28 40C28 41.0609 27.5786 42.0783 26.8284 42.8284C26.0783 43.5786 25.0609 44 24 44Z"
      fill={color}
    />
    <path d="M26 42H22V50H26V42Z" fill="white" />
  </svg>
);
