import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type { PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import * as React from "react";

import { indigo } from "../../../themes/colors";

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isInRange: boolean;
  isFirst: boolean;
  isLast: boolean;
  isOneDayRange: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isInRange",
})<CustomPickerDayProps>(
  ({ theme, isSelected, isInRange, day, isFirst, isLast, isOneDayRange }) => ({
    borderRadius: 0,
    margin: "0 2px",
    boxShadow: "4px 0px 0px 0px transparent",
    transition: "background-color 500ms, box-shadow 500ms, border-radius 500ms",
    "&.MuiPickersDay-today": {
      border: `none`,
    },
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      },
    }),
    ...(isInRange && {
      backgroundColor: theme.palette.primary[theme.palette.mode],
      color: "white !important",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      },
    }),
    ...((day.day() === 0 || isFirst) && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...((day.day() === 6 || isLast) && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
    ...(isOneDayRange && {
      borderRadius: "50% !important",
    }),
    ...(!isLast &&
      !isOneDayRange &&
      isInRange &&
      day.day() !== 6 && {
        boxShadow: `4px 0px 0px 0px ${theme.palette.primary[theme.palette.mode]}`,
      }),
    ...((isSelected || isInRange) &&
      day.day() !== 6 && {
        "&.Mui-disabled": {
          color: `${theme.palette.primary.contrastText} !important`,
          backgroundColor: `${indigo.lightest} !important`,
          boxShadow: `4px 0px 0px 0px ${indigo.lightest}`,
        },
      }),
    ...((isSelected || isInRange) &&
      day.day() === 6 && {
        "&.Mui-disabled": {
          color: `${theme.palette.primary.contrastText} !important`,
          backgroundColor: `${indigo.lightest} !important`,
        },
      }),

    ...(!isSelected &&
      !isInRange && {
        "&.MuiPickersDay-today:before": {
          content: "''",
          display: "block",
          position: "absolute",
          borderRadius: "50%",
          width: "100%",
          height: "100%",
          border: "1px solid #878C8E",
          backgroundColor: "transparent",
          top: 0,
          left: 0,
          boxSizing: "border-box",
        },
      }),
  })
) as React.ComponentType<CustomPickerDayProps>;

function Day(
  props: PickersDayProps<Dayjs> & {
    startDate?: Dayjs | null;
    endDate?: Dayjs | null;
    compareDates?: any;
  }
) {
  const { day, startDate, endDate, compareDates, ...other } = props;
  const isSelected = day.isSame(startDate, "day") || day.isSame(endDate, "day");
  const isOneDayRange =
    day.isSame(startDate, "day") && day.isSame(endDate, "day");
  const isFirst = day.isSame(startDate, "day") && !day.isSame(endDate, "day");
  const isLast = day.isSame(endDate, "day") && !day.isSame(startDate, "day");
  const isInRange = day.isBetween(startDate, endDate, "day", "[]");

  return (
    <CustomPickersDay
      {...other}
      day={day}
      onClick={() => compareDates(day)}
      disableMargin
      selected={false}
      isSelected={isSelected}
      isInRange={isInRange}
      isFirst={isFirst}
      isLast={isLast}
      isOneDayRange={isOneDayRange}
    />
  );
}

interface RangePickerProps {
  setDate: (data: { startDate: string; endDate: string }) => void;
  date: { startDate: string; endDate: string };
  disablePast?: boolean;
  disableFuture?: boolean;
}

function createDateComparer(setDate: any): (selectedDate: string) => void {
  let selectedStartDate: Date | null = null;
  let selectedEndDate: Date | null = null;
  let dateCount: number = 0;

  return function (selectedDate): void {
    // Reset if two dates have been selected
    if (dateCount === 2) {
      setDate({
        startDate: dayjs(selectedStartDate).toISOString(),
        endDate: dayjs(selectedStartDate).toISOString(),
      });
      selectedStartDate = null;
      selectedEndDate = null;
      dateCount = 0;
    }

    // Convert the input date to a Date object if it's not already
    const inputDate = new Date(selectedDate);

    // If this is the first date, set it as the start date
    if (dateCount === 0) {
      selectedStartDate = inputDate;

      // handleStartDateChange(selectedStartDate);
    } else if (dateCount === 1) {
      // If this is the second date, compare it with the start date
      if (inputDate < selectedStartDate!) {
        selectedEndDate = selectedStartDate;
        selectedStartDate = inputDate;
      } else {
        selectedEndDate = inputDate;
      }
      setDate({
        startDate: dayjs(selectedStartDate).toISOString(),
        endDate: dayjs(selectedEndDate).toISOString(),
      });
    }

    dateCount += 1;
  };
}

export default function RangePicker(props: RangePickerProps) {
  const { date, setDate, disableFuture, disablePast } = props;
  const compareDates = createDateComparer(setDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={dayjs(date.startDate)}
        views={["year", "month", "day"]}
        slots={{ day: Day }}
        disableFuture={disableFuture}
        disablePast={disablePast}
        slotProps={{
          day: () =>
            ({
              compareDates,
              startDate: dayjs(date.startDate),
              endDate: dayjs(date.endDate),
            }) as any,
        }}
      />
    </LocalizationProvider>
  );
}
