import type { ITableHead } from "../../../interfaces/table-head.interface";

export const interestedUsersTableHeads: ITableHead[] = [
  {
    display_text: "Name",
    value: "user.username",
    sortable: true,
    default_sort: false,
  },
  {
    display_text: "Start Date",
    value: "created_at",
    sortable: true,
    default_sort: false,
  },
  {
    display_text: "Purchases",
    value: "user.number_of_purchases",
    sortable: true,
    default_sort: true,
  },
  {
    display_text: "Tips",
    value: "user.sent_tips",
    sortable: true,
    default_sort: false,
  },
];
