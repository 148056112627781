import { Box, Fade, Popper } from "@mui/material";
import type { FC } from "react";

import { useAppSelector } from "../../../../../hooks/redux";
import { CreatorMenu } from "../creator-menu/creator-menu";
import { SubscriberMenu } from "../subscriber-menu/subscriber-menu";
import styles from "./avatar-menu.module.scss";

type AvatarMenuProp = {
  id: string | undefined;
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
};

export const AvatarMenu: FC<AvatarMenuProp> = ({ id, isOpen, anchorEl }) => {
  const { user } = useAppSelector((data) => data.userReducer);

  return (
    <div>
      <Popper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={styles.popper}>
              {user && user.role === "creator" ? (
                <CreatorMenu />
              ) : (
                <SubscriberMenu />
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
