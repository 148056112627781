export const MakeDefaultIcon = ({
  width = 18,
  height = 18,
  fill = "white",
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 0H5.25C3.18225 0 1.5 1.68225 1.5 3.75V15.747C1.5 16.6733 2.0565 17.4937 2.91675 17.8365C3.7785 18.1815 4.746 17.9662 5.4045 17.2703L8.99925 13.1415L12.6158 17.2935C13.0515 17.7533 13.641 17.9977 14.2448 17.9977C14.5253 17.9977 14.8088 17.9452 15.0825 17.8358C15.9428 17.493 16.4993 16.6725 16.4993 15.7463V3.75C16.4993 1.68225 14.8178 0 12.75 0ZM15 15.747C15 16.2188 14.6385 16.3988 14.5283 16.4437C14.418 16.488 14.031 16.605 13.7265 16.2863L9.5655 11.508C9.42375 11.3438 9.21675 11.25 9 11.25C8.78325 11.25 8.577 11.3438 8.4345 11.508L4.2945 16.263C3.96975 16.605 3.5835 16.4872 3.47175 16.4437C3.3615 16.3988 3 16.2188 3 15.747V3.75C3 2.5095 4.0095 1.5 5.25 1.5H12.75C13.9905 1.5 15 2.5095 15 3.75V15.747ZM12.7118 5.658C12.8018 5.90475 12.7283 6.1815 12.5273 6.35025L11.049 7.55475L11.6603 9.41775C11.7443 9.66975 11.6588 9.94725 11.4488 10.1093C11.238 10.2712 10.9485 10.2825 10.7258 10.1378L9.0045 9.0165L7.31175 10.149C7.20675 10.2195 7.08525 10.2548 6.96375 10.2548C6.83175 10.2548 6.69975 10.2128 6.58875 10.1302C6.3765 9.97125 6.288 9.69525 6.3675 9.4425L6.9555 7.55775L5.46975 6.34875C5.2695 6.17925 5.19675 5.90325 5.2875 5.65725C5.3775 5.41125 5.6115 5.24775 5.874 5.24775H7.74975L8.4135 3.3855C8.50425 3.141 8.73825 2.979 8.99925 2.979C9.26025 2.979 9.49425 3.141 9.585 3.3855L10.2488 5.24775H12.1245C12.387 5.24775 12.6218 5.41125 12.7118 5.658Z"
      fill={fill}
    />
  </svg>
);
