import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { CurrenciesEnum } from "../../../components/automatic-transfers/enums/currencies.enum";
import type {
  CurrencyManualTransfer,
  ManualTransfer,
} from "../../../services/sign-in/interfaces/initialize.interface";

export interface AvailableCurrencies {
  value: CurrenciesEnum;
  display_text: string;
}

interface ManualTransfers {
  available_currencies: AvailableCurrencies[];
  manual_transfers: ManualTransfer[];
}

const initialState: ManualTransfers = {
  available_currencies: [],
  manual_transfers: [],
};

export const manualTransfersSlice = createSlice({
  name: "manualTransferAccounts",
  initialState,
  reducers: {
    setManualTransfersData(state, action: PayloadAction<ManualTransfers>) {
      const {
        manual_transfers: manualTransfers,
        available_currencies: availableCurrencies,
      } = action.payload;

      state.manual_transfers = manualTransfers;
      state.available_currencies = availableCurrencies;
    },
    addCurrencyManualTransfer(
      state,
      action: PayloadAction<{
        manual_transfer_id: string;
        balance: string | undefined;
        rest: number;
        currency_transfer: CurrencyManualTransfer;
      }>
    ) {
      const {
        manual_transfer_id: manualTransferId,
        currency_transfer: currencyTransfer,
        balance,
        rest,
      } = action.payload;

      const manualTransferIndex = state.manual_transfers.findIndex(
        (item) => item.id === manualTransferId
      );
      if (manualTransferIndex !== -1) {
        state.manual_transfers[manualTransferIndex].currency_transfers.push(
          currencyTransfer
        );
        state.manual_transfers[manualTransferIndex].balance = balance;
        state.manual_transfers[manualTransferIndex].rest = rest;
      }
    },
    removeCurrencyManualTransfer(
      state,
      action: PayloadAction<{
        manual_transfer_id: string;
        currency_transfer_id: string;
      }>
    ) {
      const {
        manual_transfer_id: manualTransferId,
        currency_transfer_id: currencyTransferId,
      } = action.payload;

      const manualTransferIndex = state.manual_transfers.findIndex(
        (item) => item.id === manualTransferId
      );
      if (manualTransferIndex !== -1) {
        const currencyTransferIndex = state.manual_transfers[
          manualTransferIndex
        ].currency_transfers.findIndex(
          (currencyTransfer) => currencyTransfer.id === currencyTransferId
        );
        if (currencyTransferIndex !== -1) {
          state.manual_transfers[manualTransferIndex].currency_transfers.splice(
            currencyTransferIndex,
            1
          );
        }
      }
    },
    changeValue(
      state,
      action: PayloadAction<{
        manual_transfer_id: string;
        currency_transfer_id: string;
        key: keyof CurrencyManualTransfer;
        value: string;
      }>
    ) {
      const {
        manual_transfer_id: manualTransferId,
        currency_transfer_id: currencyTransferId,
        key,
        value,
      } = action.payload;
      const manualTransferIndex = state.manual_transfers.findIndex(
        (transfer) => transfer.id === manualTransferId
      );
      if (manualTransferIndex !== -1) {
        const currencyTransferIndex = state.manual_transfers[
          manualTransferIndex
        ].currency_transfers.findIndex(
          (currencyTransfer) => currencyTransfer.id === currencyTransferId
        );
        if (currencyTransferIndex !== -1) {
          state.manual_transfers[manualTransferIndex].currency_transfers[
            currencyTransferIndex
          ][key] = value;
        }
      }
    },
    changeManualTransferValue(
      state,
      action: PayloadAction<{
        manual_transfer_id: string;
        key: "currency";
        value: CurrenciesEnum;
      }>
    ) {
      const {
        manual_transfer_id: manualTransferId,
        key,
        value,
      } = action.payload;
      const manualTransferIndex = state.manual_transfers.findIndex(
        (transfer) => transfer.id === manualTransferId
      );
      if (manualTransferIndex !== -1) {
        state.manual_transfers[manualTransferIndex][key] = value;
      }
    },
  },
});

export default manualTransfersSlice.reducer;
