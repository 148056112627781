import type { DragEndEvent } from "@dnd-kit/core";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSwappingStrategy } from "@dnd-kit/sortable";
import { Box } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback } from "react";

import type { IUploadFile } from "../../../../interfaces/file-uploading/uploaded-files.interface";
import { SortableItem } from "../../../shared/file-list-items/sortable-item/sortable-item";
import styles from "./greetings-media-list.module.scss";

interface GreetingsMediaListProp {
  files: IUploadFile[];
  setLoadedFiles: Dispatch<SetStateAction<IUploadFile[]>>;
  fileWidthPx: number;
  draggable: boolean;
  uploadFileOnRetry: (file: IUploadFile) => void;
  setDeletedRemoteMedia: Dispatch<SetStateAction<IUploadFile[]>>;
}

export const GreetingsMediaList: FC<GreetingsMediaListProp> = ({
  files,
  setLoadedFiles,
  fileWidthPx,
  draggable,
  uploadFileOnRetry,
  setDeletedRemoteMedia,
}) => {
  const onFileDelete = useCallback(
    (fileId: string) => {
      setLoadedFiles((prevState) =>
        prevState.filter((file) => file.id !== fileId)
      );

      const remoteFileToDelete = files
        .filter((file) => file.status === "remote")
        .find((file) => file.id === fileId);

      if (remoteFileToDelete) {
        setDeletedRemoteMedia((prevState) => [
          ...prevState,
          remoteFileToDelete,
        ]);
      }
    },
    [files]
  );
  const sensors = useSensors(useSensor(PointerSensor));
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id === over.id) return;

    const filesList = [...files];
    const sourceIndex = files.findIndex((item) => item.id === active.id);
    const destinationIndex = files.findIndex((item) => item.id === over.id);

    const temp = filesList[sourceIndex];
    filesList[sourceIndex] = filesList[destinationIndex];
    filesList[destinationIndex] = temp;

    setLoadedFiles(filesList);
  };

  const uploadFiles = async (retriedFile: IUploadFile) => {
    uploadFileOnRetry(retriedFile);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "15px",
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={files} strategy={rectSwappingStrategy}>
          <div className={styles.fileCardWrapper} data-cy="list-container">
            {files.map((file) => (
              <SortableItem
                key={file.id}
                id={file.id}
                file={file}
                fileWidthPx={fileWidthPx}
                onFileDelete={onFileDelete}
                uploadFiles={uploadFiles}
                draggable={draggable}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </Box>
  );
};
