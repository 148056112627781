import type { ColorNames } from "../../themes/interfaces/color.interface";

export const getColorOfState = (
  isLoading: boolean,
  formErrors: string | undefined,
  isCardValid: boolean
): ColorNames => {
  if (isLoading || (!formErrors && !isCardValid)) {
    return "primary";
  }

  if (formErrors || !isCardValid) {
    return "error";
  }

  return "success";
};
