import { Box, Popover, Typography } from "@mui/material";

import CommentIcon from "../../../../media/icons/comment";
import FavouriteIcon from "../../../../media/icons/favourite";
import MessageIcon from "../../../../media/icons/message";
import RectangleIcon from "../../../../media/icons/rectangle";
import styles from "./user-popover.module.scss";

interface UserPopoverProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  handleClose: () => void;
}

export default function UserPopover(props: UserPopoverProps) {
  const { anchorEl, handleClose, open } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "4px",
            width: "350px",
          },
        },
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <RectangleIcon />
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          marginTop: "-6px",
          display: "flex",
          padding: "13px 12px 13px 35px",
          justifyContent: "space-between",
          gap: "24px",
        }}
      >
        <Box className={styles.popoverChild}>
          <FavouriteIcon />
          <Typography variant="body2">Favourite</Typography>
        </Box>
        <Box className={styles.popoverChild}>
          <CommentIcon />
          <Typography variant="body2">Message</Typography>
        </Box>
        <Box className={styles.popoverChild}>
          <MessageIcon />
          <Typography variant="body2">Private Content</Typography>
        </Box>
      </Box>
    </Popover>
  );
}
