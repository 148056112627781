import type { FC } from "react";

import styles from "./uploader-indicator.module.scss";

interface IUploaderIndicatorProps {
  percentage: number;
  radius: number;
}

export const UploaderIndicator: FC<IUploaderIndicatorProps> = ({
  percentage,
  radius,
}) => {
  const strokeWidth = 2; // ширина рамки
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
    >
      <defs>
        <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: "rgba(255, 255, 255, 0.8)", stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "rgba(255, 255, 255, 0.4)", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <circle
        className={styles.loadingCircle}
        stroke="white"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle fill="url(#grad)" r={normalizedRadius} cx={radius} cy={radius} />
      {percentage === 100 && (
        <path
          className={styles.checkmark}
          fill="none"
          stroke="white"
          strokeWidth="2"
          d={`M${radius / 2 + 2} ${radius} l${radius / 4} ${radius / 4} l${radius / 2} -${radius / 2}`}
        />
      )}
    </svg>
  );
};
