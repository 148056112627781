import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { ICreateGroupParam } from "./interfaces/create-group-param.interface";
import type { ICreateGroupResponse } from "./interfaces/create-group-response.interface";
import type { IFetchGroupSubscribersParam } from "./interfaces/fetch-group-subscribers-param.interface";
import type { IFetchGroupSubscribersResponse } from "./interfaces/fetch-group-subscribers.interface";
import type { IFetchGroupsResponse } from "./interfaces/fetch-groups-response.interface";
import type { IFetchSubscribersResponse } from "./interfaces/fetch-subscribers-response.interface";
import type { IFetchInterestedUsersResponse } from "./interfaces/interested-users.interface";
import type { IManageSubscribersParams } from "./interfaces/manage-subscribers-param.interface";
import type { IUpdateGroupParams } from "./interfaces/update-group-params.interface";
import type { IUpdateGroupResponse } from "./interfaces/update-group-response.interface";

export const manageSubscribersApi = createApi({
  reducerPath: "manageSubscribersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    fetchSubscribers: build.mutation<
      IFetchSubscribersResponse,
      IManageSubscribersParams
    >({
      query: (data: IManageSubscribersParams) => ({
        url: ENDPOINTS.MANAGE_SUBSCRIBERS.FETCH_SUBSCRIBERS,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          searchText: data.searchText,
        },
      }),
    }),

    fetchInterestedUsers: build.mutation<
      IFetchInterestedUsersResponse,
      IManageSubscribersParams
    >({
      query: (data: IManageSubscribersParams) => ({
        url: ENDPOINTS.MANAGE_SUBSCRIBERS.FETCH_INTERESTED_USERS,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          searchText: data.searchText,
        },
      }),
    }),

    fetchGroupSubscribers: build.mutation<
      IFetchGroupSubscribersResponse,
      IFetchGroupSubscribersParam
    >({
      query: (data: IFetchGroupSubscribersParam) => ({
        url: `${ENDPOINTS.MANAGE_SUBSCRIBERS.FETCH_GROUP_SUBSCRIBERS}/${data.groupId}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          searchText: data.searchText,
        },
      }),
    }),
    fetchGroups: build.mutation<
      IFetchGroupsResponse,
      IManageSubscribersParams | void
    >({
      query: (data: IManageSubscribersParams) => ({
        url: ENDPOINTS.MANAGE_SUBSCRIBERS.FETCH_GROUPS,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
          searchText: data.searchText,
        },
      }),
    }),
    createGroup: build.mutation<ICreateGroupResponse, ICreateGroupParam>({
      query: (data: ICreateGroupParam) => ({
        url: ENDPOINTS.MANAGE_SUBSCRIBERS.CREATE_GROUP,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          name: data.name,
          user_ids: data.user_ids,
        },
      }),
    }),
    updateGroup: build.mutation<IUpdateGroupResponse, IUpdateGroupParams>({
      query: (data: IUpdateGroupParams) => ({
        url: `${ENDPOINTS.MANAGE_SUBSCRIBERS.UPDATE_GROUP}/${data.groupId}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          name: data.name,
          user_ids: data.user_ids,
        },
      }),
    }),
  }),
});

export const {
  useFetchSubscribersMutation,
  useFetchGroupsMutation,
  useCreateGroupMutation,
  useFetchGroupSubscribersMutation,
  useUpdateGroupMutation,
  useFetchInterestedUsersMutation,
} = manageSubscribersApi;
