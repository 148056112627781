export interface ISubscriptionPlans {
  plan_id: string;
  plan_name: string;
  plan_amount: number;
  backgroundColor: string;
  backgroundHoverColor: string;
}

export const SUBSCRIPTION_PLANS: ISubscriptionPlans[] = [
  {
    plan_id: "0",
    plan_name: "VIP",
    plan_amount: 100,
    backgroundColor: "#3A75E9",
    backgroundHoverColor: "#1B5EE3",
  },
  {
    plan_id: "1",
    plan_name: "The goods",
    plan_amount: 50,
    backgroundColor: "#E66C26",
    backgroundHoverColor: "#d06223",
  },
  {
    plan_id: "2",
    plan_name: "Fun staff",
    plan_amount: 25,
    backgroundColor: "#30D354",
    backgroundHoverColor: "#24b544",
  },
];
