import type { FC, SetStateAction } from "react";
import { useEffect, useState } from "react";

import type { ISubmitState } from "../../../../../hooks/submit-state";
import type {
  IBackyardBanishment,
  IBackyardBanishmentUpdate,
} from "../../../../../services/settings/interfaces/settings.interface";
import DefaultSelect from "../../../../shared/select/select";
import { BanishmentKeys, banishmentDropdown } from "../banishment";
import { BanishByCode } from "./banish-by-code/banish-by-code";
import { BanishByMiles } from "./banish-by-miles/banish-by-miles";
import { BanishByState } from "./banish-by-state/banish-by-state";

interface IBanishmentProps {
  banishmentEnabled: boolean;
  onUpdateBackyardBanishment: (
    backyardBanishment: IBackyardBanishmentUpdate
  ) => Promise<void>;
  banishmentData: IBackyardBanishment | null | undefined;
  submitState: ISubmitState;
  resetState: () => void;
}

export const Banishment: FC<IBanishmentProps> = ({
  banishmentEnabled,
  onUpdateBackyardBanishment,
  banishmentData,
  submitState,
  resetState,
}) => {
  const [banishmentMethod, setBanishmentMethod] = useState<BanishmentKeys>(
    BanishmentKeys.none
  );

  useEffect(() => {
    if (banishmentData) {
      setBanishmentMethod(banishmentData.type);
    }
  }, [banishmentData]);

  const renderBanishmentMethod = (selectedBanishmentMethod: BanishmentKeys) => {
    switch (selectedBanishmentMethod) {
      case BanishmentKeys.withinMiles: {
        return (
          <BanishByMiles
            banishmentData={banishmentData?.data}
            onUpdateBackyardBanishment={onUpdateBackyardBanishment}
            submitState={submitState}
            resetState={resetState}
          />
        );
      }
      case BanishmentKeys.state: {
        return (
          <BanishByState
            banishmentData={banishmentData?.data}
            onUpdateBackyardBanishment={onUpdateBackyardBanishment}
            submitState={submitState}
            resetState={resetState}
          />
        );
      }
      case BanishmentKeys.zipCodes: {
        return (
          <BanishByCode
            banishmentData={banishmentData?.data}
            onUpdateBackyardBanishment={onUpdateBackyardBanishment}
            submitState={submitState}
            resetState={resetState}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (!banishmentEnabled) {
      setBanishmentMethod(BanishmentKeys.none);
    }
  }, [banishmentEnabled]);

  return (
    <>
      <DefaultSelect
        options={banishmentDropdown}
        onChange={(value) => {
          setBanishmentMethod(value.value as SetStateAction<BanishmentKeys>);
          resetState();
        }}
        value={banishmentMethod}
        name="info"
        placeholder="Select banishment"
        disabled={!banishmentEnabled}
      />
      {renderBanishmentMethod(banishmentMethod)}
    </>
  );
};
