import type { DefaultSelectOptions } from "../../../shared/select/select";
import type { ITastesInfo } from "../interfaces/personal-info.interface";

export interface ITastesForm {
  [key: string]: any;
  interestedIn: string | null;
  turnOns: string | null;
  turnOffs: string | null;
  fetishes: string | null;
  hobbies: string | null;
  foods: string | null;
  musicGenres: string | null;
  movies: string | null;
  sports: string | null;
  videoGames: string | null;
  jewelry: string | null;
  lingerie: string | null;
  sexToys: string | null;
  drinks: string | null;
}

// eslint-disable-next-line no-shadow
export enum TastesKeys {
  interestedIn = "interested_in",
  turnOns = "turn_ons",
  turnOffs = "turn_offs",
  fetishes = "fetishes",
  hobbies = "hobbies",
  foods = "foods",
  musicGenres = "music_genres",
  movies = "movies",
  sports = "sports",
  videoGames = "video_games",
  jewelry = "jewelry",
  lingerie = "lingerie",
  sexToys = "sex_toys",
  drinks = "drinks",
}

export enum TastesNames {
  interestedIn = "Interested In",
  turnOns = "Turn Ons",
  turnOffs = "Turn Offs",
  fetishes = "Fetishes",
  hobbies = "Hobbies",
  foods = "Foods",
  musicGenres = "Music Genres",
  movies = "Movies",
  sports = "Sports",
  videoGames = "Video Games",
  jewelry = "Jewelry",
  lingerie = "Lingerie",
  sexToys = "Sex Toys",
  drinks = "Drinks",
}

export const tastesInfoDropdown: DefaultSelectOptions[] = [
  {
    value: TastesKeys.interestedIn,
    display_text: TastesNames.interestedIn,
    disabled: true,
  },
  {
    value: TastesKeys.turnOns,
    display_text: TastesNames.turnOns,
    disabled: true,
  },
  {
    value: TastesKeys.turnOffs,
    display_text: TastesNames.turnOffs,
    disabled: true,
  },
  {
    value: TastesKeys.fetishes,
    display_text: TastesNames.fetishes,
    disabled: false,
  },
  {
    value: TastesKeys.hobbies,
    display_text: TastesNames.hobbies,
    disabled: false,
  },
  {
    value: TastesKeys.foods,
    display_text: TastesNames.foods,
    disabled: false,
  },
  {
    value: TastesKeys.musicGenres,
    display_text: TastesNames.musicGenres,
    disabled: false,
  },
  {
    value: TastesKeys.movies,
    display_text: TastesNames.movies,
    disabled: false,
  },
  {
    value: TastesKeys.sports,
    display_text: TastesNames.sports,
    disabled: false,
  },
  {
    value: TastesKeys.videoGames,
    display_text: TastesNames.videoGames,
    disabled: false,
  },
  {
    value: TastesKeys.jewelry,
    display_text: TastesNames.jewelry,
    disabled: false,
  },
  {
    value: TastesKeys.lingerie,
    display_text: TastesNames.lingerie,
    disabled: false,
  },
  {
    value: TastesKeys.sexToys,
    display_text: TastesNames.sexToys,
    disabled: false,
  },
  {
    value: TastesKeys.drinks,
    display_text: TastesNames.drinks,
    disabled: false,
  },
];

export const tastes: ITastesInfo[] = [
  {
    name: TastesKeys.interestedIn,
    title: TastesNames.interestedIn,
    placeholder: "Enter interested in",
    value: null,
  },
  {
    name: TastesKeys.turnOns,
    title: TastesNames.turnOns,
    placeholder: "Enter turn ons",
    value: null,
  },
  {
    name: TastesKeys.turnOffs,
    title: TastesNames.turnOffs,
    placeholder: "Enter turn offs",
    value: null,
  },
  {
    name: TastesKeys.fetishes,
    title: TastesNames.fetishes,
    placeholder: "Enter fetishes",
    value: null,
  },
  {
    name: TastesKeys.hobbies,
    title: TastesNames.hobbies,
    placeholder: "Enter hobbies",
    value: null,
  },
  {
    name: TastesKeys.foods,
    title: TastesNames.foods,
    placeholder: "Enter favorite foods",
    value: null,
  },
  {
    name: TastesKeys.musicGenres,
    title: TastesNames.musicGenres,
    placeholder: "Enter favorite music genres",
    value: null,
  },
  {
    name: TastesKeys.movies,
    title: TastesNames.movies,
    placeholder: "Enter favorite movies",
    value: null,
  },
  {
    name: TastesKeys.sports,
    title: TastesNames.sports,
    placeholder: "Enter favorite sports",
    value: null,
  },
  {
    name: TastesKeys.videoGames,
    title: TastesNames.videoGames,
    placeholder: "Enter favorite video games",
    value: null,
  },
  {
    name: TastesKeys.jewelry,
    title: TastesNames.jewelry,
    placeholder: "Enter favorite jewelry",
    value: null,
  },
  {
    name: TastesKeys.lingerie,
    title: TastesNames.lingerie,
    placeholder: "Enter favorite lingerie",
    value: null,
  },
  {
    name: TastesKeys.sexToys,
    title: TastesNames.sexToys,
    placeholder: "Enter favorite sex toys",
    value: null,
  },
  {
    name: TastesKeys.drinks,
    title: TastesNames.drinks,
    placeholder: "Enter favorite drinks",
    value: null,
  },
];
