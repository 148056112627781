import { Box, Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import type { ManualTransfer } from "../../services/sign-in/interfaces/initialize.interface";
import { manualTransfersSlice } from "../../store/reducers/manual-transfers/manual-transfers";
import { currencyData } from "../automatic-transfers/data";
import { CurrenciesEnum } from "../automatic-transfers/enums/currencies.enum";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import Calculation from "./calculation/calculation";
import { TransferForm } from "./transfer-form/transfer-form";
import styles from "./transfers.module.scss";
import { TransferWallets } from "./wallets/transfer-wallets";

function isThirdTransactionThisMonth(manualTransfers: ManualTransfer[]) {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Filter transactions for the current month and year
  const currentMonthTransfers = manualTransfers.filter((transfer) => {
    if (!transfer.created_at) return false;
    const transferDate = new Date(transfer.created_at);
    return (
      !transfer.is_deleted &&
      transferDate.getMonth() === currentMonth &&
      transferDate.getFullYear() === currentYear
    );
  });

  // Count the transactions
  const transactionCount = currentMonthTransfers.length;

  // Determine if the current transaction is going to be the 3rd
  return transactionCount >= 2;
}
export const Transfers = () => {
  const [showNotice, setShowNotice] = useState(false);

  const {
    manual_transfers: manualTransfers,
    available_currencies: availableCurrencies,
  } = useAppSelector((item) => item.manualTransfersReducer);

  const isCurrencyActivated = (curr: CurrenciesEnum) =>
    availableCurrencies.find((item) => item.value === curr);

  const dispatch = useAppDispatch();

  const { setManualTransfersData } = manualTransfersSlice.actions;
  const currency = availableCurrencies.find((item) => {
    const exists = manualTransfers.find(
      (transfer) => transfer.currency === item.value
    );

    if (exists) {
      return false;
    }

    return true;
  })?.value;

  const { transferAccounts } = useAppSelector(
    (item) => item.transferAccountsReducer
  );

  const generateNewTransfer = () => {
    dispatch(
      setManualTransfersData({
        manual_transfers: [
          ...manualTransfers,
          {
            currency: currency || CurrenciesEnum.BTC,
            currency_transfers: [],
            id: uuidv4(),
            balance: "0",
            rest: 0,
          },
        ],
        available_currencies: availableCurrencies,
      })
    );
  };

  useEffect(() => {
    if (isThirdTransactionThisMonth(manualTransfers)) {
      setShowNotice(true);
    } else {
      setShowNotice(false);
    }
  }, [manualTransfers]);

  useEffect(() => {
    generateNewTransfer();
    // check only for transferAccounts because otherwise it gets rewritten to original array
  }, [transferAccounts]);

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.mainCardWrapper}>
          <Box display="flex" gap="16px" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap="32px" width="100%">
              <TransferWallets
                title="Transfer"
                description={
                  <>
                    <span>Make an instant transaction right now. </span>
                    <span style={{ display: "block" }}>
                      <span>Or you can </span>
                      <Link to="/cc/automatic-transfers">
                        set up automatic transfers on a schedule
                      </Link>
                    </span>
                  </>
                }
              />
              <Box>
                {manualTransfers.length > 0 && (
                  <TransferForm
                    manualTransfer={manualTransfers[manualTransfers.length - 1]}
                    generateNewTransfer={generateNewTransfer}
                    showNotice={showNotice}
                    currencyData={currencyData.map((curr) => {
                      if (
                        curr.value ===
                          manualTransfers[manualTransfers.length - 1]
                            ?.currency ||
                        !isCurrencyActivated(curr.value)
                      ) {
                        curr.disabled = true;
                      } else {
                        curr.disabled = false;
                      }

                      return curr;
                    })}
                  />
                )}
              </Box>
            </Box>
            <TransitionGroup
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {manualTransfers.length > 0 && (
                <Collapse>
                  <Calculation
                    data={manualTransfers[manualTransfers.length - 1]}
                  />
                </Collapse>
              )}
            </TransitionGroup>
          </Box>
        </Box>
      </MainWrapper>
    </Box>
  );
};
