import { Box, Typography } from "@mui/material";
import cx from "classnames";
import { FastAverageColor } from "fast-average-color";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { useAppSelector } from "../../../../../../../hooks/redux";
import type { Content } from "../../../../../../../services/catalog/interfaces/catalog.interfaces";
import { Video } from "../../../../../../shared/video/video";
import { useCreatorCatalogCCViewContext } from "../../../context/creator-catalog-cc-view-context";
import styles from "./content-media-presenter.module.scss";

interface ContentMediaProps {
  content: Content | undefined | null;
}

export const ContentMediaPresenter: React.FC<ContentMediaProps> = ({
  content,
}) => {
  const { view } = useCreatorCatalogCCViewContext();

  const [backgroundColors, setBackgroundColors] = useState<(string | null)[]>(
    []
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const { user } = useAppSelector((data) => data.userReducer);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handlePause = () => {
        // Rewind the video to the start
        videoElement.currentTime = 0;
      };

      videoElement.addEventListener("pause", handlePause);

      return () => {
        videoElement.removeEventListener("pause", handlePause);
      };
    }

    // Return undefined when videoElement is not available
    return undefined;
  }, []);

  const toggleAutoPlay = (mediaPlayAutomatically: boolean | undefined) => {
    if (mediaPlayAutomatically) {
      const playPromise = videoRef.current?.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            console.log(_);
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            console.error("video is paused", error);
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    } else {
      videoRef.current?.pause();
    }
  };

  useEffect(() => {
    toggleAutoPlay(user?.media_play_automatically);
  }, [user?.media_play_automatically]);

  const getDominantColor = async (
    fac: FastAverageColor,
    imageUrl: string
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;

      img.onload = () => {
        try {
          const color = fac.getColor(img).hex;
          resolve(color);
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });

  useEffect(() => {
    if (!content?.content_media) return;

    const fetchColors = async () => {
      if (!content?.content_media) return;

      const fac = new FastAverageColor();
      const colors = await Promise.all(
        content.content_media.map(async (item) => {
          try {
            if (!item.media.backend_media_url) {
              return null;
            }
            const dominantColor = await getDominantColor(
              fac,
              item.media.backend_media_url
            );
            return dominantColor;
          } catch (error) {
            // console.error(`Error fetching color for ${item.backend_media_url}:`, error);
            return "#f0f0f0"; // Fallback color
          }
        })
      );
      setBackgroundColors(colors);
    };

    fetchColors();
  }, [content]);

  if (!content?.content_media || content.content_media.length === 0) {
    return (
      <div className={styles.emptyMedia}>
        <div className={styles.placeholder}>content</div>
      </div>
    );
  }

  if (!content.is_collection) {
    const item = content.content_media[0];
    return (
      <div
        className={cx({
          [styles.singleMedia]: view === "tile",
          [styles.singleMediaListView]: view === "list",
        })}
        style={{
          backgroundColor:
            backgroundColors[0] !== null ? backgroundColors[0] : "opacity: 0.9",
        }}
      >
        {content.audience === "me" && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              position: "absolute",
            }}
          />
        )}
        {item.media &&
          item.media.backend_media_url &&
          (item.media?.type?.includes("video") ? (
            <Box className={styles.mediaItem}>
              <Video
                showDuration
                showControls={false}
                videoUrl={item.media.backend_media_url}
                muted={!!user?.media_sound_muted}
                isPlaying={!!user?.media_play_automatically}
              />
            </Box>
          ) : (
            <img
              className={styles.mediaItem}
              src={item.media.backend_media_url}
              alt={item.media.title}
            />
          ))}
      </div>
    );
  }

  return (
    <div
      className={cx({
        [styles.contentMediaWrapper]: view === "tile",
        [styles.contentMediaWrapperListView]: view === "list",
      })}
    >
      <Box
        sx={{
          display: "inline-flex",
          width: "100%",
        }}
      >
        {content.audience === "me" && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              position: "absolute",
              zIndex: 100,
            }}
          />
        )}
        {content.content_media.slice(0, 4).map((item, index) => (
          <div
            key={index}
            className={styles.media}
            style={{
              display: "flex",
              zIndex: 10 - index,
              marginLeft: "0px",
              width: "fit-content",
              position: index === 0 ? "inherit" : "absolute",
              left: index * 27,
              top: index * 19,
              boxShadow: "3px 4px 4px 0px #393B3F40",
            }}
          >
            {item.media &&
              item.media.backend_media_url &&
              (item.media?.type?.includes("video") ? (
                <Box
                  className={styles.mediaItem}
                  sx={{
                    width: "264px",
                  }}
                >
                  <Video
                    showDuration
                    showControls={false}
                    videoUrl={item.media.backend_media_url}
                    muted
                    isPlaying={false}
                  />
                </Box>
              ) : (
                <img
                  className={styles.mediaItem}
                  src={item.media.backend_media_url}
                  alt={item.media.title}
                  style={{
                    width: "264px",
                  }}
                />
              ))}
          </div>
        ))}
      </Box>
      {content.content_media && content.content_media.length > 4 && (
        <div
          className={cx({
            [styles.overlay]: view === "tile",
            [styles.overlayListView]: view === "list",
          })}
        >
          <Typography fontSize="26px">
            +{content.content_media.length - 4}
          </Typography>
        </div>
      )}
    </div>
  );
};
