import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { ISubscribersList } from "../../../components/manage-subscribers/my-subscribers/interfaces/my-subscribers-list.interface";
import type { IGroupSubscriber } from "../../../services/manage-subscribers/interfaces/fetch-group-subscribers.interface";

interface IManageSubscribersModal {
  subscribers: ISubscribersList[];
  groupSubscribers: IGroupSubscriber[];
}

const initialState: IManageSubscribersModal = {
  subscribers: [],
  groupSubscribers: [],
};

export const manageSubscribersModalSlice = createSlice({
  name: "manageSubscribersModal",
  initialState,
  reducers: {
    setManageSubscribers(state, action: PayloadAction<ISubscribersList[]>) {
      state.subscribers = action.payload;
    },
    setGroupSubscribers(state, action: PayloadAction<IGroupSubscriber[]>) {
      state.groupSubscribers = action.payload;
    },
  },
});

export default manageSubscribersModalSlice.reducer;
