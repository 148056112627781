import type { FC } from "react";

type WalletIconProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const WalletIcon: FC<WalletIconProp> = ({
  width = 32,
  height = 32,
  disabled = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.66699 11.333C4.21928 11.333 4.66699 10.8853 4.66699 10.333C4.66699 9.78072 4.21928 9.33301 3.66699 9.33301C3.11471 9.33301 2.66699 9.78072 2.66699 10.333C2.66699 10.8853 3.11471 11.333 3.66699 11.333Z"
      fill={`${disabled ? "#909294" : "#242424"}`}
    />
    <path
      d="M12.6667 2H3.33333C2.4496 2.00106 1.60237 2.35259 0.97748 2.97748C0.352588 3.60237 0.00105857 4.4496 0 5.33333L0 10.6667C0.00105857 11.5504 0.352588 12.3976 0.97748 13.0225C1.60237 13.6474 2.4496 13.9989 3.33333 14H12.6667C13.5504 13.9989 14.3976 13.6474 15.0225 13.0225C15.6474 12.3976 15.9989 11.5504 16 10.6667V5.33333C15.9989 4.4496 15.6474 3.60237 15.0225 2.97748C14.3976 2.35259 13.5504 2.00106 12.6667 2ZM3.33333 3.33333H12.6667C13.1971 3.33333 13.7058 3.54405 14.0809 3.91912C14.456 4.29419 14.6667 4.8029 14.6667 5.33333H1.33333C1.33333 4.8029 1.54405 4.29419 1.91912 3.91912C2.29419 3.54405 2.8029 3.33333 3.33333 3.33333ZM12.6667 12.6667H3.33333C2.8029 12.6667 2.29419 12.456 1.91912 12.0809C1.54405 11.7058 1.33333 11.1971 1.33333 10.6667V6.66667H14.6667V10.6667C14.6667 11.1971 14.456 11.7058 14.0809 12.0809C13.7058 12.456 13.1971 12.6667 12.6667 12.6667Z"
      fill={`${disabled ? "#909294" : "#242424"}`}
    />
  </svg>
);
