import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  ClickAwayListener,
  MenuList,
  Popper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import type * as React from "react";
import { useRef, useState } from "react";

import styles from "./select.module.scss";

export type DefaultSelectOptions = {
  value: string | number;
  display_text: string | number;
  start_component?: React.JSX.Element;
  end_compoent?: React.JSX.Element;
  disabled?: boolean;
};

type SelectProps<T extends DefaultSelectOptions> = {
  value: string;
  onChange: (value: T) => void;
  options: T[];
  name: string;
  disabled?: boolean;
  placeholder?: string;
};

export default function DefaultSelect<
  T extends DefaultSelectOptions = DefaultSelectOptions,
>(props: SelectProps<T>) {
  const {
    value,
    options,
    onChange,
    name,
    disabled = false,
    placeholder,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const renderValue = () =>
    options.filter((opt) => opt.value === value)[0]?.display_text ? (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="5px"
        >
          {options.filter((opt) => opt.value === value)[0]?.start_component}
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {options.filter((opt) => opt.value === value)[0]?.display_text}
          </span>
          {options.filter((opt) => opt.value === value)[0]?.end_compoent}
        </Box>
      </>
    ) : (
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          color: "rgba(170, 170, 170, 1)",
        }}
      >
        {placeholder}
      </span>
    );

  return (
    <Box width="100%">
      <FormControl fullWidth>
        <Button
          variant="outlined"
          ref={anchorRef}
          onClick={handleToggle}
          name={name}
          disabled={disabled}
          disableRipple
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            background: "#fff",
            padding: "7px 6px 7px 9px",
            color: "black",
            borderRadius: "8px",
            border: "1px solid rgba(199, 216, 222, 1)",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.59)",
              border: "1px solid rgb(177, 202, 222)",
            },

            "&:disabled": {
              color: "black",
              background: "rgba(213,213,213,0.3)",
              border: "1px solid rgba(170, 170, 170, 1);",
            },
          }}
        >
          <Typography variant="button">{renderValue()}</Typography>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </Button>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{
            zIndex: 9999,
            width: anchorRef.current
              ? anchorRef.current.clientWidth
              : undefined,
          }}
        >
          <Box className={styles.select}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                sx={{
                  width: "100%",
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={styles.menuItem}
                    disabled={option.disabled}
                    onClick={(event) => {
                      onChange(option);
                      handleClose(event);
                    }}
                    sx={{
                      width: "100%",
                      "&:hover": {
                        background: "#ced4da",
                      },
                    }}
                  >
                    {option.start_component && option.start_component}
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {option.display_text}
                    </span>
                    {option.end_compoent && option.end_compoent}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Box>
        </Popper>
      </FormControl>
    </Box>
  );
}
