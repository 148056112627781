import { Box, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { useState } from "react";

import { InfoIcon } from "../../../../media/icons/info";
import { MaterialUISwitch } from "../../material-ui-switch/material-ui-switch";

type SwitchWithTooltipProps = {
  checked?: boolean;
  disabled: boolean;
  onChange: () => void;
  isTooltipNeeded: boolean;
};

export const SwitchWithTooltip = ({
  checked,
  disabled,
  onChange,
  isTooltipNeeded,
}: SwitchWithTooltipProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (isTooltipNeeded) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Box>
            <InfoIcon width={22} height={22} />
          </Box>
          <Typography>
            Note! Deselecting all wallets is not possible.
          </Typography>
        </Box>
      }
      disableFocusListener
      placement="top-end"
      arrow
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top-end"] .${tooltipClasses.tooltip}`]:
              {
                right: -15,
                bottom: 15,
                width: "240px",
                padding: "10px",
                marginBottom: "7px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                color: "rgba(76, 75, 76, 1)",
                fontSize: "14px",
                boxShadow: "0px 5px 8px 0px rgba(95, 92, 84, 0.2)",
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top-end"] .${tooltipClasses.arrow}`]:
              {
                bottom: -8,
                marginLeft: "-8px",
                width: "10px",
                height: "20px",
                borderLeft: "13px solid transparent",
                borderRight: "13px solid transparent",
                borderTop: "20px solid rgba(255, 255, 255, 1)",
                "&::before": {
                  display: "none",
                },
              },
          },
        },
      }}
    >
      <MaterialUISwitch
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        sx={{ m: 1, ml: 3 }}
      />
    </Tooltip>
  );
};
