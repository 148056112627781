import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import cx from "classnames";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import { BitcoinTextIcon } from "../../../../../../media/icons/bitcoin-text";
import ContentLikeIcon from "../../../../../../media/icons/catalog/content-card/content-like-icon";
import PurchasesIcon from "../../../../../../media/icons/catalog/content-card/purchases-icon";
import SettingsIcon from "../../../../../../media/icons/catalog/content-card/settings-icon";
import ViewesIcon from "../../../../../../media/icons/catalog/content-card/viewes-icon";
import { useRemoveFromFolderMutation } from "../../../../../../services/catalog/catalog.service";
import type { Content } from "../../../../../../services/catalog/interfaces/catalog.interfaces";
import {
  AudienceEnum,
  AudienceValuesEnum,
} from "../../../../../../services/catalog/interfaces/request-models.interfaces";
import { CurrenciesEnum } from "../../../../../automatic-transfers/enums/currencies.enum";
import { useCreatorCatalogCCViewContext } from "../../context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../../context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { DeleteContentConfirmation } from "../../modals/delete-content-confirmation/delete-content-confirmation";
import styles from "./content-card.module.scss";
import { ContentMediaPresenter } from "./content-media/content-media-presenter";

interface ContentCardProps {
  content: Content;
  onAddToCollection: (content: Content) => void;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  content,
  onAddToCollection,
}) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const {
    setSelectedCreatorContentItems,
    selectedCreatorContentItems,
    setContentEdit,
    setPostsToDelete,
    updateContent,
    setCollectionEdit,
    setSelectedItemsForCollection,
    selectedItemsForCollection,
    fetchContent,
    view,
  } = useCreatorCatalogCCViewContext();

  const { setSelectedContentToFolder, currentOpenedFolder } =
    useFolderCreatorCatalogCCViewContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDeleteContentDialogOpened, setIsDeleteContentDialogOpened] =
    useState<boolean>(false);
  const renderAvailableDate = () => {
    const currentDate = dayjs();
    const futureDate = currentDate.add(content.visible_days || 0, "day");
    return futureDate.format("MM/DD/YYYY");
  };

  const [removeFromFolder, { isSuccess: removeFromFolderSuccess }] =
    useRemoveFromFolderMutation();

  const removeContentFromFolder = useCallback(async () => {
    if (currentOpenedFolder) {
      await removeFromFolder({
        accessToken: userAccessToken || "",
        content_id: content.id,
        folderId: currentOpenedFolder.id,
      });
    }
  }, [currentOpenedFolder, removeFromFolder, content, userAccessToken]);

  useEffect(() => {
    if (removeFromFolderSuccess) {
      fetchContent();
    }
  }, [removeFromFolderSuccess]);

  // Start popover handling
  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCopyPostLink = (contentId: string) => {
    setAnchorEl(null);
    const { protocol, host } = window.location;
    const domain = `${protocol}//${host}`;

    navigator.clipboard.writeText(`${domain}/home?contentId=${contentId}`);
  };

  const handleAddPostToCollection = (contentToAdd: Content) => {
    setAnchorEl(null);
    onAddToCollection(contentToAdd);
  };

  const handleRemovePostFromFolder = () => {
    setAnchorEl(null);
    removeContentFromFolder();
  };

  const handleEditPost = (contentToEdit: Content) => {
    setAnchorEl(null);

    if (contentToEdit.is_collection) {
      setCollectionEdit(contentToEdit);
    } else {
      setContentEdit([contentToEdit]);
    }
  };

  const handleUnpublishPost = (contentToUnpublish: Content) => {
    setAnchorEl(null);

    if (contentToUnpublish.audience === AudienceValuesEnum.me) {
      return;
    }

    updateContent({
      contentIds: [contentToUnpublish.id],
      accessToken: userAccessToken || "",
      formData: {
        audience: {
          audience: AudienceValuesEnum.me,
          ids: [],
        },
      },
      folder_id: currentOpenedFolder?.id || null,
    });
  };

  const handleAddToFolderPost = () => {
    setAnchorEl(null);
    setSelectedContentToFolder(content);
  };

  const handleDeletePost = () => {
    setAnchorEl(null);
    setIsDeleteContentDialogOpened(true);
    setPostsToDelete((prevState) => [...prevState, content.id]);
  };
  // End popover handling

  const handleCheckboxClick = (selectedContent: Content) => {
    setSelectedItemsForCollection([]);
    setSelectedCreatorContentItems((prevState) => {
      const isItemExist = prevState.find(
        (item) => item.id === selectedContent.id
      );

      if (!isItemExist) {
        return [...prevState, selectedContent];
      }

      return prevState.filter((item) => item.id !== selectedContent.id);
    });
  };

  const isContentChecked = !!selectedCreatorContentItems.find(
    (item) => item.id === content.id
  );

  const isContentSelectedToCollectionChecked =
    !!selectedItemsForCollection.find((item) => item.id === content.id);

  const isCheckboxSelected =
    isContentChecked || isContentSelectedToCollectionChecked;

  const renderPrice = (
    price: number,
    currency: CurrenciesEnum,
    signColor: string
  ) => {
    switch (currency) {
      case CurrenciesEnum.USD: {
        return `$${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.EURO: {
        return `€${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.BTC: {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1px",
              }}
            >
              <BitcoinTextIcon color={signColor} width={18} height={18} />
              <span style={{ display: "block" }}>
                {price.toFixed(5).toString()}
              </span>
            </Box>
          </>
        );
      }
      default: {
        return "";
      }
    }
  };

  return (
    <>
      <div
        className={cx({
          [styles.contentCardWrapper]: view === "tile",
          [styles.contentCardWrapperListView]: view === "list",
        })}
      >
        <Box
          className={cx({
            [styles.header]: view === "tile",
            [styles.headerListView]: view === "list",
          })}
        >
          <Box className={styles.checkboxWrapper}>
            <Checkbox
              name="selectContent"
              checked={isCheckboxSelected}
              onChange={() => handleCheckboxClick(content)}
            />
            {view === "tile" && (
              <Typography className={styles.title}>{content.name}</Typography>
            )}
          </Box>

          {view === "tile" && (
            <IconButton
              aria-describedby={id}
              onClick={handleOpenPopover}
              disabled={selectedCreatorContentItems.length !== 0}
              sx={{
                visibility:
                  selectedCreatorContentItems.length === 0
                    ? "visible"
                    : "hidden",
              }}
            >
              <SettingsIcon />
            </IconButton>
          )}
        </Box>
        <Box
          className={styles.bodyContentWrapper}
          onClick={() => {
            handleEditPost(content);
          }}
        >
          <ContentMediaPresenter content={content} />
        </Box>
        <Box
          className={cx({
            [styles.footer]: view === "tile",
            [styles.footerListView]: view === "list",
          })}
        >
          {view === "list" && (
            <Typography className={styles.title}>{content.name}</Typography>
          )}
          <Box className={styles.footerFirstRow}>
            {content.price !== 0 && (
              <Typography fontWeight="bold" variant="body1" color="#252733">
                <>{renderPrice(content.price, content.currency, "#252733")}</>
              </Typography>
            )}
            {content.price === 0 && (
              <Typography variant="body1" color="#AAAAAA">
                Free
              </Typography>
            )}
            {content.is_collection && (
              <Typography className={styles.collectionLabel} color="#AAAAAA">
                Collection
              </Typography>
            )}
          </Box>
          <Box
            className={cx({
              [styles.footerSecondRow]: view === "tile",
              [styles.footerSecondRowListView]: view === "list",
            })}
          >
            {content.audience && (
              <Typography variant="body1" color="#AAAAAA">
                {AudienceEnum[content.audience]}
              </Typography>
            )}

            {content.visible ? (
              <Typography variant="body1" color="#AAAAAA">
                Visible Now
              </Typography>
            ) : (
              <Typography variant="body1" color="#AAAAAA">
                Visible On {dayjs(content.scheduled_at).format("MM/DD/YYYY")}
              </Typography>
            )}

            {content.visible_days && (
              <Typography variant="body1" color="#AAAAAA">
                Available till {renderAvailableDate()}
              </Typography>
            )}
          </Box>
          <Box className={styles.footerThirdRow}>
            <div className={styles.categoryStatistic}>
              <ViewesIcon />
              <Typography color="#AAAAAA" variant="body1">
                {content._count.viewsPerContents}
              </Typography>
            </div>
            <div className={styles.categoryStatistic}>
              <ContentLikeIcon />
              <Typography color="#AAAAAA" variant="body1">
                {content._count.user_likes}
              </Typography>
            </div>
            {content.price > 0 ||
            (content.price === 0 &&
              content._count.contentPurchaseAmount > 0) ? (
              <>
                <div className={styles.categoryStatistic}>
                  <PurchasesIcon />
                  <Typography color="#AAAAAA" variant="body1">
                    {content._count.contentPurchaseAmount}
                  </Typography>
                </div>
                {content._count.earnings
                  .filter((item) => item.amount > 0.000001)
                  .map((earning) => (
                    <div
                      key={earning.currency}
                      className={styles.categoryStatistic}
                    >
                      <Typography color="#AAAAAA" variant="body1">
                        {renderPrice(
                          earning.amount,
                          earning.currency,
                          "#AAAAAA"
                        )}
                      </Typography>
                    </div>
                  ))}
              </>
            ) : null}
          </Box>
        </Box>

        {view === "list" && (
          <IconButton
            aria-describedby={id}
            onClick={handleOpenPopover}
            disabled={selectedCreatorContentItems.length !== 0}
            sx={{
              visibility:
                selectedCreatorContentItems.length === 0 ? "visible" : "hidden",
              alignSelf: "flex-start",
              position: "absolute",
              top: "2px",
              right: "2px",
            }}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "237px",
          }}
        >
          {currentOpenedFolder ? (
            <Button
              type="button"
              variant="text"
              sx={{
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={handleRemovePostFromFolder}
            >
              <Typography variant="button" color="#252733">
                Remove from folder
              </Typography>
            </Button>
          ) : (
            <Button
              type="button"
              variant="text"
              sx={{
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                handleCopyPostLink(content.id);
              }}
            >
              <Typography variant="button" color="#252733">
                Copy post link
              </Typography>
            </Button>
          )}

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={() => {
              handleAddPostToCollection(content);
            }}
          >
            <Typography variant="button" color="#252733">
              Add to Collection
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={() => handleEditPost(content)}
          >
            <Typography variant="button" color="#252733">
              Edit post
            </Typography>
          </Button>

          {content.audience !== AudienceValuesEnum.me && (
            <Button
              type="button"
              variant="text"
              sx={{
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => handleUnpublishPost(content)}
            >
              <Typography variant="button" color="#252733">
                Unpublish
              </Typography>
            </Button>
          )}

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleAddToFolderPost}
          >
            <Typography variant="button" color="#252733">
              Add to folder
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",

              "&.Mui-disabled": {
                backgroundColor: "rgb(238 238 238)",
              },
            }}
            disabled={content._count.contentPurchaseAmount > 0}
            onClick={handleDeletePost}
          >
            <Typography variant="button" color="#FF5252">
              Delete post
            </Typography>
          </Button>
        </Box>
      </Popover>
      <DeleteContentConfirmation
        isOpen={isDeleteContentDialogOpened}
        onCancelClick={() => {
          if (content) {
            setPostsToDelete((prevState) =>
              prevState.filter((idToDelete) => idToDelete !== content.id)
            );
            setIsDeleteContentDialogOpened(false);
          }
        }}
      />
    </>
  );
};
