import { Box } from "@mui/material";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";

import { useAppSelector } from "../../../../../hooks/redux";
import ArrowLeftIcon from "../../../../../media/icons/creator-card/arrow-left";
import ArrowRightIcon from "../../../../../media/icons/creator-card/arrow-right";
import { ContentMedia } from "../../../../../services/catalog/interfaces/catalog.interfaces";
import { GreetingMedia } from "../../../../creator-card/greetings-carousel/greeting-media.tsx/greeting-media";
import { GreetingVideoContextProvider } from "../../../../creator-card/greetings-carousel/greeting-media.tsx/gretting-content/greeting-video-context";
import styles from "../../../../creator-card/greetings-carousel/greetings-carousel.module.scss";

interface IContentCarouselProps {
  media: ContentMedia[];
  toggleGreetings?: boolean;
  isMediaActive?: boolean;
}

export const ContentCarousel: FC<IContentCarouselProps> = ({
  media,
  toggleGreetings,
  isMediaActive = true,
}) => {
  const { user } = useAppSelector((item) => item.userReducer);
  const MAX_DOTS_COUNT: number = 5;

  const [currentMedia, setCurrentMedia] = useState<ContentMedia | null>(
    media[0]
  );

  useEffect(() => {
    setCurrentMedia(media[0]);
  }, [media]);

  const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

  const dotsCount = Math.min(MAX_DOTS_COUNT, media.length);

  const setPreviousGreeting = () => {
    if (isMediaActive) {
      const previousGreetingIndex =
        media.findIndex((item) => item === currentMedia) - 1;
      if (previousGreetingIndex >= 0) {
        setCurrentMedia(media[previousGreetingIndex]);
        setCurrentMediaIndex(previousGreetingIndex);
      }
    }
  };

  const setNextGreeting = () => {
    if (isMediaActive) {
      const nextGreetingIndex =
        media.findIndex((item) => item === currentMedia) + 1;

      if (nextGreetingIndex !== 0 && nextGreetingIndex < media.length) {
        setCurrentMedia(media[nextGreetingIndex]);
        setCurrentMediaIndex(nextGreetingIndex);
      }
    }
  };

  const isActiveDot = (index: number) => {
    let isActive = false;
    if (media.length > dotsCount && currentMediaIndex >= dotsCount - 1) {
      isActive =
        currentMediaIndex === media.length
          ? index === dotsCount - 1
          : index === dotsCount - 2;
    } else {
      isActive = currentMediaIndex === index;
    }
    return isActive;
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {currentMedia?.media && (
        <Box>
          <GreetingVideoContextProvider
            showControls
            muted={!!user?.media_sound_muted}
            isPlaying={!!user?.media_play_automatically}
          >
            <GreetingMedia
              isMediaActive={isMediaActive}
              type={currentMedia.media.type}
              mediaUrl={currentMedia.media.backend_media_url || ""}
              isDominantColorEnabled
            />
          </GreetingVideoContextProvider>
        </Box>
      )}
      {toggleGreetings && (
        <Box className={styles.grettingNavigationButtons}>
          <button
            type="button"
            disabled={currentMediaIndex === 0}
            className={styles.navigationButtonStyle}
            onClick={setPreviousGreeting}
          >
            <ArrowLeftIcon />
          </button>
          <div className={styles.carouselDots}>
            {Array.from(Array(dotsCount).keys()).map((i) =>
              currentMediaIndex !== media.length - 1 || i < dotsCount - 1 ? (
                <button
                  type="button"
                  key={i}
                  className={classNames(
                    styles.dot,
                    isActiveDot(i) && styles.active,
                    !isActiveDot(i) &&
                      i === dotsCount - 1 &&
                      currentMediaIndex !== media.length - 1 &&
                      styles.smallDot
                  )}
                />
              ) : null
            )}
          </div>
          <button
            type="button"
            disabled={currentMediaIndex === media.length - 1}
            className={styles.navigationButtonStyle}
            onClick={setNextGreeting}
          >
            <ArrowRightIcon />
          </button>
        </Box>
      )}
    </Box>
  );
};
