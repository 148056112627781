import type { ITableHead } from "../../../../interfaces/table-head.interface";

export const transactionHistoryTableHeads: ITableHead[] = [
  {
    display_text: "Date",
    value: "created_at",
    sortable: true,
    default_sort: true,
    headWidth: 11,
  },
  {
    display_text: "Type",
    value: "type",
    sortable: false,
    headWidth: 20,
  },
  {
    display_text: "User",
    value: "username",
    sortable: false,
    headWidth: 23,
  },
  {
    display_text: "Content",
    value: "content",
    sortable: false,
    headWidth: 23,
  },
  {
    display_text: "Currency",
    value: "currency",
    sortable: true,
    headWidth: 17,
  },
  {
    display_text: "Amount",
    value: "amount",
    sortable: true,
    headWidth: 11,
  },
];
