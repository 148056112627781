import { MenuItem, Select } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

import { BtcLogo } from "../../../../media/icons/btc";
import { DollarLogo } from "../../../../media/icons/dollar";
import { EuroLogo } from "../../../../media/icons/euro";
import { CurrenciesEnum } from "../../../automatic-transfers/enums/currencies.enum";

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

interface ICurrencySelectProp {
  value: CurrenciesEnum;
  onValueUpdate: (currency: CurrenciesEnum) => void;
}

export const CurrencySelect: FC<ICurrencySelectProp> = ({
  value,
  onValueUpdate,
}) => {
  const handleChange = (event: any) => {
    onValueUpdate(event.target.value);
  };

  const renderValue = (selected: CurrenciesEnum) => {
    switch (selected) {
      case "btc":
        return (
          <MenuItemWrapper>
            <IconWrapper>
              <BtcLogo />
            </IconWrapper>
            BTC
          </MenuItemWrapper>
        );
      case "usd":
        return (
          <MenuItemWrapper>
            <IconWrapper>
              <DollarLogo />
            </IconWrapper>
            USD
          </MenuItemWrapper>
        );
      case "euro":
        return (
          <MenuItemWrapper>
            <IconWrapper>
              <EuroLogo />
            </IconWrapper>
            EUR
          </MenuItemWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      displayEmpty
      renderValue={renderValue}
    >
      <MenuItem value="euro">
        <MenuItemWrapper>
          <IconWrapper>
            <EuroLogo />
          </IconWrapper>
          EURO
        </MenuItemWrapper>
      </MenuItem>
      <MenuItem value="usd">
        <MenuItemWrapper>
          <IconWrapper>
            <DollarLogo />
          </IconWrapper>
          USD
        </MenuItemWrapper>
      </MenuItem>
      <MenuItem value="btc">
        <MenuItemWrapper>
          <IconWrapper>
            <BtcLogo />
          </IconWrapper>
          BTC
        </MenuItemWrapper>
      </MenuItem>
    </Select>
  );
};
