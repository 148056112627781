import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { ExitIcon } from "../../../../../media/icons/exit";
import { FollowIcon } from "../../../../../media/icons/follow";
import { GearIcon } from "../../../../../media/icons/gear";
import { HeadsetIcon } from "../../../../../media/icons/headset";
import { HelpIcon } from "../../../../../media/icons/help";
import { ProfileIcon } from "../../../../../media/icons/profile";
import { WalletIcon } from "../../../../../media/icons/wallet";
import styles from "../avatar-menu/avatar-menu.module.scss";

export const SubscriberMenu = () => {
  const navigate = useNavigate();
  const btnStyles = {
    padding: "8px 16px 6px 12px",
    borderRadius: 0,
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  };

  const textStyle = {
    color: "black",
  };

  const onSignOut = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    navigate("/sign-in");
  };

  return (
    <div className={styles.creator}>
      <Button
        sx={btnStyles}
        startIcon={<ProfileIcon width={16} height={16} color="black" />}
      >
        <span style={textStyle}> Subscriber profile</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<WalletIcon width={16} height={16} />}
        onClick={() => navigate("/payment-info")}
      >
        <span style={textStyle}>Payment info</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<FollowIcon width={16} height={16} />}
        onClick={() => navigate("/cs/subscriptions")}
      >
        <span style={textStyle}>Subscriptions</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<GearIcon width={16} height={16} />}
        onClick={() => navigate("/cs/settings")}
      >
        <span style={textStyle}>Settings</span>
      </Button>
      <Button sx={btnStyles} startIcon={<HelpIcon width={16} height={16} />}>
        <span style={textStyle}>Need help?</span>
      </Button>
      <Button sx={btnStyles} startIcon={<HeadsetIcon width={16} height={16} />}>
        <span style={textStyle}>Contact us</span>
      </Button>
      <Button
        sx={btnStyles}
        startIcon={<ExitIcon width={16} height={16} />}
        onClick={onSignOut}
      >
        <span style={textStyle}>Sign out</span>
      </Button>
    </div>
  );
};
