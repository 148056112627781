import { useState } from "react";
import { Link as LinkNav } from "react-router-dom";

import { RoleEnum } from "../../enums/role.enum";
import AgeVerification from "../age-verification/age-verification";
import AddPaymentMethod from "../select-payment-method/select-payment-method";
import { SignUp } from "./sign-up";
import type {
  AgeVerificationType,
  AgreementType,
  ConfirmEmailType,
  CreateEmailType,
  CreateUsernameType,
  PasswordType,
  PlayAutomaticallyType,
  PurchaseContentType,
  RoleType,
  SoundMutedType,
} from "./types/sign-up.types";

export default function SignUpWrapper() {
  const [ageVerificationStep, setAgeVerificationStep] =
    useState<boolean>(false);
  const [inquiryId, setInquiryId] = useState("");

  const [ageVerification, setAgeVerification] = useState<AgeVerificationType>({
    status: "none",
    value: false,
  });

  const [username, setUsername] = useState<CreateUsernameType>({
    status: "none",
    value: "",
  });

  const [email, setEmail] = useState<CreateEmailType>({
    status: "none",
    value: "",
  });

  const [confirmEmail, setConfirmEmail] = useState<ConfirmEmailType>({
    status: "none",
    value: "",
  });

  const [password, setPassword] = useState<PasswordType>({
    status: "none",
    value: "",
  });

  const [role, setRole] = useState<RoleType>({
    status: "none",
    value: RoleEnum.undefined,
    isLater: false,
  });

  const [agreement, setAgreement] = useState<AgreementType>({
    status: "none",
    value: false,
  });

  const [purchaseContent, setPurchaseContent] = useState<PurchaseContentType>({
    status: "none",
    value: false,
    isLater: false,
  });

  const [soundMuted, setSoundMuted] = useState<SoundMutedType>({
    status: "none",
    value: false,
  });

  const [selectedStep, setSelectedStep] = useState("");
  const [collectedData, setCollectedData] = useState({});
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);

  const [playAutomatically, setPlayAutomatically] =
    useState<PlayAutomaticallyType>({
      status: "none",
      value: false,
    });

  const ageVerificationBackButton = () => (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <LinkNav
        to="/sign-up"
        style={{ color: "#0F70CA" }}
        onClick={() => {
          setAgeVerificationStep(false);
        }}
      >
        Back to Sign Up
      </LinkNav>
    </>
  );

  return ageVerificationStep ? (
    <AgeVerification
      setAgeVerification={setAgeVerification}
      setAgeVerificationStep={setAgeVerificationStep}
      setInquiryId={setInquiryId}
      backBtn={ageVerificationBackButton()}
    />
  ) : (
    <>
      <SignUp
        inquiryId={inquiryId}
        setAgeVerificationStep={setAgeVerificationStep}
        setAgeVerification={setAgeVerification}
        ageVerification={ageVerification}
        agreement={agreement}
        confirmEmail={confirmEmail}
        email={email}
        password={password}
        playAutomatically={playAutomatically}
        purchaseContent={purchaseContent}
        role={role}
        setAgreement={setAgreement}
        setConfirmEmail={setConfirmEmail}
        setEmail={setEmail}
        setPassword={setPassword}
        setPlayAutomatically={setPlayAutomatically}
        setPurchaseContent={setPurchaseContent}
        setRole={setRole}
        setSoundMuted={setSoundMuted}
        setUsername={setUsername}
        soundMuted={soundMuted}
        username={username}
        setOpenPaymentMethod={setOpenPaymentMethod}
        collectedData={collectedData}
        setCollectedData={setCollectedData}
        setSelectedStep={setSelectedStep}
        setSelectedMethods={setSelectedMethods}
      />
      <AddPaymentMethod
        collectedData={collectedData}
        selectedMethods={selectedMethods}
        setCollectedData={setCollectedData}
        setSelectedMethods={setSelectedMethods}
        setSelectedStep={setSelectedStep}
        selectedStep={selectedStep}
        onClose={() => setOpenPaymentMethod(false)}
        open={openPaymentMethod}
        signUp
      />
    </>
  );
}
