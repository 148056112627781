import { Box } from "@mui/material";
import { FC } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

import { IPersonalInfo } from "../../../services/settings/interfaces/settings.interface";
import styles from "./profile-description.module.scss";

const CustomQuill = styled(ReactQuill)`
  background-color: transparent;
  border-color: transparent;

  .ql-toolbar {
    display: none;
  }

  div > .ql-editor {
    padding: 0;
    color: #3c3c3c;
    font-family: Poppins, serif;
  }
`;

interface IProfileDescriptionProps {
  personalInfoParameters: IPersonalInfo;
}

export const ProfileDescription: FC<IProfileDescriptionProps> = ({
  personalInfoParameters,
}) => (
  <>
    {personalInfoParameters?.description && (
      <Box
        sx={{
          maxHeight: "250px",
        }}
      >
        <CustomQuill
          theme="snow"
          value={personalInfoParameters?.description}
          modules={{ toolbar: true }}
          className={styles.textAreaDescription}
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
          readOnly
        />
      </Box>
    )}
  </>
);
