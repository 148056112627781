import type { FC, PropsWithChildren } from "react";

interface ITileIconProp {
  width?: number;
  height?: number;
  color?: string;
}
export const TileIcon: FC<PropsWithChildren<ITileIconProp>> = ({
  width = 32,
  height = 32,
  color = "#AAAAAA",
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 7.05H1.3C0.850003 7.05 0.5 6.7 0.5 6.25V1.3C0.5 0.850003 0.850003 0.5 1.3 0.5H6.25C6.7 0.5 7.05 0.850003 7.05 1.3V6.25C7.05 6.7 6.7 7.05 6.25 7.05Z"
        fill={color}
      />
      <path
        d="M14.4531 7.05H9.50313C9.05313 7.05 8.70312 6.7 8.70312 6.25V1.3C8.70312 0.850003 9.05313 0.5 9.50313 0.5H14.4531C14.9031 0.5 15.2531 0.850003 15.2531 1.3V6.25C15.3031 6.7 14.9031 7.05 14.4531 7.05Z"
        fill={color}
      />
      <path
        d="M22.7031 7.05H17.7531C17.3031 7.05 16.9531 6.7 16.9531 6.25V1.3C16.9531 0.850003 17.3031 0.5 17.7531 0.5H22.7031C23.1531 0.5 23.5031 0.850003 23.5031 1.3V6.25C23.5031 6.7 23.1531 7.05 22.7031 7.05Z"
        fill={color}
      />
      <path
        d="M6.25 15.3H1.3C0.850003 15.3 0.5 14.95 0.5 14.5V9.55C0.5 9.1 0.850003 8.75 1.3 8.75H6.25C6.7 8.75 7.05 9.1 7.05 9.55V14.5C7.05 14.9 6.7 15.3 6.25 15.3Z"
        fill={color}
      />
      <path
        d="M14.4531 15.3H9.50313C9.05313 15.3 8.70312 14.95 8.70312 14.5V9.55C8.70312 9.1 9.05313 8.75 9.50313 8.75H14.4531C14.9031 8.75 15.2531 9.1 15.2531 9.55V14.5C15.3031 14.9 14.9031 15.3 14.4531 15.3Z"
        fill={color}
      />
      <path
        d="M22.7031 15.3H17.7531C17.3031 15.3 16.9531 14.95 16.9531 14.5V9.55C16.9531 9.1 17.3031 8.75 17.7531 8.75H22.7031C23.1531 8.75 23.5031 9.1 23.5031 9.55V14.5C23.5031 14.9 23.1531 15.3 22.7031 15.3Z"
        fill={color}
      />
      <path
        d="M6.25 23.5031H1.3C0.850003 23.5031 0.5 23.1531 0.5 22.7031V17.7531C0.5 17.3031 0.850003 16.9531 1.3 16.9531H6.25C6.7 16.9531 7.05 17.3031 7.05 17.7531V22.7031C7.05 23.1531 6.7 23.5031 6.25 23.5031Z"
        fill={color}
      />
      <path
        d="M14.4531 23.5031H9.50313C9.05313 23.5031 8.70312 23.1531 8.70312 22.7031V17.7531C8.70312 17.3031 9.05313 16.9531 9.50313 16.9531H14.4531C14.9031 16.9531 15.2531 17.3031 15.2531 17.7531V22.7031C15.3031 23.1531 14.9031 23.5031 14.4531 23.5031Z"
        fill={color}
      />
      <path
        d="M22.7031 23.5031H17.7531C17.3031 23.5031 16.9531 23.1531 16.9531 22.7031V17.7531C16.9531 17.3031 17.3031 16.9531 17.7531 16.9531H22.7031C23.1531 16.9531 23.5031 17.3031 23.5031 17.7531V22.7031C23.5031 23.1531 23.1531 23.5031 22.7031 23.5031Z"
        fill={color}
      />
    </svg>
  </>
);
