import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { VisibilityOff } from "../../media/icons/visibility-off";
import { useSignInMutation } from "../../services/sign-in/sign-in.service";
import { UnauthorizedWrapper } from "../shared/unauthorized-wrapper/unauthorized-wrapper";
import type { SignInInterface } from "./interfaces/sign-in.interface";
import styles from "./sign-in.module.scss";
import TemporaryLockout from "./temporary-lockout/temporary-lockout";

export default function SignIn() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [block, setBlocked] = useState(false);
  const [expiresAt, setExpiresAt] = useState("");
  const [isServerError, setIsServerError] = useState(false);

  const [signInSlice, { isLoading }] = useSignInMutation();
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const searchParams = new URLSearchParams(decodeURIComponent(search));
  const path = searchParams.get("returnTo");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("You must enter an email.")
        .max(255),
      password: Yup.string().required("You must enter a password."),
    }),
    onSubmit: async (
      values: SignInInterface,
      helpers: FormikHelpers<SignInInterface>
    ) => {
      try {
        if (isServerError) {
          helpers.resetForm();
          setIsServerError(false);

          return;
        }
        if (isLoading) {
          return;
        }

        const { accessToken } = await signInSlice({
          email: values.email,
          password: values.password,
        }).unwrap();

        if (accessToken) {
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
            `Bearer ${accessToken}`
          );

          if (path) {
            navigate(path);

            return;
          }

          navigate("/home");
        }
      } catch (err: any) {
        if (err?.status === 403) {
          setExpiresAt(err.data.expires_at);
          setBlocked(true);

          return;
        }

        if (err?.status === 401) {
          helpers.setStatus({ success: false });
          helpers.setErrors({
            password: `Incorrect password. You have ${err.data.tries} more chances to get it right!`,
          });
          helpers.setSubmitting(false);

          return;
        }

        if (err?.status === 404) {
          helpers.setStatus({ success: false });
          helpers.setErrors({
            email: `No account found for that email address.`,
          });
          helpers.setSubmitting(false);

          return;
        }

        setIsServerError(true);
        helpers.setStatus({ success: false });
        helpers.setErrors({
          submit: `Oops... Something went wrong. ${err?.status && `Error: ${err.status}`}`,
        });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <UnauthorizedWrapper>
      {block ? (
        <TemporaryLockout expiresAt={expiresAt} />
      ) : (
        <Card
          className={styles.cardWrapper}
          sx={{ borderRadius: "32px", padding: "32px 96px" }}
        >
          <Typography variant="h4" fontWeight={400} textAlign="left">
            Sign In
          </Typography>

          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className={styles.form}
          >
            <TextField
              sx={{
                marginBottom: "1rem",
                gap: "0.4rem",
              }}
              placeholder="Enter email"
              type="email"
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <TextField
              sx={{
                marginBottom: "1rem",
                gap: "0.4rem",
              }}
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onShowPassword}>
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <RemoveRedEyeOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography
              textAlign="left"
              sx={{
                marginBottom: "1rem",
              }}
            >
              <Link to="/request-reset-password">Forgot password?</Link>
            </Typography>

            <Button
              sx={{
                marginBottom: "1rem",
              }}
              fullWidth
              variant="contained"
              type="submit"
              color={isServerError ? "error" : "primary"}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size="1.6rem" />
              ) : (
                <Typography variant="button">
                  {isServerError ? "Retry" : "Sign in"}
                </Typography>
              )}
            </Button>

            {formik.errors.submit && (
              <Typography
                textAlign="center"
                color="error"
                variant="caption"
                sx={{
                  fontSize: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                {formik.errors.submit}
              </Typography>
            )}

            <Typography
              sx={{
                marginBottom: "0.5rem",
              }}
              textAlign="left"
            >
              Don&apos;t have an account? <Link to="/sign-up">Sign Up</Link>
            </Typography>

            <Typography textAlign="left">
              Need Help?{" "}
              <a href="mailto:support@sidefans.com">Contact SideFans Support</a>
            </Typography>
          </form>
        </Card>
      )}
    </UnauthorizedWrapper>
  );
}
