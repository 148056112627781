import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import cx from "classnames";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../../../hooks/redux";
import { BitcoinTextIcon } from "../../../../../media/icons/bitcoin-text";
import ContentLikeIcon from "../../../../../media/icons/catalog/content-card/content-like-icon";
import PurchasesIcon from "../../../../../media/icons/catalog/content-card/purchases-icon";
import SettingsIcon from "../../../../../media/icons/catalog/content-card/settings-icon";
import ViewesIcon from "../../../../../media/icons/catalog/content-card/viewes-icon";
import { LockIcon } from "../../../../../media/icons/lock";
import { MediaImageIcon } from "../../../../../media/icons/media-image";
import { MediaVideoIcon } from "../../../../../media/icons/media-video";
import {
  useAddViewMutation,
  useBuyContentMutation,
} from "../../../../../services/catalog/catalog.service";
import type { ContentCs } from "../../../../../services/catalog/interfaces/catalog.interfaces";
import { CurrenciesEnum } from "../../../../automatic-transfers/enums/currencies.enum";
import { ModalWrapper } from "../../../../creator-card/modal-wrapper/modal-wrapper";
import { PaymentMethodPicker } from "../../../../shared/payment-method-picker/payment-method-picker";
import { useCreatorCatalogCSViewContext } from "../../context/creator-catalog-cs-view-context";
import { ContentCsCardFullscreen } from "../content-cs-card-fullscreen/content-cs-card-fullscreen";
import styles from "./content-cs-card-item.module.scss";
import { ContentMediaCsPresenter } from "./content-media-cs-presenter/content-media-cs-presenter";

interface ContentCsCardProps {
  content: ContentCs;
}

export const ContentCsCardItem: FC<ContentCsCardProps> = ({ content }) => {
  const {
    view,
    setCurrentContentCard,
    currentContentCard,
    handleSetNextCard,
    handleSetPreviousCard,
    setCreatorContentItems,
    fetchContent,
  } = useCreatorCatalogCSViewContext();
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { user } = useAppSelector((data) => data.userReducer);

  const [isPurchaseContentOpen, setIsPurchaseContentOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [addViewToContent] = useAddViewMutation();
  const [
    buyContent,
    { isLoading: isBuyContentLoading, isSuccess: isBuyContentSuccess },
  ] = useBuyContentMutation();

  // Open content
  const [showContentCard, setShowContentCard] = useState<boolean>(false);

  const handleOpenCreatorCard = (e: any | undefined) => {
    if (e) e.stopPropagation();

    setCurrentContentCard(content);
    setShowContentCard(true);

    // Update current card
    setCurrentContentCard(content);

    addViewToContent({
      accessToken: userAccessToken || "",
      content_id: content.id,
    })
      .unwrap()
      .then(() => {
        // Update current card number of views
        setCurrentContentCard({
          ...content,
          _count: {
            ...content._count,
            viewsPerContents: content._count.viewsPerContents + 1,
          },
        });

        // Update card in list number of views
        setCreatorContentItems((prevState) => {
          if (!prevState) return prevState;

          const itemInListIndex = prevState.findIndex(
            (item) => item.id === content.id
          );

          if (itemInListIndex !== -1) {
            const updatedItems = [
              ...prevState.slice(0, itemInListIndex),
              {
                ...prevState[itemInListIndex],
                _count: {
                  ...prevState[itemInListIndex]._count,
                  viewsPerContents:
                    prevState[itemInListIndex]._count.viewsPerContents + 1,
                },
              } as ContentCs,
              ...prevState.slice(itemInListIndex + 1),
            ];

            return updatedItems;
          }

          return prevState;
        });
      })
      .catch(() => {});
  };

  const formikBuyContent = useFormik({
    initialValues: {
      selectedMethodId: "",
    },
    validationSchema: Yup.object({
      selectedMethodId: Yup.string().required("Payment method is required"),
    }),
    onSubmit: async (values) => {
      await buyContent({
        accessToken: userAccessToken || "",
        currency: content.currency,
        payment_method_id: values.selectedMethodId,
        content_id: content.id,
      }).unwrap();
    },
  });

  const handleCloseCreatorCard = () => {
    setShowContentCard(false);
  };

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const defaultMethod = user?.user_payment_methods.find(
      (item) => item.default
    );

    formikBuyContent.setFieldValue(
      "selectedMethodId",
      defaultMethod ? defaultMethod.id : ""
    );
  }, [user, isPurchaseContentOpen]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderPrice = (
    price: number,
    currency: CurrenciesEnum,
    signColor: string
  ) => {
    switch (currency) {
      case CurrenciesEnum.USD: {
        return `$${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.EURO: {
        return `€${price.toFixed(2).toString()}`;
      }
      case CurrenciesEnum.BTC: {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1px",
              }}
            >
              <BitcoinTextIcon color={signColor} width={18} height={18} />
              <span style={{ display: "block" }}>
                {price.toFixed(5).toString()}
              </span>
            </Box>
          </>
        );
      }
      default: {
        return "";
      }
    }
  };

  const handleCopyPostLink = (contentId: string) => {
    setAnchorEl(null);
    const { protocol, host } = window.location;
    const domain = `${protocol}//${host}`;

    navigator.clipboard.writeText(`${domain}/home?contentId=${contentId}`);
  };

  const onPurchaseContentClose = () => {
    formikBuyContent.resetForm();
    setIsPurchaseContentOpen(false);
  };

  useEffect(() => {
    if (isBuyContentSuccess) {
      onPurchaseContentClose();
      fetchContent();
    }
  }, [isBuyContentSuccess]);

  return (
    <>
      <div
        className={cx({
          [styles.contentCardWrapper]: view === "tile",
          [styles.contentCardWrapperListView]: view === "list",
        })}
      >
        <Box
          className={cx({
            [styles.header]: view === "tile",
            [styles.headerListView]: view === "list",
          })}
        >
          {view === "tile" && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className={styles.title}>{content.name}</Typography>
              <IconButton aria-describedby={id} onClick={handleOpenPopover}>
                <SettingsIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          className={styles.bodyContentWrapper}
          onClick={(e) => {
            handleOpenCreatorCard(e);
          }}
        >
          {content.closed && (
            <Box className={styles.closedContent}>
              <Box>
                <LockIcon width={48} height={60} />
              </Box>

              {content.is_collection && (
                <Box className={styles.numberOfMedia}>
                  <Box className={styles.numberOfMediaItem}>
                    <MediaImageIcon width={16} height={16} />
                    <Typography variant="body1" color="#fff">
                      {content._count.photos}
                    </Typography>
                  </Box>
                  <Box className={styles.numberOfMediaItem}>
                    <MediaVideoIcon width={16} height={16} />
                    <Typography variant="body1" color="#fff">
                      {content._count.videos}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "auto",
                  marginTop: "10px",
                  boxShadow: "2px 4px 24px 0px #FFFFFF7A",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsPurchaseContentOpen(true);
                }}
              >
                <Typography variant="button">
                  Unlock for{" "}
                  {renderPrice(content.price, content.currency, "#ffffff")}
                </Typography>
              </Button>
            </Box>
          )}
          <ContentMediaCsPresenter content={content} />
        </Box>
        <Box
          className={cx({
            [styles.footer]: view === "tile",
            [styles.footerListView]: view === "list",
          })}
        >
          {view === "list" && (
            <Typography className={styles.title}>{content.name}</Typography>
          )}
          <Box className={styles.footerFirstRow}>
            {content.price !== 0 && (
              <Typography fontWeight="bold" variant="body1" color="#252733">
                <>{renderPrice(content.price, content.currency, "black")}</>
              </Typography>
            )}
            {content.price === 0 && (
              <Typography variant="body1" color="#AAAAAA">
                Free
              </Typography>
            )}
            {content.is_collection && (
              <Typography className={styles.collectionLabel} color="#AAAAAA">
                Collection
              </Typography>
            )}
          </Box>
          <Box className={styles.footerThirdRow}>
            <div className={styles.categoryStatistic}>
              <ViewesIcon />
              <Typography color="#AAAAAA" variant="body1">
                {content._count.viewsPerContents}
              </Typography>
            </div>
            <div className={styles.categoryStatistic}>
              <ContentLikeIcon />
              <Typography color="#AAAAAA" variant="body1">
                {content._count.user_likes}
              </Typography>
            </div>
            <div className={styles.categoryStatistic}>
              <PurchasesIcon />
              <Typography color="#AAAAAA" variant="body1">
                {content._count.contentPurchaseAmount}
              </Typography>
            </div>
          </Box>
        </Box>

        {view === "list" && (
          <IconButton
            aria-describedby={id}
            onClick={handleOpenPopover}
            sx={{
              alignSelf: "flex-start",
              position: "absolute",
              top: "2px",
              right: "2px",
            }}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "237px",
          }}
        >
          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={() => handleCopyPostLink(content.id)}
          >
            <Typography variant="button" color="#252733">
              Copy post link
            </Typography>
          </Button>
        </Box>
      </Popover>

      <ModalWrapper shown={showContentCard} close={handleCloseCreatorCard}>
        {currentContentCard && (
          <ContentCsCardFullscreen
            currentCard={currentContentCard}
            handleSetNextCard={handleSetNextCard}
            handleSetPreviousCard={handleSetPreviousCard}
            handleCloseCreatorCard={handleCloseCreatorCard}
          />
        )}
      </ModalWrapper>

      {/* Purchase content */}
      <ModalWrapper
        shown={isPurchaseContentOpen}
        close={onPurchaseContentClose}
        disableBackdropClose
      >
        <form onSubmit={formikBuyContent.handleSubmit}>
          <Box className={styles.buyContentModalWrapper}>
            <Box className={styles.buyContentModal}>
              <Box className={styles.buyContentHeaderModal}>
                <Typography fontSize="24px" color="#252733">
                  Purchase content
                </Typography>
              </Box>

              <Box className={styles.buyContentMessage}>
                <Typography variant="body1">
                  Content <b>{content?.name}</b>
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {renderPrice(
                    content?.price || 0,
                    content?.currency || CurrenciesEnum.USD,
                    "black"
                  )}
                </Typography>
              </Box>

              <Box>
                <>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Payment method
                  </Typography>

                  <PaymentMethodPicker
                    value={formikBuyContent.values.selectedMethodId}
                    onChange={(selectedMethodId) => {
                      formikBuyContent.setFieldValue(
                        "selectedMethodId",
                        selectedMethodId
                      );
                    }}
                  />
                </>
              </Box>

              <Box className={styles.buyContentFooter}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formikBuyContent.isValid || isBuyContentLoading}
                >
                  <Typography variant="button">Buy</Typography>
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  disabled={isBuyContentLoading}
                  onClick={onPurchaseContentClose}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </ModalWrapper>
    </>
  );
};
