import { ThemeProvider } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import AutomaticTransfers from "./components/automatic-transfers/automatic-transfers";
import Content from "./components/content/content";
import { Earnings } from "./components/earnings/earnings";
import { Home } from "./components/home/home";
import Invitations from "./components/invitations/invitations";
import { Loader } from "./components/loading/loading";
import { ManageSubscribers } from "./components/manage-subscribers/manage-subscribers";
import PaymentInfo from "./components/payment-info/payment-info";
import Profile from "./components/profile/profile";
import { ProtectedRoute } from "./components/protected-route/protected-route";
import CreatorRoute from "./components/protected-route/routes/creator-route";
import SubscriberRoute from "./components/protected-route/routes/subscriber-route";
import RequestResetPassword from "./components/request-reset-password/request-reset-password";
import ResetPassword from "./components/reset-password/reset-password";
import { SettingsCc } from "./components/settings-cc/settings-cc";
import { SettingsCs } from "./components/settings-cs/settings-cs";
import { AuthorizedWrapper } from "./components/shared/auth-wrapper/auth-wrapper";
import SignIn from "./components/sign-in/sign-in";
import SignUpWrapper from "./components/sign-up/sign-up-wrapper";
import { Subscriptions } from "./components/subscriptions/subscriptions";
import { TransferAccounts } from "./components/transfer-accounts/transfer-accounts";
import { Transfers } from "./components/transfers/transfers";
import { UploadContent } from "./components/upload-content/upload-content";
import UserProfile from "./components/user-profile/user-profile";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { setupStore } from "./store/store";
import createTheme from "./themes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Navigate to="/sign-in" />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Content />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/sign-in",
    element: (
      <ProtectedRoute>
        <SignIn />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/sign-up",
    element: (
      <ProtectedRoute>
        <SignUpWrapper />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/payment-info",
    element: (
      <ProtectedRoute>
        <AuthorizedWrapper>
          <PaymentInfo />
        </AuthorizedWrapper>
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/user-profile/:profileId",
    element: (
      <ProtectedRoute>
        <AuthorizedWrapper>
          <UserProfile />
        </AuthorizedWrapper>
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/request-reset-password",
    element: (
      <ProtectedRoute>
        <RequestResetPassword />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/reset-password",
    element: (
      <ProtectedRoute>
        <ResetPassword />
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/invitations",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <Invitations />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <AuthorizedWrapper>
          <Home />
        </AuthorizedWrapper>
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/automatic-transfers",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <AutomaticTransfers />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/transfers",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <Transfers />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/profile",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <Profile />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/manage-subscribers",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <ManageSubscribers />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cs/subscriptions",
    element: (
      <ProtectedRoute>
        <AuthorizedWrapper>
          <Subscriptions />
        </AuthorizedWrapper>
      </ProtectedRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/earnings",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <Earnings />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/transfer-accounts",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <TransferAccounts />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/upload-content",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <UploadContent />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cc/settings",
    element: (
      <CreatorRoute>
        <AuthorizedWrapper>
          <SettingsCc />
        </AuthorizedWrapper>
      </CreatorRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
  {
    path: "/cs/settings",
    element: (
      <SubscriberRoute>
        <AuthorizedWrapper>
          <SettingsCs />
        </AuthorizedWrapper>
      </SubscriberRoute>
    ),
    errorElement: <h1>404 Page not found</h1>,
  },
]);

const theme = createTheme();

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={setupStore()}>
        <Loader>
          <RouterProvider router={router} />
        </Loader>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
