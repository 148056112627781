import { Box, Button, IconButton, Typography } from "@mui/material";
import type React from "react";
import { useNavigate } from "react-router-dom";

import EditProfile from "../../../../../media/icons/catalog/side-bar/edit-profile";
import { EditIcon } from "../../../../../media/icons/edit";
import { CreatorCardSidebarView } from "../../../../shared/creator-card-sidebar-view/creator-card-sidebar-view";
import { ProfileDescription } from "../../../../shared/profile-description/profile-description";
import { ProfilePersonalInfo } from "../../../../shared/profile-personal-info/profile-personal-info";
import { ProfileTags } from "../../../../shared/profile-tags/profile-tags";
import { useCreatorCatalogCCViewContext } from "../context/creator-catalog-cc-view-context";
import styles from "./side-bar-cc-view.module.scss";
import { SocialMedial } from "./social-media/social_media";

interface SideBarCcViewParams {}

export const SideBarCcView: React.FC<SideBarCcViewParams> = () => {
  const navigate = useNavigate();

  const { personalInfoParamteres, creatorCard, setCreatorCard } =
    useCreatorCatalogCCViewContext();

  return (
    <div className={styles.sideBarGrid}>
      <CreatorCardSidebarView
        creatorCard={creatorCard}
        setCreatorCard={setCreatorCard}
      />
      <Button
        variant="outlined"
        type="button"
        className={styles.editProfileButton}
        onClick={() => {
          navigate("/cc/settings");
        }}
        sx={{
          background: "white",
        }}
      >
        <EditProfile />
        <Typography>Edit profile</Typography>
      </Button>

      {personalInfoParamteres?.description && (
        <ProfileDescription personalInfoParameters={personalInfoParamteres} />
      )}

      {personalInfoParamteres?.links && (
        <SocialMedial links={personalInfoParamteres.links} />
      )}

      {personalInfoParamteres?.tags && (
        <ProfileTags personalInfoParameters={personalInfoParamteres} />
      )}

      {personalInfoParamteres && (
        <ProfilePersonalInfo personalInfoParameters={personalInfoParamteres} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Subscription Plans
          </Typography>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: "2px solid #ffffff",
              borderRadius: "12px",
              padding: "10px 15px",
            }}
          >
            <Typography variant="body1">VIP:</Typography>
            <Typography variant="body1" fontWeight="bold">
              $100.00/Month
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: "2px solid #ffffff",
              borderRadius: "12px",
              padding: "10px 15px",
            }}
          >
            <Typography variant="body1">The Goods:</Typography>
            <Typography variant="body1" fontWeight="bold">
              $50.00/Month
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: "2px solid #ffffff",
              borderRadius: "12px",
              padding: "10px 15px",
            }}
          >
            <Typography variant="body1">Fun Staff:</Typography>
            <Typography variant="body1" fontWeight="bold">
              $25.00/Month
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
