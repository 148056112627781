import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import SettingsIcon from "../../../../../../media/icons/catalog/content-card/settings-icon";
import { FolderIcon } from "../../../../../../media/icons/catalog/folder/folder";
import { useCreateFolderMutation } from "../../../../../../services/catalog/catalog.service";
import { IFolder } from "../../../../../../services/catalog/interfaces/catalog-folders.interfaces";
import { useCreatorCatalogCCViewContext } from "../../context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../../context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { AddToFolderForm } from "../../context/folder-modals/add-to-folder/add-to-folder";
import { FolderCreateForm } from "../../context/folder-modals/folder-create-form/folder-create-form";
import styles from "./folder.module.scss";

interface FolderProp {
  folder: IFolder;
}

export const Folder: FC<FolderProp> = ({ folder }) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState<boolean>(false);
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const {
    setFolderToDelete,
    setFolderToUpdate,
    getFoldersById,
    currentOpenedFolder,
  } = useFolderCreatorCatalogCCViewContext();
  const { setIsFolderNavigation, updateCatalogParam } =
    useCreatorCatalogCCViewContext();

  const [
    createFolder,
    { isLoading: createFolderLoading, isSuccess: createFolderSuccess },
  ] = useCreateFolderMutation();

  const createNewFolder = useCallback(
    async (folderName: string) => {
      await createFolder({
        accessToken: userAccessToken || "",
        folderName,
        parent_folder_id: currentOpenedFolder?.id
          ? currentOpenedFolder.id
          : null,
      });
    },
    [createFolder, userAccessToken, folder, currentOpenedFolder]
  );

  // Start popover handling
  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    setFolderToUpdate(folder);
    handleClosePopover();
  };

  const handleNewFolder = () => {
    setIsCreateFolderOpen(true);
    handleClosePopover();
  };

  const handleAddToFolder = () => {
    setIsAddToFolderOpen(true);
    handleClosePopover();
  };

  const handleDeleteFolder = () => {
    if (folder.content.length === 0) {
      setFolderToDelete(folder);
      handleClosePopover();
    }
  };
  // End popover handling

  const onCreateNewFolder = useCallback(
    async (folderName: string) => {
      let name = folderName;

      if (!name) {
        const folderWithDefaultName = folder.child_folders.find((item) =>
          item.name.match(/^Folder \d+$/)
        );

        if (!folderWithDefaultName) {
          name = `Folder 1`;
        } else {
          const [, number] = folderWithDefaultName.name.split(" ");
          name = `Folder ${Number(number) + 1}`;
        }
      }

      await createNewFolder(name);
    },
    [createNewFolder, folder]
  );

  useEffect(() => {
    if (createFolderSuccess) {
      setIsCreateFolderOpen(false);
      getFoldersById(currentOpenedFolder?.id);
    }
  }, [createFolderSuccess]);

  const onFolderClick = () => {
    setIsFolderNavigation(true);
    updateCatalogParam("folder_id", folder.id);
    getFoldersById(folder.id);
  };

  return (
    <>
      <Box className={styles.folderWrapper} onClick={onFolderClick}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleOpenPopover(event);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <SettingsIcon />
        </IconButton>
        <Box className={styles.folderInfo}>
          <FolderIcon />
          <Typography
            variant="body1"
            sx={{
              userSelect: "none",
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              textWrap: "nowrap",
              textAlign: "center",
            }}
          >
            {folder.name}
          </Typography>
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "237px",
          }}
        >
          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleRenameClick}
          >
            <Typography variant="button" color="#252733">
              Rename
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleNewFolder}
          >
            <Typography variant="button" color="#252733">
              New folder
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleAddToFolder}
          >
            <Typography variant="button" color="#252733">
              Add to folder
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",

              "&.Mui-disabled": {
                backgroundColor: "rgb(238 238 238)",
              },
            }}
            disabled={
              folder.content.length > 0 || folder.child_folders.length > 0
            }
            onClick={handleDeleteFolder}
          >
            <Typography variant="button" color="#FF5252">
              Delete
            </Typography>
          </Button>
        </Box>
      </Popover>
      <AddToFolderForm
        isOpen={isAddToFolderOpen}
        folderToMove={folder}
        onClose={() => {
          setIsAddToFolderOpen(false);
        }}
      />
      <FolderCreateForm
        isOpen={isCreateFolderOpen}
        isLoading={createFolderLoading}
        onSaveCreateClick={onCreateNewFolder}
        onCancelCreateClick={() => {
          setIsCreateFolderOpen(false);
        }}
      />
    </>
  );
};
