import { Button, CircularProgress, Typography } from "@mui/material";
import { FC } from "react";

import { ModalDialog } from "../../../../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import styles from "../../../modals/confirmation-modal.module.scss";

interface IFolderDeleteConfirmationProps {
  isOpen: boolean;
  isLoading: boolean;
  onAcceptDeleteClick: () => void;
  onCancelDeleteClick: () => void;
}

export const FolderDeleteConfirmation: FC<IFolderDeleteConfirmationProps> = ({
  isOpen,
  isLoading,
  onCancelDeleteClick,
  onAcceptDeleteClick,
}) => (
  <ModalDialog isOpen={isOpen} classes={styles.modal} backdropBlur={10}>
    <ModalDialogHeader position="center">
      <Typography align="center" variant="h4" fontWeight={400}>
        Delete folder
      </Typography>
    </ModalDialogHeader>
    <ModalDialogContent>
      <div>
        <Typography align="center" variant="body1" fontWeight={400}>
          Are you sure you want to delete folder?
        </Typography>
      </div>
    </ModalDialogContent>
    <ModalDialogFooter className={styles.footer}>
      <Button
        color="error"
        variant="contained"
        type="button"
        sx={{
          width: "218px",
        }}
        disabled={isLoading}
        onClick={onAcceptDeleteClick}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size="1.6rem" />
        ) : (
          <Typography variant="button">Delete</Typography>
        )}
      </Button>
      <Button
        variant="outlined"
        type="button"
        sx={{
          backgroundColor: "transparent",
          width: "218px",
        }}
        disabled={isLoading}
        onClick={onCancelDeleteClick}
      >
        <Typography variant="button">Cancel</Typography>
      </Button>
    </ModalDialogFooter>
  </ModalDialog>
);
