import { Box, Typography } from "@mui/material";

import { MainWrapper } from "../shared/main-wrapper/main-wrapper";

export default function Content() {
  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box>
          <Typography>Content</Typography>
        </Box>
      </MainWrapper>
    </Box>
  );
}
