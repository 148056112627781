import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import {
  type FC,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { TrashIcon } from "../../../../media/icons/trash";
import { VisibilityOff } from "../../../../media/icons/visibility-off";
import { useDeleteMeMutation } from "../../../../services/sign-in/sign-in.service";
import { ModalDialog } from "../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import styles from "../security-privacy.module.scss";

export const DeleteAccount: FC<PropsWithChildren> = ({ children }) => {
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  const [isDeleteAccountInfoOpen, setIsDeleteAccountInfoOpen] =
    useState<boolean>(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [
    deleteMe,
    {
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
      error: isDeleteError,
      reset,
    },
  ] = useDeleteMeMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("You must enter an email.")
        .max(255),
      password: Yup.string().required("You must enter a password."),
    }),
    onSubmit: async (values) => {
      deleteMe({
        accessToken,
        email: values.email,
        password: values.password,
      });
    },
  });

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate("/sign-in");
    }
  }, [isDeleteSuccess]);

  const errorMessage = useMemo(() => {
    if (isDeleteError) {
      const error = isDeleteError as FetchBaseQueryError;
      if (error.status === 400) {
        return "Email or password is incorrect";
      }

      return `Oops... Something went wrong. Error ${error.status}`;
    }

    return null;
  }, [isDeleteError]);

  useEffect(() => {
    reset();
  }, [formik.values]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="error"
          sx={{
            width: "237px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setIsDeleteAccountInfoOpen(true);
          }}
        >
          <TrashIcon width={17} height={17} />
          <Typography variant="button">Delete Account</Typography>
        </Button>
      </Box>

      <ModalDialog isOpen={isDeleteAccountInfoOpen} classes={styles.modal}>
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Delete account
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>{children}</ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="error"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={() => {
              setIsDeleteAccountInfoOpen(false);
              setIsDeleteAccountOpen(true);
            }}
          >
            <Typography variant="button">Yes</Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => {
              setIsDeleteAccountInfoOpen(false);
            }}
          >
            <Typography variant="button">No</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>

      <ModalDialog isOpen={isDeleteAccountOpen} classes={styles.modalConfirm}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className={styles.modalConfirmForm}
        >
          <ModalDialogHeader position="left">
            <Typography align="left" variant="h4" fontWeight={400}>
              Delete account
            </Typography>
          </ModalDialogHeader>
          <ModalDialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "18px",
              }}
            >
              <TextField
                placeholder="Enter email"
                type="text"
                error={!!(formik.touched.email && formik.errors.email)}
                name="email"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <Box sx={{ width: "100%" }}>
                <TextField
                  placeholder="Enter password"
                  type={showPassword ? "text" : "password"}
                  error={!!(formik.touched.password && formik.errors.password)}
                  name="password"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={onShowPassword}>
                          {showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <RemoveRedEyeOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorMessage && (
                  <Typography variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </Box>
          </ModalDialogContent>
          <ModalDialogFooter className={styles.footer}>
            <Button
              color="error"
              variant="contained"
              type="submit"
              sx={{
                width: "218px",
              }}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
              disabled={isDeleteLoading}
            >
              {isDeleteLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                <Typography variant="button">Delete</Typography>
              )}
            </Button>
            <Button
              variant="outlined"
              type="button"
              sx={{
                backgroundColor: "transparent",
                width: "218px",
              }}
              onClick={() => {
                setIsDeleteAccountOpen(false);
              }}
              disabled={isDeleteLoading}
            >
              <Typography variant="button">Cancel</Typography>
            </Button>
          </ModalDialogFooter>
        </form>
      </ModalDialog>
    </>
  );
};
