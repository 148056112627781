import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import useSubmitState from "../../../hooks/submit-state";
import { BackIcon } from "../../../media/icons/back";
import { CrossIcon } from "../../../media/icons/cross";
import {
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} from "../../../services/payment-methods/payment-methods.service";
import {
  useAddTransferAccountMutation,
  useUpdateTransferAccountMutation,
} from "../../../services/transfer-accounts/transfer-accounts.service";
import { paymentMethodReducer } from "../../../store/reducers/payment-methods/payment-methods";
import { transferAccountsReducer } from "../../../store/reducers/transfer-accounts/transfer-accounts";
import CountrySelect from "../../shared/select-country/select-country";
import StateSelect from "../../shared/select-state/select-state";
import type { BankAccountInterface } from "../interfaces/bank-account.interface";
import type { CollectedData } from "../interfaces/collected-data.interface";
import styles from "../select-payment-method.module.scss";

interface BankAccountProps {
  setActiveStep: (value: string | ((prevVar: string) => string)) => void;
  selectedMethods: string[];
  selectedStep: string;
  setCollectedData: (
    value: CollectedData | ((prevVar: CollectedData) => CollectedData)
  ) => void;
  collectedData: CollectedData;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
  onClose: () => void;
  edit?: boolean;
  setIsProccesing: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  signUp?: boolean;
  transferAccount?: boolean;
}

export default function BankAccount(props: BankAccountProps) {
  const {
    setActiveStep,
    selectedMethods,
    selectedStep,
    setCollectedData,
    collectedData,
    setSelectedMethods,
    onClose,
    edit,
    setIsProccesing,
    signUp,
    transferAccount = false,
  } = props;
  const location = useLocation();
  const [headerMessage, setHeaderMessage] = useState("");
  const [createPaymentMethod, { isLoading }] = useCreatePaymentMethodMutation();

  const [createTransferAccount, { isLoading: isTransferAccountLoading }] =
    useAddTransferAccountMutation();
  const [
    updateTransferAccountMutation,
    { isLoading: isTransferAccountUpdateLoading },
  ] = useUpdateTransferAccountMutation();

  const [
    updatePaymentMethod,
    {
      isLoading: updatingPaymentMethod,
      error: updatingPaymentMethodError,
      isSuccess: updatingPaymentMethodSuccess,
      reset: updatingPaymentMethodReset,
    },
  ] = useUpdatePaymentMethodMutation();

  const { paymentMethods } = useAppSelector(
    (state) => state.paymentMethodReducer
  );

  const { transferAccounts } = useAppSelector(
    (state) => state.transferAccountsReducer
  );

  const { setPaymentMethods, updateMethod } = paymentMethodReducer.actions;

  const { setTransferAccounts, updateTransferAccount } =
    transferAccountsReducer.actions;

  const dispatch = useAppDispatch();
  const { text, color, errorCode } = useSubmitState({
    requestError: updatingPaymentMethodError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: updatingPaymentMethodSuccess,
  });

  let index = 0;

  const bankAccountInitialValues = {
    country: collectedData.bank_account?.country
      ? collectedData.bank_account.country
      : "",
    state: collectedData.bank_account?.state
      ? collectedData.bank_account.state
      : "",
    street: collectedData.bank_account?.street
      ? collectedData.bank_account.street
      : "",
    city: collectedData.bank_account?.city
      ? collectedData.bank_account.city
      : "",
    zip: collectedData.bank_account?.zip ? collectedData.bank_account.zip : "",
    bank_account_number: collectedData.bank_account?.bank_account_number
      ? collectedData.bank_account.bank_account_number
      : "",
    routing_number: collectedData.bank_account?.routing_number
      ? collectedData.bank_account.routing_number
      : "",
    name: collectedData.bank_account?.name
      ? collectedData.bank_account.name
      : "",
    submit: null,
  };

  const bankAccountValidationSchema = Yup.object({
    country: Yup.string().required("Please enter country"),
    state: Yup.string().when("country", ([country], schema) =>
      country === "US" ? schema.required("Please enter state") : schema.min(0)
    ),
    street: Yup.string().required("Please enter street"),
    city: Yup.string().required("Please enter city"),
    zip: Yup.string().required("Please enter zip"),
    bank_account_number: Yup.string().required(
      "Please enter bank account number"
    ),
    routing_number: Yup.string().required("Please enter routing number"),
  });

  const formik = useFormik({
    initialValues: bankAccountInitialValues,
    validationSchema: bankAccountValidationSchema,
    onSubmit: async (
      values: BankAccountInterface,
      helpers: FormikHelpers<BankAccountInterface>
    ) => {
      try {
        const {
          bank_account_number: bankAccountNumber,
          city,
          country,
          routing_number: routingNumber,
          state,
          street,
          zip,
          name,
        } = values;

        collectedData.bank_account = {
          bank_account_number: bankAccountNumber,
          city,
          country,
          routing_number: routingNumber,
          state,
          street,
          zip,
          name,
        };

        if (isLoading) {
          return;
        }

        const accessToken = window.localStorage.getItem(
          LOCAL_STORAGE_KEYS.ACCESS_TOKEN
        );

        setCollectedData(collectedData);

        index =
          selectedMethods.findIndex((method) => method === selectedStep) + 1;

        if (selectedMethods.length === index) {
          setIsProccesing(true);
          collectedData.accessToken = accessToken || "";

          if (signUp) {
            onClose();
            return;
          }

          if (transferAccount) {
            if (edit && collectedData.transfer_account) {
              const { data } = await updateTransferAccountMutation({
                id: collectedData.transfer_account.id || "",
                accessToken: collectedData.accessToken,
                data: collectedData,
              }).unwrap();

              if (data.bank_account) {
                dispatch(
                  updateTransferAccount({
                    id: collectedData.transfer_account.id,
                    method: {
                      ...collectedData.transfer_account,
                      bank_account: { ...data.bank_account },
                    },
                  })
                );
              }
            } else {
              const { data } =
                await createTransferAccount(collectedData).unwrap();

              if (data) {
                dispatch(setTransferAccounts([...data, ...transferAccounts]));
              }
            }

            setSelectedMethods([]);
            setActiveStep("");
            setCollectedData({});
            onClose();

            return;
          }

          if (edit) {
            const { data } = await updatePaymentMethod({
              id: collectedData.method_id || "",
              accessToken: accessToken || "",
              data: {
                bank_account: collectedData.bank_account,
              },
            }).unwrap();

            if (data) {
              dispatch(
                updateMethod({
                  id: collectedData.method_id || "",
                  method: data,
                })
              );
            }
            setSelectedMethods([]);
            setActiveStep("");
            setCollectedData({});
            setIsProccesing(false);
            onClose();

            return;
          }

          const { data } = await createPaymentMethod(collectedData).unwrap();

          if (data) {
            dispatch(setPaymentMethods([...data, ...paymentMethods]));
          }
          setSelectedMethods([]);
          setActiveStep("");
          setCollectedData({});
          onClose();
          return;
        }

        setActiveStep(selectedMethods[index]);
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.data.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleGoBack = () => {
    if (isLoading || updatingPaymentMethod) {
      return;
    }
    index = selectedMethods.findIndex((method) => method === selectedStep) - 1;

    if (index < 0) {
      setSelectedMethods([]);
      return;
    }

    setActiveStep(selectedMethods[index]);
  };
  useEffect(() => {
    function findStepBefore(): string | undefined {
      for (let i = 0; i < selectedMethods.length; i += 1) {
        if (selectedMethods[i] === selectedStep) {
          return i > 0 ? selectedMethods[i - 1] : undefined;
        }
      }
      return undefined;
    }

    if (selectedMethods.findIndex((method) => method === selectedStep) === 0) {
      if (signUp) {
        setHeaderMessage(" to payment method");
      } else if (location.pathname.includes("payment-info")) {
        setHeaderMessage(" to Payment info");
      } else if (location.pathname.includes("transfer-accounts") && edit) {
        setHeaderMessage(" to Transfer accounts");
      } else if (location.pathname.includes("transfer-accounts")) {
        setHeaderMessage(" transfer account selection");
      }
    } else {
      switch (findStepBefore()) {
        case "creditCard":
          setHeaderMessage(" to Add Visa/MC/AMEX/Discover");
          break;
        case "bankAccount":
          setHeaderMessage(" to Add Bank account");
          break;
        case "crypto":
          setHeaderMessage(" to Add Crypto wallet");
          break;
        default:
          setHeaderMessage("");
          break;
      }
    }
  }, [selectedMethods, selectedStep, signUp]);

  const disabled = edit && !transferAccount;

  return (
    <>
      {edit ? (
        <header className={styles.header}>
          <Button
            className={styles.backToSignIn}
            onClick={onClose}
            sx={{ color: "#0F70CA", paddingLeft: 0 }}
          >
            <BackIcon width={15} height={15} />
            Back{headerMessage}
          </Button>
        </header>
      ) : (
        <header className={styles.header}>
          <Button
            className={styles.backToSignIn}
            onClick={handleGoBack}
            sx={{ color: "#0F70CA", paddingLeft: 0 }}
          >
            <BackIcon width={15} height={15} />
            Back{headerMessage}
          </Button>

          <Button className={styles.exitModal} onClick={onClose}>
            <CrossIcon />
          </Button>
        </header>
      )}

      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className={styles.scrollableForm}
      >
        <Box display="flex" flexDirection="column" gap="32px" padding="32px">
          <Box display="flex" gap="8px" alignItems="center">
            {selectedMethods.length > 1 && (
              <Box
                sx={{
                  backgroundColor: "#EBEBEB",
                  width: "32px",
                  height: "32px",
                  display: "inline-flex",
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={500} color="#4C4B4C">
                  {selectedMethods.findIndex(
                    (method) => method === selectedStep
                  ) + 1}
                </Typography>
              </Box>
            )}
            <Typography fontSize="24px" fontWeight={400}>
              {edit ? "Edit" : "Add"} Bank account
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            className={styles.cardStyles}
          >
            <Typography>Payment information</Typography>
            <div>
              <TextField
                placeholder="Account name"
                type="text"
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                sx={{ backgroundColor: "white", borderRadius: "8px" }}
                data-cy="methodName"
              />
              <Typography
                color="info.dark"
                variant="caption"
                marginTop="4px"
                fontWeight={400}
              >
                Optional
              </Typography>
            </div>
            <Box display="flex" gap="16px">
              <Box width="261px">
                <CountrySelect
                  disabled={disabled}
                  onChange={async (event) => {
                    formik.handleChange(event);
                    formik.setFieldError("state", "");
                    await formik.validateForm();
                    if (event.target.value !== "US") {
                      await formik.setFieldValue("state", "");
                    }
                  }}
                  value={formik.values.country}
                />
                {formik.errors.country && (
                  <Typography color="error" variant="caption" fontSize="12px">
                    {formik.errors.country}
                  </Typography>
                )}
              </Box>
              <Box width="261px">
                <StateSelect
                  disabled={disabled || formik.values.country !== "US"}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />
                {formik.errors.state && (
                  <Typography color="error" variant="caption" fontSize="12px">
                    {formik.errors.state}
                  </Typography>
                )}
              </Box>
            </Box>

            <TextField
              variant="outlined"
              placeholder="Street"
              type="text"
              error={!!(formik.touched.street && formik.errors.street)}
              fullWidth
              name="street"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.street}
              sx={{ borderRadius: "8px" }}
              disabled={disabled}
              data-cy="street"
            />
            {formik.errors.street && (
              <Typography
                color="error"
                variant="caption"
                sx={{ width: "450px", marginTop: "-12px" }}
                fontSize="12px"
              >
                {formik.errors.street}
              </Typography>
            )}
            <Box display="flex" gap="16px">
              <Box width="261px">
                <TextField
                  variant="outlined"
                  placeholder="City"
                  type="text"
                  error={!!(formik.touched.city && formik.errors.city)}
                  fullWidth
                  name="city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  sx={{ borderRadius: "8px" }}
                  disabled={disabled}
                  data-cy="city"
                />
                {formik.errors.city && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.city}
                  </Typography>
                )}
              </Box>
              <Box width="261px">
                <TextField
                  variant="outlined"
                  placeholder="ZIP code"
                  type="text"
                  error={!!(formik.touched.zip && formik.errors.zip)}
                  fullWidth
                  name="zip"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.zip}
                  sx={{ borderRadius: "8px" }}
                  disabled={disabled}
                  data-cy="zip"
                />
                {formik.errors.zip && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.zip}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography>Bank details</Typography>
            <TextField
              variant="outlined"
              placeholder="Bank account number"
              type="text"
              error={
                !!(
                  formik.touched.bank_account_number &&
                  formik.errors.bank_account_number
                )
              }
              fullWidth
              name="bank_account_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.bank_account_number}
              sx={{ borderRadius: "8px" }}
              disabled={disabled}
              data-cy="accountNumber"
            />
            {formik.errors.bank_account_number && (
              <Typography
                color="error"
                variant="caption"
                sx={{ width: "450px", marginTop: "-12px" }}
                fontSize="12px"
              >
                {formik.errors.bank_account_number}
              </Typography>
            )}
            <TextField
              variant="outlined"
              placeholder="Routing number"
              type="text"
              error={
                !!(
                  formik.touched.routing_number && formik.errors.routing_number
                )
              }
              fullWidth
              name="routing_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.routing_number}
              sx={{ borderRadius: "8px" }}
              disabled={disabled}
              data-cy="routingNumber"
            />
            {formik.errors.routing_number && (
              <Typography
                color="error"
                variant="caption"
                sx={{ width: "450px", marginTop: "-12px" }}
                fontSize="12px"
              >
                {formik.errors.routing_number}
              </Typography>
            )}
          </Box>
          <Box>
            <Button
              type={color === "primary" ? "submit" : "button"}
              variant="contained"
              fullWidth
              color={color}
              data-cy="save"
              onClick={(e) => {
                if (color === "error") {
                  e.preventDefault();
                  updatingPaymentMethodReset();
                }
              }}
            >
              {isLoading ||
              updatingPaymentMethod ||
              isTransferAccountLoading ||
              isTransferAccountUpdateLoading ? (
                <CircularProgress color="inherit" size="1.6rem" />
              ) : (
                text
              )}
            </Button>
            {errorCode && (
              <Typography
                variant="body1"
                color="error"
                mt={1}
                textAlign="center"
              >
                Oops... Something went wrong. Error {errorCode}
              </Typography>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
