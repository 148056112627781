import { TransactionHistoryType } from "../../../services/earnings/enums/transaction-history-type.enum";

export const transactionShowValues = [
  {
    display_text: "All",
    value: TransactionHistoryType.all,
  },
  {
    display_text: "Subscriptions",
    value: TransactionHistoryType.subscription,
  },
  {
    display_text: "Tips",
    value: TransactionHistoryType.tip,
  },
  {
    display_text: "Premium Content",
    value: TransactionHistoryType.premium_content,
  },
  {
    display_text: "Birthday",
    value: TransactionHistoryType.birthday,
  },
  {
    display_text: "Merch",
    value: TransactionHistoryType.merch,
  },
  {
    display_text: "Transfer From SF",
    value: TransactionHistoryType.transfer_from_sf,
  },
  {
    display_text: "Purchases",
    value: TransactionHistoryType.purchases,
  },
];
