import { Box } from "@mui/material";
import type { ChangeEvent, FC } from "react";
import { useRef, useState } from "react";

import { acceptedImagesForAvatar } from "../../../constants/constants";
import { PhotoIcon } from "../../../media/icons/photo";
import styles from "./avatar-loader.module.scss";

interface IAvatarLoaderProp {
  onAvatarUpload: (files: File) => void;
  error: boolean;
  avatar: string | null;
}

export const AvatarLoader: FC<IAvatarLoaderProp> = ({
  onAvatarUpload,
  error,
  avatar,
}) => {
  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const selectAvatar = () => {
    avatarInputRef.current?.click();
  };

  const onAvatarSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) return;

    onAvatarUpload(files[0] as File);
    setNewAvatar(files[0]);
    event.target.value = "";
  };

  const hasAvatar = !!newAvatar || !!avatar;
  const getAvatarUrl = () => {
    if (avatar) {
      return avatar;
    }

    if (newAvatar) {
      return URL.createObjectURL(newAvatar);
    }

    return "";
  };

  return (
    <>
      {hasAvatar ? (
        <Box
          className={styles.avatarUploader}
          role="button"
          onClick={selectAvatar}
        >
          <img
            src={getAvatarUrl()}
            alt="avatar"
            className={styles.file}
            draggable={false}
          />
        </Box>
      ) : (
        <Box
          className={`${styles.avatarUploader} ${error === true ? styles.avatarUploaderError : ""}`}
          role="button"
          onClick={selectAvatar}
        >
          <PhotoIcon />
        </Box>
      )}
      <input
        type="file"
        name="file"
        className={styles.fileInput}
        ref={avatarInputRef}
        onChange={onAvatarSelect}
        accept={acceptedImagesForAvatar}
      />
    </>
  );
};
