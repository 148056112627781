import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";

import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useRemoveAutomaticTransferMutation } from "../../services/automatic-transfers/automatic-transfers";
import { automaticTransfersSlice } from "../../store/reducers/automatic-transfers/automatic-transfers";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { ModalDialog } from "../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import AutomaticTransferForm from "./automatic-transfer-form/automatic-transfer-form";
import styles from "./automatic-transfers.module.scss";
import { currencyData } from "./data";
import { CurrenciesEnum } from "./enums/currencies.enum";
import History from "./history/history";
import { AutomaticTransferWallets } from "./wallets/automatic-transfer-wallets";

export default function AutomaticTransfers() {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [automaticTransferId, setAutomaticTransferId] = useState("");
  const [checked, setChecked] = useState(false);

  const {
    automatic_transfers: automaticTransfers,
    available_currencies: availableCurrencies,
  } = useAppSelector((item) => item.automaticTransfersReducer);

  const { setAutomaticTransfersData } = automaticTransfersSlice.actions;

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const [removeAutomaticTransfer, { isLoading: isDeleteLoading }] =
    useRemoveAutomaticTransferMutation();

  const dispatch = useAppDispatch();

  const currency = availableCurrencies.find((item) => {
    const exsists = automaticTransfers.find(
      (transfer) => transfer.currency === item.value
    );

    if (exsists) {
      return false;
    }

    return true;
  })?.value;

  const handleAddCurrencyTransfer = () => {
    dispatch(
      setAutomaticTransfersData({
        automatic_transfers: [
          ...automaticTransfers,
          {
            currency: currency || CurrenciesEnum.BTC,
            currency_transfers: [],
            first_day_number: "01",
            second_day_number: "31",
            frequency: "oncePerMonth",
            id: uuidv4(),
          },
        ],
        available_currencies: availableCurrencies,
      })
    );
  };

  useEffect(() => {
    if (automaticTransfers.length === 0) {
      handleAddCurrencyTransfer();
    }
  }, [automaticTransfers]);

  const handleRemoveAutomaticTransfer = async (id: string) => {
    const data = await removeAutomaticTransfer({
      accessToken,
      id,
    });
    const newAutomaticTransfers = automaticTransfers.filter(
      (item) => item.id !== id
    );

    if (data) {
      dispatch(
        setAutomaticTransfersData({
          automatic_transfers: newAutomaticTransfers,
          available_currencies: availableCurrencies,
        })
      );
      setOpenDeleteDialog(false);
    }
  };

  useEffect(() => {
    setChecked(true);
  }, []);

  const handleDelete = () => {
    if (automaticTransferId) {
      handleRemoveAutomaticTransfer(automaticTransferId);
    }
  };

  const isCurrencyActivated = (curr: CurrenciesEnum) =>
    availableCurrencies.find((item) => item.value === curr);

  const isCurrencyUsedInTransfers = (curr: CurrenciesEnum) =>
    automaticTransfers.find((item) => item.currency === curr);

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.mainCardWrapper}>
          <Box display="flex" gap="16px">
            <Box display="flex" flexDirection="column" gap="32px" width="100%">
              <AutomaticTransferWallets
                title="Automatic transfers"
                description="In the 'Automatic Transfers' section, take control of your
        finances effortlessly. Set up regular payments with the flexibility to
        choose either a fixed amount or a percentage of your total balance,
        ensuring a seamless and convenient way to manage your funds"
              />
              <TransitionGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {automaticTransfers.map((item) => (
                  <Collapse key={item.id}>
                    <Fade in={checked}>
                      <Box key={item.id} marginTop="8px" marginRight="2px">
                        <AutomaticTransferForm
                          totalAutomaticTransfers={automaticTransfers.length}
                          setOpenDeleteDialog={setOpenDeleteDialog}
                          setAutomaticTransferId={setAutomaticTransferId}
                          automaticTransfer={item}
                          isDeleteLoading={isDeleteLoading}
                          currencyData={currencyData.map((curr) => {
                            if (
                              curr.value === item.currency ||
                              !isCurrencyActivated(curr.value) ||
                              isCurrencyUsedInTransfers(curr.value)
                            ) {
                              curr.disabled = true;
                            } else {
                              curr.disabled = false;
                            }

                            return curr;
                          })}
                        />
                      </Box>
                    </Fade>
                  </Collapse>
                ))}
              </TransitionGroup>
              <Button
                variant="contained"
                sx={{ width: "218px", padding: "8px 0" }}
                onClick={handleAddCurrencyTransfer}
                disabled={
                  availableCurrencies.length === automaticTransfers.length ||
                  automaticTransfers.some(
                    (transfer) => transfer.currency_transfers.length === 0
                  )
                }
              >
                <Typography variant="button">Add currency transfer</Typography>
              </Button>
            </Box>
            <TransitionGroup
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {automaticTransfers.map((item) => (
                <Collapse key={item.id}>
                  <History data={item} />
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        </Box>
      </MainWrapper>
      <ModalDialog isOpen={openDeleteDialog} classes={styles.modal}>
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Delete Automatic Transfer
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Are you sure you want to delete Automatic transfer?
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="error"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={handleDelete}
          >
            <Typography fontSize="16px">
              {isDeleteLoading ? <CircularProgress size="1.5rem" /> : "Delete"}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => {
              setOpenDeleteDialog(false);
              setAutomaticTransferId("");
            }}
            disabled={isDeleteLoading}
          >
            <Typography fontSize="16px">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>
    </Box>
  );
}
