import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { DeleteInvitationInterface } from "./interfaces/delete-invitation.interface";
import type {
  GetInvitationsInterfaceParams,
  GetInvitationsResponseInterface,
} from "./interfaces/get-invitations.interface";

export const invitationsApi = createApi({
  reducerPath: "getInvitations",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getInvitations: builder.mutation<
      GetInvitationsResponseInterface,
      GetInvitationsInterfaceParams
    >({
      query: (data: GetInvitationsInterfaceParams) => ({
        url: ENDPOINTS.INVITES.INVITES_LIST,
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
        },
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    cancelInvitation: builder.mutation({
      query: (data: DeleteInvitationInterface) => ({
        url: `${ENDPOINTS.INVITES.CANCEL_INVITATION}/${data.id}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const { useGetInvitationsMutation, useCancelInvitationMutation } =
  invitationsApi;
