import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";

import { MESSAGE_TIMEOUT } from "../../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useInviteUserMutation } from "../../../services/invite-user/invite-user.service";
import { invitationsSlice } from "../../../store/reducers/invitations/invitations";
import type { InvitieFriendInterface } from "../interfaces/invite-friend.interface";
import styles from "../invitations.module.scss";

export default function InviteFriend() {
  const [success, setSuccess] = useState("");
  const [isMessageVisible, setIsMessageVisible] = useState({
    sent: false,
    sentAgain: false,
  });
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const [inviteUserSlice, { isLoading }] = useInviteUserMutation();

  const { invitations: currentInvitations } = useAppSelector(
    (item) => item.invitationsReducer
  );

  const dispatch = useAppDispatch();

  const { invitations } = useAppSelector((item) => item.invitationsReducer);

  const { setInvitations, updateInvitation } = invitationsSlice.actions;

  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          "Sorry, that email address appears to be invalid. Maybe there is a typo? Please fix it or enter a different address."
        )
        .required(" ")
        .max(255),
    }),
    onSubmit: async (
      values: InvitieFriendInterface,
      helpers: FormikHelpers<InvitieFriendInterface>
    ) => {
      try {
        if (isLoading) {
          return;
        }
        setSuccess("");
        const { data } = await inviteUserSlice({
          email: values.email,
          accessToken: accessToken || "",
        }).unwrap();

        const foundedInvitation = invitations.find(
          (item) => item.email === values.email
        );

        if (foundedInvitation) {
          dispatch(updateInvitation({ id: foundedInvitation.id }));
          setSuccess("Invitation sent again");
          formik.values.email = "";
          setIsMessageVisible((prev) => ({ ...prev, sentAgain: true }));
          timeoutId.current = setTimeout(() => {
            setIsMessageVisible((prev) => ({ ...prev, sentAgain: false }));
          }, MESSAGE_TIMEOUT);
          return;
        }

        dispatch(setInvitations([data, ...currentInvitations]));
        setSuccess("Invitation sent successfully");
        formik.values.email = "";

        setIsMessageVisible((prev) => ({ ...prev, sent: true }));
        timeoutId.current = setTimeout(() => {
          setIsMessageVisible((prev) => ({ ...prev, sent: false }));
        }, MESSAGE_TIMEOUT);
      } catch (err: any) {
        const isEmailIncorrect = err.data.message.includes(
          "email must be an email"
        );

        helpers.setStatus({ success: false });
        helpers.setErrors({
          submit: isEmailIncorrect
            ? "Sorry, that email address appears to be invalid. Maybe there is a typo? Please fix it or enter a different address."
            : err.data.message,
        });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="22px"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="10px"
        >
          <Typography variant="h4" fontWeight={400}>
            Invite friends
          </Typography>
          <Box>
            <Typography variant="body1" lineHeight="22px">
              Unlock the joy of shared experiences! Invite your friends to join
              the adventure and amplify the fun.
            </Typography>
            <Typography variant="body1" lineHeight="22px">
              As a token of our appreciation, both you and your friends will
              enjoy exclusive perks and rewards when they sign up.
            </Typography>
          </Box>
        </Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box display="flex" gap="20px">
            <TextField
              placeholder="Enter email"
              type="email"
              error={!!(formik.touched.email && formik.errors.email)}
              name="email"
              onBlur={(e) => {
                formik.handleBlur(e);
                setSuccess("");
              }}
              onChange={(e) => {
                formik.handleChange(e);
                clearTimeout(timeoutId.current);
                setSuccess("");
              }}
              value={formik.values.email}
              sx={{ width: "365px", height: "40px" }}
            />
            <Button
              variant="contained"
              className={styles.sendButton}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress color="inherit" size="1.6rem" />
              ) : (
                "Send"
              )}
            </Button>
          </Box>

          {formik.errors.email && (
            <Typography
              color="error"
              variant="caption"
              sx={{ width: "360px" }}
              lineHeight="14px"
            >
              {formik.errors.email}
            </Typography>
          )}
          {formik.errors.submit && (
            <Typography
              color="error"
              variant="caption"
              sx={{ width: "360px" }}
              lineHeight="14px"
            >
              {formik.errors.submit}
            </Typography>
          )}
          {success && (isMessageVisible.sent || isMessageVisible.sentAgain) && (
            <Typography color="#30D354" variant="caption">
              {success}
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
}
