import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { useAppSelector } from "../../../hooks/redux";
import type { IUserTransferAccounts } from "../../../services/sign-in/interfaces/initialize.interface";
import { PaymentMethodTypes } from "../../select-payment-method/enums";
import type { CollectedData } from "../../select-payment-method/interfaces/collected-data.interface";
import AddPaymentMethod from "../../select-payment-method/select-payment-method";
import { checkForAutomaticTransfers } from "../utils/checkForAutomatedTransfer";
import { DeleteTransferAccountModal } from "./delete-transfer-account-modal";
import { TransferAccountsList } from "./transfer-accounts-list";

export const TransferAccountsSection = () => {
  const { transferAccounts } = useAppSelector(
    (reducers) => reducers.transferAccountsReducer
  );
  const { automatic_transfers: automaticTransfers } = useAppSelector(
    (reducers) => reducers.automaticTransfersReducer
  );
  const [isAutomaticTransferExists, setIsAutomaticTransferExists] =
    useState(false);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [selectedStep, setSelectedStep] = useState("");
  const [collectedData, setCollectedData] = useState({} as CollectedData);
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setEdit(false);
  }, []);

  const handleEdit = useCallback(
    (account: IUserTransferAccounts) => () => {
      setEdit(true);
      setOpen(true);

      if (account.bank_account) {
        setCollectedData({
          transfer_account: account,
          bank_account: account.bank_account,
        });
        setSelectedMethods([PaymentMethodTypes.BANK_ACCOUNT]);
        setSelectedStep(PaymentMethodTypes.BANK_ACCOUNT);
      } else if (account.crypto) {
        setCollectedData({
          transfer_account: account,
          crypto: account.crypto,
        });
        setSelectedMethods([PaymentMethodTypes.CRYPTO]);
        setSelectedStep(PaymentMethodTypes.CRYPTO);
      }
    },
    []
  );

  const hangleDelete = useCallback(
    (account: IUserTransferAccounts) => () => {
      setCollectedData({ transfer_account: account });
      setIsDelete(true);
    },
    []
  );

  useEffect(() => {
    if (collectedData.transfer_account) {
      const isExists = checkForAutomaticTransfers({
        transferAccount: collectedData.transfer_account,
        automaticTransfers,
      });
      setIsAutomaticTransferExists(isExists);
    }
  }, [collectedData]);

  return (
    <Box
      sx={{
        marginBottom: "2rem",
      }}
    >
      <Typography
        variant="h4"
        fontWeight={400}
        sx={{
          marginBottom: "0.5rem",
        }}
      >
        Transfer accounts
      </Typography>
      <Typography
        sx={{
          marginBottom: "1rem",
        }}
      >
        Manage your transfer accounts and keep track of your transfer history.
      </Typography>

      {!!transferAccounts.length && (
        <TransferAccountsList
          transferAccounts={transferAccounts}
          handleEdit={handleEdit}
          handleDelete={hangleDelete}
        />
      )}

      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        data-cy="addTransfer"
      >
        Add transfer account
      </Button>

      <AddPaymentMethod
        transferAccount
        open={open}
        edit={edit}
        onClose={handleOnClose}
        collectedData={collectedData}
        setCollectedData={setCollectedData}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        selectedMethods={selectedMethods}
        setSelectedMethods={setSelectedMethods}
      />
      <DeleteTransferAccountModal
        isAutomaticTransferExists={isAutomaticTransferExists}
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        collectedData={collectedData}
      />
    </Box>
  );
};
