import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { ICheckEmailResponse } from "./interfaces/check-email-response.interface";
import type { ICheckPasswordResponse } from "./interfaces/check-password-response.interface";
import type { ICheckUsernameResponse } from "./interfaces/check-username-response.interface";
import type { IConfirmEmailResponse } from "./interfaces/confirm-email.response.interface";
import type {
  ISignUpBody,
  ISignUpResponse,
} from "./interfaces/sign-up.interface";

export const signUpApi = createApi({
  reducerPath: "signUpAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    checkUsername: build.mutation<ICheckUsernameResponse, string>({
      query: (username: string) => ({
        url: ENDPOINTS.SIGN_UP.CHECK_USERNAME,
        method: "POST",
        body: { username },
      }),
    }),
    checkEmail: build.mutation<ICheckEmailResponse, string>({
      query: (email: string) => ({
        url: ENDPOINTS.SIGN_UP.CHECK_EMAIL,
        method: "POST",
        body: { email },
      }),
    }),
    confirmEmail: build.mutation<
      IConfirmEmailResponse,
      { email: string; code: number }
    >({
      query: ({ email, code }) => ({
        url: ENDPOINTS.SIGN_UP.CONFIRM_EMAIL,
        method: "POST",
        body: { email, code },
      }),
    }),
    checkPassword: build.mutation<ICheckPasswordResponse, string>({
      query: (password) => ({
        url: ENDPOINTS.SIGN_UP.CHECK_PASSWORD,
        method: "POST",
        body: { password },
      }),
    }),
    signUp: build.mutation<ISignUpResponse, ISignUpBody>({
      query: (body) => ({
        url: ENDPOINTS.SIGN_UP.SIGN_UP,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCheckUsernameMutation,
  useCheckEmailMutation,
  useConfirmEmailMutation,
  useCheckPasswordMutation,
  useSignUpMutation,
} = signUpApi;
