import { Box, Card, LinearProgress } from "@mui/material";
import Persona from "persona";
import React, { useState } from "react";

import { BackIcon } from "../../media/icons/back";
import type { AgeVerificationType } from "../sign-up/types/sign-up.types";
import styles from "./age-verification.module.scss";

interface AgeVerificationProps {
  setInquiryId: (value: string | ((prevVar: string) => string)) => void;
  setAgeVerificationStep: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  setAgeVerification: (
    value:
      | AgeVerificationType
      | ((prevVar: AgeVerificationType) => AgeVerificationType)
  ) => void;
  backBtn: React.JSX.Element;
}

function AgeVerification(props: AgeVerificationProps) {
  const { setInquiryId, setAgeVerificationStep, setAgeVerification, backBtn } =
    props;

  const [ready, setReady] = useState(false);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        className={styles.signUpCardWrapper}
        sx={{
          padding: "0",
          borderRadius: "32px",
        }}
      >
        <header className={styles.header}>
          <div className={styles.backToSignIn}>
            <BackIcon width={15} height={15} />
            {backBtn}
          </div>
        </header>
        {ready ? null : (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="700px"
          >
            <LinearProgress sx={{ width: "100%" }} />
          </Box>
        )}
        <div
          style={{
            display: ready ? "flex" : "none",
            width: "700px",
            height: "700px",
            justifyContent: "center !important",
            alignItems: "center !important",
          }}
        >
          <Persona.Inquiry
            templateId={process.env.REACT_APP_PERSONA_TEMPLATE}
            environment="sandbox"
            onReady={() => setReady(true)}
            onComplete={({ inquiryId, status }) => {
              if (status === "completed") {
                setInquiryId(inquiryId);
                setAgeVerification({
                  status: "valid",
                  value: true,
                });
                setAgeVerificationStep(false);
              }
              setAgeVerificationStep(false);
            }}
          />
        </div>
      </Card>
    </Box>
  );
}

export default AgeVerification;
