import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  IManualTransfer,
  RemoveManualTransfer,
  RemoveManualTransferResponse,
} from "./interfaces/manual-transfers.interface";

export const manualTransfersApi = createApi({
  reducerPath: "manualTransfersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    addManualTransfer: build.mutation<IManualTransfer, IManualTransfer>({
      query: (data: IManualTransfer) => ({
        url: ENDPOINTS.TRANSFERS.MANUAL_TRANSFERS,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          manual_transfer: data.manual_transfer,
        },
      }),
    }),
    removeManualTransfer: build.mutation<
      RemoveManualTransferResponse,
      RemoveManualTransfer
    >({
      query: (data: RemoveManualTransfer) => ({
        url: `${ENDPOINTS.TRANSFERS.MANUAL_TRANSFERS}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    removeCurrencyManualTransfer: build.mutation<
      RemoveManualTransferResponse,
      RemoveManualTransfer
    >({
      query: (data: RemoveManualTransfer) => ({
        url: `${ENDPOINTS.TRANSFERS.CURRENCY_TRANSFERS}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const {
  useAddManualTransferMutation,
  useRemoveManualTransferMutation,
  useRemoveCurrencyManualTransferMutation,
} = manualTransfersApi;
