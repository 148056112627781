import { useCallback, useRef, useState } from "react";

export default function useInfinityScrollContent(
  isLoading: boolean,
  loadedData: any[],
  totalNumberOfRecords: number
) {
  const [page, setPage] = useState(0);

  const observer = useRef<IntersectionObserver>();
  const lastMemberElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          loadedData.length < totalNumberOfRecords
        ) {
          setPage((prev) => prev + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, loadedData, totalNumberOfRecords, setPage]
  );

  return {
    lastMemberElementRef,
    page,
    setPage,
  };
}
