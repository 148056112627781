import type React from "react";

interface Props {
  width?: number;
  height?: number;
}

const FilterActiveIcon: React.FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4978 25.5C11.347 25.5009 11.198 25.4667 11.0628 25.4C10.8936 25.3182 10.7508 25.1904 10.6509 25.0313C10.5509 24.8721 10.4979 24.688 10.4978 24.5V14.885L1.22779 4.69C0.862879 4.28788 0.62253 3.78848 0.535915 3.25242C0.4493 2.71636 0.520143 2.16668 0.739847 1.6701C0.95955 1.17351 1.31867 0.751376 1.77362 0.454918C2.22856 0.15846 2.75978 0.000427612 3.30279 0H24.6928C25.2358 0.000427612 25.767 0.15846 26.222 0.454918C26.6769 0.751376 27.036 1.17351 27.2557 1.6701C27.4754 2.16668 27.5463 2.71636 27.4597 3.25242C27.3731 3.78848 27.1327 4.28788 26.7678 4.69L17.4978 14.885V20.5C17.4977 20.6498 17.464 20.7977 17.399 20.9327C17.3341 21.0677 17.2397 21.1864 17.1228 21.28L12.1228 25.28C11.9455 25.4222 11.7251 25.4998 11.4978 25.5ZM3.30279 2C3.14718 2.00039 2.99502 2.04587 2.86472 2.13094C2.73443 2.21601 2.63158 2.33702 2.56862 2.47933C2.50567 2.62163 2.48531 2.77914 2.51001 2.93277C2.53471 3.08641 2.60341 3.2296 2.70779 3.345L12.2378 13.845C12.4011 14.0245 12.4935 14.2574 12.4978 14.5V22.42L15.4978 20V14.5C15.4972 14.2504 15.5899 14.0097 15.7578 13.825L25.2878 3.325C25.386 3.20908 25.4493 3.06775 25.4706 2.91734C25.4918 2.76693 25.4701 2.61357 25.4078 2.475C25.3456 2.33643 25.2454 2.21829 25.1189 2.13424C24.9924 2.05018 24.8447 2.00364 24.6928 2H3.30279Z"
      fill="#6995EC"
    />
    <path
      d="M3.30279 2C3.14718 2.00039 2.99502 2.04587 2.86472 2.13094C2.73443 2.21601 2.63158 2.33702 2.56862 2.47933C2.50567 2.62163 2.48531 2.77914 2.51001 2.93277C2.53471 3.08641 2.60341 3.2296 2.70779 3.345L12.2378 13.845C12.4011 14.0245 12.4935 14.2574 12.4978 14.5V22.42L15.4978 20V14.5C15.4972 14.2504 15.5899 14.0097 15.7578 13.825L25.2878 3.325C25.386 3.20908 25.4493 3.06775 25.4706 2.91734C25.4918 2.76693 25.4701 2.61357 25.4078 2.475C25.3456 2.33643 25.2454 2.21829 25.1189 2.13424C24.9924 2.05018 24.8447 2.00364 24.6928 2H3.30279Z"
      fill="#6995EC"
    />
  </svg>
);

export default FilterActiveIcon;
