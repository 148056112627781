import type React from "react";

interface Props {
  width?: string;
  height?: string;
}

const FavouritedIcon: React.FC<Props> = ({ width, height }) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5022 21.5416C12.2055 21.5423 11.9117 21.4843 11.6375 21.3711C11.3633 21.2578 11.1143 21.0914 10.9047 20.8816L3.17966 13.1566C2.56941 12.5397 2.08802 11.8075 1.76354 11.0028C1.43906 10.198 1.27797 9.3367 1.28966 8.46906C1.29554 7.67394 1.45822 6.88779 1.7684 6.15564C2.07858 5.42349 2.53016 4.75973 3.09727 4.20238C3.66437 3.64503 4.33587 3.20504 5.07328 2.9076C5.8107 2.61017 6.59955 2.46114 7.39466 2.46906C8.20399 2.4609 9.00672 2.61536 9.75524 2.92327C10.5038 3.23118 11.1828 3.68628 11.7522 4.26156L12.5022 5.01156L13.1172 4.39656C14.1791 3.30648 15.6001 2.63819 17.117 2.51546C18.6338 2.39273 20.1438 2.82388 21.3672 3.72906C22.0635 4.26534 22.6377 4.94384 23.0513 5.71938C23.465 6.49491 23.7087 7.34967 23.7662 8.22675C23.8236 9.10382 23.6936 9.98308 23.3847 10.806C23.0758 11.6288 22.5951 12.3765 21.9747 12.9991L14.0997 20.8816C13.89 21.0914 13.641 21.2578 13.3668 21.3711C13.0926 21.4843 12.7988 21.5423 12.5022 21.5416ZM7.36466 3.96156C6.17255 3.96034 5.02673 4.42297 4.16966 5.25156C3.7327 5.67196 3.38522 6.17634 3.14808 6.73441C2.91093 7.29247 2.78902 7.8927 2.78966 8.49906C2.78308 9.16604 2.90897 9.8277 3.16002 10.4457C3.41107 11.0636 3.78229 11.6256 4.25216 12.0991L11.9772 19.8241C12.0469 19.8944 12.1298 19.9501 12.2212 19.9882C12.3126 20.0263 12.4106 20.0459 12.5097 20.0459C12.6087 20.0459 12.7067 20.0263 12.7981 19.9882C12.8895 19.9501 12.9724 19.8944 13.0422 19.8241L20.9247 11.9341C21.3873 11.4616 21.7448 10.8966 21.9736 10.2761C22.2024 9.65565 22.2973 8.99381 22.2522 8.33406C22.2121 7.66754 22.0285 7.01755 21.714 6.42857C21.3994 5.83959 20.9613 5.32552 20.4297 4.92156C19.4968 4.23373 18.3456 3.9085 17.1907 4.00647C16.0358 4.10443 14.9559 4.61892 14.1522 5.45406L13.0347 6.60156C12.9649 6.67185 12.882 6.72765 12.7906 6.76573C12.6992 6.8038 12.6012 6.82341 12.5022 6.82341C12.4031 6.82341 12.3051 6.8038 12.2137 6.76573C12.1223 6.72765 12.0394 6.67185 11.9697 6.60156L10.6947 5.32656C9.81648 4.45601 8.63119 3.96573 7.39466 3.96156H7.36466Z"
      fill="white"
    />
    <path
      d="M7.36466 3.96156C6.17255 3.96034 5.02673 4.42297 4.16966 5.25156C3.7327 5.67196 3.38522 6.17634 3.14808 6.73441C2.91093 7.29247 2.78902 7.8927 2.78966 8.49906C2.78308 9.16604 2.90897 9.8277 3.16002 10.4457C3.41107 11.0636 3.78229 11.6256 4.25216 12.0991L11.9772 19.8241C12.0469 19.8944 12.1298 19.9501 12.2212 19.9882C12.3126 20.0263 12.4106 20.0459 12.5097 20.0459C12.6087 20.0459 12.7067 20.0263 12.7981 19.9882C12.8895 19.9501 12.9724 19.8944 13.0422 19.8241L20.9247 11.9341C21.3873 11.4616 21.7448 10.8966 21.9736 10.2761C22.2024 9.65565 22.2973 8.99381 22.2522 8.33406C22.2121 7.66754 22.0285 7.01755 21.714 6.42857C21.3994 5.83959 20.9613 5.32552 20.4297 4.92156C19.4968 4.23373 18.3456 3.9085 17.1907 4.00647C16.0358 4.10443 14.9559 4.61892 14.1522 5.45406L13.0347 6.60156C12.9649 6.67185 12.882 6.72765 12.7906 6.76573C12.6992 6.8038 12.6012 6.82341 12.5022 6.82341C12.4031 6.82341 12.3051 6.8038 12.2137 6.76573C12.1223 6.72765 12.0394 6.67185 11.9697 6.60156L10.6947 5.32656C9.81648 4.45601 8.63119 3.96573 7.39466 3.96156H7.36466Z"
      fill="white"
    />
  </svg>
);

export default FavouritedIcon;
