import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";

import { DeleteCrossIcon } from "../../../../media/icons/delete-cross-icon";
import { PlusIcon } from "../../../../media/icons/plus";
import type { DefaultSelectOptions } from "../../../shared/select/select";
import DefaultSelect from "../../../shared/select/select";
import styles from "../info.module.scss";
import type { IPersonalInfo } from "../interfaces/personal-info.interface";
import stylesDetails from "./personal-details.module.scss";
import {
  PersonalInformationKeys,
  personalInfoDropdown,
  personalInformation,
} from "./personal-information";

interface PersonalDetailsProps {
  onFormikValueUpdate: (
    formikKey: string,
    formikValue: string | null | number
  ) => void;
  personalInfoDisplay: IPersonalInfo[];
  onPersonalDetailAdd: (newDetail: IPersonalInfo) => void;
  onPersonalDetailDelete: (nameToDelete: string) => void;
}

export const PersonalDetails: FC<PersonalDetailsProps> = ({
  onFormikValueUpdate,
  personalInfoDisplay,
  onPersonalDetailAdd,
  onPersonalDetailDelete,
}) => {
  const formikKey = "info_on_update.personal_info";
  const [personalInfoDropDown, setPersonalInfoDropDown] =
    useState<DefaultSelectOptions[]>(personalInfoDropdown);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const onInfoSelect = useCallback(
    (value: string) => {
      setShowDropDown(false);

      const newInfo = personalInformation.find((item) => item.name === value);

      if (newInfo) {
        onPersonalDetailAdd(newInfo);
      }
    },
    [personalInfoDisplay, personalInfoDropDown]
  );

  useEffect(() => {
    const updatedDropdown = personalInfoDropdown.map((dropdownItem) => {
      const isPresentInDisplay = personalInfoDisplay.some(
        (displayItem) => displayItem.name === dropdownItem.value
      );

      return { ...dropdownItem, disabled: isPresentInDisplay };
    });

    setPersonalInfoDropDown(updatedDropdown);
  }, [personalInfoDisplay]);

  const onPersonalInfoDelete = (name: string) => {
    onPersonalDetailDelete(name);
    onFormikValueUpdate(`${formikKey}.${name}`, null);
  };

  const isAllInfoDisplayed = personalInfoDropDown.every(
    (item) => item.disabled
  );

  return (
    <Accordion
      sx={{
        marginTop: "12px",

        "&.Mui-expanded": {
          marginTop: "12px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography variant="button" fontWeight="bold">
          Personal information
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        <Box className={styles.details}>
          {personalInfoDisplay.map((info, index) => (
            <Box
              key={info.name}
              className={styles.detail}
              sx={{
                marginBottom:
                  isAllInfoDisplayed && index === personalInfoDisplay.length - 1
                    ? "20px"
                    : "inherit",
              }}
            >
              <Typography variant="body1" width="65%">
                {info.title}:
              </Typography>
              <Box
                className={`${styles.inputArea} ${info.name === PersonalInformationKeys.birthday ? stylesDetails.inputAreaBirthday : ""}`}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {info.name === PersonalInformationKeys.birthday ? (
                  <Box sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateField
                        disableFuture
                        fullWidth
                        defaultValue={info.value ? dayjs(info.value) : null}
                        InputProps={{
                          placeholder: info.placeholder,
                        }}
                        onChange={(value: Dayjs | null, context) => {
                          if (!context.validationError && value) {
                            onFormikValueUpdate(
                              `${formikKey}.${info.name}`,
                              value.toISOString()
                            );
                            return;
                          }
                          onFormikValueUpdate(`${formikKey}.${info.name}`, "");
                        }}
                      />
                    </LocalizationProvider>
                    <Typography variant="body1" align="right" marginTop="3px">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        onClick={() => {
                          window.location.hash = "#security";
                        }}
                      >
                        Birthday Special Opt-Out
                      </Link>
                    </Typography>
                  </Box>
                ) : info.name === PersonalInformationKeys.age ? (
                  <TextField
                    name={info.name}
                    placeholder={info.placeholder}
                    fullWidth
                    defaultValue={info.value}
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 100,
                    }}
                    onChange={(e) => {
                      onFormikValueUpdate(
                        `${formikKey}.${info.name}`,
                        Number(e.target.value)
                      );
                    }}
                    onBlur={(e) => {
                      let value = Number(e.target.value);
                      if (value < 1) {
                        value = 1;
                      } else if (value > 100) {
                        value = 100;
                      }
                      e.target.value = String(value);
                      onFormikValueUpdate(`${formikKey}.${info.name}`, value);
                    }}
                  />
                ) : (
                  <TextField
                    name={info.name}
                    placeholder={info.placeholder}
                    fullWidth
                    defaultValue={info.value}
                    onChange={(e) => {
                      onFormikValueUpdate(
                        `${formikKey}.${info.name}`,
                        e.target.value
                      );
                    }}
                  />
                )}

                <IconButton
                  sx={{
                    marginTop:
                      info.name === PersonalInformationKeys.birthday
                        ? "5px"
                        : "inherit",
                  }}
                  onClick={() => onPersonalInfoDelete(info.name)}
                >
                  <DeleteCrossIcon width={14} height={14} />
                </IconButton>
              </Box>
            </Box>
          ))}

          {showDropDown && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                }}
              />
              <Box className={styles.inputArea}>
                <DefaultSelect
                  options={personalInfoDropDown}
                  onChange={(value) => {
                    onInfoSelect(value.value.toString());
                  }}
                  value=""
                  name="info"
                  placeholder="Select..."
                />
                <IconButton
                  onClick={() => {
                    if (showDropDown) {
                      setShowDropDown(false);
                    }
                  }}
                >
                  <DeleteCrossIcon width={14} height={14} />
                </IconButton>
              </Box>
            </Box>
          )}

          {!isAllInfoDisplayed && (
            <Button
              variant="text"
              sx={{
                padding: "10px 0",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "142px",
                marginBottom: "12px",
                marginTop: "-12px",
              }}
              onClick={() => {
                if (!showDropDown) {
                  setShowDropDown(true);
                }
              }}
            >
              <PlusIcon width={12} height={12} />
              <Typography variant="button">Add more info</Typography>
            </Button>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
