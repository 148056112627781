import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";

import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BitcoinAccountIcon } from "../../../media/icons/bitcoin-account-icon";
import { CreditCardIcon } from "../../../media/icons/credit-card";
import { PaymentMethodTypes } from "../enums";
import PaymentMethodCard from "../payment-method-card/payment-method-card";

interface PaymentOptionsProps {
  onClose: () => void;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
  setSelectedStep: (value: string | ((prevVar: string) => string)) => void;
  transferAccount?: boolean;
}

export default function PaymentMethodOptions(props: PaymentOptionsProps) {
  const {
    onClose,
    setSelectedMethods,
    setSelectedStep,
    transferAccount = false,
  } = props;

  const [selected, setSelected] = useState<string[]>([]);

  const data = [
    {
      id: 1,
      type: PaymentMethodTypes.CREDIT_CARD,
      icon: <CreditCardIcon />,
      text: "Visa/MC/AMEX/Discover",
    },
    {
      id: 2,
      type: PaymentMethodTypes.BANK_ACCOUNT,
      icon: <BankAcc />,
      text: "Bank Account",
    },
    {
      id: 3,
      type: PaymentMethodTypes.CRYPTO,
      icon: <BitcoinAccountIcon />,
      text: "Cryptocurrency",
    },
  ];

  const handleSelect = (item: string) => {
    const foundedSelection = selected.find((option) => option === item);

    if (foundedSelection) {
      return setSelected(selected.filter((option) => option !== item));
    }

    const priorityData = [
      {
        type: "creditCard",
        priority: 1,
      },
      {
        type: "bankAccount",
        priority: 2,
      },
      {
        type: "crypto",
        priority: 3,
      },
    ];
    const compareMethods = (a: any, b: any) => {
      const priorityA = priorityData.find((type) => type.type === a) || {
        type: "creditCard",
        priority: 1,
      };
      const priorityB = priorityData.find((type) => type.type === b) || {
        type: "creditCard",
        priority: 1,
      };
      return priorityA.priority - priorityB.priority;
    };

    return setSelected([...selected, item].sort(compareMethods));
  };

  return (
    <Box padding="32px">
      <Typography fontWeight={400} fontSize="24px">
        {transferAccount ? `Add transfer account` : `Select a payment method`}
      </Typography>
      <Box display="flex" gap="24px" marginTop="30px">
        {data
          .filter((option) => {
            if (transferAccount) {
              return option.type !== "creditCard";
            }

            return option;
          })
          .map((item, index) => (
            <Box
              key={item.id}
              onClick={() => handleSelect(item.type)}
              sx={{ cursor: "pointer" }}
            >
              <PaymentMethodCard
                icon={item.icon}
                text={item.text}
                selected={selected.includes(item.type)}
                index={index}
              />
            </Box>
          ))}
      </Box>
      <Box
        display="flex"
        width="100%"
        gap="24px"
        marginTop="24px"
        justifyContent="center"
      >
        <Button
          disabled={selected.length === 0}
          variant="contained"
          sx={{ width: "100%" }}
          data-cy="next"
          onClick={() => {
            setSelectedMethods(selected);
            setSelectedStep(selected[0]);
          }}
        >
          Next
        </Button>
        <Button variant="outlined" sx={{ width: "100%" }} onClick={onClose}>
          Back
        </Button>
      </Box>
    </Box>
  );
}
