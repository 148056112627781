export const MediaVideoIcon = ({
  width = 32,
  height = 32,
  color = "#fff",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0176 0.566845C14.7153 0.345979 14.3486 0.333059 14.0361 0.530424L11.7275 1.98617V1.80451C11.7275 0.809444 11.0547 0 10.2275 0H2C1.17285 0 0.5 0.809444 0.5 1.80451V8.19549C0.5 9.19056 1.17285 10 2 10H10.2275C11.0547 10 11.7275 9.19056 11.7275 8.19549V8.01383L14.0361 9.46957C14.1831 9.56239 14.3418 9.6082 14.5005 9.6082C14.6792 9.6082 14.8574 9.54946 15.0176 9.43315C15.3198 9.21346 15.5 8.82871 15.5 8.40402V1.59598C15.5 1.17129 15.3198 0.786538 15.0176 0.566845ZM10.7275 8.19549C10.7275 8.52737 10.5034 8.79699 10.2275 8.79699H2C1.72412 8.79699 1.5 8.52737 1.5 8.19549V1.80451C1.5 1.47263 1.72412 1.20301 2 1.20301H10.2275C10.5034 1.20301 10.7275 1.47263 10.7275 1.80451V2.9805V7.0195V8.19549ZM14.5 8.40402L11.7275 6.6559V3.3441L14.5 1.59598L14.5024 8.40519L14.5 8.40402Z"
      fill={color}
    />
  </svg>
);
