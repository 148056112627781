import { Box, Modal } from "@mui/material";
import type { FC, PropsWithChildren } from "react";

import { useAppSelector } from "../../hooks/redux";
import styles from "./loading.module.css";

export const Loader: FC<PropsWithChildren> = ({ children }) => {
  const { loading } = useAppSelector((item) => item.loaderReducer);

  return (
    <Box>
      {children}{" "}
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal}>
          <Box className={styles.loader} />
        </Box>
      </Modal>
    </Box>
  );
};
