import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppSelector } from "../../../hooks/redux";
import useRoundCurrency from "../../../hooks/round-currency";
import useWalletArray from "../../../hooks/wallet-array";
import { BitcoinTextIcon } from "../../../media/icons/bitcoin-text";
import { BtcLogo } from "../../../media/icons/btc";
import { DollarLogo } from "../../../media/icons/dollar";
import { EuroLogo } from "../../../media/icons/euro";
import { useFetchWalletsMutation } from "../../../services/wallet/wallet.service";
import AddPaymentMethod from "../../select-payment-method/select-payment-method";
import { CurrenciesEnum } from "../enums/currencies.enum";
import styles from "./automatic-transfer-wallets.module.scss";

type WalletsProp = {
  title: string;
  description?: string | ReactJSXElement;
};

export const AutomaticTransferWallets: FC<WalletsProp> = ({
  title,
  description,
}) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedStep, setSelectedStep] = useState("");
  const [collectedData, setCollectedData] = useState({});
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);

  const { user } = useAppSelector((data) => data.userReducer);

  const navigate = useNavigate();
  const { roundAmount } = useRoundCurrency();

  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const [fetchWallet, { data: walletData, isLoading, error }] =
    useFetchWalletsMutation();

  const renderLogo = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return (
          <div className={styles.walletName}>
            <div className={styles.walletIcon}>
              <BtcLogo />
            </div>
            <span>My BTC wallet</span>
          </div>
        );
      case CurrenciesEnum.USD:
        return (
          <div className={styles.walletName}>
            <div className={styles.walletIcon}>
              <DollarLogo />
            </div>
            <span>My USD wallet</span>
          </div>
        );
      case CurrenciesEnum.EURO:
        return (
          <div className={styles.walletName}>
            <div className={styles.walletIcon}>
              <EuroLogo />
            </div>
            <span>My EUR wallet</span>
          </div>
        );
      default:
        return <BtcLogo />;
    }
  };
  const renderCurrency = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return (
          <Box
            sx={{
              transform: "translate(4px, 4px)",
            }}
          >
            <BitcoinTextIcon width={24} height={24} />
          </Box>
        );
      case CurrenciesEnum.USD:
        return <span style={{ userSelect: "none" }}>$</span>;
      case CurrenciesEnum.EURO:
        return <span style={{ userSelect: "none" }}>€</span>;
      default:
        return <span style={{ userSelect: "none" }}>-</span>;
    }
  };

  const { walletArray } = useWalletArray(walletData?.data, true);

  useEffect(() => {
    fetchWallet({ accessToken: accessToken || "" });
  }, [accessToken]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error]);

  const isWalletDisplayed = (walletType: CurrenciesEnum) =>
    (user?.accept_btc && walletType === CurrenciesEnum.BTC) ||
    (user?.accept_usd && walletType === CurrenciesEnum.USD) ||
    (user?.accept_euro && walletType === CurrenciesEnum.EURO);

  return (
    <Box>
      <Typography variant="h4" fontWeight={400}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", lineHeight: "22px" }}
      >
        {description}
      </Typography>
      <Box className={styles.walletListWrapper}>
        <Box className={styles.walletList}>
          {walletArray.map(
            (wallet, idx) =>
              isWalletDisplayed(wallet.type) && (
                <Box className={styles.walletWrapper} key={wallet.type}>
                  <Box className={styles.walletNameWrapper}>
                    <Typography className={styles.walletName}>
                      {renderLogo(wallet.type)}
                    </Typography>
                    <Typography
                      className={styles.walletCurrency}
                      fontSize={24}
                      fontWeight={600}
                    >
                      {renderCurrency(wallet.type)}
                      {wallet.type === CurrenciesEnum.BTC
                        ? roundAmount(wallet._sum.amount || 0, 5)
                        : roundAmount(wallet._sum.amount || 0, 2)}
                    </Typography>
                  </Box>
                  {idx !== walletArray.length - 1 && (
                    <Box className={styles.line} />
                  )}
                </Box>
              )
          )}
        </Box>
        <Button
          className={styles.addTransferBtn}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Add transfer account
        </Button>
      </Box>
      {isLoading && <LinearProgress />}
      <AddPaymentMethod
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
        }}
        transferAccount
        collectedData={collectedData}
        selectedMethods={selectedMethods}
        selectedStep={selectedStep}
        setCollectedData={setCollectedData}
        setSelectedMethods={setSelectedMethods}
        setSelectedStep={setSelectedStep}
        edit={edit}
      />
    </Box>
  );
};
