import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  IGetBlockedUsersPayload,
  IGetBlockedUsersResponse,
  IUnblockUserPayload,
  IUnblockUserResponse,
} from "./interfaces/block-users.interface";

export const blockUserApi = createApi({
  reducerPath: "blockUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    unblockUser: builder.mutation<IUnblockUserResponse, IUnblockUserPayload>({
      query: (data: IUnblockUserPayload) => ({
        url: ENDPOINTS.BLOCK_USERS.UNBLOCK,
        method: "POST",
        body: {
          id: data.id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    getBlockerUsers: builder.mutation<
      IGetBlockedUsersResponse,
      IGetBlockedUsersPayload
    >({
      query: (data: IGetBlockedUsersPayload) => ({
        url: ENDPOINTS.BLOCK_USERS.GET_BLOCKED_USERS,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const { useUnblockUserMutation, useGetBlockerUsersMutation } =
  blockUserApi;
