import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { CreatorCatalogCCViewProvider } from "./creator-catalog/creator-catalog-cc-view/context/creator-catalog-cc-view-context";
import { FolderCreatorCatalogCCViewProvider } from "./creator-catalog/creator-catalog-cc-view/context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { CreatorCatalogCCView } from "./creator-catalog/creator-catalog-cc-view/creator-catalog-cc-view";

export default function Profile() {
  return (
    <MainWrapper>
      <div
        style={{
          paddingTop: "70px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <CreatorCatalogCCViewProvider>
          <FolderCreatorCatalogCCViewProvider>
            <CreatorCatalogCCView />
          </FolderCreatorCatalogCCViewProvider>
        </CreatorCatalogCCViewProvider>
      </div>
    </MainWrapper>
  );
}
