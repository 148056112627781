import type { FC } from "react";

type CheckmarkIconProp = {
  width?: number;
  height?: number;
  color?: string;
};

export const CheckmarkIcon: FC<CheckmarkIconProp> = ({
  width = 18,
  height = 18,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 9.15385L8.05 14L16.5 5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
