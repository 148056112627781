import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import type { FC } from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import type { ISubmitState } from "../../../../../../hooks/submit-state";
import type {
  IBackyardBanishmentDetails,
  IBackyardBanishmentUpdate,
} from "../../../../../../services/settings/interfaces/settings.interface";
import { BanishmentKeys } from "../../banishment";

interface IFormValues {
  zipCode: string;
  radius: string;
}

interface BanishByMilesrop {
  banishmentData: IBackyardBanishmentDetails | null | undefined;
  onUpdateBackyardBanishment: (
    backyardBanishment: IBackyardBanishmentUpdate
  ) => Promise<void>;
  submitState: ISubmitState;
  resetState: () => void;
}

export const BanishByMiles: FC<BanishByMilesrop> = ({
  banishmentData,
  onUpdateBackyardBanishment,
  submitState,
  resetState,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (submitState.color === "error" || submitState.color === "success") {
      setIsLoading(false);
    }
  }, [submitState]);

  const formik = useFormik<IFormValues>({
    initialValues: {
      zipCode: "",
      radius: "",
    },
    validationSchema: Yup.object({
      zipCode: Yup.string()
        .required()
        .test("is-zip-code", "Zip code is invalid", (value) =>
          /^[0-9]{5}$/.test(value)
        )
        .test(
          "is-zip-code-not-empty",
          "Zip code is invalid",
          (value) => value.trim() !== ""
        ),
      radius: Yup.string()
        .required()
        .matches(/^(1[0-9]|[2-9][0-9]|100)$/, "Radius must be a valid number")
        .test(
          "is-radius-not-empty",
          "Radius is invalid",
          (value) => value.trim() !== ""
        ),
    }),
    onSubmit: (values) => {
      onUpdateBackyardBanishment({
        type: BanishmentKeys.withinMiles,
        data: {
          user_zip_code: values.zipCode,
          radius: Number(values.radius),
          state: null,
          zip_codes: null,
        },
      });
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (banishmentData) {
      formik.setValues({
        zipCode: banishmentData.user_zip_code || "",
        radius: banishmentData.radius?.toString() || "",
      });
    }
  }, [banishmentData]);

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box display="flex" flexDirection="row" gap="15px" alignItems="center">
        <TextField
          name="zipCode"
          placeholder="Enter ZIP code"
          fullWidth
          value={formik.values.zipCode}
          onChange={(e) => {
            resetState();
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={!!(formik.touched.zipCode && formik.errors.zipCode)}
        />
        <TextField
          name="radius"
          placeholder="Enter miles radius"
          fullWidth
          value={formik.values.radius}
          onChange={(e) => {
            resetState();
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={!!(formik.touched.radius && formik.errors.radius)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{ width: "108px" }}
          variant="contained"
          disabled={!formik.isValid}
          type={submitState.color === "primary" ? "submit" : "button"}
          color={submitState.color}
          onClick={(e) => {
            if (submitState.color === "error") {
              e.preventDefault();
              resetState();
              return;
            }

            if (submitState.color === "success") {
              e.preventDefault();
              return;
            }

            setIsLoading(true);
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="1.5rem" />
          ) : (
            <Typography variant="button">{submitState.text}</Typography>
          )}
        </Button>
      </Box>
    </form>
  );
};
