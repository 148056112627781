import type React from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import useInfinityScrollContent from "../../../../hooks/infinity-scroll-content";
import { useAppDispatch } from "../../../../hooks/redux";
import {
  useGetCreatorsBySearchMutation,
  useGetInterestedCreatorsMutation,
  useGetLastSearchNamesMutation,
  useGetNewestCreatorsMutation,
  useGetRankedCreatorsMutation,
  useGetSubscribedCreatorsMutation,
} from "../../../../services/home/home.service";
import {
  type ICreatorCardData,
  type IHomeCreatorCard,
  LastSearchType,
} from "../../../../services/home/interfaces/home.interface";
import { lastSearchSlice } from "../../../../store/reducers/last-search/last-search";
import type {
  ActiveTab,
  ActiveView,
} from "../../interfaces/temporary.interface";

// Define the shape of the context
interface IHomeListContextType {
  tab: ActiveTab | undefined;
  // setTab: Dispatch<SetStateAction<ActiveTab | undefined>>,
  view: ActiveView | undefined;
  // setView: Dispatch<SetStateAction<ActiveView | undefined>>,
  searchText: string;
  // setSearchText: Dispatch<SetStateAction<string>>;
  items: IHomeCreatorCard[];
  setItems: Dispatch<SetStateAction<IHomeCreatorCard[]>>;
  currentCard: ICreatorCardData | null;
  setCurrentCard: Dispatch<SetStateAction<ICreatorCardData | null>>;
  handleSetPreviousCard: () => void;
  handleSetNextCard: () => void;
  lastMemberElementRef: (node: HTMLDivElement) => void;
  getLoadingRecords: () => boolean;
}

// Create the context with a default value
const HomeListContext = createContext<IHomeListContextType>(
  {} as IHomeListContextType
);

// Create a provider component
export const HomeListProvider: React.FC<{
  children: ReactNode;
  tab: ActiveTab;
  view: ActiveView;
  searchText: string;
}> = ({ children, tab, view, searchText }) => {
  const cardsPerPage = 10;
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const dispatch = useAppDispatch();

  const [items, setItems] = useState<IHomeCreatorCard[]>([]);

  const { setLastSearchItems } = lastSearchSlice.actions;

  const [getLastSearchNames, { data: getLastSearchNamesData }] =
    useGetLastSearchNamesMutation();

  const fetchLastSearchedList = useCallback(async () => {
    await getLastSearchNames({
      accessToken: userAccessToken || "",
      type: LastSearchType.CREATORS_SEARCH,
    }).unwrap();
  }, [userAccessToken, getLastSearchNames]);

  useEffect(() => {
    if (getLastSearchNamesData?.data) {
      dispatch(setLastSearchItems(getLastSearchNamesData.data));
    }
  }, [getLastSearchNamesData]);

  const [currentCard, setCurrentCard] = useState<ICreatorCardData | null>(null);

  const [
    getSubscribedCreators,
    { data: subscribedCreatorsData, isLoading: isSubscribedCreatorsLoading },
  ] = useGetSubscribedCreatorsMutation();

  const [
    getNewestCreators,
    { data: newestCreatorsData, isLoading: isNewestCreatorsLoading },
  ] = useGetNewestCreatorsMutation();

  const [
    getInterestedCreators,
    { data: interestedCreatorsData, isLoading: isInterestedCreatorsLoading },
  ] = useGetInterestedCreatorsMutation();

  const [
    getRankedCreators,
    { data: rankedCreatorsData, isLoading: isRankedCreatorsLoading },
  ] = useGetRankedCreatorsMutation();

  const [
    getCreatorsBySearch,
    { data: searchCreatorsData, isLoading: isSearchCreatorsLoading },
  ] = useGetCreatorsBySearchMutation();

  const getTotalRecords = () => {
    switch (tab) {
      case "top_ranked":
        return rankedCreatorsData?.total || 0;
      case "subscribed":
        return subscribedCreatorsData?.total || 0;
      case "interested":
        return interestedCreatorsData?.total || 0;
      case "newest":
        return newestCreatorsData?.total || 0;
      default: {
        if (searchText === "") {
          return 0;
        }
        return searchCreatorsData?.total || 0;
      }
    }
  };

  const getDataRecords = () => {
    switch (tab) {
      case "top_ranked":
        return rankedCreatorsData?.data || [];
      case "subscribed":
        return subscribedCreatorsData?.data || [];
      case "interested":
        return interestedCreatorsData?.data || [];
      case "newest":
        return newestCreatorsData?.data || [];
      default: {
        if (searchText === "") {
          return [];
        }

        return searchCreatorsData?.data || [];
      }
    }
  };

  const getLoadingRecords = useCallback(() => {
    switch (tab) {
      case "top_ranked":
        return isRankedCreatorsLoading;
      case "subscribed":
        return isSubscribedCreatorsLoading;
      case "interested":
        return isInterestedCreatorsLoading;
      case "newest":
        return isNewestCreatorsLoading;
      default: {
        if (searchText === "") {
          return false;
        }

        return isSearchCreatorsLoading;
      }
    }
  }, [
    tab,
    isRankedCreatorsLoading,
    isSubscribedCreatorsLoading,
    isInterestedCreatorsLoading,
    isNewestCreatorsLoading,
    searchText,
    isSearchCreatorsLoading,
  ]);

  const { lastMemberElementRef, page, setPage } = useInfinityScrollContent(
    getLoadingRecords(),
    getDataRecords(),
    getTotalRecords()
  );

  const fetchSubscribedCreators = useCallback(async () => {
    if (items.length === subscribedCreatorsData?.total) return;

    await getSubscribedCreators({
      accessToken: userAccessToken || "",
      rowsPerPage: cardsPerPage,
      page: page + 1,
    })
      .unwrap()
      .then((data) => {
        setItems((prevState) => [...prevState, ...data.data]);
      })
      .catch(() => {
        setItems((prevState) => [...prevState]);
      });
  }, [
    items.length,
    subscribedCreatorsData?.total,
    getSubscribedCreators,
    userAccessToken,
    page,
  ]);

  const fetchNewestCreators = useCallback(async () => {
    if (items.length === newestCreatorsData?.total) return;

    await getNewestCreators({
      accessToken: userAccessToken || "",
      rowsPerPage: cardsPerPage,
      page: page + 1,
    })
      .unwrap()
      .then((data) => {
        setItems((prevState) => [...prevState, ...data.data]);
      })
      .catch(() => {
        setItems((prevState) => [...prevState]);
      });
  }, [
    userAccessToken,
    getNewestCreators,
    cardsPerPage,
    page,
    newestCreatorsData,
    items,
  ]);

  const fetchInterestedCreators = useCallback(async () => {
    if (items.length === interestedCreatorsData?.total) return;

    await getInterestedCreators({
      accessToken: userAccessToken || "",
      rowsPerPage: cardsPerPage,
      page: page + 1,
    })
      .unwrap()
      .then((data) => {
        setItems((prevState) => [...prevState, ...data.data]);
      })
      .catch(() => {
        setItems((prevState) => [...prevState]);
      });
  }, [
    userAccessToken,
    getInterestedCreators,
    cardsPerPage,
    page,
    interestedCreatorsData,
    items,
  ]);

  useEffect(() => {
    if (rankedCreatorsData?.data) {
      setItems((prevState) => [...prevState, ...rankedCreatorsData.data]);
    }
  }, [rankedCreatorsData]);

  const fetchRankedCreators = useCallback(async () => {
    if (items.length === rankedCreatorsData?.total) return;

    await getRankedCreators({
      accessToken: userAccessToken || "",
      rowsPerPage: cardsPerPage,
      page: page + 1,
    }).unwrap();
  }, [
    userAccessToken,
    getRankedCreators,
    cardsPerPage,
    page,
    rankedCreatorsData,
    items,
  ]);

  useEffect(() => {
    fetchLastSearchedList();
    if (items.length === searchCreatorsData?.total) return;

    setItems((prevState) => [
      ...prevState,
      ...(searchCreatorsData?.data || []),
    ]);
  }, [searchCreatorsData]);

  const fetchSearchCreators = useCallback(async () => {
    await getCreatorsBySearch({
      accessToken: userAccessToken || "",
      rowsPerPage: cardsPerPage,
      page: page + 1,
      searchText,
    }).unwrap();
  }, [
    userAccessToken,
    getCreatorsBySearch,
    cardsPerPage,
    page,
    searchCreatorsData,
    items,
    searchText,
  ]);

  useEffect(() => {
    if (page === 0) {
      setItems([]);
    }

    switch (tab) {
      case "top_ranked":
        fetchRankedCreators();
        return;
      case "subscribed":
        fetchSubscribedCreators();
        return;
      case "interested":
        fetchInterestedCreators();
        return;
      case "newest":
        fetchNewestCreators();
        return;
      default: {
        if (searchText === "") {
          return;
        }
        fetchSearchCreators();
      }
    }
    if (items.length > 0) {
      setCurrentCard(items[0].creator);
    }
  }, [tab, page, searchText]);

  useEffect(() => {
    setPage(0);
  }, [tab]);

  const handleSetPreviousCard = useCallback(() => {
    const currentCardIndex = items.findIndex(
      (item) => item.creator.id === currentCard?.id
    );

    if (currentCardIndex === 0) {
      return;
    }

    setCurrentCard(items[currentCardIndex - 1].creator);
  }, [currentCard, items]);

  const handleSetNextCard = useCallback(() => {
    const currentCardIndex = items.findIndex(
      (item) => item.creator.id === currentCard?.id
    );

    if (currentCardIndex < items.length) {
      setCurrentCard(items[currentCardIndex + 1].creator);
    }
  }, [currentCard, items]);

  // Memorize the context value
  const contextValue = useMemo(
    () => ({
      tab,
      // setTab,
      view,
      // setView,
      searchText,
      // setSearchText,
      items,
      setItems,
      currentCard,
      setCurrentCard,
      handleSetPreviousCard,
      handleSetNextCard,
      lastMemberElementRef,
      getLoadingRecords,
    }),
    [
      tab,
      // setTab,
      view,
      // setView,
      searchText,
      // setSearchText,
      items,
      setItems,
      currentCard,
      setCurrentCard,
      handleSetPreviousCard,
      handleSetNextCard,
      lastMemberElementRef,
      getLoadingRecords,
    ]
  );

  return (
    <HomeListContext.Provider value={contextValue}>
      {children}
    </HomeListContext.Provider>
  );
};

// Custom hook to use the CreatorCardContext
export const useHomeListContext = () => useContext(HomeListContext);
