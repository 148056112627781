import { Box, Tooltip, Typography, tooltipClasses } from "@mui/material";
import type { FC } from "react";

import { InfoIcon } from "../../../../media/icons/info";

interface ISecurityPrivacyTooltipProp {
  text: string;
}

export const SecurityPrivacyTooltip: FC<ISecurityPrivacyTooltipProp> = ({
  text,
}) => (
  <Tooltip
    title={
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="5px"
      >
        <Box marginTop="2px">
          <InfoIcon width={15} height={15} color="#4C4B4C" />
        </Box>
        <Typography variant="body2">{text}</Typography>
      </Box>
    }
    placement="top-start"
    arrow
    slotProps={{
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*="top-start"] .${tooltipClasses.tooltip}`]:
            {
              right: 15,
              bottom: 20,
              width: "240px",
              padding: "10px",
              marginBottom: "7px",
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "rgba(76, 75, 76, 1)",
              fontSize: "14px",
              boxShadow: "0px 5px 8px 0px rgba(95, 92, 84, 0.2)",
            },
          [`&.${tooltipClasses.popper}[data-popper-placement*="top-start"] .${tooltipClasses.arrow}`]:
            {
              bottom: -6,
              marginLeft: "8px",
              width: "15px",
              height: "20px",
              borderLeft: "13px solid transparent",
              borderRight: "13px solid transparent",
              borderTop: "20px solid rgba(255, 255, 255, 1)",
              "&::before": {
                display: "none",
              },
            },
        },
      },
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ cursor: "pointer" }}
    >
      <InfoIcon width={18} height={18} />
    </Box>
  </Tooltip>
);
