import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  IAutomaticTransfer,
  RemoveAutomaticTransfer,
  RemoveAutomaticTransferResponse,
} from "./interfaces/automatic-transfers.interface";

export const automaticTransfersApi = createApi({
  reducerPath: "automaticTransfersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    addAutomaticTransfer: build.mutation<
      IAutomaticTransfer,
      IAutomaticTransfer
    >({
      query: (data: IAutomaticTransfer) => ({
        url: ENDPOINTS.TRANSFERS.AUTOMATIC_TRANSFERS,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          automatic_transfer: data.automatic_transfer,
        },
      }),
    }),
    removeAutomaticTransfer: build.mutation<
      RemoveAutomaticTransferResponse,
      RemoveAutomaticTransfer
    >({
      query: (data: RemoveAutomaticTransfer) => ({
        url: `${ENDPOINTS.TRANSFERS.AUTOMATIC_TRANSFERS}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    removeCurrencyTransfer: build.mutation<
      RemoveAutomaticTransferResponse,
      RemoveAutomaticTransfer
    >({
      query: (data: RemoveAutomaticTransfer) => ({
        url: `${ENDPOINTS.TRANSFERS.CURRENCY_TRANSFERS}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const {
  useAddAutomaticTransferMutation,
  useRemoveAutomaticTransferMutation,
  useRemoveCurrencyTransferMutation,
} = automaticTransfersApi;
