import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import type React from "react";
import { useState } from "react";

import type {
  CalendarDateInterface,
  DateInterface,
} from "../../payment-info/payment-history/interfaces/dates.interface";
import RangePicker from "../range-picker/range-picker";
import WeekPicker from "../week-picker/week-picker";
import styles from "./date-filters.module.scss";

interface DateFilterProps {
  date: DateInterface;
  calendarDate: CalendarDateInterface;
  handleChangeDate: (date: string) => void;
  setCalendarDate: (data: CalendarDateInterface) => void;
  setDate: (data: DateInterface) => void;
  disableArrows?: boolean;
  disableSelectingType?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableCurrentDay?: boolean;
}

export default function DateFilters(props: DateFilterProps) {
  const {
    calendarDate,
    date,
    handleChangeDate,
    setCalendarDate,
    setDate,
    disableArrows = false,
    disableSelectingType = false,
    disableFuture = false,
    disablePast = false,
    disableCurrentDay = false,
  } = props;

  const [calendarAnchorEl, setCalendarAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleOpenCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setCalendarAnchorEl(null);
  };
  const openCalendar = Boolean(calendarAnchorEl);

  const renderCalendar = () => {
    switch (date.type) {
      case "week":
        return (
          <WeekPicker
            date={calendarDate}
            setDate={setCalendarDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
          />
        );
      case "month":
        return (
          <DateCalendar
            value={dayjs(calendarDate.startDate)}
            views={["month", "year"]}
            openTo="month"
            disableFuture={disableFuture}
            disablePast={disablePast}
            defaultValue={dayjs().add(1, "day")}
            disableHighlightToday
            onChange={(newValue) =>
              setCalendarDate({
                startDate: dayjs(newValue).startOf("month").toISOString(),
                endDate: dayjs(newValue).endOf("month").toISOString(),
              })
            }
          />
        );
      case "day":
        return (
          <DateCalendar
            value={dayjs(calendarDate.startDate)}
            views={["year", "month", "day"]}
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={
              disableCurrentDay
                ? (d) => dayjs(d).isSame(dayjs(), "day")
                : undefined
            }
            onChange={(newValue) =>
              setCalendarDate({
                startDate: dayjs(newValue).toISOString(),
                endDate: dayjs(newValue).toISOString(),
              })
            }
          />
        );
      case "dateRange":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "24px",
              alignItems: "center",
            }}
          >
            <RangePicker
              date={calendarDate}
              setDate={setCalendarDate}
              disableFuture={disableFuture}
              disablePast={disablePast}
            />
          </Box>
        );
      default:
        return <WeekPicker date={calendarDate} setDate={setCalendarDate} />;
    }
  };

  const handleNext = (type: "day" | "month" | "week") => {
    const startDate = dayjs(calendarDate.startDate)
      .add(1, type)
      .startOf(type)
      .toISOString();
    const endDate = dayjs(date.startDate)
      .add(1, type)
      .endOf(type)
      .toISOString();

    setCalendarDate({ startDate, endDate });
    setDate({ startDate, endDate, type });
  };

  const handlePrev = (type: "day" | "month" | "week") => {
    const startDate = dayjs(calendarDate.startDate)
      .subtract(1, type)
      .startOf(type)
      .toISOString();
    const endDate = dayjs(calendarDate.endDate)
      .subtract(1, type)
      .endOf(type)
      .toISOString();

    setCalendarDate({ startDate, endDate });
    setDate({ startDate, endDate, type });
  };

  return (
    <Box display="flex" gap="10px">
      <Box display="flex" justifyContent="center" alignItems="center">
        {date.type !== "dateRange" && !disableArrows ? (
          <IconButton
            onClick={() => handlePrev(date.type as "day" | "month" | "week")}
            sx={{ padding: "3px" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        ) : null}
        <Button
          sx={{
            backgroundColor: "white",
            padding: "8px 12px",
            borderRadius: "8px",
            width: "max-content",
            border: "1px solid rgba(199, 216, 222, 1)",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.59)",
              border: "1px solid rgb(177, 202, 222)",
            },
          }}
          onClick={handleOpenCalendar}
          data-cy="date-picker"
        >
          <Typography variant="body1" color="#0F70CA">
            {dayjs(date.startDate).format("MM/DD/YYYY")}
            {date.type !== "day"
              ? ` - ${dayjs(date.endDate).format("MM/DD/YYYY")}`
              : null}
          </Typography>
        </Button>
        {date.type !== "dateRange" && !disableArrows ? (
          <IconButton
            onClick={() => handleNext(date.type as "day" | "month" | "week")}
            sx={{ padding: "3px" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        ) : null}
      </Box>
      {openCalendar && (
        <ClickAwayListener onClickAway={handleCloseCalendar}>
          <Popper
            open={openCalendar}
            anchorEl={calendarAnchorEl}
            className={styles.popper}
            disablePortal
            sx={{
              zIndex: 1,
            }}
          >
            <span className={styles.arrow} />
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                marginTop: "-6px",
                padding: "16px 24px",
                boxShadow: "0px 2px 8px 0px rgba(95, 92, 84, 0.5)",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {renderCalendar()}
              </LocalizationProvider>
              <Button
                sx={{ marginTop: "16px" }}
                variant="contained"
                onClick={() => {
                  const isCurrentDay =
                    dayjs(calendarDate.startDate).isBefore(dayjs(), "day") ||
                    dayjs(calendarDate.startDate).isSame(dayjs(), "day");

                  if (disableCurrentDay && isCurrentDay) {
                    setDate({
                      startDate: dayjs(calendarDate.startDate)
                        .add(1, "day")
                        .toISOString(),
                      endDate: dayjs(calendarDate.endDate)
                        .add(1, "day")
                        .toISOString(),
                      type: date.type,
                    });
                  } else {
                    setDate({
                      startDate: calendarDate.startDate,
                      endDate: calendarDate.endDate,
                      type: date.type,
                    });
                  }
                  handleCloseCalendar();
                }}
              >
                Save
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}

      {!disableSelectingType && (
        <Box display="flex" gap="20px">
          <Button
            variant="outlined"
            className={
              date.type === "day"
                ? styles.selectedDateButton
                : styles.dateButton
            }
            onClick={() => handleChangeDate("day")}
            datatype="day"
          >
            <Typography variant="body1" fontWeight={600}>
              Day
            </Typography>
          </Button>
          <Button
            variant="outlined"
            datatype="week"
            className={
              date.type === "week"
                ? styles.selectedDateButton
                : styles.dateButton
            }
            onClick={() => handleChangeDate("week")}
          >
            <Typography variant="body1" fontWeight={600}>
              Week
            </Typography>
          </Button>
          <Button
            variant="outlined"
            datatype="month"
            className={
              date.type === "month"
                ? styles.selectedDateButton
                : styles.dateButton
            }
            onClick={() => handleChangeDate("month")}
          >
            <Typography variant="body1" fontWeight={600}>
              Month
            </Typography>
          </Button>
          <Button
            variant="outlined"
            datatype="dateRange"
            className={
              date.type === "dateRange"
                ? styles.selectedDateButton
                : styles.dateButton
            }
            onClick={() => handleChangeDate("dateRange")}
          >
            <Typography variant="body1" fontWeight={600}>
              Date range
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
