import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { CollectedData } from "../../components/select-payment-method/interfaces/collected-data.interface";
import { ENDPOINTS } from "../../constants/api/api";
import type {
  GetPaymentHistoryInterface,
  PaymentHistoryResponseInterface,
} from "./interfaces/get-payment-history.interface";

export const paymentMethodsApi = createApi({
  reducerPath: "paymentMethods",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    createPaymentMethod: builder.mutation({
      query: (data: CollectedData) => ({
        url: ENDPOINTS.PAYMENT_METHODS,
        method: "POST",
        body: data,
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    getPaymentMethods: builder.mutation({
      query: (data: { accessToken: string }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    makeDefault: builder.mutation({
      query: (data: { id: string; accessToken: string }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/${data.id}`,
        method: "PUT",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    removePaymentMethod: builder.mutation({
      query: (data: { id: string; accessToken: string }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    updatePaymentMethod: builder.mutation({
      query: (data: {
        id: string;
        accessToken: string;
        data: CollectedData;
      }) => ({
        url: `${ENDPOINTS.PAYMENT_METHODS}/${data.id}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
        body: data.data,
      }),
    }),
    fetchPaymentHistory: builder.mutation<
      PaymentHistoryResponseInterface,
      GetPaymentHistoryInterface
    >({
      query: (data: GetPaymentHistoryInterface) => ({
        url: `${ENDPOINTS.TRANSACTIONS}/`,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
        },
        body: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
  }),
});

export const {
  useCreatePaymentMethodMutation,
  useGetPaymentMethodsMutation,
  useMakeDefaultMutation,
  useRemovePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useFetchPaymentHistoryMutation,
} = paymentMethodsApi;
