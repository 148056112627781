import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

import SettingsIcon from "../../../media/icons/catalog/content-card/settings-icon";
import { IFolder } from "../../../services/catalog/interfaces/catalog-folders.interfaces";

interface IFolderBreadcrumbsProps {
  folder: IFolder;
  onFolderClick: (folderId: string | null) => void;
  isBigFolderNames?: boolean;
  isDisplayFolderPopper?: boolean;
  onRenameClick?: () => void;
  onCreateFolderClick?: () => void;
  onFolderMove?: () => void;
}

export const FolderBreadcrumbs: FC<IFolderBreadcrumbsProps> = ({
  folder,
  onFolderClick,
  isBigFolderNames = true,
  isDisplayFolderPopper = false,
  onRenameClick,
  onCreateFolderClick,
  onFolderMove,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  // Start popover handling
  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    if (onRenameClick) {
      onRenameClick();
    }
    setAnchorEl(null);
  };

  const handleNewFolder = () => {
    if (onCreateFolderClick) {
      onCreateFolderClick();
    }
    setAnchorEl(null);
  };

  const handleAddToFolder = () => {
    if (onFolderMove) {
      onFolderMove();
    }
    setAnchorEl(null);
  };

  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="medium" />}
      >
        <Typography
          color="#3A75E9"
          variant="body2"
          sx={{
            cursor: "pointer",
            userSelect: "none",

            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => {
            onFolderClick(null);
          }}
        >
          Catalog
        </Typography>

        {folder.path.map((path, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
              maxWidth: "300px",
            }}
          >
            <Tooltip arrow placement="bottom" title={path.name}>
              <Typography
                color={folder.id === path.id ? "#3C3C3C" : "#3A75E9"}
                fontSize={isBigFolderNames ? "24px" : "inherit"}
                sx={{
                  cursor:
                    index === folder.path.length - 1 ? "initial" : "pointer",
                  userSelect: "none",

                  "&:hover": {
                    textDecoration:
                      folder.id === path.id ? "none" : "underline",
                  },

                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "hidden",
                  textWrap: "nowrap",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (folder.id !== path.id) {
                    onFolderClick(path.id);
                  }
                }}
              >
                {path.name}
              </Typography>
            </Tooltip>

            {index === folder.path.length - 1 && isDisplayFolderPopper && (
              <>
                <IconButton onClick={handleOpenPopover}>
                  <SettingsIcon />
                </IconButton>
              </>
            )}
          </Box>
        ))}
      </Breadcrumbs>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "&:nth-of-type(2)>.MuiBackdrop-root": {
            backdropFilter: "none",
            filter: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "237px",
          }}
        >
          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleRename}
          >
            <Typography variant="button" color="#252733">
              Rename
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleNewFolder}
          >
            <Typography variant="button" color="#252733">
              New Folder
            </Typography>
          </Button>

          <Button
            type="button"
            variant="text"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
            onClick={handleAddToFolder}
          >
            <Typography variant="button" color="#252733">
              Add to folder
            </Typography>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
