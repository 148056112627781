import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { ITransferHistoryInterface } from "../../../services/transfer-accounts/interfaces/transfer-history.interface";

interface TransferHistoryState {
  transferHistory: ITransferHistoryInterface[];
}

const initialState: TransferHistoryState = {
  transferHistory: [],
};

export const transferHistoryReducer = createSlice({
  name: "transferHistoryReducer",
  initialState,
  reducers: {
    setTransferHistory(
      state,
      action: PayloadAction<ITransferHistoryInterface[]>
    ) {
      state.transferHistory = action.payload;
    },
  },
});

export default transferHistoryReducer.reducer;
