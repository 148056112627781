import type {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import type { FC } from "react";
import { useCallback } from "react";

import type { IUploadFile } from "../../../../interfaces/file-uploading/uploaded-files.interface";
import { DeleteIcon } from "../../../../media/icons/delete-icon";
import { DragIcon } from "../../../../media/icons/drag";
import { FileIcon } from "../../../../media/icons/file";
import { UploaderIndicator } from "../../../upload-content/uploader-indicator/uploader-indicator";
import styles from "./file-list-item.module.scss";

interface IFileListItemProp {
  file: IUploadFile;
  onDescriptionUpdate?: (fileId: string, description: string) => void;
  onFileDelete: (fileId: string) => void;
  draggable?: boolean;
  isDragging?: boolean;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
}

export const FileListItem: FC<IFileListItemProp> = ({
  file,
  onDescriptionUpdate,
  onFileDelete,
  draggable,
  isDragging,
  listeners,
  attributes,
}) => {
  const renderImage = useCallback(() => {
    if (file.fileType === "video") {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          src={file.fileUrl}
          className={styles.file}
          draggable={false}
          preload="metadata"
        />
      );
    }

    if (file.fileType === "image") {
      return (
        <img
          src={file.fileUrl}
          alt={file.fileName}
          className={styles.file}
          draggable={false}
        />
      );
    }

    return (
      <>
        <FileIcon width={30} height={30} />
      </>
    );
  }, []);

  return (
    <div
      className={`${styles.fileCard} ${file.status === "uploading" && styles.fileCardUploading}`}
      data-cy="file-card"
    >
      <div className={styles.preview}>
        {(file.status === "uploading" || file.status === "uploaded") && (
          <>
            <Box
              sx={{
                position: "absolute",
                zIndex: "3",
              }}
            >
              <UploaderIndicator
                percentage={file.progressOfUploading || 0}
                radius={20}
              />
            </Box>

            <div className={styles.backgroundLoader} />
          </>
        )}
        {renderImage()}
      </div>
      <div className={styles.info}>
        {onDescriptionUpdate ? (
          <>
            <TextField
              className={styles.description}
              data-cy="description"
              placeholder="Enter description..."
              multiline
              variant="outlined"
              value={file.description}
              disabled={file.status === "uploading"}
              sx={{
                "& fieldset": {
                  border: "none",
                },
              }}
              InputProps={{
                sx: {
                  display: "flex",
                  alignItems: "baseline",
                  padding: "0",
                  height: "100%",
                },
                inputProps: {
                  maxLength: 300,
                  style: {
                    fontSize: "12px",
                    lineHeight: "18px",
                  },
                },
              }}
              onChange={(e) => {
                onDescriptionUpdate(file.id, e.target.value);
              }}
            />
            <Tooltip arrow title={file.fileName} placement="bottom">
              <Typography
                variant="body2"
                fontSize="12px"
                lineHeight="18px"
                marginTop="5px"
                height="100%"
                className={styles.fileName}
              >
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.fileType === "video" && <span>&#9658;</span>}
                  {file.fileName}
                </span>
              </Typography>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip arrow title={file.fileName} placement="top">
              <Typography
                variant="body2"
                fontSize="15px"
                overflow="hidden"
                className={styles.fileName}
                color="rgba(37, 39, 51, 1)"
              >
                <span
                  style={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.fileType === "video" && <span>&#9658;</span>}
                  {file.fileName}
                </span>
              </Typography>
            </Tooltip>
          </>
        )}
      </div>
      <div className={styles.delete}>
        {draggable && (
          <IconButton
            {...listeners}
            {...attributes}
            color="primary"
            size="small"
            disableRipple
            data-cy="drag"
            disabled={file.status === "uploading" || file.status === "uploaded"}
            sx={{
              cursor: isDragging ? "grabbing" : "grab",
            }}
          >
            <DragIcon width={20} height={13} />
          </IconButton>
        )}
        <IconButton
          color="primary"
          onClick={() => {
            onFileDelete(file.id);
          }}
          size="small"
          disableRipple
          data-cy="delete"
          disabled={file.status === "uploading" || file.status === "uploaded"}
        >
          <DeleteIcon width={20} height={20} />
        </IconButton>
      </div>
    </div>
  );
};
