import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../hooks/redux";
import { ProtectedRoute } from "../protected-route";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const CreatorRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, isAuthenticated } = useAppSelector((data) => data.userReducer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user && user.role !== "creator") {
      // Redirect to login or not authorized page
      navigate("/home", { state: { from: location } });
    }
  }, [user, navigate, location]);
  if (isAuthenticated) {
    return user && user.role === "creator" ? (
      <ProtectedRoute>{children}</ProtectedRoute>
    ) : (
      <Navigate to="/home" state={{ from: location }} />
    );
  }
  return <ProtectedRoute>{children}</ProtectedRoute>;
};

export default CreatorRoute;
