import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { FC } from "react";

import type { IHomeCreatorCard } from "../../../services/home/interfaces/home.interface";
import { HomeListItem } from "./home-list-item";

interface IHomeListSortableItemProp {
  itemData: IHomeCreatorCard;
  draggable: boolean;
  id: string;
}

export const HomeListSortableItem: FC<IHomeListSortableItemProp> = ({
  itemData,
  draggable,
  id,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div style={style} ref={setNodeRef}>
      <HomeListItem
        itemData={itemData}
        draggable={draggable}
        isDragging={isDragging}
        listeners={listeners}
        attributes={attributes}
      />
    </div>
  );
};
