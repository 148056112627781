import type { Theme } from "@mui/material";
import { createTheme as createMuiTheme } from "@mui/material";
import type { Components } from "@mui/material/styles/components";
import type { Palette } from "@mui/material/styles/createPalette";
import type { TypographyOptions } from "@mui/material/styles/createTypography";

import createComponents from "./create-components";
import createPalette from "./create-palette";
import { createTypography } from "./create-typohraphy";

export default function createTheme() {
  const palette: any = createPalette();
  const components: Components<Theme> = createComponents({ palette });
  const typography: TypographyOptions | ((pal: Palette) => TypographyOptions) =
    createTypography();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components,
    palette,
    shape: {
      borderRadius: 8,
    },
    typography,
  });
}
