import { useCallback, useEffect, useState } from "react";

import type { CurrenciesEnum } from "../components/automatic-transfers/enums/currencies.enum";
import type { IWallet } from "../services/wallet/interfaces/fetch-wallets-response.interface";

interface IWalletCurrencyArray {
  type: CurrenciesEnum;
  _sum: {
    amount: number | null;
  };
}

export default function useWalletArray(
  walletObj: IWallet | undefined,
  includeNullValue: boolean
) {
  const [walletArray, setWalletArray] = useState<IWalletCurrencyArray[]>([]);

  const convertToObjectArray = useCallback((): IWalletCurrencyArray[] => {
    if (!walletObj) return [];

    return Object.entries(walletObj)
      .map(([type, data]) => {
        // @ts-ignore
        const { _sum } = data;
        return { type: type as CurrenciesEnum, _sum };
      })
      .filter((item) => (includeNullValue ? item : item._sum.amount));
  }, [walletObj, includeNullValue]);

  useEffect(() => {
    setWalletArray(convertToObjectArray());
  }, [walletObj]);

  return {
    walletArray,
  };
}
