import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { ITransactionHistory } from "../../../services/earnings/interfaces/transaction-history-response.interface";

interface ITransactionHistoryState {
  transactionHistory: ITransactionHistory[];
}

const initialState: ITransactionHistoryState = {
  transactionHistory: [],
};

export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    setTransactionHistory(state, action: PayloadAction<ITransactionHistory[]>) {
      state.transactionHistory = action.payload;
    },
  },
});

export default transactionHistorySlice.reducer;
