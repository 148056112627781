import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import { CrossIcon } from "../../../../../../media/icons/cross";
import { PlusIcon } from "../../../../../../media/icons/plus";
import {
  useAddContentToCollectionsMutation,
  useGetContentCatalogMeMutation,
} from "../../../../../../services/catalog/catalog.service";
import { Content } from "../../../../../../services/catalog/interfaces/catalog.interfaces";
import { ModalWrapper } from "../../../../../creator-card/modal-wrapper/modal-wrapper";
import { useCreatorCatalogCCViewContext } from "../../context/creator-catalog-cc-view-context";
import { SelectCollectionItem } from "./select-collection-item/select-collection-item";
import styles from "./select-collection.module.scss";

interface ISelectCollectionProp {
  isOpen: boolean;
  onCancelClick: () => void;
  onCollectionCreateClick: () => void;
}

export const SelectCollection: FC<ISelectCollectionProp> = ({
  isOpen,
  onCancelClick,
  onCollectionCreateClick,
}) => {
  const {
    fetchContent,
    selectedCreatorContentItems,
    setSelectedCreatorContentItems,
    setCollectionMediaDisplay,
  } = useCreatorCatalogCCViewContext();

  const [collections, setCollections] = useState<Content[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<Content[]>([]);

  const [getContentCatalogMe] = useGetContentCatalogMeMutation();
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [
    addContentToCollections,
    { isLoading: isAddContentLoading, isSuccess: isAddContentSuccess },
  ] = useAddContentToCollectionsMutation();

  const onCreateNewCollectionClick = () => {
    const selectedMedia = selectedCreatorContentItems.flatMap(
      (item) => item.content_media
    );

    onCollectionCreateClick();
    setCollectionMediaDisplay(selectedMedia);
    setSelectedCollections([]);
  };

  const fetchContentCatalogCollectionsMe = useCallback(async () => {
    await getContentCatalogMe({
      accessToken: userAccessToken != null ? userAccessToken : "",
      page: 1,
      rowsPerPage: 20,
      searchText: "",

      // Filters
      filters: {
        audience: [],
        show: [],
        contentType: ["collection"],
        visibility: [],
        contentDetails: [],
      },

      // Sorting
      sortBy: "all",
      direction: null,

      // Active tab
      currentTab: "all_posts",
    })
      .unwrap()
      .then((data) => {
        if (data.data.length === 0) {
          onCreateNewCollectionClick();
        }
        setCollections(data.data);
      });
  }, [getContentCatalogMe, onCreateNewCollectionClick]);

  const handleAddContentToCollection = useCallback(async () => {
    setSelectedCollections([]);
    const mediaIds = selectedCreatorContentItems
      .flatMap((item) =>
        item.content_media?.map((mediaItem) => mediaItem.media.id)
      )
      .filter((item) => item !== undefined) as string[];

    await addContentToCollections({
      accessToken: userAccessToken != null ? userAccessToken : "",
      media_ids: mediaIds,
      collection_ids: selectedCollections.map((item) => item.id),
    });
  }, [
    addContentToCollections,
    selectedCreatorContentItems,
    selectedCollections,
    userAccessToken,
    setSelectedCollections,
  ]);

  useEffect(() => {
    if (isOpen) {
      fetchContentCatalogCollectionsMe();
    }
  }, [isOpen]);

  const handleCheckboxClick = (selectedCollection: Content) => {
    setSelectedCollections((prevState) => {
      const isItemExist = prevState.find(
        (item) => item.id === selectedCollection.id
      );

      if (!isItemExist) {
        return [...prevState, selectedCollection];
      }

      return prevState.filter((item) => item.id !== selectedCollection.id);
    });
  };

  useEffect(() => {
    if (isAddContentSuccess) {
      setSelectedCreatorContentItems([]);
      onCancelClick();
      fetchContent();
    }
  }, [isAddContentSuccess]);

  return (
    <>
      <ModalWrapper shown={isOpen} close={onCancelClick} disableBackdropClose>
        {collections.length > 0 && (
          <Box className={styles.selectCollectionWrapper}>
            <Box className={styles.selectCollection}>
              <Box className={styles.header}>
                <Typography fontSize="24px">Add to Collection</Typography>
                <IconButton
                  onClick={() => {
                    setSelectedCreatorContentItems([]);
                    setSelectedCollections([]);
                    onCancelClick();
                  }}
                >
                  <CrossIcon />
                </IconButton>
              </Box>
            </Box>

            <Box className={styles.contentCollectionWrapper}>
              {collections.map((content) => (
                <SelectCollectionItem
                  key={content.id}
                  content={content}
                  selectedCollections={selectedCollections}
                  handleCheckboxClick={handleCheckboxClick}
                />
              ))}
            </Box>

            <Box className={styles.createCollectionBtnWrapper}>
              <Button
                variant="text"
                color="primary"
                className={styles.createCollectionBtn}
                onClick={onCreateNewCollectionClick}
              >
                <PlusIcon width={18} height={18} />
                <Typography variant="button">Create new Collection</Typography>
              </Button>
            </Box>

            <Box className={styles.btnInteraction}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  selectedCollections.length === 0 || isAddContentLoading
                }
                sx={{
                  width: "237px",
                }}
                onClick={handleAddContentToCollection}
              >
                {isAddContentLoading ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  <Typography variant="button">Add</Typography>
                )}
              </Button>
            </Box>
          </Box>
        )}
      </ModalWrapper>
    </>
  );
};
