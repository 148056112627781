import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import type { FC } from "react";
import { useEffect, useState } from "react";

import type { ISubmitState } from "../../../../../hooks/submit-state";

interface IBirthdaySpecial {
  birthdayEnabled: boolean;
  value: string | undefined;
  canChange: boolean;
  onUpdateBirthdaySpecialDate: (date: string | null) => Promise<void>;
  submitState: ISubmitState;
  resetState: () => void;
  isLoading: boolean;
}

export const BirthdaySpecial: FC<IBirthdaySpecial> = ({
  birthdayEnabled,
  value,
  canChange,
  onUpdateBirthdaySpecialDate,
  submitState,
  resetState,
  isLoading,
}) => {
  const [date, setDate] = useState<null | string>(null);
  const [birthdayError, setBirthdayError] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const onApplyClick = () => {
    onUpdateBirthdaySpecialDate(date);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          disabled={!canChange || !birthdayEnabled}
          value={date ? dayjs(date) : null}
          fullWidth
          InputProps={{
            placeholder: "MM/DD/YYYY",
          }}
          onChange={(dateValue) => {
            const enteredDate = dayjs(dateValue).valueOf();
            const currentDate = dayjs(new Date()).valueOf();

            if (!dateValue) {
              setDate(null);
              setBirthdayError(false);
              return;
            }

            if (
              enteredDate &&
              currentDate &&
              currentDate - enteredDate > 0 &&
              dayjs(enteredDate).year() > 1000
            ) {
              setBirthdayError(false);
              setDate(dayjs(dateValue).toISOString());
              return;
            }

            setBirthdayError(true);
          }}
          disableFuture
        />
      </LocalizationProvider>
      {birthdayEnabled && canChange && (
        <>
          <Box>
            <Typography
              color="#FF5252"
              fontSize="14px"
              sx={{ display: "inline" }}
              fontWeight="bold"
            >
              WARNING!{"  "}
            </Typography>
            <Typography
              color="#252733"
              fontSize="14px"
              sx={{ display: "inline" }}
            >
              If you activate this option, you can&apos;t change it again for
              one year.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ width: "108px" }}
              variant="contained"
              disabled={birthdayError}
              color={submitState.color}
              onClick={(e) => {
                if (submitState.color === "error") {
                  e.preventDefault();
                  resetState();
                  return;
                }

                if (submitState.color === "success") {
                  e.preventDefault();
                  return;
                }

                resetState();
                onApplyClick();
              }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                <Typography variant="button">{submitState.text}</Typography>
              )}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
