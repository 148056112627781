import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../../constants/local-storage-keys/local-storage-keys";
import { FolderIcon } from "../../../../../../../media/icons/catalog/folder/folder";
import { CrossIcon } from "../../../../../../../media/icons/cross";
import { PlusIcon } from "../../../../../../../media/icons/plus";
import { SearchInCircleIcon } from "../../../../../../../media/icons/search-in-circle";
import {
  useCreateFolderMutation,
  useGetFoldersByIdMutation,
  useUpdateFolderMutation,
} from "../../../../../../../services/catalog/catalog.service";
import { IFolder } from "../../../../../../../services/catalog/interfaces/catalog-folders.interfaces";
import { ModalWrapper } from "../../../../../../creator-card/modal-wrapper/modal-wrapper";
import { FolderBreadcrumbs } from "../../../../../../shared/folder-breadcrumbs/folder-breadcrumbs";
import { useCreatorCatalogCCViewContext } from "../../creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../../folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { FolderCreateForm } from "../folder-create-form/folder-create-form";
import styles from "./add-to-folder.module.scss";

interface IAddToFolderProps {
  isOpen: boolean;
  folderToMove?: IFolder;
  onClose?: () => void;
}

export const AddToFolderForm: FC<IAddToFolderProps> = ({
  isOpen,
  folderToMove,
  onClose,
}) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const {
    setSelectedContentToFolder,
    selectedContentToFolder,
    getFoldersById: getFoldersByIdCatalog,
    currentOpenedFolder,
  } = useFolderCreatorCatalogCCViewContext();

  const [targetFolder, setTargetFolder] = useState<IFolder | null>(null);
  const [folders, setFolders] = useState<IFolder[]>([]);
  const [currentFolder, setCurrentFolder] = useState<IFolder | null>(null);
  const [isCreateNewFolderOpen, setIsCreateNewFolderOpen] =
    useState<boolean>(false);

  const { fetchContent } = useCreatorCatalogCCViewContext();

  const [searchText, setSearchText] = useState<string>("");
  const typingTimeout = useRef<any>(null);

  const formik = useFormik<any>({
    initialValues: {
      searchName: "",
    },
    onSubmit: async (values) => {
      setSearchText(values.searchName);
    },
  });

  const [
    getFolders,
    { data: getAllFoldersByIdData, isLoading: getAllFoldersByIdLoading },
  ] = useGetFoldersByIdMutation();

  const getFoldersById = useCallback(
    async (folderId?: string, searchQuery?: string) => {
      await getFolders({
        accessToken: userAccessToken || "",
        folderId,
        searchText: searchQuery,
      });
    },
    [getFolders]
  );

  const [
    updateFolderById,
    { isLoading: updateFolderByIdLoading, isSuccess: updateFolderByIdSuccess },
  ] = useUpdateFolderMutation();

  const [
    createFolder,
    { isLoading: createFolderLoading, isSuccess: createFolderSuccess },
  ] = useCreateFolderMutation();

  const addContentToFolder = useCallback(async () => {
    if (targetFolder && selectedContentToFolder) {
      await updateFolderById({
        accessToken: userAccessToken || "",
        folderId: targetFolder.id,
        content_id: selectedContentToFolder.id,
      });
    }
  }, [updateFolderById, targetFolder, folders, userAccessToken]);

  const addFolderToFolder = useCallback(async () => {
    if (targetFolder && folderToMove) {
      await updateFolderById({
        accessToken: userAccessToken || "",
        folderId: folderToMove.id,
        destination_folder_id: targetFolder.id,
      });
    }
  }, [updateFolderById, targetFolder, folders, userAccessToken]);

  const createNewFolder = useCallback(
    async (folderName: string) => {
      await createFolder({
        accessToken: userAccessToken || "",
        folderName,
        parent_folder_id: currentFolder?.id ? currentFolder.id : null,
      });
    },
    [createFolder, currentFolder, userAccessToken]
  );

  const handleSearchTextChange = (e: Object) => {
    clearTimeout(typingTimeout.current);

    formik.handleChange(e);

    typingTimeout.current = setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    } else {
      getFoldersById(currentFolder?.id);
    }
  }, [isOpen]);

  useEffect(() => {
    getFoldersById(currentFolder?.id, searchText);
  }, [searchText]);

  useEffect(() => {
    setFolders(getAllFoldersByIdData?.data || []);

    if (!searchText) {
      setCurrentFolder(getAllFoldersByIdData?.currentFolder || null);
    }
  }, [getAllFoldersByIdData]);

  useEffect(() => {
    setCurrentFolder(currentOpenedFolder);
  }, [currentOpenedFolder]);

  useEffect(() => {
    if (updateFolderByIdSuccess) {
      if (onClose) {
        onClose();
      }
      setTargetFolder(null);
      setSelectedContentToFolder(null);
      fetchContent();
      getFoldersByIdCatalog(
        currentOpenedFolder?.id ? currentOpenedFolder.id : undefined
      );
    }
  }, [updateFolderByIdSuccess]);

  useEffect(() => {
    if (createFolderSuccess) {
      setIsCreateNewFolderOpen(false);
      getFoldersById(currentFolder?.id);
    }
  }, [createFolderSuccess]);

  const onCheckboxChange = (folder: IFolder) => {
    if (targetFolder?.id === folder.id) {
      setTargetFolder(null);
      return;
    }
    setTargetFolder(folder);
  };

  const handleAddContentToFolder = () => {
    addContentToFolder();
  };

  const handleAddFolderToFolder = () => {
    addFolderToFolder();
  };

  const onCreateNewFolder = useCallback(
    async (folderName: string) => {
      let name = folderName;

      if (!name) {
        const folderWithDefaultName = folders.find((item) =>
          item.name.match(/^Folder \d+$/)
        );

        if (!folderWithDefaultName) {
          name = `Folder 1`;
        } else {
          const [, number] = folderWithDefaultName.name.split(" ");
          name = `Folder ${Number(number) + 1}`;
        }
      }

      await createNewFolder(name);
    },
    [createNewFolder, folders]
  );

  const onBreadcrumbsFolderClick = (folderId: string | null) => {
    formik.setFieldValue("searchName", "");
    setSearchText("");
    setTargetFolder(null);
    getFoldersById(folderId || undefined);
  };

  const onFolderOpenClick = (folder: IFolder) => {
    formik.setFieldValue("searchName", "");
    setSearchText("");
    setTargetFolder(null);
    getFoldersById(folder?.id);
  };

  return (
    <>
      <ModalWrapper
        shown={isOpen}
        close={() => {
          setTargetFolder(null);
          setSelectedContentToFolder(null);
          if (onClose) {
            onClose();
          }
        }}
        disableBackdropClose
      >
        <Box className={styles.addToFolderWrapper}>
          <Box className={styles.addToFolder}>
            <Box className={styles.header}>
              <Typography fontSize="24px">Add to folder</Typography>
              <Box className={styles.searchSection}>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  className={styles.form}
                >
                  <TextField
                    sx={{
                      width: "287px",
                    }}
                    variant="outlined"
                    fullWidth
                    name="searchName"
                    placeholder="Search folders"
                    error={!!formik.errors.searchName}
                    value={formik.values.searchName}
                    onChange={handleSearchTextChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formik.values.searchName.length > 0 ? (
                            <IconButton
                              onClick={() => {
                                formik.setFieldValue("searchName", "");
                                formik.submitForm();
                              }}
                            >
                              <CrossIcon />
                            </IconButton>
                          ) : (
                            <SearchInCircleIcon height={24} width={24} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>

                <IconButton
                  onClick={() => {
                    setTargetFolder(null);
                    setSelectedContentToFolder(null);
                    if (onClose) {
                      onClose();
                    }
                  }}
                >
                  <CrossIcon />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.body}>
              {(folderToMove || selectedContentToFolder) &&
                currentFolder !== null && (
                  <FolderBreadcrumbs
                    folder={currentFolder}
                    onFolderClick={onBreadcrumbsFolderClick}
                    isBigFolderNames={false}
                  />
                )}

              <Typography fontSize="14px">Select folder</Typography>

              <Box className={styles.folderList}>
                {getAllFoldersByIdLoading ? (
                  <LinearProgress sx={{ width: "100%" }} />
                ) : (
                  <>
                    {folders.filter((item) => item.id !== folderToMove?.id)
                      .length === 0 ? (
                      <Box
                        sx={{
                          width: "100%",
                          padding: "20px 0",
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          color="#AAAAAA"
                          textAlign="center"
                        >
                          No folders found
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {folders
                          .filter((item) => item.id !== folderToMove?.id)
                          .map((item) => (
                            <Box
                              key={item.id}
                              className={styles.folder}
                              onClick={() => onFolderOpenClick(item)}
                            >
                              <Tooltip arrow placement="top" title={item.name}>
                                <Box className={styles.folderName}>
                                  <FolderIcon width={20} height={16} />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      textOverflow: "ellipsis",
                                      width: "100%",
                                      overflow: "hidden",
                                      textWrap: "nowrap",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Tooltip>
                              <Box className={styles.checkbox}>
                                <Checkbox
                                  checked={targetFolder?.id === item.id}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    onCheckboxChange(item);
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              </Box>
                            </Box>
                          ))}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box className={styles.addMoreBtnWrapper}>
              <Button
                variant="text"
                color="primary"
                className={styles.addMoreBtn}
                onClick={() => {
                  setIsCreateNewFolderOpen(true);
                }}
              >
                <PlusIcon width={18} height={18} />
                <Typography variant="button">New folder</Typography>
              </Button>
            </Box>
            <Box className={styles.footer}>
              <Button
                variant="contained"
                sx={{ width: "237px" }}
                disabled={updateFolderByIdLoading || !targetFolder}
                onClick={() => {
                  if (folderToMove) {
                    handleAddFolderToFolder();
                  } else {
                    handleAddContentToFolder();
                  }
                }}
              >
                {updateFolderByIdLoading ? (
                  <CircularProgress color="inherit" size="1.6rem" />
                ) : (
                  <Typography variant="button">Add</Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
      <FolderCreateForm
        isOpen={isCreateNewFolderOpen}
        isLoading={createFolderLoading}
        onSaveCreateClick={onCreateNewFolder}
        onCancelCreateClick={() => {
          setIsCreateNewFolderOpen(false);
        }}
      />
    </>
  );
};
