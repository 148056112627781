import { Box, Button, Chip, LinearProgress, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { CloseIcon } from "../../../media/icons/close";
import {
  useCancelInvitationMutation,
  useGetInvitationsMutation,
} from "../../../services/invitations/invitations.service";
import { invitationsSlice } from "../../../store/reducers/invitations/invitations";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import { InvitationStatuses } from "../enums/invitation-statuses.enum";
import type { InvitationListInterface } from "../interfaces/invitation-list.interface";
import styles from "./invitations-list.module.scss";
import { invitationsTableHead } from "./invitations-table-head";

export default function InvitesList() {
  const rowsPerPage = 5;
  const { invitations } = useAppSelector(
    (reducers) => reducers.invitationsReducer
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [getInvitationsLice, { isLoading, error, data: invitationData }] =
    useGetInvitationsMutation();

  const [canceInvitation, { isLoading: cancelLoading }] =
    useCancelInvitationMutation();

  const { setInvitations, cancelInvitation } = invitationsSlice.actions;

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    invitations,
    invitationData?.count || 0,
    invitationsTableHead
  );

  const fetchInvitations = useCallback(async () => {
    try {
      await getInvitationsLice({
        accessToken: accessToken || "",
        direction,
        page: page + 1,
        sortBy,
        rowsPerPage,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [accessToken, getInvitationsLice, rowsPerPage, direction, sortBy, page]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    fetchInvitations();
  }, [fetchInvitations, page]);

  useEffect(() => {
    if (invitationData) {
      if (page === 0) {
        dispatch(setInvitations(invitationData.data));
        return;
      }

      dispatch(setInvitations([...invitations, ...invitationData.data]));
    }
  }, [invitationData]);

  useEffect(() => {
    dispatch(setInvitations([]));
    setPage(0);
  }, [sortBy, direction]);

  const handleCancelInvitation = async (id: string) => {
    const canceled = await canceInvitation({
      id,
      accessToken: accessToken || "",
    }).unwrap();

    if (canceled.success) {
      dispatch(cancelInvitation({ id }));
    }
  };

  const renderChipColor = (status: string) => {
    switch (status) {
      case InvitationStatuses.ACCEPTED:
        return "success";
      case InvitationStatuses.PENDING:
        return "warning";
      case InvitationStatuses.CANCELED:
        return "error";
      default:
        return "success";
    }
  };

  const renderText = (status: string) => {
    switch (status) {
      case InvitationStatuses.ACCEPTED:
        return <Typography variant="body1">Accepted</Typography>;
      case InvitationStatuses.PENDING:
        return <Typography variant="body1">Pending</Typography>;
      case InvitationStatuses.CANCELED:
        return <Typography variant="body1">Canceled</Typography>;
      default:
        return "-";
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="8px"
      >
        <Typography variant="h4" fontWeight={400}>
          Invitation status
        </Typography>
        <Table
          sx={{
            minWidth: 650,
            maxHeight: "100px",
            overflow: "hidden",
            width: "89%",
          }}
          aria-label="simple table"
        >
          <CustomTableHead
            tableHeads={invitationsTableHead}
            sortBy={sortBy}
            setSortBy={setSortBy}
            direction={direction}
            setDirection={setDirection}
          />

          {(!isLoading || !cancelLoading) && (
            <TableBody>
              {invitations.map((row: InvitationListInterface, idx) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  ref={
                    invitations.length === idx + 1 ? lastMemberElementRef : null
                  }
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={styles.tableCell}
                    sx={{
                      width: "10%",
                    }}
                  >
                    <Typography variant="body1">{idx + 1}.</Typography>
                  </TableCell>
                  <TableCell
                    className={styles.tableCell}
                    align="left"
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Typography variant="body1">{row.email}</Typography>
                  </TableCell>
                  <TableCell
                    className={styles.tableCell}
                    align="left"
                    sx={{
                      width: "10%",
                    }}
                  >
                    <Chip
                      label={renderText(row.status)}
                      color={renderChipColor(row.status)}
                      variant="outlined"
                      className={styles.chip}
                    />
                  </TableCell>
                  <TableCell
                    className={`${styles.tableCell} ${styles.tableCellCancel}`}
                    align="center"
                    sx={{
                      width: "40%",
                    }}
                  >
                    {row.status === InvitationStatuses.PENDING ? (
                      <Button
                        variant="outlined"
                        color="error"
                        className={styles.rescindButton}
                        onClick={() => handleCancelInvitation(row.id)}
                      >
                        <CloseIcon color="#FF5252" /> Cancel invitation
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {(isLoading || cancelLoading) && (
          <LinearProgress sx={{ width: "100%" }} />
        )}
      </Box>
    </>
  );
}
