import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  LinearProgress,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { getReadableDateTable } from "../../../helpers/helpers";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { EditIcon } from "../../../media/icons/edit";
import type {
  IGroup,
  IMember,
} from "../../../services/manage-subscribers/interfaces/fetch-groups-response.interface";
import { useFetchGroupsMutation } from "../../../services/manage-subscribers/manage-subscribers.service";
import { subscriberGroupsSlice } from "../../../store/reducers/manage-subscribers/subscriber-groups";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import { DefaultAvatar } from "../../shared/default-avatar/default-avatar";
import styles from "../manage-subscribers.module.scss";
import { AddGroupModal } from "./add-group-modal";
import { subscriberGroupsTableHead } from "./subscriber-groups-table-head";
import { UpdateGroupModal } from "./update-group-modal";

type SubscriberGroupListProps = {
  searchText: string;
  isDialogAddOpened: boolean;
  setIsDialogAddOpened: Dispatch<SetStateAction<boolean>>;
};

export const SubscriberGroupsList: FC<SubscriberGroupListProps> = ({
  searchText,
  isDialogAddOpened,
  setIsDialogAddOpened,
}) => {
  const rowsPerPage = 5;

  const [editGroupId, setEditGroupId] = useState("");
  const [isDialogUpdateOpened, setIsDialogUpdateOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { setSubscriberGroups } = subscriberGroupsSlice.actions;
  const { subscriberGroups } = useAppSelector(
    (reducers) => reducers.subscriberGroupsReducer
  );

  const [fetchGroups, { error, isLoading, data: groupsData }] =
    useFetchGroupsMutation();

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    subscriberGroups,
    groupsData?.count || 0,
    subscriberGroupsTableHead
  );

  const getGroups = useCallback(async () => {
    try {
      await fetchGroups({
        accessToken: accessToken || "",
        rowsPerPage,
        page: page + 1,
        direction,
        sortBy,
        searchText,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [
    accessToken,
    fetchGroups,
    rowsPerPage,
    direction,
    sortBy,
    page,
    searchText,
  ]);

  const updateGroupList = useCallback(async () => {
    setEditGroupId("");
    dispatch(setSubscriberGroups([]));
    setPage(0);
    await getGroups();
  }, [getGroups]);

  useEffect(() => {
    getGroups();
  }, [rowsPerPage, direction, sortBy, page, searchText]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    if (!groupsData?.data) return;

    if (page === 0) {
      dispatch(setSubscriberGroups(groupsData.data));
      return;
    }

    dispatch(setSubscriberGroups([...subscriberGroups, ...groupsData.data]));
  }, [groupsData]);

  useEffect(() => {
    dispatch(setSubscriberGroups([]));
    setPage(0);
  }, [sortBy, searchText, direction]);

  return (
    <>
      <Table aria-label="simple table">
        <CustomTableHead
          tableHeads={subscriberGroupsTableHead}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
        />

        <TableBody>
          {subscriberGroups.map((row: IGroup, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              ref={
                subscriberGroups.length === index + 1
                  ? lastMemberElementRef
                  : null
              }
            >
              <TableCell
                className={`${styles.tableCell} ${styles.groupsListTableCell}`}
                sx={{ width: "35%" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  gap="0.5rem"
                >
                  <AvatarGroup
                    max={3}
                    sx={{
                      "& .MuiAvatarGroup-avatar:not(:has(> div))": {
                        width: 32,
                        height: 32,
                        background: "rgba(37, 39, 51, 1)",
                      },
                    }}
                  >
                    {row.group_members?.map((groupMember: IMember) => (
                      <Avatar
                        alt={groupMember.user?.username}
                        src={groupMember.user?.avatar?.backend_media_url || ""}
                        sx={{ width: 32, height: 32 }}
                        key={groupMember.id}
                      >
                        {!groupMember.user?.avatar && (
                          <DefaultAvatar width={32} height={32} />
                        )}
                      </Avatar>
                    ))}
                  </AvatarGroup>
                  <Tooltip arrow title={row.name} placement="top">
                    <Typography
                      variant="body1"
                      className={styles.textWithOverFlowHidden}
                    >
                      {row.name}
                    </Typography>
                  </Tooltip>
                </Box>
              </TableCell>

              <TableCell
                className={`${styles.tableCell} ${styles.groupsListTableCell}`}
                sx={{ width: "30%" }}
              >
                <Typography variant="body1">
                  {row.group_members?.length}
                </Typography>
              </TableCell>

              <TableCell
                className={`${styles.tableCell} ${styles.groupsListTableCell}`}
                sx={{ width: "25%" }}
              >
                <Typography variant="body1">
                  {getReadableDateTable(row.created_at)}
                </Typography>
              </TableCell>

              <TableCell
                className={styles.editButtonCell}
                align="right"
                sx={{ width: "10%" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.editButton}
                  onClick={() => {
                    setEditGroupId(row.id);
                    setIsDialogUpdateOpened(true);
                  }}
                >
                  <EditIcon />
                  <Typography variant="body2" fontWeight={600}>
                    Edit
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddGroupModal
        isDialogOpened={isDialogAddOpened}
        setIsDialogOpened={setIsDialogAddOpened}
        onGroupUpdate={updateGroupList}
      />
      <UpdateGroupModal
        isDialogOpened={isDialogUpdateOpened}
        setIsDialogOpened={setIsDialogUpdateOpened}
        onGroupUpdate={updateGroupList}
        onModalClose={() => setEditGroupId("")}
        editGroupId={editGroupId}
      />
      {isLoading && (
        <LinearProgress style={{ width: "100%", marginTop: "5px" }} />
      )}
    </>
  );
};
