function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#3A75E9" rx="16" />
      <path
        fill="#fff"
        d="M13.184 10.087l5.38 5.465a.637.637 0 010 .896l-5.379 5.466a.64.64 0 00.203 1.037.618.618 0 00.683-.138l5.378-5.464a1.925 1.925 0 000-2.697L14.07 9.187a.618.618 0 00-.887 0 .642.642 0 000 .9z"
      />
    </svg>
  );
}

export default ArrowRightIcon;
