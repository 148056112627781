import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type { PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import * as React from "react";

import { indigo } from "../../../themes/colors";

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  boxShadow: "4px 0px 0px 0px transparent",
  transition: "background-color 500ms, box-shadow 500ms, border-radius 500ms",
  margin: "0 2px",
  "&.MuiPickersDay-today": {
    border: `none`,
  },
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    color: "white !important",

    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
  ...((isSelected || isHovered) &&
    day.day() !== 6 && {
      boxShadow: `4px 0px 0px 0px ${theme.palette.primary[theme.palette.mode]}`,
      "&.Mui-disabled": {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${indigo.lightest} !important`,
        boxShadow: `4px 0px 0px 0px ${indigo.lightest} !important`,
      },
    }),
  ...((isSelected || isHovered) &&
    day.day() === 6 && {
      "&.Mui-disabled": {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${indigo.lightest} !important`,
      },
    }),
  ...(!isSelected &&
    !isHovered && {
      "&.MuiPickersDay-today:before": {
        content: "''",
        display: "block",
        position: "absolute",
        borderRadius: "50%",
        width: "100%",
        height: "100%",
        border: "1px solid #878C8E",
        backgroundColor: "transparent",
        top: 0,
        left: 0,
        boxSizing: "border-box",
      },
    }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

interface WeekPickerProps {
  setDate: (data: { startDate: string; endDate: string }) => void;
  date: { startDate: string; endDate: string };
  disableFuture?: boolean;
  disablePast?: boolean;
}

export default function WeekPicker(props: WeekPickerProps) {
  const { date, setDate, disableFuture, disablePast } = props;
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(date.startDate)
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        value={selectedDate}
        views={["year", "month", "day"]}
        onChange={(newValue) => {
          setDate({
            startDate: dayjs(newValue).startOf("week").toISOString(),
            endDate: dayjs(newValue).endOf("week").toISOString(),
          });
          setSelectedDate(newValue);
        }}
        disableFuture={disableFuture}
        disablePast={disablePast}
        showDaysOutsideCurrentMonth
        slots={{ day: Day }}
        slotProps={{
          day: (ownerState) =>
            ({
              selectedDay: selectedDate,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
            }) as any,
        }}
      />
    </LocalizationProvider>
  );
}
