function XSocialMediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4626_70724"
        style={{ maskType: "luminance" }}
        width="21"
        height="22"
        x="0"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M21 1.5H0v21h21v-21z" />
      </mask>
      <g mask="url(#mask0_4626_70724)">
        <path
          fill="#000"
          d="M12.498 10.387L20.316 1.5h-1.853l-6.788 7.717L6.253 1.5H0l8.199 11.67L0 22.49h1.853l7.168-8.15 5.726 8.15H21l-8.503-12.102zM9.96 13.272l-.83-1.162-6.61-9.246h2.846l5.334 7.462.83 1.162 6.934 9.7h-2.846L9.96 13.272z"
        />
      </g>
    </svg>
  );
}

export default XSocialMediaIcon;
