import { Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import type { Dispatch, FC, SetStateAction } from "react";

import type { SortingDirection } from "../../../../enums/sorting-direction.enum";
import useRoundCurrency from "../../../../hooks/round-currency";
import useQueryParam from "../../../../hooks/set-query-param";
import { BtcLogo } from "../../../../media/icons/btc";
import { DollarLogo } from "../../../../media/icons/dollar";
import { EuroLogo } from "../../../../media/icons/euro";
import type { ITransactionHistory } from "../../../../services/earnings/interfaces/transaction-history-response.interface";
import { CurrenciesEnum } from "../../../automatic-transfers/enums/currencies.enum";
import { CustomTableHead } from "../../../shared/custom-table-head/custom-table-head";
import styles from "./transaction-history-list.module.scss";
import { transactionHistoryTableHeads } from "./transaction-history-table-heads";

type TransactionHistoryListProp = {
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  direction: SortingDirection;
  setDirection: Dispatch<SetStateAction<SortingDirection>>;
  transactionHistory: ITransactionHistory[];
  lastMemberElementRef: (node: HTMLTableRowElement) => void;
};

export const TransactionHistoryList: FC<TransactionHistoryListProp> = ({
  setSortBy,
  sortBy,
  direction,
  setDirection,
  transactionHistory,
  lastMemberElementRef,
}) => {
  const { roundAmount } = useRoundCurrency();
  const { setParam } = useQueryParam();

  const renderLogo = (logo: CurrenciesEnum) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return (
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <BtcLogo /> BTC
          </span>
        );
      case CurrenciesEnum.USD:
        return (
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <DollarLogo /> USD
          </span>
        );
      case CurrenciesEnum.EURO:
        return (
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <EuroLogo /> EUR
          </span>
        );
      default:
        return <>-</>;
    }
  };

  return (
    <>
      <Table
        sx={{
          minWidth: 650,
          maxHeight: "100px",
          overflow: "hidden",
          marginTop: "5px",
        }}
        aria-label="simple table"
      >
        <CustomTableHead
          tableHeads={transactionHistoryTableHeads}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
        />
        <TableBody>
          {transactionHistory.length ? (
            transactionHistory.map((row: ITransactionHistory, index) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                ref={
                  transactionHistory.length === index + 1
                    ? lastMemberElementRef
                    : null
                }
              >
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "11%" }}
                >
                  <Typography fontSize="18px">
                    {dayjs(row.created_at).format("MM/DD/YYYY")}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "20%" }}
                >
                  <Typography fontSize="18px">
                    {row.type === "oneTime"
                      ? "Premium Content"
                      : row.type.charAt(0).toUpperCase() + row.type.slice(1)}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "23%" }}
                >
                  <Typography fontSize="18px">
                    {row.tip?.tipper?.username}
                    {row.subscription?.subscriber?.username}
                    {row.oneTimePurchase?.user.display_name
                      ? row.oneTimePurchase.user.display_name
                      : row.oneTimePurchase?.user.username}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "23%" }}
                >
                  <Typography fontSize="18px">
                    {row.subscription ? (
                      "Subscription"
                    ) : (
                      <>
                        {row.type === "tip" && row.tip?.content && (
                          <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                              onClick={() => {
                                setParam(
                                  "contentId",
                                  row.tip?.content?.id || ""
                                );
                              }}
                            >
                              {row.tip?.content?.name
                                ? row.tip?.content?.name
                                : "Content"}
                            </Link>
                          </>
                        )}

                        {row.type === "oneTime" && (
                          <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                              onClick={() => {
                                setParam(
                                  "contentId",
                                  row.oneTimePurchase?.content.id || ""
                                );
                              }}
                            >
                              {row.type === "oneTime"
                                ? row.oneTimePurchase?.content.name
                                : null}
                            </Link>
                          </>
                        )}

                        {row.type === "tip" && !row.tip?.content && "Tip"}
                      </>
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "17%" }}
                >
                  <Typography fontSize="18px">
                    {renderLogo(row.currency)}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align="left"
                  sx={{ width: "11%" }}
                >
                  <Typography fontSize="18px">
                    {row.currency === CurrenciesEnum.BTC
                      ? roundAmount(row.amount, 5)
                      : roundAmount(row.amount, 2)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow sx={{ borderTop: "1px solid #fff" }} />
          )}
        </TableBody>
      </Table>
    </>
  );
};
