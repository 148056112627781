// Define the shape of the context
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import {
  useDeleteFolderMutation,
  useGetFoldersByIdMutation,
  useUpdateFolderMutation,
} from "../../../../../../services/catalog/catalog.service";
import { IFolder } from "../../../../../../services/catalog/interfaces/catalog-folders.interfaces";
import { Content } from "../../../../../../services/catalog/interfaces/catalog.interfaces";
import { AddToFolderForm } from "../folder-modals/add-to-folder/add-to-folder";
import { FolderDeleteConfirmation } from "../folder-modals/folder-delete-confirmation/folder-delete-confirmation";
import { FolderUpdateForm } from "../folder-modals/folder-update-form/folder-update-form";

interface IFolderCreatorCatalogCCViewContextType {
  getFoldersById: (folderId?: string, searchText?: string) => void;
  deleteFolder: (folderId: string) => void;
  updateFolder: (folderName: string) => void;

  folderToDelete: IFolder | null;
  setFolderToDelete: Dispatch<SetStateAction<IFolder | null>>;

  folderToUpdate: IFolder | null;
  setFolderToUpdate: Dispatch<SetStateAction<IFolder | null>>;

  folders: IFolder[];
  setFolders: Dispatch<SetStateAction<IFolder[]>>;

  isGetFoldersLoading: boolean;
  isGetFoldersSuccess: boolean;

  selectedContentToFolder: Content | null;
  setSelectedContentToFolder: Dispatch<SetStateAction<Content | null>>;

  currentOpenedFolder: IFolder | null;
  setCurrentOpenedFolder: Dispatch<SetStateAction<IFolder | null>>;
}

const FolderCreatorCatalogCCViewContext =
  createContext<IFolderCreatorCatalogCCViewContextType>(
    {} as IFolderCreatorCatalogCCViewContextType
  );

export const FolderCreatorCatalogCCViewProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [folders, setFolders] = useState<IFolder[]>([]);
  const [isGetFoldersLoading, setIsGetFoldersLoading] =
    useState<boolean>(false);
  const [isGetFoldersSuccess, setIsGetFoldersSuccess] =
    useState<boolean>(false);

  const [folderToDelete, setFolderToDelete] = useState<IFolder | null>(null);
  const [folderToUpdate, setFolderToUpdate] = useState<IFolder | null>(null);
  const [selectedContentToFolder, setSelectedContentToFolder] =
    useState<Content | null>(null);
  const [currentOpenedFolder, setCurrentOpenedFolder] =
    useState<IFolder | null>(null);

  // API requests
  const [
    getFolders,
    {
      data: getAllFoldersData,
      isLoading: getAllFoldersLoading,
      isSuccess: getAllFoldersSuccess,
    },
  ] = useGetFoldersByIdMutation();

  const [
    deleteFolderById,
    { isLoading: deleteFolderByIdLoading, isSuccess: deleteFolderByIdSuccess },
  ] = useDeleteFolderMutation();

  const [
    updateFolderById,
    { isLoading: updateFolderByIdLoading, isSuccess: updateFolderByIdSuccess },
  ] = useUpdateFolderMutation();

  // Functions
  const getFoldersById = useCallback(
    async (folderId?: string, searchText?: string) => {
      await getFolders({
        accessToken: userAccessToken || "",
        folderId,
        searchText,
      });
    },
    [getFolders]
  );

  const deleteFolder = useCallback(
    async (folderId: string) => {
      await deleteFolderById({
        accessToken: userAccessToken || "",
        folderId,
      });
    },
    [deleteFolderById]
  );

  const updateFolder = useCallback(
    async (folderName: string) => {
      if (folderToUpdate) {
        let name = folderName;

        if (!name) {
          const folderWithDefaultName = folders.find((item) =>
            item.name.match(/^Folder \d+$/)
          );

          if (!folderWithDefaultName) {
            name = `Folder 1`;
          } else {
            const [, number] = folderWithDefaultName.name.split(" ");
            name = `Folder ${Number(number) + 1}`;
          }
        }

        await updateFolderById({
          accessToken: userAccessToken || "",
          folderId: folderToUpdate.id,
          folderName: name,
        });
      }
    },
    [updateFolderById, folderToUpdate, folders]
  );

  // get folders
  useEffect(() => {
    setFolders(getAllFoldersData?.data || []);
    setCurrentOpenedFolder(getAllFoldersData?.currentFolder || null);
  }, [getAllFoldersData]);

  useEffect(() => {
    setIsGetFoldersLoading(getAllFoldersLoading);
  }, [getAllFoldersLoading]);

  useEffect(() => {
    setIsGetFoldersSuccess(getAllFoldersSuccess);
  }, [getAllFoldersSuccess]);

  // delete folders
  useEffect(() => {
    if (deleteFolderByIdSuccess) {
      setFolderToDelete(null);
      getFoldersById(currentOpenedFolder?.id);
    }
  }, [deleteFolderByIdSuccess]);

  // update folder
  useEffect(() => {
    if (updateFolderByIdSuccess) {
      setFolderToUpdate(null);
      getFoldersById(currentOpenedFolder?.id);
    }
  }, [updateFolderByIdSuccess]);

  const contextValues = useMemo(
    () => ({
      setFolders,
      folders,

      getFoldersById,
      isGetFoldersLoading,
      isGetFoldersSuccess,

      deleteFolder,
      setFolderToDelete,
      folderToDelete,

      updateFolder,
      setFolderToUpdate,
      folderToUpdate,

      setSelectedContentToFolder,
      selectedContentToFolder,

      setCurrentOpenedFolder,
      currentOpenedFolder,
    }),
    [
      setFolders,
      folders,

      getFoldersById,
      isGetFoldersLoading,
      isGetFoldersSuccess,

      deleteFolder,
      setFolderToDelete,
      folderToDelete,

      updateFolder,
      setFolderToUpdate,
      folderToUpdate,

      setSelectedContentToFolder,
      selectedContentToFolder,

      setCurrentOpenedFolder,
      currentOpenedFolder,
    ]
  );

  const onCancelDeleteClick = () => {
    setFolderToDelete(null);
  };

  const onAcceptDeleteClick = () => {
    if (folderToDelete) {
      deleteFolder(folderToDelete.id);
    }
  };

  const onCancelUpdateClick = () => {
    setFolderToUpdate(null);
  };

  return (
    <FolderCreatorCatalogCCViewContext.Provider value={contextValues}>
      <>
        {children}
        <FolderDeleteConfirmation
          isOpen={!!folderToDelete}
          isLoading={deleteFolderByIdLoading}
          onAcceptDeleteClick={onAcceptDeleteClick}
          onCancelDeleteClick={onCancelDeleteClick}
        />

        <FolderUpdateForm
          isOpen={!!folderToUpdate}
          isLoading={updateFolderByIdLoading}
          onAcceptUpdateClick={updateFolder}
          onCancelUpdateClick={onCancelUpdateClick}
          folderToUpdate={folderToUpdate}
        />

        <AddToFolderForm isOpen={!!selectedContentToFolder} />
      </>
    </FolderCreatorCatalogCCViewContext.Provider>
  );
};

// Custom hook to use the CreatorCatalogCCViewContext
export const useFolderCreatorCatalogCCViewContext = () =>
  useContext(FolderCreatorCatalogCCViewContext);
