import {
  Avatar,
  Box,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { getReadableDateTable } from "../../../helpers/helpers";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useFetchSubscribersMutation } from "../../../services/manage-subscribers/manage-subscribers.service";
import { mySubscribersTableSlice } from "../../../store/reducers/manage-subscribers/my-subscribers-table";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import { DefaultAvatar } from "../../shared/default-avatar/default-avatar";
import styles from "../manage-subscribers.module.scss";
import type { ISubscribersList } from "./interfaces/my-subscribers-list.interface";
import { mySubscribersTableHeads } from "./my-subscribers-tablle-head";

type MySubscribersListProps = {
  searchText: string;
};

export const MySubscribersList: FC<MySubscribersListProps> = ({
  searchText,
}) => {
  const rowsPerPage = 5;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { setMySubscribersTable } = mySubscribersTableSlice.actions;
  const { subscribers } = useAppSelector(
    (reducers) => reducers.mySubscribersTableReducer
  );
  const [fetchSubscribers, { error, isLoading, data: subscribersData }] =
    useFetchSubscribersMutation();

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    subscribers,
    subscribersData?.total || 0,
    mySubscribersTableHeads
  );

  const getSubscribers = useCallback(async () => {
    try {
      await fetchSubscribers({
        accessToken: accessToken || "",
        rowsPerPage,
        page: page + 1,
        direction,
        sortBy,
        searchText,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [
    accessToken,
    fetchSubscribers,
    rowsPerPage,
    direction,
    sortBy,
    page,
    searchText,
  ]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    getSubscribers();
  }, [getSubscribers, page]);

  useEffect(() => {
    if (page === 0) {
      dispatch(setMySubscribersTable(subscribersData?.data || []));
      return;
    }

    dispatch(
      setMySubscribersTable([...subscribers, ...(subscribersData?.data || [])])
    );
  }, [subscribersData]);

  useEffect(() => {
    dispatch(setMySubscribersTable([]));
    setPage(0);
  }, [sortBy, searchText, direction]);

  return (
    <>
      <Table aria-label="simple table" sx={{ tableLayout: "fixed" }}>
        <CustomTableHead
          tableHeads={mySubscribersTableHeads}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
        />

        <TableBody>
          {subscribers
            .filter(
              (row) =>
                row.subscriber &&
                row.subscription_renewals &&
                row.number_of_purchases
            )
            .map((row: ISubscribersList, index) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                ref={
                  subscribers.length === index + 1 ? lastMemberElementRef : null
                }
              >
                <TableCell className={styles.tableCell} sx={{ width: "17%" }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    gap="0.5rem"
                  >
                    {row.subscriber?.avatar ? (
                      <Avatar
                        alt={row.subscriber.username}
                        src={row.subscriber.avatar.backend_media_url}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar
                        alt={row.subscriber?.username}
                        sx={{ width: 32, height: 32 }}
                      >
                        <DefaultAvatar width={32} height={32} />
                      </Avatar>
                    )}
                    <Tooltip
                      arrow
                      title={row.subscriber?.username}
                      placement="top"
                    >
                      <Typography
                        variant="body1"
                        className={styles.textWithOverFlowHidden}
                      >
                        {row.subscriber?.username}
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>

                <TableCell className={styles.tableCell} sx={{ width: "17%" }}>
                  <Typography variant="body1">
                    {getReadableDateTable(row.created_at)}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell} sx={{ width: "20%" }}>
                  <Typography variant="body1">
                    {getReadableDateTable(row.expires_at)}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell} sx={{ width: "25%" }}>
                  <Typography variant="body1">
                    {row.subscription_renewals?.length}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableCell} sx={{ width: "15%" }}>
                  <Link to="/cc/earnings">
                    <Typography variant="body1">
                      {row.number_of_purchases?.length}
                    </Typography>
                  </Link>
                </TableCell>

                <TableCell className={styles.tableCell} sx={{ width: "6%" }}>
                  <Link to="/cc/earnings">
                    <Typography variant="body1">
                      {row.subscriber?.sent_tips.length}
                    </Typography>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress />}
    </>
  );
};
