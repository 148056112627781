import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { ISubscribersList } from "../../../components/manage-subscribers/my-subscribers/interfaces/my-subscribers-list.interface";

interface IMySubscribersTable {
  subscribers: ISubscribersList[];
}

const initialState: IMySubscribersTable = {
  subscribers: [],
};

export const mySubscribersTableSlice = createSlice({
  name: "mySubscribersTable",
  initialState,
  reducers: {
    setMySubscribersTable(state, action: PayloadAction<ISubscribersList[]>) {
      state.subscribers = action.payload;
    },
  },
});

export default mySubscribersTableSlice.reducer;
