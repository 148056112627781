import { Box, Card, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/redux";
import useRoundCurrency from "../../../hooks/round-currency";
import { BitcoinTextIcon } from "../../../media/icons/bitcoin-text";
import type { IUserProfile } from "../../../services/sign-in/interfaces/initialize.interface";
import { CurrenciesEnum } from "../enums/currencies.enum";
import styles from "./history.module.scss";

interface HistoryProps {
  data: any;
}

export default function History(props: HistoryProps) {
  const { data } = props;

  const { user } = useAppSelector((item) => item.userReducer);
  const { roundAmount } = useRoundCurrency();

  const sumOfTransfer = data.currency_transfers.reduce(
    (prev: any, curr: any) => {
      if (curr.payment_type === "fixed") {
        if (curr.if_amount_is_not_available === "dontTransfer") {
          const walletBalance = Number(user?.wallets[data.currency]);

          if (Number(prev) + Number(curr.amount) > walletBalance) {
            return Number(prev);
          }
          return Number(prev) + Number(curr.amount);
        }
        return Number(prev) + Number(curr.amount);
      }
      if (curr.payment_type === "percentageOfTotal") {
        const amount =
          (Number(user?.wallets[data.currency]) *
            Number(curr.custom_amount.replace(/%/g, ""))) /
          100;

        return Number(prev) + amount;
      }
      return Number(prev) + Number(curr.amount);
    },
    0
  );

  const renderRest = (userProfile: IUserProfile | null, dataTransfer: any) => {
    const restAmount =
      Number(userProfile?.wallets[dataTransfer.currency]) - sumOfTransfer < 0
        ? 0
        : Number(userProfile?.wallets[dataTransfer.currency]) - sumOfTransfer;

    return dataTransfer.currency === CurrenciesEnum.BTC
      ? roundAmount(restAmount, 5)
      : roundAmount(restAmount, 2);
  };

  const renderCurrency = (logo: CurrenciesEnum, color?: string) => {
    switch (logo) {
      case CurrenciesEnum.BTC:
        return (
          <span
            style={{
              userSelect: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(3px, -1px)",
            }}
          >
            <BitcoinTextIcon color={color} width={18} height={18} />
          </span>
        );
      case CurrenciesEnum.USD:
        return <span style={{ userSelect: "none" }}>$</span>;
      case CurrenciesEnum.EURO:
        return <span style={{ userSelect: "none" }}>€</span>;
      default:
        return <span style={{ userSelect: "none" }}>-</span>;
    }
  };

  let remainingBalance = Number(user?.wallets[data.currency]);

  return (
    <div className={styles.cardWrapper}>
      <Card className={styles.card}>
        <Typography fontSize={20} color="#252733">
          If transfer was made today:
        </Typography>
        <Box className={styles.transactionsWrapper}>
          <Box className={styles.transaction}>
            <Typography color="#4C4B4C">Balance:</Typography>
            <Typography
              fontWeight={600}
              display="flex"
              justifyContent="center"
              align="center"
              color="#252733"
            >
              {renderCurrency(data.currency)}
              <div>
                {data.currency === CurrenciesEnum.BTC
                  ? roundAmount(Number(user?.wallets[data.currency]) || 0, 5)
                  : roundAmount(Number(user?.wallets[data.currency]) || 0, 2)}
              </div>
            </Typography>
          </Box>
          {data.currency_transfers.length > 0 && (
            <Box>
              <Box>
                {data.currency_transfers.map(
                  (transaction: any, index: number) => {
                    let transferredAmount = 0;
                    let intendedAmount = 0;

                    if (transaction.payment_type === "fixed") {
                      if (
                        transaction.if_amount_is_not_available ===
                        "dontTransfer"
                      ) {
                        if (Number(transaction.amount) > remainingBalance) {
                          transferredAmount = 0;
                          intendedAmount = Number(transaction.amount);
                        } else {
                          intendedAmount = Number(transaction.amount);
                          transferredAmount = Math.min(
                            intendedAmount,
                            remainingBalance
                          );
                        }
                      } else {
                        intendedAmount = Number(transaction.amount);
                        transferredAmount = Math.min(
                          intendedAmount,
                          remainingBalance
                        );
                      }
                    } else if (
                      transaction.payment_type === "percentageOfTotal"
                    ) {
                      intendedAmount =
                        (Number(user?.wallets[data.currency]) *
                          Number(transaction.custom_amount.replace(/%/g, ""))) /
                        100;
                      transferredAmount = Math.min(
                        intendedAmount,
                        remainingBalance
                      );
                    }

                    remainingBalance -= transferredAmount;

                    if (
                      transferredAmount < intendedAmount &&
                      Number(renderRest(user, data)) - intendedAmount < 0
                    ) {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography color="#4C4B4C">
                            Transfer {index + 1}:
                          </Typography>
                          <Box>
                            <Typography
                              fontWeight={600}
                              sx={{ textDecoration: "line-through" }}
                              color="error"
                              display="flex"
                              justifyContent="flex-end"
                              align="center"
                            >
                              {renderCurrency(
                                data.currency,
                                "rgba(255, 82, 82, 1)"
                              )}
                              <div>
                                {data.currency === CurrenciesEnum.BTC
                                  ? roundAmount(intendedAmount, 5)
                                  : roundAmount(intendedAmount, 2)}
                              </div>
                            </Typography>
                            <Typography
                              fontWeight={600}
                              color="error"
                              display="flex"
                              justifyContent="flex-end"
                              align="center"
                            >
                              {renderCurrency(
                                data.currency,
                                "rgba(255, 82, 82, 1)"
                              )}
                              <div>
                                {data.currency === CurrenciesEnum.BTC
                                  ? roundAmount(transferredAmount, 5)
                                  : roundAmount(transferredAmount, 2)}
                              </div>
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography color="#4C4B4C">
                          Transfer {index + 1}:
                        </Typography>
                        <Typography
                          fontWeight={600}
                          color="error"
                          display="flex"
                          justifyContent="flex-end"
                          align="center"
                        >
                          {renderCurrency(
                            data.currency,
                            "rgba(255, 82, 82, 1)"
                          )}
                          <div>
                            {data.currency === CurrenciesEnum.BTC
                              ? roundAmount(transferredAmount, 5)
                              : roundAmount(transferredAmount, 2)}
                          </div>
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>
          )}
          <Box className={styles.line} />
          <Box className={styles.transaction}>
            <Typography color="#4C4B4C">Rest:</Typography>
            <Typography
              fontWeight={600}
              display="flex"
              justifyContent="center"
              align="center"
              color="#252733"
            >
              {renderCurrency(data.currency)}
              {renderRest(user, data)}
            </Typography>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
