import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { ResetPasswordInterface } from "./interfaces/reset-password.interface";

export const resetPasswordApi = createApi({
  reducerPath: "resetPassword",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: (data: ResetPasswordInterface) => ({
        url: ENDPOINTS.UPDATE_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useResetPasswordMutation } = resetPasswordApi;
