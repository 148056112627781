export const FolderIcon = ({
  width = 57,
  height = 46,
  color = "#6995EC",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 57 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.8967 14.6016H3.10467C0.526428 14.6016 0.397628 15.8565 0.537628 17.3898L2.84875 42.6128C2.98875 44.1466 3.35219 45.4016 5.92651 45.4016H51.0743C53.6996 45.4016 54.0109 44.1466 54.1509 42.6133L56.462 17.3904C56.6026 15.8565 56.4744 14.6016 53.8967 14.6016ZM52.0324 8.44156C51.7216 7.20956 50.1984 6.20156 48.6461 6.20156H29.5658C28.014 6.20156 25.8446 5.31228 24.7459 4.22588L23.0776 2.5778C21.9784 1.49084 19.8095 0.601562 18.2577 0.601562H9.14147C7.58971 0.601562 6.17963 1.85372 6.00883 3.38476L5.19067 10.6816H52.5969L52.0324 8.44156Z"
      fill={color}
    />
  </svg>
);
