import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 51,
  height: 31,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    boxSizing: "border-box",
    width: 27,
    height: 27,
    transform: "translateY(-1px)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 31 / 2,
  },
}));
