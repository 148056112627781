import type { FC, PropsWithChildren } from "react";

interface IProfileIconProp {
  width?: number;
  height?: number;
  color?: string;
}
export const ProfileIcon: FC<PropsWithChildren<IProfileIconProp>> = ({
  width = 32,
  height = 32,
  color,
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.5 12C12.4889 12 13.4556 11.7068 14.2779 11.1573C15.1001 10.6079 15.741 9.82705 16.1194 8.91342C16.4978 7.99979 16.5969 6.99446 16.4039 6.02455C16.211 5.05465 15.7348 4.16373 15.0355 3.46447C14.3363 2.76521 13.4454 2.289 12.4755 2.09608C11.5055 1.90315 10.5002 2.00217 9.58658 2.3806C8.67295 2.75904 7.89206 3.39991 7.34265 4.22215C6.79324 5.0444 6.5 6.0111 6.5 7C6.50132 8.32568 7.02853 9.59668 7.96593 10.5341C8.90332 11.4715 10.1743 11.9987 11.5 12ZM11.5 3.66667C12.1593 3.66667 12.8037 3.86217 13.3519 4.22844C13.9001 4.59471 14.3273 5.1153 14.5796 5.72439C14.8319 6.33348 14.8979 7.0037 14.7693 7.6503C14.6407 8.29691 14.3232 8.89085 13.857 9.35703C13.3908 9.8232 12.7969 10.1407 12.1503 10.2693C11.5037 10.3979 10.8335 10.3319 10.2244 10.0796C9.6153 9.82731 9.09471 9.40007 8.72843 8.8519C8.36216 8.30374 8.16667 7.65927 8.16667 7C8.16667 6.11595 8.51786 5.2681 9.14298 4.64298C9.7681 4.01786 10.6159 3.66667 11.5 3.66667Z"
        fill={color}
      />
      <path
        d="M11.5 13.668C9.51155 13.6702 7.60518 14.4611 6.19914 15.8671C4.79309 17.2731 4.00221 19.1795 4 21.168C4 21.389 4.0878 21.6009 4.24408 21.7572C4.40036 21.9135 4.61232 22.0013 4.83333 22.0013C5.05435 22.0013 5.26631 21.9135 5.42259 21.7572C5.57887 21.6009 5.66667 21.389 5.66667 21.168C5.66667 19.6209 6.28125 18.1371 7.37521 17.0432C8.46917 15.9492 9.9529 15.3346 11.5 15.3346C13.0471 15.3346 14.5308 15.9492 15.6248 17.0432C16.7188 18.1371 17.3333 19.6209 17.3333 21.168C17.3333 21.389 17.4211 21.6009 17.5774 21.7572C17.7337 21.9135 17.9457 22.0013 18.1667 22.0013C18.3877 22.0013 18.5996 21.9135 18.7559 21.7572C18.9122 21.6009 19 21.389 19 21.168C18.9978 19.1795 18.2069 17.2731 16.8009 15.8671C15.3948 14.4611 13.4884 13.6702 11.5 13.668Z"
        fill={color}
      />
    </svg>
  </>
);
