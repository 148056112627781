import type { FC, PropsWithChildren } from "react";

interface IVolumeIconProp {
  width?: number;
  height?: number;
}
export const VolumeIcon: FC<PropsWithChildren<IVolumeIconProp>> = ({
  width = 32,
  height = 32,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_993_34499)">
        <path
          d="M17.3374 3.57234C17.26 3.49491 17.1681 3.4335 17.0669 3.39159C16.9658 3.34969 16.8574 3.32813 16.7479 3.32812C16.6384 3.32812 16.5299 3.34969 16.4288 3.39159C16.3276 3.4335 16.2357 3.49491 16.1583 3.57234C16.0809 3.64976 16.0194 3.74168 15.9775 3.84284C15.9356 3.944 15.9141 4.05243 15.9141 4.16192C15.9141 4.27142 15.9356 4.37984 15.9775 4.481C16.0194 4.58216 16.0809 4.67408 16.1583 4.7515C17.5474 6.14392 18.3275 8.03048 18.3275 9.99734C18.3275 11.9642 17.5474 13.8508 16.1583 15.2432C16.0019 15.3995 15.9141 15.6116 15.9141 15.8328C15.9141 16.0539 16.0019 16.266 16.1583 16.4223C16.3146 16.5787 16.5267 16.6666 16.7479 16.6666C16.969 16.6666 17.1811 16.5787 17.3374 16.4223C19.0389 14.717 19.9945 12.4063 19.9945 9.99734C19.9945 7.58833 19.0389 5.2777 17.3374 3.57234Z"
          fill="#3A75E9"
        />
        <path
          d="M15.0832 6.07381C15.0058 5.99611 14.9138 5.93443 14.8125 5.89227C14.7113 5.85012 14.6027 5.82832 14.493 5.82813C14.3833 5.82793 14.2747 5.84935 14.1732 5.89114C14.0718 5.93294 13.9796 5.9943 13.9019 6.07173C13.8242 6.14915 13.7626 6.24113 13.7204 6.34239C13.6782 6.44366 13.6564 6.55223 13.6563 6.66192C13.6561 6.77161 13.6775 6.88027 13.7193 6.98168C13.7611 7.08309 13.8224 7.17528 13.8999 7.25298C14.6271 7.98139 15.0356 8.96865 15.0356 9.99798C15.0356 11.0273 14.6271 12.0146 13.8999 12.743C13.8224 12.8207 13.7611 12.9129 13.7193 13.0143C13.6775 13.1157 13.6561 13.2243 13.6563 13.334C13.6564 13.4437 13.6782 13.5523 13.7204 13.6536C13.7626 13.7548 13.8242 13.8468 13.9019 13.9242C14.0589 14.0806 14.2715 14.1682 14.493 14.1678C14.6027 14.1676 14.7113 14.1458 14.8125 14.1037C14.9138 14.0615 15.0058 13.9998 15.0832 13.9221C16.1226 12.8807 16.7064 11.4694 16.7064 9.99798C16.7064 8.52657 16.1226 7.11527 15.0832 6.07381Z"
          fill="#3A75E9"
        />
        <path
          d="M11.5167 0.170015C8.98275 0.646071 6.7289 2.07822 5.22167 4.17001H4.16667C3.06214 4.17178 2.00336 4.61133 1.22234 5.39235C0.441316 6.17337 0.00176302 7.23215 0 8.33668L0 11.67C0.00176302 12.7745 0.441316 13.8333 1.22234 14.6143C2.00336 15.3954 3.06214 15.8349 4.16667 15.8367H5.22167C6.7292 17.9282 8.98291 19.3602 11.5167 19.8367C11.5664 19.8464 11.6169 19.8511 11.6675 19.8508C11.8885 19.8508 12.1005 19.7631 12.2568 19.6068C12.413 19.4505 12.5008 19.2385 12.5008 19.0175V0.991681C12.5011 0.869293 12.4745 0.748339 12.4228 0.637416C12.371 0.526494 12.2955 0.428325 12.2016 0.349888C12.1076 0.27145 11.9976 0.21467 11.8792 0.183582C11.7608 0.152494 11.637 0.147862 11.5167 0.170015ZM10.8333 17.9492C8.99532 17.3633 7.4149 16.1627 6.3575 14.5492C6.28195 14.433 6.1786 14.3374 6.0568 14.2712C5.93501 14.205 5.79863 14.1702 5.66 14.17H4.16667C3.50363 14.17 2.86774 13.9066 2.3989 13.4378C1.93006 12.9689 1.66667 12.3331 1.66667 11.67V8.33668C1.66667 7.67364 1.93006 7.03776 2.3989 6.56891C2.86774 6.10007 3.50363 5.83668 4.16667 5.83668H5.66667C5.80514 5.83666 5.94143 5.80214 6.06321 5.73623C6.18499 5.67032 6.28842 5.5751 6.36417 5.45918C7.41941 3.84605 8.9974 2.64497 10.8333 2.05751V17.9492Z"
          fill="#3A75E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_993_34499">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);
