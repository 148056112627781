import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useState } from "react";

import FilterIcon from "../../../../media/filter";
import FilterActiveIcon from "../../../../media/filter-active";
import { ListIcon } from "../../../../media/icons/list";
import { TileIcon } from "../../../../media/icons/tile";
import { IFilterCatalogCs } from "../../../../services/catalog/interfaces/request-models.interfaces";
import { LastSearchType } from "../../../../services/home/interfaces/home.interface";
import { ActiveView } from "../../../home/interfaces/temporary.interface";
import { SearchBar } from "../../../shared/search-bar/search-bar";
import { useCreatorCatalogCSViewContext } from "../context/creator-catalog-cs-view-context";
import styles from "./catalog-search-bar-cs.module.scss";

interface InputObject {
  [key: string]: {
    [key: string]: boolean;
  };
}

export const CatalogSearchBarCs = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { updateCsCatalogParam, setView, view, isPreview } =
    useCreatorCatalogCSViewContext();

  const handleSearchText = (searchText: string) => {
    updateCsCatalogParam("searchText", searchText);
  };

  const transformFilters = (input: InputObject): IFilterCatalogCs => {
    const output: any = {};
    Object.entries(input).forEach(([category, values]) => {
      output[category] = [];

      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          output[category].push(key);
        }
      });
    });

    return output;
  };

  const formik = useFormik({
    initialValues: {
      show: {
        free: false,
        paid: false,
      },
      contentType: {
        image: false,
        video: false,
        collection: false,
      },
      price: {
        lowHigh: false,
        highLow: false,
      },
    },
    onSubmit: (values) => {
      updateCsCatalogParam("filters", transformFilters(values));
    },
  });

  const handleViewChange = useCallback(
    (nextView: ActiveView) => {
      setView(nextView);
    },
    [setView]
  );

  const handleFilterChange = useCallback(() => {
    setIsFilterOpen((prevState) => !prevState);
  }, [setIsFilterOpen]);

  const formControlLabelStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const renderFooter = () => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <ToggleButtonGroup
            orientation="horizontal"
            value={view}
            exclusive
            onChange={(e, nextView) => {
              if (isPreview) return;
              if (nextView === null) return;

              handleViewChange(nextView);
            }}
            className={styles.btnViewGroup}
          >
            <ToggleButton
              value="tile"
              aria-label="tile"
              color="primary"
              sx={{
                "&.MuiToggleButtonGroup-firstButton": {
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "transparent",
                  background: "transparent",
                },
                "&.Mui-selected:hover": {
                  color: "transparent",
                  background: "transparent",
                },
                borderRadius: "0",
                padding: "0",
              }}
            >
              {view === "tile" ? (
                <TileIcon color="#6995EC" width={24} height={24} />
              ) : (
                <TileIcon color="#AAAAAA" width={24} height={24} />
              )}
            </ToggleButton>

            <ToggleButton
              value="list"
              aria-label="list"
              color="primary"
              sx={{
                "&.MuiToggleButtonGroup-lastButton": {
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "transparent",
                  background: "transparent",
                },
                "&.Mui-selected:hover": {
                  color: "transparent",
                  background: "transparent",
                },
                borderRadius: "0",
                padding: "0",
              }}
            >
              {view === "list" ? (
                <ListIcon color="#6995EC" width={24} height={24} />
              ) : (
                <ListIcon color="#AAAAAA" width={24} height={24} />
              )}
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            className={styles.btnViewGroup}
            onChange={(e, nextView) => {
              if (isPreview) return;
              if (nextView === null) return;

              handleFilterChange();
            }}
          >
            <ToggleButton
              value="filter"
              aria-label="filter"
              color="primary"
              sx={{
                "&.MuiToggleButtonGroup-middleButton": {
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  border: "none",
                },
                "&.Mui-selected": {
                  color: "transparent",
                  background: "transparent",
                },
                "&.Mui-selected:hover": {
                  color: "transparent",
                  background: "transparent",
                },
                borderRadius: "0",
                padding: "0",
                border: "0px solid black",
              }}
            >
              {isFilterOpen ? (
                <FilterActiveIcon width={27} height={27} />
              ) : (
                <FilterIcon width={27} height={27} />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {isFilterOpen && (
          <Box
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: "100%" }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                sx={{
                  margin: "0",

                  ".MuiGrid-item": {
                    paddingLeft: "2px",
                  },
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Show:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="show.free"
                          checked={formik.values.show.free}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Free posts"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="show.paid"
                          checked={formik.values.show.paid}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Paid posts"
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Content type:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.image"
                          checked={formik.values.contentType.image}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Photos"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.video"
                          checked={formik.values.contentType.video}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Videos"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="contentType.collection"
                          checked={formik.values.contentType.collection}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Collections"
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Price:
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="price.lowHigh"
                          checked={formik.values.price.lowHigh}
                          onChange={formik.handleChange}
                          disabled={formik.values.price.highLow}
                        />
                      }
                      label="Low to High"
                    />
                    <FormControlLabel
                      sx={formControlLabelStyle}
                      control={
                        <Checkbox
                          name="price.highLow"
                          checked={formik.values.price.highLow}
                          onChange={formik.handleChange}
                          disabled={formik.values.price.lowHigh}
                        />
                      }
                      label="High to Low"
                    />
                  </Box>
                </Grid>

                <Box
                  sx={{
                    width: "100%",
                    marginRight: "17px",
                    marginTop: "19px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await formik.submitForm();
                    }}
                    sx={{
                      padding: "8px 40px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="reset"
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      formik.handleReset(e);
                      formik.submitForm();
                    }}
                  >
                    Reset all
                  </Button>
                </Box>
              </Grid>
            </form>
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <>
      <SearchBar
        placeholder="Search..."
        handleSearchTextChange={handleSearchText}
        renderFooter={renderFooter()}
        lastSearchType={LastSearchType.MY_CATALOG_SEARCH}
        isPreview={isPreview}
      />
    </>
  );
};
