function TikTokSocialMediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FF004F"
        d="M17.176 8.664a9.362 9.362 0 005.476 1.76V6.48c-.386 0-.772-.04-1.15-.121v3.104a9.364 9.364 0 01-5.476-1.759v8.048c0 4.026-3.252 7.289-7.263 7.289a7.21 7.21 0 01-4.043-1.233 7.228 7.228 0 005.193 2.193c4.011 0 7.263-3.263 7.263-7.29V8.665zm1.419-3.978a5.506 5.506 0 01-1.419-3.218V.961h-1.09a5.521 5.521 0 002.509 3.725zM7.257 18.719a3.328 3.328 0 01-.678-2.019 3.328 3.328 0 014.33-3.178V9.491a7.3 7.3 0 00-1.15-.067v3.138a3.328 3.328 0 00-4.33 3.178c0 1.302.744 2.43 1.828 2.979z"
      />
      <path
        fill="#3C3C3C"
        d="M16.027 7.704a9.364 9.364 0 005.476 1.759V6.359a5.492 5.492 0 01-2.907-1.673A5.522 5.522 0 0116.087.961h-2.862v15.75a3.329 3.329 0 01-3.323 3.324 3.314 3.314 0 01-2.645-1.316A3.335 3.335 0 015.43 15.74a3.328 3.328 0 014.33-3.178V9.424c-3.94.082-7.11 3.314-7.11 7.288 0 1.984.79 3.782 2.07 5.096a7.21 7.21 0 004.044 1.233c4.011 0 7.263-3.264 7.263-7.29V7.705z"
      />
      <path
        fill="#00F2EA"
        d="M21.503 6.358v-.84a5.46 5.46 0 01-2.908-.833 5.487 5.487 0 002.908 1.673zM16.087.96a5.611 5.611 0 01-.06-.453V0h-3.953v15.75a3.328 3.328 0 01-3.322 3.324 3.299 3.299 0 01-1.495-.356 3.314 3.314 0 002.645 1.316 3.329 3.329 0 003.322-3.323V.96h2.863zM9.76 9.423V8.53a7.308 7.308 0 00-.996-.068c-4.012 0-7.264 3.263-7.264 7.289a7.289 7.289 0 003.22 6.056 7.278 7.278 0 01-2.07-5.096c0-3.974 3.17-7.206 7.11-7.288z"
      />
    </svg>
  );
}

export default TikTokSocialMediaIcon;
