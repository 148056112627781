import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import type { FC } from "react";

import { TagsInput } from "../../../shared/tags-input/tags-input";

interface TagsProp {
  tags: string[];
  onFormikValueUpdate: (formikKey: string, formikValue: string[]) => void;
}

export const Tags: FC<TagsProp> = ({ tags, onFormikValueUpdate }) => {
  const formikKey = "info_on_update.tags";

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="button" fontWeight="bold">
          Tags
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0 0 15px 0",
        }}
      >
        <Typography variant="body2">
          Add up to 10 tags to your profile to make it easy for other users to
          find
        </Typography>
        <Box sx={{ marginTop: "15px" }}>
          <TagsInput
            formikKey={formikKey}
            tags={tags}
            onFormikValueUpdate={onFormikValueUpdate}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
