import {
  Avatar,
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { SubscriptionStatus } from "../../../enums/subscriprion-statuses.enum";
import { getReadableDateTable } from "../../../helpers/helpers";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { CloseIcon } from "../../../media/icons/close";
import {
  useCancelSubscriptionMutation,
  useFetchSubscriptionsMutation,
} from "../../../services/subscriptions/subscriptions.service";
import { activeSubscriptionsSlice } from "../../../store/reducers/subscriptions/active-subscriptions";
import { CustomTableHead } from "../../shared/custom-table-head/custom-table-head";
import { DefaultAvatar } from "../../shared/default-avatar/default-avatar";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import type { ISubscriptions } from "../interfaces/subscriptions.interface";
import { subscriptionsTableHead } from "../subscriptions-table-head";
import styles from "../subscriptions.module.scss";

export const ActiveSubscriptionsList = () => {
  const rowsPerPage = 5;

  const [selected, setSelected] = useState<string[]>([]);
  const [subscriptionCancelId, setSubscriptionCancelId] = useState<string[]>(
    []
  );
  const [manualChecked, setManualChecked] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { setActiveSubscriptions } = activeSubscriptionsSlice.actions;
  const { activeSubscriptions } = useAppSelector(
    (reducers) => reducers.activeSubscriptionsReducer
  );
  const [
    fetchSubscriptions,
    { error, isLoading, data: activeSubscriptionsData },
  ] = useFetchSubscriptionsMutation();
  const [
    cancelSubscriptions,
    { error: cancelError, isLoading: cancelLoading, isSuccess: cancelSuccess },
  ] = useCancelSubscriptionMutation();
  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    activeSubscriptions,
    activeSubscriptionsData?.total || 0,
    subscriptionsTableHead
  );

  const getSubscriptions = useCallback(async () => {
    try {
      await fetchSubscriptions({
        accessToken: accessToken || "",
        rowsPerPage,
        page: page + 1,
        direction,
        sortBy,
        status: SubscriptionStatus.ACTIVE,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [accessToken, fetchSubscriptions, direction, sortBy, page]);

  const handleRowClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    const newSelected: string[] = [];
    if (selectedIndex === -1) {
      setSelected(newSelected.concat(selected, id));
      return;
    }

    if (selectedIndex === 0) {
      setSelected(newSelected.concat(selected.slice(1)));
      return;
    }

    if (selectedIndex === selected.length - 1) {
      setSelected(newSelected.concat(selected.slice(0, -1)));
      return;
    }

    if (selectedIndex > 0) {
      setSelected(
        newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      );
      return;
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setManualChecked(true);
      const newSelected = activeSubscriptions.map((n) => n.id);
      setSelected(newSelected);
      return;
    }

    setManualChecked(false);
    setSelected([]);
  };

  const handleCancelSubscription = (id: string) => {
    setSubscriptionCancelId([id]);
    setIsModalOpen(true);
  };

  const handleCancelSelectedSubscription = async () => {
    setIsModalOpen(true);
  };
  const handleConfirmCancelSubscription = async () => {
    await cancelSubscriptions({
      subscription_ids:
        subscriptionCancelId.length > 0 ? subscriptionCancelId : selected,
      accessToken: accessToken || "",
    }).unwrap();
  };

  const handleDialogClose = useCallback(() => {
    setSelected([]);
    setSubscriptionCancelId([]);
    setIsModalOpen(false);
  }, []);

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const isHeadChecked = useMemo(() => {
    if (manualChecked) {
      return true;
    }

    return (
      activeSubscriptions.length > 0 &&
      selected.length === activeSubscriptions.length
    );
  }, [activeSubscriptions, selected, manualChecked]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    getSubscriptions();
  }, [getSubscriptions, page]);

  useEffect(() => {
    if (page === 0) {
      dispatch(setActiveSubscriptions(activeSubscriptionsData?.data || []));
      return;
    }

    dispatch(
      setActiveSubscriptions([
        ...activeSubscriptions,
        ...(activeSubscriptionsData?.data || []),
      ])
    );
  }, [activeSubscriptionsData]);

  useEffect(() => {
    dispatch(setActiveSubscriptions([]));
    setPage(0);
  }, [sortBy, direction]);

  useEffect(() => {
    if (manualChecked) {
      const ids = activeSubscriptionsData?.data.map((item) => item.id) || [];
      setSelected([...selected, ...ids]);
    }
  }, [activeSubscriptionsData]);

  useEffect(() => {
    if (cancelSuccess) {
      setManualChecked(false);
      setSelected([]);
      setSubscriptionCancelId([]);
      dispatch(setActiveSubscriptions([]));
      setPage(0);
      setIsModalOpen(false);
      getSubscriptions();
    }
  }, [cancelSuccess]);

  useEffect(() => {
    if (cancelError) {
      navigate("/");
    }
  }, [cancelError]);

  return (
    <>
      {selected.length !== 0 ? (
        <Button
          variant="outlined"
          color="error"
          className={styles.selectedButton}
          onClick={handleCancelSelectedSubscription}
        >
          <CloseIcon color="rgba(255, 82, 82, 1)" width={16} height={16} />{" "}
          <Typography variant="body2">Cancel selected</Typography>
        </Button>
      ) : null}
      <Table
        sx={{
          minWidth: 650,
          marginTop: "12px",
          maxHeight: "100px",
          overflow: "hidden",
        }}
        aria-label="simple table"
      >
        <CustomTableHead
          tableHeads={subscriptionsTableHead}
          sortBy={sortBy}
          setSortBy={setSortBy}
          direction={direction}
          setDirection={setDirection}
          checkBoxCell={
            <TableCell padding="checkbox" className={styles.tableHead}>
              <Checkbox
                color="primary"
                indeterminate={
                  !manualChecked &&
                  selected.length > 0 &&
                  selected.length < activeSubscriptions.length
                }
                checked={isHeadChecked}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
          }
        />
        <TableBody>
          {activeSubscriptions
            .filter((row) => row.creator)
            .map((row: ISubscriptions, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  ref={
                    activeSubscriptions.length === index + 1
                      ? lastMemberElementRef
                      : null
                  }
                >
                  <TableCell
                    padding="checkbox"
                    className={`${styles.tableCell} ${styles.tableCellCheckbox}`}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={() => {
                        setManualChecked(false);
                        handleRowClick(row.id);
                      }}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    className={`${styles.tableCell} ${styles.tableCellAvatar}`}
                    sx={{ fontSize: "16px" }}
                  >
                    {row.creator?.avatar ? (
                      <Avatar
                        alt={row.creator.username}
                        src={row.creator.avatar.backend_media_url}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar
                        alt={row.creator?.username}
                        sx={{ width: 32, height: 32 }}
                      >
                        <DefaultAvatar width={32} height={32} />
                      </Avatar>
                    )}
                    <Typography variant="body1">
                      <Link to={`/user-profile/${row.creator?.id}`}>
                        {row.creator?.username}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="left">
                    <Typography variant="body1">Subscription name</Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="left">
                    <Typography variant="body1">
                      ${row.plan}.00/month
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="left">
                    <Typography variant="body1">
                      {getReadableDateTable(row.expires_at)}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="left">
                    {selected.length === 0 ? (
                      <Button
                        variant="outlined"
                        color="error"
                        className={styles.cancelButton}
                        onClick={() => handleCancelSubscription(row.id)}
                      >
                        <CloseIcon
                          color="rgba(255, 82, 82, 1)"
                          width={16}
                          height={16}
                        />{" "}
                        <Typography variant="body2">Cancel</Typography>
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress data-cy="loader" />}
      <ModalDialog isOpen={isModalOpen} onClose={handleDialogClose}>
        <ModalDialogHeader position="center">
          <Typography variant="h6" fontWeight={400}>
            Cancel subscription
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <Typography variant="body1" align="center">
            Are you sure you want to cancel subscriptions?
          </Typography>
        </ModalDialogContent>
        <ModalDialogFooter>
          {cancelLoading ? (
            <LinearProgress />
          ) : (
            <Box display="flex" gap="20px">
              <Button
                color="primary"
                sx={{ borderRadius: "12px", width: "100%" }}
                variant="contained"
                type="button"
                onClick={() => setIsModalOpen(false)}
              >
                <Typography variant="body1">Close</Typography>
              </Button>
              <Button
                color="error"
                variant="contained"
                type="button"
                sx={{ borderRadius: "12px", width: "100%" }}
                onClick={handleConfirmCancelSubscription}
              >
                <Typography variant="body1">Confirm cancellation</Typography>
              </Button>
            </Box>
          )}
        </ModalDialogFooter>
      </ModalDialog>
    </>
  );
};
