function PurchasesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g fill="#AAA" clipPath="url(#clip0_4623_57921)">
        <path d="M3.664 11.336a1 1 0 100-2 1 1 0 000 2z" />
        <path d="M12.667 2H3.333A3.337 3.337 0 000 5.333v5.334A3.337 3.337 0 003.333 14h9.334A3.337 3.337 0 0016 10.667V5.333A3.337 3.337 0 0012.667 2zM3.333 3.333h9.334a2 2 0 012 2H1.333a2 2 0 012-2zm9.334 9.334H3.333a2 2 0 01-2-2v-4h13.334v4a2 2 0 01-2 2z" />
      </g>
      <defs>
        <clipPath id="clip0_4623_57921">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PurchasesIcon;
