export const BitcoinAccountIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0621 14.9584L17.7934 15.2326L18.1529 15.3674C20.8321 16.3721 22.7507 18.9613 22.7507 22C22.7507 25.9019 19.5692 29.0833 15.6673 29.0833H13.6673H13.4173V29.3333V30.6667C13.4173 31.2619 12.9292 31.75 12.334 31.75C11.7387 31.75 11.2507 31.2619 11.2507 30.6667V29.3333V29.0833H11.0007H8.33398H8.08398V29.3333V30.6667C8.08398 31.2619 7.59591 31.75 7.00065 31.75C6.40539 31.75 5.91732 31.2619 5.91732 30.6667V29.3333V29.0833H5.66732C2.85872 29.0833 0.583984 26.8086 0.583984 24V8C0.583984 5.1914 2.85872 2.91667 5.66732 2.91667H5.91732V2.66667V1.33333C5.91732 0.738071 6.40539 0.25 7.00065 0.25C7.59591 0.25 8.08398 0.738071 8.08398 1.33333V2.66667V2.91667H8.33398H11.0007H11.2507V2.66667V1.33333C11.2507 0.738071 11.7387 0.25 12.334 0.25C12.9292 0.25 13.4173 0.738071 13.4173 1.33333V2.69333V2.92119L13.6442 2.94226C17.2507 3.27715 20.084 6.31748 20.084 10C20.084 11.9321 19.3114 13.6833 18.0621 14.9584ZM2.75065 14.6667V14.9167H3.00065H13.0007C15.7121 14.9167 17.9173 12.7114 17.9173 10C17.9173 7.2886 15.7121 5.08333 13.0007 5.08333H5.66732C4.06258 5.08333 2.75065 6.39526 2.75065 8V14.6667ZM3.00065 17.0833H2.75065V17.3333V24C2.75065 25.6047 4.06258 26.9167 5.66732 26.9167H15.6673C18.3787 26.9167 20.584 24.7114 20.584 22C20.584 19.2886 18.3787 17.0833 15.6673 17.0833H3.00065Z"
      fill="black"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);
