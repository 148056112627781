import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as Yup from "yup";

import { maxLengthOfFolderName } from "../../../../../../../constants/constants";
import { CrossIcon } from "../../../../../../../media/icons/cross";
import { IFolder } from "../../../../../../../services/catalog/interfaces/catalog-folders.interfaces";
import { ModalWrapper } from "../../../../../../creator-card/modal-wrapper/modal-wrapper";
import styles from "./folder-update-form.module.scss";

interface IFolderUpdateFormProps {
  isOpen: boolean;
  isLoading: boolean;
  onAcceptUpdateClick: (folderName: string) => void;
  onCancelUpdateClick: () => void;
  folderToUpdate: IFolder | null;
}

export const FolderUpdateForm: FC<IFolderUpdateFormProps> = ({
  isOpen,
  isLoading,
  onAcceptUpdateClick,
  onCancelUpdateClick,
  folderToUpdate,
}) => {
  const formik = useFormik<{ name: string }>({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable().max(maxLengthOfFolderName),
    }),
    onSubmit: async (values) => {
      onAcceptUpdateClick(values.name);
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", folderToUpdate?.name || "");
  }, [folderToUpdate]);

  return (
    <ModalWrapper
      shown={isOpen}
      close={onCancelUpdateClick}
      disableBackdropClose
    >
      <Box className={styles.updateWrapper}>
        <Box className={styles.update}>
          <Box className={styles.header}>
            <Typography fontSize="24px">Update name</Typography>
            <IconButton
              onClick={() => {
                onCancelUpdateClick();
              }}
            >
              <CrossIcon />
            </IconButton>
          </Box>

          <form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className={styles.form}
          >
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                name="name"
                placeholder="Folder name"
                error={!!formik.errors.name}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <Typography
                variant="caption"
                align="right"
                color={formik.errors.name ? "#FF5252" : "#989898"}
                marginTop="3px"
              >
                {formik.values.name ? formik.values.name.length : 0}/
                {maxLengthOfFolderName}
              </Typography>
            </Box>
            <Box className={styles.btnInteraction}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                sx={{
                  width: "237px",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  <Typography variant="button">Save</Typography>
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
