import React, { useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../../constants/local-storage-keys/local-storage-keys";
import { useCreateFolderMutation } from "../../../../services/catalog/catalog.service";
import { FolderBreadcrumbs } from "../../../shared/folder-breadcrumbs/folder-breadcrumbs";
import { CatalogSearchBarCC } from "../../catalog-search-bar-cc/catalog-search-bar-cc";
import { ContentCcCollection } from "./content-collection/content-cc-collection";
import { useCreatorCatalogCCViewContext } from "./context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "./context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { AddToFolderForm } from "./context/folder-modals/add-to-folder/add-to-folder";
import { FolderCreateForm } from "./context/folder-modals/folder-create-form/folder-create-form";
import styles from "./creator-catalog-cc-view.module.scss";
import { SideBarCcView } from "./side-bar-cc-view/side-bar-cc-view";

interface CreatorCatalogCCViewParams {}

export const CreatorCatalogCCView: React.FC<
  CreatorCatalogCCViewParams
> = () => {
  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { isFolderNavigation, setIsFolderNavigation, updateCatalogParam } =
    useCreatorCatalogCCViewContext();
  const { currentOpenedFolder, getFoldersById, setFolderToUpdate } =
    useFolderCreatorCatalogCCViewContext();
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState<boolean>(false);
  const [isAddToFolderOpen, setIsAddToFolderOpen] = useState<boolean>(false);

  const [
    createFolder,
    { isLoading: createFolderLoading, isSuccess: createFolderSuccess },
  ] = useCreateFolderMutation();

  const createNewFolder = useCallback(
    async (folderName: string) => {
      await createFolder({
        accessToken: userAccessToken || "",
        folderName,
        parent_folder_id: currentOpenedFolder?.id
          ? currentOpenedFolder.id
          : null,
      });
    },
    [createFolder, userAccessToken, currentOpenedFolder]
  );

  const onFolderClick = useCallback(
    (folderId: string | null) => {
      setIsFolderNavigation(!!folderId);
      updateCatalogParam("folder_id", folderId || undefined);
      getFoldersById(folderId || undefined);
    },
    [setIsFolderNavigation, updateCatalogParam, getFoldersById]
  );

  const onRenameClick = () => {
    setFolderToUpdate(currentOpenedFolder);
  };

  const onCreateFolderClick = () => {
    setIsCreateFolderOpen(true);
  };

  const onFolderMoveClick = () => {
    setIsAddToFolderOpen(true);
  };

  const onCreateNewFolder = useCallback(
    async (folderName: string) => {
      let name = folderName;

      if (!name) {
        const folderWithDefaultName = currentOpenedFolder?.child_folders.find(
          (item) => item.name.match(/^Folder \d+$/)
        );

        if (!folderWithDefaultName) {
          name = `Folder 1`;
        } else {
          const [, number] = folderWithDefaultName.name.split(" ");
          name = `Folder ${Number(number) + 1}`;
        }
      }

      await createNewFolder(name);
    },
    [currentOpenedFolder, createNewFolder]
  );

  useEffect(() => {
    if (createFolderSuccess) {
      setIsCreateFolderOpen(false);
      getFoldersById(currentOpenedFolder?.id);
    }
  }, [createFolderSuccess]);

  return (
    <div
      className={`${
        isFolderNavigation
          ? styles.containerSelectPostForCollection
          : styles.container
      }`}
    >
      {!isFolderNavigation && (
        <>
          <SideBarCcView />
          <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
            <CatalogSearchBarCC />
          </div>
        </>
      )}

      {isFolderNavigation && currentOpenedFolder && (
        <FolderBreadcrumbs
          folder={currentOpenedFolder}
          onFolderClick={onFolderClick}
          isDisplayFolderPopper
          onRenameClick={onRenameClick}
          onCreateFolderClick={onCreateFolderClick}
          onFolderMove={onFolderMoveClick}
        />
      )}
      <ContentCcCollection />
      {currentOpenedFolder && (
        <FolderCreateForm
          isOpen={isCreateFolderOpen}
          onSaveCreateClick={onCreateNewFolder}
          isLoading={createFolderLoading}
          onCancelCreateClick={() => {
            setIsCreateFolderOpen(false);
          }}
        />
      )}
      {currentOpenedFolder && (
        <AddToFolderForm
          isOpen={isAddToFolderOpen}
          folderToMove={currentOpenedFolder}
          onClose={() => {
            setIsAddToFolderOpen(false);
          }}
        />
      )}
    </div>
  );
};
