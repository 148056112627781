import type { FC } from "react";

type PasswordValidationSuccessProp = {
  width?: number;
  height?: number;
};

export const PasswordValidationSuccess: FC<PasswordValidationSuccessProp> = ({
  width = 14,
  height = 14,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 0.333008C3.32367 0.333008 0.333008 3.32367 0.333008 6.99967C0.333008 10.6757 3.32367 13.6663 6.99967 13.6663C10.6757 13.6663 13.6663 10.6757 13.6663 6.99967C13.6663 3.32367 10.6757 0.333008 6.99967 0.333008ZM5.66701 9.94167L3.19167 7.47167L4.13301 6.52767L5.66567 8.05767L9.19501 4.52834L10.1377 5.47101L5.66701 9.94167Z"
      fill="#30D354"
    />
  </svg>
);
