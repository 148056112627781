import type { SwitchProps, Theme } from "@mui/material";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& .MuiSwitch-thumb::before": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6627 5.31061L5.30267 0.643942C4.80593 0.280198 4.21815 0.0611376 3.60451 0.0110503C2.99087 -0.0390371 2.37535 0.0818059 1.8262 0.36018C1.27704 0.638553 0.815718 1.06358 0.493372 1.58813C0.171027 2.11268 0.000257343 2.71626 0 3.33194V12.6653C0.000109848 13.2812 0.170889 13.8851 0.493383 14.4099C0.815878 14.9347 1.27748 15.3599 1.82695 15.6382C2.37642 15.9166 2.99227 16.0372 3.60616 15.9868C4.22006 15.9364 4.80797 15.7169 5.30467 15.3526L11.6647 10.6859C12.0868 10.3763 12.4302 9.97165 12.6668 9.50465C12.9034 9.03765 13.0268 8.52148 13.0268 7.99794C13.0268 7.47441 12.9034 6.95823 12.6668 6.49124C12.4302 6.02424 12.0868 5.61954 11.6647 5.30994L11.6627 5.31061ZM10.8733 9.61061L4.51333 14.2773C4.21534 14.495 3.86291 14.6261 3.49505 14.6559C3.12718 14.6858 2.75824 14.6132 2.42904 14.4464C2.09985 14.2795 1.82326 14.0248 1.62987 13.7104C1.43648 13.3961 1.33385 13.0343 1.33333 12.6653V3.33194C1.32963 2.96217 1.43033 2.59886 1.62385 2.28376C1.81737 1.96865 2.09587 1.71454 2.42733 1.55061C2.70926 1.4072 3.02103 1.33229 3.33733 1.33194C3.76126 1.33356 4.17348 1.4712 4.51333 1.72461L10.8733 6.39127C11.1263 6.57707 11.332 6.81981 11.4738 7.09985C11.6156 7.3799 11.6895 7.68938 11.6895 8.00327C11.6895 8.31717 11.6156 8.62665 11.4738 8.9067C11.332 9.18674 11.1263 9.42948 10.8733 9.61528V9.61061Z' fill='%233A75E9'/%3E%3C/svg%3E")`,
        left: 1,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: (theme as Theme).palette.primary,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    boxSizing: "border-box",
    width: 24,
    height: 24,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.94844 1.36111C1.94844 0.857205 2.30372 0.55 2.62344 0.55H5.07343C5.39316 0.55 5.74844 0.857205 5.74844 1.36111V12.6389C5.74844 13.1428 5.39316 13.45 5.07343 13.45H2.62344C2.30372 13.45 1.94844 13.1428 1.94844 12.6389V1.36111Z' stroke='%233A75E9' stroke-width='1'/%3E%3Cpath d='M8.25313 1.36111C8.25313 0.857204 8.60841 0.55 8.92813 0.55H11.3781C11.6978 0.55 12.0531 0.857204 12.0531 1.36111V12.6389C12.0531 13.1428 11.6978 13.45 11.3781 13.45H8.92813C8.60841 13.45 8.25313 13.1428 8.25313 12.6389V1.36111Z' stroke='%233A75E9' stroke-width='1'/%3E%3C/svg%3E")`,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 31 / 2,
    backgroundColor: "#D0D9DC",
    opacity: 1,
    transition: (theme as Theme).transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CustomMediaSwitch = (props: SwitchProps) => (
  <MaterialUISwitch {...props} sx={{ m: 1 }} />
);
