import type { FC, PropsWithChildren } from "react";

interface ILogoSmallProp {
  status?: "valid" | "default";
  width?: number;
  height?: number;
}
export const LogoSmallIcon: FC<PropsWithChildren<ILogoSmallProp>> = ({
  width = 32,
  height = 32,
  status = "default",
  children,
}) => (
  <>
    {status === "default" && (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 48 48"
          fill="none"
        >
          <g filter="url(#filter0_b_696_32643)">
            <rect
              width="48"
              height="48"
              rx="24"
              fill="black"
              fillOpacity="0.3"
            />
            <rect
              x="0.461538"
              y="0.461538"
              width="47.0769"
              height="47.0769"
              rx="23.5385"
              stroke="white"
              strokeWidth="0.923077"
            />
          </g>
          <defs>
            <filter
              id="filter0_b_696_32643"
              x="-1.84615"
              y="-1.84615"
              width="51.6923"
              height="51.6923"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.923077" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_696_32643"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_696_32643"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        {children}
      </>
    )}

    {status === "valid" && (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 48 48"
          fill="none"
        >
          <g filter="url(#filter0_b_3852_72609)">
            <rect
              width="48"
              height="48"
              rx="24"
              fill="url(#paint0_linear_3852_72609)"
            />
            <rect
              x="0.461538"
              y="0.461538"
              width="47.0769"
              height="47.0769"
              rx="23.5385"
              stroke="white"
              strokeWidth="0.923077"
            />
          </g>
          <defs>
            <filter
              id="filter0_b_3852_72609"
              x="-1.84615"
              y="-1.84615"
              width="51.6923"
              height="51.6923"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.923077" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3852_72609"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3852_72609"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_3852_72609"
              x1="0.00764655"
              y1="24.0223"
              x2="47.997"
              y2="24.0223"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#50EC72" />
              <stop offset="0.563814" stopColor="#3DF365" />
              <stop offset="0.997668" stopColor="#56E185" />
            </linearGradient>
          </defs>
        </svg>
        {children}
      </>
    )}
  </>
);
