export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      color: "#4C4B4C",
      fontSize: "1rem",
    },
  };
}
