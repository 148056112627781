import type { ITableHead } from "../../../interfaces/table-head.interface";

export const mySubscribersTableHeads: ITableHead[] = [
  {
    display_text: "Name",
    value: "subscriber.username",
    sortable: true,
    default_sort: false,
    headWidth: 17,
  },
  {
    display_text: "Start date",
    value: "created_at",
    sortable: true,
    default_sort: false,
    headWidth: 17,
  },
  {
    display_text: "Expiration date",
    value: "expires_at",
    sortable: true,
    default_sort: true,
    headWidth: 20,
  },
  {
    display_text: "Number of Renewals",
    value: "subscription_renewals",
    sortable: true,
    default_sort: true,
    headWidth: 25,
  },
  {
    display_text: "Purchases",
    value: "number_of_purchases",
    sortable: true,
    default_sort: true,
    headWidth: 15,
  },
  {
    display_text: "Tips",
    value: "sent_tips",
    sortable: true,
    default_sort: true,
    headWidth: 6,
  },
];
