export const EuroLogo = ({
  width = 19,
  height = 18,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2 13.41C15.6674 14.1822 14.9611 14.8187 14.1379 15.2686C13.3147 15.7184 12.3975 15.9689 11.46 16C8.78 16 6.46 15 5.46 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11C12 10.7348 11.8946 10.4804 11.7071 10.2929C11.5196 10.1054 11.2652 10 11 10H5.05C5.05 9.67 5 9.33 5 9C5 8.67 5 8.33 5.05 8H11C11.2652 8 11.5196 7.89464 11.7071 7.70711C11.8946 7.51957 12 7.26522 12 7C12 6.73478 11.8946 6.48043 11.7071 6.29289C11.5196 6.10536 11.2652 6 11 6H5.47C6.47 3 8.78 2 11.47 2C12.4058 2.03267 13.321 2.28392 14.1424 2.73366C14.9637 3.1834 15.6684 3.81913 16.2 4.59C16.2706 4.71152 16.366 4.8168 16.48 4.89898C16.5939 4.98116 16.724 5.03839 16.8616 5.06694C16.9992 5.09549 17.1412 5.09472 17.2785 5.06468C17.4158 5.03464 17.5452 4.976 17.6583 4.8926C17.7714 4.80919 17.8656 4.70288 17.9349 4.58061C18.0041 4.45833 18.0468 4.32283 18.0602 4.18294C18.0736 4.04305 18.0573 3.90192 18.0125 3.76874C17.9676 3.63556 17.8952 3.51332 17.8 3.41C17.0877 2.38118 16.1418 1.53577 15.0398 0.943041C13.9378 0.350313 12.711 0.027138 11.46 0C7.7 0 4.46 1.84 3.39 6H1C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8H3.05C3.05 8.33 3.05 8.67 3.05 9C3.05 9.33 3.05 9.67 3.05 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H3.39C4.48 16.16 7.7 18 11.46 18C12.711 17.9729 13.9378 17.6497 15.0398 17.057C16.1418 16.4642 17.0877 15.6188 17.8 14.59C17.9237 14.377 17.9644 14.1258 17.9144 13.8846C17.8643 13.6434 17.727 13.4292 17.5288 13.283C17.3305 13.1368 17.0853 13.0689 16.8401 13.0923C16.5949 13.1158 16.367 13.2289 16.2 13.41Z"
      fill="#FF9100"
    />
  </svg>
);
