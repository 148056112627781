import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import {
  IAddContentToCollectionsParams,
  IAddViewParams,
  IBuyContentParams,
  ICreateCollectionParams,
  ICreateFolderParams,
  IDeleteContentByIdsParams,
  IDeleteFolderParams,
  IGetAllFoldersParams,
  IGetContentByIdParams,
  IGetContentCatalogCsParams,
  IGetContentCatalogParams,
  IGetUserCreatorByIdParams,
  IRemoveFromFolderParams,
  IRemoveLovedParams,
  ISetLovedParams,
  IUpdateCollectionParams,
  IUpdateContentByIdsParams,
  IUpdateFolderParams,
  VisibilityEnum,
} from "./interfaces/request-models.interfaces";
import {
  IAddContentToCollectionsResponse,
  IAddViewResponse,
  IBuyContentResponse,
  ICreateCollectionResponse,
  ICreateFolderResponse,
  IDeleteContentByIdsResponse,
  IDeleteFolderResponse,
  IGetAllFoldersResponse,
  IGetContentByIdResponse,
  IGetContentCatalogMeResponce,
  IGetContentCatalogResponse,
  IGetUserCreatorByIdResponse,
  IRemoveFromFolderResponse,
  IRemoveLovedResponse,
  ISetLovedResponse,
  IUpdateContentByIdsResponse,
  IUpdateFolderResponse,
} from "./interfaces/responce-models.interfaces";

export const catalogApi = createApi({
  reducerPath: "catalogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getContentCatalogMe: builder.mutation<
      IGetContentCatalogMeResponce,
      IGetContentCatalogParams
    >({
      query: (data: IGetContentCatalogParams) => {
        const {
          audience = [],
          show = [],
          contentType = [],
          visibility = [],
          contentDetails = [],
        } = data.filters || {};

        const modifiedSort = data.sortBy?.split("/")[0];

        return {
          url:
            `${ENDPOINTS.CATALOG.GET_MY_CONTENT}?page=${data.page}&rowsPerPage=${data.rowsPerPage}` +
            `${data.searchText !== undefined && data.searchText ? `&searchText=${data.searchText}` : ""}` +
            `${audience.length > 0 ? `&audience=${audience.join(",")}` : ""}` +
            `${visibility.length > 0 ? `&visibility=${visibility.join(",")}` : ""}` +
            `${contentDetails.length > 0 ? `&contentDetails=${contentDetails.join(",")}` : ""}` +
            `${contentType.length > 0 ? `&contentType=${contentType.join(",")}` : ""}` +
            `${show.length > 0 ? `&show=${show}` : ""}` +
            `${modifiedSort !== null ? `&sortBy=${modifiedSort}` : ""}` +
            `${data.direction !== null ? `&direction=${data.direction}` : ""}` +
            `${data.folder_id !== undefined ? `&folder_id=${data.folder_id}` : ""}` +
            `${data.currentTab !== null ? `&currentTab=${data.currentTab}` : ""}`,
          method: "GET",
          headers: {
            Authorization: data.accessToken,
          },
        };
      },
    }),

    getContentCatalog: builder.mutation<
      IGetContentCatalogResponse,
      IGetContentCatalogCsParams
    >({
      query: (data: IGetContentCatalogCsParams) => {
        const { show = [], contentType = [], price } = data.filters || {};
        const direction =
          /* eslint-disable-next-line no-nested-ternary */
          price[0] === "highLow"
            ? "desc"
            : price[0] === "lowHigh"
              ? "asc"
              : null;

        return {
          url:
            `${ENDPOINTS.CATALOG.GET_CC_CONTENT}${data.creator_id}?page=${data.page}&rowsPerPage=${data.rowsPerPage}` +
            `${data.searchText !== undefined && data.searchText ? `&searchText=${data.searchText}` : ""}` +
            `${contentType.length > 0 ? `&contentType=${contentType.join(",")}` : ""}` +
            `${show.length > 0 ? `&show=${show}` : ""}` +
            `${direction !== null ? `&sortBy=price` : ""}` +
            `${direction !== null ? `&direction=${direction}` : ""}`,
          method: "GET",
          headers: {
            Authorization: data.accessToken,
          },
        };
      },
    }),

    getUserCreatorById: builder.mutation<
      IGetUserCreatorByIdResponse,
      IGetUserCreatorByIdParams
    >({
      query: (data: IGetUserCreatorByIdParams) => ({
        url: `${ENDPOINTS.CATALOG.GET_USER_CREATOR_CARD_BY_ID}/${data.id}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    deleteContentByIds: builder.mutation<
      IDeleteContentByIdsResponse,
      IDeleteContentByIdsParams
    >({
      query: (data: IDeleteContentByIdsParams) => ({
        url: `${ENDPOINTS.CATALOG.DELETE_CONTENT_BY_IDS}`,
        method: "DELETE",
        body: {
          content_ids: data.content_ids,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    updateContentById: builder.mutation<
      IUpdateContentByIdsResponse,
      IUpdateContentByIdsParams
    >({
      query: (data: IUpdateContentByIdsParams) => {
        console.log("111: ", data);

        return {
          url: ENDPOINTS.CATALOG.UPDATE_SINGLE_CONTENT,
          method: "PATCH",
          body: {
            content_ids: data.contentIds,
            name: data.formData.title === "" ? null : data.formData.title,
            description: data.formData.description,
            audience: {
              audience: data.formData?.audience?.audience,
              ids: data.formData.audience?.ids,
            },
            price: data.formData.price?.amount,
            currency: data.formData.price?.currency,
            visibility:
              data.formData.visibility?.visibility === VisibilityEnum.planned
                ? data.formData.visibility?.date
                : null,
            content_tags: data.formData.tags,
            folder_id: data.folder_id,
          },
          headers: {
            Authorization: data.accessToken,
          },
        };
      },
    }),

    updateCollectionById: builder.mutation<
      IUpdateContentByIdsResponse,
      IUpdateCollectionParams
    >({
      query: (data: IUpdateCollectionParams) => ({
        url: ENDPOINTS.CATALOG.UPDATE_COLLECTION,
        method: "PATCH",
        body: {
          collectionId: data.collectionId,
          media_id_changed: data.media_id_changed,
          name: data.formData.title === "" ? null : data.formData.title,
          description: data.formData.description,
          audience: {
            audience: data.formData?.audience?.audience,
            ids: data.formData.audience?.ids,
          },
          price: data.formData.price?.amount,
          currency: data.formData.price?.currency,
          visibility:
            data.formData.visibility?.visibility === VisibilityEnum.planned
              ? data.formData.visibility?.date
              : null,
          content_tags: data.formData.tags,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    createCollectionBy: builder.mutation<
      ICreateCollectionResponse,
      ICreateCollectionParams
    >({
      query: (data: ICreateCollectionParams) => ({
        url: ENDPOINTS.CATALOG.CREATE_COLLECTION,
        method: "POST",
        body: {
          media_ids: data.media_ids,
          name: data.formData.title === "" ? null : data.formData.title,
          description: data.formData.description,
          audience: {
            audience: data.formData?.audience?.audience,
            ids: data.formData.audience?.ids,
          },
          price: data.formData.price?.amount,
          currency: data.formData.price?.currency,
          visibility:
            data.formData.visibility?.visibility === VisibilityEnum.planned
              ? data.formData.visibility?.date
              : null,
          content_tags: data.formData.tags,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    addContentToCollections: builder.mutation<
      IAddContentToCollectionsResponse,
      IAddContentToCollectionsParams
    >({
      query: (data: IAddContentToCollectionsParams) => ({
        url: ENDPOINTS.CATALOG.ADD_CONTENT_TO_COLLECTIONS,
        method: "PATCH",
        body: {
          media_ids: data.media_ids,
          collection_ids: data.collection_ids,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getFoldersById: builder.mutation<
      IGetAllFoldersResponse,
      IGetAllFoldersParams
    >({
      query: (data: IGetAllFoldersParams) => ({
        url: ENDPOINTS.CATALOG.GET_FOLDER_BY_ID,
        method: "POST",
        body: {
          folderId: data.folderId ? data.folderId : null,
          searchText: data.searchText ? data.searchText : null,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    createFolder: builder.mutation<ICreateFolderResponse, ICreateFolderParams>({
      query: (data: ICreateFolderParams) => ({
        url: ENDPOINTS.CATALOG.CREATE_FOLDER,
        method: "POST",
        body: {
          name: data.folderName,
          parent_folder_id: data.parent_folder_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    deleteFolder: builder.mutation<IDeleteFolderResponse, IDeleteFolderParams>({
      query: (data: IDeleteFolderParams) => ({
        url: `${ENDPOINTS.CATALOG.DELETE_FOLDER}${data.folderId}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    removeFromFolder: builder.mutation<
      IRemoveFromFolderResponse,
      IRemoveFromFolderParams
    >({
      query: (data: IRemoveFromFolderParams) => ({
        url: `${ENDPOINTS.CATALOG.REMOVE_FROM_FOLDER}${data.folderId}`,
        method: "DELETE",
        body: {
          content_id: data.content_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    updateFolder: builder.mutation<IUpdateFolderResponse, IUpdateFolderParams>({
      query: (data: IUpdateFolderParams) => ({
        url: `${ENDPOINTS.CATALOG.UPDATE_FOLDER}${data.folderId}`,
        method: "PATCH",
        body: {
          name: data.folderName,
          content_id: data.content_id,
          destination_folder_id: data.destination_folder_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    setLoved: builder.mutation<ISetLovedResponse, ISetLovedParams>({
      query: (data: ISetLovedParams) => ({
        url: ENDPOINTS.CATALOG.SET_LOVED,
        method: "POST",
        body: {
          content_id: data.content_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    removeLoved: builder.mutation<IRemoveLovedResponse, IRemoveLovedParams>({
      query: (data: IRemoveLovedParams) => ({
        url: ENDPOINTS.CATALOG.REMOVE_LOVED,
        method: "DELETE",
        body: {
          content_id: data.content_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    addView: builder.mutation<IAddViewResponse, IAddViewParams>({
      query: (data: IAddViewParams) => ({
        url: ENDPOINTS.CATALOG.ADD_VIEW,
        method: "POST",
        body: {
          content_id: data.content_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    buyContent: builder.mutation<IBuyContentResponse, IBuyContentParams>({
      query: (data: IBuyContentParams) => ({
        url: `${ENDPOINTS.CATALOG.BUY_CONTENT}${data.content_id}`,
        method: "POST",
        body: {
          currency: data.currency,
          payment_method_id: data.payment_method_id,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),

    getContentById: builder.mutation<
      IGetContentByIdResponse,
      IGetContentByIdParams
    >({
      query: (data: IGetContentByIdParams) => ({
        url: `${ENDPOINTS.CATALOG.GET_CONTENT_BY_ID}${data.content_id}`,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const {
  useGetContentCatalogMeMutation,
  useGetUserCreatorByIdMutation,
  useDeleteContentByIdsMutation,
  useUpdateContentByIdMutation,
  useAddContentToCollectionsMutation,
  useUpdateCollectionByIdMutation,
  useCreateCollectionByMutation,
  useGetFoldersByIdMutation,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
  useCreateFolderMutation,
  useRemoveFromFolderMutation,
  useGetContentCatalogMutation,
  useSetLovedMutation,
  useRemoveLovedMutation,
  useAddViewMutation,
  useBuyContentMutation,
  useGetContentByIdMutation,
} = catalogApi;
