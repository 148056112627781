import type { ITableHead } from "../../interfaces/table-head.interface";

export const TransferHistoryTableHead: ITableHead[] = [
  {
    display_text: "Date:",
    value: "date",
    sortable: true,
    default_sort: true,
    headWidth: 20,
  },
  {
    display_text: "To:",
    value: "to",
    sortable: false,
    headWidth: 20,
  },
  {
    display_text: "Currency:",
    value: "currency",
    sortable: false,
    headWidth: 20,
  },
  {
    display_text: "Amount:",
    value: "amount",
    sortable: true,
    headWidth: 20,
  },
  {
    display_text: "Status:",
    value: "status",
    sortable: false,
    headWidth: 20,
  },
];
