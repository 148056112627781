export const InfoIcon = ({
  width = 20,
  height = 20,
  color = "#AAAAAA",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.5C3.8625 0.5 0.5 3.8625 0.5 8C0.5 12.1375 3.8625 15.5 8 15.5C12.1375 15.5 15.5 12.1375 15.5 8C15.5 3.8625 12.1375 0.5 8 0.5ZM8 14.25C4.55 14.25 1.75 11.45 1.75 8C1.75 4.55 4.55 1.75 8 1.75C11.45 1.75 14.25 4.55 14.25 8C14.25 11.45 11.45 14.25 8 14.25Z"
      fill={color}
    />
    <path
      d="M8 6.5625C7.65 6.5625 7.375 6.8375 7.375 7.1875V11.4375C7.375 11.7875 7.65 12.0625 8 12.0625C8.35 12.0625 8.625 11.7875 8.625 11.4375V7.1875C8.625 6.85 8.35 6.5625 8 6.5625Z"
      fill={color}
    />
    <path
      d="M8.0125 4.00131C7.6875 3.97631 7.3875 4.31381 7.375 4.65131C7.375 4.66381 7.375 4.73881 7.375 4.75131C7.375 5.08881 7.6375 5.33881 7.9875 5.35131H8C8.3375 5.35131 8.6125 5.05131 8.625 4.72631C8.625 4.71381 8.625 4.58881 8.625 4.58881C8.625 4.22631 8.3625 4.00131 8.0125 4.00131Z"
      fill={color}
    />
  </svg>
);
