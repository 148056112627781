export const EditIcon = ({
  width = 18,
  height = 18,
  color = "#3A75E9",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66406 3.33594H3.83073C3.3887 3.33594 2.96478 3.51153 2.65222 3.82409C2.33966 4.13665 2.16406 4.56058 2.16406 5.0026V16.6693C2.16406 17.1113 2.33966 17.5352 2.65222 17.8478C2.96478 18.1603 3.3887 18.3359 3.83073 18.3359H15.4974C15.9394 18.3359 16.3633 18.1603 16.6759 17.8478C16.9885 17.5352 17.1641 17.1113 17.1641 16.6693V10.8359"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9141 2.08027C16.2456 1.74875 16.6952 1.5625 17.1641 1.5625C17.6329 1.5625 18.0825 1.74875 18.4141 2.08027C18.7456 2.41179 18.9318 2.86143 18.9318 3.33027C18.9318 3.79911 18.7456 4.24875 18.4141 4.58027L10.4974 12.4969L7.16406 13.3303L7.9974 9.99693L15.9141 2.08027Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
