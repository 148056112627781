import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import type { Dispatch, FC, SetStateAction } from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { MESSAGE_TIMEOUT } from "../../../../constants/constants";
import type { IConfirmEmailFail } from "../../../../services/sign-up/interfaces/confirm-email.response.interface";
import {
  useCheckEmailMutation,
  useConfirmEmailMutation,
} from "../../../../services/sign-up/sign-up.service";
import signUpShared from "../../sign-up-shared.module.scss";
import { getColorOfState } from "../../sign-up.utils";
import type { ConfirmEmailType } from "../../types/sign-up.types";
import { SignUpCard } from "../sign-up-card/sign-up-card";

type ConfirmEmailProp = {
  setConfirmEmail: Dispatch<SetStateAction<ConfirmEmailType>>;
  isConfirmEmailValid: boolean;
  email: string;
  confirmEmail: ConfirmEmailType;
};

export const ConfirmEmail: FC<ConfirmEmailProp> = ({
  setConfirmEmail,
  isConfirmEmailValid,
  email,
  confirmEmail: code,
}) => {
  const [isMessageVisible, setIsMessageVisible] = useState({
    newCode: false,
    submitedCode: false,
  });
  const [confirmEmail, { error, isSuccess, isLoading }] =
    useConfirmEmailMutation();
  const [
    checkEmail,
    { isLoading: isResendLoading, isSuccess: isResendSuccess },
  ] = useCheckEmailMutation();

  const formik = useFormik({
    initialValues: {
      code: code.value,
    },
    validationSchema: Yup.object({
      code: Yup.string(),
    }),
    onSubmit: async (values: { code: string }) => {
      await confirmEmail({ email, code: Number(values.code) || 0 }).unwrap();
    },
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isResendSuccess) {
      setIsMessageVisible((prev) => ({ ...prev, newCode: true }));
      timeoutId = setTimeout(() => {
        setIsMessageVisible((prev) => ({ ...prev, newCode: false }));
      }, MESSAGE_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
  }, [isResendSuccess]);

  useEffect(() => {
    if (error) {
      setConfirmEmail({ status: "invalid", value: "" });

      const err = error as IConfirmEmailFail;
      let errorMessage = "";

      switch (err.status) {
        case 409:
          errorMessage =
            "Sorry, the code you entered doesn't match. Please try again. If you entered the wrong email address, go back and fix it now, and we'll send another code.";
          break;
        default:
          errorMessage = "Something went wrong. Please try again!";
      }

      formik.setErrors({
        code: errorMessage,
      });
    }
  }, [error]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isSuccess) {
      setConfirmEmail({ status: "valid", value: formik.values.code });
      setIsMessageVisible((prev) => ({ ...prev, submitedCode: true }));
      timeoutId = setTimeout(() => {
        setIsMessageVisible((prev) => ({ ...prev, submitedCode: false }));
      }, MESSAGE_TIMEOUT);
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isSuccess]);

  return (
    <SignUpCard status={isConfirmEmailValid ? "valid" : "default"}>
      <div className={signUpShared.cardContent}>
        <Typography variant="h6" fontWeight={400}>
          Enter the code in the email we sent you:
        </Typography>
        {!isConfirmEmailValid && (
          <Typography
            style={{
              display: "flex",
              gap: "3px",
            }}
            variant="body2"
          >
            If you haven&apos;t received the code, click{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              disabled={isResendLoading || isConfirmEmailValid}
              sx={{
                color: isResendLoading || isConfirmEmailValid ? "#bebebe" : "",
                cursor:
                  isResendLoading || isConfirmEmailValid ? "auto" : "pointer",
              }}
              onClick={async () => {
                await checkEmail(email);
              }}
            >
              Resend Code
              {isResendLoading && (
                <CircularProgress
                  style={{ marginLeft: "5px" }}
                  color="primary"
                  size=".7rem"
                />
              )}
            </Link>
          </Typography>
        )}

        <form
          className={signUpShared.cardForm}
          noValidate
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <TextField
            placeholder="Enter the code"
            color={getColorOfState(
              isLoading,
              formik.errors.code,
              isConfirmEmailValid
            )}
            error={!!(formik.touched.code && formik.errors.code)}
            fullWidth
            name="code"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
            disabled={isConfirmEmailValid}
            InputProps={{ sx: { borderRadius: "4px" } }}
          />
          {!isConfirmEmailValid && (
            <Button
              color="primary"
              className={signUpShared.actionBtn}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && <CircularProgress color="inherit" size="1rem" />}
              <Typography variant="button">Submit</Typography>
            </Button>
          )}
        </form>
      </div>
      {isResendSuccess &&
        !isResendLoading &&
        !isConfirmEmailValid &&
        isMessageVisible.newCode && (
          <Typography color="success.main" variant="caption" marginTop="4px">
            New code has been sent
          </Typography>
        )}
      {formik.errors.code && (
        <Typography color="error" variant="caption" marginTop="4px">
          {formik.errors.code}
        </Typography>
      )}
    </SignUpCard>
  );
};
