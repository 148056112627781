export default function CommentIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3234_63759)">
        <path
          d="M20.0009 9.98625C19.8804 8.03627 19.1919 6.16418 18.0203 4.60077C16.8486 3.03737 15.2451 1.85099 13.4074 1.18791C11.5696 0.524832 9.57802 0.414029 7.67807 0.869164C5.77812 1.3243 4.05289 2.32548 2.71507 3.74927C1.37724 5.17306 0.485308 6.95722 0.149226 8.8818C-0.186857 10.8064 0.0476043 12.7872 0.823704 14.5802C1.5998 16.3731 2.88361 17.8998 4.51686 18.9719C6.15011 20.044 8.0614 20.6147 10.0151 20.6138H15.8343C16.939 20.6126 17.9982 20.1733 18.7793 19.3921C19.5605 18.611 19.9998 17.5518 20.0009 16.4471V9.98625ZM18.3343 16.4471C18.3343 17.1101 18.0709 17.746 17.602 18.2149C17.1332 18.6837 16.4973 18.9471 15.8343 18.9471H10.0151C8.83922 18.9466 7.67664 18.6982 6.60319 18.2182C5.52973 17.7382 4.5695 17.0373 3.78511 16.1613C2.99692 15.2856 2.40505 14.2516 2.04919 13.1286C1.69332 12.0055 1.58169 10.8193 1.72177 9.64958C1.94297 7.80454 2.77193 6.08557 4.07797 4.76369C5.38402 3.4418 7.09286 2.59218 8.93511 2.34875C9.29447 2.30371 9.65627 2.28089 10.0184 2.28042C11.9605 2.27512 13.8424 2.95367 15.3343 4.19708C16.2055 4.9212 16.9207 5.81471 17.4364 6.82344C17.9521 7.83217 18.2575 8.93512 18.3343 10.0654V16.4471Z"
          fill="#252733"
        />
        <path
          d="M6.66536 8.11198H9.9987C10.2197 8.11198 10.4317 8.02418 10.588 7.8679C10.7442 7.71162 10.832 7.49966 10.832 7.27865C10.832 7.05763 10.7442 6.84567 10.588 6.68939C10.4317 6.53311 10.2197 6.44531 9.9987 6.44531H6.66536C6.44435 6.44531 6.23239 6.53311 6.07611 6.68939C5.91983 6.84567 5.83203 7.05763 5.83203 7.27865C5.83203 7.49966 5.91983 7.71162 6.07611 7.8679C6.23239 8.02418 6.44435 8.11198 6.66536 8.11198Z"
          fill="#252733"
        />
        <path
          d="M13.332 9.78125H6.66536C6.44435 9.78125 6.23239 9.86905 6.07611 10.0253C5.91983 10.1816 5.83203 10.3936 5.83203 10.6146C5.83203 10.8356 5.91983 11.0476 6.07611 11.2038C6.23239 11.3601 6.44435 11.4479 6.66536 11.4479H13.332C13.553 11.4479 13.765 11.3601 13.9213 11.2038C14.0776 11.0476 14.1654 10.8356 14.1654 10.6146C14.1654 10.3936 14.0776 10.1816 13.9213 10.0253C13.765 9.86905 13.553 9.78125 13.332 9.78125Z"
          fill="#252733"
        />
        <path
          d="M13.332 13.1133H6.66536C6.44435 13.1133 6.23239 13.2011 6.07611 13.3574C5.91983 13.5136 5.83203 13.7256 5.83203 13.9466C5.83203 14.1676 5.91983 14.3796 6.07611 14.5359C6.23239 14.6922 6.44435 14.7799 6.66536 14.7799H13.332C13.553 14.7799 13.765 14.6922 13.9213 14.5359C14.0776 14.3796 14.1654 14.1676 14.1654 13.9466C14.1654 13.7256 14.0776 13.5136 13.9213 13.3574C13.765 13.2011 13.553 13.1133 13.332 13.1133Z"
          fill="#252733"
        />
      </g>
      <defs>
        <clipPath id="clip0_3234_63759">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.613281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
