import type { FC, PropsWithChildren } from "react";

interface ISearchIconProp {
  width?: number;
  height?: number;
}
export const SearchIcon: FC<PropsWithChildren<ISearchIconProp>> = ({
  width = 32,
  height = 32,
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.7663 20.5889L16.7962 15.6188C18.1506 13.9623 18.8165 11.8487 18.6562 9.71497C18.4959 7.58128 17.5216 5.59083 15.9349 4.15534C14.3482 2.71984 12.2704 1.94913 10.1314 2.00261C7.99233 2.05609 5.95568 2.92968 4.44268 4.44268C2.92968 5.95568 2.05609 7.99233 2.00261 10.1314C1.94913 12.2704 2.71984 14.3482 4.15534 15.9349C5.59083 17.5216 7.58128 18.4959 9.71497 18.6562C11.8487 18.8165 13.9623 18.1506 15.6188 16.7962L20.5889 21.7663C20.7459 21.9179 20.9563 22.0019 21.1746 22C21.3929 21.9981 21.6017 21.9105 21.7561 21.7561C21.9105 21.6017 21.9981 21.3929 22 21.1746C22.0019 20.9563 21.9179 20.7459 21.7663 20.5889ZM10.3531 17.0143C9.03568 17.0143 7.74781 16.6237 6.65238 15.8917C5.55695 15.1598 4.70317 14.1194 4.199 12.9023C3.69483 11.6851 3.56292 10.3458 3.81994 9.05361C4.07697 7.76146 4.71138 6.57455 5.64297 5.64297C6.57455 4.71138 7.76146 4.07697 9.05361 3.81994C10.3458 3.56292 11.6851 3.69483 12.9023 4.199C14.1194 4.70317 15.1598 5.55695 15.8917 6.65238C16.6237 7.74781 17.0143 9.03568 17.0143 10.3531C17.0124 12.1192 16.3099 13.8123 15.0611 15.0611C13.8123 16.3099 12.1192 17.0124 10.3531 17.0143Z"
        fill="#3A75E9"
      />
    </svg>
  </>
);
