import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import type { FC } from "react";

import type { IUploadFile } from "../../../../interfaces/file-uploading/uploaded-files.interface";
import { DeleteIcon } from "../../../../media/icons/delete-icon";
import { RefreshIcon } from "../../../../media/icons/refresh";
import { WarningIcon } from "../../../../media/icons/warning";
import styles from "./file-list-item-error.module.scss";

interface IFileListItemErrorProp {
  uploadedFile: IUploadFile | Partial<IUploadFile>;
  onFileDelete: (fileId: string) => void;
  reason: "unstable_connection" | "type_unsupported" | "broken" | string;
  uploadFile: (file: IUploadFile) => Promise<void>;
}

export const FileListItemError: FC<IFileListItemErrorProp> = ({
  uploadedFile,
  onFileDelete,
  reason,
  uploadFile,
}) => {
  const renderErrorMessage = () => {
    switch (reason) {
      case "unstable_connection":
        return "Unstable network connection";
      case "type_unsupported":
        return "This file type is not supported";
      case "broken":
        return "File is broken";
      default:
        return reason;
    }
  };

  const renderRetryButton = (file: IUploadFile) => (
    <Box
      className={`${styles.preview} ${styles.previewRetry}`}
      component="button"
      onClick={() => {
        uploadFile(file);
      }}
      data-cy="retry"
    >
      <div>
        <RefreshIcon color="red" width={30} height={30} />
      </div>
      <Typography color="error" variant="body2">
        Retry
      </Typography>
    </Box>
  );

  const renderThumbnail = (file: IUploadFile | Partial<IUploadFile>) => {
    switch (reason) {
      case "unstable_connection":
        return renderRetryButton(file as IUploadFile);
      case "type_unsupported":
      case "broken":
        return (
          <div className={styles.preview}>
            <div>
              <WarningIcon color="red" width={30} height={30} />
            </div>
            <Typography color="error" variant="body2">
              Error
            </Typography>
          </div>
        );
      default:
        return renderRetryButton(file as IUploadFile);
    }
  };

  return (
    <div className={styles.fileCard} data-cy="file-card">
      {renderThumbnail(uploadedFile)}
      <div className={styles.info}>
        <Tooltip arrow title={uploadedFile.fileName} placement="top">
          <Typography
            variant="body2"
            fontSize="15px"
            overflow="hidden"
            className={styles.fileName}
          >
            <span
              style={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {uploadedFile.fileName}
            </span>
          </Typography>
        </Tooltip>

        <Typography
          variant="body2"
          color="error"
          sx={{
            wordBreak: "break-word",
          }}
          data-cy="error-message"
        >
          {renderErrorMessage()}
        </Typography>
      </div>
      <div className={styles.delete}>
        <IconButton
          color="primary"
          onClick={() => {
            onFileDelete(uploadedFile.id || "");
          }}
          size="small"
          disableRipple
          data-cy="delete"
        >
          <DeleteIcon width={20} height={20} />
        </IconButton>
      </div>
    </div>
  );
};
