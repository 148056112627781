import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IInterestedUser } from "../../../services/manage-subscribers/interfaces/interested-users.interface";

interface IInterestedUsersTable {
  interestedUsers: IInterestedUser[];
}

const initialState: IInterestedUsersTable = {
  interestedUsers: [],
};

export const interestedUsersSlice = createSlice({
  name: "interestedUsers",
  initialState,
  reducers: {
    setInterestedUsers(state, action: PayloadAction<IInterestedUser[]>) {
      state.interestedUsers = action.payload;
    },
  },
});

export default interestedUsersSlice.reducer;
