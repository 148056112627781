import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { VerificationInterface } from "./interfaces/verification.interface";

export const checkVerificationApi = createApi({
  reducerPath: "checkVerification",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    checkVerification: builder.mutation({
      query: (data: VerificationInterface) => ({
        url: `${ENDPOINTS.CHECK_VERIFICATION}/${data.id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useCheckVerificationMutation } = checkVerificationApi;
