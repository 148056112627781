import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { FastAverageColor } from "fast-average-color";
import { useFormik } from "formik";
import { isEqual } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";

import {
  maxLengthOfContentDescription,
  maxLengthOfContentTitle,
} from "../../../../../../constants/constants";
import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import useDateGroup from "../../../../../../hooks/date-group";
import { useAppSelector } from "../../../../../../hooks/redux";
import { CrossIcon } from "../../../../../../media/icons/cross";
import { EditIcon } from "../../../../../../media/icons/edit";
import { TrashIcon } from "../../../../../../media/icons/trash";
import { ContentMedia } from "../../../../../../services/catalog/interfaces/catalog.interfaces";
import {
  AudienceEnum,
  AudienceValuesEnum,
  ICreateCollectionParams,
  IEditSingleCollectionFormValues,
  IEditSingleContentFormValues,
  IUpdateCollectionParams,
  PriceEnum,
  VisibilityEnum,
} from "../../../../../../services/catalog/interfaces/request-models.interfaces";
import { CurrenciesEnum } from "../../../../../automatic-transfers/enums/currencies.enum";
import { ModalWrapper } from "../../../../../creator-card/modal-wrapper/modal-wrapper";
import { quillModules } from "../../../../../settings-cc/personal-info/quill-modules";
import DateFilters from "../../../../../shared/date-filters/date-filters";
import { CurrencySelect } from "../../../../../shared/select/currency-select/currency-select";
import { TagsInput } from "../../../../../shared/tags-input/tags-input";
import { Video } from "../../../../../shared/video/video";
import { useCreatorCatalogCCViewContext } from "../../context/creator-catalog-cc-view-context";
import { useFolderCreatorCatalogCCViewContext } from "../../context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import { AddGroups } from "../add-groups/add-groups";
import { AddSubscribers } from "../add-subscribers/add-subscribers";
import { CloseEditingConfirmation } from "../close-editing-confirmation/close-editing-confirmation";
import { DeleteContentConfirmation } from "../delete-content-confirmation/delete-content-confirmation";
import styles from "./edit-collection.module.scss";

interface IEditCollectionProp {
  isOpen: boolean;
}

export const EditCollection: FC<IEditCollectionProp> = ({ isOpen }) => {
  const {
    updateCollection,
    createCollection,
    isUpdateLoading,
    isUpdateSuccess,
    setCollectionEdit,
    collectionEdit,
    setPostsToDelete,
    setCollectionMediaDisplay,
    collectionMediaDisplay,
    setCollectionMediaChanged,
    collectionMediaChanged,
    isCollectionCreating,
    setIsCollectionCreating,
    setSelectedCreatorContentItems,
  } = useCreatorCatalogCCViewContext();
  const { currentOpenedFolder } = useFolderCreatorCatalogCCViewContext();
  const quillRef = useRef<ReactQuill | null>(null);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState<boolean>(false);
  const [isOpenAddSubscribersDialog, setIsOpenAddSubscribersDialog] =
    useState<boolean>(false);
  const [isOpenAddGroupsDialog, setIsOpenAddGroupsDialog] =
    useState<boolean>(false);
  const [isDeleteContentDialogOpened, setIsDeleteContentDialogOpened] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<Omit<
    IEditSingleContentFormValues,
    "descriptionText"
  > | null>(null);

  const [backgroundColors, setBackgroundColors] = useState<(string | null)[]>(
    []
  );

  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { user } = useAppSelector((data) => data.userReducer);

  const {
    date: dateVisibility,
    setDate: setDateVisibility,
    handleChangeDate: handleChangeDateVisibility,
    setCalendarDate: setCalendarDateVisibility,
    calendarDate: calendarDateVisibility,
  } = useDateGroup("day");

  const formik = useFormik<IEditSingleCollectionFormValues>({
    initialValues: {
      title: "",
      description: "",
      descriptionText: "",
      tags: [],
      audience: {
        audience: AudienceValuesEnum.me,
        ids: null,
      },
      price: {
        price: PriceEnum.free,
        amount: null,
        currency: null,
      },
      visibility: {
        visibility: VisibilityEnum.visible,
        date: null,
      },
      folder_id: currentOpenedFolder?.id || null,
    },
    validationSchema: Yup.object({
      title: Yup.string().nullable().max(maxLengthOfContentTitle),
      descriptionText: Yup.string()
        .nullable()
        .max(maxLengthOfContentDescription),
      price: Yup.object({
        amount: Yup.number().nullable(),
      }),
    }),
    onSubmit: async (values) => {
      const currentDate = values.visibility.date
        ? dayjs(values.visibility.date).toISOString()
        : null;

      if (isCollectionCreating) {
        const newCollection: ICreateCollectionParams = {
          accessToken: accessToken || "",
          media_ids: collectionMediaDisplay.map((item) => item.media.id) || [],
          formData: {
            title: values.title,
            description: values.description,
            descriptionText: values.descriptionText,
            tags: values.tags,
            audience: {
              audience: values.audience.audience,
              ids: values.audience.ids,
            },
            price: {
              price: values.price.price,
              amount: Number(values.price.amount),
              currency: values.price.currency,
            },
            visibility: {
              visibility: values.visibility.visibility,
              date: currentDate,
            },
            folder_id: values.folder_id,
          },
        };

        createCollection(newCollection);
      } else {
        const updatedCollection: IUpdateCollectionParams = {
          accessToken: accessToken || "",
          collectionId: collectionEdit?.id || "",
          media_id_changed:
            collectionMediaChanged.map((item) => item.media.id) || [],
          formData: {
            title: values.title,
            description: values.description,
            descriptionText: values.descriptionText,
            tags: values.tags,
            audience: {
              audience: values.audience.audience,
              ids: values.audience.ids,
            },
            price: {
              price: values.price.price,
              amount: Number(values.price.amount),
              currency: values.price.currency,
            },
            visibility: {
              visibility: values.visibility.visibility,
              date: currentDate,
            },
            folder_id: values.folder_id,
          },
        };

        updateCollection(updatedCollection);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("folder_id", currentOpenedFolder?.id || null);
  }, [currentOpenedFolder]);

  const handleCloseEditModal = () => {
    if (!isUpdateLoading && initialFormValues) {
      const { descriptionText, ...restFormikValues } = formik.values;
      console.warn(descriptionText);
      const isFormChanged = !isEqual(restFormikValues, initialFormValues);

      if (isFormChanged) {
        setIsOpenConfirmationDialog(true);
      } else {
        setCollectionEdit(null);
        setIsOpenConfirmationDialog(false);
      }
    }
  };

  useEffect(() => {
    if (
      formik.values.visibility.visibility === VisibilityEnum.planned &&
      collectionEdit?.scheduled_at &&
      !dayjs(formik.values.visibility.date).isSame(
        collectionEdit?.scheduled_at,
        "day"
      )
    ) {
      formik.setFieldValue("visibility.date", dateVisibility.startDate);
    }
  }, [dateVisibility]);

  const onFormikValueUpdate = useCallback(
    (formikKey: string, formikValue: string | string[] | null | number) => {
      formik.setFieldValue(formikKey, formikValue);
    },
    [formik]
  );

  useEffect(() => {
    setCollectionMediaDisplay(collectionEdit?.content_media || []);

    const formInitValues: Omit<
      IEditSingleContentFormValues,
      "descriptionText"
    > = {
      title: collectionEdit?.name || "",
      description: collectionEdit?.description || "",
      tags: collectionEdit?.content_tags || [],
      audience: {
        audience: collectionEdit?.audience || AudienceValuesEnum.me,
        ids: [],
      },
      price: {
        price: collectionEdit?.price ? PriceEnum.paid : PriceEnum.free,
        amount: collectionEdit?.price || 0,
        currency: collectionEdit?.currency || CurrenciesEnum.USD,
      },
      visibility: {
        visibility: collectionEdit?.visible
          ? VisibilityEnum.visible
          : VisibilityEnum.planned,
        date: collectionEdit?.visible
          ? null
          : collectionEdit?.scheduled_at || "",
      },
      folder_id: currentOpenedFolder?.id || null,
    };

    setInitialFormValues(formInitValues);

    formik.setValues({
      ...formik.values,
      ...formInitValues,
    });

    const scheduledAt = collectionEdit?.scheduled_at;
    const nextDay = dayjs().add(1, "day").toISOString();

    const isScheduledAtBeforeOrSameToday =
      scheduledAt &&
      (dayjs(scheduledAt).isBefore(dayjs(), "day") ||
        dayjs(scheduledAt).isSame(dayjs(), "day"));

    const adjustedStartDate = isScheduledAtBeforeOrSameToday
      ? nextDay
      : scheduledAt || nextDay;

    const adjustedEndDate = adjustedStartDate;

    if (
      !collectionEdit?.scheduled_at &&
      formik.values.visibility.visibility === VisibilityEnum.planned
    ) {
      setDateVisibility({
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        type: "day",
      });
    }
  }, [collectionEdit]);

  const setEditorContent = () => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      formik.setFieldValue("descriptionText", editor.getText().trim());
    }
  };

  useEffect(() => {
    if (formik.values.description) {
      setEditorContent();
    }
  }, [formik.values.description]);

  const cancelSelectSubscribersClick = useCallback(() => {
    setSelectedUsers(
      collectionEdit?.content_subscriber_audience.map(
        (item) => item.subscription.subscriber_id
      ) || []
    );
  }, [collectionEdit]);

  const cancelSelectGroupsClick = useCallback(() => {
    setSelectedGroups(
      collectionEdit?.content_group_audience.map((item) => item.group.id) || []
    );
  }, [collectionEdit]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setCollectionEdit(null);
      setIsOpenConfirmationDialog(false);
    }
  }, [isUpdateSuccess]);

  const handleDeleteMedia = (contentMedia: ContentMedia) => {
    setCollectionMediaDisplay((prevState) =>
      prevState.filter((item) => item.media.id !== contentMedia.media.id)
    );
    setCollectionMediaChanged((prevState) => {
      const isItemExist = prevState.find(
        (item) => item.media.id === contentMedia.media.id
      );

      if (!isItemExist) {
        return [...prevState, contentMedia];
      }

      return prevState.filter(
        (item) => item.media.id !== contentMedia.media.id
      );
    });
  };

  const handleDeleteClick = (reason?: "deleted" | "deleteCreating") => {
    if (isCollectionCreating && reason !== "deleteCreating") {
      setIsDeleteContentDialogOpened(false);
      return;
    }

    if (isCollectionCreating && reason === "deleteCreating") {
      setIsDeleteContentDialogOpened(false);
      setIsCollectionCreating(false);
      setCollectionEdit(null);
      setSelectedCreatorContentItems([]);
      return;
    }

    if (collectionEdit) {
      setPostsToDelete([]);
      setIsDeleteContentDialogOpened(false);
      if (reason === "deleted") {
        setCollectionEdit(null);
      }
    }
  };

  const getDominantColor = async (
    fac: FastAverageColor,
    imageUrl: string
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;

      img.onload = () => {
        try {
          const color = fac.getColor(img).hex;
          resolve(color);
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });

  useEffect(() => {
    if (!collectionEdit?.content_media) return;

    const fetchColors = async () => {
      if (!collectionEdit?.content_media) return;

      const fac = new FastAverageColor();
      const colors = await Promise.all(
        collectionEdit.content_media.map(async (item) => {
          try {
            if (!item.media.backend_media_url) {
              return null;
            }
            const dominantColor = await getDominantColor(
              fac,
              item.media.backend_media_url
            );
            return dominantColor;
          } catch (error) {
            // console.error(`Error fetching color for ${item.backend_media_url}:`, error);
            return "#f0f0f0"; // Fallback color
          }
        })
      );
      setBackgroundColors(colors);
    };

    fetchColors();
  }, [collectionEdit]);

  return (
    <>
      <ModalWrapper
        shown={isOpen}
        close={handleCloseEditModal}
        disableBackdropClose
      >
        <Box className={styles.edit}>
          <Box className={styles.editWrapper}>
            <Box className={styles.header}>
              <Typography fontSize="24px">
                {isCollectionCreating ? "Create collection" : "Edit collection"}
              </Typography>
              <IconButton
                onClick={() => {
                  handleCloseEditModal();
                }}
              >
                <CrossIcon />
              </IconButton>
            </Box>

            {collectionMediaDisplay.length === 1 ? (
              <>
                <Box
                  className={styles.content}
                  sx={{
                    backgroundColor:
                      backgroundColors[0] !== null
                        ? backgroundColors[0]
                        : "opacity: 0.9",
                  }}
                >
                  {collectionMediaDisplay[0].media?.type?.includes("video") ? (
                    <Box className={styles.cardVideo}>
                      <Video
                        muted={!!user?.media_sound_muted}
                        isPlaying={!!user?.media_play_automatically}
                        showControls
                        videoUrl={
                          collectionMediaDisplay[0].media.backend_media_url ||
                          "undefined"
                        }
                      />
                    </Box>
                  ) : (
                    <img
                      src={
                        collectionMediaDisplay[0].media.backend_media_url ||
                        undefined
                      }
                      alt="content"
                      className={styles.cardImage}
                    />
                  )}
                </Box>
              </>
            ) : (
              <Box className={styles.listOfMedia}>
                {collectionMediaDisplay.map((media) => (
                  <React.Fragment key={media.media.id}>
                    {media.media.type?.includes("video") ? (
                      <Box className={styles.cardVideoMultiple}>
                        <Video
                          muted
                          isPlaying={false}
                          showControls={false}
                          videoUrl={
                            media.media.backend_media_url || "undefined"
                          }
                        />
                        <IconButton
                          sx={{
                            background: "#4D4F4F80",
                            position: "absolute",
                            right: 5,
                            bottom: 5,
                          }}
                          onClick={() => {
                            handleDeleteMedia(media);
                          }}
                        >
                          <TrashIcon width={17} height={17} />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <img
                          src={media.media.backend_media_url || undefined}
                          alt="content"
                          className={styles.cardImageMultiple}
                        />
                        <IconButton
                          sx={{
                            background: "#4D4F4F80",
                            position: "absolute",
                            right: 5,
                            bottom: 15,
                          }}
                          onClick={() => {
                            handleDeleteMedia(media);
                          }}
                        >
                          <TrashIcon width={17} height={17} />
                        </IconButton>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            )}

            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-start"
                  sx={{
                    paddingTop: "20px",
                    paddingLeft: "21px",
                    paddingRight: "0",

                    ".MuiGrid-item": {
                      paddingLeft: "0",
                      paddingRight: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                    },
                  }}
                >
                  {/* Title and Tags */}
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        paddingRight: "10px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="title"
                        placeholder="Title"
                        error={!!formik.errors.title}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                      <Typography
                        variant="caption"
                        align="right"
                        color={formik.errors.title ? "#FF5252" : "#989898"}
                        marginTop="3px"
                      >
                        {formik.values.title ? formik.values.title.length : 0}/
                        {maxLengthOfContentTitle}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        paddingLeft: "12px",
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Tags
                      </Typography>
                      <Typography variant="body1" fontSize="14px">
                        Add up to 10 tags to your photos for a customized touch!
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Description */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      paddingLeft: "21px",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "17px",
                        paddingRight: "10px",
                      }}
                    >
                      <ReactQuill
                        ref={quillRef}
                        className={`quill ${formik.errors.descriptionText ? "quillError" : ""}`}
                        theme="snow"
                        value={formik.values.description || ""}
                        onChange={(content, delta, source, editor) => {
                          formik.setFieldValue("description", editor.getHTML());
                          formik.setFieldValue(
                            "descriptionText",
                            editor.getText().trim()
                          );
                        }}
                        modules={quillModules}
                        placeholder="Write description here..."
                      />
                      <Typography
                        variant="caption"
                        align="right"
                        color={
                          formik.errors.descriptionText ? "#FF5252" : "#989898"
                        }
                        marginTop="3px"
                      >
                        {formik.values.descriptionText
                          ? formik.values.descriptionText.length
                          : 0}
                        /{maxLengthOfContentDescription}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        paddingLeft: "12px",
                        marginTop: "17px",
                      }}
                    >
                      <TagsInput
                        formikKey="tags"
                        tags={formik.values.tags}
                        onFormikValueUpdate={onFormikValueUpdate}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        marginTop: "34px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        margin="normal"
                      >
                        Access Settings
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Access Settings */}
                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        gap: "15px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        {/* Start audience */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "7px",
                          }}
                        >
                          <Box className={styles.dropdownWrapper}>
                            <Typography variant="body1" width="25%">
                              Audience:
                            </Typography>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                            >
                              <Select
                                value={formik.values.audience.audience}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "audience.audience",
                                    e.target.value
                                  );
                                  formik.setFieldValue("audience.ids", null);
                                }}
                              >
                                <MenuItem value={AudienceValuesEnum.me}>
                                  {AudienceEnum.me}
                                </MenuItem>
                                <MenuItem value={AudienceValuesEnum.everyone}>
                                  {AudienceEnum.everyone}
                                </MenuItem>
                                <MenuItem
                                  value={AudienceValuesEnum.subscribers}
                                >
                                  {AudienceEnum.subscribers}
                                </MenuItem>
                                <MenuItem
                                  value={AudienceValuesEnum.specific_subscriber}
                                >
                                  {AudienceEnum.specific_subscriber}
                                </MenuItem>
                                <MenuItem value={AudienceValuesEnum.group}>
                                  {AudienceEnum.group}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {(formik.values.audience.audience ===
                            AudienceValuesEnum.specific_subscriber ||
                            formik.values.audience.audience ===
                              AudienceValuesEnum.group) && (
                            <Button
                              variant="outlined"
                              type="button"
                              sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                              onClick={() => {
                                if (
                                  formik.values.audience.audience ===
                                  AudienceValuesEnum.specific_subscriber
                                ) {
                                  setIsOpenAddSubscribersDialog(true);
                                }

                                if (
                                  formik.values.audience.audience ===
                                  AudienceValuesEnum.group
                                ) {
                                  setIsOpenAddGroupsDialog(true);
                                }
                              }}
                            >
                              <EditIcon />
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                marginTop="3px"
                              >
                                Edit
                              </Typography>
                            </Button>
                          )}
                        </Box>
                        {/* End audience */}
                        {/* Start price */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Box className={styles.dropdownWrapper}>
                            <Typography variant="body1" width="25%">
                              Price:
                            </Typography>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="normal"
                            >
                              <Select
                                value={formik.values.price.price}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "price.price",
                                    e.target.value
                                  );

                                  if (e.target.value === PriceEnum.free) {
                                    formik.setFieldValue("price.amount", null);
                                    formik.setFieldValue(
                                      "price.currency",
                                      null
                                    );
                                  }
                                }}
                              >
                                <MenuItem value={PriceEnum.free}>Free</MenuItem>
                                <MenuItem value={PriceEnum.paid}>
                                  Amount
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {formik.values.price.price === PriceEnum.paid && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                }}
                              >
                                <Typography variant="body1" width="25%" />
                                <FormControl fullWidth variant="outlined">
                                  <CurrencySelect
                                    value={
                                      formik.values.price.currency ||
                                      CurrenciesEnum.BTC
                                    }
                                    onValueUpdate={(selectedCurrency) => {
                                      formik.setFieldValue(
                                        "price.currency",
                                        selectedCurrency
                                      );
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                }}
                              >
                                <Typography variant="body1" width="25%" />
                                <FormControl fullWidth variant="outlined">
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="price.amount"
                                    placeholder="Enter amount"
                                    error={!!formik.errors.price?.amount}
                                    value={formik.values.price.amount}
                                    onChange={formik.handleChange}
                                  />
                                </FormControl>
                              </Box>
                            </>
                          )}
                        </Box>
                        {/* End price */}
                      </Box>

                      {/* Start visibility */}
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Box className={styles.dropdownWrapper}>
                          <Typography variant="body1" width="25%">
                            Visibility:
                          </Typography>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          >
                            <Select
                              value={formik.values.visibility.visibility}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "visibility.visibility",
                                  e.target.value
                                );

                                if (e.target.value === VisibilityEnum.visible) {
                                  formik.setFieldValue("visibility.date", null);
                                }
                              }}
                            >
                              <MenuItem value={VisibilityEnum.visible}>
                                Visible Now
                              </MenuItem>
                              <MenuItem value={VisibilityEnum.planned}>
                                Make visible on
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        {formik.values.visibility.visibility ===
                          VisibilityEnum.planned && (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <DateFilters
                                calendarDate={calendarDateVisibility}
                                date={dateVisibility}
                                handleChangeDate={handleChangeDateVisibility}
                                setCalendarDate={setCalendarDateVisibility}
                                setDate={setDateVisibility}
                                disableArrows
                                disableSelectingType
                                disablePast
                                disableCurrentDay
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {/* End visibility */}
                    </Box>
                  </Grid>

                  {/* Buttons */}
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={12}>
                    <Box className={styles.btnInteraction}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isUpdateLoading}
                        sx={{
                          width: "237px",
                        }}
                      >
                        {isUpdateLoading ? (
                          <CircularProgress color="inherit" size="1.5rem" />
                        ) : (
                          <Typography variant="button" fontWeight="bold">
                            Save
                          </Typography>
                        )}
                      </Button>

                      {collectionEdit && (
                        <Button
                          variant="outlined"
                          color="error"
                          disabled={
                            isUpdateLoading ||
                            collectionEdit._count.contentPurchaseAmount > 0
                          }
                          onClick={() => {
                            if (collectionEdit) {
                              setPostsToDelete([collectionEdit.id]);
                            }
                            setIsDeleteContentDialogOpened(true);
                          }}
                        >
                          <Typography variant="button" fontWeight="bold">
                            Delete
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
      <CloseEditingConfirmation
        isOpen={isOpenConfirmationDialog}
        onSaveClick={() => formik.submitForm()}
        onDiscardClick={() => {
          setIsOpenConfirmationDialog(false);
          setIsCollectionCreating(false);
          setCollectionEdit(null);
          setSelectedCreatorContentItems([]);
        }}
        onBackClick={() => {
          setIsOpenConfirmationDialog(false);
        }}
      />
      <AddSubscribers
        isDialogOpened={isOpenAddSubscribersDialog}
        setIsOpenAddSubscribersDialog={setIsOpenAddSubscribersDialog}
        selectedUsersIds={selectedUsers || []}
        onSaveClick={(switches: string[]) => {
          formik.setFieldValue("audience.ids", switches);
        }}
        setSelectedUsers={setSelectedUsers}
        onCancelClick={cancelSelectSubscribersClick}
      />
      <AddGroups
        isDialogOpened={isOpenAddGroupsDialog}
        setIsOpenAddGroupsDialog={setIsOpenAddGroupsDialog}
        selectedGroupsIds={selectedGroups || []}
        onSaveClick={(switches: string[]) => {
          formik.setFieldValue("audience.ids", switches);
        }}
        setSelectedGroups={setSelectedGroups}
        onCancelClick={cancelSelectGroupsClick}
      />
      <DeleteContentConfirmation
        isOpen={isDeleteContentDialogOpened}
        onCancelClick={handleDeleteClick}
      />
    </>
  );
};
