export const CreditCardIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0px 0px ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4150_68070)">
      <path
        d="M9.08398 20.6665C9.08398 21.633 8.30048 22.4165 7.33398 22.4165C6.36749 22.4165 5.58398 21.633 5.58398 20.6665C5.58398 19.7 6.36749 18.9165 7.33398 18.9165C8.30048 18.9165 9.08398 19.7 9.08398 20.6665Z"
        fill="black"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M29.3333 10.9167H29.5833V10.6667C29.5833 9.5395 29.1356 8.45849 28.3385 7.66146C27.5415 6.86443 26.4605 6.41667 25.3333 6.41667H6.66667C5.5395 6.41667 4.45849 6.86443 3.66146 7.66146C2.86443 8.45849 2.41667 9.5395 2.41667 10.6667V10.9167H2.66667H29.3333ZM2.66667 13.0833H2.41667V13.3333V21.3333C2.41667 22.4605 2.86443 23.5415 3.66146 24.3385C4.45849 25.1356 5.5395 25.5833 6.66667 25.5833H25.3333C26.4605 25.5833 27.5415 25.1356 28.3385 24.3385C29.1356 23.5415 29.5833 22.4605 29.5833 21.3333V13.3333V13.0833H29.3333H2.66667ZM2.13174 6.13174C3.33468 4.9288 4.96561 4.25208 6.66682 4.25H25.3332C27.0344 4.25208 28.6653 4.9288 29.8683 6.13174C31.0712 7.33471 31.748 8.96571 31.75 10.667V21.333C31.748 23.0343 31.0712 24.6653 29.8683 25.8683C28.6653 27.0712 27.0343 27.748 25.333 27.75H6.66697C4.96571 27.748 3.33471 27.0712 2.13174 25.8683C0.928798 24.6653 0.252077 23.0344 0.25 21.3332V10.6668C0.252077 8.96561 0.928798 7.33468 2.13174 6.13174Z"
        fill="black"
        stroke="white"
        strokeWidth="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_4150_68070">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
