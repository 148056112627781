import type { ITableHead } from "../../../interfaces/table-head.interface";

export const subscriberGroupsTableHead: ITableHead[] = [
  {
    display_text: "Subscriber groups",
    value: "name",
    sortable: true,
    default_sort: false,
    headWidth: 25,
  },
  {
    display_text: "Group Membership Count",
    value: "group_members",
    sortable: true,
    default_sort: false,
    headWidth: 25,
  },
  {
    display_text: "Group Creation Date",
    value: "created_at",
    sortable: true,
    default_sort: true,
    headWidth: 25,
  },
  {
    display_text: " ",
    value: "",
    sortable: false,
    default_sort: false,
    headWidth: 25,
  },
];
