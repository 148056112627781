import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import type React from "react";
import { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch } from "../../../hooks/redux";
import useSubmitState from "../../../hooks/submit-state";
import { CalendarLogo } from "../../../media/icons/calendar";
import { PlusIcon } from "../../../media/icons/plus";
import {
  useAddAutomaticTransferMutation,
  useRemoveCurrencyTransferMutation,
} from "../../../services/automatic-transfers/automatic-transfers";
import type {
  AutomaticTransfer,
  CurrencyTransfer,
} from "../../../services/sign-in/interfaces/initialize.interface";
import { automaticTransfersSlice } from "../../../store/reducers/automatic-transfers/automatic-transfers";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import DefaultSelect from "../../shared/select/select";
import { btcValues, euroValues, usdValues } from "../data";
import { CurrenciesEnum } from "../enums/currencies.enum";
import type { AutomaticTransfersInterface } from "../interfaces/automatic-transfer.interface";
import AutomaticCurrencyTransfer from "./automatic-currency-transfer/automatic-currency-transfer";
import styles from "./automatic-transfer-form.module.scss";
import { FrequencyTooltip } from "./frequency-tooltip/frequency-tooltip";

interface AutomaticTransferFormProps {
  totalAutomaticTransfers: number;
  automaticTransfer: AutomaticTransfer;
  isDeleteLoading: boolean;
  currencyData: {
    display_text: string;
    value: CurrenciesEnum;
    start_component: React.JSX.Element;
    disabled?: boolean;
  }[];
  setOpenDeleteDialog: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  setAutomaticTransferId: (
    value: string | ((prevVar: string) => string)
  ) => void;
}

export default function AutomaticTransferForm(
  props: AutomaticTransferFormProps
) {
  const {
    totalAutomaticTransfers,
    automaticTransfer,
    isDeleteLoading,
    currencyData,
    setOpenDeleteDialog,
    setAutomaticTransferId,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [currencyTransferId, setCurrencyTransferId] = useState("");
  const [selectedAutomaticTransfer, setSelectedAutomaticTransfer] =
    useState("");
  const [numberOfInvalidTransfer, setNumberOfInvalidTransfer] = useState<
    string[]
  >([]);

  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const [
    saveCurrencyTransfer,
    {
      isLoading: isSaveLoading,
      error: saveCurrencyTransferError,
      isSuccess: saveCurrencyTransferSuccess,
      reset: resetAddCurrency,
    },
  ] = useAddAutomaticTransferMutation();

  const [
    deleteCurrencyTransfer,
    { isLoading: isDeleteCurrencyTransferLoading },
  ] = useRemoveCurrencyTransferMutation();

  const {
    addCurrencyTransfer,
    removeCurrencyTransfer,
    changeAutomaticTransferValue,
  } = automaticTransfersSlice.actions;

  const dispatch = useAppDispatch();
  const { text, color, errorCode } = useSubmitState({
    requestError: saveCurrencyTransferError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: saveCurrencyTransferSuccess,
  });

  const formik = useFormik({
    initialValues: {
      currency: automaticTransfer.currency,
      frequency: automaticTransfer.frequency,
      amount: btcValues[0].value,
      first_day_number: "01",
      second_day_number: "31",
      submit: null,
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(),
      frequency: Yup.string().required(),
      first_day_number: Yup.number().required(),
    }),
    onSubmit: async (
      values: AutomaticTransfersInterface,
      helpers: FormikHelpers<AutomaticTransfersInterface>
    ) => {
      try {
        await saveCurrencyTransfer({
          automatic_transfer: {
            currency: automaticTransfer.currency,
            currency_transfers: automaticTransfer.currency_transfers.map(
              (item) => ({
                amount: item.amount,
                automatic_transfer_id: automaticTransfer.id,
                custom_amount: item.custom_amount,
                if_amount_is_not_available: item.if_amount_is_not_available,
                payment_type: item.payment_type,
                transfer_account: item.transfer_account,
                id: item.id,
              })
            ),
            first_day_number: automaticTransfer.first_day_number,
            frequency: automaticTransfer.frequency,
            second_day_number: automaticTransfer.second_day_number,
            id: automaticTransfer.id,
          },
          accessToken,
        });
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.data.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleValidTransfer = (isValid: boolean, transferId: string) => {
    const isTransferInState = numberOfInvalidTransfer.find(
      (currTransfer) => currTransfer === transferId
    );

    if (!isValid && !isTransferInState) {
      setNumberOfInvalidTransfer((prev) => [...prev, transferId]);
    }

    if (isValid && isTransferInState) {
      const filteredTransfers = numberOfInvalidTransfer.filter(
        (curr) => curr !== transferId
      );
      setNumberOfInvalidTransfer(filteredTransfers);
    }
  };

  const handleSetFirstDate = (value: any) => {
    formik.values.first_day_number = value;
    dispatch(
      changeAutomaticTransferValue({
        automatic_transfer_id: automaticTransfer.id,
        key: "first_day_number",
        value,
      })
    );
    resetAddCurrency();
  };

  const handleSetSecondDate = (value: any) => {
    formik.values.second_day_number = value;
    dispatch(
      changeAutomaticTransferValue({
        automatic_transfer_id: automaticTransfer.id,
        key: "second_day_number",
        value,
      })
    );
    resetAddCurrency();
  };
  const handleAddAnotherCurrencyTransfer = () => {
    resetAddCurrency();
    const transferTemplate: CurrencyTransfer = {
      amount: "",
      custom_amount: "",
      id: uuidv4(),
      if_amount_is_not_available: "transferAll",
      payment_type: "fixed",
      transfer_account: "",
    };
    handleValidTransfer(false, transferTemplate.id);

    dispatch(
      addCurrencyTransfer({
        automatic_transfer_id: automaticTransfer.id,
        currency_transfer: transferTemplate,
      })
    );
  };

  const handleRemoveCurrencyTransfer = async (id: string) => {
    const data = await deleteCurrencyTransfer({
      accessToken,
      id,
    });

    if (data) {
      dispatch(
        removeCurrencyTransfer({
          automatic_transfer_id: automaticTransfer.id,
          currency_transfer_id: id,
        })
      );

      setOpenDialog(false);
    }
  };

  const handleDelete = () => {
    if (currencyTransferId) {
      handleValidTransfer(true, currencyTransferId);
      handleRemoveCurrencyTransfer(currencyTransferId);
    }
  };

  const formatDay = (value: string) => {
    if (value === "" || Number.isNaN(Number(value))) {
      return "01";
    }
    const numValue = Number(value);
    if (numValue < 1) {
      value = "1";
    } else if (numValue > 31) {
      value = "31";
    }
    return value.toString().padStart(2, "0");
  };

  return (
    <>
      <Card className={styles.card}>
        <Box>
          <Box display="flex" flexDirection="column" gap="16px">
            {totalAutomaticTransfers > 1 && (
              <Box width="100%" display="flex" justifyContent="flex-end">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  onClick={() => {
                    setOpenDeleteDialog(true);
                    setAutomaticTransferId(automaticTransfer.id);
                  }}
                  color="error"
                >
                  <Typography variant="body2" letterSpacing="0">
                    Delete transfer
                  </Typography>
                </Link>
              </Box>
            )}

            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Currency: </Typography>
              <Box width={300}>
                <DefaultSelect
                  name="currency"
                  options={currencyData}
                  onChange={(value) => {
                    const selectedCurrency = value.value;
                    formik.values.currency = selectedCurrency;

                    switch (selectedCurrency) {
                      case CurrenciesEnum.BTC:
                        formik.values.amount = btcValues[0].value;
                        break;
                      case CurrenciesEnum.EURO:
                        formik.values.amount = euroValues[0].value;
                        break;
                      case CurrenciesEnum.USD:
                        formik.values.amount = usdValues[0].value;
                        break;
                      default:
                        break;
                    }

                    formik.setFieldValue("currency", value.value);
                    dispatch(
                      changeAutomaticTransferValue({
                        automatic_transfer_id: automaticTransfer.id,
                        key: "currency",
                        value: value.value,
                      })
                    );
                  }}
                  value={formik.values.currency}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap="17px"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Typography>Frequency: </Typography>
                <Box width={300}>
                  <DefaultSelect
                    disabled={isDeleteLoading}
                    name="frequency"
                    options={[
                      {
                        value: "oncePerMonth",
                        display_text: "Once per month",
                      },
                      {
                        value: "twicePerMonth",
                        display_text: "Twice per month",
                      },
                    ]}
                    value={formik.values.frequency}
                    onChange={(value) => {
                      formik.setFieldValue("frequency", value.value);

                      dispatch(
                        changeAutomaticTransferValue({
                          automatic_transfer_id: automaticTransfer.id,
                          key: "frequency",
                          value: value.value as CurrenciesEnum,
                        })
                      );

                      resetAddCurrency();
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                alignItems="flex-end"
              >
                <Box
                  width={210}
                  display="flex"
                  flexDirection="column"
                  gap="17px"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="17px"
                  >
                    <FrequencyTooltip />
                    <TextField
                      placeholder="Enter day"
                      type="text"
                      disabled={isDeleteLoading}
                      error={
                        !!(
                          formik.touched.first_day_number &&
                          formik.errors.first_day_number
                        )
                      }
                      name="first_day_number"
                      onBlur={(e) => {
                        const formatted = formatDay(e.target.value);
                        formik.setFieldValue("first_day_number", formatted);
                        handleSetFirstDate(formatted);
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.first_day_number}
                      InputProps={{
                        style: {
                          paddingLeft: "15px",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarLogo width={20} height={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {formik.values.frequency === "twicePerMonth" && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="17px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FrequencyTooltip />
                      </Box>
                      <TextField
                        placeholder="Enter day"
                        type="text"
                        disabled={isDeleteLoading}
                        error={
                          !!(
                            formik.touched.second_day_number &&
                            formik.errors.second_day_number
                          )
                        }
                        name="second_day_number"
                        onBlur={(e) => {
                          const formatted = formatDay(e.target.value);
                          formik.setFieldValue("second_day_number", formatted);
                          handleSetSecondDate(formatted);
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.second_day_number}
                        InputProps={{
                          style: {
                            paddingLeft: "15px",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarLogo width={20} height={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <TransitionGroup>
            {automaticTransfer.currency_transfers.map((item, index) => (
              <Collapse key={item.id}>
                <Box key={item.id} marginTop="23px">
                  <Box className={styles.line} marginBottom="16px" />
                  <AutomaticCurrencyTransfer
                    handleValidTransfer={handleValidTransfer}
                    currency={formik.values.currency}
                    currencyTransfer={item}
                    automaticTransfer={automaticTransfer}
                    isDeleteLoading={isDeleteLoading}
                    reset={resetAddCurrency}
                    index={index}
                    setOpenDialog={setOpenDialog}
                    setCurrencyTransferId={setCurrencyTransferId}
                    setSelectedAutomaticTransfer={setSelectedAutomaticTransfer}
                  />
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>
        </Box>
        <Box marginTop="24px" display="flex" gap="16px">
          <Button
            variant="contained"
            onClick={() => {
              if (color === "error") {
                resetAddCurrency();
                return;
              }

              if (saveCurrencyTransferSuccess) {
                return;
              }
              formik.handleSubmit();
            }}
            disabled={
              isDeleteLoading ||
              isSaveLoading ||
              automaticTransfer.currency_transfers.length === 0 ||
              numberOfInvalidTransfer.length > 0
            }
            color={color}
          >
            {text}
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#fff",
              width: "218px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            disabled={isDeleteLoading || isSaveLoading}
            onClick={handleAddAnotherCurrencyTransfer}
          >
            <Box
              display="flex"
              gap="5px"
              alignItems="center"
              justifyContent="center"
            >
              <PlusIcon width={11} height={11} />
              <span>Add Transfer</span>
            </Box>
          </Button>
        </Box>
        {errorCode && (
          <Typography variant="body1" color="error" mt={1}>
            Oops... Something went wrong. Error {errorCode}
          </Typography>
        )}
      </Card>
      <ModalDialog
        isOpen={
          openDialog && automaticTransfer.id === selectedAutomaticTransfer
        }
        classes={styles.modal}
      >
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Delete Currency Transfer
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Are you sure you want to delete Currency transfer?
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="error"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={handleDelete}
          >
            <Typography fontSize="16px">
              {isDeleteCurrencyTransferLoading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                "Delete"
              )}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => setOpenDialog(false)}
            disabled={isDeleteCurrencyTransferLoading}
          >
            <Typography fontSize="16px">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>
    </>
  );
}
