import { Box, Button } from "@mui/material";
import type { AxiosError } from "axios";
import type { Dispatch, FC, SetStateAction } from "react";
import { useCallback } from "react";

import { ENDPOINTS } from "../../../constants/api/api";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useFileUploader from "../../../hooks/file-upload";
import type {
  IUnsupportedResponse,
  IUpdateFile,
  IUploadFile,
} from "../../../interfaces/file-uploading/uploaded-files.interface";
import { FileListItemError } from "../../shared/file-list-items/file-list-item-error/file-list-item-error";
import { FileListItem } from "../../shared/file-list-items/file-list-item/file-list-item";
import styles from "./upload-content-list.module.scss";

interface IUploadContentListProp {
  filesInStore: IUploadFile[];
  setLoadedFiles: Dispatch<SetStateAction<IUploadFile[]>>;
}

export const UploadContentList: FC<IUploadContentListProp> = ({
  filesInStore,
  setLoadedFiles,
}) => {
  const brokenFiles: Partial<IUploadFile>[] = JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ERROR_FILES) || "[]"
  );

  const updateFileInfo = useCallback(
    (payload: IUpdateFile) => {
      setLoadedFiles((prevState) => {
        const updatedFiles = prevState.map((file) => {
          if (file.id === payload.fileId) {
            return { ...file, ...payload.info };
          }
          return file;
        });

        return updatedFiles;
      });
    },
    [filesInStore]
  );

  const {
    uploadFile: uploadFileHook,
    cancelUpload,
    renewCancelToken,
  } = useFileUploader(
    `${process.env.REACT_APP_BASE_URL}${ENDPOINTS.UPLOAD_CONTENT}`
  );

  const removeFileFromLocalStorage = (fileId: string) => {
    const filesWithErrors: Partial<IUploadFile>[] = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.ERROR_FILES) || "[]"
    );
    const removeFileOnRetry = filesWithErrors.filter(
      (file) => file.id !== fileId
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.ERROR_FILES,
      JSON.stringify(removeFileOnRetry)
    );
  };

  const setFileInLocalStorage = (file: IUploadFile) => {
    const fileToLocalStorage: Partial<IUploadFile> = {
      id: file.id,
      status: "error",
      reason: "broken",
      fileName: file.fileName,
    };

    const filesWithErrors: Partial<IUploadFile>[] = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.ERROR_FILES) || "[]"
    );
    filesWithErrors.push(fileToLocalStorage);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.ERROR_FILES,
      JSON.stringify(filesWithErrors)
    );
  };

  const onFileDelete = useCallback(
    (fileId: string) => {
      removeFileFromLocalStorage(fileId);
      setLoadedFiles((prevState) =>
        prevState.filter((file) => file.id !== fileId)
      );
    },
    [filesInStore]
  );

  const uploadFile = useCallback(async (file: IUploadFile) => {
    updateFileInfo({
      fileId: file.id,
      info: {
        status: "uploading",
        progressOfUploading: 0,
        reason: undefined,
      },
    });

    try {
      if (!file.file) return;
      await uploadFileHook(
        file.file,
        [{ key: "description", value: file.description }],
        (progress) => {
          updateFileInfo({
            fileId: file.id,
            info: { progressOfUploading: progress },
          });
        }
      );
      setTimeout(() => {
        onFileDelete(file.id);
      }, 800);
    } catch (error) {
      const e = error as AxiosError;

      if (e.code === "ERR_CANCELED") return;

      setFileInLocalStorage(file);

      if (e.code === "ERR_NETWORK") {
        updateFileInfo({
          fileId: file.id,
          info: {
            status: "error",
            progressOfUploading: 0,
            reason: "unstable_connection",
          },
        });
        return;
      }

      if (
        (e.response?.data as IUnsupportedResponse).message ===
        "File type is not supported"
      ) {
        updateFileInfo({
          fileId: file.id,
          info: {
            status: "error",
            progressOfUploading: 0,
            reason: "type_unsupported",
          },
        });
        return;
      }

      updateFileInfo({
        fileId: file.id,
        info: {
          status: "error",
          progressOfUploading: 0,
          reason: `Oops... Something went wrong ${e.response?.status}`,
        },
      });
    }
  }, []);

  const uploadFiles = async (retriedFile?: IUploadFile) => {
    renewCancelToken();

    if (retriedFile) {
      removeFileFromLocalStorage(retriedFile.id);
      uploadFile(retriedFile);
      return;
    }

    const validFiles = filesInStore.filter((file) => file.status === "loaded");

    // eslint-disable-next-line no-restricted-syntax
    for (const file of validFiles) {
      uploadFile(file);
    }
  };

  const onDescriptionUpdate = useCallback(
    (fileId: string, description: string) => {
      updateFileInfo({ fileId, info: { description } });
    },
    [filesInStore]
  );

  const onCancelClick = useCallback(() => {
    const loadedFiles = filesInStore.filter(
      (file) => file.status !== "uploading"
    );
    const uploadingFiles = filesInStore.filter(
      (file) => file.status === "uploading"
    );

    loadedFiles.forEach((file) => {
      onFileDelete(file.id);
    });

    brokenFiles.forEach((file) => {
      if (file.id) {
        onFileDelete(file.id);
      }
    });

    cancelUpload();

    uploadingFiles.forEach((file) => {
      updateFileInfo({
        fileId: file.id,
        info: {
          status: "loaded",
          progressOfUploading: 0,
        },
      });
    });
  }, [filesInStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "15px",
      }}
    >
      <div className={styles.fileCardWrapper} data-cy="list-container">
        {filesInStore.map((file) =>
          file.reason ? (
            <Box
              sx={{
                width: "410px",
              }}
            >
              <FileListItemError
                uploadedFile={file}
                onFileDelete={onFileDelete}
                reason={file.reason}
                uploadFile={uploadFiles}
                key={file.id}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "410px",
              }}
            >
              <FileListItem
                key={file.id}
                onDescriptionUpdate={onDescriptionUpdate}
                file={file}
                onFileDelete={onFileDelete}
              />
            </Box>
          )
        )}

        {brokenFiles.map((brokenFile) => {
          const isFileInStore = filesInStore.some(
            (file) => file.id === brokenFile.id
          );

          if (!isFileInStore) {
            return (
              <FileListItemError
                uploadedFile={brokenFile}
                onFileDelete={onFileDelete}
                reason="broken"
                uploadFile={uploadFiles}
                key={brokenFile.id}
              />
            );
          }

          return null;
        })}
      </div>
      {(filesInStore.length > 0 || brokenFiles.length > 0) && (
        <div className={styles.uploadBtns} data-cy="interactions">
          <Button
            variant="contained"
            sx={{ width: "218px" }}
            onClick={() => uploadFiles()}
            disabled={filesInStore.some((file) => file.status === "uploading")}
          >
            {(() => {
              if (filesInStore.some((file) => file.status === "uploading")) {
                return <>Uploading...</>;
              }
              return <>Upload</>;
            })()}
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "218px" }}
            onClick={onCancelClick}
          >
            {(() => {
              if (filesInStore.some((file) => file.status === "uploading")) {
                return <>Pause</>;
              }
              return <>Clear</>;
            })()}
          </Button>
        </div>
      )}
    </Box>
  );
};
