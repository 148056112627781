import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface Loader {
  loading: boolean;
}

const initialState: Loader = {
  loading: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<Loader>) {
      state.loading = action.payload.loading;
    },
  },
});

export default loaderSlice.reducer;
