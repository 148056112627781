function EditProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        stroke="#3A75E9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.664 3.336H3.831a1.667 1.667 0 00-1.667 1.667v11.666a1.667 1.667 0 001.667 1.667h11.666a1.667 1.667 0 001.667-1.667v-5.833"
      />
      <path
        stroke="#3A75E9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.914 2.08a1.768 1.768 0 112.5 2.5l-7.917 7.917-3.333.833.833-3.333 7.917-7.917z"
      />
    </svg>
  );
}

export default EditProfileIcon;
