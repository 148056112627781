import dayjs, { ManipulateType, OpUnitType } from "dayjs";
import { useState } from "react";

import type {
  CalendarDateInterface,
  DateInterface,
} from "../components/payment-info/payment-history/interfaces/dates.interface";

export default function useDateGroup(
  initialDateType: "day" | "week" | "month" | "dateRange"
) {
  const currentDate = dayjs(new Date());
  const [calendarDate, setCalendarDate] = useState<CalendarDateInterface>({
    startDate: currentDate.toISOString(),
    endDate: currentDate.toISOString(),
  });
  const [date, setDate] = useState<DateInterface>({
    startDate: currentDate
      .subtract(1, initialDateType as ManipulateType)
      .toISOString(),
    endDate: currentDate.toISOString(),
    type: initialDateType,
  });

  const getStartingDate = (type: OpUnitType) =>
    dayjs(calendarDate.startDate).startOf(type);

  const handleChangeDate = (type: string) => {
    if (type === date.type) return;

    switch (type) {
      case "week":
        const startOfTheWeek = getStartingDate(type);
        setDate({
          startDate: startOfTheWeek.toISOString(),
          endDate: startOfTheWeek.add(1, type).toISOString(),
          type: "week",
        });
        return;
      case "month":
        const startOfTheMonth = getStartingDate(type);
        setDate({
          startDate: startOfTheMonth.toISOString(),
          endDate: startOfTheMonth.add(1, type).toISOString(),
          type: "month",
        });
        return;
      case "day":
        setDate({
          startDate: calendarDate.startDate,
          endDate: calendarDate.startDate,
          type: "day",
        });
        return;
      case "dateRange":
        setDate({
          startDate: currentDate.toISOString(),
          endDate: currentDate.toISOString(),
          type: "dateRange",
        });
        return;
      default:
        setDate({
          startDate: currentDate.toISOString(),
          endDate: currentDate.toISOString(),
          type: "day",
        });
    }
  };

  return {
    calendarDate,
    date,
    handleChangeDate,
    setCalendarDate,
    setDate,
  };
}
