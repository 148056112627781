import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { enterDuration } from "../../../constants/animations/fade";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import useQueryParam from "../../../hooks/set-query-param";
import { MutedIcon } from "../../../media/icons/muted";
import { ProfileIcon } from "../../../media/icons/profile";
import { TextLogo } from "../../../media/icons/text-logo";
import { VolumeIcon } from "../../../media/icons/volume";
import {
  useGetContentByIdMutation,
  useGetUserCreatorByIdMutation,
} from "../../../services/catalog/catalog.service";
import { ContentCs } from "../../../services/catalog/interfaces/catalog.interfaces";
import { IHomeCreatorCard } from "../../../services/home/interfaces/home.interface";
import { useUpdateMeMutation } from "../../../services/sign-in/sign-in.service";
import { userSlice } from "../../../store/reducers/user/user-slice";
import { CreatorCard } from "../../creator-card/creator-card";
import { ModalWrapper } from "../../creator-card/modal-wrapper/modal-wrapper";
import { ContentCsCardFullscreen } from "../../user-profile/creator-catalog-cs-view/content-cs-collection/content-cs-card-fullscreen/content-cs-card-fullscreen";
import { AvatarMenu } from "./components/avatar-menu/avatar-menu";
import { CustomMediaSwitch } from "./components/custom-media-switch/custom-media-switch";
import styles from "./main-wrapper.module.scss";

export const MainWrapper: FC<PropsWithChildren> = ({ children }) => {
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const { setParam } = useQueryParam();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<ContentCs | null>(null);
  const [currentCreatorCard, setCurrentCreatorCard] =
    useState<IHomeCreatorCard | null>(null);

  const anchorElRef = useRef<null | HTMLButtonElement>(null);
  const id = anchorElRef.current ? "simple-popper" : undefined;

  const { initialize } = userSlice.actions;
  const { user } = useAppSelector((data) => data.userReducer);
  const [updateMe, { data: newProfile }] = useUpdateMeMutation();
  const [getContentById, { data: contentById }] = useGetContentByIdMutation();
  const [getUserCreatorById, { data: creatorById }] =
    useGetUserCreatorByIdMutation();

  const handleMenuClick = (source: "outside" | "element") => {
    if (source === "outside") {
      setIsMenuOpen(false);
      return;
    }

    setIsMenuOpen(!isMenuOpen);
  };

  const updateProfile = useCallback(
    async ({
      soundMuted,
      playAutomatically,
    }: {
      soundMuted?: boolean;
      playAutomatically?: boolean;
    }) => {
      try {
        await updateMe({
          accessToken: accessToken || "",
          media_sound_muted: soundMuted,
          media_play_automatically: playAutomatically,
        }).unwrap();
      } catch (err: any) {
        navigate("/");
      }
    },
    [accessToken, updateMe]
  );

  const handlePlayClick = useCallback(async () => {
    if (user) {
      await updateProfile({
        playAutomatically: !user.media_play_automatically,
      });
    }
  }, [user]);

  const handleMuteClick = useCallback(async () => {
    if (user) {
      await updateProfile({ soundMuted: !user.media_sound_muted });
    }
  }, [user]);

  useEffect(() => {
    if (newProfile) {
      dispatch(initialize(newProfile.user));
    }
  }, [newProfile]);

  const handleCloseCreatorCard = () => {
    setCurrentCard(null);
    setCurrentCreatorCard(null);
    setParam("", "");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const contentIdValue = params.get("contentId");
    const creatorIdValue = params.get("creatorId");
    if (contentIdValue) {
      getContentById({
        accessToken: accessToken || "",
        content_id: contentIdValue,
      })
        .unwrap()
        .then()
        .catch(() => {});
    }

    if (creatorIdValue) {
      getUserCreatorById({
        accessToken: accessToken || "",
        id: creatorIdValue,
      })
        .unwrap()
        .then()
        .catch();
    }
  }, [location.search]);

  useEffect(() => {
    if (contentById?.success) {
      setCurrentCard(contentById.data);
    }
  }, [contentById]);

  useEffect(() => {
    if (creatorById?.success) {
      setCurrentCreatorCard(creatorById.data);
    }
  }, [creatorById]);

  const renderHeader = () => (
    <>
      <header
        style={{ width: "100%", position: "fixed", top: "0", zIndex: "1" }}
      >
        <div className={styles.header}>
          <Link to="/home" className={styles.logo}>
            <TextLogo />
          </Link>
          <div className={styles.headerInteraction}>
            <Tooltip
              title={
                user?.media_play_automatically
                  ? "Autoplay in on"
                  : "Autoplay in off"
              }
              placement="bottom"
              arrow
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                      {
                        marginTop: "0px",
                      },
                  },
                },
              }}
            >
              <span>
                <CustomMediaSwitch
                  checked={user?.media_play_automatically}
                  onChange={() => handlePlayClick()}
                />
              </span>
            </Tooltip>
            <div className={styles.headerInteractionCircles}>
              <button
                className={styles.interactionVolume}
                type="button"
                onClick={() => handleMuteClick()}
              >
                {user?.media_sound_muted ? (
                  <MutedIcon width={20} height={20} />
                ) : (
                  <VolumeIcon width={20} height={20} />
                )}
              </button>
              <ClickAwayListener onClickAway={() => handleMenuClick("outside")}>
                <button
                  ref={anchorElRef}
                  className={styles.interactionAvatar}
                  aria-describedby={id}
                  type="button"
                  onClick={() => handleMenuClick("element")}
                >
                  {user?.avatar ? (
                    <Avatar
                      alt={user.username}
                      src={user.avatar.backend_media_url}
                      sx={{
                        width: 48,
                        height: 48,
                        border: "2px solid #6995ec",
                      }}
                    />
                  ) : (
                    <ProfileIcon color="#3A75E9" width={24} height={24} />
                  )}
                </button>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </header>
      <AvatarMenu isOpen={isMenuOpen} id={id} anchorEl={anchorElRef.current} />
    </>
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "100vh",
        paddingTop: "30px",
      }}
    >
      {renderHeader()}
      <Box
        className={styles.mainBox}
        style={{ width: "100%", height: "100%", flexGrow: 1 }}
      >
        {children && (
          <Fade in timeout={enterDuration}>
            {children as ReactElement<any, any>}
          </Fade>
        )}
      </Box>

      <ModalWrapper shown={!!currentCard} close={handleCloseCreatorCard}>
        {currentCard && (
          <ContentCsCardFullscreen
            currentCard={currentCard}
            handleSetNextCard={() => {}}
            handleSetPreviousCard={() => {}}
            handleCloseCreatorCard={handleCloseCreatorCard}
            isOpenedFromMainWrapper
          />
        )}
      </ModalWrapper>

      <ModalWrapper shown={!!currentCreatorCard} close={handleCloseCreatorCard}>
        {currentCreatorCard && (
          <CreatorCard
            handleCloseCreatorCard={handleCloseCreatorCard}
            currentCard={currentCreatorCard.creator}
            isPreview
          />
        )}
      </ModalWrapper>
    </Box>
  );
};
