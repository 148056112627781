import { Box, Card, Tab, Tabs } from "@mui/material";
import React from "react";

import { a11yProps } from "../../helpers/tabs/allyProps";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { CustomTabPanel } from "../shared/tabs/custom-tab-panel";
import { ActiveSubscriptions } from "./active-subscriptions/active-subscriptions";
import { CancelledSubscriptions } from "./cancelled-subscriptions/cancelled-subscriptions";
import styles from "./subscriptions.module.scss";

export const Subscriptions = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.wrapper}>
          <Card className={styles.mainCard} sx={{ borderRadius: "32px" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Active subscriptions" {...a11yProps(0)} />
                  <Tab label="Cancelled subscriptions" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ActiveSubscriptions />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <CancelledSubscriptions />
              </CustomTabPanel>
            </Box>
          </Card>
        </Box>
      </MainWrapper>
    </Box>
  );
};
