import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  UserCurrency,
  UserCurrencyResponse,
} from "./interfaces/user-currencies.interface";

export const userCurrenciesApi = createApi({
  reducerPath: "userCurrenciesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (build) => ({
    setUserCurrency: build.mutation<UserCurrencyResponse, UserCurrency>({
      query: (data: UserCurrency) => ({
        url: ENDPOINTS.CURRENCIES,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        body: {
          currency: data.currency,
        },
      }),
    }),
  }),
});

export const { useSetUserCurrencyMutation } = userCurrenciesApi;
