import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Typography,
} from "@mui/material";
import { type FormikHelpers, useFormik } from "formik";
import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  useAddManualTransferMutation,
  useRemoveCurrencyManualTransferMutation,
} from "../../../services/manual-transfers/manual-transfers";
import type { ManualTransfer } from "../../../services/sign-in/interfaces/initialize.interface";
import { manualTransfersSlice } from "../../../store/reducers/manual-transfers/manual-transfers";
import {
  btcValues,
  euroValues,
  usdValues,
} from "../../automatic-transfers/data";
import { CurrenciesEnum } from "../../automatic-transfers/enums/currencies.enum";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import DefaultSelect from "../../shared/select/select";
import type { ITransfers } from "../interfaces/transfer.interface";
import styles from "../transfers.module.scss";
import CurrencyTransfer from "./currency-transfer/currency-transfer";

interface ManualTransferFormProps {
  manualTransfer: ManualTransfer;
  currencyData: {
    display_text: string;
    value: CurrenciesEnum;
    start_component: React.JSX.Element;
    disabled?: boolean;
  }[];
  generateNewTransfer: () => void;
  showNotice?: boolean;
}
export const TransferForm = (props: ManualTransferFormProps) => {
  const { manualTransfer, currencyData, generateNewTransfer, showNotice } =
    props;
  const [openDialog, setOpenDialog] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [currencyManualTransferId, setCurrencyManualTransferId] = useState("");
  const [selectedManualTransfer, setSelectedManualTransfer] = useState("");
  const [isNoticeConfirmed, setIsNoticeConfirmed] = useState(false);

  const navigate = useNavigate();
  const [saveCurrencyTransfer] = useAddManualTransferMutation();

  const { user } = useAppSelector((item) => item.userReducer);
  const accessToken =
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";

  const dispatch = useAppDispatch();

  const {
    addCurrencyManualTransfer,
    removeCurrencyManualTransfer,
    changeManualTransferValue,
  } = manualTransfersSlice.actions;

  const handleAddAnotherCurrencyManualTransfer = () => {
    dispatch(
      addCurrencyManualTransfer({
        manual_transfer_id: manualTransfer.id,
        balance: user?.wallets[manualTransfer.currency as any],
        rest: Number(user?.wallets[manualTransfer.currency as any]) || 0,
        currency_transfer: {
          amount: "",
          custom_amount: "",
          id: uuidv4(),
          payment_type: "fixed",
          transfer_account: "",
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      currency: manualTransfer.currency,
      amount: btcValues[0].value,
      submit: null,
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(),
    }),
    onSubmit: async (
      values: ITransfers,
      helpers: FormikHelpers<ITransfers>
    ) => {
      try {
        if (showNotice && !isNoticeConfirmed) {
          setNoticeModalOpen(true);
          return;
        }

        const missingAmount = manualTransfer.currency_transfers.find(
          (item) => item.amount === null || item.amount === ""
        );

        const missingCustomAmount = manualTransfer.currency_transfers.find(
          (item) => item.custom_amount === null || item.custom_amount === ""
        );

        const missingTransferAccount = manualTransfer.currency_transfers.find(
          (item) =>
            item.transfer_account === null || item.transfer_account === ""
        );

        if (missingAmount && missingCustomAmount) {
          return;
        }

        if (missingTransferAccount) {
          return;
        }
        const rest = manualTransfer.currency_transfers.reduce(
          (prev: any, curr: any) => {
            if (curr.payment_type === "fixed") {
              return Number(prev) + Number(curr.amount);
            }
            if (curr.payment_type === "percentageOfTotal") {
              const amount =
                (Number(user?.wallets[manualTransfer.currency as any]) *
                  Number(curr.custom_amount.replace(/%/g, ""))) /
                100;

              return Number(prev) + amount;
            }
            return Number(prev) + Number(curr.amount);
          },
          0
        );

        await saveCurrencyTransfer({
          manual_transfer: {
            currency: manualTransfer.currency,
            balance: user?.wallets[manualTransfer.currency as any],
            rest:
              Number(user?.wallets[manualTransfer.currency as any] || 0) - rest,
            currency_transfers: manualTransfer.currency_transfers.map(
              (item) => ({
                amount: item.amount,
                manual_transfer_id: manualTransfer.id,
                custom_amount: item.custom_amount,
                payment_type: item.payment_type,
                transfer_account: item.transfer_account,
                id: item.id,
              })
            ),
            id: uuidv4(),
          },
          accessToken,
        });

        generateNewTransfer();
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.data.message });
        helpers.setSubmitting(false);
      }
    },
  });
  const [
    deleteCurrencyManualTransfer,
    { isLoading: isDeleteCurrencyTransferLoading },
  ] = useRemoveCurrencyManualTransferMutation();

  const handleRemoveCurrencyTransfer = async (id: string) => {
    const data = await deleteCurrencyManualTransfer({
      accessToken,
      id,
    });

    if (data) {
      dispatch(
        removeCurrencyManualTransfer({
          manual_transfer_id: manualTransfer.id,
          currency_transfer_id: id,
        })
      );

      setOpenDialog(false);
    }
  };

  const handleDelete = () => {
    if (currencyManualTransferId) {
      handleRemoveCurrencyTransfer(currencyManualTransferId);
    }
  };

  const { transferAccounts } = useAppSelector(
    (item) => item.transferAccountsReducer
  );
  useEffect(() => {
    formik.setFieldValue("currency", manualTransfer.currency);
  }, [manualTransfer.currency]);
  return (
    <>
      <Card className={styles.card} data-type="Transfer form">
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Currency: </Typography>
                <Box width={300}>
                  <DefaultSelect
                    name="currency"
                    options={currencyData}
                    onChange={(value) => {
                      const selectedCurrency = value.value;
                      formik.values.currency = selectedCurrency;

                      switch (selectedCurrency) {
                        case CurrenciesEnum.BTC:
                          formik.values.amount = btcValues[0].value;
                          break;
                        case CurrenciesEnum.EURO:
                          formik.values.amount = euroValues[0].value;
                          break;
                        case CurrenciesEnum.USD:
                          formik.values.amount = usdValues[0].value;
                          break;
                        default:
                          break;
                      }

                      formik.setFieldValue("currency", value.value);
                      dispatch(
                        changeManualTransferValue({
                          manual_transfer_id: manualTransfer.id,
                          key: "currency",
                          value: value.value,
                        })
                      );
                    }}
                    value={formik.values.currency}
                  />
                </Box>
              </Box>
            </Box>
            {manualTransfer.currency_transfers.length === 0 && (
              <Box className={styles.line} marginTop="16px" />
            )}
            <TransitionGroup>
              {manualTransfer.currency_transfers.map((item, index) => (
                <Collapse key={item.id}>
                  <Box key={item.id} marginTop="23px">
                    <Box className={styles.line} marginBottom="16px" />
                    <CurrencyTransfer
                      currency={formik.values.currency}
                      transferAccounts={transferAccounts.map((curr) => {
                        const isDisabled =
                          manualTransfer.currency_transfers.findIndex(
                            (trans) => trans.transfer_account === curr.id
                          ) !== -1;

                        return {
                          ...curr,
                          disabled: isDisabled,
                        };
                      })}
                      currencyManualTransfer={item}
                      setIsValid={setIsValid}
                      manualTransfer={manualTransfer}
                      index={index}
                      setOpenDialog={setOpenDialog}
                      setCurrencyManualTransferId={setCurrencyManualTransferId}
                      setSelectedManualTransfer={setSelectedManualTransfer}
                    />
                  </Box>
                </Collapse>
              ))}
            </TransitionGroup>
          </form>
        </Box>
        <Box marginTop="24px" display="flex" gap="16px">
          <Button
            variant="contained"
            disabled={!isValid}
            sx={{
              ...(manualTransfer.currency_transfers.length === 0 && {
                display: "none",
              }),
            }}
            data-type="transfer button"
            onClick={() => {
              setConfirmModalOpen(true);
            }}
          >
            Transfer
          </Button>
          <Button
            onClick={handleAddAnotherCurrencyManualTransfer}
            variant="outlined"
            sx={{
              backgroundColor: "#fff",
            }}
            data-type="add transfer button"
          >
            + Add Transfer
          </Button>
        </Box>
      </Card>

      <ModalDialog isOpen={confirmModalOpen} classes={styles.modal}>
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Transfer now
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Do you want to confirm this transfer?
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={() => {
              formik.handleSubmit();
              setConfirmModalOpen(false);
            }}
          >
            <Typography fontSize="16px">
              {isDeleteCurrencyTransferLoading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                "Transfer"
              )}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => setConfirmModalOpen(false)}
            disabled={isDeleteCurrencyTransferLoading}
          >
            <Typography fontSize="16px">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>

      <ModalDialog isOpen={noticeModalOpen} classes={styles.modal}>
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Important Notice
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Please be informed that your third money transfer this month will
              incur a fee imposed by our payment processor.
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={() => {
              setNoticeModalOpen(false);
              setIsNoticeConfirmed(true);
              formik.submitForm();
            }}
          >
            <Typography fontSize="16px">
              {isDeleteCurrencyTransferLoading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                "Agree"
              )}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => navigate("/cc/automatic-transfers")}
            disabled={isDeleteCurrencyTransferLoading}
          >
            <Typography fontSize="16px">Schedule</Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            color="error"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
              borderColor: "inherit",
            }}
            onClick={() => setNoticeModalOpen(false)}
            disabled={isDeleteCurrencyTransferLoading}
          >
            <Typography fontSize="16px">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>

      <ModalDialog
        isOpen={openDialog && manualTransfer.id === selectedManualTransfer}
        classes={styles.modal}
      >
        <ModalDialogHeader position="center">
          <Typography align="center" variant="h4" fontWeight={400}>
            Delete Currency Transfer
          </Typography>
        </ModalDialogHeader>
        <ModalDialogContent>
          <div>
            <Typography align="center" variant="body1" fontWeight={400}>
              Are you sure you want to delete Currency transfer?
            </Typography>
          </div>
        </ModalDialogContent>
        <ModalDialogFooter className={styles.footer}>
          <Button
            color="error"
            variant="contained"
            type="button"
            sx={{
              width: "218px",
            }}
            onClick={handleDelete}
          >
            <Typography fontSize="16px">
              {isDeleteCurrencyTransferLoading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                "Delete"
              )}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: "218px",
            }}
            onClick={() => setOpenDialog(false)}
            disabled={isDeleteCurrencyTransferLoading}
          >
            <Typography fontSize="16px">Cancel</Typography>
          </Button>
        </ModalDialogFooter>
      </ModalDialog>
    </>
  );
};
