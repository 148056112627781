export const BitcoinTextIcon = ({
  width = 32,
  height = 32,
  color = "rgba(37, 39, 51, 1)",
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13,4.06437853 C15.4137328,4.38772165 17,5.92171577 17,8.5 C17,9.80666218 16.5925809,10.8451074 15.8765128,11.5936812 C17.2001695,12.3022124 18,13.6304361 18,15.5 C18,18.4022916 16.0724776,20 13.25,20 L13,20 L13,22 L11,22 L11,20 L9,20 L9,22 L7,22 L7,20 L5,20 L5,18 L7,18 L7,6 L5,6 L5,4 L7,4 L7,2 L9,2 L9,4 L11,4 L11,2 L13,2 L13,4.06437853 Z M12,6 L9,6 L9,11 L12,11 C13.9561749,11 15,10.1888576 15,8.5 C15,6.81114244 13.9561749,6 12,6 Z M9,13 L9,18 L13.25,18 C15.0421608,18 16,17.2060545 16,15.5 C16,13.7939455 15.0421608,13 13.25,13 L9,13 Z"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);
