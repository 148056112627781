import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";

import { REGEX } from "../../../../constants/regex/regex";
import { useAppDispatch } from "../../../../hooks/redux";
import { BankAcc } from "../../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../../media/icons/btc";
import { DeleteIcon } from "../../../../media/icons/delete-icon";
import { DollarLogo } from "../../../../media/icons/dollar";
import { EuroLogo } from "../../../../media/icons/euro";
import type {
  CurrencyManualTransfer,
  IUserTransferAccounts,
  ManualTransfer,
} from "../../../../services/sign-in/interfaces/initialize.interface";
import { manualTransfersSlice } from "../../../../store/reducers/manual-transfers/manual-transfers";
import { paymentValues } from "../../../automatic-transfers/data";
import { CurrenciesEnum } from "../../../automatic-transfers/enums/currencies.enum";
import DefaultSelect from "../../../shared/select/select";
import type { ITransferForm } from "../../interfaces/transfer.interface";

type CurrencyManualTransferProps = {
  currency: CurrenciesEnum;
  manualTransfer: ManualTransfer;
  currencyManualTransfer: CurrencyManualTransfer;
  index: number;
  setOpenDialog: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setCurrencyManualTransferId: (
    value: string | ((prevVar: string) => string)
  ) => void;
  setSelectedManualTransfer: (
    value: string | ((prevVar: string) => string)
  ) => void;
  setIsValid: any;
  transferAccounts: IUserTransferAccounts[];
};

export default function CurrencyTransfer(props: CurrencyManualTransferProps) {
  const {
    currency,
    manualTransfer,
    currencyManualTransfer,
    index,
    setCurrencyManualTransferId,
    setOpenDialog,
    setSelectedManualTransfer,
    setIsValid,
    transferAccounts,
  } = props;

  const { changeValue } = manualTransfersSlice.actions;

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      amount: currencyManualTransfer.amount,
      payment_type: currencyManualTransfer.payment_type,
      submit: null,
      custom_amount: currencyManualTransfer.custom_amount,
      to: currencyManualTransfer.transfer_account,
    },
    validationSchema: Yup.object({
      payment_type: Yup.string().required(),
      custom_amount: Yup.string().when(
        "payment_type",
        ([paymentType], schema) =>
          paymentType === "percentageOfTotal"
            ? schema
                .required("Please enter amount")
                .matches(
                  REGEX.CURRENCY_TRANSFER,
                  "Please enter correct amount with (%)"
                )
            : schema.min(0)
      ),
      to: Yup.string().required(),
      amount: Yup.number().when("payment_type", ([paymentType], schema) =>
        paymentType === "fixed"
          ? schema.required("Please enter amount")
          : schema.min(0)
      ),
    }),
    onSubmit: async (
      values: ITransferForm,
      helpers: FormikHelpers<ITransferForm>
    ) => {
      try {
        /* Waiting for further implementation */
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.data.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (formik.isValid && formik.dirty) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  });

  const renderAmount = () => (
    <>
      <TextField
        placeholder="Enter amount"
        type="text"
        inputMode="decimal"
        error={!!(formik.touched.amount && formik.errors.amount)}
        name="amount"
        lang="fr"
        onBlur={formik.handleBlur}
        onChange={(e) => {
          const { value } = e.target;
          if (value.match(/^\d*(\.\d{0,2})?$/)) {
            formik.setFieldValue("amount", value);

            dispatch(
              changeValue({
                manual_transfer_id: manualTransfer.id,
                currency_transfer_id: currencyManualTransfer.id,
                key: "amount",
                value,
              })
            );
          }
        }}
        value={formik.values.amount}
        sx={{ width: "300px" }}
        InputProps={{
          sx: {
            background: "#fff",
          },
          startAdornment: (
            <InputAdornment position="start">
              {currency === CurrenciesEnum.BTC && (
                <BtcLogo width={24} height={24} />
              )}
              {currency === CurrenciesEnum.EURO && (
                <EuroLogo width={24} height={24} />
              )}
              {currency === CurrenciesEnum.USD && (
                <DollarLogo width={24} height={24} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const calculateDisplayText = (item: IUserTransferAccounts) => {
    if (item.bank_account) {
      return item.bank_account.name
        ? item.bank_account.name
        : item.bank_account.bank_account_number;
    }

    if (item.crypto) {
      return item.crypto.name ? item.crypto.name : item.crypto.wallet_id;
    }

    return "";
  };
  const formatPercentageAmount = (value: string) => {
    if (value === "" || Number.isNaN(Number(value))) {
      return "0";
    }
    const numValue = Number(value);
    if (numValue < 1) {
      value = "0";
    } else if (numValue > 100) {
      value = "100";
    }
    return value;
  };

  const filteredTransferAccountsByCurrency = useMemo(
    () =>
      transferAccounts.filter((account) =>
        manualTransfer.currency === CurrenciesEnum.BTC
          ? account.crypto !== null
          : account.bank_account !== null
      ),
    [manualTransfer.currency, transferAccounts]
  );

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight={700}>Transfer № {index + 1}</Typography>
        <IconButton
          onClick={() => {
            setOpenDialog(true);
            setCurrencyManualTransferId(currencyManualTransfer.id);
            setSelectedManualTransfer(manualTransfer.id);
          }}
          sx={{ transform: "translate(8px, -1px)" }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>To: </Typography>
        <Box width={300}>
          {filteredTransferAccountsByCurrency.length > 0 ? (
            <DefaultSelect
              placeholder="Select account"
              name="to"
              options={filteredTransferAccountsByCurrency.map((item) => ({
                display_text: calculateDisplayText(item),
                value: item.id,
                disabled: item.disabled,
                start_component: item.bank_account
                  ? BankAcc({ width: 24, height: 24 })
                  : BtcLogo({ width: 24, height: 24 }),
              }))}
              onChange={(value) => {
                formik.setFieldValue("to", value.value);

                dispatch(
                  changeValue({
                    manual_transfer_id: manualTransfer.id,
                    currency_transfer_id: currencyManualTransfer.id,
                    key: "transfer_account",
                    value: value.value,
                  })
                );
              }}
              value={formik.values.to}
            />
          ) : (
            <Typography>Please add transfer account</Typography>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Payment Type: </Typography>
        <Box width={300}>
          <DefaultSelect
            name="payment_type"
            options={paymentValues}
            onChange={(value) => {
              formik.setFieldValue("payment_type", value.value);

              dispatch(
                changeValue({
                  manual_transfer_id: manualTransfer.id,
                  currency_transfer_id: currencyManualTransfer.id,
                  key: "payment_type",
                  value: value.value,
                })
              );
            }}
            value={formik.values.payment_type}
          />
        </Box>
      </Box>
      {formik.values.payment_type === "fixed" ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Amount: </Typography>
          <Box width={300}>{renderAmount()}</Box>
        </Box>
      ) : (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Amount: </Typography>
          <Box width={300}>
            <TextField
              placeholder="Enter amount"
              type="text"
              inputMode="numeric"
              error={
                !!(formik.touched.custom_amount && formik.errors.custom_amount)
              }
              name="custom_amount"
              onFocus={(e) => {
                if (e.target.value.includes("%")) {
                  formik.setFieldValue(
                    "custom_amount",
                    `${e.target.value.slice(0, -1)}`
                  );
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);

                if (!e.target.value.includes("%") && e.target.value) {
                  formik.setFieldValue(
                    "custom_amount",
                    `${formatPercentageAmount(e.target.value)}%`
                  );
                }
              }}
              onChange={(e) => {
                const { value } = e.target;
                if (value.match(/^\d*(\.\d{0,2})?%?$/)) {
                  formik.setFieldValue("custom_amount", value);
                  // formik.handleChange(e);

                  dispatch(
                    changeValue({
                      manual_transfer_id: manualTransfer.id,
                      currency_transfer_id: currencyManualTransfer.id,
                      key: "custom_amount",
                      value: !value.includes("%")
                        ? `${formatPercentageAmount(value)}%`
                        : formatPercentageAmount(value),
                    })
                  );
                }
              }}
              value={formik.values.custom_amount}
              sx={{ width: "300px" }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
