import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import type { IUploadFile } from "../../interfaces/file-uploading/uploaded-files.interface";
import { FileLoader } from "../shared/file-loader/file-loader";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { UploadContentList } from "./upload-content-list/upload-content-list";

export const UploadContent = () => {
  const [loadedFiles, setLoadedFiles] = useState<IUploadFile[]>([]);

  const getFileType = (file: File): "image" | "video" | "unknown" => {
    if (file.type.includes("video")) {
      return "video";
    }

    if (file.type.includes("image")) {
      return "image";
    }

    return "unknown";
  };

  const onFilesUploaded = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const alreadyExists = loadedFiles.find(
          (f) => f.file?.name === file.name && f.file.size === file.size
        );

        if (!alreadyExists) {
          const newFile: IUploadFile = {
            file,
            id: uuidv4(),
            description: "",
            status: "loaded",
            progressOfUploading: 0,
            fileName: file.name,
            fileUrl: URL.createObjectURL(file),
            fileType: getFileType(file),
          };

          setLoadedFiles((prevState) => [...prevState, newFile]);
        }
      });
    },
    [loadedFiles]
  );

  return (
    <Box display="flex" justifyContent="center" height="200px">
      <MainWrapper>
        <Box padding="47px 126px">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap="14px"
          >
            <Typography variant="h4" fontWeight="normal">
              Upload Content
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography fontSize="20px" fontWeight="normal">
                Select content to upload
              </Typography>
              <Typography variant="body1" fontWeight="normal" marginTop="2px">
                <Link to="/">Need help?</Link>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: "25px", height: "140px" }}>
            <FileLoader onFilesUpload={onFilesUploaded} />
          </Box>
          <UploadContentList
            filesInStore={loadedFiles}
            setLoadedFiles={setLoadedFiles}
          />
        </Box>
      </MainWrapper>
    </Box>
  );
};
