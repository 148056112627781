import { useEffect } from "react";

import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage-keys/local-storage-keys";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { CreatorCatalogCSViewProvider } from "./creator-catalog-cs-view/context/creator-catalog-cs-view-context";
import { CreatorCatalogCsView } from "./creator-catalog-cs-view/creator-catalog-cs-view";
import { SUBSCRIPTION_PLANS } from "./creator-catalog-cs-view/sidebar-cs-view/subscription-plans-cs-view/subscription-plans";

export default function UserProfile() {
  useEffect(() => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN,
      SUBSCRIPTION_PLANS[0].plan_id
    );

    return () =>
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION_PLAN);
  }, []);

  return (
    <MainWrapper>
      <div
        style={{
          paddingTop: "70px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <CreatorCatalogCSViewProvider>
          <CreatorCatalogCsView />
        </CreatorCatalogCSViewProvider>
      </div>
    </MainWrapper>
  );
}
