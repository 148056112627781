import { Card } from "@mui/material";
import { styled } from "styled-components";

export const StyledCard = styled(Card)({
  width: "742px",
  display: "flex",
  flexDirection: "column",
  gap: "17px",
  borderRadius: "32px",
  padding: "32px 96px",
  background:
    "linear-gradient(180deg,rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 156.08%)",
  backdropFilter: "blur(3px)",
});
