import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { type FC, useEffect } from "react";

import { useQuery } from "../../../hooks/query-params";
import { SearchIcon } from "../../../media/icons/search";
import { LastSearchType } from "../../../services/home/interfaces/home.interface";
import { LastSearchedList } from "./last-searched/last-searched-list";
import styles from "./search-bar.module.scss";

interface ISearchBarProp {
  placeholder: string;
  renderFooter?: React.JSX.Element;
  handleSearchTextChange: (searchText: string) => void;
  lastSearchType: LastSearchType;
  isPreview?: boolean;
}

export const SearchBar: FC<ISearchBarProp> = ({
  handleSearchTextChange,
  renderFooter,
  placeholder,
  lastSearchType,
  isPreview = false,
}) => {
  const query = useQuery();
  const searchTag = query.get("searchTag");

  const formik = useFormik({
    initialValues: {
      searchText: searchTag || "",
    },
    onSubmit: async (values: { searchText: string }) => {
      if (!isPreview) {
        handleSearchTextChange(values.searchText);
      }
    },
  });

  useEffect(() => {
    if (searchTag !== null) {
      formik.submitForm();
    }
  }, [searchTag]);

  useEffect(() => {
    if (formik.values.searchText === "") {
      formik.handleSubmit();
    }
  }, [formik.values.searchText]);

  return (
    <form noValidate onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <section>
        <TextField
          placeholder={placeholder}
          type="text"
          name="searchText"
          fullWidth
          value={formik.values.searchText}
          onChange={formik.handleChange}
          InputProps={{
            style: {
              padding: "13px 14px 13px 13px",
              borderRadius: "12px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    border: "1px solid #6995EC",
                  }}
                  type="submit"
                >
                  <SearchIcon width={15} height={15} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </section>
      {!isPreview && (
        <LastSearchedList
          lastTextHandleChange={formik.handleChange}
          lastSearchType={lastSearchType}
        />
      )}
      <section className={styles.filterSection}>
        {renderFooter === undefined ? null : renderFooter}
      </section>
    </form>
  );
};
