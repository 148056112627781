import type { FC } from "react";

type PasswordValidationErrorProp = {
  width?: number;
  height?: number;
};

export const PasswordValidationError: FC<PasswordValidationErrorProp> = ({
  width = 14,
  height = 14,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 0.333008C3.33301 0.333008 0.333008 3.33301 0.333008 6.99967C0.333008 10.6663 3.33301 13.6663 6.99967 13.6663C10.6663 13.6663 13.6663 10.6663 13.6663 6.99967C13.6663 3.33301 10.6663 0.333008 6.99967 0.333008ZM10.2663 9.33301L9.33301 10.2663L6.99967 7.93301L4.66634 10.2663L3.73301 9.33301L6.06634 6.99967L3.73301 4.66634L4.66634 3.73301L6.99967 6.06634L9.33301 3.73301L10.2663 4.66634L7.93301 6.99967L10.2663 9.33301Z"
      fill="#EC5050"
    />
  </svg>
);
