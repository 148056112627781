import { Box, Typography } from "@mui/material";
import type React from "react";

import InstagramSocialMediaIcon from "../../../../../../media/icons/catalog/side-bar/social-medias/instagram-social-media-icon";
import TikTokSocialMediaIcon from "../../../../../../media/icons/catalog/side-bar/social-medias/tik-tok-social-media-icon";
import XSocialMediaIcon from "../../../../../../media/icons/catalog/side-bar/social-medias/x-social-media-icon";
import YoutubeSocialMediaIcon from "../../../../../../media/icons/catalog/side-bar/social-medias/youtube-social-media-icon";
import { ILinkForm } from "../../../../../settings-cc/personal-info/social-media/social-info";
import styles from "./social-media.module.scss";

interface SocialMedialParams {
  links: ILinkForm;
}

export const SocialMedial: React.FC<SocialMedialParams> = ({ links }) => {
  const hasSomeLink = Object.values(links).some((value) => value !== null);

  return (
    <>
      {hasSomeLink && (
        <Box className={styles.socialMediaRow}>
          <Typography fontSize={16}>
            <strong>Social media:</strong>
          </Typography>
          {links.twitter && (
            <a href={links.twitter} target="_blank" rel="noopener noreferrer">
              <XSocialMediaIcon />
            </a>
          )}

          {links.instagram && (
            <a href={links.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramSocialMediaIcon />
            </a>
          )}

          {links.tiktok && (
            <a href={links.tiktok} target="_blank" rel="noopener noreferrer">
              <TikTokSocialMediaIcon />
            </a>
          )}

          {links.youtube && (
            <a href={links.youtube} target="_blank" rel="noopener noreferrer">
              <YoutubeSocialMediaIcon />
            </a>
          )}
        </Box>
      )}
    </>
  );
};
