import {
  DndContext,
  type DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSwappingStrategy } from "@dnd-kit/sortable";
import { Box, LinearProgress } from "@mui/material";
import type { FC } from "react";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import {
  usePatchInterestedCardOrderMutation,
  usePatchSubscriptionsOrderMutation,
} from "../../../services/home/home.service";
import styles from "../../settings-cc/greetings/greetings-media-list/greetings-media-list.module.scss";
import { HomeListSortableItem } from "../home-list-item/home-list-sortable-item";
import { useHomeListContext } from "./home-list-context/home-list-context";

interface IHomeListProp {}

export const HomeList: FC<IHomeListProp> = () => {
  const { tab, items, setItems, lastMemberElementRef, getLoadingRecords } =
    useHomeListContext();

  const [patchInterestedCardOrder] = usePatchInterestedCardOrderMutation();

  const [patchSubscriptionsOrder] = usePatchSubscriptionsOrderMutation();

  const userAccessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const putchCardOrder = (subscriptioId: string, cardOrder: number) => {
    if (userAccessToken !== null) {
      if (tab === "interested") {
        patchInterestedCardOrder({
          id: subscriptioId,
          order: cardOrder,
          accessToken: userAccessToken,
        });
      }

      if (tab === "subscribed") {
        patchSubscriptionsOrder({
          id: subscriptioId,
          order: cardOrder,
          accessToken: userAccessToken,
        });
      }
    }
  };

  const sensors = useSensors(useSensor(PointerSensor));
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id === over.id) return;

    const list = [...items];
    const sourceIndex = items.findIndex((item) => item.id === active.id);
    const destinationIndex = items.findIndex((item) => item.id === over.id);

    const temp = list[sourceIndex];
    list[sourceIndex] = list[destinationIndex];
    list[destinationIndex] = temp;

    putchCardOrder(list[sourceIndex].id, sourceIndex);
    putchCardOrder(list[destinationIndex].id, destinationIndex);

    setItems(list);
  };

  return (
    <Box
      marginTop="20px"
      alignSelf="center"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap="20px"
      width="calc(100% - 400px)"
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={rectSwappingStrategy}>
          <div className={styles.fileCardWrapper}>
            {items.length > 0 &&
              items.map((card, index) => (
                <div
                  key={card.id}
                  ref={items.length === index + 1 ? lastMemberElementRef : null}
                >
                  <HomeListSortableItem
                    id={card.id}
                    itemData={card}
                    draggable={tab === "subscribed" || tab === "interested"}
                  />
                </div>
              ))}
          </div>
          {getLoadingRecords() && <LinearProgress sx={{ width: "100%" }} />}
        </SortableContext>
      </DndContext>
    </Box>
  );
};
