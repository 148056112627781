import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import type { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { a11yProps } from "../../helpers/tabs/allyProps";
import { useAppSelector } from "../../hooks/redux";
import useQueryParam from "../../hooks/set-query-param";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { CustomTabPanel } from "../shared/tabs/custom-tab-panel";
import { Greetings } from "./greetings/greetings";
import { PersonalInfo } from "./personal-info/personal-info";
import { SecurityPrivacy } from "./security-privacy/security-privacy";
import styles from "./settings-cc.module.scss";

export const SettingsCc = () => {
  const [value, setValue] = useState(0);
  const [hash, setHash] = useState(window.location.hash);
  const { user } = useAppSelector((item) => item.userReducer);
  const { setParam } = useQueryParam();
  const navigate = useNavigate();

  useEffect(() => {
    function handleHashChange() {
      setHash(window.location.hash);
    }

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    switch (hash) {
      case "#personal-info": {
        setValue(0);
        break;
      }
      case "#greetings": {
        setValue(1);
        break;
      }
      case "#security": {
        setValue(2);
        break;
      }
      default: {
        setValue(0);
      }
    }
  }, [hash]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0: {
        window.location.hash = "#personal-info";
        break;
      }
      case 1: {
        window.location.hash = "#greetings";
        break;
      }
      case 2: {
        window.location.hash = "#security";
        break;
      }
      default: {
        window.location.hash = "#personal-info";
      }
    }
  };

  const openProfilePreview = () => {
    navigate(`/user-profile/${user?.id}?isPreview=true`);
  };

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.wrapper}>
          <Box
            sx={{
              flexGrow: "1",
            }}
          >
            <Typography variant="h4" fontWeight="normal">
              Profile settings
            </Typography>

            <Box sx={{ width: "100%", marginTop: "15px" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Personal info"
                    {...a11yProps(0)}
                    disableRipple
                    sx={{
                      color: "#4C4B4C",
                      justifyContent: "flex-end",
                      padding: "0 25px 5px 25px",
                      fontSize: "16px",
                    }}
                  />
                  <Tab
                    label="Greeting photos/videos"
                    {...a11yProps(1)}
                    disableRipple
                    sx={{
                      color: "#4C4B4C",
                      justifyContent: "flex-end",
                      padding: "0 29px 5px 29px",
                      fontSize: "16px",
                    }}
                  />
                  <Tab
                    label="Security & Privacy"
                    {...a11yProps(2)}
                    disableRipple
                    sx={{
                      color: "#4C4B4C",
                      justifyContent: "flex-end",
                      padding: "0 12px 5px 12px",
                      fontSize: "16px",
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <PersonalInfo />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Greetings />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <SecurityPrivacy />
              </CustomTabPanel>
            </Box>
          </Box>

          <Box className={styles.preview}>
            <Button
              color="primary"
              variant="contained"
              type="button"
              sx={{ width: "237px" }}
              onClick={openProfilePreview}
            >
              <Typography variant="button">Profile preview</Typography>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="button"
              sx={{ width: "237px" }}
              onClick={() => {
                setParam("creatorId", user?.id || "");
              }}
            >
              <Typography variant="button">Creator card preview</Typography>
            </Button>
          </Box>
        </Box>
      </MainWrapper>
    </Box>
  );
};
