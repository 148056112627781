import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ILastSearchItem } from "../../../services/home/interfaces/home.interface";

interface ILastSearch {
  lastSearchItems: ILastSearchItem[];
}

const initialState: ILastSearch = {
  lastSearchItems: [],
};

export const lastSearchSlice = createSlice({
  name: "lastSearch",
  initialState,
  reducers: {
    setLastSearchItems(state, action: PayloadAction<ILastSearchItem[]>) {
      state.lastSearchItems = action.payload;
    },
  },
});

export default lastSearchSlice.reducer;
