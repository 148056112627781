import type { FC, PropsWithChildren } from "react";

interface IDragIconProp {
  width?: number;
  height?: number;
}
export const DragIcon: FC<PropsWithChildren<IDragIconProp>> = ({
  width = 32,
  height = 32,
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14062 1.57031H14.8549"
        stroke="#3A75E9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.14062 5H14.8515"
        stroke="#3A75E9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.14062 8.42188H14.8492"
        stroke="#3A75E9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
