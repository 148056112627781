import {
  Avatar,
  Button,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Link as LinkButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import type { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { SortingDirection } from "../../../enums/sorting-direction.enum";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { ArrowDown } from "../../../media/icons/arrowDown";
import { SearchInCircleIcon } from "../../../media/icons/search-in-circle";
import {
  useCreateGroupMutation,
  useFetchSubscribersMutation,
} from "../../../services/manage-subscribers/manage-subscribers.service";
import { manageSubscribersModalSlice } from "../../../store/reducers/manage-subscribers/manage-subscribers-modal";
import { DefaultAvatar } from "../../shared/default-avatar/default-avatar";
import { ModalDialog } from "../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";
import type { ISearchText } from "../interfaces/search-text.interface";
import type { ISubscribersList } from "../my-subscribers/interfaces/my-subscribers-list.interface";
import type { IManageGroup } from "./interfaces/manage-group.interface";
import styles from "./subscriber-groups.module.scss";

type SubscriberGroupsModalProp = {
  setIsDialogOpened: Dispatch<SetStateAction<boolean>>;
  isDialogOpened: boolean;
  onGroupUpdate: () => Promise<void>;
};

export const AddGroupModal: FC<SubscriberGroupsModalProp> = ({
  isDialogOpened,
  setIsDialogOpened,
  onGroupUpdate,
}) => {
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");

  const typingTimeout = useRef<any>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const { setManageSubscribers } = manageSubscribersModalSlice.actions;
  const { subscribers } = useAppSelector(
    (reducers) => reducers.manageSubscribersModalReducer
  );
  const [
    fetchSubscribers,
    {
      error: fetchSubscribersError,
      isLoading: fetchSubscribersLoading,
      data: subscribersData,
    },
  ] = useFetchSubscribersMutation();

  const [
    createGroup,
    {
      isLoading: isLoadingCreateGroup,
      isSuccess: isSuccessCreateGroup,
      error: createGroupError,
    },
  ] = useCreateGroupMutation();

  const containerElemRef = useRef<HTMLDivElement | null>(null);

  const formikGroup = useFormik<IManageGroup>({
    initialValues: {
      name: "",
      switches: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
    }),
    onSubmit: async (values: IManageGroup) => {
      if (formikGroup.errors.name) {
        return;
      }
      createGroup({
        accessToken: accessToken || "",
        name: values.name,
        user_ids: values.switches,
      });
    },
  });

  const formikSubscribers = useFormik({
    initialValues: {
      searchText,
    },
    onSubmit: async (values: ISearchText) => {
      setSearchText(values.searchText);
    },
  });

  const handleSearchTextChange = (e: Object) => {
    clearTimeout(typingTimeout.current);

    formikSubscribers.handleChange(e);

    typingTimeout.current = setTimeout(() => {
      formikSubscribers.submitForm();
    }, 500);
  };

  const getSubscribers = useCallback(async () => {
    try {
      await fetchSubscribers({
        accessToken: accessToken || "",
        rowsPerPage: 6,
        page: page + 1,
        direction: SortingDirection.asc,
        sortBy: "created_at",
        searchText,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [accessToken, fetchSubscribers, page, searchText]);

  useEffect(() => {
    if (fetchSubscribersError) {
      navigate("/");
    }

    if (!isDialogOpened) {
      formikGroup.resetForm();
      formikSubscribers.resetForm();
      setSearchText("");
      return;
    }

    getSubscribers();
  }, [getSubscribers, isDialogOpened]);

  useEffect(() => {
    if (subscribersData) {
      if (page === 0) {
        dispatch(setManageSubscribers(subscribersData.data));
        return;
      }

      dispatch(setManageSubscribers([...subscribers, ...subscribersData.data]));
    }
  }, [subscribersData]);

  useEffect(() => {
    containerElemRef.current?.scrollTo({
      top: containerElemRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [subscribers]);

  useEffect(() => {
    if (isSuccessCreateGroup) onGroupUpdate();
    setIsDialogOpened(false);
    dispatch(setManageSubscribers([]));
    setPage(0);
  }, [isSuccessCreateGroup]);

  useEffect(() => {
    if (createGroupError || fetchSubscribersError) {
      navigate("/");
    }
  }, [createGroupError, fetchSubscribersError]);

  useEffect(() => {
    dispatch(setManageSubscribers([]));
    setPage(0);
  }, [searchText]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const switches = checked
      ? [...formikGroup.values.switches, value]
      : formikGroup.values.switches.filter((item) => item !== value);

    formikGroup.setFieldValue("switches", switches);
  };

  return (
    <ModalDialog
      isOpen={isDialogOpened}
      classes={styles.modal}
      dataCy="add-group-modal"
      onClose={() => {
        setIsDialogOpened(false);
        dispatch(setManageSubscribers([]));
      }}
    >
      <ModalDialogHeader>
        <Typography variant="h5" color="#252733" fontWeight={400}>
          Create group
        </Typography>
      </ModalDialogHeader>
      <ModalDialogContent>
        <form
          className={styles.groupForm}
          noValidate
          onSubmit={formikGroup.handleSubmit}
        >
          <TextField
            placeholder="Enter group name"
            type="text"
            fullWidth
            name="name"
            error={!!(formikGroup.touched.name && formikGroup.errors.name)}
            onBlur={formikGroup.handleBlur}
            onChange={formikGroup.handleChange}
            value={formikGroup.values.name}
            variant="outlined"
          />
          <TextField
            placeholder="Search SideFans"
            type="text"
            fullWidth
            name="searchText"
            onBlur={formikSubscribers.handleBlur}
            onChange={handleSearchTextChange}
            value={formikSubscribers.values.searchText}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchInCircleIcon height={24} width={24} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <div className={styles.subscriberList} ref={containerElemRef}>
              {subscribers.length > 0 &&
                subscribers
                  .filter((row) => row.subscriber)
                  .map((row: ISubscribersList) => (
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      key={row.id}
                      className={styles.subscriberLabel}
                      control={
                        <Switch
                          sx={{ mt: 1, mb: 1 }}
                          onChange={handleSwitchChange}
                          checked={formikGroup.values.switches.includes(
                            row.subscriber_id
                          )}
                        />
                      }
                      label={
                        <div className={styles.subscriberInfo}>
                          {row.subscriber?.avatar ? (
                            <Avatar
                              alt={row.subscriber.username}
                              src={row.subscriber.avatar.backend_media_url}
                              sx={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <Avatar
                              alt={row.subscriber?.username}
                              sx={{ width: 32, height: 32 }}
                            >
                              <DefaultAvatar width={32} height={32} />
                            </Avatar>
                          )}
                          <Typography variant="body1">
                            {row.subscriber?.username}
                          </Typography>
                        </div>
                      }
                      labelPlacement="start"
                      value={row.subscriber?.id}
                    />
                  ))}
            </div>
            {subscribersData?.total !== subscribers.length && (
              <div className={styles.showMoreWrapper}>
                <Button
                  sx={{ padding: 0 }}
                  disableRipple
                  component={LinkButton}
                  variant="text"
                  onClick={() => {
                    setPage((prev) => prev + 1);
                  }}
                >
                  <Typography className={styles.showMore} variant="body1">
                    <span style={{ display: "block" }}>Show more</span>{" "}
                    <ArrowDown color="blue" />
                  </Typography>
                </Button>
              </div>
            )}
          </FormControl>
        </form>
      </ModalDialogContent>
      <ModalDialogFooter>
        <div className={styles.modalFooter}>
          <Button
            color="primary"
            className={styles.btn}
            variant="contained"
            type="button"
            disabled={!formikGroup.values.name || !!formikGroup.errors.name}
            onClick={async () => {
              await formikGroup.submitForm();
            }}
          >
            <Typography variant="body1">Save</Typography>
          </Button>
          <Button
            color="primary"
            className={styles.btn}
            variant="outlined"
            type="button"
            onClick={() => {
              setIsDialogOpened(false);
              dispatch(setManageSubscribers([]));
              setPage(0);
            }}
            sx={{ borderRadius: "12px" }}
          >
            <Typography variant="body1">Cancel</Typography>
          </Button>
        </div>
        {(fetchSubscribersLoading || isLoadingCreateGroup) && (
          <LinearProgress style={{ width: "100%", marginTop: "5px" }} />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};
