import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import type { FC } from "react";

import styles from "../info.module.scss";
import type { ISocialInfo } from "../interfaces/personal-info.interface";

interface SocialMediaProps {
  onFormikValueUpdate: (formikKey: string, formikValue: string | null) => void;
  linksDisplay: ISocialInfo[];
}

export const SocialMedia: FC<SocialMediaProps> = ({
  onFormikValueUpdate,
  linksDisplay,
}) => {
  const formikKey = "info_on_update.links";

  return (
    <Accordion
      sx={{
        marginTop: "12px",

        "&.Mui-expanded": {
          marginTop: "12px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Typography variant="button" fontWeight="bold">
          Social media
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        <Box className={styles.details}>
          {linksDisplay.map((info, index) => (
            <Box
              key={info.name}
              className={styles.detail}
              sx={{
                marginBottom:
                  index === linksDisplay.length - 1 ? "20px" : "inherit",
              }}
            >
              <Typography variant="body1" width="65%">
                {info.title}:
              </Typography>
              <Box className={styles.inputArea}>
                <TextField
                  name={info.name}
                  placeholder={info.placeholder}
                  defaultValue={info.value}
                  fullWidth
                  onChange={(e) => {
                    onFormikValueUpdate(
                      `${formikKey}.${info.name}`,
                      e.target.value === "" ? null : e.target.value
                    );
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
