export default function useRoundCurrency() {
  const roundAmount = (amount: number, fractionDigits: number) => {
    if (amount === 0) {
      return "0.".padEnd(fractionDigits + 2, "0");
    }

    return amount.toFixed(fractionDigits);
  };

  return {
    roundAmount,
  };
}
