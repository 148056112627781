import { Box, Checkbox, Typography } from "@mui/material";
import { FC } from "react";

import { Content } from "../../../../../../../services/catalog/interfaces/catalog.interfaces";
import { Video } from "../../../../../../shared/video/video";
import styles from "./select-collection-item.module.scss";

interface ISelectCollectionItem {
  content: Content;
  selectedCollections: Content[];
  handleCheckboxClick: (selectedCollection: Content) => void;
}

export const SelectCollectionItem: FC<ISelectCollectionItem> = ({
  content,
  selectedCollections,
  handleCheckboxClick,
}) => {
  const isContentChecked = !!selectedCollections.find(
    (item) => item.id === content.id
  );

  return (
    <Box
      className={styles.contentCardWrapper}
      sx={{
        border: isContentChecked
          ? "2px solid #3A75E9"
          : "2px solid rgba(0,0,0,0)",
      }}
    >
      <Box className={styles.header}>
        <Box className={styles.checkboxWrapper}>
          <Typography className={styles.title}>{content.name}</Typography>
          <Checkbox
            name="selectContent"
            checked={isContentChecked}
            onChange={() => handleCheckboxClick(content)}
          />
        </Box>
      </Box>
      <Box className={styles.bodyContentWrapper}>
        <div className={styles.contentMediaWrapper}>
          <Box
            sx={{
              display: "inline-flex",
              width: "100%",
            }}
          >
            {content.content_media &&
              content.content_media.slice(0, 4).map((item, index) => (
                <div
                  key={index}
                  className={styles.media}
                  style={{
                    display: "flex",
                    zIndex: 10 - index,
                    marginLeft: "0px",
                    width: "fit-content",
                    position: index === 0 ? "inherit" : "absolute",
                    left: index * 27,
                    top: index * 19,
                    boxShadow: "3px 4px 4px 0px #393B3F40",
                  }}
                >
                  {item.media &&
                    item.media.backend_media_url &&
                    (item.media?.type?.includes("video") ? (
                      <Box
                        className={styles.mediaItem}
                        sx={{
                          width: "219px",
                          height: "219px",
                        }}
                      >
                        <Video
                          showDuration
                          showControls={false}
                          videoUrl={item.media.backend_media_url}
                          muted
                          isPlaying={false}
                        />
                      </Box>
                    ) : (
                      <img
                        className={styles.mediaItem}
                        src={item.media.backend_media_url}
                        alt={item.media.title}
                        style={{
                          width: "219px",
                          height: "219px",
                        }}
                      />
                    ))}
                </div>
              ))}
          </Box>
          {content.content_media && content.content_media.length > 4 && (
            <div className={styles.overlay}>
              <Typography fontSize="26px">
                +{content.content_media.length - 4}
              </Typography>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};
