import type { ITableHead } from "../../../interfaces/table-head.interface";

export const invitationsTableHead: ITableHead[] = [
  {
    display_text: "№",
    value: "created_at",
    sortable: true,
    default_sort: true,
    headWidth: 10,
  },
  {
    display_text: "Email",
    value: "email",
    sortable: false,
    default_sort: false,
    headWidth: 40,
  },
  {
    display_text: "Status",
    value: "status",
    sortable: true,
    default_sort: false,
    headWidth: 10,
  },
  {
    display_text: "",
    value: "action",
    sortable: false,
    default_sort: false,
    headWidth: 40,
  },
];
