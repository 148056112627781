import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { REGEX } from "../../constants/regex/regex";
import useSubmitState from "../../hooks/submit-state";
import { VisibilityOff } from "../../media/icons/visibility-off";
import { useCheckVerificationMutation } from "../../services/get-verification/check-verification.service";
import { useResetPasswordMutation } from "../../services/reset-password/reset-password.service";
import PasswordValidationPopover from "../shared/popovers/password-validation-popover/password-validation-popover";
import { UnauthorizedWrapper } from "../shared/unauthorized-wrapper/unauthorized-wrapper";
import type { ResetPasswordInterface } from "./interfaces/reset-password.interface";
import styles from "./reset-password.module.scss";

export default function ResetPassword() {
  // eslint-disable-next-line prefer-destructuring
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [showPassword, setShowPassword] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const verificationId = searchParams.get("verification_id");
  const [resetPasswordSlice, { isLoading, isSuccess, error, reset }] =
    useResetPasswordMutation();
  const [
    checkVerificationSlice,
    { isLoading: verificationLoading, isSuccess: verificationSuccess },
  ] = useCheckVerificationMutation();

  const checkVerification = useCallback(async () => {
    await checkVerificationSlice({ id: verificationId || "" });
  }, [verificationId, checkVerificationSlice]);

  const { text, color, errorCode } = useSubmitState({
    requestError: error as FetchBaseQueryError,
    defaultText: "Reset",
    successText: "Done!",
    isRequestSuccess: isSuccess,
  });

  useEffect(() => {
    checkVerification();
  }, [verificationId, checkVerification]);

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      submit: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(255)
        .matches(REGEX.PASSWORD.GENERAL, {
          message: "Password doesn't meet all rules",
        })
        .required("Password doesn't meet all rules"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password mismatch")
        .required("Password mismatch"),
    }),
    onSubmit: async (values: ResetPasswordInterface) => {
      if (isLoading || isSuccess) {
        return;
      }
      await resetPasswordSlice({
        verification_id: searchParams.get("verification_id") || "",
        password: values.password,
      }).unwrap();

      navigate("/sign-in");
    },
  });

  useEffect(() => {
    if (!formik.errors.password) {
      setAnchorRef(null);
      return;
    }

    setAnchorRef(inputRef.current);
  }, [formik.errors]);

  const isOpen =
    Boolean(formik.errors.password) && Boolean(anchorRef) && isFieldFocused;

  const renderComponent = () => {
    if (!verificationSuccess) {
      return <Typography>Verification not found!</Typography>;
    }
    return (
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className={styles.cardContent}
        autoComplete="off"
      >
        <section>
          <TextField
            placeholder="Enter new password"
            type={showPassword ? "text" : "password"}
            error={!!(formik.touched.password && formik.errors.password)}
            fullWidth
            name="password"
            onBlur={(e) => {
              formik.handleBlur(e);
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
            ref={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onShowPassword}>
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && (
            <Typography color="error" variant="caption">
              {formik.errors.password}
            </Typography>
          )}
        </section>
        <section>
          <TextField
            placeholder="Confirm password"
            type={showPassword ? "text" : "password"}
            error={
              !!(
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              )
            }
            fullWidth
            name="confirm_password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onShowPassword}>
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirm_password &&
            formik.touched.confirm_password && (
              <Typography color="error" variant="caption">
                {formik.errors.confirm_password}
              </Typography>
            )}
          {formik.errors.submit && (
            <Typography color="error" variant="caption">
              {formik.errors.submit}
            </Typography>
          )}
        </section>
        <Button
          disabled={!formik.isValid || !formik.dirty}
          variant="contained"
          type={color === "primary" ? "submit" : "button"}
          color={color}
          onClick={(e) => {
            if (color === "error") {
              e.preventDefault();
              reset();
            }

            if (color === "success") {
              e.preventDefault();
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="1.6rem" />
          ) : (
            <Typography variant="button">{text}</Typography>
          )}
        </Button>
        {errorCode && (
          <Typography variant="body1" color="error" mt={1} textAlign="center">
            Oops... Something went wrong. Error {errorCode}
          </Typography>
        )}
        <Typography textAlign="left">
          Need Help? <Link to="/">Contact SideFans Support</Link>
        </Typography>
      </form>
    );
  };

  return (
    <UnauthorizedWrapper>
      <div className={styles.cardWrapper}>
        <Typography
          color="#3C3C3C"
          variant="h4"
          fontWeight={400}
          textAlign="left"
        >
          Reset password
        </Typography>

        {verificationLoading ? <LinearProgress /> : renderComponent()}
      </div>
      <PasswordValidationPopover
        anchorEl={anchorRef}
        open={isOpen}
        password={formik.values.password}
      />
    </UnauthorizedWrapper>
  );
}
