import { Box } from "@mui/material";
import { FC } from "react";

import { useAppSelector } from "../../../../../../hooks/redux";
import { BitcoinTextIcon } from "../../../../../../media/icons/bitcoin-text";
import { CurrenciesEnum } from "../../../../../automatic-transfers/enums/currencies.enum";
import styles from "./render-exchange-rate.module.scss";

interface IRenderExchangeRateProps {
  currency: CurrenciesEnum;
  amount: number;
}

export const RenderExchangeRate: FC<IRenderExchangeRateProps> = ({
  amount,
  currency,
}) => {
  const { user } = useAppSelector((item) => item.userReducer);

  const renderPrice = (
    price: number,
    curr: CurrenciesEnum,
    signColor: string
  ) => {
    if (Number.isNaN(Number(price))) return "0.00";

    switch (curr) {
      case CurrenciesEnum.USD: {
        return `$${Math.floor(Number(price) * 100) / 100}`;
      }
      case CurrenciesEnum.EURO: {
        return `€${Math.floor(Number(price) * 100) / 100}`;
      }
      case CurrenciesEnum.BTC: {
        return (
          <>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0px",
              }}
            >
              <Box
                sx={{
                  paddingTop: "4px",
                }}
              >
                <BitcoinTextIcon color={signColor} width={13} height={13} />
              </Box>
              <span style={{ display: "block" }}>
                {Math.floor(Number(price) * 100000) / 100000}
              </span>
            </Box>
          </>
        );
      }
      default: {
        return "";
      }
    }
  };

  const renderExchangeRate = () => {
    if (user) {
      // If BTC IS SELECTED START
      if (
        currency === CurrenciesEnum.BTC &&
        !user.accept_btc &&
        !user.accept_usd
      ) {
        const btcToEuro = Number(amount * Number(user.exchange_rate.btceur));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                btcToEuro,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (
        currency === CurrenciesEnum.BTC &&
        !user.accept_btc &&
        !user.accept_euro
      ) {
        const btcToUsd = Number(amount * Number(user.exchange_rate.btcusd));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                btcToUsd,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (currency === CurrenciesEnum.BTC && !user.accept_btc) {
        const btcToEuro = Number(amount * Number(user.exchange_rate.btceur));

        const btcToUsd = Number(amount * Number(user.exchange_rate.btcusd));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                btcToEuro,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>,&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                btcToUsd,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }
      // If BTC IS SELECTED END

      // If USD IS SELECTED START
      if (
        currency === CurrenciesEnum.USD &&
        !user.accept_usd &&
        !user.accept_btc
      ) {
        const usdToEuro = Number(amount * Number(user.exchange_rate.usdeur));

        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                usdToEuro,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (
        currency === CurrenciesEnum.USD &&
        !user.accept_usd &&
        !user.accept_euro
      ) {
        const usdToBtc = Number(amount * Number(user.exchange_rate.usdbtc));

        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                usdToBtc,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (currency === CurrenciesEnum.USD && !user.accept_usd) {
        const usdToEuro = Number(amount * Number(user.exchange_rate.usdeur));

        const usdToBtc = Number(amount * Number(user.exchange_rate.usdbtc));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                usdToEuro,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>,&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                usdToBtc,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }
      // If USD IS SELECTED END

      // If EURO IS SELECTED START
      if (
        currency === CurrenciesEnum.EURO &&
        !user.accept_euro &&
        !user.accept_usd
      ) {
        const euroToBtc = Number(amount * Number(user.exchange_rate.eurbtc));

        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                euroToBtc,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (
        currency === CurrenciesEnum.EURO &&
        !user.accept_euro &&
        !user.accept_btc
      ) {
        const euroToUsd = Number(amount * Number(user.exchange_rate.eurusd));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                euroToUsd,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }

      if (currency === CurrenciesEnum.EURO && !user.accept_euro) {
        const euroToUsd = Number(amount * Number(user.exchange_rate.eurusd));

        const euroToBtc = Number(amount * Number(user.exchange_rate.eurbtc));
        return (
          <Box className={styles.exchangeCurrency}>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                amount,
                CurrenciesEnum.EURO,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>&nbsp;=&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                euroToUsd,
                CurrenciesEnum.USD,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
            <Box className={styles.exchangeCurrencyColor}>,&nbsp;</Box>
            <Box className={styles.exchangeCurrencyColor}>
              {renderPrice(
                euroToBtc,
                CurrenciesEnum.BTC,
                "rgba(170, 170, 170, 1)"
              )}
            </Box>
          </Box>
        );
      }
      // If EURO IS SELECTED END
      return null;
    }
    return null;
  };

  return <>{renderExchangeRate()}</>;
};
