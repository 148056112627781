import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import type React from "react";

import { CountryType, countries } from "./countries";
import styles from "./select-country.module.scss";

type SelectProps = {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: any }>) => void;
  disabled?: boolean;
};

export default function CountrySelect(props: SelectProps) {
  const { value, onChange, disabled = false } = props;
  function findCountryByCode(code: string): CountryType | undefined {
    return countries.find((country) => country.code === code);
  }
  const country = findCountryByCode(value);

  return (
    <Autocomplete
      id="country"
      fullWidth
      value={country}
      onChange={(e: any, eValue: any) => {
        const event = {
          ...e,
          target: { ...e.target, name: "country", value: eValue.code },
        };

        return onChange(event);
      }}
      options={countries}
      autoHighlight
      disableClearable
      disabled={disabled}
      ListboxProps={{
        className: styles.menu,
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(option) => option.label}
      renderOption={(properties, option) => (
        <Box
          component="li"
          value={option.code}
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...properties}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select country"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
