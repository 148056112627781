import {
  Button,
  Card,
  CircularProgress,
  Fade,
  Link,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  enterDuration,
  exitDuration,
} from "../../../constants/animations/fade";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { RoleEnum } from "../../../enums/role.enum";
import { BackIcon } from "../../../media/icons/back";
import { useBecomeCreatorMutation } from "../../../services/settings/settings.service";
import AgeVerification from "../../age-verification/age-verification";
import { AgeVerification as AgeVerificationStep } from "../../sign-up/components/age-verification/age-verification";
import { Agreement } from "../../sign-up/components/agreement/agreement";
import { SignUpCard } from "../../sign-up/components/sign-up-card/sign-up-card";
import type {
  AgeVerificationType,
  AgreementType,
  RoleType,
} from "../../sign-up/types/sign-up.types";
import styles from "./become-creator.module.scss";

interface IBecomeCreatorProp {
  onClose: () => void;
}

export const BecomeCreator: FC<IBecomeCreatorProp> = ({ onClose }) => {
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );
  const containerElemRef = useRef<HTMLDivElement | null>(null);
  const [agreement, setAgreement] = useState<AgreementType>({
    status: "none",
    value: false,
  });
  const [role] = useState<RoleType>({
    status: "valid",
    value: RoleEnum.creator,
    isLater: false,
  });
  const [ageVerification, setAgeVerification] = useState<AgeVerificationType>({
    status: "none",
    value: false,
  });
  const [ageVerificationStep, setAgeVerificationStep] =
    useState<boolean>(false);
  const [inquiryId, setInquiryId] = useState("");

  const [
    becomeCreator,
    {
      isLoading: isBecomeCreatorLoading,
      error: becomeCreatorError,
      isSuccess: isBecomeCreatorSuccess,
    },
  ] = useBecomeCreatorMutation();

  const onChangeRole = async () => {
    await becomeCreator({
      accessToken: accessToken || "",
      creator_agreement: agreement.value && role.value === RoleEnum.creator,
      inquiry_id: inquiryId || "inquiryIdPromptPlaceHolder123",
    }).unwrap;
  };

  useEffect(() => {
    if (!agreement.value && agreement.status === "valid") {
      onClose();
    }
  }, [agreement]);

  const ageVerificationBackButton = () => (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        style={{ color: "#0F70CA" }}
        onClick={() => {
          setAgeVerificationStep(false);
        }}
      >
        Back
      </Link>
    </>
  );

  useEffect(() => {
    if (isBecomeCreatorSuccess) {
      navigate("/sign-in");
    }
  }, [isBecomeCreatorSuccess]);

  return (
    <>
      {ageVerificationStep ? (
        <AgeVerification
          setAgeVerification={setAgeVerification}
          setAgeVerificationStep={setAgeVerificationStep}
          setInquiryId={setInquiryId}
          backBtn={ageVerificationBackButton()}
        />
      ) : (
        <Card
          className={styles.becomeCreatorCardWrapper}
          sx={{
            padding: "0",
            borderRadius: "32px",
            display: "flex",
          }}
        >
          <header className={styles.header}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className={styles.backToSettings} onClick={onClose}>
              <BackIcon width={15} height={15} />
              <Typography style={{ color: "#0F70CA" }}>
                Back to Settings
              </Typography>
            </Link>
          </header>
          <div
            className={styles.becomeCreatorContent}
            ref={(el) => {
              containerElemRef.current = el;
            }}
          >
            <div style={{ width: "100%" }}>
              <Agreement
                setAgreement={setAgreement}
                agreement={agreement}
                isAgreementValid={agreement.status === "valid"}
                role={role.value}
              />
            </div>

            <Fade
              timeout={{ enter: enterDuration, exit: exitDuration }}
              in={
                agreement.status === "valid" &&
                role.value === RoleEnum.creator &&
                agreement.value
              }
              onExited={() =>
                setAgeVerification({
                  status: "none",
                  value: false,
                })
              }
              unmountOnExit
            >
              <div style={{ width: "100%" }}>
                <AgeVerificationStep
                  setAgeVerificationStep={setAgeVerificationStep}
                  isAgeVerified={ageVerification.status === "valid"}
                />
              </div>
            </Fade>

            <Fade
              timeout={{ enter: enterDuration, exit: exitDuration }}
              in={ageVerification.status === "valid"}
              unmountOnExit
            >
              <div style={{ width: "100%" }}>
                <SignUpCard status="default">
                  <div className={styles.cardContent}>
                    <form
                      className={styles.cardForm}
                      noValidate
                      style={{ padding: "5px 26px 0 26px" }}
                    >
                      <Button
                        color="primary"
                        className={styles.actionBtnStretch}
                        variant="contained"
                        type="button"
                        onClick={onChangeRole}
                        disabled={isBecomeCreatorLoading}
                      >
                        {isBecomeCreatorLoading && (
                          <CircularProgress color="inherit" size="1rem" />
                        )}
                        <Typography variant="button">Become creator</Typography>
                      </Button>
                    </form>
                  </div>
                  {becomeCreatorError && (
                    <Typography color="error" variant="caption" marginTop="4px">
                      Something went wrong. Please try again!
                    </Typography>
                  )}
                </SignUpCard>
              </div>
            </Fade>
          </div>
        </Card>
      )}
    </>
  );
};
