import { Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";

import { enterDuration, exitDuration } from "../../constants/animations/fade";
import BankAccount from "./bank-account/bank-account";
import BankCard from "./bank-card/bank-card";
import CryptoWallet from "./crypto-wallet/crypro-wallet";
import { PaymentMethodTypes } from "./enums";
import type { CollectedData } from "./interfaces/collected-data.interface";
import PaymentMethodOptions from "./payment-method-options/payment-method-options";
import styles from "./select-payment-method.module.scss";

interface AddPaymentMethodProps {
  open: boolean;
  onClose: () => void;
  setSelectedStep: (value: string | ((prevVar: string) => string)) => void;
  selectedMethods: string[];
  selectedStep: string;
  setCollectedData: (
    value: CollectedData | ((prevVar: CollectedData) => CollectedData)
  ) => void;
  collectedData: CollectedData;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
  edit?: boolean;
  signUp?: boolean;
  transferAccount?: boolean;
}

export default function AddPaymentMethod(props: AddPaymentMethodProps) {
  const {
    open,
    onClose,
    edit = false,
    collectedData,
    selectedMethods,
    selectedStep,
    setCollectedData,
    setSelectedMethods,
    setSelectedStep,
    signUp = false,
    transferAccount = false,
  } = props;

  const [, setIsProccesing] = useState(false);

  useEffect(() => {
    const priorityData = [
      {
        type: "creditCard",
        priority: 1,
      },
      {
        type: "bankAccount",
        priority: 2,
      },
      {
        type: "crypto",
        priority: 3,
      },
    ];
    const compareMethods = (a: any, b: any) => {
      const priorityA = priorityData.find((item) => item.type === a) || {
        type: "creditCard",
        priority: 1,
      };
      const priorityB = priorityData.find((item) => item.type === b) || {
        type: "creditCard",
        priority: 1,
      };
      return priorityA.priority - priorityB.priority;
    };

    selectedMethods.sort(compareMethods);

    setSelectedMethods(selectedMethods.sort(compareMethods));
  }, [selectedMethods, setSelectedMethods]);

  const onCloseDialog = useCallback(() => {
    if (!signUp) {
      setCollectedData({});
    }
    setSelectedMethods([]);
    setSelectedStep("");
    onClose();
  }, [onClose, edit]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onCloseDialog();
      }}
    >
      <Box className={styles.modalWrapper}>
        <Box borderRadius="24px">
          <Fade
            in={open}
            timeout={{ enter: enterDuration, exit: exitDuration }}
            unmountOnExit
          >
            <Box className={styles.contentWrapper}>
              {selectedMethods.length ? null : (
                <PaymentMethodOptions
                  onClose={onCloseDialog}
                  setSelectedMethods={setSelectedMethods}
                  setSelectedStep={setSelectedStep}
                  transferAccount={transferAccount}
                />
              )}
              {selectedStep === PaymentMethodTypes.CREDIT_CARD &&
              selectedMethods.length ? (
                // transferAccount prop is not used in bank card because User can not add credit card as transfer account
                <BankCard
                  selectedMethods={selectedMethods}
                  selectedStep={selectedStep}
                  setActiveStep={setSelectedStep}
                  collectedData={collectedData}
                  setCollectedData={setCollectedData}
                  setSelectedMethods={setSelectedMethods}
                  onClose={onCloseDialog}
                  setIsProccesing={setIsProccesing}
                  edit={edit}
                  signUp={signUp}
                />
              ) : null}
              {selectedStep === PaymentMethodTypes.BANK_ACCOUNT &&
              selectedMethods.length ? (
                <BankAccount
                  selectedMethods={selectedMethods}
                  selectedStep={selectedStep}
                  setActiveStep={setSelectedStep}
                  collectedData={collectedData}
                  setCollectedData={setCollectedData}
                  setSelectedMethods={setSelectedMethods}
                  onClose={onCloseDialog}
                  edit={edit}
                  setIsProccesing={setIsProccesing}
                  signUp={signUp}
                  transferAccount={transferAccount}
                />
              ) : null}
              {selectedStep === PaymentMethodTypes.CRYPTO &&
              selectedMethods.length ? (
                <CryptoWallet
                  selectedMethods={selectedMethods}
                  selectedStep={selectedStep}
                  setActiveStep={setSelectedStep}
                  collectedData={collectedData}
                  setCollectedData={setCollectedData}
                  setSelectedMethods={setSelectedMethods}
                  onClose={onCloseDialog}
                  edit={edit}
                  setIsProccesing={setIsProccesing}
                  signUp={signUp}
                  transferAccount={transferAccount}
                />
              ) : null}
            </Box>
          </Fade>
        </Box>
      </Box>
    </Modal>
  );
}
