export const MasterCardIconLarge = ({
  width = 48,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="20" rx="4" fill="white" />
    <g opacity="0.01">
      <rect x="3.10156" y="2" width="25.8067" height="16" fill="white" />
    </g>
    <rect
      x="12.6016"
      y="4.7876"
      width="6.80667"
      height="10.425"
      fill="#F26122"
    />
    <path
      d="M13.3338 10C13.3226 7.97096 14.3056 6.04941 16.0005 4.78751C13.1007 2.64957 8.93512 2.96002 6.43201 5.50063C3.92891 8.04125 3.92891 11.9588 6.43201 14.4994C8.93512 17.04 13.1007 17.3504 16.0005 15.2125C14.3056 13.9506 13.3226 12.029 13.3338 10Z"
      fill="#EA1D25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7622 13.8938V14.1063H26.7222V13.8938H26.6289V13.8501H26.8622V13.8938H26.7622ZM27.2156 13.8501V14.1063H27.1689V13.9126L27.0956 14.0813H27.0422L26.9622 13.9126V14.1063H26.9156V13.8501H26.9822L27.0689 14.0313L27.1489 13.8501H27.2156Z"
      fill="#F69E1E"
    />
    <path
      d="M27.44 10.0001C27.4383 12.5372 25.8926 14.851 23.4586 15.96C21.0246 17.069 18.129 16.7788 16 15.2126C19.0667 12.9488 19.5978 8.78402 17.1867 5.90633C16.841 5.48963 16.4425 5.11394 16 4.78758C18.129 3.22133 21.0246 2.93117 23.4586 4.04019C25.8926 5.14921 27.4383 7.46299 27.44 10.0001Z"
      fill="#F69E1E"
    />
  </svg>
);
