import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { IUserProfile } from "../../../services/sign-in/interfaces/initialize.interface";

interface IUserState {
  user: IUserProfile | null;
  isAuthenticated: boolean;
  error: string;
}

const initialState: IUserState = {
  user: null,
  isAuthenticated: false,
  error: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initialize(state, action: PayloadAction<IUserProfile | null>) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setCurrencyAccept(
      state,
      action: PayloadAction<{
        type: "accept_euro" | "accept_btc" | "accept_usd";
      }>
    ) {
      if (state.user) {
        state.user[action.payload.type] = !state.user[action.payload.type];
      }
    },
    signOut(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export default userSlice.reducer;
