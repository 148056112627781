import type { IUserAvatar } from "../../sign-in/interfaces/initialize.interface";

export enum LastSearchType {
  CREATORS_SEARCH = "creatorsSearch",
  CONTENT_SEARCH = "contentSearch",
  MY_CATALOG_SEARCH = "myCatalogSearch",
}

export interface IBasicParamsResponse {
  message: string;
  status: number;
  success: boolean;
}

export interface IIdentifierParams {
  accessToken: string;
  id: string;
}

export interface IGetCreatorsParams {
  accessToken: string;
  rowsPerPage: number;
  page: number;
  searchText?: string;
}

export interface IBaseParams {
  accessToken: string;
}

export interface ILastSearchParam extends IBaseParams {
  type: LastSearchType;
}

interface ICreatorPopularity {
  interested_users: number;
  subscribers: number;
}

// Item to display in modal
export interface ICreatorCardData {
  id: string;
  avatar: IUserAvatar | null;
  rank: number | null;
  username: string;
  greetings: IUserAvatar[] | null;
  tags: string[];
  _count: ICreatorPopularity;
  number_of_posts: number;
  new_content_count?: number;
  is_interested: boolean;
  is_subscribed: boolean;
  creators_amount: number;
}

// for Interested creators
export interface IInterestedCreatorItem {
  order?: number;
  new_content_count?: number;
}

// for subscribed creators
export interface ISubscribedCreatorItem {
  order?: number;
}

// for creator by id
export interface ICreatorByIdItem {
  creators_amount?: number;
}

// Item to display in the home page
export interface IHomeCreatorCard
  extends IInterestedCreatorItem,
    ICreatorByIdItem,
    ISubscribedCreatorItem {
  id: string;
  creator: ICreatorCardData;
}

// Get top ranked
export interface IGetTopRankedCreatorsResponse {
  message: string;
  status: number;
  success: boolean;
  total: number;
  data: IHomeCreatorCard[];
}

// Get interested
export interface IGetInterestedCreatorsResponse {
  message: string;
  status: number;
  success: boolean;
  total: number;
  contentCount: number;
  data: IHomeCreatorCard[];
}

// Get interested
export interface IGetNewestCreatorsResponse {
  message: string;
  status: number;
  success: boolean;
  total: number;
  data: IHomeCreatorCard[];
}

// Get subscribed
export interface IGetSubscribedCreatorsResponse {
  message: string;
  status: number;
  success: boolean;
  total: number;
  contentCount: number;
  data: IHomeCreatorCard[];
}

// Get subscribed
export interface IGetSearchedCreatorsResponse {
  message: string;
  status: number;
  success: boolean;
  total: number;
  data: IHomeCreatorCard[];
}

export interface ILastSearchItem {
  id: string;
  text: string;
  new_creators_count?: number;
}

export interface IGetLastSearchParamsResponse {
  message: string;
  status: number;
  success: boolean;
  data: ILastSearchItem[];
}

export interface IContentCount {
  subscribed_content_count: number | null;
  interested_content_count: number | null;
}

export interface IGetContentCountResponse {
  message: string;
  status: string;
  success: string;
  data: IContentCount;
}

export interface IPatchOrderResponse {
  message: string;
  status: string;
  success: string;
  data: {
    id: string;
    order: number;
  };
}

export interface IPatchOrderParams {
  id: string;
  order: number;
  accessToken: string;
}
