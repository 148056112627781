import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { FormikHelpers } from "formik";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import useSubmitState from "../../../hooks/submit-state";
import { BackIcon } from "../../../media/icons/back";
import { CrossIcon } from "../../../media/icons/cross";
import {
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} from "../../../services/payment-methods/payment-methods.service";
import { paymentMethodReducer } from "../../../store/reducers/payment-methods/payment-methods";
import CountrySelect from "../../shared/select-country/select-country";
import StateSelect from "../../shared/select-state/select-state";
import type { BankCardInterface } from "../interfaces/bank-card.interface";
import type { CollectedData } from "../interfaces/collected-data.interface";
import styles from "../select-payment-method.module.scss";

interface BankCardProprs {
  setActiveStep: (value: string | ((prevVar: string) => string)) => void;
  selectedMethods: string[];
  selectedStep: string;
  setCollectedData: (
    value: CollectedData | ((prevVar: CollectedData) => CollectedData)
  ) => void;
  collectedData: CollectedData;
  setSelectedMethods: (
    value: string[] | ((prevVar: string[]) => string[])
  ) => void;
  onClose: () => void;
  edit?: boolean;
  setIsProccesing: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  signUp?: boolean;
}

export default function BankCard(props: BankCardProprs) {
  const {
    selectedMethods,
    selectedStep,
    setActiveStep,
    setCollectedData,
    collectedData,
    setSelectedMethods,
    onClose,
    edit,
    setIsProccesing,
    signUp,
  } = props;
  const location = useLocation();
  const [headerMessage, setHeaderMessage] = useState("");
  const [createPaymentMethod, { isLoading }] = useCreatePaymentMethodMutation();

  const [
    updatePaymentMethod,
    {
      isLoading: updatingPaymentMethod,
      error: updatingPaymentMethodError,
      isSuccess: updatingPaymentMethodSuccess,
      reset: updatingPaymentMethodReset,
    },
  ] = useUpdatePaymentMethodMutation();

  const { paymentMethods } = useAppSelector(
    (state) => state.paymentMethodReducer
  );

  const { setPaymentMethods, updateMethod } = paymentMethodReducer.actions;

  const dispatch = useAppDispatch();
  const { text, color, errorCode } = useSubmitState({
    requestError: updatingPaymentMethodError as FetchBaseQueryError,
    defaultText: "Save",
    successText: "Saved",
    isRequestSuccess: updatingPaymentMethodSuccess,
  });

  let index = 0;

  const bankCardInitialValues = {
    country: collectedData.credit_card?.country
      ? collectedData.credit_card.country
      : "",
    state: collectedData.credit_card?.state
      ? collectedData.credit_card.state
      : "",
    street: collectedData.credit_card?.street
      ? collectedData.credit_card.street
      : "",
    city: collectedData.credit_card?.city ? collectedData.credit_card.city : "",
    zip: collectedData.credit_card?.zip ? collectedData.credit_card.zip : "",
    card_number: collectedData.credit_card?.card_number
      ? collectedData.credit_card.card_number
      : "",
    cvv: collectedData.credit_card?.cvv ? collectedData.credit_card.cvv : "",
    expiry_date: collectedData.credit_card?.expiry_date
      ? collectedData.credit_card.expiry_date
      : "",
    name: collectedData.credit_card?.name ? collectedData.credit_card.name : "",
    submit: null,
  };

  const bankCardValidationSchema = Yup.object({
    country: Yup.string().required("Please enter country"),
    state: Yup.string().when("country", ([country], schema) =>
      country === "US" ? schema.required("Please enter state") : schema.min(0)
    ),
    street: Yup.string().required("Please enter street"),
    city: Yup.string().required("Please enter city"),
    zip: Yup.string().required("Please enter zip"),
    card_number: Yup.string().required("Please enter card number"),
    cvv: Yup.string()
      .required("Please enter cvv")
      .matches(/^\d+$/, "Please write only number")
      .min(3)
      .max(3),
    expiry_date: Yup.string().required("Please enter expiry date"),
  });

  const formik = useFormik({
    initialValues: bankCardInitialValues,
    validationSchema: bankCardValidationSchema,
    onSubmit: async (
      values: BankCardInterface,
      helpers: FormikHelpers<BankCardInterface>
    ) => {
      try {
        const {
          card_number: cardNumber,
          city,
          country,
          expiry_date: expirationDate,
          cvv,
          state,
          street,
          zip,
          name,
        } = values;

        collectedData.credit_card = {
          card_number: cardNumber,
          city,
          country,
          expiry_date: expirationDate,
          cvv,
          state,
          street,
          zip,
          name,
        };

        if (isLoading) {
          return;
        }

        const accessToken = window.localStorage.getItem(
          LOCAL_STORAGE_KEYS.ACCESS_TOKEN
        );

        if (collectedData.credit_card.card_number.startsWith("4")) {
          collectedData.credit_card.card_type = "visa";
        } else {
          collectedData.credit_card.card_type = "mastercard";
        }

        setCollectedData(collectedData);

        index =
          selectedMethods.findIndex((method) => method === selectedStep) + 1;

        if (selectedMethods.length === index) {
          setIsProccesing(true);
          collectedData.accessToken = accessToken || "";

          if (signUp) {
            onClose();
            return;
          }

          if (edit) {
            const { data } = await updatePaymentMethod({
              id: collectedData.method_id || "",
              accessToken: accessToken || "",
              data: {
                credit_card: collectedData.credit_card,
              },
            }).unwrap();

            if (data) {
              dispatch(
                updateMethod({
                  id: collectedData.method_id || "",
                  method: data,
                })
              );
            }
            setSelectedMethods([]);
            setActiveStep("");
            setCollectedData({});
            setIsProccesing(false);
            onClose();

            return;
          }

          const { data } = await createPaymentMethod(collectedData).unwrap();

          if (data) {
            dispatch(setPaymentMethods([...data, ...paymentMethods]));
          }
          setSelectedMethods([]);
          setActiveStep("");
          setCollectedData({});
          onClose();
          return;
        }

        setActiveStep(selectedMethods[index]);
      } catch (err: any) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.data.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleGoBack = () => {
    if (isLoading || updatingPaymentMethod) {
      return;
    }
    index = selectedMethods.findIndex((method) => method === selectedStep) - 1;

    if (index < 0) {
      setSelectedMethods([]);
      return;
    }

    setActiveStep(selectedMethods[index]);
  };

  useEffect(() => {
    function findStepBefore(): string | undefined {
      for (let i = 0; i < selectedMethods.length; i += 1) {
        if (selectedMethods[i] === selectedStep) {
          return i > 0 ? selectedMethods[i - 1] : undefined;
        }
      }
      return undefined;
    }

    if (selectedMethods.findIndex((method) => method === selectedStep) === 0) {
      if (signUp) {
        setHeaderMessage(" to payment method");
      } else if (location.pathname.includes("payment-info")) {
        setHeaderMessage(" to Payment info");
      }
    } else {
      switch (findStepBefore()) {
        case "creditCard":
          setHeaderMessage(" to Add Visa/MC/AMEX/Discover");
          break;
        case "bankAccount":
          setHeaderMessage(" to Add Bank account");
          break;
        case "crypto":
          setHeaderMessage(" to Add Crypto wallet");
          break;
        default:
          setHeaderMessage("");
          break;
      }
    }
  }, [selectedMethods, selectedStep, signUp]);

  return (
    <>
      {edit ? (
        <header className={styles.header}>
          <Button
            className={styles.backToSignIn}
            onClick={onClose}
            sx={{ color: "#0F70CA", paddingLeft: 0 }}
          >
            <BackIcon width={15} height={15} />
            Back{headerMessage}
          </Button>
        </header>
      ) : (
        <header className={styles.header}>
          <Button
            className={styles.backToSignIn}
            onClick={handleGoBack}
            sx={{ color: "#0F70CA", paddingLeft: 0 }}
          >
            <BackIcon width={15} height={15} />
            Back{headerMessage}
          </Button>

          <Button className={styles.exitModal} onClick={onClose}>
            <CrossIcon />
          </Button>
        </header>
      )}

      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className={styles.scrollableForm}
      >
        <Box display="flex" flexDirection="column" gap="32px" padding="32px">
          <Box display="flex" gap="8px" alignItems="center">
            {selectedMethods.length > 1 && (
              <Box
                sx={{
                  backgroundColor: "#EBEBEB",
                  width: "32px",
                  height: "32px",
                  display: "inline-flex",
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="16px" fontWeight={500} color="#4C4B4C">
                  {selectedMethods.findIndex(
                    (method) => method === selectedStep
                  ) + 1}
                </Typography>
              </Box>
            )}
            <Typography fontSize="24px" fontWeight={400}>
              {edit ? "Edit" : "Add"} Visa/MC/AMEX/Discover
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            className={styles.cardStyles}
          >
            <Typography fontSize="16px">Payment information</Typography>
            <div>
              <TextField
                data-cy="methodName"
                placeholder="Account name"
                type="text"
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                sx={{ backgroundColor: "white", borderRadius: "8px" }}
              />
              <Typography
                color="info.dark"
                variant="caption"
                marginTop="4px"
                fontWeight={400}
              >
                Optional
              </Typography>
            </div>
            <Box display="flex" gap="16px">
              <Box width="261px">
                <CountrySelect
                  disabled={edit}
                  onChange={async (event) => {
                    formik.handleChange(event);
                    formik.setFieldError("state", "");
                    await formik.validateForm();
                    if (event.target.value !== "US") {
                      await formik.setFieldValue("state", "");
                    }
                  }}
                  value={formik.values.country}
                />
                {formik.errors.country && (
                  <Typography color="error" variant="caption" fontSize="12px">
                    {formik.errors.country}
                  </Typography>
                )}
              </Box>
              <Box width="261px">
                <StateSelect
                  disabled={edit || formik.values.country !== "US"}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />
                {formik.errors.state && (
                  <Typography color="error" variant="caption" fontSize="12px">
                    {formik.errors.state}
                  </Typography>
                )}
              </Box>
            </Box>
            <TextField
              variant="outlined"
              placeholder="Street"
              type="text"
              error={!!(formik.touched.street && formik.errors.street)}
              fullWidth
              name="street"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.street}
              sx={{ borderRadius: "8px" }}
              disabled={edit}
            />
            {formik.errors.street && (
              <Typography
                color="error"
                variant="caption"
                sx={{ width: "450px", marginTop: "-12px" }}
                fontSize="12px"
              >
                {formik.errors.street}
              </Typography>
            )}
            <Box display="flex" gap="16px">
              <Box width="261px">
                <TextField
                  variant="outlined"
                  placeholder="City"
                  type="text"
                  error={!!(formik.touched.city && formik.errors.city)}
                  fullWidth
                  name="city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  sx={{ borderRadius: "8px" }}
                  disabled={edit}
                />
                {formik.errors.city && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.city}
                  </Typography>
                )}
              </Box>
              <Box width="261px">
                <TextField
                  variant="outlined"
                  placeholder="ZIP code"
                  type="text"
                  error={!!(formik.touched.zip && formik.errors.zip)}
                  fullWidth
                  name="zip"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.zip}
                  sx={{ borderRadius: "8px" }}
                  disabled={edit}
                />
                {formik.errors.zip && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.zip}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography>Bank details</Typography>
            <TextField
              variant="outlined"
              placeholder="Card Number"
              type="text"
              error={
                !!(formik.touched.card_number && formik.errors.card_number)
              }
              fullWidth
              name="card_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.card_number
                .replace(/\s/g, "")
                .replace(/(\d{4})/g, "$1 ")
                .trim()}
              sx={{ borderRadius: "8px" }}
              disabled={edit}
            />
            {formik.errors.card_number && (
              <Typography
                color="error"
                variant="caption"
                sx={{ width: "450px", marginTop: "-12px" }}
                fontSize="12px"
              >
                {formik.errors.card_number}
              </Typography>
            )}
            <Box display="flex" gap="16px">
              <Box width="261px">
                <TextField
                  data-cy="expiration"
                  placeholder="Expiration date"
                  type="text"
                  error={
                    !!(formik.touched.expiry_date && formik.errors.expiry_date)
                  }
                  fullWidth
                  name="expiry_date"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let { value } = e.target;

                    // Remove non-numeric characters
                    value = value.replace(/\D/g, "");

                    // Check if the length is 1 and the value is greater than 1, add leading zero
                    if (value.length === 1 && Number(value) > 1) {
                      e.target.value = `0${value}`;
                    }

                    // Check if the length is 2 and value is greater than 12, limit it to 12
                    if (value.length === 2 && Number(value) > 12) {
                      e.target.value = "12";
                    }

                    // If the length is 3 or more, format as MM/YYYY (assuming MM/YYYY format)
                    if (value.length >= 3) {
                      e.target.value = `${value.substring(0, 2)}/${value.substring(2)}`;
                    }

                    if (value.length === 5) {
                      return;
                    }

                    // Pass the event to formik's handleChange
                    formik.handleChange(e);
                  }}
                  value={formik.values.expiry_date}
                  sx={{ backgroundColor: "white", borderRadius: "8px" }}
                />
                {formik.errors.expiry_date && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.expiry_date}
                  </Typography>
                )}
              </Box>
              <Box width="261px">
                <TextField
                  data-cy="cvv"
                  variant="outlined"
                  placeholder="CVV"
                  type="text"
                  error={!!(formik.touched.cvv && formik.errors.cvv)}
                  fullWidth
                  name="cvv"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (e.target.value.length === 4) {
                      return;
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.cvv}
                  sx={{ borderRadius: "8px" }}
                />
                {formik.errors.cvv && (
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ width: "450px", marginTop: "5px" }}
                    fontSize="12px"
                  >
                    {formik.errors.cvv}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              type={color === "primary" ? "submit" : "button"}
              variant="contained"
              fullWidth
              color={color}
              data-cy="save"
              onClick={(e) => {
                if (color === "error") {
                  e.preventDefault();
                  updatingPaymentMethodReset();
                }
              }}
            >
              {isLoading || updatingPaymentMethod ? (
                <CircularProgress color="inherit" size="1.6rem" />
              ) : (
                text
              )}
            </Button>
            {errorCode && (
              <Typography
                variant="body1"
                color="error"
                mt={1}
                textAlign="center"
              >
                Oops... Something went wrong. Error {errorCode}
              </Typography>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
}
