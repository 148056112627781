import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import { IPersonalInfo } from "../../../services/settings/interfaces/settings.interface";
import { BodyMeasurementNames } from "../../settings-cc/personal-info/body-measurements/body-measurement-info";
import { PersonalInformationNames } from "../../settings-cc/personal-info/personal-details/personal-information";
import { TastesNames } from "../../settings-cc/personal-info/tastes/tastes-info";

type PersonalInfo = {
  [key: string]: any;
};

interface IPersonalInfoDisplay {
  key: string;
  value: string;
  display_text: string;
}

interface ProfilePersonalInfoDisplayProp {
  personalInfoParameters: IPersonalInfo;
}

export const ProfilePersonalInfo: FC<ProfilePersonalInfoDisplayProp> = ({
  personalInfoParameters,
}) => {
  const [personalInfo, setPersonalInfo] = useState<IPersonalInfoDisplay[]>([]);
  const [bodyInfo, setBodyInfo] = useState<IPersonalInfoDisplay[]>([]);
  const [tastesInfo, setTastesInfo] = useState<IPersonalInfoDisplay[]>([]);

  const transformObjectToArray = (
    obj: PersonalInfo,
    enumNames: { [key: string]: string }
  ): IPersonalInfoDisplay[] =>
    Object.keys(obj)
      .map((key) => {
        const normalizedKey = key.replace(/_([a-z])/g, (match, letter) =>
          letter.toUpperCase()
        );
        const displayText =
          enumNames[normalizedKey as keyof typeof enumNames] ?? key;

        return {
          key,
          value: String(obj[key]),
          display_text: displayText,
        };
      })
      .filter((item) => item.value !== "null" && item.value !== "undefined");

  useEffect(() => {
    if (personalInfoParameters?.personal_info) {
      setPersonalInfo(
        transformObjectToArray(
          personalInfoParameters.personal_info,
          PersonalInformationNames
        )
      );
    }

    if (personalInfoParameters?.body_measurements) {
      setBodyInfo(
        transformObjectToArray(
          personalInfoParameters.body_measurements,
          BodyMeasurementNames
        )
      );
    }

    if (personalInfoParameters?.tastes) {
      setTastesInfo(
        transformObjectToArray(personalInfoParameters.tastes, TastesNames)
      );
    }
  }, [personalInfoParameters]);

  return (
    <>
      {(personalInfo.length > 0 ||
        bodyInfo.length > 0 ||
        tastesInfo.length > 0) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {personalInfo.length > 0 && (
            <Accordion
              sx={{
                width: "100%",
                borderBottom: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  border: "2px solid #ffffff",
                  borderRadius: "12px",
                  padding: "4px 15px",

                  "&.Mui-expanded": {
                    padding: "4px 15px",
                    margin: 0,
                    minHeight: "auto",
                    borderBottom: "none",
                    borderRadius: "12px 12px 0 0",
                  },

                  "&>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },

                  "&.Mui-expanded>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },
                }}
              >
                <Typography variant="button" fontWeight="bold">
                  Personal information
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                  border: "2px solid #ffffff",
                  borderTop: "none",
                  borderRadius: "0 0 12px 12px",
                }}
              >
                {personalInfo.map((personalInfoItem) => (
                  <Typography key={personalInfoItem.key}>
                    {personalInfoItem.key === "birthday" ? (
                      <>
                        {personalInfoItem.display_text}:{" "}
                        {dayjs(personalInfoItem.value).format("MM/DD/YYYY")}
                      </>
                    ) : (
                      <>
                        {personalInfoItem.display_text}:{" "}
                        {personalInfoItem.value}
                      </>
                    )}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {bodyInfo.length > 0 && (
            <Accordion
              sx={{
                width: "100%",
                borderBottom: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  border: "2px solid #ffffff",
                  borderRadius: "12px",
                  padding: "4px 15px",

                  "&.Mui-expanded": {
                    padding: "4px 15px",
                    margin: 0,
                    minHeight: "auto",
                    borderBottom: "none",
                    borderRadius: "12px 12px 0 0",
                  },

                  "&>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },

                  "&.Mui-expanded>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },
                }}
              >
                <Typography variant="button" fontWeight="bold">
                  Body measurement
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                  border: "2px solid #ffffff",
                  borderTop: "none",
                  borderRadius: "0 0 12px 12px",
                }}
              >
                {bodyInfo.map((bodyInfoItem) => (
                  <Typography key={bodyInfoItem.key}>
                    {bodyInfoItem.display_text}: {bodyInfoItem.value}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {tastesInfo.length > 0 && (
            <Accordion
              sx={{
                width: "100%",
                borderBottom: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                sx={{
                  border: "2px solid #ffffff",
                  borderRadius: "12px",
                  padding: "4px 15px",

                  "&.Mui-expanded": {
                    padding: "4px 15px",
                    margin: 0,
                    minHeight: "auto",
                    borderBottom: "none",
                    borderRadius: "12px 12px 0 0",
                  },

                  "&>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },

                  "&.Mui-expanded>.MuiAccordionSummary-content": {
                    minHeight: "auto",
                    margin: "6px 0",
                  },
                }}
              >
                <Typography variant="button" fontWeight="bold">
                  My tastes
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                  border: "2px solid #ffffff",
                  borderTop: "none",
                  borderRadius: "0 0 12px 12px",
                }}
              >
                {tastesInfo.map((tastesInfoItem) => (
                  <Typography key={tastesInfoItem.key}>
                    {tastesInfoItem.display_text}: {tastesInfoItem.value}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      )}
    </>
  );
};
