function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0F70CA"
        d="M9.75 12c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25S13.24 9.75 12 9.75 9.75 10.76 9.75 12zM9.75 19.5c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25-2.25 1.01-2.25 2.25zM9.75 4.5c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25S13.24 2.25 12 2.25 9.75 3.26 9.75 4.5z"
      />
    </svg>
  );
}

export default SettingsIcon;
