import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { useQuery } from "../../hooks/query-params";
import { MainWrapper } from "../shared/main-wrapper/main-wrapper";
import { HomeList } from "./home-list/home-list";
import { HomeListProvider } from "./home-list/home-list-context/home-list-context";
import { HomeSearchBar } from "./home-search-bar/home-search-bar";
import styles from "./home.module.scss";
import type { ActiveTab, ActiveView } from "./interfaces/temporary.interface";

export const Home = () => {
  const [tab, setTab] = useState<ActiveTab>(null);
  const [view, setView] = useState<ActiveView>("tile");
  const [searchText, setSearchText] = useState<string>("");
  const query = useQuery();
  const searchTag = query.get("searchTag");

  useEffect(() => {
    setTab(searchTag ? null : "top_ranked");
  }, [searchTag]);

  const handleTabChange = useCallback(
    (nextView: ActiveTab) => {
      setTab(nextView);
    },
    [setTab]
  );

  const handleViewChange = useCallback(
    (nextView: ActiveView) => {
      setView(nextView);
    },
    [setView]
  );

  const handleSearchTextChange = useCallback(
    (text: string) => {
      setSearchText(text);
    },
    [setSearchText]
  );

  return (
    <Box display="flex" justifyContent="center">
      <MainWrapper>
        <Box className={styles.wrapper}>
          <Box
            sx={{
              width: "calc(100% - 915px)",
            }}
          >
            <HomeSearchBar
              tab={tab}
              handleTabChange={handleTabChange}
              view={view}
              handleViewChange={handleViewChange}
              handleSearchTextChange={handleSearchTextChange}
            />
          </Box>
          <HomeListProvider tab={tab} view={view} searchText={searchText}>
            <HomeList />
          </HomeListProvider>
        </Box>
      </MainWrapper>
    </Box>
  );
};
