import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import useSubmitState from "../../hooks/submit-state";
import { useRequestResetPasswordMutation } from "../../services/request-reset-password/request-reset-password.service";
import { UnauthorizedWrapper } from "../shared/unauthorized-wrapper/unauthorized-wrapper";
import type { RequestResetPasswordInterface } from "./interfaces/request-reset-password.interface";
import styles from "./request-reset-password.module.scss";

export default function RequestResetPassword() {
  const [requestResetPasswordSlice, { isLoading, isSuccess, error, reset }] =
    useRequestResetPasswordMutation();

  const { text, color, errorCode } = useSubmitState({
    requestError: error as FetchBaseQueryError,
    defaultText: "Send Request",
    successText: "Sent!",
    isRequestSuccess: isSuccess,
    statusGreaterThan: 500,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("")
        .max(255),
    }),
    onSubmit: async (values: RequestResetPasswordInterface) => {
      if (isLoading || isSuccess || color === "success") {
        return;
      }

      await requestResetPasswordSlice({
        email: values.email,
      }).unwrap();
    },
  });

  return (
    <UnauthorizedWrapper>
      <div className={styles.cardWrapper}>
        <Typography
          color="#3C3C3C"
          variant="h4"
          fontWeight={400}
          textAlign="left"
        >
          Request Reset Password
        </Typography>

        <form
          noValidate
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
          className={styles.cardContent}
          autoComplete="off"
        >
          <Typography variant="body1">
            If your email address matches our records, we&apos;ll send password
            reset instructions.
          </Typography>
          <section>
            <TextField
              placeholder="Enter email"
              type="email"
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              name="email"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                reset();
              }}
              value={formik.values.email}
            />
            {formik.errors.email && (
              <Typography color="error" variant="caption">
                {formik.errors.email}
              </Typography>
            )}
          </section>

          <Button
            disabled={!formik.isValid || !formik.dirty}
            variant="contained"
            type={color === "primary" ? "submit" : "button"}
            color={color}
            onClick={(e) => {
              if (color === "error") {
                e.preventDefault();
                reset();
              }

              if (color === "success") {
                e.preventDefault();
              }
            }}
          >
            {isLoading ? (
              <CircularProgress
                color="inherit"
                size="1.6rem"
                data-cy="loader"
              />
            ) : (
              text
            )}
          </Button>
          {errorCode && (
            <Typography variant="body1" color="error" mt={1} textAlign="center">
              Oops... Something went wrong. Error {errorCode}
            </Typography>
          )}
          <Typography textAlign="left">
            Need Help? <Link to="/">Contact SideFans Support</Link>
          </Typography>
        </form>
      </div>
    </UnauthorizedWrapper>
  );
}
