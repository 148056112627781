import {
  Avatar,
  AvatarGroup,
  Button,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Link as LinkButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import {
  type ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/local-storage-keys/local-storage-keys";
import { SortingDirection } from "../../../../../../enums/sorting-direction.enum";
import { ArrowDown } from "../../../../../../media/icons/arrowDown";
import { SearchInCircleIcon } from "../../../../../../media/icons/search-in-circle";
import {
  IGroup,
  type IMember,
} from "../../../../../../services/manage-subscribers/interfaces/fetch-groups-response.interface";
import { useFetchGroupsMutation } from "../../../../../../services/manage-subscribers/manage-subscribers.service";
import type { ISearchText } from "../../../../../manage-subscribers/interfaces/search-text.interface";
import styles from "../../../../../manage-subscribers/subscriber-groups/subscriber-groups.module.scss";
import { DefaultAvatar } from "../../../../../shared/default-avatar/default-avatar";
import { ModalDialog } from "../../../../../shared/modal-dialog/modal-dialog";
import { ModalDialogContent } from "../../../../../shared/modal-dialog/modal-dialog-content/modal-dialog-content";
import { ModalDialogFooter } from "../../../../../shared/modal-dialog/modal-dialog-footer/modal-dialog-footer";
import { ModalDialogHeader } from "../../../../../shared/modal-dialog/modal-dialog-header/modal-dialog-header";

interface IAddGroupsProp {
  isDialogOpened: boolean;
  setIsOpenAddGroupsDialog: Dispatch<SetStateAction<boolean>>;
  selectedGroupsIds: string[];
  onSaveClick: (switches: string[]) => void;
  setSelectedGroups: Dispatch<SetStateAction<string[]>>;
  onCancelClick: () => void;
}

export const AddGroups: FC<IAddGroupsProp> = ({
  setIsOpenAddGroupsDialog,
  selectedGroupsIds,
  isDialogOpened,
  onSaveClick,
  setSelectedGroups,
  onCancelClick,
}) => {
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  // All groups
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  // Values which will be sent to the backend
  const [switches, setSwitches] = useState<string[]>([]);

  const typingTimeout = useRef<any>(null);

  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const formikSubscribers = useFormik({
    initialValues: {
      searchText,
    },
    onSubmit: async (values: ISearchText) => {
      setSearchText(values.searchText);
    },
  });

  const handleSearchTextChange = (e: Object) => {
    clearTimeout(typingTimeout.current);

    formikSubscribers.handleChange(e);

    typingTimeout.current = setTimeout(() => {
      formikSubscribers.submitForm();
    }, 500);
  };

  useEffect(() => {
    setSelectedGroups(selectedGroupsIds);
  }, [selectedGroupsIds]);

  const [
    fetchGroups,
    {
      error: fetchGroupsError,
      isLoading: fetchGroupsLoading,
      data: groupsData,
    },
  ] = useFetchGroupsMutation(); // change to groups

  const containerElemRef = useRef<HTMLDivElement | null>(null);

  const getGroups = useCallback(async () => {
    try {
      await fetchGroups({
        accessToken: accessToken || "",
        rowsPerPage: 6,
        page: page + 1,
        direction: SortingDirection.asc,
        sortBy: "created_at",
        searchText,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [accessToken, fetchGroups, page, searchText]);

  useEffect(() => {
    setAllGroups([]);
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    if (groupsData) {
      if (page === 0) {
        setAllGroups(groupsData.data || []);
        return;
      }

      setAllGroups((prevState) => [...prevState, ...(groupsData.data || [])]);
    }
  }, [groupsData]);

  useEffect(() => {
    containerElemRef.current?.scrollTo({
      top: containerElemRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [allGroups]);

  useEffect(() => {
    if (fetchGroupsError) {
      navigate("/");
    }

    if (!isDialogOpened) {
      setSearchText("");
      return;
    }

    getGroups();
  }, [getGroups, isDialogOpened]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setSelectedGroups((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value)
    );

    const isGroupChanged = switches.findIndex((id) => id === value);

    if (isGroupChanged !== -1) {
      setSwitches((prevState) => prevState.filter((id) => id !== value));
      return;
    }

    setSwitches((prevState) => [...prevState, value]);
  };

  const handleSaveClick = () => {
    setIsOpenAddGroupsDialog(false);
    onSaveClick(switches);
    setSwitches([]);
  };

  return (
    <ModalDialog
      isOpen={isDialogOpened}
      classes={styles.modal}
      dataCy="edit-group-modal"
    >
      <ModalDialogHeader>
        <Typography variant="h5" color="#252733" fontWeight={400}>
          Select subscriber group
        </Typography>
      </ModalDialogHeader>
      <ModalDialogContent>
        <form className={styles.groupForm} noValidate>
          <TextField
            placeholder="Search SideFans"
            type="text"
            fullWidth
            name="searchText"
            onBlur={formikSubscribers.handleBlur}
            onChange={handleSearchTextChange}
            value={formikSubscribers.values.searchText}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchInCircleIcon height={24} width={24} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <div className={styles.subscriberList} ref={containerElemRef}>
              {allGroups.length > 0 &&
                allGroups.map((row: IGroup) => (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    key={row.id}
                    className={styles.subscriberLabel}
                    control={
                      <Switch
                        sx={{ mt: 1, mb: 1 }}
                        onChange={handleSwitchChange}
                        checked={selectedGroupsIds.includes(row.id)}
                      />
                    }
                    label={
                      <div className={styles.subscriberInfo}>
                        <AvatarGroup
                          max={3}
                          sx={{
                            "& .MuiAvatarGroup-avatar:not(:has(> div))": {
                              width: 32,
                              height: 32,
                              background: "rgba(37, 39, 51, 1)",
                            },
                          }}
                        >
                          {row.group_members?.map((groupMember: IMember) => (
                            <Avatar
                              alt={groupMember.user?.username}
                              src={
                                groupMember.user?.avatar?.backend_media_url ||
                                ""
                              }
                              sx={{ width: 32, height: 32 }}
                              key={groupMember.id}
                            >
                              {!groupMember.user?.avatar && (
                                <DefaultAvatar width={32} height={32} />
                              )}
                            </Avatar>
                          ))}
                        </AvatarGroup>
                        <Typography variant="body1">{row.name}</Typography>
                      </div>
                    }
                    labelPlacement="start"
                    value={row.id}
                  />
                ))}
            </div>
            {groupsData?.count !== allGroups.length && (
              <div className={styles.showMoreWrapper}>
                <Button
                  sx={{ padding: 0 }}
                  disableRipple
                  component={LinkButton}
                  variant="text"
                  onClick={() => {
                    setPage((prev) => prev + 1);
                  }}
                >
                  <Typography className={styles.showMore} variant="body1">
                    <span style={{ display: "block" }}>Show more</span>{" "}
                    <ArrowDown color="blue" />
                  </Typography>
                </Button>
              </div>
            )}
          </FormControl>
        </form>
      </ModalDialogContent>
      <ModalDialogFooter>
        <div className={styles.modalFooter}>
          <Button
            color="primary"
            className={styles.btn}
            variant="contained"
            type="button"
            onClick={handleSaveClick}
          >
            <Typography variant="body1">Save</Typography>
          </Button>
          <Button
            color="primary"
            className={styles.btn}
            variant="outlined"
            type="button"
            onClick={() => {
              setPage(0);
              setIsOpenAddGroupsDialog(false);
              onCancelClick();
            }}
            sx={{ borderRadius: "12px" }}
          >
            <Typography variant="body1">Cancel</Typography>
          </Button>
        </div>
        {fetchGroupsLoading && (
          <LinearProgress style={{ width: "100%", marginTop: "5px" }} />
        )}
      </ModalDialogFooter>
    </ModalDialog>
  );
};
