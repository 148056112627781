import { Box, Link as LinkMUI, Typography } from "@mui/material";
import type { ChangeEvent, FC } from "react";
import { useRef, useState } from "react";

import { PlusIcon } from "../../../media/icons/plus";
import styles from "./file-loader.module.scss";

interface IFileLoaderProp {
  onFilesUpload: (files: File[]) => void;
  isError?: boolean;
}

export const FileLoader: FC<IFileLoaderProp> = ({ onFilesUpload, isError }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const selectFiles = () => {
    fileInputRef.current?.click();
  };

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files || files.length === 0) return;

    onFilesUpload(Array.from(files));
    event.target.value = "";
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    setIsDragging(true);
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "copy";
    }
  };

  const onDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event: any) => {
    event.preventDefault();
    setIsDragging(false);
    const { files }: { files: File[] } = event.dataTransfer;

    onFilesUpload(Array.from(files));
  };

  const borderStyle = isDragging
    ? `2px solid ${isError ? "rgba(255, 82, 82, 0.7)" : "rgba(15, 112, 202, 0.7)"}`
    : `2px dashed ${isError ? "rgba(255, 82, 82, 1)" : "rgba(15, 112, 202, 1)"}`;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#F3F7FA",
          borderRadius: "16px",
          border: borderStyle,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
        }}
        role="button"
        onClick={selectFiles}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        data-cy="dropzone"
      >
        {isDragging ? (
          <Typography
            variant="body1"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LinkMUI
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              Drop files here
            </LinkMUI>
          </Typography>
        ) : (
          <Typography
            variant="body1"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LinkMUI
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  transform: "translate(1px, 2px)",
                }}
              >
                <PlusIcon width={16} height={16} />
              </Box>
              <Box marginLeft="3px">Click to upload</Box>
            </LinkMUI>
            <div>&nbsp;or drag & drop</div>
          </Typography>
        )}
        <input
          type="file"
          name="file"
          className={styles.fileInput}
          multiple
          ref={fileInputRef}
          onChange={onFileSelect}
        />
      </Box>
    </>
  );
};
