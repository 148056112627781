function ContentLikeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#AAA"
        d="M7.999 14.364a1.5 1.5 0 01-1.065-.44l-5.15-5.15a4.36 4.36 0 01-1.26-3.125 4.03 4.03 0 014.07-4 4.03 4.03 0 012.905 1.195l.5.5.41-.41a4.195 4.195 0 015.5-.445 4.124 4.124 0 01.405 6.18l-5.25 5.255a1.499 1.499 0 01-1.065.44zM4.574 2.644a3.06 3.06 0 00-2.811 1.848 3 3 0 00-.24 1.177 3.36 3.36 0 00.976 2.4l5.15 5.15a.5.5 0 00.71 0l5.255-5.26a3.124 3.124 0 00.885-2.4 3.09 3.09 0 00-1.215-2.275 3.185 3.185 0 00-4.185.355l-.745.765a.5.5 0 01-.71 0l-.85-.85a3.14 3.14 0 00-2.2-.91h-.02z"
      />
    </svg>
  );
}

export default ContentLikeIcon;
