function ViewesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
        d="M8 3C4.734 3 1.968 5.098 1 8c.968 2.902 3.734 5 7 5s6.032-2.098 7-5c-.968-2.902-3.734-5-7-5z"
      />
      <path
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
        d="M8 11a3 3 0 100-6 3 3 0 000 6z"
      />
    </svg>
  );
}

export default ViewesIcon;
