import { Box, LinearProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage-keys/local-storage-keys";
import useDateGroup from "../../../hooks/date-group";
import useInfinityScrollTable from "../../../hooks/infinity-scroll-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useFetchTransactionHistoryMutation } from "../../../services/earnings/earnings.service";
import { TransactionHistoryType } from "../../../services/earnings/enums/transaction-history-type.enum";
import { totalEarningsSlice } from "../../../store/reducers/transactions-history/total-earnings";
import { transactionHistorySlice } from "../../../store/reducers/transactions-history/transaction-history";
import DateFilters from "../../shared/date-filters/date-filters";
import DefaultSelect from "../../shared/select/select";
import { TransactionHistoryList } from "./transaction-history-list/transaction-history-list";
import { transactionHistoryTableHeads } from "./transaction-history-list/transaction-history-table-heads";
import { transactionShowValues } from "./transaction-show-values";

export const TransactionHistory = () => {
  const rowsPerPage = 5;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = window.localStorage.getItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN
  );

  const [selectedType, setSelectedType] = useState<TransactionHistoryType>(
    TransactionHistoryType.all
  );

  const { setTransactionHistory } = transactionHistorySlice.actions;
  const { setTotalEarnings, setInitialTotalEarningsState } =
    totalEarningsSlice.actions;
  const { transactionHistory } = useAppSelector(
    (reducers) => reducers.transactionHistoryReducer
  );
  const [
    fetchTransactionHistory,
    { error, isLoading, data: transactionHistoryData },
  ] = useFetchTransactionHistoryMutation();

  const {
    lastMemberElementRef,
    page,
    setPage,
    direction,
    setDirection,
    sortBy,
    setSortBy,
  } = useInfinityScrollTable(
    isLoading,
    transactionHistory,
    transactionHistoryData?.count || 0,
    transactionHistoryTableHeads
  );

  const { date, setDate, handleChangeDate, setCalendarDate, calendarDate } =
    useDateGroup("week");

  const getHistory = useCallback(async () => {
    try {
      await fetchTransactionHistory({
        accessToken: accessToken || "",
        rowsPerPage,
        page: page + 1,
        direction,
        sortBy,
        type: selectedType === "all" ? undefined : selectedType,
        startDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.startDate).subtract(1, "day").toISOString()
            : date.startDate,
        endDate:
          date.type === "day" || dayjs(date.startDate) === dayjs(date.endDate)
            ? dayjs(date.endDate).add(1, "day").toISOString()
            : date.endDate,
      }).unwrap();
    } catch (err: any) {
      navigate("/");
    }
  }, [
    accessToken,
    fetchTransactionHistory,
    rowsPerPage,
    direction,
    sortBy,
    page,
    date,
    selectedType,
  ]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }

    getHistory();
  }, [getHistory, page]);

  useEffect(() => {
    if (
      !transactionHistoryData ||
      !transactionHistoryData.sum ||
      !transactionHistoryData.data
    )
      return;

    dispatch(setTotalEarnings(transactionHistoryData.sum));

    if (page === 0) {
      dispatch(setTransactionHistory(transactionHistoryData.data));
      return;
    }

    dispatch(
      setTransactionHistory([
        ...transactionHistory,
        ...transactionHistoryData.data,
      ])
    );
  }, [transactionHistoryData]);

  useEffect(() => {
    dispatch(setTransactionHistory([]));
    dispatch(setInitialTotalEarningsState());
    setPage(0);
  }, [sortBy, direction, date, selectedType]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Typography
        sx={{
          marginBottom: "24px",
        }}
        variant="h4"
        fontWeight={400}
      >
        Transaction history
      </Typography>
      <Box display="flex" justifyContent="space-between" gap="20px">
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
        >
          <Typography variant="body1">Sort by:</Typography>
          <Box width="230px">
            <DefaultSelect
              name="show"
              options={transactionShowValues}
              onChange={(value) => {
                setSelectedType(value.value);
              }}
              value={selectedType}
            />
          </Box>
        </Box>
        <Box
          sx={{
            alignSelf: "right",
          }}
        >
          <DateFilters
            date={date}
            calendarDate={calendarDate}
            handleChangeDate={handleChangeDate}
            setCalendarDate={setCalendarDate}
            setDate={setDate}
            disableFuture
          />
        </Box>
      </Box>
      {/* TODO: Uncomment it when decision about having this component will be approved */}
      {/* <TotalEarnings transactionType={selectedType} /> */}
      <TransactionHistoryList
        transactionHistory={transactionHistory}
        direction={direction}
        setDirection={setDirection}
        setSortBy={setSortBy}
        sortBy={sortBy}
        lastMemberElementRef={lastMemberElementRef}
      />
      {isLoading && <LinearProgress data-cy="loader" />}
    </Box>
  );
};
