import type { FC } from "react";

type HelpIconProp = {
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const HelpIcon: FC<HelpIconProp> = ({
  width = 32,
  height = 32,
  disabled = false,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2871_62636)">
      <path
        d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
      <path
        d="M8.47767 3.37522C8.09323 3.30518 7.69808 3.32048 7.3202 3.42005C6.94232 3.51962 6.59095 3.70102 6.29094 3.95142C5.99093 4.20183 5.74962 4.5151 5.58408 4.86909C5.41855 5.22307 5.33283 5.60911 5.33301 5.99989C5.33301 6.1767 5.40325 6.34627 5.52827 6.47129C5.65329 6.59632 5.82286 6.66656 5.99967 6.66656C6.17649 6.66656 6.34605 6.59632 6.47108 6.47129C6.5961 6.34627 6.66634 6.1767 6.66634 5.99989C6.66617 5.80374 6.70929 5.60997 6.7926 5.43239C6.87592 5.25482 6.99739 5.09781 7.14835 4.97257C7.29932 4.84733 7.47605 4.75695 7.66596 4.70785C7.85586 4.65876 8.05426 4.65217 8.24701 4.68856C8.51037 4.73968 8.75251 4.86815 8.94251 5.05755C9.1325 5.24695 9.26173 5.48869 9.31367 5.75189C9.36614 6.02816 9.32991 6.31394 9.21018 6.56838C9.09044 6.82282 8.89332 7.03289 8.64701 7.16856C8.23912 7.40487 7.90207 7.74623 7.67096 8.15709C7.43985 8.56795 7.32315 9.03326 7.33301 9.50456V9.99989C7.33301 10.1767 7.40325 10.3463 7.52827 10.4713C7.65329 10.5963 7.82286 10.6666 7.99967 10.6666C8.17649 10.6666 8.34606 10.5963 8.47108 10.4713C8.5961 10.3463 8.66634 10.1767 8.66634 9.99989V9.50456C8.65798 9.27256 8.71088 9.04246 8.81972 8.83742C8.92856 8.63237 9.0895 8.45962 9.28634 8.33656C9.76935 8.07127 10.1583 7.66286 10.3997 7.16749C10.6412 6.67212 10.7232 6.11413 10.6345 5.57024C10.5459 5.02635 10.2909 4.52328 9.90467 4.13022C9.51844 3.73715 9.01993 3.4734 8.47767 3.37522Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
      <path
        d="M8.66634 11.9997C8.66634 11.6315 8.36786 11.333 7.99967 11.333C7.63148 11.333 7.33301 11.6315 7.33301 11.9997C7.33301 12.3679 7.63148 12.6663 7.99967 12.6663C8.36786 12.6663 8.66634 12.3679 8.66634 11.9997Z"
        fill={`${disabled ? "#909294" : "#242424"}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_2871_62636">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
