import { Box, LinearProgress } from "@mui/material";
import { useEffect } from "react";

import { useFolderCreatorCatalogCCViewContext } from "../context/folder-creator-catalog-cc-view-context/folder-creator-catalog-cc-view-context";
import styles from "./content-folders.module.scss";
import { Folder } from "./folder/folder";

export const ContentFolders = () => {
  const { folders, isGetFoldersLoading, getFoldersById } =
    useFolderCreatorCatalogCCViewContext();
  const { currentOpenedFolder } = useFolderCreatorCatalogCCViewContext();

  useEffect(() => {
    getFoldersById(currentOpenedFolder?.id);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "33px",
      }}
    >
      {isGetFoldersLoading ? (
        <LinearProgress sx={{ width: "100%" }} />
      ) : (
        <Box className={styles.folderList}>
          {folders.map((folder) => (
            <Box key={folder.id}>
              <Folder folder={folder} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
