function SeeAllOfMeNextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#fff"
        d="M8.71 18.758l6.148-6.246a.728.728 0 000-1.024L8.712 5.24a.733.733 0 01.231-1.185.707.707 0 01.78.157l6.147 6.245a2.2 2.2 0 010 3.083l-6.146 6.245a.707.707 0 01-1.014 0 .734.734 0 010-1.028z"
      />
    </svg>
  );
}

export default SeeAllOfMeNextIcon;
