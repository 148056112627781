import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import type { FC } from "react";

import type { IUploadFile } from "../../../../interfaces/file-uploading/uploaded-files.interface";
import { FileListItemError } from "../file-list-item-error/file-list-item-error";
import { FileListItem } from "../file-list-item/file-list-item";

interface ISortedItemProp {
  id: string;
  file: IUploadFile;
  fileWidthPx: number;
  onFileDelete: (fileId: string) => void;
  uploadFiles(retriedFile?: IUploadFile): Promise<void>;
  draggable: boolean;
}
export const SortableItem: FC<ISortedItemProp> = ({
  id,
  file,
  fileWidthPx,
  onFileDelete,
  uploadFiles,
  draggable,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div style={style} ref={setNodeRef}>
      {file.reason ? (
        <Box
          sx={{
            width: `${fileWidthPx}px`,
          }}
        >
          <FileListItemError
            uploadedFile={file}
            onFileDelete={onFileDelete}
            reason={file.reason}
            uploadFile={uploadFiles}
            key={file.id}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: `${fileWidthPx}px`,
          }}
        >
          <FileListItem
            key={file.id}
            file={file}
            onFileDelete={onFileDelete}
            draggable={draggable}
            isDragging={isDragging}
            listeners={listeners}
            attributes={attributes}
          />
        </Box>
      )}
    </div>
  );
};
