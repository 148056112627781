import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { CollectedData } from "../../components/select-payment-method/interfaces/collected-data.interface";
import { ENDPOINTS } from "../../constants/api/api";
import type {
  RemoveTransferAccountArgs,
  RemoveTransferAccountResponse,
  UpdateTransferAccountArgs,
  UpdateTransferAccountResponse,
} from "./interfaces/transfer-account.interface";
import type {
  FetchTransferHistoryArgs,
  FetchTransferHistoryResponse,
} from "./interfaces/transfer-history.interface";

export const transferAccountsApi = createApi({
  reducerPath: "transferAccounts",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getTransferHistory: builder.mutation<
      FetchTransferHistoryResponse,
      FetchTransferHistoryArgs
    >({
      query: (data) => ({
        url: `${ENDPOINTS.TRANSFER_ACCOUNTS.FETCH_TRANSFER_HISTORY}`,
        method: "POST",
        headers: {
          Authorization: data.accessToken,
        },
        params: {
          rowsPerPage: data.rowsPerPage,
          page: data.page,
          direction: data.direction,
          sortBy: data.sortBy,
        },
        body: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
    addTransferAccount: builder.mutation({
      query: (data: CollectedData) => ({
        url: ENDPOINTS.TRANSFER_ACCOUNTS.ROOT,
        method: "POST",
        body: data,
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    getTransferAccounts: builder.mutation({
      query: (data: { accessToken: string }) => ({
        url: ENDPOINTS.TRANSFER_ACCOUNTS.ROOT,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    removeTransferAccount: builder.mutation<
      RemoveTransferAccountResponse,
      RemoveTransferAccountArgs
    >({
      query: (data) => ({
        url: `${ENDPOINTS.TRANSFER_ACCOUNTS.ROOT}/${data.id}`,
        method: "DELETE",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    updateTransferAccount: builder.mutation<
      UpdateTransferAccountResponse,
      UpdateTransferAccountArgs
    >({
      query: (data) => ({
        url: `${ENDPOINTS.TRANSFER_ACCOUNTS.ROOT}/${data.id}`,
        method: "PATCH",
        headers: {
          Authorization: data.accessToken,
        },
        body: data.data,
      }),
    }),
  }),
});

export const {
  useAddTransferAccountMutation,
  useGetTransferAccountsMutation,
  useRemoveTransferAccountMutation,
  useUpdateTransferAccountMutation,
  useGetTransferHistoryMutation,
} = transferAccountsApi;
