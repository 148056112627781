import { Dispatch, FC, SetStateAction } from "react";

import { useAppSelector } from "../../../../../../hooks/redux";
import { ICreatorCardData } from "../../../../../../services/home/interfaces/home.interface";
import { CreatorCard } from "../../../../../creator-card/creator-card";

interface ICatalogCreatorCardProp {
  handleCloseCreatorCard: () => void;
  creatorCard: ICreatorCardData | null;
  setCreatorCard: Dispatch<SetStateAction<ICreatorCardData | null>>;
}

export const CatalogCreatorCard: FC<ICatalogCreatorCardProp> = ({
  handleCloseCreatorCard,
  setCreatorCard,
  creatorCard,
}) => {
  const { user } = useAppSelector((data) => data.userReducer);

  const isAllowBeInterested = creatorCard?.id !== user?.id;

  return (
    <CreatorCard
      handleCloseCreatorCard={handleCloseCreatorCard}
      currentCard={creatorCard}
      setCurrentCard={setCreatorCard}
      allowBeInterested={isAllowBeInterested}
    />
  );
};
