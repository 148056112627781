import { DefaultSelectOptions } from "../../../../shared/select/select";

// eslint-disable-next-line no-shadow
export enum CatalogSortKeys {
  all = "all",
  name_asc = "name/asc",
  name_desc = "name/desc",

  created_at_asc = "created_at/asc",
  created_at_desc = "created_at/desc",

  price_asc = "price/asc",
  price_desc = "price/desc",

  views = "views",

  sold = "sold",

  earnings_asc = "earnings/asc",
  earnings_desc = "earnings/desc",

  folders_asc = "folders/asc",
  folders_desc = "folders/desc",
}

export type CatalogDefaultSelectOptions = DefaultSelectOptions & {
  direction?: "asc" | "desc";
};

export const catalogSortOptions: CatalogDefaultSelectOptions[] = [
  {
    value: CatalogSortKeys.all,
    display_text: "All",
    disabled: false,
  },

  {
    value: CatalogSortKeys.name_asc,
    display_text: "Title A-Z",
    disabled: false,
    direction: "asc",
  },
  {
    value: CatalogSortKeys.name_desc,
    display_text: "Title Z-A",
    disabled: false,
    direction: "desc",
  },

  {
    value: CatalogSortKeys.created_at_asc,
    display_text: "Date created (Newest first)",
    disabled: false,
    direction: "desc",
  },
  {
    value: CatalogSortKeys.created_at_desc,
    display_text: "Date created (Oldest first)",
    disabled: false,
    direction: "asc",
  },

  {
    value: CatalogSortKeys.price_asc,
    display_text: "Price (Low to High)",
    disabled: false,
    direction: "asc",
  },
  {
    value: CatalogSortKeys.price_desc,
    display_text: "Price (High to Low)",
    disabled: false,
    direction: "desc",
  },

  {
    value: CatalogSortKeys.views,
    display_text: "Most viewed",
    disabled: true,
  },

  {
    value: CatalogSortKeys.sold,
    display_text: "Most sold",
    disabled: true,
  },

  {
    value: CatalogSortKeys.earnings_asc,
    display_text: "Earnings (Low to High)",
    disabled: true,
    direction: "asc",
  },
  {
    value: CatalogSortKeys.earnings_desc,
    display_text: "Earnings (High to Low)",
    disabled: true,
    direction: "desc",
  },

  {
    value: CatalogSortKeys.folders_asc,
    display_text: "Folders on top",
    disabled: false,
    direction: "asc",
  },
  {
    value: CatalogSortKeys.folders_desc,
    display_text: "Folders on the bottom",
    disabled: false,
    direction: "desc",
  },
];
