import { Box, Chip, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup";

import { DeleteCrossIcon } from "../../../media/icons/delete-cross-icon";

interface ITagsInputProp {
  formikKey: string;
  tags: string[];
  onFormikValueUpdate: (formikKey: string, formikValue: string[]) => void;
}

export const TagsInput: FC<ITagsInputProp> = ({
  onFormikValueUpdate,
  tags,
  formikKey,
}) => {
  const [limitError, setLimitError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      tag: "",
    },
    validationSchema: Yup.object({
      tag: Yup.string().required(),
    }),
    onSubmit: async (values: { tag: string }) => {
      if (tags.includes(values.tag)) return;

      if (tags.length === 10) {
        setLimitError(true);
        return;
      }
      const arrayOfTags = [...tags, values.tag];
      onFormikValueUpdate(formikKey, arrayOfTags);
      formik.resetForm();
    },
  });

  const handleDelete = (tagToDelete: string) => {
    const arrayOfTags = tags.filter((tag) => tag !== tagToDelete);
    onFormikValueUpdate(formikKey, arrayOfTags);

    setLimitError(false);
  };

  return (
    <>
      <TextField
        name="tag"
        error={limitError}
        onChange={(e) => {
          formik.handleChange(e);

          if (e.target.value === "") {
            setLimitError(false);
          }
        }}
        placeholder="Enter tag and press Enter"
        fullWidth
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            formik.handleSubmit();
          }
        }}
        value={formik.values.tag}
      />
      {limitError && (
        <Typography
          variant="caption"
          align="left"
          color="#FF5252"
          marginTop="3px"
        >
          Only 10 tags are acceptable
        </Typography>
      )}

      <Box
        sx={{
          marginTop: "15px",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={<Typography variant="button">{tag}</Typography>}
            variant="outlined"
            onDelete={() => handleDelete(tag)}
            deleteIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DeleteCrossIcon width={10} height={10} />
              </Box>
            }
            sx={{
              padding: "20px 10px",
              gap: "10px",
              background: "#fff",
              borderRadius: "10px",
              border: "1px solid #EBEBEB",
            }}
          />
        ))}
      </Box>
    </>
  );
};
