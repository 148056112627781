import type { FC, PropsWithChildren } from "react";

interface IVolumeIconProp {
  width?: number;
  height?: number;
  color?: string;
}
export const WarningIcon: FC<PropsWithChildren<IVolumeIconProp>> = ({
  width = 32,
  height = 32,
  color = "black",
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4552 22.2343L17.4546 22.2343L17.4552 22.2475C17.4903 23.0087 16.785 23.6957 16 23.6957C15.1882 23.6957 14.5055 23.0129 14.5055 22.2012C14.5055 21.3894 15.1882 20.7066 16 20.7066C16.8172 20.7066 17.491 21.3802 17.4552 22.2343Z"
        fill="#FF5252"
        stroke={color}
        strokeWidth="0.3"
      />
      <path
        d="M31.0915 26.3095L31.0915 26.3096C30.0787 28.0596 28.2794 29.1028 26.2746 29.1028H5.6983C3.69935 29.1028 1.91343 28.0721 0.914106 26.3553L0.914053 26.3552C-0.0981222 24.6191 -0.104743 22.5386 0.894791 20.7892C0.894851 20.789 0.894911 20.7889 0.894971 20.7888L11.2091 2.92991L11.2094 2.92936C12.2023 1.19339 13.9945 0.15 16.0062 0.15C18.0181 0.15 19.8099 1.18695 20.8027 2.93553L20.8033 2.93648L31.1046 20.7763L31.1046 20.7763C32.1037 22.5055 32.0976 24.5729 31.0915 26.3095ZM29.1145 25.1707L29.1147 25.1704C29.7065 24.145 29.7144 22.9302 29.1345 21.9223L29.1344 21.9221L18.8269 4.06987C18.8269 4.06981 18.8268 4.06975 18.8268 4.06968C18.241 3.04303 17.185 2.42861 16.0062 2.42861C14.8213 2.42861 13.7717 3.03597 13.1857 4.06286C13.1856 4.063 13.1855 4.06315 13.1854 4.06329L2.8714 21.9155L2.87116 21.916C2.27869 22.9493 2.28572 24.1772 2.87795 25.2033L2.87801 25.2034C3.46436 26.2174 4.52072 26.8176 5.69173 26.8176H26.268C27.4533 26.8176 28.5156 26.2033 29.1145 25.1707Z"
        fill="#FF5252"
        stroke={color}
        strokeWidth="0.3"
      />
      <path
        d="M14.5819 12.3652L14.5819 12.3652L14.5817 12.3623C14.562 12.103 14.544 11.8421 14.5259 11.5805L14.5259 11.5802C14.5079 11.3209 14.4899 11.0609 14.4703 10.8025C14.4724 10.0026 14.9211 9.36097 15.6277 9.15745C16.3401 8.99145 17.0484 9.33183 17.3536 10.0002C17.4546 10.2384 17.4919 10.479 17.4922 10.7585C17.4726 11.1666 17.4448 11.5751 17.4169 11.9852L17.4169 11.9859C17.389 12.3964 17.361 12.8085 17.3412 13.2209C17.305 13.8553 17.2672 14.4896 17.2294 15.1242L17.2294 15.1244C17.1916 15.7582 17.1538 16.3921 17.1177 17.0261C17.0779 17.4463 17.0778 17.8263 17.0778 18.231C17.047 18.8304 16.584 19.2819 15.9977 19.2819C15.4058 19.2819 14.9522 18.8567 14.9174 18.2656C14.8615 17.2756 14.8055 16.2955 14.7496 15.3154L14.7496 15.3152C14.6937 14.3351 14.6378 13.3551 14.5819 12.3652Z"
        fill="#FF5252"
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  </>
);
