export const MESSAGE_TIMEOUT = 8000;
export const maxLengthOfDisplayName = 40;
export const maxLengthOfContentTitle = 40;
export const maxLengthOfContentDescription = 300;
export const maxNumberOfGreetingVideo = 1;
export const maxNumberOfGreetingPhoto = 1;
export const maxNumberOfGreetingMedia = 10;
export const maxLengthOfIntroduction = 300;
export const acceptedImagesForAvatar = "image/*";
export const maxLengthOfFolderName = 200;
