import { FC } from "react";

import { useAppSelector } from "../../../hooks/redux";
import { BankAcc } from "../../../media/icons/bank-account-icon";
import { BtcLogo } from "../../../media/icons/btc";
import { MastercardLogo } from "../../../media/icons/mastercard";
import { VisaLogo } from "../../../media/icons/visa";
import { IPaymentMethods } from "../../../services/payment-methods/interfaces/payment-methods.interface";
import DefaultSelect from "../select/select";

interface IPaymentMethodPickerProps {
  value: string;
  onChange: (value: string) => void;
}

export const PaymentMethodPicker: FC<IPaymentMethodPickerProps> = ({
  value,
  onChange,
}) => {
  const { user } = useAppSelector((data) => data.userReducer);

  const calculateDisplayText = (item: IPaymentMethods) => {
    if (item.bank_account) {
      return item.bank_account.name
        ? item.bank_account.name
        : item.bank_account.bank_account_number;
    }

    if (item.card) {
      return item.card.name ? item.card.name : item.card.card_number;
    }

    if (item.crypto) {
      return item.crypto.name ? item.crypto.name : item.crypto.wallet_id;
    }

    return "";
  };

  const renderIcon = (item: IPaymentMethods) => {
    if (item.bank_account) {
      return BankAcc({ width: 24, height: 24 });
    }

    if (item.card) {
      return item.card.card_number.startsWith("4") ? (
        <VisaLogo />
      ) : (
        <MastercardLogo />
      );
    }

    if (item.crypto) {
      return BtcLogo({ width: 24, height: 24 });
    }

    return <></>;
  };

  return (
    <DefaultSelect
      placeholder="Select payment method"
      name="selectedMethodId"
      options={
        !user
          ? []
          : user.user_payment_methods.map((item) => ({
              display_text: calculateDisplayText(item),
              value: item.id,
              disabled: false,
              start_component: renderIcon(item),
            }))
      }
      onChange={(v) => {
        onChange(v.value);
      }}
      value={value}
    />
  );
};
