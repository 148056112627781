import { useState } from "react";

import { HeaderWithSearch } from "../header-with-search/header-with-search";
import { MySubscribersList } from "./my-subscribers-list";

export const MySubscribers = () => {
  const [searchText, setSearchText] = useState<string>("");

  return (
    <>
      <HeaderWithSearch setSearchText={setSearchText} />
      <MySubscribersList searchText={searchText} />
    </>
  );
};
