import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { RequestResetPasswordInterface } from "./interfaces/request-reset-password.interface";

export const requestResetPasswordApi = createApi({
  reducerPath: "requestResetPassword",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    requestResetPassword: builder.mutation({
      query: (data: RequestResetPasswordInterface) => ({
        url: ENDPOINTS.REQUEST_RESET_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useRequestResetPasswordMutation } = requestResetPasswordApi;
