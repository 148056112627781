import type React from "react";

import { Video } from "../../../../shared/video/video";
import { useGreetingVideoContext } from "./greeting-video-context";

interface GreetingVideoProps {
  url: string;
}

export const GreetingVideo: React.FC<GreetingVideoProps> = ({ url }) => {
  const {
    showControls,
    muted: mutedContext,
    isPlaying,
  } = useGreetingVideoContext();

  return (
    <Video
      muted={mutedContext}
      showControls={showControls}
      videoUrl={url}
      isPlaying={isPlaying}
    />
  );
};
