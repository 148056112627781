import { Box, Button, CircularProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";

import type { ISubmitState } from "../../../../../../hooks/submit-state";
import type {
  IBackyardBanishmentDetails,
  IBackyardBanishmentUpdate,
} from "../../../../../../services/settings/interfaces/settings.interface";
import StateSelect from "../../../../../shared/select-state/select-state";
import { BanishmentKeys } from "../../banishment";

interface BanishByStateProp {
  banishmentData: IBackyardBanishmentDetails | null | undefined;
  onUpdateBackyardBanishment: (
    backyardBanishment: IBackyardBanishmentUpdate
  ) => Promise<void>;
  submitState: ISubmitState;
  resetState: () => void;
}

export const BanishByState: FC<BanishByStateProp> = ({
  banishmentData,
  onUpdateBackyardBanishment,
  submitState,
  resetState,
}) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (submitState.color === "error" || submitState.color === "success") {
      setIsLoading(false);
    }
  }, [submitState]);

  useEffect(() => {
    if (banishmentData) {
      setSelectedState(banishmentData.state || "");
    }
  }, [banishmentData]);

  const handleApplyState = () => {
    onUpdateBackyardBanishment({
      type: BanishmentKeys.state,
      data: {
        zip_codes: null,
        radius: null,
        state: selectedState,
        user_zip_code: null,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="row" gap="15px" alignItems="center">
      <StateSelect
        onChange={(e) => {
          resetState();
          setSelectedState(e.target.value);
        }}
        value={selectedState}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{ width: "108px" }}
          variant="contained"
          disabled={selectedState === ""}
          color={submitState.color}
          onClick={(e) => {
            if (submitState.color === "error") {
              e.preventDefault();
              resetState();
              return;
            }

            if (submitState.color === "success") {
              e.preventDefault();
              return;
            }

            handleApplyState();
            setIsLoading(true);
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="1.5rem" />
          ) : (
            <Typography variant="button">{submitState.text}</Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
};
