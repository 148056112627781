import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type {
  IDeleteMeParams,
  IDeleteMeResponse,
  IInitializeInterfaceResponse,
  IUpdateProfileParams,
  InitializeInterface,
} from "./interfaces/initialize.interface";
import type {
  SignInInterface,
  SignInSuccessResponseInterface,
} from "./interfaces/sign-in.interface";

export const signInApi = createApi({
  reducerPath: "signInApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInSuccessResponseInterface, SignInInterface>({
      query: (data: SignInInterface) => ({
        url: ENDPOINTS.SIGN_IN,
        method: "POST",
        body: data,
      }),
    }),
    me: builder.mutation<IInitializeInterfaceResponse, InitializeInterface>({
      query: (data: InitializeInterface) => ({
        url: ENDPOINTS.ME,
        method: "GET",
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
    updateMe: builder.mutation<
      IInitializeInterfaceResponse,
      IUpdateProfileParams
    >({
      query: (data: IUpdateProfileParams) => {
        const { accessToken, ...bodyData } = data;

        return {
          url: ENDPOINTS.UPDATE_ME,
          method: "PATCH",
          headers: {
            Authorization: accessToken,
          },
          body: bodyData,
        };
      },
    }),

    deleteMe: builder.mutation<IDeleteMeResponse, IDeleteMeParams>({
      query: (data: IDeleteMeParams) => {
        const { accessToken, ...bodyData } = data;

        return {
          url: ENDPOINTS.DELETE_ME,
          method: "DELETE",
          headers: {
            Authorization: accessToken,
          },
          body: bodyData,
        };
      },
    }),
  }),
});

export const {
  useSignInMutation,
  useMeMutation,
  useUpdateMeMutation,
  useDeleteMeMutation,
} = signInApi;
