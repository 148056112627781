import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ENDPOINTS } from "../../constants/api/api";
import type { InviteUserInterface } from "./interfaces/invite-user.interface";

export const inviteUserApi = createApi({
  reducerPath: "inviteUser",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    inviteUser: builder.mutation({
      query: (data: InviteUserInterface) => ({
        url: ENDPOINTS.INVITES.INVITE_USER,
        method: "POST",
        body: {
          email: data.email,
        },
        headers: {
          Authorization: data.accessToken,
        },
      }),
    }),
  }),
});

export const { useInviteUserMutation } = inviteUserApi;
