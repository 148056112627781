import type { DefaultSelectOptions } from "../../../shared/select/select";
import type { IBodyMeasurementInfo } from "../interfaces/personal-info.interface";

export interface IBodyMeasurementsForm {
  [key: string]: any;
  height: string | null;
  weight: string | null;
  body_type: string | null;
  skin_tone: string | null;
  eye_color: string | null;
  hair_style: string | null;
  hair_color: string | null;
  cup_size: string | null;
  cock_size: string | null;
  waist_size: string | null;
  tattoos: string | null;
  piercings: string | null;
}

// eslint-disable-next-line no-shadow
export enum BodyMeasurementKeys {
  height = "height",
  weight = "weight",
  ethnicity = "ethnicity",
  bodyType = "body_type",
  skinTone = "skin_tone",
  eyeColor = "eye_color",
  hairStyle = "hair_style",
  hairColor = "hair_color",
  cupSize = "cup_size",
  cockSize = "cock_size",
  waistSize = "waist_size",
  tattoos = "tattoos",
  piercings = "piercings",
}

export enum BodyMeasurementNames {
  height = "Height",
  weight = "Weight",
  ethnicity = "Ethnicity",
  bodyType = "Body Type",
  skinTone = "Skin Tone",
  eyeColor = "Eye Color",
  hairStyle = "Hair Style",
  hairColor = "Hair Color",
  cupSize = "Cup Size",
  cockSize = "Cock Size",
  waistSize = "Waist Size",
  tattoos = "Tattoos",
  piercings = "Piercings",
}

export const bodyMeasurementInfoDropdown: DefaultSelectOptions[] = [
  {
    value: BodyMeasurementKeys.height,
    display_text: BodyMeasurementNames.height,
    disabled: true,
  },
  {
    value: BodyMeasurementKeys.weight,
    display_text: BodyMeasurementNames.weight,
    disabled: true,
  },
  {
    value: BodyMeasurementKeys.eyeColor,
    display_text: BodyMeasurementNames.eyeColor,
    disabled: true,
  },
  {
    value: BodyMeasurementKeys.ethnicity,
    display_text: BodyMeasurementNames.ethnicity,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.bodyType,
    display_text: BodyMeasurementNames.bodyType,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.skinTone,
    display_text: BodyMeasurementNames.skinTone,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.hairStyle,
    display_text: BodyMeasurementNames.hairStyle,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.hairColor,
    display_text: BodyMeasurementNames.hairColor,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.cupSize,
    display_text: BodyMeasurementNames.cupSize,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.cockSize,
    display_text: BodyMeasurementNames.cockSize,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.waistSize,
    display_text: BodyMeasurementNames.waistSize,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.tattoos,
    display_text: BodyMeasurementNames.tattoos,
    disabled: false,
  },
  {
    value: BodyMeasurementKeys.piercings,
    display_text: BodyMeasurementNames.piercings,
    disabled: false,
  },
];

export const bodyMeasurement: IBodyMeasurementInfo[] = [
  {
    name: BodyMeasurementKeys.height,
    title: BodyMeasurementNames.height,
    placeholder: "Enter height",
    value: null,
  },
  {
    name: BodyMeasurementKeys.weight,
    title: BodyMeasurementNames.weight,
    placeholder: "Enter weight",
    value: null,
  },
  {
    name: BodyMeasurementKeys.eyeColor,
    title: BodyMeasurementNames.eyeColor,
    placeholder: "Enter eye color",
    value: null,
  },
  {
    name: BodyMeasurementKeys.ethnicity,
    title: BodyMeasurementNames.ethnicity,
    placeholder: "Enter ethnicity",
    value: null,
  },
  {
    name: BodyMeasurementKeys.bodyType,
    title: BodyMeasurementNames.bodyType,
    placeholder: "Enter body type",
    value: null,
  },
  {
    name: BodyMeasurementKeys.skinTone,
    title: BodyMeasurementNames.skinTone,
    placeholder: "Enter skin tone",
    value: null,
  },
  {
    name: BodyMeasurementKeys.hairStyle,
    title: BodyMeasurementNames.hairStyle,
    placeholder: "Enter hair style",
    value: null,
  },
  {
    name: BodyMeasurementKeys.hairColor,
    title: BodyMeasurementNames.hairColor,
    placeholder: "Enter hair color",
    value: null,
  },
  {
    name: BodyMeasurementKeys.cupSize,
    title: BodyMeasurementNames.cupSize,
    placeholder: "Enter cup size",
    value: null,
  },
  {
    name: BodyMeasurementKeys.cockSize,
    title: BodyMeasurementNames.cockSize,
    placeholder: "Enter cock size",
    value: null,
  },
  {
    name: BodyMeasurementKeys.waistSize,
    title: BodyMeasurementNames.waistSize,
    placeholder: "Enter waist size",
    value: null,
  },
  {
    name: BodyMeasurementKeys.tattoos,
    title: BodyMeasurementNames.tattoos,
    placeholder: "Enter tattoos",
    value: null,
  },
  {
    name: BodyMeasurementKeys.piercings,
    title: BodyMeasurementNames.piercings,
    placeholder: "Enter piercings",
    value: null,
  },
];
