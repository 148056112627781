import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { ISubscriptions } from "../../../components/subscriptions/interfaces/subscriptions.interface";

interface ICancelledSubscriptions {
  cancelledSubscriptions: ISubscriptions[];
}

const initialState: ICancelledSubscriptions = {
  cancelledSubscriptions: [],
};

export const cancelledSubscriptionsSlice = createSlice({
  name: "cancelledSubscriptions",
  initialState,
  reducers: {
    setCancelledSubscriptions(state, action: PayloadAction<ISubscriptions[]>) {
      state.cancelledSubscriptions = action.payload;
    },
  },
});

export default cancelledSubscriptionsSlice.reducer;
