import { Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { StyledCard } from "../styled";

export default function TemporaryLockout({ expiresAt }: any) {
  const [remainingTime, setRemainingTime] = useState({
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const currentTime = new Date().getTime();
      const endTime = new Date(expiresAt).getTime();
      const timeDiff = endTime - currentTime;

      if (timeDiff <= 0) {
        setRemainingTime({ minutes: "00", seconds: "00" });
        return;
      }
      const seconds = Math.floor((timeDiff / 1000) % 60);
      const minutes = Math.floor((timeDiff / 1000 / 60) % 60);

      const formattedMinutes =
        minutes < 10 ? `0${minutes}` : minutes.toString();
      const formattedSeconds =
        seconds < 10 ? `0${seconds}` : seconds.toString();

      setRemainingTime({
        minutes: formattedMinutes,
        seconds: formattedSeconds,
      });
    };

    const timer = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiresAt]);

  const { minutes, seconds } = remainingTime;

  return (
    <StyledCard>
      <Typography variant="h4" fontWeight={400} textAlign="left">
        Temporary lockout
      </Typography>
      <Typography textAlign="left">
        An incorrect password was entered 3 times. For security reasons, you
        must wait{" "}
        <span style={{ fontWeight: 700 }}>
          {minutes}:{seconds}
        </span>{" "}
        minutes before you can try again.
      </Typography>
      <Typography textAlign="left">
        Need Help? <Link href="/">Contact Sidefans Support</Link>
      </Typography>
    </StyledCard>
  );
}
